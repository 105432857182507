import React, {Component} from "react";
import LoadingSpinner from "../../../../components/loading-spinner";
import {setupReduxConnection} from "../../../../redux";

class PayrollGroupOnboardingCompleteComponent extends Component {
  state = {label: ""};

  isMulti() {
    const {payrollGroup} = this.props.shop;

    return payrollGroup?.TYPE === "MULTI";
  }

  async componentDidMount() {
    setTimeout(() => {
      window.location = `${window.location.protocol + "//" + window.location.host}/payroll`;
    }, 5000);
  }

  render() {
    let {label} = this.state;

    return (
      <div className="flex justify-center flex-col">
        <div className={"text-center font-semibold"}>{label}</div>

        <div className="h-48 flex flex-col justify-center items-center font-bold space-y-10">
          <div>We're bringing you to the next page to continue your payroll onboarding!</div>

          <LoadingSpinner />
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(PayrollGroupOnboardingCompleteComponent);

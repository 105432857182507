import React, {Component} from "react";
import {FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import FormTimeSelect from "../../../components/form-time-select";
import {Formik} from "formik";
import PropTypes from "prop-types";
import FormRow from "../../../components/form-row";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import * as Yup from "yup";
import moment from "moment";
import {values} from "lodash";

class RecurringInvoiceForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData() {
    const {interval, frequency, anchor, due, start, end, timeDue} = this.formikRef.values;

    return {
      schedule: {
        INTERVAL_TYPE: interval,
        FREQUENCY: frequency,

        BILLING_ANCHOR: anchor,
        DAYS_UNTIL_DUE: due,

        DATE_START: start,
        DATE_END: end,

        TIME_DUE: timeDue * 60 * 1000,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  fetchInitialValues() {
    const {schedule} = this.props;

    return {
      start: schedule?.DATE_START ?? Math.floor(new Date().setHours(0, 0, 0, 0)),
      end: schedule?.DATE_END ?? null,
      anchor: schedule?.BILLING_ANCHOR ?? Math.floor(new Date().setHours(0, 0, 0, 0)),
      due: schedule?.DAYS_UNTIL_DUE ?? 0,
      interval: schedule?.INTERVAL_TYPE ?? "MONTH",
      frequency: schedule?.FREQUENCY ?? 1,
      timeDue: schedule?.TIME_DUE ?? 0,
    };
  }

  renderDueDate(values, item) {
    if (parseInt(values.due) < 0) {
      return;
    }

    const dueDate = moment(values.anchor)
      .add(parseInt(values.frequency) * item, values.interval)
      .add(values.due, "days")
      .format("ddd MMMM Do YYYY");

    return (
      <p className="mt-1 text-sm leading-6 text-gray-700 flex flex-row">
        <p className="font-bold mr-1">Due:</p>
        {dueDate}
      </p>
    );
  }

  renderExampleSchedule(values, errors) {
    return (
      <>
        <h2 className="mt-2 text-base font-semibold leading-7 text-gray-900">Invoice Schedule</h2>
        {[0, 1, 2, 3, 4].map((item) => {
          //Render the next five invoices that will be sent out based on the schedule configuration

          const isInvoicePastEndDate =
            moment(values.anchor).add(parseInt(values.frequency) * item, values.interval) >
            moment(values.end);

          if (isInvoicePastEndDate || errors.length > 0) {
            return;
          }

          const creationDate = moment(values.anchor)
            .add(parseInt(values.frequency) * item, values.interval)
            .format("ddd MMMM Do YYYY");

          return (
            <div className="flex flex-row justify-between">
              <p className="mt-1 text-sm leading-6 text-gray-700">{creationDate}</p>
              {this.renderDueDate(values, item)}
            </div>
          );
        })}
      </>
    );
  }

  renderTimeDue(options, values) {
    if (values.due && values.due > 0) {
      return (
        <FormTimeSelect
          className="mr-2"
          name="timeDue"
          label={"Time Due"}
          options={options}
          tooltip="The time of day the invoice is due to be paid."
        />
      );
    }

    return null;
  }

  renderForm(options, values) {
    return (
      <>
        <FormRow>
          <FormSelect
            flex
            name="interval"
            options={options}
            label="Interval"
            data={[
              {label: "Day", value: "DAY"},
              {label: "Week", value: "WEEK"},
              {label: "Month", value: "MONTH"},
              {label: "Year", value: "YEAR"},
            ]}
          />

          <FormInput flex name="frequency" options={options} label="Frequency" />
        </FormRow>

        <FormRow>
          <FormDateTimeSelect name="start" label="Date Start" options={options} hideTime flex />

          <FormDateTimeSelect name="end" label="Date End" options={options} hideTime flex />
        </FormRow>

        <FormRow>
          <FormDateTimeSelect
            name="anchor"
            label="Billing Anchor Date"
            options={options}
            tooltip="The date the billing interval will change from"
            hideTime
            flex
          />

          <FormInput
            name="due"
            label="Days until Due"
            options={options}
            tooltip="How long the invoice has to be paid. Put 0 if there should be no due date."
            flex
          />
        </FormRow>

        {this.renderTimeDue(options, values)}
      </>
    );
  }

  render() {
    const {handleSubmit} = this.props;

    const validationSchema = Yup.object().shape({
      start: Yup.number().required("Start date is required"),
      end: Yup.number()
        .nullable()
        .test("test-endDate", "End date should be after initial date", function testEnd(end) {
          const {start} = this.parent;
          return !end || end > start;
        }),
      anchor: Yup.number()
        .required("Anchor date is required")
        .min(Yup.ref("start"), "Anchor date cannot be before the start date")
        .test("test-anchor", "Anchor date must be before the end date", function testAnchor(anchor) {
          const {end} = this.parent;
          return !end || end > anchor;
        }),
      frequency: Yup.number()
        .typeError("Interval amount must be a number")
        .required("Interval amount is required")
        .min(1, "Interval amount must be at least 1"),
    });

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={this.fetchInitialValues()}
        validationSchema={Yup.lazy((values) => {
          return validationSchema;
        })}
      >
        {(formikOptions) => {
          const {values, handleSubmit, errors} = formikOptions;
          return (
            <form onSubmit={handleSubmit}>
              {this.renderForm(formikOptions, values)}
              {this.renderExampleSchedule(values, errors)}
            </form>
          );
        }}
      </Formik>
    );
  }
}

RecurringInvoiceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  schedule: PropTypes.object.isRequired,
};

export default RecurringInvoiceForm;

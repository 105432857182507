import React, {Component} from "react";
import {
  FormElement,
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {FieldArray, Formik} from "formik";
import moment from "moment-timezone";
import {
  classNames,
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import Combobox from "../../components/combobox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";

class TransactionModal extends Component {
  state = {transaction: null, showCategories: false};

  open(transaction) {
    this.setState({transaction});

    this.modal.open();
  }

  handleSubmit = async ({vendorUniqueId, details, categories}) => {
    const {transaction} = this.state;
    const {ledgers} = this.props.accounting;

    const payload = {
      VENDOR_UNIQUE_ID: vendorUniqueId,
      DETAILS: details,
      CATEGORIES: categories.map(({lineEntryUniqueId, categoryLedgerUniqueId, amount}) => ({
        LINE_ENTRY_UNIQUE_ID: lineEntryUniqueId,
        CATEGORY_LEDGER_UNIQUE_ID: categoryLedgerUniqueId,
        AMOUNT: decimalToDollars(amount),
      })),
    };

    await request(`accounting/transactions/${transaction.UNIQUE_ID}`, "PATCH", payload);

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    const {transaction, showCategories} = this.state;
    const {ledgers, vendors} = this.props.accounting;

    const accountLabel = transaction?.AMOUNT < 0 ? "Withdrawn From" : "Deposited Into";

    const categoryLedgers = ledgers?.filter((_l) => !_l.FINANCIAL_ACCOUNT_TYPE);

    let categories = transaction?.CATEGORIES.map(({LINE_ENTRY_UNIQUE_ID, LEDGER_UNIQUE_ID, AMOUNT, SEQ}) => ({
      lineEntryUniqueId: LINE_ENTRY_UNIQUE_ID,
      categoryLedgerUniqueId: LEDGER_UNIQUE_ID,
      amount: toDollars(`${AMOUNT}`),
      seq: SEQ,
    }));

    const transactionAmount = Math.abs(transaction?.AMOUNT);

    const disabled = transaction?.JOURNAL_ENTRY_TYPE === "PAYOUT";

    let titleString = `Edit Transaction`;

    if (transaction?.JOURNAL_ENTRY_TYPE === "PAYOUT") {
      titleString = "View Payout";
    }

    return (
      <Modal
        label={titleString}
        buttonLabel={!disabled ? "Save" : ""}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            ledgerName: transaction?.LEDGER_NAME,
            date: moment.utc(transaction?.DATE_TRANSACTION).format("MMM D, YYYY"),
            vendorUniqueId: transaction?.VENDOR_UNIQUE_ID,
            categories: categories ? categories : [{}],
            details: transaction?.DETAILS,
          }}
          validationSchema={Yup.object({
            categories: Yup.array(
              Yup.object({
                categoryLedgerUniqueId: Yup.string().required("Category is required"),
                amount: Yup.string().required("Amount is required"),
              })
            ),
            // total: Yup.string()
            //   .nullable("")
            //   .test(
            //     "balance",
            //     `Category amounts must add to ${toDollars(
            //       transaction?.AMOUNT,
            //       true
            //     )}`,
            //     function () {
            //       const {categories} = this.parent;
            //
            //       return (
            //         categories.reduce(
            //           (total, {amount}) =>
            //             total + decimalToDollars(amount ?? "0"),
            //           0
            //         ) === transaction?.AMOUNT
            //       );
            //     }
            //   ),
          })}
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {categories} = values;

            let total = categories?.reduce((total, {amount}) => {
              let s = decimalToDollars(`${amount}` ?? "0");

              if (isNaN(s)) {
                s = 0;
              }

              return total + s;
            }, 0);

            if (isNaN(total)) {
              total = 0;
            }

            const splitArr = [
              {label: "Split Amount", value: total},
              {
                label: "Transaction Amount",
                value: transactionAmount,
              },
              {
                label: "Difference",
                value: total - transactionAmount,
                className: total - transactionAmount === 0 ? `text-green-600` : "text-red-500",
              },
            ];

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name={"ledgerName"} label={accountLabel} options={formikOptions} disabled={true} />
                <FormInput label={"Date"} name={"date"} options={formikOptions} disabled={true} />
                {!disabled && (
                  <Combobox
                    label={"Vendor"}
                    name={"vendorUniqueId"}
                    data={vendors.map(({UNIQUE_ID, NAME}) => ({
                      id: UNIQUE_ID,
                      label: NAME,
                    }))}
                    options={formikOptions}
                  />
                )}
                {disabled && (
                  <div className={"text-indigo-500 font-semibold text-sm cursor-pointer space-y-2 mt-3"}>
                    <div
                      onClick={() =>
                        window.open(`${window.location.origin}/reports/payment-methods`, "_newtab")
                      }
                    >
                      Open Payout in Reporting
                    </div>
                    <div
                      className={"flex flex-row"}
                      onClick={() => this.setState({showCategories: !showCategories})}
                    >
                      View Categories
                      {!showCategories && (
                        <ChevronUpIcon className="h-5 w-5  text-color-gray-700 cursor-pointer" />
                      )}
                      {showCategories && (
                        <ChevronDownIcon className="h-5 w-5  text-color-gray-700 cursor-pointer" />
                      )}
                    </div>
                  </div>
                )}

                {(!disabled || showCategories) && (
                  <FieldArray name={"categories"} options={formikOptions}>
                    {({remove}) => (
                      <div>
                        {categories
                          .sort((a, b) => a.seq - b.seq)
                          .map((_c, index) => (
                            <div className={"flex flex-row space-x-2"}>
                              <Combobox
                                className={"flex-1"}
                                label={index === 0 ? "Category" : ""}
                                name={`categories.${index}.categoryLedgerUniqueId`}
                                data={categoryLedgers.map((_ledger) => ({
                                  label: _ledger.NAME,
                                  id: _ledger.UNIQUE_ID,
                                }))}
                                options={formikOptions}
                                disabled={disabled}
                              />

                              <FormInput
                                name={`categories.${index}.amount`}
                                label={index === 0 ? "Amount" : ""}
                                className={"w-28"}
                                options={formikOptions}
                                disabled={categories.length === 1 || disabled}
                              />

                              <div className={"relative"}>
                                {categories.length > 1 && !disabled && (
                                  <FontAwesomeIcon
                                    className={classNames(
                                      "absolute text-red-500 text-xs cursor-pointer -right-3",
                                      " bottom-3"
                                    )}
                                    icon={"fa-x"}
                                    onClick={() => {
                                      if (categories.length > 2) {
                                        remove(index);
                                      } else {
                                        setFieldValue("categories", [
                                          {
                                            lineEntryUniqueId: categories[0].lineEntryUniqueId,
                                            categoryLedgerUniqueId: categories[0].categoryLedgerUniqueId,
                                            amount: toDollars(`${Math.abs(transaction.AMOUNT)}`),
                                          },
                                        ]);
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </FieldArray>
                )}

                {!disabled && (
                  <div>
                    <FormElement className={"display-none"} name={"total"} />

                    <div className={"mt-2 mb-3 flex flex-row text-center justify-between"}>
                      <div
                        className={"text-indigo-500 font-semibold text-sm text-left cursor-pointer"}
                        onClick={() => setFieldValue("categories", [...categories, {}])}
                      >
                        {categories.length > 1 ? "+ Add Category" : "+ Split Transaction"}
                      </div>

                      {categories.length > 1 && (
                        <div className={"font-semibold text-sm w-56"}>
                          {splitArr.map(({label, value, className}) => (
                            <div className={classNames("flex flex-row justify-between", className)}>
                              <div>{label}</div>
                              <div>{toDollars(value, true)}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <FormTextArea
                      name={"details"}
                      label={"Details"}
                      hint={"Optional"}
                      options={formikOptions}
                    />
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["accounting"], null, {forwardRef: true})(TransactionModal);

import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
class PurchaseTextCreditsModal extends Component {
  state = {display: null};

  open() {
    this.setState({display: null});
    this.modal.open();
  }

  async purchaseCredits({amount, customAmount}) {
    const {companySettings} = this.props.shop;
    const {TEXT_MARKETING_PURCHASED_CREDITS} = companySettings;

    if (!amount) {
      this.modal.close();
      return;
    }

    try {
      const close = await showLoadingConfirmAlert(
        "Purchase Credits",
        "Are you sure you want to purchase text credits? This will cost " +
          toDollars(amount === -1 ? customAmount : amount, true) +
          ". We will charge your card at the end of the month.",
        "Buy for " + toDollars(amount === -1 ? customAmount : amount, true)
      );

      close();
    } catch {
      this.modal.stopLoading();
      return;
    }

    if (amount === -1) {
      await request("text/marketing/credits/", "POST", {AMOUNT: customAmount});

      companySettings.TEXT_MARKETING_PURCHASED_CREDITS =
        parseInt(TEXT_MARKETING_PURCHASED_CREDITS) + parseInt(customAmount);
    } else {
      await request("text/marketing/credits/", "POST", {AMOUNT: amount});

      companySettings.TEXT_MARKETING_PURCHASED_CREDITS =
        parseInt(TEXT_MARKETING_PURCHASED_CREDITS) + parseInt(amount);
    }

    this.props.updateShop({companySettings});
    this.props.onSubmit && this.props.onSubmit();

    this.modal.close();
  }

  render() {
    const {display} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={"Buy" + (display && !isNaN(display) ? "for $" + display : "")}
        formikOnClick={() => this.formikRef}
        label="Purchase Text Credits"
        description="Text credits cost $0.01 each and will be automaticially charged to your card at the end of the month"
      >
        <Formik
          onSubmit={this.purchaseCredits.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            amount: null,
            customAmount: null,
          }}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name="amount"
                  label="Credits"
                  tooltip={{
                    data:
                      "Text marketing credits are $0.01 each. You will be charged automatically on the 1st of" +
                      " every month",
                    label: "Cost",
                  }}
                  onChangeSoft={({value}) =>
                    this.setState({
                      display: value === -1 ? null : toDollars(value),
                    })
                  }
                  options={formikOptions}
                  data={[
                    {label: "5", value: 5},
                    {label: "10", value: 10},
                    {label: "20", value: 20},
                    {label: "50", value: 50},
                    {label: "100", value: 100},
                    {label: "200", value: 200},
                    {label: "500", value: 500},
                    {label: "1,000", value: 1000},
                    {label: "2,000", value: 2000},
                    {label: "10,000", value: 10000},
                    {label: "Custom Amount", value: -1},
                  ]}
                />

                {values.amount === -1 && (
                  <FormInput
                    label={"Custom Credit Amount"}
                    name="customAmount"
                    onChangeSoft={(data) => {
                      this.setState({display: toDollars(data)});
                    }}
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(PurchaseTextCreditsModal);

import React, {Component} from "react";
import {CELL_TYPES, PRODUCT_SALES_COLUMNS} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import ProductTagsDropdown from "../../dropdowns/sales/product-tags-dropdown";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PlatformDropdown from "../../dropdowns/sales/platform-dropdown";
import {setupReduxConnection} from "../../redux";
import {AUDIT_PRODUCT_SALES_COLUMNS} from "../../features/reporting/reports/audit-reporting-constants";
import {isSupplyChainWhitelisted} from "../../utils/util";

class AllProductsSalesReportPage extends Component {
  state = {selectedTags: [], selectedPlatforms: []};

  convertDataToRows = (reportData) => {
    let rows = [];
    const {groupReports, locationIdArray} = this.props.reporting;
    let modifierGroupSelector = "PRODUCT_ID";

    if (groupReports && locationIdArray.length > 1) {
      modifierGroupSelector = "LINE_ITEM_NAME";
    }

    if (reportData) {
      let {LINE_ITEM_RECORDS, MODIFIERS} = reportData;

      LINE_ITEM_RECORDS = LINE_ITEM_RECORDS.filter((item) => {
        return ["PRODUCT", "CUSTOM_PRODUCT"].includes(item.TYPE);
      });

      for (let record of LINE_ITEM_RECORDS) {
        let {LINE_ITEM_NAME, PRODUCT_ID, CATEGORY_NAME, TYPE, LOCATION_NAME} = record;

        if (TYPE === "CUSTOM_PRODUCT") {
          record.CATEGORY_NAME = "Custom Product";
        }

        rows.push({
          label: LINE_ITEM_NAME,
          numbers: record,
          rowType: CELL_TYPES.REGULAR,
          expandCell: (row) => {
            return (
              <div className="px-12 py-9 pb-12 font-semibold text-sm">
                <div className="flex flex-row justify-between">
                  <div>{`Modifiers`}</div>
                </div>

                <div className="border border-gray-200 mt-4">
                  <Table
                    columns={[
                      {
                        label: "Name",
                        value: "OPTION_NAME",
                        tooltip: "This column lists the name of the option that was added onto the product.",
                      },
                      {
                        label: "Order Count",
                        value: "ORDER_COUNT",
                        tooltip:
                          "The value under this column represents the total amount of times the modifier was added onto the product during your selected time period.",
                      },
                      {
                        label: "Price",
                        value: "PRICE",
                        tooltip:
                          "The value under this column represents the current cost of adding the modifier onto the product.",
                        format: (value) => toDollars(value, true),
                      },
                      {
                        label: "Total Modifier Sales",
                        value: "MODIFIER_TOTAL",
                        format: (value, row) => {
                          return toDollars(row.MODIFIER_TOTAL, true);
                        },
                        tooltip:
                          "The value under this column represents the total cost of adding the modifier onto each product.",
                      },
                    ]}
                    data={MODIFIERS[row.numbers[modifierGroupSelector]]}
                  />
                </div>
              </div>
            );
          },
        });
      }

      let productTotals = {
        ORDER_COUNT: 0,
        GROSS_SALES: 0,
        REFUNDS: 0,
        DISCOUNTS: 0,
        FEES: 0,
        NET_SALES: 0,
        TAXES: 0,
        TOTAL_COLLECTED: 0,
        NET_TOTAL: 0,
      };

      productTotals = reportData.TOTALS_BY_TYPE.reduce((sum, record) => {
        if (["PRODUCT", "CUSTOM_PRODUCT", "TICKET_DISCOUNT"].includes(record.TYPE)) {
          return Object.fromEntries(Object.keys(sum).map((k) => [k, sum[k] + record[k]]));
        }

        return sum;
      }, productTotals);

      rows.push({
        label: "Total",
        numbers: productTotals,
        rowType: CELL_TYPES.BOLD,
        footer: true,
      });
    }

    return rows;
  };

  render() {
    let {selectedTags, selectedPlatforms} = this.state;
    const {reportingLocations, locationIdArray} = this.props.reporting;

    let {REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} = this.props.shop.settings;
    const showLocationColumn = Object.values(reportingLocations)?.length > 1;

    let columns = PRODUCT_SALES_COLUMNS;

    if (REPORTING_MODE === "AUDIT") {
      columns = showLocationColumn
        ? [{label: "Location", columnSelector: "LOCATION_NAME"}, ...AUDIT_PRODUCT_SALES_COLUMNS]
        : AUDIT_PRODUCT_SALES_COLUMNS;
    }

    // This is temporary
    columns = columns.filter((it) => it.columnSelector !== "ESTIMATED_COST");

    const loadingTextCheck = locationIdArray.length > 1;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"All Product Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="all-product-sales"
          endpoint="report/productsales"
          firstColLabel="Product Name"
          constantColumns={columns}
          search={true}
          searchText={"Search Products by Name"}
          convertDataToRows={this.convertDataToRows}
          locationPicker={true}
          expandable={true}
          customParams={{
            SELECTED_TAGS_ARRAY: [...selectedTags],
            SELECTED_PLATFORMS_ARRAY: [...selectedPlatforms],
          }}
          showExcludeThird={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
          groupbyFilter={true}
          loadingText={loadingTextCheck && "This report can take up to 30 seconds per location."}
          customFields={
            <div className={"flex-row flex space-x-3 items-center"}>
              <ProductTagsDropdown
                ignoreMargin
                iconPadding
                ignoreShadow
                className="-mt-1"
                onChangeSoft={(selected) => {
                  const {selectedTags} = this.state;

                  const index = selectedTags.findIndex((tag) => tag === selected.value);

                  if (index !== -1) {
                    selectedTags.splice(index, 1);
                  } else {
                    selectedTags.push(selected.value);
                  }

                  this.setState({selectedTags});
                }}
                value={selectedTags}
              />

              <PlatformDropdown
                ignoreMargin
                noMl
                onChange={(selectedPlatforms) => this.setState({selectedPlatforms})}
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(AllProductsSalesReportPage);

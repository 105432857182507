import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class WifiPrinterModal extends Component {
  state = {printer: null};

  open(printer) {
    this.setState({printer}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }
  _getPayload(name, ip) {
    let {
      DEVICE_ID,
      MAKE_TICKET,
      PRINT_SAVED_ORDERS,
      PRINT_PLACED,
      PLATFORMS,
      CATEGORIES,
      MODIFIERS,
      TYPES,
      PRINT_SEPARATE_ITEMS,
      DISABLED,
      CASH_DRAWER,
      TARGET_DEVICE_ID,
    } = this.state.printer;

    let payload = {
      NAME: name,
      DEVICE_ID,
      IP_ADDRESS: ip,
      PLATFORMS,
      MODIFIERS,
      TYPES,
      CATEGORIES,
      MAKE_TICKET,
      PRINT_SAVED_ORDERS,
      PRINT_PLACED,
      DISABLED,
      PRINT_SEPARATE_ITEMS,
      CASH_DRAWER,
      TARGET_DEVICE_ID,
    };

    return payload;
  }

  handleSubmit = async (values) => {
    const {printer} = this.state;

    const payload = {
      ...this._getPayload(values.NAME, values.IP_ADDRESS),

      DATE_UPDATED: Date.now(),
    };

    await request("partner/printers/" + printer.ID, "PATCH", payload);

    this.props.updateInfo(printer);
    this.modal.close();
  };

  render() {
    const {printer} = this.state;

    const schema = Yup.object({
      NAME: Yup.string().nullable().required("Name is required."),
      IP_ADDRESS: Yup.string().nullable().required("IP Address is required."),
    });

    return (
      <Modal
        label="Edit Printer"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            NAME: printer?.NAME,
            IP_ADDRESS: printer?.IP_ADDRESS,
          }}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Printer Name" name="NAME" options={formikOptions} />

                <FormInput label="IP Address" name="IP_ADDRESS" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default WifiPrinterModal;

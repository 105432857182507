import React, {Component} from "react";
import Option from "./option";
import {setupReduxConnection} from "../../redux";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {CheckIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon} from "@heroicons/react/solid";

class ModifierCard extends Component {
  state = {open: true};

  renderQuantitySelections() {
    const {modifier} = this.props;

    if (!modifier.MAX_SELECTIONS && !modifier.MIN_SELECTIONS) {
      return <div />;
    }

    if (modifier.MIN_SELECTIONS === modifier.MAX_SELECTIONS) {
      return (
        <span className="flex bg-gray-200 px-2.5 py-0.5 rounded-md text-sm text-gray-500 items-center font-medium">
          pick {modifier.MIN_SELECTIONS}
        </span>
      );
    }

    const selections = [];
    if (modifier.MIN_SELECTIONS > 0) {
      selections.push(
        <span
          key={2}
          className="flex bg-gray-200 px-2.5 py-0.5 rounded-md text-sm text-gray-500 items-center font-medium"
        >
          {modifier.MIN_SELECTIONS} min
        </span>
      );
    }

    if (modifier.MAX_SELECTIONS > 0) {
      selections.push(
        <span
          key={1}
          className="flex bg-gray-200 px-2.5 py-0.5 rounded-md text-sm text-gray-500 items-center font-medium"
        >
          {modifier.MAX_SELECTIONS} max
        </span>
      );
    }

    return selections;
  }

  render() {
    const {modifier, selections, invalid} = this.props;
    const {open} = this.state;

    const variant = selections.find((item) => {
      return item.MODIFIER_TYPE === 5;
    });

    let {ID, REQUIRED} = modifier;

    let requiredSatisfied = REQUIRED && selections.find((customization) => ID === customization.MODIFIER_ID);

    return (
      <div className="bg-white p-5">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() =>
            this.setState((prevState) => ({
              open: !prevState.open,
            }))
          }
        >
          <h4
            className={
              "text-md " + classNames(invalid ? "text-red-500" : "text-gray-900", "text-sm font-bold")
            }
          >
            {modifier.NAME}
          </h4>

          <div className="flex flex-row space-x-3 items-center">
            {this.renderQuantitySelections()}

            {!!requiredSatisfied && (
              <div className="flex flex-row items-center bg-green-200 text-green-900 text-xs px-2 py-0.5 rounded-sm text-sm font-medium">
                <CheckIcon className="h-3 w-3 mr-1" />
                <span>Required</span>
              </div>
            )}

            {!requiredSatisfied && !!modifier.REQUIRED && (
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-sm text-xs font-medium text-green-900 ${
                  invalid ? "bg-red-100" : "bg-green-200"
                }`}
              >
                Required
              </span>
            )}

            {!modifier.REQUIRED && (
              <p className="font-medium text-xs text-gray-900 bg-gray-200 px-2.5 py-0.5 ">Optional</p>
            )}

            <div className="h-5 w-5 rounded-full bg-gray-100">
              {!!open && <ChevronDownIcon className="h-5 w-5 cursor-pointer" />}

              {!open && <ChevronRightIcon className="h-5 w-5 cursor-pointer" />}
            </div>
          </div>
        </div>

        {open && (
          <div className="mt-3">
            <label className="sr-only">Choose a size</label>

            <div className="grid grid-cols-1">
              {modifier.OPTIONS.map((item) => {
                const selection = selections.find((selection) => {
                  return selection.MODIFIER_ID === modifier.ID && selection.OPTION_ID === item.ID;
                });

                return (
                  <Option
                    {...this.props}
                    active={!!selection}
                    quantity={selection?.QUANTITY ?? 1}
                    selections={selections}
                    modifier={modifier}
                    variant={variant}
                    option={item}
                    key={item.ID}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(ModifierCard);

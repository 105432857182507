import {getStore} from "../redux";
import {addPendingMessage, addPendingUpserts, removeConversation, upsertConversation} from "../redux/chat";

export function setupMessageListener(socket) {
  const {dispatch, getState} = getStore();

  socket.on("message", (message) => {
    const {conversations} = getState().chat;

    const conversation = conversations.find((item) => {
      return item.ID === parseInt(message.CONVERSATION_ID);
    });

    if (!conversation) {
      return;
    }

    conversation.DATE_LAST_MESSAGE = message.DATE_CREATED;
    conversation.LAST_MESSAGE = message.CONTENT;

    dispatch(addPendingMessage(message));
    dispatch(upsertConversation(conversation));
  });

  socket.on("CONVERSATION_UPSERT", (conversation) => {
    dispatch(upsertConversation(conversation));
    dispatch(addPendingUpserts(conversation));
  });

  socket.on("CONVERSATION_REMOVE", (conversation) => {
    dispatch(removeConversation(conversation));
  });

  socket.on("CONVERSATION_PARTICIPANT_REMOVE", ({conversation}) => {
    dispatch(upsertConversation(conversation));
    dispatch(addPendingUpserts(conversation));
  });

  socket.on("CONVERSATION_READ", (conversation) => {
    dispatch(upsertConversation(conversation));
    dispatch(addPendingUpserts(conversation));
  });

  socket.on("CONVERSATION_PARTICIPANT_ADD", (conversation) => {
    dispatch(upsertConversation(conversation));
    dispatch(addPendingUpserts(conversation));
  });
}

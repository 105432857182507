import React, {Component} from "react";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class InvoiceLinesCard extends Component {
  render() {
    const {hideEdit, handleEdit, ticket, lines, serviceFees = []} = this.props;

    const serviceFeeTotal = serviceFees.reduce((accum, item) => accum + item.TOTAL, 0);

    const serviceFeeTax = serviceFees.reduce((accum, item) => accum + item.AMOUNT_TAX, 0);

    let feeTotal = serviceFeeTotal;
    let tipTotal = 0;
    let discountTotal = 0;
    let sub = 0;
    let total = serviceFeeTotal;
    let tax = serviceFeeTax;

    for (const line of lines) {
      total += line.TOTAL;
      if (line.TYPE === "TIP") {
        tipTotal += line.AMOUNT;
      } else {
        feeTotal += line.AMOUNT_FEES ?? 0;
        discountTotal += line.AMOUNT_DISCOUNT;
        sub += line.AMOUNT + line.AMOUNT_DISCOUNT - (line.AMOUNT_FEES ?? 0);
        tax += line.AMOUNT_TAX;
      }
    }

    return (
      <Card
        label="Invoices Lines"
        button={
          hideEdit
            ? undefined
            : {
                label: "Edit Lines",
                onClick: () => handleEdit(),
              }
        }
      >
        <Table
          data={[
            ...lines.filter((line) => line.TYPE !== "TIP").sort((a, b) => a.SEQ - b.SEQ),
            ...serviceFees,
            {
              QUANTITY: "Subtotal",
              TOTAL: sub ?? ticket?.PAYMENT_INTENT?.AMOUNT,
            },
            {
              QUANTITY: "Discounts",
              TOTAL: -discountTotal ?? -ticket?.PAYMENT_INTENT.AMOUNT_DISCOUNT,
            },
            {QUANTITY: "Tax", TOTAL: tax ?? ticket?.PAYMENT_INTENT.AMOUNT_TAX},
            {
              QUANTITY: "Fees",
              TOTAL: feeTotal ?? ticket?.PAYMENT_INTENT.AMOUNT_FEES,
            },
            {
              QUANTITY: "Tip",
              TOTAL: tipTotal ?? ticket?.PAYMENT_INTENT.AMOUNT_TIP,
            },
            {QUANTITY: "Total", TOTAL: ticket?.PAYMENT_INTENT?.TOTAL ?? total},
          ]}
          columns={[
            {
              width: 1,
              value: "NAME",
              label: "Invoice Line",
            },
            {
              width: 1,
              value: "AMOUNT",
              label: "Amount",
              format: (total, item) =>
                total || total === 0 ? "$" + toDollars((total + item.AMOUNT_DISCOUNT) / item.QUANTITY) : "",
            },
            {
              width: 1,
              value: "AMOUNT_DISCOUNT",
              label: "Discount Amount",
              format: (discount, item) =>
                discount || discount === 0 ? toDollars(discount / item.QUANTITY, true) : "",
            },
            {
              width: 1,
              value: "AMOUNT_TAX",
              label: "Amount Tax",
              format: (total, item) => (total || total === 0 ? "$" + toDollars(total / item.QUANTITY) : ""),
            },
            {
              width: 1,
              value: "QUANTITY",
              label: "Quantity",
            },
            {
              width: 1,
              value: "TOTAL",
              label: "Total",
              format: (total) => "$" + toDollars(total),
            },
          ]}
        />
      </Card>
    );
  }
}

InvoiceLinesCard.propTypes = {
  lines: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default InvoiceLinesCard;

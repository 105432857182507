import React, {Component} from "react";
import {CheckIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {Button} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {Trans} from "react-i18next";

class Wizard extends Component {
  state = {step: 0};

  setStep(step) {
    this.setState({step});
  }

  fetchValues() {
    return this.formikRef.values;
  }

  fetchSubmitButton() {
    return this.submitRef;
  }

  resetForm() {
    this.formikRef.resetForm();
  }

  setCurrentFormFieldValue(key, value) {
    this.formikRef.setFieldValue(key, value);
  }

  getI18NextKey(path) {
    return "components.wizard." + path;
  }

  renderStep(formikOptions, step, stepIdx) {
    const {step: currentId} = this.state;
    const {steps, flexHeader} = this.props;

    let isComplete = false;
    if (step.yup) {
      if (step.yup.isValidSync(formikOptions.values)) {
        isComplete = true;
      }
    } else if (currentId >= step.id) {
      isComplete = true;
    }

    if (currentId === step.id) {
      isComplete = false;
    }

    return (
      <li
        key={stepIdx}
        className={classNames(
          "relative overflow-hidden ",
          !flexHeader ? "lg:flex-1" : "",
          flexHeader ? "transition-[all] ease-in-out duration-500" : "",
          flexHeader && currentId === step.id ? "flex-auto " : ""
        )}
      >
        <div
          className={classNames(
            stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
            stepIdx === steps.length - 1 ? "border-t-0 rounded-b-md" : "",
            "border border-gray-200 overflow-hidden lg:border-0"
          )}
        >
          {isComplete ? (
            <a
              className="group"
              onClick={() => {
                step.didUnmount && step.didUnmount();

                this.setState({step: step.id});
              }}
            >
              <span
                className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                aria-hidden="true"
              />
              <span
                className={classNames(
                  stepIdx !== 0 ? "lg:pl-9" : "",
                  "px-6 py-5 flex items-start text-sm font-medium"
                )}
              >
                <span className="flex-shrink-0">
                  <span className="w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                </span>

                {!flexHeader && (
                  <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                    <span className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                    <span className="text-sm font-medium text-gray-500">{step.description}</span>
                  </span>
                )}
              </span>
            </a>
          ) : currentId === step.id ? (
            <a href={step.href} aria-current="step">
              <span
                className="absolute top-0 left-0 w-1 h-full bg-indigo-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                aria-hidden="true"
              />
              <span
                className={classNames(
                  stepIdx !== 0 ? "lg:pl-9" : "",
                  "px-6 py-5 flex items-start text-sm font-medium"
                )}
              >
                <span className="flex-shrink-0">
                  <span className="w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                    <span className="text-indigo-600">{step.id + 1}</span>
                  </span>
                </span>
                <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                  <span className="text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                    {step.name}
                  </span>
                  <span className="text-sm font-medium text-gray-500">{step.description}</span>
                </span>
              </span>
            </a>
          ) : (
            <a href={step.href} className="group">
              <span
                className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                aria-hidden="true"
              />
              <span
                className={classNames(
                  stepIdx !== 0 ? "lg:pl-9" : "",
                  "px-6 py-5 flex items-start text-sm font-medium"
                )}
              >
                <span className="flex-shrink-0">
                  <span className="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                    <span className="text-gray-500">{step.id + 1}</span>
                  </span>
                </span>
                {!flexHeader && (
                  <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                    <span className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                    <span className="text-sm font-medium text-gray-500">{step.description}</span>
                  </span>
                )}
              </span>
            </a>
          )}

          {stepIdx !== 0 ? (
            <>
              {/* Separator */}
              <div className="hidden absolute top-0 left-0 w-3 inset-0 lg:block" aria-hidden="true">
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 12 82"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.5 0V31L10.5 41L0.5 51V82"
                    stroke="currentcolor"
                    vectorEffect="non-scaling-stroke"
                  />
                </svg>
              </div>
            </>
          ) : null}
        </div>
      </li>
    );
  }

  renderNext(formikOptions) {
    const {steps, submitLabel = "Submit"} = this.props;
    const {step} = this.state;

    const currentStep = steps.find((item) => item.id === step);

    if (step === steps.length - 1) {
      return (
        <Button
          ref={(e) => (this.submitRef = e)}
          label={submitLabel}
          type="gray"
          onClick={async () => {
            if (currentStep.yup && !currentStep.yup.isValidSync(formikOptions.values)) {
              return;
            }

            if (currentStep.customValidation) {
              const validated = await currentStep.customValidation(formikOptions);

              if (!validated) {
                return;
              }
            }

            this.submitRef.startLoading();

            formikOptions.submitForm();
          }}
        />
      );
    }

    return (
      <a
        onClick={async () => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          await this.moveForward(formikOptions);
        }}
        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white cursor-pointer hover:bg-gray-50"
      >
        <Trans i18nKey={this.getI18NextKey("next.label")} />
      </a>
    );
  }

  async moveForward(formikOptions) {
    const {steps} = this.props;
    const {step} = this.state;

    const currentStep = steps.find((item) => item.id === step);

    if (currentStep.yup) {
      try {
        currentStep.yup.validateSync(formikOptions.values);
      } catch (e) {
        if (!currentStep.initialValues) return;

        return Object.keys(currentStep.initialValues).forEach((item) => {
          formikOptions.setFieldTouched(item, true);
        });
      }
    }

    if (currentStep.validation && !(await currentStep.validation(formikOptions))) {
      return;
    }

    if (this.stepRef && this.stepRef.validateForm) {
      const isValid = await this.stepRef.validateForm();

      if (!isValid) return;
    }

    if (this.stepRefs) {
      for (let ref of Object.values(this.stepRefs)) {
        if (!ref) continue;

        if (ref.validateForm) {
          const isValid = await ref.validateForm();

          if (!isValid) return;
        }
      }
    }

    if (currentStep.customValidation) {
      const validated = await currentStep.customValidation(formikOptions);
      if (!validated) {
        return;
      }
    }

    currentStep.didUnmount && currentStep.didUnmount();

    this.setState({step: step + 1}, async () => {
      if (steps[step + 1] && steps[step + 1].yup) {
        await steps[step].yup.validate(formikOptions.values);
      }
    });
  }

  render() {
    const {steps, onSubmit, initialValues = {}} = this.props;
    const {step} = this.state;

    const currentStep = steps[step];
    const initialDict = steps.reduce((dict, item) => {
      return {...dict, ...item.initialValues};
    }, initialValues);

    return (
      <Formik
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialDict}
        innerRef={(ref) => (this.formikRef = ref)}
        validationSchema={Yup.lazy((vals) => {
          if (!currentStep.yup) {
            return Yup.object({});
          }

          return currentStep.yup;
        })}
      >
        {(formikOptions) => {
          const {values, errors} = formikOptions;

          return (
            <div>
              <div className="lg:border-t lg:border-b lg:border-gray-200 transition-all duration-500">
                <nav className="mx-auto" aria-label="Progress">
                  <ol
                    role="list"
                    className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none"
                  >
                    {steps.map((step, stepIdx) => this.renderStep(formikOptions, step, stepIdx))}
                  </ol>
                </nav>
              </div>

              <div>
                {currentStep.render &&
                  currentStep.render(formikOptions, {
                    setupRef: (e) => (this.stepRef = e),
                    setupRefs: (e, index) => {
                      if (!this.stepRefs) {
                        this.stepRefs = {};
                      }

                      this.stepRefs[index] = e;
                    },
                    handleSubmit: ({...options}) => {
                      if (this.stepRefs) {
                        for (let ref of Object.values(this.stepRefs)) {
                          if (!ref) {
                            continue;
                          }

                          const optionData = ref.fetchFormData(options);

                          for (let key of Object.keys(optionData)) {
                            formikOptions.setFieldValue(key, optionData[key]);
                          }
                        }
                      }

                      if (this.stepRef) {
                        const optionData = this.stepRef.fetchFormData(options);

                        for (let key of Object.keys(optionData)) {
                          formikOptions.setFieldValue(key, optionData[key]);
                        }
                      }
                    },
                  })}
              </div>

              <nav
                className="py-3 flex items-center justify-between border-t border-gray-200"
                aria-label="Pagination"
              >
                <div className="flex-1 flex justify-between sm:justify-end sm:space-x-2">
                  <Button
                    type="gray"
                    label={<Trans i18nKey={this.getI18NextKey("back.label")} />}
                    onClick={() => {
                      currentStep.didUnmount && currentStep.didUnmount();

                      if (step === 0) {
                        this.props.onQuit && this.props.onQuit();
                      }

                      this.setState({step: Math.max(0, step - 1)});
                    }}
                  />

                  {this.renderNext(formikOptions)}
                </div>
              </nav>
            </div>
          );
        }}
      </Formik>
    );
  }
}

Wizard.propTypes = {
  onQuit: PropTypes.func,
  submitLabel: PropTypes.string,
  initialValues: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      initialValues: PropTypes.object.isRequired,
    })
  ),
};

export default Wizard;

import {FormBoolean, FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {companyNameToSubdomain} from "../../../utils/util";
import * as Yup from "yup";

class MarketingModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async ({fromName, user, subdomain, optIn, showTime}) => {
    const {onlyEmail} = this.props;
    const {companySettings} = this.props.shop;
    const companyPayload = {
      MARKETING_EMAIL_FROM: !fromName || fromName === "" ? null : fromName.trim(),
      MARKETING_EMAIL_USER: !user || user.trim() === "" ? "marketing" : user.trim(),
      MARKETING_EMAIL_SUBDOMAIN: !subdomain || subdomain === "" ? null : subdomain.trim(),
    };

    if (!onlyEmail) {
      companyPayload.EXPIRATION_DATE_FORMAT = showTime === "0" ? "MM/DD/YY" : "MM/DD/YY hh:mm a";
    }

    await request("settings/company", "PATCH", {
      SETTINGS: companyPayload,
    });

    if (!onlyEmail) {
      let payload = {
        TEXT_MARKETING_OPT_IN: optIn,
      };

      await request("partner/settings/v2", "PATCH", {SETTINGS: payload});
    }

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({
      settings,
      companySettings: {...companySettings, ...companyPayload},
    });

    this.modal.close();
  };

  render() {
    const {companyName, onlyEmail} = this.props;

    const validationObject = Yup.object().shape({
      fromName: Yup.string("Name is required").required("Name is required"),
      subdomain: Yup.string("Subdomain is required")
        .matches(
          /^[a-zA-Z0-9._-]+$/,
          "Email must contain only alphanumeric characters, '.', '-', '_' and must be a properly formatted email address"
        )
        .required("Subdomain is required"),
    });

    const {MARKETING_EMAIL_USER, MARKETING_EMAIL_FROM, MARKETING_EMAIL_SUBDOMAIN, EXPIRATION_DATE_FORMAT} =
      this.props.shop.companySettings;
    const {TEXT_MARKETING_OPT_IN} = this.props.shop.settings;

    return (
      <Modal
        label="Marketing Settings"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={validationObject}
          initialValues={{
            fromName: MARKETING_EMAIL_FROM || companyName,
            user: MARKETING_EMAIL_USER,
            subdomain: MARKETING_EMAIL_SUBDOMAIN || companyNameToSubdomain(companyName),
            optIn: TEXT_MARKETING_OPT_IN,
            showTime: EXPIRATION_DATE_FORMAT === "MM/DD/YY hh:mm a" ? "1" : "0",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="fromName"
                  label="Display Name"
                  options={formikOptions}
                  tooltip="This is the name that will display to email recipients."
                />

                <FormInput
                  name="user"
                  tooltip="This is the user that will display on the email. Emails will be sent from <user>@<subdomain>.dripos.com"
                  label="Email User"
                  options={formikOptions}
                />

                <FormInput
                  name="subdomain"
                  label="Subdomain"
                  options={formikOptions}
                  tooltip="This is the subdomain that will display on the email. Emails will be sent from <user>@<subdomain>.dripos.com"
                />

                {!onlyEmail && (
                  <>
                    <FormBoolean
                      name="optIn"
                      label="Display Marketing Opt In"
                      options={formikOptions}
                      tooltip={
                        "If set to yes, a brief message will ask patrons if they want to subscribe to marketing campaigns after checkout."
                      }
                    />

                    <FormBoolean
                      name="showTime"
                      label="Show Time on Expiration Text"
                      options={formikOptions}
                      tooltip={
                        "If set to yes, coupons attached to text campaigns will show the expiration date and time in the text. Otherwise, only the date will be shown and not the time."
                      }
                    />
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(MarketingModal);

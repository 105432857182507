import React from "react";
import {Handle, Position} from "reactflow";

function IngredientNode({data}) {
  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-white">
      <div className="flex">
        <div>
          <div className="text-md font-bold">{data.label}</div>
          <div className="text-sm ">
            {data.quantity} {data.unit} for {data.cost}
          </div>
        </div>
      </div>

      <Handle type="target" position={Position.Left} className={data.good ? "bg-green-600" : "bg-red-600"} />

      <Handle type="source" position={Position.Right} className={data.good ? "bg-green-600" : "bg-red-600"} />
    </div>
  );
}

export default IngredientNode;

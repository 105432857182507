import React, {Component} from "react";
import SuccessBadge from "../../components/badges/success-badge";
import GrayBadge from "../../components/badges/gray-badge";
import DangerBadge from "../../components/badges/danger-badge";

class PercentageBadge extends Component {
  render() {
    const {current, past} = this.props;

    const percentage = (((current - past) / past) * 100).toFixed(2);

    if (parseFloat(percentage) > 0) {
      return <SuccessBadge>{`${percentage}%`}</SuccessBadge>;
    }

    if (parseFloat(percentage) === 0 || isNaN(parseFloat(percentage))) {
      return <GrayBadge>{`0.00%`}</GrayBadge>;
    }

    return <DangerBadge>{`${percentage}%`}</DangerBadge>;
  }
}

export default PercentageBadge;

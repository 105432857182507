import React, {Component} from "react";
import Wizard from "../../../components/wizard";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import EmployeeForm from "../../../forms/team/employees/employee-form";

class CreateEmployeePage extends Component {
  async submitEmployee({name, email, role, phone, pin}) {
    phone = "1" + phone.replaceAll(" ", "");

    let employeePayload = {
      PIN: "" + pin,
      FULL_NAME: name,
      ROLE_ID: role,
      EMAIL: email,
      PHONE: phone,
    };

    console.log(employeePayload);

    const serverEmployee = await request("employees", "POST", employeePayload);

    this.props.router.navigate("/employee/" + serverEmployee.UNIQUE_ID);
  }

  render() {
    return (
      <Wizard
        onSubmit={this.submitEmployee.bind(this)}
        onQuit={() => this.props.router.navigate("/employees")}
        steps={[
          {
            id: 0,
            name: "Basic information",
            description: "Simple information about the employee.",
            render: ({values}, {handleSubmit, setupRef}) => {
              return (
                <EmployeeForm ref={(e) => setupRef(e)} handleSubmit={handleSubmit} employee={values?.role} />
              );
            },
          },
          {
            id: 1,
            name: "Pay Rates",
            description: "How will this product be priced.",
            initialValues: {type: "0", price: "", variant: this.variant},
            render: (formikOptions) => <div />,
          },
          {
            id: 2,
            name: "Preview",
            description: "Confirm employee creation.",
            render: ({values}) => {
              const {name, role, phone, email, pin} = values;

              return (
                <TwoColumnList
                  label="Employee Summary"
                  description="Lets take a quick look before we create this new employee"
                  data={[
                    {label: "Name", value: name},
                    {label: "Role", value: role},
                    {
                      label: "Phone",
                      value: phone,
                    },
                    {
                      label: "Email",
                      value: email,
                    },
                    {
                      label: "Pin",
                      value: pin,
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />
    );
  }
}

export default withRouter(CreateEmployeePage);

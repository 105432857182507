import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {PageHeadings, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment";
import {request} from "../../../../utils/request";
import TipBucketsTable from "../../../../tables/operations/tip-buckets-table";

class TipBucketsPage extends Component {
  state = {
    isLoading: true,
    start: moment().subtract(31, "day").valueOf(),
    end: moment().valueOf(),
    data: [],
  };

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const {start, end} = this.state;

    const data = await request("operations/buckets", "GET", {
      DATE_START: start,
      DATE_END: end,
    });

    this.setState({isLoading: false, data});
  }

  render() {
    const {isLoading, data} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings label="Tip Buckets" description="View and manage tip buckets in your stores" />

        <TipBucketsTable className="mt-4" buckets={data} refresh={this.syncData.bind(this)} />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(TipBucketsPage));

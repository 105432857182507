import React, {Component} from "react";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import PayrollCustomBenefitModal from "../../../../modals/payroll/benefits/payroll-custom-benefit-modal";
import {CUSTOM_BENEFIT_TYPES} from "../../../../utils/payroll-constants";
import {withRouter} from "../../../../utils/navigation";

class CustomBenefitsPage extends Component {
  state = {benefits: null};

  async componentDidMount() {
    await this.syncState();
  }

  syncState = async () => {
    let benefits = await request("payroll/benefits/custom", "GET");

    this.setState({benefits});
  };

  render() {
    let {benefits} = this.state;

    return (
      <div>
        <PayrollCustomBenefitModal ref={(e) => (this.benefitModal = e)} />

        <PageHeadings label={"Custom Benefits"} />

        <Card
          label={"Custom Benefits"}
          description={"Administer your custom benefits"}
          buttons={[
            {
              label: "Create Benefit",
              onClick: () => this.benefitModal.open(null),
            },
          ]}
        >
          <Table
            actionButtons={[
              {
                label: "View",
                onClick: (row) => {
                  this.props.router.navigate(row.UNIQUE_ID);
                },
              },
            ]}
            data={benefits}
            columns={[
              {
                label: "Name",
                value: "NAME",
              },
              {
                label: "Type",
                value: "BENEFIT",
                format: (value) => CUSTOM_BENEFIT_TYPES[value],
              },
              {
                label: "Active Employees",
                value: "EMPLOYEES",
                format: (value) => value?.length ?? 0,
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(CustomBenefitsPage);

import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../../utils/request";
import TransferItemsForm from "../../../../pages/operations/supply-chain/transfers/transfer-items-form";
import {deepClone} from "../../../../utils/util";

class TransferLinesModal extends Component {
  state = {transfer: null};

  open(transfer = null) {
    this.formikRef && this.formikRef.resetForm();
    this.setState({transfer: deepClone(transfer)}, () => this.modal.open());
  }

  async saveTransfer({transferLocation, transferItems, notes, date, number}) {
    const {transfer} = this.state;

    await request("inventory-transfers/" + transfer.UNIQUE_ID, "PATCH", {
      ITEMS: transferItems.map((item) => {
        return {
          RECEIVED_QUANTITY: item.RAW_QUANTITY ?? item.QUANTITY,
          TOTAL: item.TOTAL,
          INGREDIENT_ID: item.INGREDIENT_ID,
          NAME: item.NAME,
          PRODUCT_SKU: item.PRODUCT_SKU,
          UNIT_ID: item.UNIT_ID,
          ITEM_REQUEST_ID: item.ITEM_REQUEST_ID,
          INVENTORY_TRANSFER_ID: item.INVENTORY_TRANSFER_ID,
        };
      }),
      RECEIVER_LOCATION_ID: transferLocation,
      NOTES: notes,
      DELIVERY_DATE: date,
      TRANSFER_NUMBER: number,
    });

    this.props.syncState && (await this.props.syncState());
    this.modal.close();
  }

  render() {
    const {transfer} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Transfer Items"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        xlarge
      >
        <Formik
          onSubmit={(values) => this.saveTransfer(values)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            transferLocation: transfer?.RECEIVER_LOCATION_ID ?? null,
            transferItems: transfer?.ITEMS ?? [],
            notes: transfer?.NOTES ?? "",
            number: transfer?.TRANSFER_NUMBER ?? 1,
            date: transfer?.DATE_DELIVERED ?? null,
          }}
          validationSchema={Yup.object({
            transferLocation: Yup.number().nullable().required("Please select a location."),
            transferItems: Yup.array(),
            notes: Yup.string(),
            number: Yup.number().nullable().required("Please enter the order number."),
            date: Yup.number().nullable(),
          })}
        >
          {(formikOptions) => {
            const {setFieldValue} = formikOptions;
            const {transferItems} = formikOptions.values;

            return (
              <TransferItemsForm
                transferItems={transferItems}
                addItem={(idx, item) => {
                  transferItems[idx] = {
                    ...transferItems[idx],
                    ...item,
                  };

                  setFieldValue("transferItems", transferItems);
                }}
                removeItem={(idx) => {
                  transferItems.splice(idx, 1);
                  setFieldValue("transferItems", transferItems);
                }}
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TransferLinesModal;

import React, {Component} from "react";
import {FormEmail, FormInput, FormPhone} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import FormRow from "../../../components/form-row";
import FormSpacer from "../../../components/form-spacer";
import RoleDropdown from "../../../dropdowns/team/role-dropdown";
import * as Yup from "yup";

class RoleForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({name, email, role, phone, pin}) {
    phone = "1" + phone.replaceAll(" ", "");

    return {
      role: {
        PIN: "" + pin,
        FULL_NAME: name,
        ROLE_ID: role,
        EMAIL: email,
        PHONE: phone,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, employee} = this.props;

    return (
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={{
          name: employee?.FULL_NAME ?? "",
          role: employee?.ROLE_ID,
          phone: employee?.PHONE ?? "",
          email: employee?.EMAIL ?? "",
          pin: employee?.PIN ?? "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Employee Name is required."),
          role: Yup.string().nullable().required("Default role is required."),
          phone: Yup.number().required("Phone number is required."),
          email: Yup.string(),
          pin: Yup.number().required("PIN is required."),
        })}
      >
        {(formikOptions) => {
          return (
            <>
              <FormRow>
                <FormInput label="Employee Name" options={formikOptions} name="name" flex />

                <RoleDropdown options={formikOptions} name="role" flex />
              </FormRow>

              <FormRow>
                <FormEmail label="Email" options={formikOptions} name="email" flex hint={"Optional"} />

                <FormPhone label="Phone" options={formikOptions} name="phone" flex />
              </FormRow>

              <FormRow>
                <FormInput
                  flex
                  label="Pin"
                  name="pin"
                  buttonHint={{
                    label: "Randomize Pin",
                    onClick: () => console.log("DOGS"),
                  }}
                  options={formikOptions}
                  tooltip={
                    "A unique PIN is required for each employee in order to uniquely identify them within your company."
                  }
                />

                <FormSpacer />
              </FormRow>
            </>
          );
        }}
      </Formik>
    );
  }
}

RoleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  role: PropTypes.object,
};

export default RoleForm;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const PERMISSIONS = {
  ADMIN: {
    FULL: {
      id: "ADMIN_FULL",
      label: "Admin",
      description:
        "Gives employee access to entire Partner Dashboard. Should only be given to employees/owners who should be able to access all aspects of the dashboard.",
    },
  },
  SHOP: {
    FULL: {
      id: "SHOP_FULL",
      label: "Shop full",
      description:
        "Gives employee access to the entire Settings Tab which includes: My Shop, Websites, and Devices.",
    },
    MY_SHOP_FULL: {
      id: "MY_SHOP_FULL",
      label: "Shop",
      description: "Gives employee access to the My Shop Tab in the settings.",
    },
    WEBSITES: {
      id: "WEBSITES_FULL",
      label: "Websites",
      description: "Gives employee access to the Websites Tab in the settings.",
    },
    DEVICES_FULL: {
      id: "DEVICES_FULL",
      label: "Devices",
      description: "Gives employee access to the Devices Tab in the settings.",
    },
    POINT_OF_SALE_FULL: {
      id: "POINT_OF_SALE_FULL",
      label: "POS Login",
      description: "Gives employee ability to login to the POS from any tablet.",
    },
  },
  SALES: {
    FULL: {
      id: "SALES_FULL",
      label: "Sales Full",
      description:
        "Gives employee access to the entire Sales Tab which includes: Menu, Tickets, Checkout Flow, and Discounts.",
    },
    MENU_FULL: {
      id: "MENU_FULL",
      label: "Menu Full",
      description: "Gives employee access to the Menu page under the Sales Tab.",
    },
    MENU_SYNC_FULL: {
      id: "MENU_SYNC_FULL",
      label: "Menu Sync (Alpha Feature)",
      description: "Gives employee the ability to sync menu elements across locations.",
    },
    TICKETS_FULL: {
      id: "TICKET_FULL",
      label: "Ticket Full",
      description: "Gives employee access to the Tickets page under the Sales Tab.",
    },
    CHECKOUT_FLOW_FULL: {
      id: "CHECKOUT_FLOW_FULL",
      label: "Checkout Flow",
      description: "Gives employee access to the Checkout Flow page under the Sales Tab.",
    },
    DISCOUNTS_FULL: {
      id: "DISCOUNTS_FULL",
      label: "Discounts",
      description: "Gives employee access to the Discounts page under the Sales Tab.",
    },
    REFUNDS_FULL: {
      id: "REFUNDS_FULL",
      label: "Refunds",
      description: "Allows employee to refund and void ticket on the Point of Sale.",
    },
    TAGS_FULL: {
      id: "TAGS_FULL",
      label: "Tags",
      description: "Gives employee access to the Tags page under the Sales Tab.",
    },
    TIP_MANAGEMENT_FULL: {
      id: "POS_TIP_MANAGEMENT",
      label: "Tips",
      description: "Gives employee access to Tip Management.",
    },
    // TABLE_FULL: {
    //   id: "TABLE_FULL",
    //   label: "Table Service Full",
    //   description:
    //     "Gives employee ability to edit table service configuration.",
    // },
    // ECOMMERCE_FULL: {
    //   id: "ECOMMERCE_FULL",
    //   label: "Ecommerce",
    //   description: "Gives employee ability manage ecommerce orders.",
    // },
    TABLE_EDITING: {
      id: "TABLE_EDITING",
      label: "Table Editing",
      description: "Gives employee ability to edit table configurations on the POS.",
    },
    CASH_DRAWER_DISCREPANCY: {
      id: "CASH_DRAWER_DISCREPANCY",
      label: "Cash Drawer Discrepancy",
      description: "Gives employee ability to close Cash Drawer with discrepancy larger than over/short max.",
    },
    CASH_DRAWER_VERIFYING: {
      id: "CASH_DRAWER_VERIFYING",
      label: "Cash Drawer Verification",
      description: "Gives employee ability to verify cash drawer amount.",
    },
  },
  TEAM: {
    FULL: {
      id: "TEAM_FULL",
      label: "Team Full",
      description:
        "Gives employee access to the entire Team Tab which includes: Approvals, My Team, Scheduling, Time Cards, and Memos.",
    },
    PAY_RATES_FULL: {
      id: "PAY_RATES_FULL",
      label: "Pay Rates",
      description:
        "Gives employee access to view Pay Rates. Only employees with this permission or the Admin permission will be able to access how other employees are paid. If this role (or Admin) is not enabled, all employee pay related information will be hidden.",
    },
    GIFT_CARDS_EDIT_FULL: {
      id: "GIFT_CARDS_EDIT_FULL",
      label: "Gift Card Editing",
      description:
        'Gives employee access to edit and create new gift cards. Only employees with this permission or the Admin permission will be able edit gift cards. Neither the "Marketing full" nor the "Gift Cards" permissions will give employees the ability to edit gift cards.',
    },
    APPROVALS_FULL: {
      id: "APPROVALS_FULL",
      label: "Approvals (Full Access)",
      description: "Gives employee access to the entire approval center on both mobile and web dashboard.",
    },
    APPROVALS_TIME_CARDS: {
      id: "APPROVALS_TIME_CARDS",
      label: "Time Card Approvals",
      description: "Gives employee access to the time card tab in the approval center.",
    },
    APPROVALS_TIME_OFF: {
      id: "APPROVALS_TIME_OFF",
      label: "Time Off Approvals",
      description: "Gives employee access to the time off tab in the approval center.",
    },
    APPROVALS_TRADE_REQUESTS: {
      id: "APPROVALS_TRADE_REQUESTS",
      label: "Trade Request Approvals",
      description: "Gives employee access to the trade request tab in the approval center.",
    },
    APPROVALS_OPEN_SHIFT_REQUESTS: {
      id: "APPROVALS_OPEN_SHIFT_REQUESTS",
      label: "Open Shift Approvals",
      description: "Gives employee access to the open shift tab in the approval center.",
    },
    MY_TEAM_FULL: {
      id: "MY_TEAM_FULL",
      label: "My Team",
      description: "Gives employee access to the My Team page under the Team Tab.",
    },
    ROLES_FULL: {
      id: "ROLES_FULL",
      label: "Roles",
      description: "Gives employee access to the Roles and Permissions tab.",
    },
    SCHEDULING_FULL: {
      id: "SCHEDULING_FULL",
      label: "Scheduling",
      description: "Gives employee access to the Scheduling page under the Team Tab.",
    },
    TIME_FULL: {
      id: "TIME_FULL",
      label: "Time",
      description: "Gives employee access to the Time Cards page under the Team Tab.",
    },
    TIME_READ_ONLY: {
      id: "TIME_READ_ONLY",
      label: "Time (Read Only)",
      description: "Gives employee access to view time cards but not edit them.",
    },
    POLICIES_FULL: {
      id: "POLICIES_FULL",
      label: "Time Off Policies",
      description: "Give employees access to creating and issuing time off policies.",
    },
    MEMOS_FULL: {
      id: "MEMOS_FULL",
      label: "Memos",
      description: "Gives employee access to the Memos page under the Team Tab.",
    },
    EMPLOYEE_FILES_FULL: {
      id: "EMPLOYEE_FILES_FULL",
      label: "Employee Files",
      description: "Gives employee access to viewing employee files.",
    },
    TAX_TOGGLE_FULL: {
      id: "TAX_TOGGLE_FULL",
      label: "Tax Toggle",
      description: "Gives the employee access to the tax toggle if it is PIN protected.",
    },
    DISCOUNTS_APPLY: {
      id: "DISCOUNTS_APPLY",
      label: "Apply Discounts",
      description: "Gives employee access to apply discounts on the POS if it is PIN protected.",
    },
    EDIT_MODE_FULL: {
      id: "EDIT_MODE_FULL",
      label: "Edit Mode",
      description: "Gives the employee access to edit mode on the POS if it is PIN protected.",
    },
    SETTINGS_PAGE_FULL: {
      id: "SETTINGS_PAGE_FULL",
      label: "Settings Page",
      description: "Gives the employee access to the settings page on the POS if it is PIN protected.",
    },
    ONBOARDING_FULL: {
      id: "ONBOARDING_FULL",
      label: "Onboarding",
      description: "Gives employee access to onboarding.",
    },
    I9_FULL: {
      id: "I9_FULL",
      label: "I9 Forms",
      description: "Gives employee ability to complete part 2 of I9 forms.",
    },
    MOBILE_CLOCK: {
      id: "MOBILE_CLOCK",
      label: "Mobile Clock In",
      description: "Gives employees the ability to clock in from the employee app.",
    },
    EMPLOYEE_INFO: {
      id: "EMPLOYEE_INFO",
      label: "Employee Contact Info",
      description:
        "Gives employee ability to see other employees' email addresses and phone numbers in the Hub app.",
    },
  },
  MARKETING: {
    FULL: {
      id: "MARKETING_FULL",
      label: "Marketing Full",
      description:
        "Gives employee access to the entire Marketing Tab which includes: Marketing Campaigns, Promotions & Coupons, Loyalty, Gift Cards, Patrons, Fundraisers, News, and Events.",
    },
    CAMPAIGNS_FULL: {
      id: "CAMPAIGNS_FULL",
      label: "Marketing Campaigns",
      description: "Gives employee access to the Marketing Campaigns page under the Marketing Tab.",
    },
    PROMOTIONS_FULL: {
      id: "PROMOTIONS_FULL",
      label: "Promotions & Coupons",
      description: "Gives employee access to the Promotions & Coupons page under the Marketing Tab.",
    },
    LOYALTY_FULL: {
      id: "LOYALTY_FULL",
      label: "Loyalty",
      description: "Gives employee access to the Loyalty page under the Marketing Tab.",
    },
    GIFT_CARDS_FULL: {
      id: "GIFT_CARDS_FULL",
      label: "Gift Cards",
      description: "Gives employee access to the Gift Cards page under the Marketing Tab.",
    },
    PATRONS_FULL: {
      id: "PATRONS_FULL",
      label: "Patrons",
      description: "Gives employee access to the Patrons page under the Marketing Tab.",
    },
    FUNDRAISERS_FULL: {
      id: "FUNDRAISERS_FULL",
      label: "Fundraisers",
      description: "Gives employee access to the Fundraiser page under the Marketing Tab.",
    },
    NEWS_FULL: {
      id: "NEWS_FULL",
      label: "News",
      description: "Gives employee access to the News page under the Marketing Tab.",
    },
    EVENTS_FULL: {
      id: "EVENTS_FULL",
      label: "Events",
      description: "Gives employee access to the Events page under the Marketing Tab.",
    },
  },
  OPERATIONS: {
    FULL: {
      id: "OPERATIONS_FULL",
      label: "Operations Full",
      description:
        "Gives employee access to the entire Operations Tab which includes: Cash Management, Ingredients, Labels, Checklists, Inventory, and Customer Chats.",
    },
    CASH_FULL: {
      id: "CASH_FULL",
      label: "Cash",
      description: "Gives employee access to the Cash Drawers page under the Operations Tab.",
    },
    TIPS_FULL: {
      id: "TIPS_FULL",
      label: "Tips",
      description: "Gives employee access to the Tip Buckets page under the Operations Tab.",
    },
    INGREDIENTS_FULL: {
      id: "INGREDIENTS_FULL",
      label: "Ingredients",
      description: "Gives employee access to the Ingredients page under the Operations Tab.",
    },
    LABELS_FULL: {
      id: "LABELS_FULL",
      label: "Labels",
      description: "Gives employee access to the Labels page under the Operations Tab.",
    },
    CHECKLISTS_FULL: {
      id: "CHECKLISTS_FULL",
      label: "Checklists",
      description: "Gives employee access to the Checklists page under the Operations Tab.",
    },
    INVENTORY_FULL: {
      id: "INVENTORY_FULL",
      label: "Inventory",
      description: "Gives employee access to the Inventory page under the Operations Tab.",
    },
    INVOICES_FULL: {
      id: "INVOICES_FULL",
      label: "Invoices",
      description: "Create and send invoices to customers.",
    },
    SUBSCRIPTIONS_FULL: {
      id: "SUBSCRIPTIONS_FULL",
      label: "Subscriptions",
      description: "Create and send subscriptions.",
    },
    SUPPORT_TEXTS_FULL: {
      id: "SUPPORT_TEXTS_FULL",
      label: "Support Texts",
      description: "Gives employee access to the Customer Chats page under the Operations Tab.",
    },
    SUPPLY_CHAIN_FULL: {
      id: "SUPPLY_CHAIN_FULL",
      label: "Supply Chain",
      description: "Gives employee access to the Supply Chain page under the Operations Tab.",
    },
    RECIPES_FULL: {
      id: "RECIPES_FULL",
      label: "Recipes",
      description: "Gives employee access to the Recipes page under the Operations Tab.",
    },
    VENDORS_FULL: {
      id: "VENDORS_FULL",
      label: "Vendors",
      description: "Gives employee access to the Vendors page under the Operations Tab.",
    },
    PURCHASE_ORDERS_FULL: {
      id: "PURCHASE_ORDERS_FULL",
      label: "Purchase Orders",
      description: "Gives employee access to the Purchase Orders page under the Operations Tab.",
    },
  },
  REPORTS: {
    FULL: {
      id: "REPORTS_FULL",
      label: "Reports Full",
      description:
        "Gives employee access to the entire Reports Tab which includes: Sales, Payment Methods, All Product Sales, Modifier Sales, Category Sales, Platform Sales, Ticket Type Sales, Gift Card Sales, and Time Card Reports.",
    },
    SALES_REPORT: {
      id: "SALES_REPORT",
      label: "Sales Summary Report",
      description: "Gives employee access to the Sales page under the Reports Tab.",
    },
    PAYMENT_METHODS_REPORT: {
      id: "PAYMENT_METHODS_REPORT",
      label: "Payment Methods Report",
      description: "Gives employee access to the Payment Methods page under the Reports Tab.",
    },
    PRODUCTS_REPORT: {
      id: "PRODUCTS_REPORT",
      label: "Product(s) Report",
      description: "Gives employee access to the All Product Sales page under the Reports Tab.",
    },
    MODIFIER_REPORT: {
      id: "MODIFIER_REPORT",
      label: "Modifier Report",
      description: "Gives employee access to the Modifier Sales page under the Reports Tab.",
    },
    INGREDIENT_REPORT: {
      id: "INGREDIENT_REPORT",
      label: "Ingredients Report",
      description: "Gives employee access to the Ingredient Sales page under the Reports Tab.",
    },
    INVENTORY_REPORT: {
      id: "INVENTORY_REPORT",
      label: "Inventory Report",
      description: "Gives employee access to the Inventory Sales page under the Reports Tab.",
    },
    CATEGORY_REPORT: {
      id: "CATEGORY_REPORT",
      label: "Category Sales Report",
      description: "Gives employee access to the Category Sales page under the Reports Tab.",
    },
    PLATFORM_REPORT: {
      id: "PLATFORM_REPORT",
      label: "Platform Sales Report",
      description: "Gives employee access to the Platform Sales page under the Reports Tab.",
    },
    ORDER_TYPE_REPORT: {
      id: "ORDER_TYPE_REPORT",
      label: "Ticket Type Sales Report",
      description: "Gives employee access to the Ticket Type Sales page under the Reports Tab.",
    },
    GIFT_CARDS_REPORT: {
      id: "GIFT_CARDS_REPORT",
      label: "Gift Card Sales Report",
      description: "Gives employee access to the Gift Card Sales page under the Reports Tab.",
    },
    TIME_CARDS_REPORT: {
      id: "TIME_CARDS_REPORT",
      label: "Time Cards Report",
      description: "Gives employee access to the Time Card Reports page under the Reports Tab.",
    },
    TAXES_REPORT: {
      id: "TAXES_REPORT",
      label: "Taxes Report",
      description: "Gives employee access to the Taxes Report under the Reports Tab.",
    },
    DISCOUNTS_REPORT: {
      id: "DISCOUNTS_REPORT",
      label: "Discounts Report",
      description: "Gives employee access to the Discounts Report under the Reports Tab.",
    },
    LABOR_VS_SALES: {
      id: "LABOR_VS_SALES_REPORT",
      label: "Labor vs Sales Report",
      description: "Gives employee access to Labor vs Sales Report under the Reports Tab",
    },
    CASH_DRAWERS_REPORT: {
      id: "CASH_DRAWERS_REPORT",
      label: "Cash Drawers Report",
      description: "Gives employee access to Cash Drawers Report under the Reports Tab",
    },
    CASH_FLOW_REPORT: {
      id: "CASH_FLOW_REPORT",
      label: "Cash Flow Report",
      description: "Gives employee access to Cash Flow Report under the Reports Tab",
    },
    CUSTOM_PAYMENT_TYPE_TIPS_REPORT: {
      id: "CUSTOM_PAYMENT_TYPE_TIPS_REPORT",
      label: "Custom Payment Type Tips Report",
      description: "Gives employee access to Custom Payment Type Tips Report under the Reports Tab",
    },
    INVOICING_REPORT: {
      id: "INVOICING_REPORT",
      label: "Invoicing Report",
      description: "Gives employee access to Invoicing Report under the Reports Tab",
    },
    CHECKLISTS_REPORT: {
      id: "CHECKLISTS_REPORT",
      label: "Checklists Report",
      description: "Gives employee access to Checklists Report under the Reports Tab",
    },
  },
  FINANCE: {
    FULL: {
      id: "FINANCE_FULL",
      label: "Finance Full",
      description:
        "Gives employee access to the entire Finance Tab which includes: Banking, Accounting and Payroll.",
    },
    BANKING_FULL: {
      id: "BANKING_FULL",
      label: "Instant Cash & Payouts",
      description: "Gives employee access to the Banking and Billing pages under the Finance Tab.",
    },
    ACCOUNTING_FULL: {
      id: "ACCOUNTING_FULL",
      label: "Accounting",
      description: "Gives employee access to the Accounting page under the Finance Tab.",
    },
    PAYROLL_FULL: {
      id: "PAYROLL_FULL",
      label: "Payroll",
      description: "Gives employee access to the Payroll page under the Finance Tab.",
    },
  },
};

export const PERMISSION_ARRAY = Object.values(PERMISSIONS).reduce((dict, item) => {
  return {...dict, ...item};
}, {});

export const DOCUMENT_FIELDS = [
  {
    TYPE: "SIGNATURE",
    LABEL: "Signature",
    ICON: <FontAwesomeIcon icon="fa-solid fa-file-signature" />,
    ASSIGNEE: null,
  },
  {
    TYPE: "INITIALS",
    LABEL: "Initials",
    ICON: <FontAwesomeIcon icon="fa-solid fa-signature" />,
    ASSIGNEE: null,
  },
  {
    TYPE: "DATE_SIGNED",
    LABEL: "Date Signed",
    ICON: <FontAwesomeIcon icon="fa-solid fa-calendar-days" />,
    ASSIGNEE: null,
  },
  {
    TYPE: "EMAIL",
    LABEL: "E-mail",
    ICON: <FontAwesomeIcon icon="fa-solid fa-envelope" />,
    ASSIGNEE: null,
  },
  {
    TYPE: "PHONE",
    LABEL: "Phone",
    ICON: <FontAwesomeIcon icon={"fa-solid fa-phone"} />,
    ASSIGNEE: null,
  },
  {
    TYPE: "COMPANY",
    LABEL: "Company",
    ICON: <FontAwesomeIcon icon="fa-solid fa-building" />,
    ASSIGNEE: null,
  },
  {
    TYPE: "TEXTBOX",
    LABEL: "Textbox",
    ICON: <FontAwesomeIcon icon="fa-solid fa-text-size" />,
    ASSIGNEE: null,
  },
  {
    TYPE: "CHECKBOX",
    LABEL: "Checkbox",
    ICON: <FontAwesomeIcon icon="fa-solid fa-square-check" />,
    OPTIONS: [],
    ASSIGNEE: null,
  },
  {
    TYPE: "DROPDOWN",
    LABEL: "Dropdown",
    ICON: <FontAwesomeIcon icon="fa-solid fa-list-dropdown" />,
    OPTIONS: [],
    ASSIGNEE: null,
  },
];

export const DOCUMENT_FIELD_ICON_MAP = {
  SIGNATURE: <FontAwesomeIcon icon="fa-solid fa-file-signature" />,
  INITIALS: <FontAwesomeIcon icon={"fa-solid fa-signature"} />,
  DATE_SIGNED: <FontAwesomeIcon icon={"fa-solid fa-calendar-days"} />,
  EMAIL: <FontAwesomeIcon icon={"fa-solid fa-envelope"} />,
  COMPANY: <FontAwesomeIcon icon={"fa-solid fa-building"} />,
  TEXTBOX: <FontAwesomeIcon icon="fa-solid fa-text-size" />,
  CHECKBOX: <FontAwesomeIcon icon="fa-solid fa-square-check" />,
  DROPDOWN: <FontAwesomeIcon icon="fa-solid fa-list-dropdown" />,
  RADIO_GROUP: <FontAwesomeIcon icon="fa-solid fa-list-radio" />,
};

export const DOCUMENT_FIELD_DEFAULTS = {
  HEIGHT: 33,
  WIDTH: 100,
  X_COORDINATE: 0,
  Y_COORDINATE: 0,
};

export const I9_PREFILL_FIELDS = {
  lastName: {
    X_COORDINATE: 35,
    Y_COORDINATE: 172,
    WIDTH: 173,
    HEIGHT: 31,
    KEY: "lastName",
  },
  firstName: {
    X_COORDINATE: 207,
    Y_COORDINATE: 172,
    WIDTH: 152,
    HEIGHT: 31,
  },
  middleInitial: {
    X_COORDINATE: 357,
    Y_COORDINATE: 172,
    WIDTH: 65,
    HEIGHT: 31,
    KEY: "middleInitial",
  },
  otherLastNames: {
    X_COORDINATE: 421,
    Y_COORDINATE: 172,
    WIDTH: 156,
    HEIGHT: 31,
    KEY: "otherLastNames",
  },
  address: {
    X_COORDINATE: 35,
    Y_COORDINATE: 202,
    WIDTH: 206,
    HEIGHT: 31,
    KEY: "address",
  },
  aptNumber: {
    X_COORDINATE: 240,
    Y_COORDINATE: 202,
    WIDTH: 63,
    HEIGHT: 31,
    KEY: "aptNumber",
  },
  cityOrTown: {
    X_COORDINATE: 301,
    Y_COORDINATE: 202,
    WIDTH: 63,
    HEIGHT: 31,
    KEY: "cityOrTown",
  },
  state: {
    X_COORDINATE: 451,
    Y_COORDINATE: 202,
    WIDTH: 41,
    HEIGHT: 31,
    KEY: "state",
  },
  zip: {
    X_COORDINATE: 491,
    Y_COORDINATE: 202,
    WIDTH: 84,
    HEIGHT: 31,
    KEY: "zip",
  },
  dateOfBirth: {
    X_COORDINATE: 35,
    Y_COORDINATE: 232,
    WIDTH: 114,
    HEIGHT: 36,
    KEY: "dateOfBirth",
  },
  ssn1: {
    X_COORDINATE: 153,
    Y_COORDINATE: 246,
    WIDTH: 32,
    HEIGHT: 18,
    KEY: "ssn1",
  },
  ssn2: {
    X_COORDINATE: 194,
    Y_COORDINATE: 246,
    WIDTH: 22,
    HEIGHT: 18,
    KEY: "ssn2",
  },
  ssn3: {
    X_COORDINATE: 226,
    Y_COORDINATE: 246,
    WIDTH: 39,
    HEIGHT: 18,
    KEY: "ssn3",
  },
  email: {
    X_COORDINATE: 267,
    Y_COORDINATE: 232,
    WIDTH: 175,
    HEIGHT: 36,
    KEY: "email",
  },
  phone: {
    X_COORDINATE: 440,
    Y_COORDINATE: 232,
    WIDTH: 138,
    HEIGHT: 36,
    KEY: "phone",
  },
  CITIZEN: {
    X_COORDINATE: 40,
    Y_COORDINATE: 319,
    WIDTH: 10,
    HEIGHT: 10,
    KEY: "CITIZEN",
  },
  NONCITIZEN_NATIONAL: {
    X_COORDINATE: 40,
    Y_COORDINATE: 336,
    WIDTH: 10,
    HEIGHT: 10,
    KEY: "NONCITIZEN_NATIONAL",
  },
  PERMANENT_RESIDENT: {
    X_COORDINATE: 40,
    Y_COORDINATE: 352,
    WIDTH: 10,
    HEIGHT: 10,
    KEY: "PERMANENT_RESIDENT",
  },
  AUTHORIZED_ALIEN: {
    X_COORDINATE: 40,
    Y_COORDINATE: 369,
    WIDTH: 10,
    HEIGHT: 10,
    KEY: "AUTHORIZED_ALIEN",
  },
  expiration: {
    X_COORDINATE: 355,
    Y_COORDINATE: 368,
    WIDTH: 69,
    HEIGHT: 14,
    KEY: "expiration",
  },
  registrationNumber: {
    X_COORDINATE: 226,
    Y_COORDINATE: 420,
    HEIGHT: 17,
    WIDTH: 141,
  },
  authorizedAlienNumber: {
    X_COORDINATE: 227,
    Y_COORDINATE: 420,
    WIDTH: 138,
    HEIGHT: 20,
    KEY: "authorizedAlienNumber",
  },
  admissionNumber: {
    X_COORDINATE: 174,
    Y_COORDINATE: 439,
    WIDTH: 191,
    HEIGHT: 23,
    KEY: "admissionNumber",
  },
  passportNumber: {
    X_COORDINATE: 160,
    Y_COORDINATE: 464,
    WIDTH: 204,
    HEIGHT: 23,
    KEY: "passportNumber",
  },
  countryOfIssuance: {
    X_COORDINATE: 143,
    Y_COORDINATE: 488,
    WIDTH: 221,
    HEIGHT: 15,
    KEY: "countryOfIssuance",
  },
};

export const I9_SPANISH_PREFILL_FIELDS = {
  lastName: {
    HEIGHT: 27,
    WIDTH: 133,
    X_COORDINATE: 36,
    Y_COORDINATE: 180,
    KEY: "lastName",
    PAGE: 1,
  },
  firstName: {
    HEIGHT: 28,
    WIDTH: 128,
    X_COORDINATE: 167,
    Y_COORDINATE: 179,
    KEY: "firstName",
    PAGE: 1,
  },
  middleInitial: {
    WIDTH: 146,
    HEIGHT: 25,
    X_COORDINATE: 292,
    Y_COORDINATE: 181,
    KEY: "middleInitial",
    PAGE: 1,
  },
  otherLastNames: {
    WIDTH: 138,
    HEIGHT: 25,
    X_COORDINATE: 438,
    Y_COORDINATE: 180,
    KEY: "otherLastNames",
    PAGE: 1,
  },
  address: {
    WIDTH: 181,
    HEIGHT: 26,
    X_COORDINATE: 35,
    Y_COORDINATE: 205,
    KEY: "address",
    PAGE: 1,
  },
  aptNumber: {
    WIDTH: 159,
    HEIGHT: 26,
    X_COORDINATE: 214,
    Y_COORDINATE: 206,
    KEY: "aptNumber",
    PAGE: 1,
  },
  cityOrTown: {
    WIDTH: 78,
    HEIGHT: 26,
    X_COORDINATE: 372,
    Y_COORDINATE: 206,
    KEY: "cityOrTown",
    PAGE: 1,
  },
  state: {
    X_COORDINATE: 451,
    Y_COORDINATE: 206,
    WIDTH: 41,
    HEIGHT: 26,
    KEY: "state",
    PAGE: 1,
  },
  zip: {
    WIDTH: 71,
    HEIGHT: 26,
    X_COORDINATE: 504,
    Y_COORDINATE: 206,
    KEY: "zip",
    PAGE: 1,
  },
  dateOfBirth: {
    WIDTH: 128,
    HEIGHT: 28,
    X_COORDINATE: 35,
    Y_COORDINATE: 230,
    KEY: "dateOfBirth",
    PAGE: 1,
  },
  ssn1: {
    WIDTH: 36,
    HEIGHT: 14,
    X_COORDINATE: 167,
    Y_COORDINATE: 243,
    KEY: "ssn1",
    PAGE: 1,
  },
  ssn2: {
    WIDTH: 25,
    HEIGHT: 14,
    X_COORDINATE: 202,
    Y_COORDINATE: 243,
    KEY: "ssn2",
    PAGE: 1,
  },
  ssn3: {
    WIDTH: 49,
    HEIGHT: 14,
    X_COORDINATE: 225,
    Y_COORDINATE: 243,
    KEY: "ssn3",
    PAGE: 1,
  },
  email: {
    WIDTH: 156,
    HEIGHT: 28,
    X_COORDINATE: 282,
    Y_COORDINATE: 230,
    KEY: "email",
    PAGE: 1,
  },
  phone: {
    WIDTH: 140,
    HEIGHT: 28,
    X_COORDINATE: 437,
    Y_COORDINATE: 231,
    KEY: "phone",
    PAGE: 1,
  },
  CITIZEN: {
    WIDTH: 10,
    HEIGHT: 10,
    X_COORDINATE: 181,
    Y_COORDINATE: 276,
    KEY: "CITIZEN",
    PAGE: 1,
  },
  NONCITIZEN_NATIONAL: {
    WIDTH: 10,
    HEIGHT: 10,
    X_COORDINATE: 181,
    Y_COORDINATE: 289,
    KEY: "NONCITIZEN_NATIONAL",
    PAGE: 1,
  },
  PERMANENT_RESIDENT: {
    X_COORDINATE: 181,
    Y_COORDINATE: 300,
    WIDTH: 10,
    HEIGHT: 10,
    KEY: "PERMANENT_RESIDENT",
    PAGE: 1,
  },
  AUTHORIZED_ALIEN: {
    X_COORDINATE: 182,
    Y_COORDINATE: 317,
    WIDTH: 10,
    HEIGHT: 10,
    KEY: "AUTHORIZED_ALIEN",
    PAGE: 1,
  },
  expiration: {
    WIDTH: 103,
    HEIGHT: 19,
    X_COORDINATE: 473,
    Y_COORDINATE: 318,
    KEY: "expiration",
    PAGE: 1,
  },
  registrationNumber: {
    WIDTH: 80,
    HEIGHT: 14,
    X_COORDINATE: 173,
    Y_COORDINATE: 358,
    KEY: "registrationNumber",
    PAGE: 1,
  },
  authorizedAlienNumber: {
    WIDTH: 80,
    HEIGHT: 14,
    X_COORDINATE: 173,
    Y_COORDINATE: 358,
    KEY: "authorizedAlienNumber",
    PAGE: 1,
  },
  admissionNumber: {
    WIDTH: 129,
    HEIGHT: 14,
    X_COORDINATE: 263,
    Y_COORDINATE: 359,
    KEY: "admissionNumber",
    PAGE: 1,
  },
  passportNumber: {
    WIDTH: 83,
    HEIGHT: 13,
    X_COORDINATE: 402,
    Y_COORDINATE: 359,
    KEY: "passportNumber",
    PAGE: 1,
  },
  countryOfIssuance: {
    WIDTH: 91,
    HEIGHT: 11,
    X_COORDINATE: 486,
    Y_COORDINATE: 361,
    KEY: "countryOfIssuance",
    PAGE: 1,
  },
};

export const I9_PART_2_PREFILL_FIELDS = {
  employeeLastName: {
    X_COORDINATE: 162,
    Y_COORDINATE: 126,
    WIDTH: 150,
    HEIGHT: 27,
  },
  employeeMiddleInitial: {
    X_COORDINATE: 431,
    Y_COORDINATE: 126,
    WIDTH: 28,
    HEIGHT: 27,
  },
  employeeFirstName: {
    X_COORDINATE: 311,
    Y_COORDINATE: 126,
    WIDTH: 121,
    HEIGHT: 27,
  },
  employeeImmigrationStatus: {
    X_COORDINATE: 455,
    Y_COORDINATE: 126,
    WIDTH: 123,
    HEIGHT: 27,
  },
  listADocumentTitle: {
    X_COORDINATE: 37,
    Y_COORDINATE: 172,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listADocumentIssuingAuthority: {
    X_COORDINATE: 37,
    Y_COORDINATE: 195,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listADocumentNumber: {
    X_COORDINATE: 37,
    Y_COORDINATE: 217,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listADocumentExpirationDate: {
    X_COORDINATE: 37,
    Y_COORDINATE: 239,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listBDocumentTitle: {
    X_COORDINATE: 219,
    Y_COORDINATE: 172,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listBDocumentIssuingAuthority: {
    X_COORDINATE: 219,
    Y_COORDINATE: 195,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listBDocumentNumber: {
    X_COORDINATE: 219,
    Y_COORDINATE: 217,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listBDocumentExpirationDate: {
    X_COORDINATE: 219,
    Y_COORDINATE: 239,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listCDocumentTitle: {
    X_COORDINATE: 404,
    Y_COORDINATE: 172,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listCDocumentIssuingAuthority: {
    X_COORDINATE: 404,
    Y_COORDINATE: 195,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listCDocumentNumber: {
    X_COORDINATE: 404,
    Y_COORDINATE: 217,
    WIDTH: 175,
    HEIGHT: 27,
  },
  listCDocumentExpirationDate: {
    X_COORDINATE: 404,
    Y_COORDINATE: 239,
    WIDTH: 175,
    HEIGHT: 27,
  },
  employeesFirstDay: {
    X_COORDINATE: 285,
    Y_COORDINATE: 474,
    WIDTH: 81,
    HEIGHT: 30,
  },
  employerLastName: {
    X_COORDINATE: 36,
    Y_COORDINATE: 532,
    WIDTH: 185,
    HEIGHT: 27,
    KEY: "employerLastName",
  },
  employerTitle: {
    X_COORDINATE: 378,
    Y_COORDINATE: 503,
    WIDTH: 201,
    HEIGHT: 30,
  },
  employerFirstName: {
    X_COORDINATE: 219,
    Y_COORDINATE: 532,
    WIDTH: 287,
    HEIGHT: 27,
  },

  employerBusinessName: {
    X_COORDINATE: 404,
    Y_COORDINATE: 532,
    WIDTH: 174,
    HEIGHT: 27,
  },

  employerAddress: {
    X_COORDINATE: 37,
    Y_COORDINATE: 558,
    WIDTH: 274,
    HEIGHT: 28,
  },
  employerCityOrTown: {
    X_COORDINATE: 311,
    Y_COORDINATE: 558,
    WIDTH: 135,
    HEIGHT: 28,
  },
  employerState: {
    X_COORDINATE: 445,
    Y_COORDINATE: 558,
    WIDTH: 40,
    HEIGHT: 28,
  },
  employerZip: {
    X_COORDINATE: 483,
    Y_COORDINATE: 558,
    WIDTH: 96,
    HEIGHT: 28,
  },
};

export const I9_PART_2_SPANISH_PREFILL_FIELDS = {
  employeeLastName: {
    X_COORDINATE: 162,
    Y_COORDINATE: 126,
    WIDTH: 150,
    HEIGHT: 27,
    PAGE: 2,
  },
  employeeMiddleInitial: {
    X_COORDINATE: 431,
    Y_COORDINATE: 126,
    WIDTH: 28,
    HEIGHT: 27,
    PAGE: 2,
  },
  employeeFirstName: {
    X_COORDINATE: 311,
    Y_COORDINATE: 126,
    WIDTH: 121,
    HEIGHT: 27,
    PAGE: 2,
  },
  employeeImmigrationStatus: {
    X_COORDINATE: 455,
    Y_COORDINATE: 126,
    WIDTH: 123,
    HEIGHT: 27,
    PAGE: 2,
  },
  listADocumentTitle: {
    WIDTH: 138,
    HEIGHT: 20,
    X_COORDINATE: 126,
    Y_COORDINATE: 147,
    PAGE: 2,
  },
  listADocumentIssuingAuthority: {
    WIDTH: 138,
    HEIGHT: 20,
    X_COORDINATE: 125,
    Y_COORDINATE: 165,
    PAGE: 2,
  },
  listADocumentNumber: {
    WIDTH: 138,
    HEIGHT: 20,
    X_COORDINATE: 125,
    Y_COORDINATE: 182,
    PAGE: 2,
  },
  listADocumentExpirationDate: {
    WIDTH: 138,
    HEIGHT: 20,
    X_COORDINATE: 125,
    Y_COORDINATE: 200,
    PAGE: 2,
  },
  listBDocumentTitle: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 275,
    Y_COORDINATE: 146,
    PAGE: 2,
  },
  listBDocumentIssuingAuthority: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 276,
    Y_COORDINATE: 165,
    PAGE: 2,
  },
  listBDocumentNumber: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 276,
    Y_COORDINATE: 182,
    PAGE: 2,
  },
  listBDocumentExpirationDate: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 276,
    Y_COORDINATE: 200,
    PAGE: 2,
  },
  listCDocumentTitle: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 425,
    Y_COORDINATE: 146,
    PAGE: 2,
  },
  listCDocumentIssuingAuthority: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 425,
    Y_COORDINATE: 164,
    PAGE: 2,
  },
  listCDocumentNumber: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 425,
    Y_COORDINATE: 182,
    PAGE: 2,
  },
  listCDocumentExpirationDate: {
    WIDTH: 151,
    HEIGHT: 20,
    X_COORDINATE: 425,
    Y_COORDINATE: 200,
    PAGE: 2,
  },
  employeesFirstDay: {
    WIDTH: 119,
    HEIGHT: 16,
    X_COORDINATE: 456,
    Y_COORDINATE: 381,
    PAGE: 2,
  },
  employerLastName: {
    WIDTH: 83,
    HEIGHT: 22,
    X_COORDINATE: 35,
    Y_COORDINATE: 407,
    KEY: "employerLastName",
    PAGE: 2,
  },
  employerTitle: {
    WIDTH: 93,
    HEIGHT: 22,
    X_COORDINATE: 196,
    Y_COORDINATE: 407,
    PAGE: 2,
  },
  employerFirstName: {
    WIDTH: 83,
    HEIGHT: 22,
    X_COORDINATE: 111,
    Y_COORDINATE: 407,
    PAGE: 2,
  },

  employerBusinessName: {
    WIDTH: 180,
    HEIGHT: 21,
    X_COORDINATE: 36,
    Y_COORDINATE: 444,
    PAGE: 2,
  },

  employerAddress: {
    WIDTH: 180,
    HEIGHT: 21,
    X_COORDINATE: 215,
    Y_COORDINATE: 444,
    PAGE: 2,
  },
  employerCityOrTown: {
    WIDTH: 89,
    HEIGHT: 21,
    X_COORDINATE: 395,
    Y_COORDINATE: 441,
    PAGE: 2,
  },
  employerState: {
    WIDTH: 61,
    HEIGHT: 22,
    X_COORDINATE: 484,
    Y_COORDINATE: 440,
    PAGE: 2,
  },
  employerZip: {
    WIDTH: 70,
    HEIGHT: 22,
    X_COORDINATE: 545,
    Y_COORDINATE: 441,
    PAGE: 2,
  },
};

export const W4_EMPLOYEE_PREFILL_FIELDS = {
  firstName: {
    WIDTH: 181,
    HEIGHT: 25,
    X_COORDINATE: 93,
    Y_COORDINATE: 84,
  },
  lastName: {
    WIDTH: 204,
    HEIGHT: 25,
    X_COORDINATE: 273,
    Y_COORDINATE: 84,
  },
  ssn: {
    WIDTH: 102,
    HEIGHT: 25,
    X_COORDINATE: 475,
    Y_COORDINATE: 84,
  },
  address: {
    WIDTH: 383,
    HEIGHT: 25,
    X_COORDINATE: 93,
    Y_COORDINATE: 107,
  },
};

export const W4_EMPLOYEE_PREFILL_FIELDS_SPANISH = {
  firstName: {
    WIDTH: 174,
    HEIGHT: 24,
    X_COORDINATE: 93,
    Y_COORDINATE: 96,
  },
  lastName: {
    WIDTH: 181,
    HEIGHT: 26,
    X_COORDINATE: 265,
    Y_COORDINATE: 95,
  },
  ssn: {
    WIDTH: 130,
    HEIGHT: 25,
    X_COORDINATE: 446,
    Y_COORDINATE: 95,
  },
  address: {
    WIDTH: 354,
    HEIGHT: 33,
    X_COORDINATE: 93,
    Y_COORDINATE: 118,
  },
};

export const W4_COMPANY_PREFILL_FIELDS = {
  employerName: {
    WIDTH: 294,
    HEIGHT: 22,
    X_COORDINATE: 94,
    Y_COORDINATE: 657,
  },
  employerAddress: {
    WIDTH: 294,
    HEIGHT: 22,
    X_COORDINATE: 94,
    Y_COORDINATE: 674,
  },
  firstDateOfEmployment: {
    WIDTH: 81,
    HEIGHT: 27,
    X_COORDINATE: 388,
    Y_COORDINATE: 670,
  },
  ein: {
    WIDTH: 110,
    HEIGHT: 27,
    X_COORDINATE: 467,
    Y_COORDINATE: 669,
  },
};

export const W4_COMPANY_PREFILL_FIELDS_SPANISH = {
  employerName: {
    WIDTH: 295,
    HEIGHT: 21,
    X_COORDINATE: 93,
    Y_COORDINATE: 707,
  },
  employerAddress: {
    WIDTH: 294,
    HEIGHT: 19,
    X_COORDINATE: 94,
    Y_COORDINATE: 725,
  },
  firstDateOfEmployment: {
    WIDTH: 81,
    HEIGHT: 27,
    X_COORDINATE: 388,
    Y_COORDINATE: 717,
  },
  ein: {
    WIDTH: 110,
    HEIGHT: 27,
    X_COORDINATE: 467,
    Y_COORDINATE: 717,
  },
};

export const W4_MARRIED_CUTOFFS = [
  [
    0, 0, 85000, 85000, 100000, 102000, 102000, 102000, 102000, 187000, 204000, 204000, 204000, 204000,
    204000, 204000, 297000, 314000,
  ],
  [
    0, 93000, 185000, 200000, 220000, 222000, 222000, 222000, 222000, 407000, 444000, 444000, 444000, 444000,
    444000, 444000, 647000, 684000,
  ],
  [
    85000, 185000, 292000, 312000, 332000, 334000, 334000, 334000, 417000, 619000, 676000, 676000, 676000,
    676000, 676000, 676000, 989000, 1046000,
  ],
  [
    85000, 200000, 312000, 332000, 352000, 354000, 354000, 354000, 537000, 739000, 816000, 816000, 816000,
    816000, 816000, 855000, 1239000, 1316000,
  ],
  [
    100000, 220000, 332000, 352000, 372000, 374000, 374000, 472000, 657000, 859000, 956000, 956000, 956000,
    956000, 956000, 1075000, 1489000, 1586000,
  ],
  [
    102000, 222000, 334000, 354000, 374000, 376000, 475000, 575000, 760000, 961000, 1078000, 1078000, 1078000,
    1078000, 1078000, 1277000, 1722000, 1839000,
  ],
  [
    102000, 222000, 334000, 354000, 374000, 475000, 575000, 675000, 860000, 1061000, 1198000, 1198000,
    1198000, 1198000, 1198000, 1477000, 1952000, 2089000,
  ],
  [
    102000, 222000, 334000, 354000, 472000, 575000, 675000, 775000, 960000, 1166000, 1318000, 1318000,
    1318000, 1318000, 1347000, 1677000, 2182000, 2339000,
  ],
  [
    102000, 222000, 334000, 452000, 572000, 675000, 775000, 875000, 1060000, 1286000, 1438000, 1438000,
    1438000, 1438000, 1547000, 1877000, 2412000, 2589000,
  ],
  [
    102000, 222000, 432000, 552000, 672000, 775000, 875000, 975000, 1160000, 1406000, 1558000, 1558000,
    1558000, 1587000, 1747000, 2077000, 2642000, 2839000,
  ],
  [
    102000, 320000, 532000, 652000, 772000, 875000, 975000, 1075000, 1260000, 1526000, 1678000, 1678000,
    1678000, 1787000, 1947000, 2277000, 2872000, 3089000,
  ],
  [
    187000, 407000, 619000, 739000, 859000, 961000, 1061000, 1161000, 1346000, 1633000, 1785000, 1785000,
    1814000, 1974000, 2134000, 2464000, 3088000, 3325000,
  ],
];

export const W4_INDIVIDUAL_CUTOFFS = [
  [
    31000, 89000, 102000, 102000, 171000, 187000, 187000, 204000, 204000, 204000, 272000, 290000, 297000,
    314000,
  ],
  [
    89000, 163000, 175000, 175000, 34500, 360000, 372000, 397000, 397000, 397000, 545000, 593000, 601000,
    601000, 638000,
  ],
  [
    102000, 175000, 188000, 272000, 457000, 473000, 506000, 530000, 530000, 561000, 758000, 836000, 844000,
    844000, 901000,
  ],
  [
    102000, 175000, 272000, 372000, 557000, 586000, 626000, 650000, 650000, 761000, 958000, 1066000, 1074000,
    1074000, 1151000,
  ],
  [
    102000, 260000, 372000, 472000, 657000, 706000, 746000, 770000, 770000, 961000, 1158000, 1296000, 1304000,
    1304000, 1401000,
  ],
  [
    186000, 360000, 472000, 572000, 770000, 826000, 866000, 890000, 961000, 1161000, 1387000, 1526000,
    1534000, 1534000, 1651000,
  ],
  [
    187000, 360000, 473000, 573000, 791000, 846000, 886000, 911000, 1061000, 1261000, 1518000, 1657000,
    1664000, 1664000, 1801000,
  ],
  [
    187000, 360000, 473000, 589000, 811000, 866000, 906000, 961000, 1161000, 1375000, 1648000, 1787000,
    1794000, 1794000, 1951000,
  ],
  [
    187000, 360000, 489000, 609000, 831000, 886000, 926000, 1061000, 1261000, 1505000, 1778000, 1917000,
    1924000, 1924000, 2101000,
  ],
  [
    187000, 376000, 509000, 629000, 851000, 906000, 946000, 1161000, 1361000, 1635000, 1908000, 2047000,
    2054000, 2054000, 2251000,
  ],
  [
    203000, 396000, 529000, 649000, 871000, 926000, 1043000, 1261000, 1490000, 1765000, 2038000, 2177000,
    2184000, 2184000, 2401000,
  ],
  [
    204000, 397000, 530000, 650000, 872000, 928000, 1124000, 1343000, 1602000, 1877000, 2149000, 2288000,
    2296000, 2296000, 2533000,
  ],
];

export const W4_HEAD_OF_HOUSEHOLD_CUTOFFS = [
  [0, 62000, 86000, 102000, 102000, 150000, 187000, 204000, 204000, 204000, 219000, 272000, 297000, 314000],
  [
    62000, 163000, 206000, 222000, 222000, 370000, 407000, 444000, 444000, 444000, 539000, 619000, 647000,
    684000,
  ],
  [
    86000, 206000, 249000, 265000, 313000, 513000, 569000, 607000, 607000, 607000, 782000, 892000, 920000,
    977000,
  ],
  [102000, 222000, 265000, 344000, 529000, 748000, 825000, 863000, 863000, 998000, 1138000, 1166000, 1243000],
  [
    102000, 222000, 265000, 344000, 529000, 748000, 825000, 863000, 863000, 998000, 1198000, 1368000, 1396000,
    1493000,
  ],
  [
    102000, 222000, 328000, 444000, 629000, 868000, 945000, 983000, 998000, 1198000, 1406000, 1598000,
    1626000, 1743000,
  ],
  [
    102000, 285000, 428000, 544000, 748000, 988000, 1065000, 1103000, 1198000, 1398000, 1636000, 1828000,
    1856000, 1993000,
  ],
  [
    165000, 385000, 528000, 646000, 868000, 1108000, 1185000, 1223000, 1398000, 1598000, 1866000, 2058000,
    2086000, 2243000,
  ],
  [
    // 8
    187000, 407000, 552000, 688000, 910000, 1150000, 1226000, 1319000, 1519000, 1742000, 2017000, 2209000,
    2238000, 2415000,
  ],
  [
    // 9
    187000, 409000, 572000, 708000, 930000, 1170000, 1246000, 1419000, 1619000, 1872000, 2147000, 2339000,
    2368000, 2565000,
  ],
  [
    189000, 429000, 592000, 728000, 950000, 1190000, 1287000, 1519000, 1727000, 2002000, 2277000, 2469000,
    2498000, 2715000,
  ],
  [
    204000, 444000, 607000, 743000, 965000, 1205000, 1382000, 1615000, 1853000, 2128000, 2403000, 2595000,
    2623000, 2860000,
  ],
];

export const ISO_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const REGULAR_STAGE_VERBIAGE = [
  {LABEL: "Confirmed", DESCRIPTION: "has been confirmed by the kitchen"},
  {LABEL: "In-Progress", DESCRIPTION: "has been started and is being made"},
  {
    LABEL: "Completed",
    DESCRIPTION: "has been completed and is ready for pickup",
  },
];

export const DELIVERY_STAGE_VERBIAGE = [
  {LABEL: "Confirmed", DESCRIPTION: "has been confirmed by the kitchen"},
  {LABEL: "In-Progress", DESCRIPTION: "has been started and is being made"},
  {
    LABEL: "Completed",
    DESCRIPTION: "has been completed and is waiting to be delivered",
  },
  {
    LABEL: "On The Way",
    DESCRIPTION: "has started the delivery to your location",
  },
  {LABEL: "Arrived", DESCRIPTION: "has arrived at your location"},
  {LABEL: "Delivered", DESCRIPTION: "has been dropped off and delivered"},
];

export const POLICY_TYPE = {
  UNPAID: "Generic Time Off",
  PAID: "Paid Time Off",
  PARENTAL: "Parental Leave",
  SICK: "Sick Leave",
  VACATION: "Vacation",
  PERSONAL: "Personal Time",
  HOLIDAY: "Holiday",
};

export const PRODUCT_ENABLES = {
  POS_ENABLED: "Point of Sale",
  ENABLED: "Mobile",
  KIOSK_ENABLED: "Kiosk",
  THIRD_PARTY_ENABLED: "Third Party",
};

export const CATEGORY_ENABLES = {
  POS_ENABLED: "Point of Sale",
  APP_ENABLED: "Mobile",
  KIOSK_ENABLED: "Kiosk",
  THIRD_PARTY_ENABLED: "Third Party",
};

export const COLLECTION_METHODS = {
  AUTO_CHARGE: "Automatically Charge",
  SEND_INVOICE: "Send Invoice",
};

export const RECURRING_INVOICE_FINISHED_STATUSES = ["EXPIRED", "CANCELLED"];
export const WEIGHT_UNITS = [
  {
    label: "Ounce",
    value: "ounce",
  },
  {
    label: "Pound",
    value: "pound",
  },
  {
    label: "Gram",
    value: "gram",
  },
  {
    label: "Kilogram",
    value: "kilogram",
  },
];

export const SERVICE_CODES = [
  {
    label: "UPS Ground",
    value: "ups_ground",
  },
  {
    label: "Fedex Ground",
    value: "fedex_ground",
  },
];

export function _shippingPackagesToShipEngineFormat(serverShippingPackages) {
  let formattedPackages = [];

  formattedPackages = serverShippingPackages.map((x) => ({
    name: x.NAME,
    weight: {
      value: x.WEIGHT_VALUE,
      unit: x.WEIGHT_UNIT,
    },
    dimensions: {
      height: x.HEIGHT,
      width: x.WIDTH,
      length: x.LENGTH,
      unit: "inch",
    },
  }));

  return formattedPackages;
}

export const ECOMMERCE_ORDER_STATUES = {
  RECEIVED: "RECEIVED",
  PRODUCING: "PRODUCING",
  AWAITING_PACKAGING: "AWAITING_PACKAGING",
  AWAITING_SHIPPING: "AWAITING_SHIPPING",
  FULFILLED: "FULFILLED",
  ON_HOLD: "ON_HOLD",
};

export const ECOMMERCE_ORDER_STATUES_LABEL_MAP = {
  RECEIVED: "Received",
  PRODUCING: "Producing",
  AWAITING_PACKAGING: "Awaiting Packaging",
  AWAITING_SHIPPING: "Awaiting Shipping",
  FULFILLED: "Fulfilled",
  ON_HOLD: "On Hold",
};

export const SYNC_STATUS_DICT = {
  OUT_OF_DATE: "OUT_OF_DATE",
  AHEAD: "AHEAD",
  SYNCED: "SYNCED",
};

export const RESET_STORE = "RESET_STORE";

export const MILLI_SECOND = 1000;
export const MILLI_MINUTE = MILLI_SECOND * 60;
export const MILLI_HOUR = MILLI_MINUTE * 60;
export const MILLI_DAY = MILLI_HOUR * 24;
export const MILLI_MONTH = MILLI_DAY * 31;

export const USER_GUIDES = {
  NEW_MESSAGE: "NEW_MESSAGE",
  CHAT_MESSAGE: "CHAT_MESSAGE",
  FILE_SIDE_BAR: "FILE_SIDE_BAR",
  FILE_CONFIRM: "FILE_CONFIRM",
};

export const FILE_TYPES = {
  PDF: "application/pdf",
  JPG: "image/jpg",
  JPEG: "image/jpeg",
  PNG: "image/png",
  GIF: "image/gif",
};

export const TIME_CARD_EVENTS = {
  NEW: "Create",
  EDIT: "Edit",
  DELETE: "Delete",
  DELIVERY: "Delivery",
  OFFLINE: "Offline",
};

export const GIFT_CARD_LOG_TYPES = {
  IMPORT: "Imported", // needed to differentiate from manual activations
  ACTIVATION: "Activation",
  ACTIVE: "Activation",

  RELOAD: "Card Reload",
  CLEAR: "Card Reset",
  USAGE: "Card Used",

  REFUND_PURCHASE: "Gift Card Purchase Refunded", // This type represents the refund of a purchase of a gift card
  REFUND_USAGE: "Gift Card Usage Refunded", // This type represents the refund of an item purchased with a gift card

  // BACKFILL VALUES
  BACKFILL: "Backfilled", // used for unknown discrepancies when we initially backfill and move to this system
  BACKFILL_ACTIVE: "Backfilled", // used to backfill the known purchases from the ticket ID on the card
  BACKFILL_USAGE: "Backfilled", // used to backfill the known usages from the payments table

  BACKFILL_REFUND_PURCHASE: "Backfilled Refunded Gift Card Purchase", // This type represents the refund of a purchase of a gift card but a backfilled value
  BACKFILL_REFUND_USAGE: "Backfilled Refunded Gift Card Usage", // This type represents the refund of a purchase of a gift card
  // BACKFILL_RELOAD: "B_RELOAD" // Backfilled values from the Card_Reloads table

  BACKFILL_DUMB: "Backfilled", // a temporary backfill before we actually setup a real backfill. This is inserted so the numbers will match
  B_DUMB: "Backfilled",
};

import React, {Component} from "react";
import {FormInput, FormTextArea, FormBoolean} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

class PolicyLimitsForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({capHours, hours, capBalance, balance, capCarry, carry, waitingAccrue, waitingRequest}) {
    return {
      limits: {
        MAX_HOURS: capHours === "1" ? hours : null,
        MAX_BALANCE: capBalance === "1" ? balance : null,
        MAX_CARRY_OVER: capCarry === "1" ? carry : null,
        WAITING_PERIOD_ACCRUE: waitingAccrue,
        WAITING_PERIOD_REQUEST: waitingRequest,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, limits, method} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        validationSchema={Yup.object({})}
        initialValues={{
          capHours: limits ? (limits.MAX_HOURS !== null ? "1" : "0") : "0",
          hours: limits?.MAX_HOURS ?? "0",
          capBalance: limits ? (limits.MAX_BALANCE !== null ? "1" : "0") : "0",
          balance: limits?.MAX_BALANCE ?? "0",
          capCarry: limits ? (limits.MAX_CARRY_OVER !== null ? "1" : "0") : "0",
          carry: limits?.MAX_CARRY_OVER ?? "0",
          waitingAccrue: limits?.WAITING_PERIOD_ACCRUE ?? "0",
          waitingRequest: limits?.WAITING_PERIOD_REQUEST ?? "0",
        }}
      >
        {(formikOptions) => {
          const {handleSubmit, values} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              {method.METHOD === "HOURLY" && (
                <>
                  <FormBoolean
                    options={formikOptions}
                    label="Maximum Hours Tracked"
                    name="capHours"
                    tooltip="Maximum amount of hours in a calendar year that will be tracked and used to calculate time off accrual. If you do not wish to have a maximum amount, please leave this at no."
                  />

                  {values.capHours === "1" && (
                    <FormInput options={formikOptions} label="Number of Hours" name="hours" />
                  )}

                  <FormBoolean
                    options={formikOptions}
                    label="Maximum Employee Balance"
                    name="capBalance"
                    tooltip="Maximum amount of hours an employee can earn before hours will stop being accumulated."
                  />

                  {values.capBalance === "1" && (
                    <FormInput options={formikOptions} label="Number of Hours" name="balance" />
                  )}

                  <FormBoolean options={formikOptions} label="Reset hours" name="capCarry" />

                  {values.capCarry === "1" && (
                    <FormInput options={formikOptions} label="Max number of hours" name="carry" />
                  )}
                </>
              )}

              <FormInput
                options={formikOptions}
                label="Accrue Waiting Period"
                name="waitingAccrue"
                tooltip="How many days before an employee can begin to accrue hours"
              />

              <FormInput
                options={formikOptions}
                label="Request Waiting Period"
                name="waitingRequest"
                tooltip="How many days before an employee can begin to request time off"
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

PolicyLimitsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  limits: PropTypes.object,
};

export default PolicyLimitsForm;

import {FormBoolean, Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../../redux";
import {request} from "../../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class LoyaltyModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {rewardsEnabled, pointName, ignoreGiftCards, onePer, ignoreGiftCardPurchases, ignoreDiscountedItems} =
      values;

    let payload = {
      REWARDS_ENABLED: rewardsEnabled,
      IGNORE_LOYALTY_WITH_GIFT_CARDS: ignoreGiftCards,
      IGNORE_LOYALTY_EARN_ON_GIFT_CARDS: ignoreGiftCardPurchases,
      IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS: ignoreDiscountedItems,
      ADVANCED_LOYALTY_ONE_RULE_PER_TICKET: onePer,
    };

    const companyPayload = {
      LOYALTY_POINTS_VERBIAGE: pointName.toLowerCase().trim(),
      // NEW_PATRON_AWARD: newPatronAmount,
    };

    await Promise.all([
      request("partner/settings/v2", "PATCH", {SETTINGS: payload}),
      request("settings/company", "PATCH", {
        SETTINGS: companyPayload,
      }),
    ]);

    let {PARTNER_SETTINGS: settings, COMPANY_SETTINGS: companySettings} = await request(
      "settings/shop/all",
      "GET"
    );

    this.props.updateShop({settings});
    this.props.updateShop({companySettings});
    this.modal.close();
  };

  render() {
    let {
      REWARDS_ENABLED,
      MOBILE_REWARD_MAX_REDEEM,
      IGNORE_LOYALTY_WITH_GIFT_CARDS,
      IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
      IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS,
      ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
    } = this.props.shop.settings;
    let {LOYALTY_POINTS_VERBIAGE, NEW_PATRON_AWARD} = this.props.shop.companySettings;

    const terminology = LOYALTY_POINTS_VERBIAGE ? LOYALTY_POINTS_VERBIAGE : "points";

    return (
      <Modal
        label="Loyalty Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            rewardsEnabled: REWARDS_ENABLED,
            rewardMaxRedeem: toDollars(MOBILE_REWARD_MAX_REDEEM),
            pointName: LOYALTY_POINTS_VERBIAGE,
            // newPatronAmount: NEW_PATRON_AWARD,
            ignoreGiftCards: IGNORE_LOYALTY_WITH_GIFT_CARDS,
            ignoreGiftCardPurchases: IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
            ignoreDiscountedItems: IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS,
            onePer: ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean name="rewardsEnabled" label="Rewards Enabled" options={formikOptions} />

                <FormInput
                  label={"Terminology"}
                  name="pointName"
                  options={formikOptions}
                  tooltip={{
                    data: "Please use the plural version of your word.",
                  }}
                  hint={"Company Setting"}
                />

                {/*<FormInput*/}
                {/*  label={"New Patron Sign Up Amount"}*/}
                {/*  name="newPatronAmount"*/}
                {/*  options={formikOptions}*/}
                {/*  hint={"Company Setting"}*/}
                {/*/>*/}

                <FormBoolean
                  name="ignoreGiftCards"
                  label="Ignore Loyalty for Gift Cards as a Payment"
                  tooltip="If enabled, transactions purchased with a gift card will not earn the patron any loyalty."
                  options={formikOptions}
                />

                <FormBoolean
                  name="ignoreGiftCardPurchases"
                  label="Ignore Loyalty for Purchased Gift Cards"
                  tooltip="If enabled, purchases of gift cards will not grant loyalty points."
                  options={formikOptions}
                />

                <FormBoolean
                  name="ignoreDiscountedItems"
                  label="Ignore Loyalty for Already Discounted Items"
                  tooltip="If enabled, purchases of already discounted items will not grant loyalty points."
                  options={formikOptions}
                />

                <FormBoolean
                  name="onePer"
                  label="One Earn Rule Per Payment"
                  options={formikOptions}
                  tooltip={{
                    label: "One Rule Per Payment",
                    data: `If enabled, only one earn rule will be applied per payment. The rule that generates the maximum amount of ${terminology} will be applied. Otherwise, the patron will instead earn the sum of the ${terminology} for each satisfied earn rule.`,
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(LoyaltyModal);

import React, {Component} from "react";
import {PageHeadings, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import ContactTab from "../../features/shop/shop-page/contact-tab";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import LocationTab from "../../features/shop/shop-page/location-tab";
import ImagesTab from "../../features/shop/shop-page/images-tab";

class ShopPage extends Component {
  state = {info: {}};

  async componentDidMount() {
    let info = await request("location/information", "GET");

    let {EMAIL, FACEBOOK, TWITTER, INSTAGRAM, PHONE} = info;
    let {WEBSITE} = this.props.shop.location;

    let fullInfo = {
      EMAIL,
      FACEBOOK,
      TWITTER,
      INSTAGRAM,
      WEBSITE,
      PHONE,
    };

    this.setState({info: fullInfo});
  }

  render() {
    let {info} = this.state;

    return (
      <div>
        <PageHeadings label="My Shop" description="View and edit the information about your location" />

        <Tab
          data={[
            {
              id: "location",
              label: "Location",
            },
            {
              id: "contact",
              label: "Contact",
            },
            {
              id: "images",
              label: "Images",
            },
          ]}
        >
          {(tab) => {
            if (tab === "contact") {
              return <ContactTab info={info} updateInfo={(newInfo) => this.setState({info: newInfo})} />;
            }

            if (tab === "location") {
              return <LocationTab />;
            }

            return <ImagesTab />;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(ShopPage);

import React, {Component} from "react";
import ComboBox from "../../../components/combobox";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";

class VendorItemsDropdown extends Component {
  render() {
    const {vendors} = this.props.supply;
    const {vendorId, unique} = this.props;
    const {items} = this.props;

    const comboItems =
      items ?? vendors.find((vendor) => [vendor.ID, vendor.UNIQUE_ID].includes(vendorId))?.ITEMS ?? [];

    return (
      <ComboBox
        key={"box_" + randomString(24)}
        fixed
        compact
        extraHeight={0}
        onChange={(idx, item) => {
          return this.props.onChange(idx, item);
        }}
        data={comboItems.map((item) => {
          return {
            label: item.NAME,
            id: unique ? item.UNIQUE_ID : item.ID,
            item,
          };
        })}
        ignoreMargin
        style={{
          marginTop: 0,
          minWidth: 100,
          flex: 1,
          borderWidth: 0,
        }}
        placeholder="Input a product"
        {...this.props}
      />
    );
  }
}

export default setupReduxConnection(["supply"])(VendorItemsDropdown);

import React, {Component} from "react";
import {Modal, FormSelect, FormBoolean, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {getCurrentPayrun} from "../../../utils/payroll-helper";
import {Formik} from "formik";
import LoadingSpinner from "../../../components/loading-spinner";
import {showConfirmAlert, showErrorAlert, showSuccessAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import {CUSTOM_BENEFIT_TYPES} from "../../../utils/payroll-constants";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import moment from "moment";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";

class PayrollCustomBenefitModal extends Component {
  state = {benefit: null};

  async open(benefit) {
    this.setState({benefit}, () => this.slide.open());
  }

  upsertBenefit = async ({benefitType, name}) => {
    let {benefit} = this.state;

    let payload = {
      BENEFIT: benefitType,
      NAME: name,
    };

    if (benefit) {
      await request(`payroll/benefits/custom/${benefit.UNIQUE_ID}`, "PATCH", payload);

      this.props.syncState();

      this.slide.close();
    } else {
      let benefitUniqueId = await request("payroll/benefits/custom", "POST", payload);

      this.props.router.navigate(`${benefitUniqueId}`);
    }
  };

  render() {
    let {benefit} = this.state;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={benefit ? "Edit Benefit" : "Create Benefit"}
        buttonLabel={benefit ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
        disableButtonLoading={true}
        deleteLabel={benefit ? "Delete" : ""}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            benefitType: benefit?.BENEFIT ?? null,
            name: benefit?.NAME ?? "",
          }}
          onSubmit={this.upsertBenefit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions, handleSubmit) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  data={Object.keys(CUSTOM_BENEFIT_TYPES).map((key) => ({
                    label: CUSTOM_BENEFIT_TYPES[key],
                    value: key,
                  }))}
                  label={"Benefit"}
                  name={"benefitType"}
                  options={formikOptions}
                />

                <FormInput label={"Name"} name={"name"} options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(PayrollCustomBenefitModal);

import React, {Component} from "react";
import {
  Tab,
  Card,
  Loading,
  PageHeadings,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";

import loan from "./data/loan.json";
import payments from "./data/loan-payments.json";

class OfferPage extends Component {
  state = {loan: null, payments: null};

  componentDidMount() {
    this.setState({loan, payments: payments.data});
  }

  render() {
    const {loan, payments} = this.state;

    if (loan === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label={loan.state}
          description="You paid off your $2,150.00 loan and flat fee. You received your loan on March 9, 2020 and made your final payment on July 4, 2020."
          buttons={[
            {
              label: "Make Payment",
            },
            {
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Make Payment",
                      onClick: () => this.delete(loan),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Finance", url: "/"},
            {label: "Loans", url: "/loans"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "View Contact",
              onClick: () => this.taxModal.open(loan),
            },
          ]}
          label={"Loan Information"}
          description="Information about this loan"
          data={[
            {
              label: "Loan amount",
              value: loan.accepted_advance_amount,
              type: "dollars",
            },
            {
              label: "Amount payed",
              value: loan.amount_paid_down,
              type: "dollars",
            },
            {
              label: "Fixed fee",
              value: loan.accepted_premium_amount,
              type: "dollars",
            },
            {
              label: "Total amount due",
              value: loan.accepted_total_amount_sold,
              type: "dollars",
            },
            {label: "Repayment rate", value: loan.current_withhold_rate + "%"},
            {label: "60-day minimum", value: loan.NAME},
            {label: "Date of first paydown", value: loan.NAME},
          ]}
        />

        <Tab className="mt-2" data={[{label: "Payments", id: "payments"}]}>
          {(id) => {
            if (id === "payments") {
              return (
                <Card label="Payments" description="The transactions of payments sent to pay off the loan">
                  <Table
                    pagination
                    data={payments}
                    columns={[
                      {
                        width: 1,
                        value: "effective_time",
                        label: "Created",
                        type: "datetime",
                      },
                      {
                        value: "user_facing_description",
                        label: "Description",
                      },
                      {
                        width: 1,
                        value: "amount",
                        label: "Amount",
                        type: "dollars",
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) => this.props.router.navigate("/category/" + row.UNIQUE_ID),
                      },
                    ]}
                  />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default OfferPage;

import React, {Component} from "react";
import StepNavCard from "../../../../features/step-nav-card";
import {setupReduxConnection} from "../../../../redux";
import PayrollCompanyFormComponent from "../../../../features/payroll/onboard/group/payroll-company-form-component";
import PayrollGroupOnboardingCompleteComponent from "../../../../features/payroll/onboard/group/payroll-group-creation-component";
import PayrollGroupMultiLocationSetupComponent from "../../../../features/payroll/onboard/group/payroll-group-multi-location-setup-component";
import PayrollWorkplacesComponent from "../../../../features/payroll/onboard/group/payroll-workplaces-component";
import {PAYROLL_ONBOARDING_STATUSES} from "../../../../utils/payroll-constants";
import SingleGroupWelcomeComponent from "../../../../features/payroll/onboard/group/single-group-welcome-component";

class PayrollGroupOnboardingPage extends Component {
  state = {steps: null, defaultStep: 0};

  getSteps() {
    const {companyLocations, payrollGroup} = this.props.shop;

    let isMultiLocationCompany = companyLocations.length > 1;

    let steps = [];

    if (isMultiLocationCompany) {
      steps.push({
        label: "Multi-Location Setup",
        component: PayrollGroupMultiLocationSetupComponent,
        hideBack: true,
        onboardingStatus: "NO_GROUP",
      });
    } else {
      steps.push({
        label: "Welcome",
        component: SingleGroupWelcomeComponent,
        hideBack: true,
        onboardingStatus: "NO_GROUP",
      });
    }

    steps = [
      ...steps,
      {
        label: "Business Information",
        component: PayrollCompanyFormComponent,
        onboardingStatus: "GROUP_CREATED",
        hideBack: true,
      },
      {
        label: "Location Addresses",
        component: PayrollWorkplacesComponent,
        hideBack: true,
        onboardingStatus: "COMPANY_CREATED",
      },
      {
        label: "Onboarding",
        component: PayrollGroupOnboardingCompleteComponent,
        hideBack: true,
        onboardingStatus: "WORKPLACES_CREATED",
      },
    ];

    const defaultStep = steps.findIndex((_step) => _step.onboardingStatus === payrollGroup?.STATUS);

    return {steps, defaultStep};
  }

  render() {
    let {router} = this.props;
    let {NAME} = this.props.shop.location;
    let {steps, defaultStep} = this.getSteps();

    return (
      <StepNavCard
        label={`Payroll Setup for ${NAME}`}
        router={router}
        steps={steps}
        defaultStep={defaultStep}
      />
    );
  }
}

export default setupReduxConnection(["shop"])(PayrollGroupOnboardingPage);

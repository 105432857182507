import React, {Component} from "react";
import {ChevronRightIcon} from "@heroicons/react/outline";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";

class StartingPoint extends Component {
  renderList() {
    const {double, data} = this.props;

    if (double) {
      return (
        <ul
          role="list"
          className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2"
        >
          {data.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root cursor-pointer">
              <div
                className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
                onClick={item.onClick}
              >
                <div
                  className={classNames(
                    item.background,
                    "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                  )}
                >
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    <a href="#" className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />

                      {item.title}

                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <ul role="list" className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
        {data.map((item, itemIdx) => (
          <li key={itemIdx}>
            <div
              className="relative group py-4 flex items-start space-x-3 cursor-pointer"
              onClick={item.onClick}
            >
              <div
                className={classNames(
                  item.background,
                  "flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-lg"
                )}
              >
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>

              <div className="min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-900">
                  <a href={item.href}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.title}
                  </a>
                </div>
                <p className="text-sm text-gray-500">{item.description}</p>
              </div>

              <div className="flex-shrink-0 self-center">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const {label, description, otherLabel, otherClick} = this.props;

    return (
      <div>
        <h2 className="text-lg font-medium text-gray-900">{label}</h2>

        {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}

        {this.renderList()}

        {otherLabel && (
          <div className="mt-4 flex">
            <a
              onClick={otherClick}
              className="text-sm font-medium text-indigo-600 cursor-pointer hover:text-indigo-500"
            >
              {otherLabel}
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        )}
      </div>
    );
  }
}

StartingPoint.propTypes = {
  double: PropTypes.bool,

  label: PropTypes.string.isRequired,
  description: PropTypes.string,

  otherClick: PropTypes.func,
  otherLabel: PropTypes.string,

  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      icon: PropTypes.object,
      background: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default StartingPoint;

import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";
import StatusModal from "../../../components/status-modal";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import {showSuccessNotification} from "../../../utils/notification-helper";
import {showSuccessAlert} from "../../../utils/alert-helper";

class ImportPatronsModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  async sendImport({type, points, phones, patrons}) {
    this.statusModal.open(
      "Upload Started",
      "You can leave this tab, but in this tab don't navigate from this current page. The page will reload once the menu has been fully uploaded."
    );

    try {
      showSuccessNotification("Patron Import Started", "Import will take up to 10 minutes", {timeout: 30});

      if (type === "SQUARE") {
        await request("import/square/patrons", "POST", {
          POINTS: points,
          PHONES: phones,
        });
      } else if (type === "TOAST") {
        await request("import/toast/patrons", "POST", {
          PATRONS: patrons,
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (e) {
      if (e.error === "POINTS_INVALID") {
        this.formikRef.setFieldError(
          "points",
          "Point file is incorrectly formatted. Please contact support."
        );
      }

      if (e.error === "PHONES_INVALID") {
        this.formikRef.setFieldError(
          "phones",
          "Patron information file is incorrectly formatted. Please contact support."
        );
      }

      if (e.error === "PATRONS_INVALID") {
        this.formikRef.setFieldError(
          "patrons",
          "Patron information file is incorrectly formatted. Please contact support."
        );
      }

      return this.modal.stopLoading();
    }

    this.modal.stopLoading();
    // this.modal.close();
  }

  renderContent(formikOptions) {
    const {type} = formikOptions.values;

    if (type === "SQUARE") {
      return (
        <div>
          <ImageFormDropZone
            name="points"
            type="CSV"
            tooltip={{
              label: "Finding Points CSV",
              data: "If you require help with this step, please contact support at (781) 583-3699",
            }}
            label="Points CSV"
            options={formikOptions}
          />

          <ImageFormDropZone
            name="phones"
            type="CSV"
            tooltip={{
              data: [
                {
                  label: "Finding Patrons CSV",
                  data: "Learn about required csv columns on next page. If you require help with this step, please contact support at (781) 583-3699",
                },
                {
                  label: "Required CSV Columns",
                  data: "First Name, Last Name, Phone Number, Email Address, First Visit, Last Visit",
                },
              ],
            }}
            hint="Optional"
            label="Patron Information CSV"
            options={formikOptions}
          />
        </div>
      );
    }

    if (type === "TOAST") {
      return (
        <div>
          <ImageFormDropZone
            options={formikOptions}
            label="Patron Information CSV"
            name="patrons"
            type="CSV"
            tooltip={{
              data: [
                {
                  label: "Finding Patrons CSV",
                  data: "Learn about required csv columns on next page. If you require help with this step, please contact support at (781) 583-3699",
                },
                {
                  label: "Required CSV Columns",
                  data: "Phone number, Email, Balance (pts), First Trans. Date, Last Trans. Date",
                },
              ],
            }}
          />
        </div>
      );
    }

    return <div />;
  }

  render() {
    return (
      <Modal
        label="Import Patrons"
        buttonLabel="Import"
        description="Pull patrons from your old platform and use it here"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          onSubmit={this.sendImport.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.lazy(({type}) => {
            const defaultSchema = {};

            if (type === "SQUARE") {
              defaultSchema.points = Yup.array()
                .min(1, "Points CSV has to be set")
                .required("Points CSV has to be set");
            }

            if (type === "TOAST") {
              defaultSchema.patrons = Yup.array()
                .min(1, "Patrons CSV has to be set")
                .required("Patrons CSV has to be set");
            }

            return Yup.object(defaultSchema);
          })}
          initialValues={{
            type: "SQUARE",
            patrons: [],
            cards: [],
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <StatusModal ref={(e) => (this.statusModal = e)} />

                <FormSelect
                  tooltip={{
                    data: "Choose which platform you are importing your patrons from.",
                  }}
                  label="Old Platform"
                  name="type"
                  options={formikOptions}
                  data={[
                    {label: "Square", value: "SQUARE"},
                    {label: "Toast", value: "TOAST"},
                  ]}
                />

                {this.renderContent(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ImportPatronsModal;

import {parseIdDict, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";

export function convertColumnsToCSVHeaders(columns, firstColLabel = "") {
  let csvHeaders = columns
    .filter((column) => !column.sticky)
    .map((column) => {
      return {
        label: column.label,
        key: column.columnSelector,
      };
    });

  csvHeaders.unshift({
    label: firstColLabel,
    key: "LABEL",
  });

  return csvHeaders;
}

export function convertToCSVData(columns, rows, labelSelector = "label") {
  let csvData = [];

  let columnDict = parseIdDict(columns, "columnSelector");

  for (let row of rows) {
    let {numbers} = row;
    let entry = {};
    for (let key of Object.keys(numbers)) {
      if (columnDict[key]?.csvFormat) {
        entry[key] = columnDict[key]?.csvFormat(numbers[key], numbers);
      } else if (columnDict[key]?.dollarAmount) {
        entry[key] = toDollars(numbers[key], true);
      } else {
        entry[key] = numbers[key];
      }
    }

    entry.LABEL = row[labelSelector];

    csvData.push(entry);
  }

  return csvData;
}

export function getDateString(startEpoch, endEpoch) {
  let newDateString = moment(startEpoch).format("M-D");

  if (startEpoch !== endEpoch) {
    newDateString = `${moment(startEpoch).format("M-D")}_${moment(endEpoch).format("M-D")}`;
  }

  return newDateString;
}

export function nullifyIgnoredColumns(numbersObj, ignoreColumnArray) {
  return {
    ...Object.keys(numbersObj).reduce((obj, key) => {
      if (ignoreColumnArray.includes(key)) {
        obj[key] = numbersObj[key];
      } else {
        obj[key] = null;
      }

      return obj;
    }, {}),
  };
}

export function convertEpochsToDateString(startEpoch, endEpoch, format = "M-D", splitChar = "_") {
  let newDateString = moment(startEpoch).format(format);

  if (!moment(startEpoch).isSame(endEpoch, "day")) {
    newDateString = `${moment(startEpoch).format(format)}${splitChar}${moment(endEpoch).format(format)}`;
  }

  return newDateString;
}

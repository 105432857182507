const defaultState = {
  data: [],
};

const UPDATE_ALERT_DATA = "UPDATE_ALERT_DATA";

export function updateAlertData(payload) {
  return {type: UPDATE_ALERT_DATA, payload};
}

export function addAlert(payload) {
  return (dispatch, getState) => {
    const {data} = getState().alert;

    dispatch(updateAlertData([payload, ...data]));
  };
}

export function updateAlert(id, payload) {
  return (dispatch, getState) => {
    const {data} = getState().alert;
    const dataIndex = data.findIndex((item) => id === item.id);

    if (dataIndex !== -1) {
      data.splice(dataIndex, 1, {...data[dataIndex], ...payload});

      dispatch(updateAlertData(data));
    }
  };
}

export function removeAlert(payload) {
  return (dispatch, getState) => {
    const {data} = getState().alert;
    const dataIndex = data.findIndex((item) => payload.id === item.id);

    if (dataIndex !== -1) {
      data.splice(dataIndex, 1);

      dispatch(updateAlertData(data));
    }
  };
}

export const alertReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_ALERT_DATA:
      return {...state, data: payload};
  }
};

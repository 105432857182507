import React, {Component} from "react";
import {FormPhone, FormEmail, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../../utils/request";
import PropTypes from "prop-types";

class TokenRequestModal extends Component {
  state = {policy: null};

  open(policy = null) {
    this.setState({policy}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createTokenRequest({phone, email}) {
    phone = "1" + phone.replaceAll(" ", "");

    const serverPolicyEmployee = await request("tore", "POST", {
      PHONE: phone,
      EMAIL: email,
    });

    this.modal.close();
  }

  render() {
    return (
      <Modal
        buttonLabel="Create"
        label="Create Token REquest"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.createTokenRequest.bind(this)}
          initialValues={{
            phone: "",
            email: "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormPhone name="phone" label="Account Phone" options={formikOptions} />

                <div>or</div>

                <FormEmail name="email" label="Account Email" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

TokenRequestModal.propTypes = {
  policies: PropTypes.array.isRequired,
  employee: PropTypes.object.isRequired,

  addState: PropTypes.func,
  updateState: PropTypes.func,
};

export default TokenRequestModal;

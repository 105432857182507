import React, {Component} from "react";
import {FormBoolean, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Trans} from "react-i18next";

class W4Step4 extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.w4-employee.w4-step-4." + path;
  }

  renderDeductionsForm() {
    const {options} = this.props;

    return (
      <div>
        <FormInput
          name={"itemizedEstimate"}
          options={options}
          label={<Trans i18nKey={this.getI18NextKey("estimate.label")} />}
        />

        <FormInput
          options={options}
          name={"studentLoanEstimate"}
          label={<Trans i18nKey={this.getI18NextKey("studentLoanEstimate.label")} />}
        />
      </div>
    );
  }

  render() {
    const {options} = this.props;

    return (
      <form>
        <FormInput
          name={"otherIncome"}
          options={options}
          label={<Trans i18nKey={this.getI18NextKey("otherIncome.label")} />}
        />

        <FormBoolean
          name={"deductionsBoolean"}
          options={options}
          label={<Trans i18nKey={this.getI18NextKey("deduction.label")} />}
        />

        {options.values.deductionsBoolean === "1" && this.renderDeductionsForm()}

        <FormInput
          name={"extraWitholding"}
          options={options}
          label={<Trans i18nKey={this.getI18NextKey("extraWitholding.label")} />}
        />
      </form>
    );
  }
}

export default W4Step4;

import React, {Component} from "react";
import {
  FormBoolean,
  FormSelect,
  FormInput,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TicketTypeDropdown from "../../../dropdowns/sales/ticket-type-dropdown";
import * as Yup from "yup";

const PLATFORM_LABELS = [
  {label: "Kiosk", value: "KIOSK"},
  {label: "Mobile", value: "MOBILE"},
  {
    label: "Point of Sale",
    value: "POS",
  },
  {label: "Web", value: "WEB"},
];

const PLATFORM = {
  KIOSK_ENABLED: "Kiosk",
  MOBILE_ENABLED: "Mobile",
  POS_ENABLED: "Point of Sale",
  WEB_ENABLED: "Web",
};

const PLATFORM_VALUE = {
  KIOSK_ENABLED: "KIOSK",
  MOBILE_ENABLED: "MOBILE",
  POS_ENABLED: "POS",
  WEB_ENABLED: "WEB",
};

class PromptDetailsModal extends Component {
  state = {
    prompt: null,
    options: null,
    platform: null,
    type: null,
    serverTypes: [],
  };

  open(state) {
    this.setState(state, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createPrompt({long, short, platform, type, question}) {
    let serverPaymentType = {
      SHORT_NAME: short,
      FULL_NAME: long,
      TYPE: "TEXT",
      QUESTION_TEXT: question,
      PLATFORMS_ARRAY: platform,
      TICKET_TYPES: type,
    };

    let serverPrompt = await request("prompts", "POST", serverPaymentType);

    this.props.addState(serverPrompt);
    this.modal.close();
  }

  async savePrompt({long, short, platform, type, question}) {
    const {prompt} = this.state;

    let serverPaymentType = {
      SHORT_NAME: short,
      FULL_NAME: long,
      TYPE: "TEXT",
      QUESTION_TEXT: question,
      PLATFORMS_ARRAY: platform,
      TICKET_TYPES: type,
    };

    let serverCategory = await request("prompts/update/" + prompt.ID, "PATCH", serverPaymentType);

    this.props.updateState(serverCategory);
    this.modal.close();
  }

  render() {
    const {prompt, serverTypes, platform, options, type} = this.state;

    return (
      <Modal
        buttonLabel={prompt ? "Save" : "Add"}
        label={prompt ? "Edit Prompt" : "Create Prompt"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={prompt ? this.savePrompt.bind(this) : this.createPrompt.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            long: prompt?.FULL_NAME,
            short: prompt?.SHORT_NAME,
            question: options ? options[0]?.OPTION_TEXT : [],
            type: prompt ? type.map((item) => item.TICKET_TYPE_ID) : [],
            platform: platform
              ? Object.keys(PLATFORM)
                  .filter((item) => {
                    return platform[item];
                  })
                  .map((item) => {
                    return PLATFORM_VALUE[item];
                  })
              : [],
          }}
          validationSchema={Yup.object().shape({
            long: Yup.string().nullable().required("Long name is required."),
            short: Yup.string().nullable().required("Short name is required."),
            type: Yup.array().nullable().min(1, "Please select an order type."),
            platform: Yup.array().nullable().min(1, "Please select a platform."),
            question: Yup.string().nullable().required("Prompt question is required."),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            console.log("VALUES", values);

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Short Name" name="short" options={formikOptions} />

                <FormInput label="Long Name" name="long" options={formikOptions} />

                <TicketTypeDropdown multi name="type" label="Ticket Types" options={formikOptions} />

                <FormSelect
                  multi
                  label="Platform"
                  name="platform"
                  options={formikOptions}
                  data={PLATFORM_LABELS}
                />

                <FormInput label="Prompt Question" name="question" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PromptDetailsModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default PromptDetailsModal;

import React, {Component} from "react";
import {FormElement, FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Trans} from "react-i18next";
import FormRow from "../../../../../components/form-row";
import FormStateSelect from "../../../../../components/form-state-select";
import InputMask from "react-input-mask";

class W4Step1 extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.w4-employee.w4-step-1." + path;
  }

  render() {
    const {options} = this.props;

    return (
      <form>
        <FormRow>
          <FormInput
            name={"firstName"}
            label={<Trans i18nKey={this.getI18NextKey("firstName.label")} />}
            flex
            options={options}
          />

          <FormInput
            name={"middleInitial"}
            label={<Trans i18nKey={this.getI18NextKey("middleInitial.label")} />}
            options={options}
            flex
          />

          <FormInput
            name={"lastName"}
            label={<Trans i18nKey={this.getI18NextKey("lastName.label")} />}
            flex
            options={options}
          />
        </FormRow>

        <FormElement
          options={options}
          label={<Trans i18nKey={this.getI18NextKey("ssn.label")} />}
          name={"ssn"}
        >
          {(value, error, onChange) => {
            return (
              <InputMask
                mask={"999-99-9999"}
                name={"ssn"}
                type={"password"}
                pattern={"[0-9]*"}
                maskChar={null}
                className={
                  "focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                }
                value={value}
                flex
                options={options}
                onChange={onChange}
                aria-describedby="email-error"
                aria-invalid="true"
              />
            );
          }}
        </FormElement>

        <FormInput
          name={"address"}
          label={<Trans i18nKey={this.getI18NextKey("address.label")} />}
          options={options}
        />

        <FormRow>
          <FormInput
            name={"cityOrTown"}
            label={<Trans i18nKey={this.getI18NextKey("city.label")} />}
            flex
            options={options}
          />

          <FormStateSelect
            name={"state"}
            label={<Trans i18nKey={this.getI18NextKey("state.label")} />}
            flex
            options={options}
          />

          <FormInput
            name={"zip"}
            label={<Trans i18nKey={this.getI18NextKey("zip.label")} />}
            flex
            options={options}
          />
        </FormRow>

        <FormSelect
          name={"filingType"}
          options={options}
          data={[
            {
              value: "INDIVIDUAL",
              label: <Trans i18nKey={this.getI18NextKey("single.label")} />,
            },
            {
              value: "MARRIED",
              label: <Trans i18nKey={this.getI18NextKey("married.label")} />,
            },
            {
              value: "HEAD_OF_HOUSEHOLD",
              label: <Trans i18nKey={this.getI18NextKey("head.label")} />,
            },
          ]}
          label={<Trans i18nKey={this.getI18NextKey("filingType.label")} />}
        />
      </form>
    );
  }
}

export default W4Step1;

import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import GrayBadge from "../../components/badges/gray-badge";

class TaxesTable extends Component {
  render() {
    const {taxes, actionButtons} = this.props;

    let buttons = actionButtons || [
      {
        label: "View",
        onClick: (row) => this.props.router.navigate("/tax/" + row.UNIQUE_ID),
      },
    ];

    return (
      <Table
        {...this.props}
        pagination
        data={taxes}
        ref={(e) => (this.tableRef = e)}
        actionButtons={buttons}
        columns={[
          {
            value: "NAME",
            label: "Name",
            format: (value, row) => (
              <div>
                <div className="text-sm font-medium text-gray-900">{value}</div>

                <div className="text-sm text-gray-500">{row.IS_DEFAULT ? "Default" : ""}</div>
              </div>
            ),
          },
          {
            value: "RATE",
            label: "Rate",
            format: (value, _) => {
              return value + "%";
            },
          },
          {
            value: "IS_INCLUSIVE",
            label: "TYPE",
            format: (value, _) =>
              value === 1 || value === "1" ? (
                <GrayBadge>Inclusive</GrayBadge>
              ) : (
                <GrayBadge>Exclusive</GrayBadge>
              ),
          },
        ]}
      />
    );
  }
}

TaxesTable.propTypes = {
  taxes: PropTypes.array.isRequired,

  actionButtons: PropTypes.array,
};

export default withRouter(TaxesTable);

import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import * as Yup from "yup";

class ProductDiscountDetailsModal extends Component {
  state = {productDiscount: null};

  open(productDiscount = null) {
    this.setState({productDiscount}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async removeFromProduct() {
    const {productDiscount} = this.state;

    await request(`product/discount/${productDiscount.ID}`, "DELETE", null);

    this.props.onDelete && this.props.onDelete(productDiscount);

    this.modal.close();
  }

  async addProductDiscount({name, type, content, logo}) {
    // New Discount add it as a Discount first to Discounts table,
    // then add it as a Product Discount for this product
    let serverPaymentType = {
      NAME: name,
      CONTENT: type === 0 ? decimalToDollars(content) : content,
      PRODUCT: 1,
      TYPE: parseInt(type),
      LOGO: logo?.trim()?.length > 0 ? logo : "appicon.png",
    };

    const serverDiscount = await request("partner/discount", "POST", serverPaymentType);

    await this.props.addState(serverDiscount);
    this.modal && this.modal.close();
  }

  async saveProductDiscount({name, type, content, logo}) {
    let {productDiscount} = this.state;

    let serverPaymentType = {
      NAME: name,
      TYPE: parseInt(type),
      CONTENT: parseInt(type) === 0 ? decimalToDollars(content) : content,
      LOGO: logo?.trim().length > 0 ? logo : "appicon.png",
    };

    await request("partner/discount/" + productDiscount.DISCOUNT_ID, "PUT", serverPaymentType);

    this.props.updateState({
      productDiscount: {...productDiscount, ...serverPaymentType},
    });

    this.modal && this.modal.close();
  }

  render() {
    const {productDiscount} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        tooltip={
          productDiscount
            ? {
                data: "Changes made to this discount will be applied to all other products this discount is attached to.",
              }
            : undefined
        }
        label={productDiscount ? "Edit Product Discount" : "Create Product Discount"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteLabel={productDiscount ? "Remove" : null}
        deleteOnClick={() => {
          this.removeFromProduct();
        }}
      >
        <Formik
          onSubmit={
            productDiscount ? this.saveProductDiscount.bind(this) : this.addProductDiscount.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().nullable().required("Name is required."),
            type: Yup.number().nullable().required("Type is required"),
            content: Yup.number()
              .typeError("Discount Amount must be a number. Please remove any $ or %.")
              .required("Discount Amount is required."),
            logo: Yup.string(),
          })}
          initialValues={{
            name: productDiscount?.NAME,
            type: productDiscount?.TYPE,
            content:
              parseInt(productDiscount?.TYPE) === 0
                ? toDollars(productDiscount?.CONTENT)
                : productDiscount?.CONTENT,
            logo: productDiscount?.LOGO,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Name"
                  name="name"
                  options={formikOptions}
                  placeholder={"Employee Discount"}
                />

                <FormSelect
                  name="type"
                  label="Discount Type"
                  options={formikOptions}
                  data={[
                    {label: "Flat", value: 0},
                    {label: "Percentage", value: 1},
                  ]}
                />

                <FormInput
                  label="Discount Amount"
                  name="content"
                  placeholder={values?.type ? "75" : "5.00"}
                  options={formikOptions}
                />

                <ImageFormDropZone options={formikOptions} label="Discount Logo" name="logo" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ProductDiscountDetailsModal.propTypes = {
  updateState: PropTypes.func,
};

export default ProductDiscountDetailsModal;

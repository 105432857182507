import React, {Component} from "react";
import i18next from "i18next";

class LanguageSelectPage extends Component {
  selectLanguage(language) {
    i18next.changeLanguage(language, (err) => {
      if (err) return console.log("something went wrong loading", err);
      this.props.setLanguage(language);
    });
  }

  render() {
    const {options} = this.props;

    return (
      <div className={"flex flex-col items-center justify-center align-middle space-y-5 align-middle py-5"}>
        <button
          type="button"
          className=" flex-row justify-center w-1/2 items-center text-center py-5 border border-gray-300 shadow-sm text-lg leading-4 font-medium rounded-md text-gray-700 bg-white hover:outline-none hover:bg-indigo-500 hover:text-white focus:outline-none focus:bg-indigo-500 focus:text-white transition-colors duration-500 ease-in-out"
          onClick={() => {
            this.selectLanguage("en");
            options.setFieldValue("language", "en");
          }}
        >
          English
        </button>
        <button
          type="button"
          className=" flex-row justify-center w-1/2 items-center text-center py-5 border border-gray-300 shadow-sm text-lg leading-4 font-medium rounded-md text-gray-700 bg-white hover:outline-none hover:bg-indigo-500 hover:text-white focus:outline-none focus:bg-indigo-500 focus:text-white transition-colors duration-500 ease-in-out"
          onClick={() => {
            this.selectLanguage("es");
            options.setFieldValue("language", "es");
          }}
        >
          Español
        </button>
      </div>
    );
  }
}

export default LanguageSelectPage;

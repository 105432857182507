export const Box = ({children, className = ""}) => {
  return <div className={`flex ${className}`}>{children}</div>;
};

export const Row = ({children, className = ""}) => {
  return <div className={`flex flex-row ${className}`}>{children}</div>;
};

export const Col = ({children, className = ""}) => {
  return <div className={`flex flex-col ${className}`}>{children}</div>;
};

const HeaderText = ({text}) => (
  <div className="flex-1 pl-4 text-left text-gray-500 py-2 text-sm font-medium">{text}</div>
);

/**
 *
 * @param columns - list of objects, requires the label key
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({columns}) => (
  <Row className="w-full justify-between border-b bg-gray-50 border-gray-200">
    {columns.map(({label}) => (
      <HeaderText text={label} />
    ))}
  </Row>
);

const LineText = ({text, isLast = false}) => (
  <div className={`flex-1 pl-4 text-left py-2 text-sm ${!isLast ? "border-r border-gray-300" : ""}`}>
    {text}
  </div>
);

/**
 *
 * @param columns - list of objects with fields key, format, and label
 * @param line - the line of data to use
 * @returns {JSX.Element}
 * @constructor
 */
const Line = ({columns, line}) => (
  <Row className="w-full border-b">
    {columns.map(({key, format = (it) => it}, idx) => (
      <LineText text={format(line[key])} isLast={idx === columns.length - 1} />
    ))}
  </Row>
);

export const ReadOnlyTable = ({columns, data}) => {
  return (
    <Box className="bg-white">
      <Col className="m-2 w-full border border-gray-200 rounded">
        <Header columns={columns} />
        {data.map((dataLine) => (
          <Line columns={columns} line={dataLine} />
        ))}
      </Col>
    </Box>
  );
};

import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import PropTypes from "prop-types";
import {showErrorNotification, showSuccessNotification} from "../../../utils/notification-helper";

class EmployeeFolderModal extends Component {
  state = {employee: null};

  open() {
    this.modal.open();
  }

  handleSubmit = async (values) => {
    const {employee} = this.props;

    if (!employee?.ID) {
      showErrorNotification("", "Missing employee information. Please refresh and try again.");

      return this.modal.stopLoading();
    }
    const {name} = values;

    try {
      await request("employees/folder", "POST", {
        EMPLOYEE_ID: employee.ID,
        NAME: name,
      });
    } catch (err) {
      showErrorNotification("", "Error creating employee folder. Please refresh and try again.");

      return this.modal.stopLoading();
    }

    showSuccessNotification("", "Successfully created employee folder.");

    if (this.props.updateState) {
      this.props.updateState();
    }

    return this.modal.close();
  };

  close() {
    this.modal.close();
  }

  render() {
    return (
      <Modal
        buttonLabel={"Create"}
        label={"Create Folder"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        closeLabel={"Close"}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Please enter a nme for this folder"),
          })}
        >
          {(formikOptions) => {
            return (
              <>
                <FormInput options={formikOptions} name={"name"} label={"Folder Name"} />
              </>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EmployeeFolderModal.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default EmployeeFolderModal;

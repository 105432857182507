import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Tab,
  Loading,
  PageHeadings,
  TwoColumnList,
  Table,
  Card,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import moment from "moment";
import LabelLineModal, {NON_TEXT_LINE_TYPES} from "../../../modals/operations/labels/label-line-modal";
import {updateStateDelegator} from "../../../utils/util";
import LabelModal from "../../../modals/operations/labels/label-modal";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";

class LabelPage extends Component {
  state = {label: null, lines: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let label = await request("labels/" + id, "GET", null);

    request("labels/" + id + "/lines", "GET", null).then((lines) => {
      lines.sort((a, b) => a.SEQ - b.SEQ);

      this.setState({lines});
    });

    this.setState({label});
  }

  saveSequence() {
    const {lines} = this.state;

    request("labels/lines/seq", "POST", {
      LINES: lines.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    }).then(() => {
      lines.sort((a, b) => a.SEQ - b.SEQ);
    });
  }

  archiveLabel() {
    const {label} = this.state;

    showLoadingConfirmAlert("Delete Label", "Are you sure you want to delete this label?").then(
      async (close) => {
        await request("labels/" + label.ID, "DELETE", {});

        this.props.router.navigate("/labels");

        close();

        showSuccessNotification("Label Deleted", "We have deleted the label");
      }
    );
  }

  renderLabel() {
    const {location} = this.props.shop;
    const {account} = this.props.user;
    const {lines} = this.state;

    if (lines === null) {
      return <Loading />;
    }

    return (
      <Card
        className="mt-4"
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "black",
          borderRadius: 2,
          padding: 12,
        }}
      >
        {lines.map((item) => {
          const [top, right, bottom, left] = item.PADDING.split(" ");
          const textStyle = {
            fontSize: item.FONT_SIZE,
            paddingTop: parseInt(top),
            paddingRight: parseInt(right),
            paddingBottom: parseInt(bottom),
            paddingLeft: parseInt(left),
          };

          if (item.FONT_STYLE.indexOf("b") !== -1) {
            textStyle.fontWeight = "bold";
          }

          if (item.FONT_STYLE.indexOf("i") !== -1) {
            textStyle.fontWeight = "italic";
          }

          if (item.FONT_STYLE.indexOf("s") !== -1) {
            textStyle.textDecoration = "line-through";
          }

          if (item.FONT_STYLE.indexOf("u") !== -1) {
            textStyle.textDecoration = "underline";
          }

          let textContent = item.CONTENT;
          if (item.TYPE === "DATE") {
            textContent = moment().format("h:mma dddd MMM Do YYYY");
          }

          if (item.TYPE === "EMPLOYEE") {
            textContent = account.FULL_NAME;
          }

          if (item.TYPE === "DEVICE") {
            textContent = location.NAME + "s Device";
          }

          return <div style={textStyle}>{textContent}</div>;
        })}
      </Card>
    );
  }

  render() {
    const {label, lines} = this.state;

    if (label === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label={label.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [{label: "Delete Label", onClick: () => this.archiveLabel()}],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Labels Page", url: "/labels"},
          ]}
        />

        <LabelModal updateState={(label) => this.setState({label})} ref={(e) => (this.labelModal = e)} />

        <LabelLineModal
          label={label}
          ref={(e) => (this.lineModal = e)}
          addState={(line) => this.setState({lines: [...lines, line]})}
          updateState={(id, line) => {
            this.setState({
              lines: updateStateDelegator(lines, id, line),
            });
          }}
        />

        <TwoColumnList
          label="Label"
          description="Information about the label"
          data={[
            {label: "Name", value: label.NAME},
            {label: "Unique ID", value: label.UNIQUE_ID},
            {
              label: "Category",
              value: label.LABEL_CATEGORY_NAME ?? "No Category",
            },
            {
              label: "Default Printer",
              value: label.LABELER_NAME ?? "No Printer",
            },
            {label: "Notes", value: label.NOTES ?? "None", span: true},
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.labelModal.open(label),
            },
          ]}
        />

        <Tab
          data={[
            {id: "lines", label: "Label Lines"},
            {id: "preview", label: "Label Preview"},
          ]}
        >
          {(id) => {
            if (id === "preview") {
              return this.renderLabel();
            }

            if (id === "lines") {
              const seq = lines ? Math.max(...lines.map((item) => item.SEQ), 0) + 1 : 1;

              return (
                <Card
                  label="Label Lines"
                  button={{
                    label: "Add Line",
                    onClick: () => this.lineModal.open(null, seq),
                  }}
                >
                  <Table
                    draggable
                    pagination
                    data={lines}
                    onDrag={this.saveSequence.bind(this)}
                    ref={(e) => (this.tableRef = e)}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.lineModal.open(row, seq),
                      },
                    ]}
                    columns={[
                      {
                        value: "TYPE",
                        label: "Type",
                      },
                      {
                        value: "CONTENT",
                        label: "Content",
                        format: (value, row) => {
                          if (row.TYPE === "IMAGE") {
                            return (
                              <img
                                className="h-5 rounded-md"
                                src={getObjectImage(row, "CONTENT", "appicon.png")}
                                alt=""
                              />
                            );
                          }

                          return value;
                        },
                      },
                      {
                        value: "FONT_SIZE",
                        label: "Font Size",
                        format: (value, row) => {
                          return NON_TEXT_LINE_TYPES[row.TYPE] ? "" : value;
                        },
                      },
                      {
                        value: "FONT_STYLE",
                        label: "Font Style",
                        format: (value, row) => {
                          return NON_TEXT_LINE_TYPES[row.TYPE] ? "" : value;
                        },
                      },
                      {
                        value: "PADDING",
                        label: "Padding",
                      },
                    ]}
                  />
                </Card>
              );
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(withRouter(LabelPage));

import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import ReportTabModal from "../../../modals/shop/shop-settings/report-tab-modal";
import {REPORTING_DATING_LABELS} from "../../../utils/settings-constants";
import ReportCSVTabModal from "../../../modals/shop/shop-settings/report-csv-tab-modal";

class ReportTab extends Component {
  render() {
    let {
      PIN_PROTECT_REPORTS_POS,
      REPORTING_MODE,
      REPORTING_DATING,
      REPORTING_SHOW_LOCATION_CSV,
      REPORTING_SHOW_DATE_CSV,
    } = this.props.shop.settings;

    let data = [
      {
        label: "Pin Protect Reports on Point of Sale",
        value: PIN_PROTECT_REPORTS_POS,
        type: "bool",
        tooltip: {
          data: "Require employees to enter their employee PIN in order to view reports on the POS. If they do not have the corresponding credentials, they will not be able to view this tab.",
        },
      },
      {
        label: "Reporting Mode",
        value: REPORTING_MODE.charAt(0) + REPORTING_MODE.substring(1).toLowerCase(),
      },
    ];

    if (REPORTING_MODE === "AUDIT") {
      data.push({
        label: "Report Ticket at",
        value: REPORTING_DATING_LABELS[REPORTING_DATING],
        tooltip: {
          data: "Report tickets for either the time of payment or time goods are exchanged.",
        },
      });
    }

    return (
      <div>
        <ReportTabModal ref={(e) => (this.reportModal = e)} />
        <ReportCSVTabModal ref={(e) => (this.reportCSVModal = e)} />

        <TwoColumnList
          label="Reports"
          description="Configure settings for your reports"
          data={data}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.reportModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Reporting Export Settings"
          description="Configure settings for the csv exports of your reports"
          data={[
            {
              label: "List Locations in CSV",
              value: REPORTING_SHOW_LOCATION_CSV,
              type: "bool",
            },
            {
              label: "List Date in CSV",
              value: REPORTING_SHOW_DATE_CSV,
              type: "bool",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.reportCSVModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(ReportTab);

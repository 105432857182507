import React, {Component} from "react";
import {FormBoolean, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../../redux";
import {request} from "../../../../utils/request";
import {showErrorAlert} from "../../../../utils/alert-helper";
import MultiEmployeeSelect from "../../../../components/multi-employee-select";
import * as Yup from "yup";

class PayrollGroupMultiLocationSetupComponent extends Component {
  state = {company: {}};

  async componentDidMount() {
    const {location} = this.props.shop;

    let company = await request(`companies/${location.COMPANY_ID}`);

    this.setState({company});
  }

  async handleClick() {
    return await this.formikRef.submitForm();
  }

  submitForm = async (values) => {
    let {isMultiLocation, type, locationSelect, employees, overtimeWeekStartIsoDay} = values;
    const {metadata} = this.props.navState;

    if (locationSelect === "OTHER") {
      showErrorAlert("Contact Support", "Please contact support for a custom location payroll setup!");
      return false;
    }

    let payload = {
      TYPE: isMultiLocation === "0" ? "SINGLE" : type,
      LOCATION_SELECT: locationSelect,
      LOCATION_ACCESS_EMPLOYEE_IDS: employees,
      OVERTIME_WORKWEEK_START_ISODAY: overtimeWeekStartIsoDay,
    };

    this.props.updateNavState({
      metadata: {...metadata, type: isMultiLocation === "0" ? "SINGLE" : type},
    });

    const {payrollGroup, payrollLocationUniqueId} = await request("payroll/groups", "POST", payload);

    if (payload.TYPE === "MULTI") {
      localStorage.setItem("CURRENT_LOCATION", "" + payrollLocationUniqueId);

      window.location = `${window.location.protocol + "//" + window.location.host}/payroll`;

      return false;
    }

    this.props.updateShop({payrollGroup});

    return true;
  };

  render() {
    const {NAME} = this.state.company;
    const {location, companyLocations} = this.props.shop;
    const {employee} = this.props.user;
    const {OVERTIME_WORKWEEK_START_ISODAY} = this.props.shop.settings;

    let locationSelectOptions = [
      {
        label: `All in ${NAME}`,
        value: "COMPANY",
      },
      {
        label: "Other",
        value: "OTHER",
      },
    ];

    const areMultipleFranchises =
      Array.from(new Set(companyLocations.map((_loc) => _loc.FRANCHISE_ID))).length > 1;

    if (areMultipleFranchises) {
      locationSelectOptions.splice(1, 0, {
        label: "All in this franchise",
        value: "FRANCHISE",
      });
    }

    const validationSchema = Yup.object().shape({
      numbers: Yup.array().min(1, "Select at least one employee"),
    });

    return (
      <div className={"p-6 w-96 flex justify-center w-full"} style={{height: 600}}>
        <Formik
          onSubmit={this.submitForm}
          initialValues={{
            overtimeWeekStartIsoDay: OVERTIME_WORKWEEK_START_ISODAY,
            isMultiLocation: "0",
            locationSelect: "COMPANY",
            type: "MULTI",
            employees: employee?.ID ? [employee.ID] : [],
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            let {isMultiLocation, locationSelect, type} = formikOptions.values;

            let selectedLocations = companyLocations.filter((_loc) => _loc.TYPE === "STORE");

            if (locationSelect === "FRANCHISE") {
              selectedLocations = selectedLocations.filter(
                (_loc) => _loc.FRANCHISE_ID === location.FRANCHISE_ID
              );
            }

            return (
              <div className="w-full px-6 md:w-1/2 md:px-6 py-10">
                <FormSelect
                  label={"Work Week Start"}
                  name={"overtimeWeekStartIsoDay"}
                  data={[
                    {value: 1, label: "Monday"},
                    {value: 2, label: "Tuesday"},
                    {value: 3, label: "Wednesday"},
                    {value: 4, label: "Thursday"},
                    {value: 5, label: "Friday"},
                    {value: 6, label: "Saturday"},
                    {value: 7, label: "Sunday"},
                  ]}
                  options={formikOptions}
                  tooltip={{
                    data: "The day from which overtime starts calculating every week. Overtime is calculated on a weekly basis according to the applicable state laws.",
                  }}
                />

                <FormBoolean
                  label={"Do you have multiple locations under this EIN?"}
                  name={"isMultiLocation"}
                  options={formikOptions}
                />

                {isMultiLocation === "1" && (
                  <div>
                    <FormSelect
                      name={"locationSelect"}
                      label={"Which locations are under this EIN?"}
                      data={locationSelectOptions}
                      options={formikOptions}
                    />

                    {["COMPANY", "FRANCHISE"].includes(locationSelect) && (
                      <div className={"py-2"}>
                        {selectedLocations.map((_loc) => (
                          <li className={"text-sm font-medium text-gray-700"}>{_loc.NAME}</li>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {isMultiLocation === "1" && locationSelect !== "OTHER" && (
                  <FormSelect
                    name={"type"}
                    label={"How would you like to run payroll each period?"}
                    data={[
                      {label: "All locations in one payroll", value: "MULTI"},
                      {
                        label: "Each location individually",
                        value: "INDEPENDENT",
                      },
                    ]}
                    tooltip={{
                      data: [
                        {
                          label: "All locations in one payroll",
                          data: `A location specific for payroll will be created so you can handle payroll for all your shops in one place. All accounting entries will be lump sums for all locations.`,
                        },
                        {
                          label: "Each location individually",
                          data: "Every period, you'll run payroll for each location's timesheets separately. This allows accounting entries to be broken out by location.",
                        },
                      ],
                    }}
                    options={formikOptions}
                  />
                )}

                {isMultiLocation === "1" && type === "MULTI" && (
                  <MultiEmployeeSelect
                    name="employees"
                    label={"Who should have access to the payroll location?"}
                    options={formikOptions}
                    placeholder="Click to select employees"
                  />
                )}

                {isMultiLocation === "1" && locationSelect === "OTHER" && (
                  <div className={"py-4 text-sm font-bold"}>
                    Please reach out to Dripos Support for a custom location setup!
                  </div>
                )}
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(PayrollGroupMultiLocationSetupComponent);

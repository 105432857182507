import React, {Component} from "react";
import {request} from "../../utils/request";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import FilterDropdown from "../../components/filter-dropdown";

class PlatformDropdown extends Component {
  state = {data: [], serverPlatforms: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching platform data", err);
    });
  }

  async syncData() {
    const valueMap = {
      WEB: "Web",
      POS: "POS",
      MOBILE: "Mobile",
      KIOSK: "Kiosk",
    };

    const serverPlatforms = await request("report/productsales/options", "GET");

    const data = serverPlatforms
      .filter((platform) => platform.PLATFORM)
      .map((platform) => {
        return {
          value: platform,
          name: valueMap[platform.PLATFORM] ?? platform.PLATFORM,
        };
      });

    const initialValues = serverPlatforms.filter((platform) => platform.PLATFORM);

    this.setState({data, serverPlatforms, initialValues});
  }

  render() {
    const {data, initialValues} = this.state;

    return (
      <FormElement
        hint={"Pick Multiple"}
        id={this.id}
        {...this.props}
        iconPadding
        ignoreShadow
        tooltip={{data: "Filter sales by platform"}}
      >
        {() => {
          return (
            <FilterDropdown
              label={"Platforms"}
              data={data}
              plural={"Platforms"}
              cannotBeEmpty={true}
              initialValues={initialValues}
              enableReinitialize={true}
              {...this.props}
            />
          );
        }}
      </FormElement>
    );
  }
}

export default PlatformDropdown;

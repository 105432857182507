const defaultState = {
  settings: {},
  companySettings: {},
  location: null,
  locations: [],
  companyLocations: [],
  taxRates: [],
  employees: [],
  roles: [],
  departments: [],
};

const UPDATE_SHOP = "UPDATE_SHOP";
const UPDATE_LOCATION = "UPDATE_LOCATION";
const UPDATE_ACCOUNTING_GROUP = "UPDATE_ACCOUNTING_GROUP";

export function updateShop(payload) {
  return {type: UPDATE_SHOP, payload};
}

export function updateLocation(payload) {
  return {type: UPDATE_LOCATION, payload};
}

export function updateAccountingGroup(payload) {
  return {type: UPDATE_ACCOUNTING_GROUP, payload};
}

export function setAccountingGroupPrevProvider(payload) {
  return (dispatch, getState) => {
    let {accountingGroup} = getState().shop;

    accountingGroup.PREV_PROVIDER = payload;

    dispatch(updateAccountingGroup(accountingGroup));
  };
}

export function fetchTaxRate() {
  return (dispatch, getState) => {
    return getState().shop.location.TAX_RATE;
  };
}

export function fetchDefaultTaxRate() {
  return (dispatch, getState) => {
    return getState().shop.taxRates.reduce(
      (defaultRate, taxRate) => (taxRate.IS_DEFAULT ? taxRate.RATE + defaultRate : defaultRate),
      0
    );
  };
}

export const shopReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_SHOP:
      return {...state, ...payload, showShop: true};
    case UPDATE_LOCATION:
      return {...state, location: payload};
    case UPDATE_ACCOUNTING_GROUP:
      return {...state, accountingGroup: payload};
  }
};

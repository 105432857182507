import React, {Component} from "react";
import {withRouter} from "../../../../utils/navigation";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {IngredientCategoryRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import {updateIngredientCategories, updateVendors} from "../../../../redux/supply";
import {getStore, setupReduxConnection} from "../../../../redux";
import IngredientCategoryModal from "../../../../modals/operations/supply-chain/recipes/ingredient-category-modal";

class SupplyChainGroupsPage extends Component {
  state = {hasWaited: false};
  render() {
    const {hasWaited} = this.state;
    const {categories} = this.props.supply;

    if (categories.length === 0 && !hasWaited) {
      setTimeout(() => {
        this.setState({hasWaited: true});
      }, 5000);
      return <Loading />;
    }

    const nameColumn = {value: "NAME", label: "Name"};
    const wasteTargetColumn = {value: "WASTE_TARGET", label: "Waste Target (%)"};
    const cogsTargetColumn = {value: "COGS_TARGET", label: "COGS Target (%)"};

    const createGroupButton = {
      label: "Create Group",
      onClick: () => this.ingredientCategoryModal.open(),
    };

    const trashGroup = {
      label: <FontAwesomeIcon icon="fa-regular fa-trash" color={"red"} />,
      onClick: async (group) => {
        try {
          const close = await showLoadingConfirmAlert(
            `Delete Group: ${group.NAME}`,
            "Are you sure you want to delete this group? This cannot be undone."
          );
          await IngredientCategoryRequests.deleteCategory(group.ID);
          await getStore().dispatch(updateIngredientCategories());
          close();
          await showSuccessNotification(`Group ${group.NAME} successfully deleted.`);
        } catch (e) {
          await showSuccessNotification(`Group ${group.NAME} not deleted.`);
        }
      },
    };

    const editGroup = {
      label: "View",
      onClick: (row) => {
        this.props.router.navigate("/supply-chain-group/" + row.UNIQUE_ID);
      },
    };

    return (
      <div>
        <IngredientCategoryModal
          ref={(e) => (this.ingredientCategoryModal = e)}
          syncState={() => {
            getStore().dispatch(updateIngredientCategories());
          }}
        />

        <PageHeadings
          label="Groups"
          description="View all of your Supply Chain Ingredient Groups"
          button={createGroupButton}
        />

        <Table
          data={categories}
          className="mt-4"
          columns={[nameColumn, wasteTargetColumn, cogsTargetColumn]}
          actionButtons={[trashGroup, editGroup]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainGroupsPage));

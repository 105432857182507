import React, {Component} from "react";
import LoadingSpinner from "../../components/loading-spinner";
import {Table, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import moment from "moment-timezone";
import {classNames, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class BillingTab extends Component {
  state = {billing: null, selectedMonth: moment().startOf("month").format("YYYY-MM-DD")};

  componentDidMount() {
    this.refreshBilling();
  }

  refreshBilling = async () => {
    let {selectedMonth} = this.state;

    this.setState({billing: null});

    let billing = await request("admin/payroll-billing", "GET", {
      START_OF_MONTH: selectedMonth,
    });

    this.setState({billing});
  };

  selectMonth = async (selectedMonth) => {
    await this.setState({selectedMonth});
    this.refreshBilling();
  };

  render() {
    let {billing, selectedMonth} = this.state;

    let months = [];
    let currentMonthStart = moment().startOf("month");

    for (let i = 6; i > 0; i--) {
      months.push({
        label: currentMonthStart.format("MMMM YYYY"),
        value: currentMonthStart.format("YYYY-MM-DD"),
      });

      currentMonthStart.subtract(1, "month");
    }

    return (
      <div>
        <div className={"text-lg font-semibold mb-4 flex flex-row text-center items-center space-x-2"}>
          <div className={"mt-3"}>Billing for</div>

          <FormSelect className="w-48" value={selectedMonth} data={months} onChange={this.selectMonth} />
        </div>

        {!billing && <LoadingSpinner />}

        {billing && (
          <div>
            <Table
              columns={[
                {
                  label: "Location",
                  value: "NAME",
                },
                {
                  label: "Company",
                  width: 1,
                  value: "COMPANY_NAME",
                },
                {
                  label: "Employees",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div
                        className={classNames("text-right", row.NUMBER_OF_EMPLOYEES === 0 && "text-gray-300")}
                      >
                        <div className="text-medium">{toDollars(row.EMPLOYEE_REVENUE, true)}</div>

                        <div className={classNames(row.NUMBER_OF_EMPLOYEES > 0 && "text-gray-500")}>
                          {row.NUMBER_OF_EMPLOYEES}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  label: "Contractors",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div
                        className={classNames(
                          "text-right",
                          row.NUMBER_OF_CONTRACTORS === 0 && "text-gray-300"
                        )}
                      >
                        <div className="text-medium">{toDollars(row.CONTRACTOR_REVENUE, true)}</div>

                        <div className={classNames(row.NUMBER_OF_CONTRACTORS > 0 && "text-gray-500")}>
                          {row.NUMBER_OF_CONTRACTORS}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  label: "One Day",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div className={classNames("text-right", row.ONE_DAY === 0 && "text-gray-300")}>
                        <div className="text-medium">{toDollars(row.ONE_DAY_REVENUE, true)}</div>

                        <div className={classNames(row.ONE_DAY > 0 && "text-gray-500")}>{row.ONE_DAY}</div>
                      </div>
                    );
                  },
                },
                {
                  label: "Total",
                  value: "TOTAL_REVENUE",
                  width: 1,
                  format: (value) => {
                    return <div className="font-medium text-medium">{toDollars(value, true)}</div>;
                  },
                },
              ]}
              data={billing ?? null}
            />
          </div>
        )}
      </div>
    );
  }
}

export default BillingTab;

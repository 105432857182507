import React, {Component} from "react";
import {getStore, setupReduxConnection} from "../../../../redux";
import {fetchDefaultTaxRate} from "../../../../redux/shop";
import * as Yup from "yup";
import {request} from "../../../../utils/request";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {isSupplyChainWhitelisted} from "../../../../utils/util";
import ModifierInventoryModal from "../../../../modals/sales/modifiers/modifier-inventory-modal";
import ModifierIngredientsModal from "../../../../modals/operations/supply-chain/recipes/setup-product-ingredients/modifier-ingredients-modal";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";

class RecipeModifierOptions extends Component {
  constructor(props) {
    super(props);

    this.setState = this.props.setState;
  }

  fetchTaxRate() {
    const {product} = this.props;

    if (product) {
      const rate = product.TAX_RATES.reduce((accum, item) => {
        if (item.IS_INCLUSIVE) {
          return accum;
        }

        return accum + item.RATE;
      }, 0);

      return rate / 100;
    }

    return getStore().dispatch(fetchDefaultTaxRate()) / 100;
  }

  updateName(index, val) {
    const {modifier} = this.props;

    const options = [...modifier.OPTIONS];

    options.splice(index, 1, {
      ...options[index],
      NAME: val,
    });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchNameColumn() {
    return {
      value: "NAME",
      label: "Name",
      minWidth: "175px",
      editable: true,
      isSimple: true,
      placeholder: "Placeholder name",
      yup: Yup.string().min(1, "Minimum length is 1").required("Name is required"),
      onChange: (index, val) => this.updateName(index, val),
      format: (value, row) => {
        return row.NAME;
      },
    };
  }

  updateEnabled(index, val) {
    const {modifier} = this.props;

    const options = [...modifier.OPTIONS];

    options.splice(index, 1, {
      ...options[index],
      ENABLED: val,
    });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchEnabledColumn() {
    return {
      value: "ENABLED",
      label: "Enabled",
      type: "select",
      data: [
        {label: "Yes", value: "1"},
        {label: "No", value: "0"},
      ],
      editable: true,
      width: "100px",
      onChange: (index, val) => this.updateEnabled(index, val),
    };
  }

  async setDefault(option) {
    const {modifier, product} = this.props;
    const defaults = product.DEFAULTS;

    const globalPayload = await request(
      `product/${product.ID}/preset/${modifier.PRESET_ID}/customization/${modifier.ID}/option/${option.ID}`,
      "POST",
      {}
    );

    option.DEFAULT_OBJECT = globalPayload;
    defaults.push(globalPayload);
    return option;
  }

  async resetDefault(option) {
    const {product} = this.props;

    const defaults = product.DEFAULTS;

    await request(`customization/defaults/${option.DEFAULT_OBJECT.ID}`, "DELETE", {});

    const defaultIndex = defaults.findIndex((item) => {
      return item.ID === option.DEFAULT_OBJECT.ID;
    });

    if (defaultIndex !== -1) {
      defaults.splice(defaultIndex, 1);
    }
  }

  async updateDefault(index, val, option) {
    const {modifier} = this.props;
    const options = [...modifier.OPTIONS];

    if (val === "1") {
      await this.setDefault(option);
    } else {
      await this.resetDefault(option);
    }

    options.splice(index, 1, {
      ...options[index],
      DEFAULT: val,
    });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchDefaultColumn() {
    return {
      value: "DEFAULT",
      label: "Default",
      width: "100px",
      editable: true,
      type: "select",
      data: [
        {label: "Yes", value: "1"},
        {label: "No", value: "0"},
      ],
      onChange: async (index, val, option) => this.updateDefault(index, val, option),
    };
  }

  updatePrice(index, val) {
    const {modifier} = this.props;

    const options = [...modifier.OPTIONS];

    options.splice(index, 1, {
      ...options[index],
      PRICE: val,
    });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  updateCost(index, val) {
    const {modifier} = this.props;

    const options = [...modifier.OPTIONS];

    options.splice(index, 1, {
      ...options[index],
      ESTIMATED_COST: val,
    });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchPriceColumn() {
    return {
      icon: "dollar-sign",
      placeholder: "2.50",
      value: "PRICE",
      label: "Price",
      width: "100px",
      editable: true,
      isSimple: true,
      yup: Yup.number().typeError("Must be a number").required("Price is required"),
      onChange: (index, val) => this.updatePrice(index, val),
      format: (value, row) => {
        return row.PRICE;
      },
    };
  }

  fetchCostColumn() {
    return {
      icon: "dollar-sign",
      value: "ESTIMATED_COST",
      label: "Estimated Cost",
      width: "100px",
      editable: true,
      isSimple: true,
      yup: Yup.number().typeError("Must be a number").required("Cost is required"),
      tooltip: "Estimated cost for the cost of goods report.",
      onChange: (index, val) => this.updateCost(index, val),
      format: (value, row) => {
        return row.ESTIMATED_COST;
      },
    };
  }

  formatTax(item) {
    return toDollars(
      decimalToDollars("" + item.PRICE) + Math.round(decimalToDollars("" + item.PRICE) * this.fetchTaxRate())
    );
  }

  fetchTaxColumn() {
    const {product} = this.props;

    return {
      icon: "dollar-sign",
      value: "PRICE_TAX",
      label: "Price w/ Tax",
      width: "100px",
      isSimple: true,
      tooltip: product
        ? ""
        : "The price with tax is calculated using the default tax rate. The price may change depending on the product to which this modifier is applied.",
      format: (_, item) => this.formatTax(item),
    };
  }

  updateModifierMultiple(index, val) {
    const {modifier} = this.props;

    const options = [...modifier.OPTIONS];

    options.splice(index, 1, {
      ...options[index],
      MODIFIER: val,
    });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchModifierMultipleColumn() {
    return {
      editable: true,
      value: "MODIFIER",
      label: "Modifier Multiple",
      tooltip: "The multiple the other modifiers will increase by",
      width: "100px",
      onChange: (index, val) => this.updateModifierMultiple(index, val),
    };
  }

  fetchTableColumns() {
    const {showModifier, modifier} = this.props;

    const tableColumns = [this.fetchNameColumn()];

    if (modifier.TYPE !== 4) {
      tableColumns.push(this.fetchPriceColumn(), this.fetchTaxColumn());
    }

    tableColumns.push(this.fetchCostColumn());

    tableColumns.push(this.fetchEnabledColumn());

    if (modifier.PRESET_ID) {
      tableColumns.push(this.fetchDefaultColumn());
    }

    if (showModifier) {
      tableColumns.push(this.fetchModifierMultipleColumn());
    }

    return tableColumns;
  }

  addOption() {
    const {modifier} = this.props;
    const options = [...modifier.OPTIONS];
    const seq = Math.max(...modifier.OPTIONS.map((item) => item.SEQ));

    options.push({
      NAME: "",
      PRICE: "0.00",
      ENABLED: 1,
      SEQ: seq + 1,
      INGREDIENTS: [],
      ESTIMATED_COST: "0.00",
    });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchAddOptionActionText() {
    return {
      label: "Add Option",
      onClick: () => this.addOption(),
    };
  }

  sortOptions() {
    const {modifier} = this.props;

    const options = [...modifier.OPTIONS]
      .sort((a, b) => {
        return a.NAME.localeCompare(b.NAME);
      })
      .map((value, index) => {
        return {...value, SEQ: index + 1};
      });

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchSortOptionsActionText() {
    return {
      label: "Sort Options",
      prefixCharacter: "",
      onClick: () => this.sortOptions(),
    };
  }

  fetchActionTexts() {
    return [this.fetchAddOptionActionText(), this.fetchSortOptionsActionText()];
  }

  updateOption(option) {
    const {modifier} = this.props;
    const options = [...modifier.OPTIONS];

    const optionIndex = options.findIndex((_option) => _option.ID === option.ID);

    if (optionIndex !== -1) {
      options.splice(optionIndex, 1, option);
    }

    return this.setState({modifier: {...modifier, OPTIONS: options}});
  }

  deleteOption(row, index) {
    const {modifier} = this.props;
    const options = [...modifier.OPTIONS];
    options.splice(index, 1);

    if (this.modifierTable?.state?.errors) {
      const newErrors = this.modifierTable.state.errors;

      for (const key of Object.keys(this.modifierTable.state.errors)) {
        if (key.includes(row.tid)) {
          delete newErrors[key];
        }
      }

      this.modifierTable.setState({errors: newErrors});
    }

    this.setState({
      modifier: {...modifier, OPTIONS: options},
    });
  }

  fetchTrashButton() {
    return {
      icon: "trash",
      onClick: (row, index) => this.deleteOption(row, index),
    };
  }

  fetchActionButtons() {
    return [this.fetchTrashButton()];
  }

  onErrorChange(tableInvalid) {
    const {modifier} = this.props;

    this.setState({
      tableInvalid: tableInvalid || modifier.OPTIONS.length === 0,
    });
  }

  render() {
    const {modifier} = this.props;

    const options = modifier.OPTIONS.sort((a, b) => {
      return (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ);
    });

    return (
      <>
        <ModifierInventoryModal ref={(e) => (this.inventoryModal = e)} />

        <ModifierIngredientsModal
          syncState={(option) => this.updateOption(option)}
          ref={(e) => (this.modifierIngredientsModal = e)}
        />

        <Table
          white
          draggable
          verticalLines
          className="mt-4"
          columns={this.fetchTableColumns()}
          data={options}
          ref={(e) => (this.modifierTable = e)}
          onErrorChange={(tableInvalid) => this.onErrorChange(tableInvalid)}
          actionTexts={this.fetchActionTexts()}
          actionButtons={this.fetchActionButtons()}
        />
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(RecipeModifierOptions);

import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import IngredientCategoryModal from "../../../../modals/operations/supply-chain/recipes/ingredient-category-modal";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import {IngredientCategoryRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {setupReduxConnection} from "../../../../redux";
import GroupIngredientModal from "../../../../modals/operations/supply-chain/recipes/group-ingredient-modal";

class SupplyChainGroupPage extends Component {
  state = {
    group: null,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {UNIQUE_ID: uniqueId} = this.props.router.params;
    const group = await IngredientCategoryRequests.fetchCategory(uniqueId);
    const groupIngredients = await IngredientCategoryRequests.fetchCategoryIngredients(group.ID);
    this.setState({
      group: {
        ...group,
        INGREDIENTS: groupIngredients,
      },
    });
  }

  async removeCategoryIngredient(categoryIngredient) {
    const {group} = this.state;
    showLoadingConfirmAlert(
      "Remove Ingredient",
      "Are you sure you would like to remove this ingredient from the group?"
    )
      .then(async (close) => {
        await IngredientCategoryRequests.removeCategoryIngredient(group.ID, categoryIngredient.ID);

        await this.syncState();

        return close();
      })
      .catch((e) => {
        return e;
      });
  }

  render() {
    const {group} = this.state;

    const columns = [
      {
        value: "NAME",
        label: "Name",
      },
    ];

    const actionButtons = [
      {
        label: "View",
        onClick: (row) => {
          this.props.router.navigate("/recipes/ingredients/" + row.UNIQUE_ID);
        },
      },
      {
        label: "Remove",
        onClick: (row) => {
          return this.removeCategoryIngredient(row);
        },
      },
    ];

    if (!group) {
      return <Loading />;
    }

    return (
      <div>
        <IngredientCategoryModal ref={(e) => (this.groupModal = e)} syncState={() => this.syncState()} />

        <GroupIngredientModal
          ref={(e) => (this.groupIngredientModal = e)}
          group={group}
          syncState={() => this.syncState()}
        />

        <PageHeadings
          label={group.NAME}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Groups", url: "/groups"},
          ]}
        />

        <TwoColumnList
          label="Ingredient Group Information"
          data={[
            {label: "Group Name", value: group.NAME},
            {label: "Waste Target (%)", value: group.WASTE_TARGET},
            {label: "COGS Target (%)", value: group.COGS_TARGET},
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.groupModal.open(group),
            },
          ]}
        />

        <Card
          label="Group Ingredients"
          description="View the ingredients for this group"
          button={{
            label: "Add Ingredient",
            onClick: () => {
              this.groupIngredientModal.open(group);
            },
          }}
        >
          <Table data={group.INGREDIENTS} columns={columns} actionButtons={actionButtons} />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainGroupPage));

import React, {Component} from "react";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ProductDiscountDetailsModal from "../../../../../modals/sales/discounts/product-discount-details-modal";
import SelectProductDiscountModal from "../../../../../modals/sales/product/select-product-discount-modal";

class ProductDiscountsTab extends Component {
  async addProductDiscount(discountId) {
    const {product} = this.props;
    const productDiscounts = product.PRODUCT_DISCOUNTS;

    const productDiscount = await request(`product/discount/${discountId}`, "POST", {PRODUCTS: [product.ID]});

    if (productDiscount.length === 1) {
      const newProductDiscounts = [...productDiscounts, productDiscount[0]];

      this.props.updateProduct({
        ...product,
        PRODUCT_DISCOUNTS: newProductDiscounts,
      });
    }
  }

  render() {
    const {product} = this.props;
    const productDiscounts = product.PRODUCT_DISCOUNTS;

    const exclude = productDiscounts?.map((item) => {
      return item.DISCOUNT_ID;
    });

    return (
      <>
        <ProductDiscountDetailsModal
          ref={(e) => (this.discountDetailsModal = e)}
          updateState={({productDiscount}) => {
            const discountIndex = product.PRODUCT_DISCOUNTS.findIndex(
              (pDis) => pDis.ID === productDiscount.ID
            );

            if (discountIndex !== -1) {
              product.PRODUCT_DISCOUNTS.splice(discountIndex, 1, productDiscount);
            }

            this.props.updateProduct(product);
          }}
          addState={async (item) => {
            await this.addProductDiscount(item.ID);
          }}
          onDelete={(productDiscount) => {
            const discountIndex = product.PRODUCT_DISCOUNTS.findIndex(
              (pDis) => pDis.ID === productDiscount.ID
            );

            if (discountIndex !== -1) {
              product.PRODUCT_DISCOUNTS.splice(discountIndex, 1);
            }

            this.props.updateProduct(product);
          }}
        />

        <SelectProductDiscountModal
          ref={(e) => {
            this.selectDiscountModal = e;
          }}
          updateState={(values, callback) => {
            const {discount} = values;
            this.addProductDiscount(discount).then(() => callback());
          }}
          exclude={exclude}
        />

        <Card
          label="Product Discounts"
          description="Discounts that can be applied to this product on the POS"
          tooltip={{
            data:
              "These discounts are available for selection on this products page on the POS. When selected, this " +
              "discount will be applied as a discount for the product only, not the entire ticket.",
          }}
          buttons={[
            {
              theme: "primary",
              label: "Add Discount",
              icon: "plus",
              type: "list",
              onChange: ({id}) => {
                if (id === "add") {
                  this.selectDiscountModal.open();
                }

                if (id === "create") {
                  this.discountDetailsModal.open();
                }

                if (id === "test") {
                  request("product/scan/" + product.ID, "POST");
                }
              },
              items: [
                {
                  id: "add",
                  label: "Add Existing Discount",
                  description: "Add an existing Product Discount to this product.",
                },
                {
                  id: "create",
                  label: "Create New Product Discount",
                  description: "Create a new Product Discount and add it to this product.",
                },
              ],
            },
          ]}
        >
          <Table
            verticalLines
            classNames="mt-0"
            onClick={(item) => this.discountDetailsModal.open(item, 1, product)}
            data={productDiscounts}
            actionButtons={[
              {
                label: "Edit",
                onClick: (item) => this.discountDetailsModal.open(item, 1, product),
              },
            ]}
            ref={(e) => (this.discountsTable = e)}
            columns={[
              {
                value: "NAME",
                label: "Name",
                width: "200px",
                format: (value, _) => {
                  return (
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">{value}</div>
                      </div>
                    </div>
                  );
                },
              },
              {
                width: "40px",
                value: "TYPE",
                label: "Type",
                mobile: "sm:table-cell",
                format: (value, _) => {
                  return (
                    <div className="text-sm font-medium text-gray-900">
                      {value === 0 ? "Flat" : "Percentage"}
                    </div>
                  );
                },
              },
              {
                width: "60px",
                value: "CONTENT",
                label: "Value",
                mobile: "sm:table-cell",
                format: (value, row) => {
                  return (
                    <div className="text-sm font-medium text-gray-900">
                      {row.TYPE === 0 ? `$${toDollars(value)}` : `${value}%`}
                    </div>
                  );
                },
              },
            ]}
          />
        </Card>
      </>
    );
  }
}

export default ProductDiscountsTab;

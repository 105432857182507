import React, {Component} from "react";
import FormDateSpecify from "../../../../../components/form-date-specify";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import FormRow from "../../../../../components/form-row";
import FormStateSelect from "../../../../../components/form-state-select";

class EmploymentVerificationForm extends Component {
  render() {
    const {formikOptions} = this.props;

    return (
      <form>
        <FormDateSpecify
          name={"employeesFirstDay"}
          label={"Employee's First Day of Employment"}
          options={formikOptions}
          mdy
          flex
        />

        <FormInput
          name={"employerTitle"}
          label={"Title of Employer or Authorized Representative"}
          options={formikOptions}
          flex
        />

        <FormInput
          name={"employerLastName"}
          label={"Last Name of Employer or Authorized Representative"}
          options={formikOptions}
          flex
        />

        <FormInput
          name={"employerFirstName"}
          label={"First Name of Employer or Authorized Representative"}
          options={formikOptions}
          flex
        />

        <FormInput
          name={"employerBusinessName"}
          label={"Employer's Business or Organization Name"}
          options={formikOptions}
          flex
        />

        <FormInput name={"employerAddress"} label={"Address"} options={formikOptions} />

        <FormInput name={"employerApartmentNumber"} label={"Apartment Number"} options={formikOptions} />

        <FormRow>
          <FormInput name={"employerCityOrTown"} label={"City or Town"} flex options={formikOptions} />

          <FormStateSelect
            name={"employerState"}
            label={"State"}
            flex
            options={formikOptions}
            autocomplete={"state"}
          />

          <FormInput name={"employerZip"} label={"Zip Code"} flex options={formikOptions} />
        </FormRow>
      </form>
    );
  }
}

export default EmploymentVerificationForm;

import moment from "moment-timezone";
import {SHIFT_VIEWS, SORT_BY, VIEWS} from "../features/scheduling/scheduler";
import {calculateWeekStartEpochFromMoment} from "../utils/scheduling-helper";

const defaultState = {
  locationArr: null,
  showUnavailability: true,
  showEmployeesWithShifts: false,
  showLaborCost: true,
  printView: false,
  weather: {},
  payViewPermission: false,
  showShiftsOnly: false,
  sortByAlphabetical: false,
  currentView: VIEWS.WEEK,
  shiftView: SHIFT_VIEWS.ROLES,
  sortBy: SORT_BY.EMPLOYEES,
  currentViewString: "",
  currentDateString: "",
  timezone: "America/Chicago",
  waitingForLoad: false,
  waitingForLoadEvent: {},
  rolesToView: [],
  employeesToView: [],
  loadingStats: false,
  schedule: {
    events: [],
    shifts: [],
    employees: [],
    employeesWithoutRoles: [],
    employeesByShift: [],
    unavailability: [],
    crossLocationShifts: [],
    timeOff: [],
    shiftStatsByEmployee: [],
    laborSalesPercentages: {},
    locationShiftStats: {
      totalHours: 0,
      totalCost: 0,
      dailyStats: {
        0: {
          hours: 0,
          cost: 0,
        },
        1: {
          hours: 0,
          cost: 0,
        },
        2: {
          hours: 0,
          cost: 0,
        },
        3: {
          hours: 0,
          cost: 0,
        },
        4: {
          hours: 0,
          cost: 0,
        },
        5: {
          hours: 0,
          cost: 0,
        },
        6: {
          hours: 0,
          cost: 0,
        },
      },
    },
  },
};

const UPDATE_SCHEDULER = "UPDATE_SCHEDULER";
const UPDATE_SHIFTS = "UPDATE_SHIFTS";

export function updateScheduler(payload) {
  return {type: UPDATE_SCHEDULER, payload};
}

export function updateShifts(payload) {
  return {type: UPDATE_SHIFTS, payload};
}

export function setScheduler(payload) {
  return (dispatch) => {
    dispatch(updateScheduler(payload));
  };
}

export function updateSchedule(payload) {
  return (dispatch) => {
    dispatch(updateScheduler({schedule: payload}));
  };
}

export function getCalendarWeekStartEpoch() {
  return async (dispatch, getState) => {
    let {currentDateString} = getState().scheduling;
    let {SCHEDULER_FIRST_DAY} = getState().shop.settings;

    return calculateWeekStartEpochFromMoment(moment(currentDateString), SCHEDULER_FIRST_DAY);
  };
}

export function getWeekStartEpochFromMoment(fromMoment) {
  return async (dispatch, getState) => {
    let {SCHEDULER_FIRST_DAY} = getState().shop.settings;

    return calculateWeekStartEpochFromMoment(fromMoment, SCHEDULER_FIRST_DAY);
  };
}

export function upsertShifts(payload) {
  return (dispatch, getState) => {
    let {shifts} = getState().scheduling.schedule;

    payload.forEach((_shift) => {
      const toFindIndex = shifts.findIndex(({ID}) => ID === _shift.ID);

      if (toFindIndex !== -1) {
        shifts.splice(toFindIndex, 1, _shift);
      } else {
        shifts.push(_shift);
      }
    });

    dispatch(updateShifts(shifts));
  };
}

export function deleteShifts(shiftIdsToDelete) {
  return (dispatch, getState) => {
    let {shifts} = getState().scheduling.schedule;

    const toDeleteIdSet = new Set(shiftIdsToDelete);

    shifts = shifts.filter((_shift) => {
      return !toDeleteIdSet.has(_shift.ID + "");
    });

    dispatch(updateShifts(shifts));
  };
}

export function deleteShift(id) {
  return (dispatch, getState) => {
    let {shifts} = getState().scheduling.schedule;

    const toFindIndex = shifts.findIndex(({ID}) => ID === id);

    if (toFindIndex !== -1) {
      shifts.splice(toFindIndex, 1);
    }

    dispatch(updateShifts(shifts));
  };
}

export function publishShifts(locations) {
  return (dispatch, getState) => {
    let {shifts} = getState().scheduling.schedule;

    const locationSet = new Set(locations);

    shifts.forEach((_shift) => {
      if (locationSet.has(_shift.LOCATION_ID)) {
        _shift.DRAFT = 0;
      }
    });

    dispatch(updateShifts(shifts));
  };
}

export function insertCard(card) {
  if (!card.SHIFT_ID) {
    return;
  }

  return (dispatch, getState) => {
    const {shifts} = getState().scheduling.schedule;

    const findShift = shifts.find(({ID}) => ID === card.SHIFT_ID);

    if (!findShift) {
      return;
    }

    findShift.TIMESHEETS = findShift.TIMESHEETS ? [...findShift.TIMESHEETS, card] : [card];

    dispatch(updateShifts(shifts));
  };
}

export const schedulingReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_SCHEDULER:
      return {...state, ...payload};
    case UPDATE_SHIFTS:
      return {...state, schedule: {...state.schedule, shifts: payload}};
  }
};

export function togglePrintView() {
  return (dispatch, getState) => {
    const {printView: isPrintViewEnabled} = getState().scheduling;

    dispatch(
      updateScheduler({
        showUnavailability: isPrintViewEnabled,
        showEmployeesWithShifts: !isPrintViewEnabled,
        showLaborCost: isPrintViewEnabled,
        printView: !isPrintViewEnabled,
      })
    );
  };
}

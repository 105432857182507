import React, {Component} from "react";
import {FormInput, FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import ProductDropdown from "../../../dropdowns/sales/product-dropdown";

class InventoryModal extends Component {
  state = {product: null, inventory: null};

  open(product = null, inventory = null) {
    this.setState({product, inventory}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createInventory({name}) {
    let serverCategory = await request("labels/categories", "POST", {
      NAME: name,
    });

    this.props.addState(serverCategory);
    this.modal.close();
  }

  async saveInventory({quantity, lifetime, expires}) {
    const {product, inventory} = this.state;

    await request(`inventory/dashboard/product/${product.ID}/lifetime`, "POST", {
      LIFETIME: expires ? lifetime : null,
    });

    await request("inventory/dashboard/product/" + product.ID, "PATCH", {
      QUANTITY: parseInt(quantity),
    });

    this.props.updateState({
      product,
      inventory: {
        ...inventory,
        QUANTITY: quantity,
        LIFETIME: expires ? lifetime : null,
      },
    });

    this.modal.close();
  }

  render() {
    const {product, inventory} = this.state;

    return (
      <Modal
        buttonLabel={product ? "Save" : "Add"}
        label={product ? "Edit Product Inventory" : "Save Product Inventory"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={product ? this.saveInventory.bind(this) : this.createInventory.bind(this)}
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            product: inventory?.PRODUCT_ID,
            quantity: inventory?.QUANTITY,
            lifetime: inventory?.LIFETIME,
            expires: inventory?.LIFETIME ? "1" : "0",
          }}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ProductDropdown options={formikOptions} disabled={product} name="product" />

                <FormInput name="quantity" options={formikOptions} label="Inventory Quantity" />

                <FormBoolean name="expires" options={formikOptions} label="Product Expires" />

                {values.expires === "1" && (
                  <FormInput label="Product Expiry" name="lifetime" options={formikOptions} />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default InventoryModal;

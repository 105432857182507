import React, {Component} from "react";
import ChoosePaydayComponent from "../choose-payday-component";
import {request} from "../../../../utils/request";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {hasStartDateError} from "../../../../utils/payroll-helper";
import {readableDate} from "../../../../utils/util";

class ContractorsChoosePaymentDateComponent extends Component {
  state = {loading: false, paymentDate: null};

  componentDidMount() {
    this.props.loadPayroll();
  }

  async handleClick() {
    let {paymentDate} = this.state;

    const {loaded} = this.props.payroll;

    if (loaded) {
      if (hasStartDateError(this.props.payroll)) {
        const {start_date} = this.props.payroll.company;
        await showErrorAlert(
          "Invalid Paydate",
          `Your first allowed paydate is your company start date, ${readableDate(
            start_date
          )}. Please contact Dripos Support if you would like to pay your emplyoees sooner.`
        );
        return;
      }
    }

    const payload = {
      PAYMENT_DATE: paymentDate,
    };

    try {
      await request("payroll/contractor-payroll/create", "POST", payload);
      return true;
    } catch (error) {
      let label = "Invalid Dates";
      let description = "The dates selected are invalid. Please correct them to run an off-cycle payroll";

      if (error === "APPROVAL_DEADLINE_PASSED") {
        label = "Approval Deadline Passed";
        description =
          "The approval deadline for the specified dates has passed. Please choose a different paydate";
      }

      await showErrorAlert(label, description, "Ok");
    }
  }

  render() {
    let {loading} = this.state;

    return (
      <ChoosePaydayComponent
        title={"Payment Date"}
        description={"Choose between the following to pay your contractors:"}
        loading={loading}
        onPaydayChange={(newPayday) => {
          this.setState({paymentDate: newPayday});
        }}
        chooseWorkPeriod={false}
      />
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(ContractorsChoosePaymentDateComponent));

import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import EmployeeManagementModal from "../../../modals/shop/shop-settings/employee-management-modal";

class EmployeeManagementTab extends Component {
  render() {
    let {
      REQUIRE_TIME_CARD_BLESSINGS,
      TIME_CARD_BLESSING_CASH_DRAWER_PAYOUTS,
      SEND_EMPLOYEE_CREATION_TEXT,
      PIN_PROTECT_TAX_TOGGLE,
      PIN_PROTECT_EDIT_MODE,
      PIN_PROTECT_POS_SETTINGS,
      PIN_PROTECT_DISCOUNTS,
      PIN_PROTECT_REFUNDS,
      MOBILE_CLOCK_IN,
      REQUIRE_TIME_CARD_PICTURE,
      DISABLE_TIME_OFF,
      DISABLE_SHIFT_PREFERENCE,
      SHOW_TIPS_ON_HUB,
    } = this.props.shop.settings;

    return (
      <div>
        <EmployeeManagementModal ref={(e) => (this.employeeManagementModal = e)} />

        <TwoColumnList
          label="Employee Management"
          description="Configure settings for your employees clocking in and out"
          data={[
            {
              label: "Require Tip Confirmation",
              value: REQUIRE_TIME_CARD_BLESSINGS,
              type: "bool",
              tooltip: "When an employee clocks out, require a manager to confirm their tip payout.",
            },
            {
              label: "Tip Confirmation Creates Cash Drawer Payouts",
              value: TIME_CARD_BLESSING_CASH_DRAWER_PAYOUTS,
              type: "bool",
              tooltip: "When a tip is confirmed, create a cash drawer payouts event.",
            },
            {
              label: "Send Employees a Text Upon Creation",
              value: SEND_EMPLOYEE_CREATION_TEXT,
              type: "bool",
              tooltip:
                "Send employees a text when you create them with a link to download the dashboard app.",
            },
            {
              label: "PIN Protect Tax Toggle",
              value: PIN_PROTECT_TAX_TOGGLE,
              type: "bool",
              tooltip: "Pin protect the tax toggle on the POS.",
            },
            {
              label: "PIN Protect Edit Mode",
              value: PIN_PROTECT_EDIT_MODE,
              type: "bool",
              tooltip: "Pin protect edit mode on the POS.",
            },
            {
              label: "PIN Protect the POS Settings Page",
              value: PIN_PROTECT_POS_SETTINGS,
              type: "bool",
              tooltip: "Pin protect the settings page on the POS.",
            },
            {
              label: "PIN Protect Discounts",
              value: PIN_PROTECT_DISCOUNTS,
              type: "bool",
              tooltip: "PIN protect giving discounts on the POS.",
            },
            {
              label: "PIN Protect Refunds",
              value: PIN_PROTECT_REFUNDS,
              type: "bool",
              tooltip: "PIN protect refunding and voiding tickets on the POS.",
            },
            {
              label: "Mobile Clock In",
              value: MOBILE_CLOCK_IN,
              type: "bool",
              tooltip:
                "If enabled, all employees will have the ability to clock in and out on the employee app. If disabled, this feature is still available to roles with mobile clock in, teams or admin permissions.",
            },
            {
              label: "Require Picture on Clock In / Out",
              value: REQUIRE_TIME_CARD_PICTURE,
              type: "bool",
              tooltip:
                "If enabled, employees will be required to take a picture before clock in and out. Images will be viewable on the time cards page",
            },
            {
              label: "Disable Time Off Requests",
              value: DISABLE_TIME_OFF,
              type: "bool",
              tooltip: "If enabled, employees will no longer be able to request time off.",
            },
            {
              label: "Disable Shift Preferences",
              value: DISABLE_SHIFT_PREFERENCE,
              type: "bool",
              tooltip: "If enabled, employees will no longer be able to add or edit shift preferences.",
            },
            {
              label: "Show Employee Tips on Hub App Time Cards",
              value: SHOW_TIPS_ON_HUB,
              type: "bool",
              tooltip:
                "If enabled, employees will be able the tips earned on their own time cards. Only enable this setting if you calculate tips by transaction with Dripos.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.employeeManagementModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(EmployeeManagementTab);

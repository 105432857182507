import {request} from "../../request";

const basePath = "recurring-invoices/";

export const RecurringInvoiceRequests = {
  fetch: async (id) => {
    return request(basePath + id, "GET");
  },
  fetchAll: async () => {
    return request(basePath, "GET");
  },
  fetchInvoices: async (id) => {
    return request(basePath + id + "/invoices", "GET");
  },
  create: async (payload) => {
    return request(basePath, "POST", payload);
  },
  update: async (id, payload) => {
    return request(basePath + id, "PATCH", payload);
  },
  updateSimple: async (id, payload) => {
    return request(basePath + id + "/simple", "PATCH", payload);
  },
  delete: async (id, payload) => {
    return request(basePath + id, "DELETE", null);
  },
  executeScheduler: async () => {
    return request(basePath + "test-scheduler", "POST", null);
  },
};

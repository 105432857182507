import React, {Component} from "react";
import {Modal, FormPhone} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class NewChatModal extends Component {
  state = {phone: null};

  open(phone) {
    this.setState({phone}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  newChat = async ({phone}) => {
    this.props.router.navigate("/chat/1" + phone.replaceAll(" ", ""));
    this.modal.close();
  };

  render() {
    return (
      <Modal
        label="New Chat"
        buttonLabel="Open"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{}}
          onSubmit={this.newChat}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object({
            phone: Yup.string().required("Phone number is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormPhone label="Phone Number" name="phone" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default NewChatModal;

import React, {Component} from "react";
import {
  FormInput,
  FormPhone,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {setupReduxConnection} from "../../../redux";

class EditTicketModal extends Component {
  state = {ticket: null, types: []};

  open(ticket = null) {
    const {orderTypes} = this.props.menu;

    if (orderTypes && ticket?.TICKET_TYPE_TYPE === "IN-STORE") {
      const filteredOrderTypes = orderTypes
        .filter((type) => type.TYPE === "IN-STORE" && type.ENABLED)
        .map((type) => ({
          label: type.NAME,
          value: type.ID,
        }));

      this.setState({types: filteredOrderTypes});
    }

    this.setState({ticket}, () => {
      this.modal.open();
    });
  }

  async saveTicketType({name, email, phone, notes, type}) {
    const {ticket: oldTicket} = this.state;

    let serverTicketType = {
      NAME: name,
      EMAIL: email,
      PHONE: phone,
      TICKET_TYPE_ID: type,
      NOTES: notes,
    };

    try {
      await request("dashboard/ticket/" + oldTicket.UNIQUE_ID, "PATCH", serverTicketType);
      this.props.handleUpdate();
    } catch (e) {
    } finally {
      this.modal.close();
    }
  }

  renderFormBody(formikOptions) {
    const {handleSubmit} = formikOptions;
    const {ticket, types} = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <FormInput label="Name" name="name" options={formikOptions} />
        <FormInput label="Email" name="email" options={formikOptions} />
        <FormPhone label="Phone" name="phone" options={formikOptions} />
        {ticket?.TICKET_TYPE_TYPE === "IN-STORE" && (
          <FormSelect name="type" label="Order Type" options={formikOptions} data={types || []} />
        )}

        <FormTextArea label="Notes" name="notes" options={formikOptions} />
      </form>
    );
  }

  renderForm() {
    const {ticket} = this.state;

    let phone = ticket?.PHONE?.startsWith("1") ? ticket?.PHONE?.slice(1) : ticket?.PHONE;

    return (
      <Formik
        onSubmit={this.saveTicketType.bind(this)}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        initialValues={{
          name: ticket?.NAME ?? "",
          email: ticket?.EMAIL ?? "",
          phone: phone ?? "",
          notes: ticket?.NOTES ?? "",
          type: ticket?.TICKET_TYPE_ID ?? "",
        }}
      >
        {(formikOptions) => {
          return this.renderFormBody(formikOptions);
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Modal
        buttonLabel="Save"
        label="Update Ticket"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

EditTicketModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default setupReduxConnection(["menu"])(EditTicketModal);

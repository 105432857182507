import React, {Component} from "react";
import FormRow from "../../../../../components/form-row";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Trans} from "react-i18next";

class EmployeeNamePage extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.i9-part1.employee-name-page." + path;
  }

  render() {
    const {options} = this.props;

    return (
      <form>
        <FormRow>
          <FormInput
            name={"firstName"}
            label={<Trans i18nKey={this.getI18NextKey("firstName.label")} />}
            flex
            options={options}
          />

          <FormInput
            name={"middleInitial"}
            label={<Trans i18nKey={this.getI18NextKey("middleInitial.label")} />}
            options={options}
            flex
          />

          <FormInput
            name={"lastName"}
            label={<Trans i18nKey={this.getI18NextKey("lastName.label")} />}
            flex
            options={options}
          />
        </FormRow>

        <FormInput
          name={"otherLastNamesUsed"}
          label={<Trans i18nKey={this.getI18NextKey("otherLastNamesUsed.label")} />}
          options={options}
        />
      </form>
    );
  }
}

export default EmployeeNamePage;

import {Card, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {useFormik} from "formik";
import {useQuery} from "@tanstack/react-query";
import {EmployeeRequests} from "../../../../utils/request-helpers/employees/employee-requests";
import * as Yup from "yup";
import {useState} from "react";
import {ConfirmationModal} from "../../../../modals/general/confirmation-modal";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import {GiftCardRequests} from "../../../../utils/request-helpers/marketing/marketing-requests";

export const GiftCardsTab = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      selectedLocation: null,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: Yup.object().shape({
      selectedLocation: Yup.number()
        .required("Location is required")
        .typeError("The selected location must have a valid ID"),
    }),
  });

  const {isLoading, error, data} = useQuery({
    queryKey: ["locations"],
    queryFn: async () => {
      return EmployeeRequests.fetchLocations().then(
        (data) => data?.map(({ID, NAME}) => ({value: ID, label: NAME})) ?? []
      );
    },
  });

  const handleSubmit = () => {
    setIsConfirmModalOpen(true);
  };

  const handleClearGiftCards = async () => {
    try {
      await GiftCardRequests.archive(formik.values?.selectedLocation);
      showSuccessNotification("Success", "Gift cards were successfully cleared for the specified location");
    } catch (error) {
      showErrorNotification("Gift Card Error", "Unable to clear gift cards for the specified location");
    } finally {
      formik.resetForm();
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        handleModalClose={() => setIsConfirmModalOpen(false)}
        modalLabel={"Clear Gift Cards Confirmation"}
        tooltip={
          "Clearing gift cards for this location will archive all active gift cards, preventing patrons from using them"
        }
        confirmationMessage={`Are you sure you want to clear all active gift cards for the selected location?`}
        confirmationAction={handleClearGiftCards}
      />

      <Card label="Clear All Gift Cards" className={"mt-8"}>
        <form onSubmit={formik.handleSubmit} className={"m-6"}>
          <FormSelect
            name="selectedLocation"
            data={data ?? []}
            label={"Select Location"}
            placeholder={"Click to select location"}
            options={formik}
            disabled={isLoading || error}
          />

          <button
            type="submit"
            className="mb-6 mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </form>
      </Card>
    </>
  );
};

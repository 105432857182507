import React, {Component} from "react";
import {Formik} from "formik";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import FormStateSelect from "../../../components/form-state-select";
import {request} from "../../../utils/request";

class LocationAddressModal extends Component {
  state = {location: {}};

  open(location) {
    this.setState({location});

    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  handleSubmit = async ({ADDRESS, CITY, STATE, ZIPCODE}) => {
    let {location} = this.state;

    let locationPayload = {
      ADDRESS,
      CITY,
      STATE,
      ZIPCODE,
    };

    await request(`locations/${location.ID}`, "PATCH", locationPayload);

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    let {location} = this.state;

    return (
      <Modal
        label={`${location.NAME} Address`}
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          initialValues={{...location}}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Address" name={"ADDRESS"} options={formikOptions} />

                <div className="flex flex-row justify-between">
                  <FormInput label="City" name="CITY" options={formikOptions} />
                  <FormStateSelect className={"mx-3"} label="State" name="STATE" options={formikOptions} />

                  <FormInput className="w-24" label="Zipcode" name="ZIPCODE" options={formikOptions} />
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(LocationAddressModal);

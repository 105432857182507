import React, {Component} from "react";
import {request} from "../../../../utils/request";

class SingleGroupWelcomeComponent extends Component {
  handleClick = async () => {
    await request("payroll/groups", "POST", {
      TYPE: "SINGLE",
    });

    return true;
  };

  render() {
    return (
      <div style={{height: 600}} className="py-20 flex flex-col justify-center  items-center">
        <div className="text-2xl font-semibold">Welcome to Dripos Payroll!</div>

        <div className="text-gray-700 font-semibold mt-2">Click next to begin entering your information</div>
      </div>
    );
  }
}

export default SingleGroupWelcomeComponent;

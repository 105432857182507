import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {Loading, PageHeadings, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import InvoiceLinesCard from "../../../features/operations/invoices/invoice-lines-card";
import InvoiceLinesModal from "../../../modals/operations/invoices/invoice-lines-modal";
import InvoiceRecipientsCard from "../../../features/operations/invoices/invoice-recipients-card";
import RemindInvoiceModal from "../../../modals/operations/invoices/remind-invoice-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import {showSuccessNotification} from "../../../utils/notification-helper";
import InvoiceRecipientModal from "../../../modals/operations/invoices/invoice-recipient-modal";
import {RECURRING_INVOICE_FINISHED_STATUSES} from "../../../utils/constants";
import InvoicesTable from "../../../tables/operations/invoices/invoices-table";
import RecurringInvoiceCard from "../../../features/operations/recurring-invoices/recurring-invoice-card";
import RecurringInvoiceModal from "../../../modals/operations/recurring-invoices/recurring-invoice-modal";
import {RecurringInvoiceRequests} from "../../../utils/request-helpers/invoices/recurring-invoice-requests";

class RecurringInvoicePage extends Component {
  state = {schedule: null, invoices: [], invoice: null, ticket: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let schedule = await RecurringInvoiceRequests.fetch(id);
    let invoices = await RecurringInvoiceRequests.fetchInvoices(id);

    this.setState({
      schedule,
      invoice: schedule.INVOICE,
      ticket: schedule.INVOICE.TICKET,
      invoices,
    });
  }

  updateStatus(status) {
    const {schedule} = this.state;

    showLoadingConfirmAlert(
      "Update Status",
      "Are you sure you want to update the status of this recurring invoice?"
    )
      .then(async (close) => {
        await RecurringInvoiceRequests.updateSimple(schedule.ID, {
          STATUS: status,
        });
        await this.syncState();
        close();
        showSuccessNotification("Recurring Invoice Status Updated");
      })

      .catch(() => {});
  }

  deleteRecurringInvoice() {
    const {schedule} = this.state;

    showLoadingConfirmAlert(
      "Delete Recurring Invoice",
      "Are you sure you want to delete this recurring invoice?"
    )
      .then(async (close) => {
        await RecurringInvoiceRequests.delete(schedule.ID, null);
        this.props.router.navigate("/recurring-invoices");
        showSuccessNotification("Recurring Invoice Deleted");
        close();
      })
      .catch(() => {});
  }

  fetchHeaderButtons() {
    const {schedule, invoices} = this.state;

    const dropdownButtons = [];
    if (schedule.STATUS === "ACTIVE") {
      dropdownButtons.push({
        label: "Pause Recurring Invoice",
        onClick: () => {
          this.updateStatus("PAUSED");
        },
      });
    } else if (schedule.STATUS === "PAUSED" && (!schedule.DATE_END || Date.now() < schedule.DATE_END)) {
      dropdownButtons.push({
        label: "Resume Recurring Invoice",
        onClick: () => {
          this.updateStatus("ACTIVE");
        },
      });
    }

    dropdownButtons.push({
      label: "Delete Recurring Invoice",
      onClick: () => {
        this.deleteRecurringInvoice();
      },
    });

    const headerButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [{items: dropdownButtons}],
      },
    ];

    const activeInvoices = invoices.filter((invoice) => invoice.STATUS === "OPEN");
    if (activeInvoices.length > 0) {
      const latestButton = {
        label: "View Latest Invoice",
        onClick: () => {
          this.props.router.navigate("/invoice/" + activeInvoices[0].UNIQUE_ID);
        },
      };

      headerButtons.unshift(latestButton);
    }

    return headerButtons;
  }

  renderHeadingLabel() {
    const {invoice} = this.state;

    return (
      <div className={"flex flex-row "}>
        <div className={"mr-2"}>
          {invoice.CONTACT.FIRST_NAME + " " + invoice.CONTACT.LAST_NAME + " Recurring Invoice"}
        </div>

        {invoice.STATUS === "REFUNDED" && <DangerBadge className={"my-1"}>Refunded</DangerBadge>}

        {invoice.DATE_ARCHIVED && <DangerBadge className={"my-1"}>Archived</DangerBadge>}
      </div>
    );
  }

  fetchTabComponent(id) {
    const {invoice, invoices} = this.state;
    console.log(id);
    switch (id) {
      case "invoices":
        console.log("1");
        return <InvoicesTable className="mt-4" data={invoices} />;
      case "lines":
        console.log("2");
        return (
          <InvoiceLinesCard
            ticket={invoice.TICKET}
            lines={invoice.TICKET.ITEMS}
            hideEdit={invoice.STATUS === "PAID"}
            handleEdit={() => this.invoiceLinesModal.open(invoice)}
          />
        );
      case "recipients":
        console.log("3");
        return (
          <InvoiceRecipientsCard
            invoice={this.state.invoice}
            handleEdit={() => this.recipientModal.open(invoice)}
            updateState={(invoice) => this.setState({invoice})}
          />
        );
      default:
        console.log("HEHE");
        return;
    }
  }

  render() {
    const {invoice, schedule, invoices} = this.state;

    if (invoice === null) {
      return <Loading />;
    }
    const invoiceTabs = [
      {id: "invoices", label: "Invoices"},
      {id: "lines", label: "Invoice Line Items"},
      {id: "recipients", label: "Additional Recipients"},
    ];

    return (
      <div>
        <RecurringInvoiceModal
          ref={(e) => (this.recurringInvoiceModal = e)}
          updateState={(schedule) => {
            this.setState({schedule, INVOICE: schedule.INVOICE});
          }}
        />

        <InvoiceLinesModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.invoiceLinesModal = e)}
        />

        <RemindInvoiceModal ref={(e) => (this.remindModal = e)} />

        <InvoiceRecipientModal
          ref={(e) => (this.recipientModal = e)}
          updateState={(invoice) => this.setState({invoice})}
        />

        <PageHeadings
          label={this.renderHeadingLabel()}
          buttons={this.fetchHeaderButtons()}
          breadcrumbs={[
            {label: "Recurring Invoices", url: "/recurring-invoices"},
            {label: "Recurring Invoice", url: "/recurring-invoices"},
          ]}
        />

        <RecurringInvoiceCard
          schedule={schedule}
          hideEdit={RECURRING_INVOICE_FINISHED_STATUSES.includes(schedule.STATUS)}
          handleEdit={() =>
            this.recurringInvoiceModal.open({
              ...schedule,
              BILLING_ANCHOR: invoice.DATE_CREATED,
            })
          }
        />

        <Tab data={invoiceTabs}>
          {(id) => {
            return this.fetchTabComponent(id);
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(RecurringInvoicePage);

import moment from "moment-timezone";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {saveAs} from "file-saver";
import EmployeeFileModal from "../../modals/team/employee/employee-file-modal";
import {EmployeeRequests} from "../../utils/request-helpers/employees/employee-requests";

class EmployeeFilesTable extends Component {
  async downloadFile(signedUrl, name) {
    saveAs(signedUrl, name);
  }

  render() {
    const {employee, filters = [], files = [], folders = []} = this.props;

    return (
      <>
        <EmployeeFileModal
          ref={(e) => {
            this.fileModal = e;
          }}
          employee={employee}
          syncState={() => this.props.loadFiles()}
          folders={folders}
        />

        <Table
          pagination
          actionButtons={[
            {
              label: "Download",
              onClick: async (row) => await this.downloadFile(row.SIGNED_URL, row.FILE_NAME),
            },
            {
              label: "Edit",
              onClick: (row) => this.fileModal.open(employee, row),
            },
          ]}
          columns={[
            {
              value: "FILE_NAME",
              label: "File Name",
            },
            {
              value: "FOLDER_NAME",
              label: "Tag",
              format: (val) => val ?? "-",
              tooltip:
                "To categorize a file with a tag, press edit or press manage files to assign more than one file at once",
            },
            {
              value: "DATE_UPLOADED",
              label: "Date Uploaded",
              format: (end) => moment(parseInt(end)).format("MMM DD, h:mm A"),
            },
          ]}
          data={files}
          filters={filters}
        />
      </>
    );
  }
}

EmployeeFilesTable.propTypes = {
  employee: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
};

export default EmployeeFilesTable;

import React, {Component} from "react";
import {Loading, PageHeadings, Table, Filter} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

class ReadersPage extends Component {
  state = {readers: null};

  componentDidMount() {
    request("readers/deployments/readers", "GET").then((readers) => {
      this.setState({readers});
    });
  }

  render() {
    const {readers} = this.state;

    if (readers === null) {
      return <Loading />;
    }

    return (
      <>
        <PageHeadings label="Readers" description="Create and manage companies" />

        <Filter searchable defaultFilters={[]} data={[]}>
          {(filters, search) => {
            return (
              <Table
                pagination
                search={search}
                filters={filters}
                searchFields={["id", "label", "serial_number"]}
                data={readers}
                className="mt-4"
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => {
                      this.props.router.navigate("/admin/company/" + row.ID);
                    },
                  },
                ]}
                columns={[
                  {
                    value: "id",
                    label: "ID",
                  },
                  {
                    value: "label",
                    label: "Name",
                  },
                  {
                    value: "serial_number",
                    label: "Serial Number",
                  },
                  {
                    value: "device_type",
                    label: "Device",
                  },
                ]}
              />
            );
          }}
        </Filter>
      </>
    );
  }
}

export default withRouter(ReadersPage);

import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {PageHeadings, Filter, Table, Loading, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment";
import {request} from "../../../../utils/request";
import {DRAWER_TYPES} from "../../../../utils/operations-constants";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import CashDrawerTable from "../../../../tables/operations/cash-drawer-table";

class CashDrawersPage extends Component {
  render() {
    return (
      <div>
        <PageHeadings
          label="Cash Drawers"
          description="View all active cash drawers and employee drawers and their cash balances"
        />

        <CashDrawerTable className="mt-4" />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(CashDrawersPage));

import React, {Component} from "react";
import {SlideOver, Loading} from "@frostbyte-technologies/frostbyte-tailwind";

class LocationsModal extends Component {
  state = {company: null};

  open(company) {
    this.setState({company}, () => this.slideMenu.open());
  }

  render() {
    const {company} = this.state;

    if (!company) {
      return <div />;
    }

    const {locations} = company;

    return (
      <SlideOver
        label={"Locations"}
        buttons={[
          {
            label: "Close",
            type: "gray",
            onClick: () => this.slideMenu.close(),
          },
        ]}
        ref={(e) => (this.slideMenu = e)}
      >
        <div className={"space-y-4 px-6 py-2"}>
          {locations?.map((location) => {
            return (
              <div className={"flex flex-col justify-between"}>
                <div className={"border-0 text-sm font-medium"}>{location.NAME}</div>

                <div className={"text-sm text-indigo-500"}>
                  {location.ADDRESS}, {location.CITY}, {location.STATE}
                </div>
              </div>
            );
          })}
        </div>
      </SlideOver>
    );
  }
}

export default LocationsModal;

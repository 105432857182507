import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {Card, Loading, PageHeadings, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import StartingPoint from "../../../components/starting-point";
import {ClockIcon, TableIcon, ViewBoardsIcon} from "@heroicons/react/outline";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ImportMenuModal from "../../../modals/sales/import-menu-modal";
import {request} from "../../../utils/request";
import CategoryDetailsModal from "../../../modals/sales/categories/category-details-modal";
import MenuSyncModal from "../../../modals/sales/menu-sync-modal";
import {hasPermission} from "../../../redux/user";
import ProductTableExpansionComponent from "../../../features/product/product-table-expansion-component";
import ProductsTable from "../../../tables/sales/products-table";
import ProductDetailsModal from "../../../modals/sales/product/product-details-modal";

class TicketsPage extends Component {
  state = {isLoading: true, filter: "all", categories: [], products: []};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const categories = await request("categories", "GET");
    const products = await request("products", "GET");

    products.sort((a, b) => {
      return (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ);
    });

    this.setState({isLoading: false, categories, products});
  }

  saveSequence(category) {
    const {products} = this.state;

    const categorizeProducts = products.filter((item) => {
      return item.CATEGORY_ID === category;
    });

    request("products/seq", "POST", {
      ITEMS: categorizeProducts.map((item) => ({
        PRODUCT_ID: item.ID,
        SEQ: item.SEQ,
      })),
    });

    products.sort((a, b) => {
      return (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ);
    });

    this.setState({products});
  }

  renderMenu() {
    const {categories, products, filter} = this.state;

    const categoryDict = parseIdDict(categories);

    categories.sort((a, b) => a.SEQ - b.SEQ);

    return (
      <Tab
        className="mt-2"
        data={categories
          .filter((item) => {
            if (filter?.id === "mobile") {
              return item.APP_ENABLED;
            }

            if (filter?.id === "kiosk") {
              return item.KIOSK_ENABLED;
            }

            return true;
          })
          .map((item) => {
            return {label: item.NAME, id: item.ID};
          })}
      >
        {(t) => {
          const category = categoryDict[t];
          const filteredProducts = products.filter((item) => {
            return item.CATEGORY_ID === t;
          });

          if (filteredProducts.length === 0) {
            return (
              <div className="mt-4">
                {/*<EmptyBox label="Create your first product" icon="cart-plus" />*/}
              </div>
            );
          }

          return (
            <Card
              label={category?.NAME + "'s Products"}
              description={`Products that are in the "${category?.NAME}" category`}
              buttons={[
                {
                  label: "View Category",
                  onClick: () => this.props.router.navigate("/category/" + category?.UNIQUE_ID),
                },
                {
                  label: "Create Product",
                  onClick: () => this.productDetailsModal.open(),
                },
              ]}
            >
              <ProductsTable
                draggable
                data={filteredProducts}
                onDrag={() => this.saveSequence(t)}
                updateProduct={(serverProduct) => {
                  const productIndex = products.findIndex((item) => {
                    return item.ID === serverProduct.ID;
                  });

                  if (productIndex !== -1) {
                    products.splice(productIndex, 1, {
                      ...products[productIndex],
                      ...serverProduct,
                    });
                  }

                  this.setState({products});
                }}
                expandable={(product) => {
                  return (
                    <ProductTableExpansionComponent
                      product={product}
                      updatePricing={() => this.syncState()}
                      updateProduct={(serverProduct) => {
                        const productIndex = products.findIndex((item) => {
                          return item.ID === serverProduct.ID;
                        });

                        if (productIndex !== -1) {
                          products.splice(productIndex, 1, {
                            ...products[productIndex],
                            ...serverProduct,
                          });
                        }

                        this.setState({products});
                      }}
                      {...this.props}
                    />
                  );
                }}
              />
            </Card>
          );
        }}
      </Tab>
    );
  }

  renderEmpty(yarn) {
    return (
      <Card className="p-6">
        <StartingPoint
          label="Setup a menu"
          otherLabel="Or click here to import an existing menu"
          otherClick={() => this.importMenuRef.open()}
          description="Let's get a menu setup so we can start taking orders"
          data={[
            {
              title: "Create a Category",
              description: "A category is the place products will be created.",
              icon: ViewBoardsIcon,
              background: "bg-blue-500",
              onClick: () => console.log("Yo"),
            },
            {
              title: "Create a Product",
              description: "A product is a sellable item.",
              icon: TableIcon,
              background: "bg-indigo-500",
              onClick: () => console.log("Yo"),
            },
            {
              title: "Create a Global Modifier",
              description: "Have the ability to use modifiers around.",
              icon: ClockIcon,
              background: "bg-purple-500",
              onClick: () => console.log("Yo"),
            },
          ]}
        />
      </Card>
    );
  }

  renderHeaderButtons() {
    const buttonArr = [
      {
        searchParam: "view",
        theme: "gray",
        selectable: true,
        labelPrefix: "Viewing from ",
        label: "Viewing all categories ",
        type: "list",
        icon: "eye",
        onChange: (filter) => this.setState({filter}),
        items: [
          {
            id: "all",
            label: "All Platforms",
            description: "View all products from all platforms.",
          },
          {
            id: "pos",
            label: "Point of Sale",
            description: "View all products available on the POS.",
          },
          {
            id: "mobile",
            label: "Mobile and Website",
            description: "View all products available on Mobile and Website ordering.",
          },
          {
            id: "kiosk",
            label: "Kiosk",
            description: "View all products available on Kiosk.",
          },
        ],
      },
      {
        theme: "primary",
        label: "Create",
        icon: "plus",
        type: "list",
        onChange: ({id}) => {
          if (id === "product") {
            this.productDetailsModal.open();
          }

          if (id === "category") {
            this.categoryDetailsModal.open();
          }
        },
        items: [
          {
            id: "product",
            label: "Product",
            description: "Create a new product for your menu.",
          },
          {
            id: "category",
            label: "Category",
            description: "Create a new category for your products on your menu.",
          },
        ],
      },
    ];

    if (hasPermission("MENU_SYNC_FULL")) {
      buttonArr.unshift({
        label: "Sync Menu",
        onClick: () => this.syncModal.open(),
      });
    }

    return buttonArr;
  }

  render() {
    const {isLoading, products} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <ImportMenuModal ref={(e) => (this.importMenuRef = e)} />
        <MenuSyncModal ref={(e) => (this.syncModal = e)} />

        <CategoryDetailsModal
          updateState={(e) => this.setState(e)}
          ref={(e) => (this.categoryDetailsModal = e)}
          addState={(category) => this.props.router.navigate("/category/" + category.UNIQUE_ID)}
        />

        <ProductDetailsModal
          ref={(e) => (this.productDetailsModal = e)}
          updateProduct={(product) => {
            this.setState((prevState) => {
              const {products: prevProducts} = prevState;
              return {products: [product, ...prevProducts]};
            });
          }}
        />

        <PageHeadings
          label="My Menu"
          description="View all of the products and categories on your menu"
          buttons={this.renderHeaderButtons()}
        />

        {products.length === 0 ? this.renderEmpty() : this.renderMenu()}
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "user"])(withRouter(TicketsPage));

import React, {Component} from "react";
import {Button} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {MoonLoader} from "react-spinners";

class AuthRedirectPage extends Component {
  state = {
    grant: null,
    refresh_token: null,
    expire_time: null,
    access_token: null,
    session: null,
    locations: null,
  };

  componentDidMount() {
    const queryParams = this.props.router.searchParams;

    this.setState({grant: queryParams.get("code")});
  }

  async generateAccessToken() {
    const {grant} = this.state;

    this.buttonRef.startLoading();

    const serverToken = await request("auth/v1/token", "POST", {
      grant_type: "authorization_code",
      client_id: "aapp_2ZaXGmSumkxyEnMW38Dl38v2gO9",
      client_secret: "sk_2ZaXGyzsNry22iq3d5bU4cXnLgU_XxVXssZ7DdhF3rHjigrmLxGXaZ2",
      code: grant,
    });

    this.setState(serverToken);
  }

  async generateRefreshToken() {
    const {refresh_token} = this.state;

    this.buttonRef.startLoading();

    const serverToken = await request("auth/v1/oauth/token", "POST", {
      grant_type: "refresh_token",
      client_id: "aapp_2ZaXGmSumkxyEnMW38Dl38v2gO9",
      client_secret: "sk_2ZaXGyzsNry22iq3d5bU4cXnLgU_XxVXssZ7DdhF3rHjigrmLxGXaZ2",
      refresh_token: refresh_token,
    });

    this.setState(serverToken);

    this.buttonRef.stopLoading();
  }

  async fetchSession() {
    const {access_token} = this.state;

    const serverSession = await request("auth/v1/validate", "GET", null, {
      Authentication: "Bearer " + access_token,
    });

    return this.setState({session: serverSession});
  }

  async fetchLocations() {
    const {access_token} = this.state;

    const {locations} = await request("auth/v1/locations", "GET", null, {
      Authentication: "Bearer " + access_token,
    });

    return this.setState({locations});
  }

  renderSession() {
    const {session} = this.state;

    if (session === null) {
      return <div />;
    }

    return (
      <div className="break-words">
        {Object.keys(session).map((item) => {
          return (
            <>
              <div className="font-bold text-lg">{item}:</div>
              <div className="text-sm mb-2">{JSON.stringify(session[item])}</div>
            </>
          );
        })}
      </div>
    );
  }

  renderLocations() {
    const {locations} = this.state;

    if (locations === null) {
      return <div />;
    }

    return (
      <div className="break-words">
        <div className="font-bold text-lg">locations:</div>
        <div className="text-sm mb-2">{locations.map((item) => item.name).join(", ")}</div>
      </div>
    );
  }

  renderToken() {
    const {refresh_token, expire_time, access_token} = this.state;

    return (
      <div className="break-words">
        <div className="font-bold text-lg">access_token:</div>
        <div className="text-sm mb-2">{access_token}</div>

        <div className="font-bold text-lg">expire_time:</div>
        <div className="text-sm mb-2">{expire_time}</div>

        <div className="font-bold text-lg">refresh_token:</div>
        <div className="text-sm mb-2">{refresh_token}</div>

        <Button
          type="button"
          ref={(e) => (this.buttonRef = e)}
          onClick={() => this.generateRefreshToken()}
          label="Refresh Token"
          flex
        />

        {this.renderSession()}

        <Button
          type="button"
          className="mt-2"
          ref={(e) => (this.buttonRef = e)}
          onClick={() => this.fetchSession()}
          label="Fetch Session Info"
          flex
        />

        {this.renderLocations()}

        <Button
          type="button"
          className="mt-2"
          ref={(e) => (this.buttonRef = e)}
          onClick={() => this.fetchLocations()}
          label="Fetch Locations"
          flex
        />
      </div>
    );
  }

  renderGrant() {
    const {grant} = this.state;

    return (
      <div className="break-words">
        <div className="font-bold text-lg">Grant Token (code):</div>
        <div className="text-sm mb-2"> {grant}</div>

        <Button
          type="button"
          ref={(e) => (this.buttonRef = e)}
          onClick={() => this.generateAccessToken()}
          label="Fetch Access Token"
          flex
        />
      </div>
    );
  }

  renderForms() {
    const {grant, access_token} = this.state;

    if (access_token !== null) {
      return this.renderToken();
    }

    if (grant !== null) {
      return this.renderGrant();
    }

    return (
      <div className="flex flex-grow justify-center items-center">
        <MoonLoader color={"blue"} loading={true} size={50} />
      </div>
    );
  }

  render() {
    return (
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            src={require("./../../assets/images/logo.png")}
            className="mx-auto h-12 w-auto"
            alt="Workflow"
          />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 break-words shadow sm:rounded-lg sm:px-10">
            <div className="font-bold text-lg">client_id:</div>
            <div className="text-sm mb-2">aapp_2ZaXGmSumkxyEnMW38Dl38v2gO9</div>

            <div className="font-bold text-lg mt-2">client_secret:</div>
            <div className="text-sm mb-2">sk_2ZaXGyzsNry22iq3d5bU4cXnLgU_XxVXssZ7DdhF3rHjigrmLxGXaZ2</div>

            {this.renderForms()}
          </div>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(AuthRedirectPage));

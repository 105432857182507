import React, {Component} from "react";
import {Modal, FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";

class PayrollContractorPaymentModal extends Component {
  state = {breakdownItem: null};

  open = (breakdownItem) => {
    this.setState({breakdownItem}, () => this.modal.open());
  };

  submitPayment = async (values) => {
    let {id} = this.state.breakdownItem.CONTRACTOR;
    let {amount, paymentMethod, reimbursementAmount} = values;

    if (reimbursementAmount == "") {
      reimbursementAmount = 0;
    }

    let payload = {
      CONTRACTOR_ID: id,
      AMOUNT: amount,
      REIMBURSEMENT_AMOUNT: reimbursementAmount,
      IS_MANUAL: paymentMethod === "manual",
    };

    await request("payroll/contractor-payroll/payment", "POST", payload);

    await this.props.refresh();
    this.modal.close();
  };

  delete = async () => {
    let {id} = this.state.breakdownItem.CONTRACTOR;

    await request("payroll/contractor-payroll/payment/" + id, "DELETE");

    await this.props.refresh();
    this.modal.close();
  };

  render() {
    let {breakdownItem} = this.state;

    let deleteLabel = breakdownItem?.PAYMENT ? "Delete" : "";

    return (
      <Modal
        label={`Payment for ${breakdownItem?.CONTRACTOR.first_name} ${breakdownItem?.CONTRACTOR.last_name}`}
        buttonLabel="Save"
        deleteLabel={deleteLabel}
        deleteOnClick={() => this.delete()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            amount: breakdownItem?.PAYMENT?.amount || "",
            reimbursementAmount: breakdownItem?.PAYMENT?.reimbursement_amount || "",
            paymentMethod: breakdownItem?.PAYMENT?.IS_MANUAL ? "manual" : "direct_deposit",
          }}
          onSubmit={this.submitPayment}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label={"Amount"} name={"amount"} options={formikOptions} />

                <FormInput
                  label={"Reimbursement Amount"}
                  name={"reimbursementAmount"}
                  hint={"Optional"}
                  options={formikOptions}
                />

                <FormSelect
                  label={"Payment Method"}
                  name={"paymentMethod"}
                  data={[
                    {
                      label: "Direct Deposit",
                      value: "direct_deposit",
                    },
                    {
                      label: "Manual",
                      value: "manual",
                    },
                  ]}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PayrollContractorPaymentModal;

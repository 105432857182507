import React, {Component} from "react";
import {CSVLink} from "react-csv";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";

class DownloadToastExport extends Component {
  state = {csvData: null, csvHeaders: null};

  componentDidMount() {
    this.importToast();
  }

  importToast() {
    let {checkTemplateData, paycheckToCheckMapping, exportData, matchedEmployees} =
      this.props.payroll.importData;
    let {data} = checkTemplateData;

    let csvHeaders = Object.keys(data[0]);
    let csvData = [];

    for (const _row of exportData) {
      const entry = {};

      if (_row[0] === "Employee Name") {
        continue;
      }

      entry["Employee ID"] = matchedEmployees[_row[0]];

      for (const key of Object.keys(paycheckToCheckMapping)) {
        if (paycheckToCheckMapping[key].skip) {
          continue;
        }

        entry[key] = _row[paycheckToCheckMapping[key].colOffset];
      }

      csvData.push(entry);
    }

    this.setState({csvHeaders, csvData});
  }

  render() {
    let {csvData, csvHeaders} = this.state;

    return (
      <div className={"px-6"}>
        {csvData && (
          <CSVLink filename={`toast-quarter-export`} data={csvData} headers={csvHeaders}>
            <button
              type="button"
              className="mb-2 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
              onClick={this.downloadImport}
            >
              Download
            </button>
          </CSVLink>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(DownloadToastExport));

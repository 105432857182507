import React, {Component} from "react";
import {Modal, FormBoolean, FormTextArea, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {showErrorNotification} from "../../../utils/notification-helper";

class EmployeeTerminationsModal extends Component {
  state = {employee: null, terminations: []};

  open(employee = null, terminations = []) {
    this.setState({employee, terminations}, () => {
      this.modal.open();
    });
  }

  render() {
    const {employee, terminations} = this.props;

    return (
      <Modal
        large
        buttonLabel="Done"
        ref={(e) => (this.modal = e)}
        label={employee?.FULL_NAME + "'s Terminations"}
      >
        <Table
          columns={[
            {
              width: 1,
              label: "Terminator",
              value: "TERMINATOR_NAME",
            },
            {
              minWidth: 200,
              label: "Termination Notes",
              value: "NOTES",
              empty: "None",
            },
            {
              width: 1,
              label: "Voluntary",
              value: "VOLUNTARY",
              type: "bool",
            },
            {
              width: 200,
              label: "Date Terminated",
              value: "DATE_TERMINATED",
              type: "datetime",
            },
            {
              width: 125,
              label: "Date Reinstated",
              value: "DATE_REINSTATED",
              type: "datetime",
              empty: "-",
            },
          ]}
          data={terminations}
        />
      </Modal>
    );
  }
}

export default EmployeeTerminationsModal;

import React, {Component} from "react";
import {Card, FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import moment from "moment";
import {showConfirmAlert, showErrorAlert} from "../../utils/alert-helper";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import * as Yup from "yup";
import FormStateSelect from "../../components/form-state-select";
import FormCheckbox from "../../components/form-elements/form-checkbox";
import Accordion from "../../components/form-elements/accordion";
import Banner from "../../components/banner";
import {addBreadcrumb} from "@sentry/react";
import {PayrollRequests} from "../../utils/request-helpers/payroll/payroll-requests";
class PostTaxDeductionModal extends Component {
  state = {
    companyEmployees: [],
    deduction: null,
    id: null,
    errorText: null,
    validationSchema: getValidationSchema("miscellaneous"),
  };

  openAdd = () => {
    this.setState(
      {
        id: null,
        deduction: null,
      },
      () => this.fetchAndOpen()
    );
  };

  openEdit = (deduction) => {
    this.setState(
      {
        deduction: deduction,
        id: deduction.id,
        validationSchema: getValidationSchema(deduction.miscellaneous ? "miscellaneous" : "child_support"),
      },
      () => this.fetchAndOpen()
    );
  };

  fetchAndOpen = async () => {
    let companyEmployees = await PayrollRequests.fetchEmployees();
    companyEmployees = companyEmployees.filter((employee) => employee.CHECK_EMPLOYEE_ID);
    this.setState({companyEmployees});
    this.slide.open();
  };

  formatDeduction = (values, id = null) => {
    const deduction = {
      id: id,
      description: values.description || typeToString(values.type),
      effective_start: values.effective_start,
      effective_end: values.effective_end,
      type: values.type,
      managed: values.managed,
    };

    if (values.type === "child_support") {
      const childSupport = values.child_support;
      deduction.child_support = {
        agency: childSupport.agency,
        external_id: childSupport.external_id,
        issue_date: childSupport.issue_date,
        amount: childSupport.amount ? Number(childSupport.amount) : null,
        total_amount: childSupport.total_amount ? Number(childSupport.total_amount) : null,
        percent: childSupport.percent ? Number(childSupport.percent) : null,
        max_percent: childSupport.max_percent ? Number(childSupport.max_percent) : null,
      };
    } else {
      const misc = values.miscellaneous;
      deduction.miscellaneous = {
        frequency: misc.frequency,
        amount: misc.amount ? Number(misc.amount) : null,
        total_amount: misc.total_amount ? Number(misc.total_amount) : null,
        annual_limit: misc.annual_limit ? Number(misc.annual_limit) : null,
        percent: misc.percent ? Number(misc.percent) : null,
      };

      if (misc.frequency === "recurring_indefinitely") {
        deduction.effective_end = null;
      }
      delete deduction.managed;
    }
    return deduction;
  };

  addDeduction = async () => {
    try {
      const values = this.formikRef.values;
      const deduction = this.formatDeduction(values);

      for (const employee of values.employees) {
        const payload = {
          ...deduction,
          employee: employee,
        };
        await PayrollRequests.createPostTaxDeduction({deduction: payload});
      }

      await this.closeModal();
    } catch (error) {
      this.handleError(error);
    }
  };

  handleError = (error) => {
    if (error.message && error.message.startsWith("Invalid child support case ID. ")) {
      this.setState({
        errorText: error.message.replace("Invalid child support case ID. ", ""),
      });
    } else {
      addBreadcrumb("Error with post tax deduction", {deduction: this.state, message: error.message});
      showErrorAlert("Error", "Oops! Something went wrong. Please try again or contact support for help!");
    }
  };

  editDeduction = async () => {
    try {
      const values = this.formikRef.values;
      const deductionPayload = this.formatDeduction(values, this.state.deduction.id);
      deductionPayload.employee = values.employees[0];
      await PayrollRequests.editPostTaxDeduction({deduction: deductionPayload});
      await this.closeModal();
    } catch (error) {
      this.handleError(error);
    }
  };

  deleteDeduction = async () => {
    try {
      await showConfirmAlert(
        "Delete deduction?",
        `Are you sure you would like to delete this post tax deduction?`
      );
      const {deduction} = this.state;
      await PayrollRequests.deletePostTaxDeduction(deduction.id);
    } finally {
      await this.closeModal();
    }
  };

  getModalTitle() {
    return this.isEdit() ? "Edit Deduction" : "Add Deduction";
  }

  isEdit() {
    return !!this.state.id;
  }

  clearState() {
    this.setState({
      deduction: null,
      id: null,
      companyEmployees: [],
      errorText: null,
    });
  }

  async closeModal() {
    this.props.refresh();
    this.clearState();
    this.slide.close();
  }

  getInitialValues() {
    const {deduction} = this.state;

    if (deduction) {
      return {
        employees: [deduction.employee],
        ...deduction,
      };
    }

    return {
      employees: [],
      description: "",
      effective_start: moment().format("YYYY-MM-DD"),
      effective_end: null,
      type: null,
      managed: true,
      child_support: {
        agency: "",
        external_id: "",
        issue_date: "",
        amount: "",
        total_amount: "",
        percent: "",
        max_percent: "",
      },
      miscellaneous: {
        frequency: "",
        amount: "",
        total_amount: "",
        percent: "",
      },
    };
  }

  render() {
    const {errorText, validationSchema} = this.state;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={this.getModalTitle()}
        initialValues
        xlarge
        hideClose
        // backgroundClose = {false}
        buttonLabel={this.isEdit() ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        disableButtonLoading={true}
        deleteLabel={this.isEdit() ? "Remove" : ""}
        deleteOnClick={this.isEdit() ? this.deleteDeduction : () => {}}
      >
        <Formik
          enableReinitialize={true}
          initialValues={this.getInitialValues()}
          onSubmit={this.isEdit() ? this.editDeduction : this.addDeduction}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={validationSchema}
        >
          {(formikOptions, handleSubmit) => {
            const requiredSelected = formikOptions.values.type && formikOptions.values.employees?.length > 0;
            const isChildSupport = formikOptions.values.type === "child_support";
            const isRecurring = formikOptions.values.miscellaneous?.frequency === "recurring";
            const cardLabel = isChildSupport ? "Child Support Deduction" : "Miscellaneous Deduction";
            return (
              <form onSubmit={handleSubmit} className="grid gap-4">
                <div>
                  <Card label={"Select Type"}>
                    <div className="p-8 grid grid-cols-2 gap-4">
                      <FormSelect
                        disabled={this.isEdit()}
                        name={"employees"}
                        label={this.isEdit() ? "Employee" : "Employees"}
                        options={formikOptions}
                        data={this.state.companyEmployees.map(({FULL_NAME, CHECK_EMPLOYEE_ID}) => ({
                          label: FULL_NAME,
                          value: CHECK_EMPLOYEE_ID,
                        }))}
                        onChange={(value) => this.formikRef.setFieldValue("employees", value)}
                        tooltip={"Select the employees for whom the deduction is being made."}
                        multi={true}
                      />
                      <FormSelect
                        disabled={this.isEdit()}
                        name={"type"}
                        label={"Type"}
                        options={formikOptions}
                        data={POST_TAX_TYPES}
                        onChangeSoft={(value) => {
                          this.formikRef.setFieldValue("type", value.value);
                          this.setState({validationSchema: getValidationSchema(value.value)});
                        }}
                        tooltip={
                          "Select the type of deduction. Miscellaneous covers everything besides child support, including union dues."
                        }
                      />
                    </div>
                  </Card>
                </div>
                <div>
                  {requiredSelected && (
                    <Card label={cardLabel}>
                      <div className="p-8 grid grid-cols-2 gap-8">
                        <div>
                          {!isChildSupport && (
                            <div>
                              <FormInput
                                name={"miscellaneous.amount"}
                                label={"Amount"}
                                options={formikOptions}
                                prefix={"$"}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("miscellaneous.amount", value)
                                }
                                tooltip={"Enter the fixed amount of the deduction, if applicable."}
                              />
                              <FormInput
                                name={"miscellaneous.total_amount"}
                                label={"Total Amount"}
                                options={formikOptions}
                                prefix={"$"}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("miscellaneous.total_amount", value)
                                }
                                tooltip={
                                  "Total amount is for the entire effective period of a deduction, and may span multiple payrolls or multiple years if applicable."
                                }
                              />
                              <FormInput
                                name={"miscellaneous.annual_limit"}
                                label={"Annual Limit"}
                                options={formikOptions}
                                prefix={"$"}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("miscellaneous.annual_limit", value)
                                }
                                tooltip={
                                  "Enter the annual limit for the deduction, if applicable. You cannot use both an annual and total limit."
                                }
                              />
                              <FormInput
                                name={"miscellaneous.percent"}
                                label={"Percent"}
                                options={formikOptions}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("miscellaneous.percent", value)
                                }
                                tooltip={
                                  "Enter the percentage of the employee's income that will be deducted."
                                }
                              />
                            </div>
                          )}

                          {isChildSupport && (
                            <div>
                              <FormInput
                                name={"child_support.max_percent"}
                                label={"Max Percent"}
                                options={formikOptions}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("child_support.max_percent", value)
                                }
                                tooltip={
                                  "Enter the maximum percentage of the employee's income that will be deducted."
                                }
                              />
                              <FormInput
                                name={"child_support.external_id"}
                                label={"External ID"}
                                options={formikOptions}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("child_support.external_id", value)
                                }
                                tooltip={
                                  "The unique identifier of the garnishment order, listed as the case number on the order."
                                }
                              />
                              {errorText && (
                                <div className="mt-4 h-16">
                                  <Banner type="error" label={errorText} />
                                </div>
                              )}
                              <FormInput
                                name={"child_support.issue_date"}
                                label={"Issue Date"}
                                options={formikOptions}
                                type={"date"}
                                onChange={(value) =>
                                  this.formikRef.setFieldValue("child_support.issue_date", value)
                                }
                                tooltip={"The date the collections agency issued the order"}
                              />
                            </div>
                          )}
                        </div>

                        <div>
                          {!isChildSupport && (
                            <>
                              <FormInput
                                name={"effective_start"}
                                label={"Start Date"}
                                options={formikOptions}
                                type={"date"}
                                required
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("effective_start", value)
                                }
                                tooltip={
                                  "Select the start date for the deduction. This is the date from which the deduction will begin."
                                }
                              />
                              <FormSelect
                                name={"miscellaneous.frequency"}
                                label={"Ends"}
                                options={formikOptions}
                                data={FREQUENCY_OPTIONS}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("miscellaneous.frequency", value)
                                }
                                tooltip={"Select the frequency of the deduction. If unsure, select Never"}
                              />
                              {isRecurring && (
                                <FormInput
                                  name={"effective_end"}
                                  label={"End Date"}
                                  options={formikOptions}
                                  type={"date"}
                                  format={(value) => (value ? value : "None")}
                                  onChangeSoft={(value) =>
                                    this.formikRef.setFieldValue("effective_end", value)
                                  }
                                  tooltip={
                                    "Select the end date for the deduction. The deduction will only apply to pay runs with pay dates earlier than this date."
                                  }
                                />
                              )}
                            </>
                          )}

                          {isChildSupport && (
                            <div>
                              <FormInput
                                name={"child_support.amount"}
                                label={"Amount"}
                                options={formikOptions}
                                prefix={"$"}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("child_support.amount", value)
                                }
                                tooltip={"Per pay period amount to deduct"}
                              />
                            </div>
                          )}
                          <FormInput
                            name={"description"}
                            label={"Description"}
                            options={formikOptions}
                            onChangeSoft={(value) => this.formikRef.setFieldValue("description", value)}
                            tooltip={
                              "Enter a description for the deduction. This is optional but can be helpful for record-keeping."
                            }
                          />

                          {isChildSupport && (
                            <div>
                              <FormStateSelect
                                label={"Agency"}
                                name={"child_support.agency"}
                                options={formikOptions}
                                onChangeSoft={(value) =>
                                  this.formikRef.setFieldValue("child_support.agency", value)
                                }
                                tooltip={"Select the state agency which issued the child support."}
                              />
                              <div className={"pt-6"}>
                                <Accordion label={"Advanced Preferences"}>
                                  <FormCheckbox
                                    className={"pb-8"}
                                    name={"managed"}
                                    label={"Managed"}
                                    options={formikOptions}
                                    onChange={(value) => this.formikRef.setFieldValue("managed", value)}
                                    tooltip={
                                      "Whether you want us to manage the deduction. If unsure, keep this checked."
                                    }
                                  />
                                </Accordion>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(PostTaxDeductionModal));

function isEmpty(value) {
  return !value || value === "";
}

const exactlyOneNotEmpty = (value1, value2) => {
  return (isEmpty(value1) && !isEmpty(value2)) || (!isEmpty(value1) && isEmpty(value2));
};

function getValidationSchema(type) {
  const baseSchema = {
    employees: Yup.array().of(Yup.string()).required(),
    description: Yup.string().nullable(),
    effective_start: Yup.string().required("Start date required"),
    effective_end: Yup.string().nullable(),
    type: Yup.string().nullable().required(),
  };

  const numericValidation = Yup.string()
    .nullable()
    .matches(/^\d+(\.\d+)?$/, "Amount should be a number")
    .test("is-positive", "Amount should be greater than 0", (value) => {
      if (isEmpty(value)) return true;
      return Number(value) > 0;
    });
  const percentValidation = Yup.string()
    .nullable()
    .matches(/^\d+(\.\d+)?$/, "Percent should be a number")
    .test("is-positive", "Percent should be greater than 0", (value) => {
      if (isEmpty(value)) return true;
      return Number(value) > 0;
    })
    .test("less-than-100", "Percent should be less than or equal to 100", (value) => {
      if (isEmpty(value)) return true;
      return Number(value) <= 100;
    });
  const childSupportSchema = {
    ...baseSchema,
    managed: Yup.boolean(),
    child_support: Yup.object().shape({
      agency: Yup.string().required("Agency is required"),
      external_id: Yup.string().required("External ID is required"),
      issue_date: Yup.string().required("Issue date is required"),
      amount: numericValidation,
      total_amount: numericValidation,
      percent: percentValidation,
      max_percent: percentValidation.test(
        "required-when-not-managed",
        "Max percent is required when managed is false",
        function (value) {
          return this.parent.managed || !isEmpty(value);
        }
      ),
    }),
  };

  const miscSchema = {
    ...baseSchema,
    effective_end: Yup.string()
      .nullable()
      .when("miscellaneous.frequency", {
        is: "recurring",
        then: Yup.string()
          .nullable()
          .required("End date is required if end date is not none")
          .test("after-start", "End date must be after start date", function (value) {
            if (!value) return true;
            return moment(value).isAfter(this.parent.effective_start);
          }),
      }),
    miscellaneous: Yup.object().shape({
      frequency: Yup.string().nullable().required("Select Ends on a Date, or Never in the Ends field"),
      amount: numericValidation
        .test("mutually-exclusive", "Exactly one of amount or percent must be filled", function (value) {
          return exactlyOneNotEmpty(value, this.parent.percent);
        })
        .test("annual-limit", "Amount must be less than or equal to the annual limit", function (value) {
          if (isEmpty(value) || isEmpty(this.parent.annual_limit)) return true;
          return Number(value) <= Number(this.parent.annual_limit);
        })
        .test("total-amount", "Amount must be less than or equal to the total amount", function (value) {
          if (isEmpty(value) || isEmpty(this.parent.total_amount)) return true;
          return Number(value) <= Number(this.parent.total_amount);
        }),
      total_amount: numericValidation,
      percent: percentValidation.test(
        "mutually-exclusive",
        "Exactly one of amount or percent must be filled",
        function (value) {
          return exactlyOneNotEmpty(value, this.parent.amount);
        }
      ),
    }),
  };
  return Yup.object().shape(type === "child_support" ? childSupportSchema : miscSchema);
}

const FREQUENCY_OPTIONS = [
  {label: "Never", value: "recurring_indefinitely"},
  {label: "On a Date", value: "recurring"},
];

const POST_TAX_TYPES = [
  {label: "Miscellaneous", value: "miscellaneous"},
  {label: "Child Support", value: "child_support"},
];

export function typeToString(type) {
  return type
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

import React, {Component} from "react";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import Wizard from "../../../components/wizard";
import CustomFormPage from "./landing-page-types/custom-form-page";
import * as Yup from "yup";
import I9SignPage from "./landing-page-types/i9-part1/i-9-sign-page";
import EmployeeNamePage from "./landing-page-types/i9-part1/employee-name-page";
import EmployeeAddressPage from "./landing-page-types/i9-part1/employee-address-page";
import EmployeeContactPage from "./landing-page-types/i9-part1/employee-contact-page";
import EmployeeAttestationPage from "./landing-page-types/i9-part1/employee-attestation-page";
import {setupReduxConnection} from "../../../redux";
import LanguageSelectPage from "./landing-page-types/i9-part1/language-select-page";
import {Trans} from "react-i18next";
import EmployeeInformationForm from "./landing-page-types/i9-part2/employee-information-form";
import DocumentVerificationForm from "./landing-page-types/i9-part2/document-verification-form";
import EmploymentVerificationForm from "./landing-page-types/i9-part2/employment-verification-form";
import I9Part2SignPage from "./landing-page-types/i9-part2/i-9-part-2-sign-page";
import ReviewI9Part1Page from "./landing-page-types/i9-part2/review-i-9-part-1-page";
import VideoLandingPage from "./landing-page-types/video-landing-page";
import W4Step1 from "./landing-page-types/w4-employee/w4-step-1";
import W4Step2 from "./landing-page-types/w4-employee/w4-step-2";
import W4Step3 from "./landing-page-types/w4-employee/w4-step-3";
import W4Step4 from "./landing-page-types/w4-employee/w4-step-4";
import W4SignPage from "./landing-page-types/w4-employee/w4-sign-page";
import W4CompanyInfoPage from "./landing-page-types/w4-company/w4-company-info-page";
import W4ConfirmPage from "./landing-page-types/w4-company/w4-confirm-page";
import i18next from "i18next";

class OnboardingLandingPage extends Component {
  state = {onboardingChecklist: null, employee: null, language: "eng"};

  componentDidMount() {
    this.syncState();
    i18next.changeLanguage("en");
  }

  async syncState() {
    const {ID} = this.props.router.params;

    const onboardingChecklist = await request("checklists/onboarding/instance/" + ID, "GET", null).catch(
      (err) => {
        if (err.error === "SESSION_INVALID") {
          this.props.router.navigate("/login");
        }
      }
    );

    const employee = await request("employees/" + onboardingChecklist.EMPLOYEE_ID, "GET", null);

    this.setState({onboardingChecklist, employee});
  }

  async submitOnboarding() {
    this.props.router.navigate("/onboarding/overview");
    return true;
  }

  getI18NextKey(path) {
    return "pages.onboarding.onboarding-landing-page." + path;
  }

  getEmployeeNameValidation() {
    return Yup.object({
      firstName: Yup.string().nullable().required("Please enter your first name"),
      middleInitial: Yup.string().nullable(),
      lastName: Yup.string().nullable().required("Please enter your last name"),
      otherLastNames: Yup.string().nullable(),
    });
  }

  getEmployeeAddressValidation() {
    return Yup.object({
      address: Yup.string().nullable().required("Please enter a valid address"),
      aptNumber: Yup.string().nullable(),
      cityOrTown: Yup.string().nullable().required("Please enter your city or town"),
      state: Yup.string().nullable().required("Please enter your state"),
      zip: Yup.number().nullable().required("Please enter your zip code"),
    });
  }

  getEmployeeContactValidation() {
    return Yup.object({
      dateOfBirth: Yup.string().nullable().required("Please enter your date of birth"),
      ssn: Yup.string()
        .nullable()
        .test(
          "length check",
          "Please enter a valid 9 digit social security number.",
          (val) => val && val.length === 11
        ),
      email: Yup.string().nullable().required("Please enter your email address."),
      phone: Yup.string().nullable().required("Please enter your phone number"),
    });
  }

  getEmployeeAttestationValidation() {
    return Yup.object({
      validationType: Yup.string().nullable().required("Please select an attestation type."),
      authorizedAlienValidation: Yup.string()
        .nullable()
        .when("validationType", {
          is: (validationType) => validationType && validationType === "AUTHORIZED_ALIEN",
          then: Yup.string().nullable().required("Please select a validation type"),
        }),
      authorizedWorkExpiration: Yup.string().nullable(),
      registrationNumber: Yup.string()
        .nullable()
        .when("validationType", {
          is: (validationType) => validationType === "PERMANENT_RESIDENT",
          then: Yup.string().nullable().required("Alien Registration Number/USCIS Number is required ."),
        }),
      authorizedAlienNumber: Yup.string()
        .nullable()
        .when(["validationType", "authorizedAlienValidation"], {
          is: (validationType, authorizedAlienValidation) =>
            authorizedAlienValidation === "ALIEN_REGISTRATION_NUMBER" &&
            validationType === "AUTHORIZED_ALIEN",
          then: Yup.string().nullable().required("Alien Registration Number/USCIS Number is required."),
        }),
      admissionNumber: Yup.string()
        .nullable()
        .when(["validationType", "authorizedAlienValidation"], {
          is: (validationType, authorizedAlienValidation) =>
            authorizedAlienValidation === "ADMISSION_NUMBER" && validationType === "AUTHORIZED_ALIEN",
          then: Yup.string().nullable().required("Form I-94 Admission Number is required"),
        }),
      passportNumber: Yup.string()
        .nullable()
        .when(["validationType", "authorizedAlienValidation"], {
          is: (validationType, authorizedAlienValidation) =>
            authorizedAlienValidation === "FOREIGN_PASSPORT" && validationType === "AUTHORIZED_ALIEN",
          then: Yup.string().nullable().required("Passport number is required."),
        }),
      countryOfIssuance: Yup.string()
        .nullable()
        .when(["validationType", "authorizedAlienValidation"], {
          is: (validationType, authorizedAlienValidation) =>
            authorizedAlienValidation === "FOREIGN_PASSPORT" && validationType === "AUTHORIZED_ALIEN",
          then: Yup.string().nullable().required("Country of issuance is required"),
        }),
    });
  }

  getEmployeeInformationValidationPart2() {
    return Yup.object({
      employeeLastName: Yup.string().nullable().required("Please enter the employee's last name"),
      employeeMiddleInitial: Yup.string(),
      employeeFirstName: Yup.string().nullable().required("Please enter the employee's first name"),
      employeeImmigrationStatus: Yup.string()
        .nullable()
        .required("Please enter the employee's immigration status"),
    });
  }

  getDocumentVerificationValidationPart2() {
    return Yup.object({
      documentType: Yup.string().nullable().required("Please select a document type"),
      listADocumentTitle: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "A",
          then: Yup.string().nullable().required("Please enter the document title"),
        }),
      listADocumentIssuingAuthority: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "A",
          then: Yup.string().nullable().required("Please enter the document issuing authority"),
        }),
      listADocumentNumber: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "A",
          then: Yup.string().nullable().required("Please enter the document number"),
        }),
      listADocumentExpirationDate: Yup.string().nullable(),
      listBDocumentTitle: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "BC",
          then: Yup.string().nullable().required("Please enter the document title"),
        }),
      listBDocumentIssuingAuthority: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "BC",
          then: Yup.string().nullable().required("Please enter the document issuing authority"),
        }),
      listBDocumentNumber: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "BC",
          then: Yup.string().nullable().required("Please enter the document number"),
        }),
      listBDocumentExpirationDate: Yup.string().nullable(),
      listCDocumentTitle: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "BC",
          then: Yup.string().nullable().required("Please enter the document title"),
        }),
      listCDocumentIssuingAuthority: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "BC",
          then: Yup.string().nullable().required("Please enter the document issuing authority"),
        }),
      listCDocumentNumber: Yup.string()
        .nullable()
        .when("documentType", {
          is: (documentType) => documentType && documentType === "BC",
          then: Yup.string().nullable().required("Please enter the document number"),
        }),
      listCDocumentExpirationDate: Yup.string().nullable(),
    });
  }

  getEmployerInformationValidationPart2() {
    return Yup.object({
      employeesFirstDay: Yup.string().nullable().required("Please enter the employee's first day"),
      employerLastName: Yup.string().nullable().required("Please enter the employer's last name"),
      employerFirstName: Yup.string().nullable().required("Please enter the employer's first name"),
      employerTitle: Yup.string().nullable().required("Please enter the job title of the employer"),
      employerBusinessName: Yup.string().nullable().required("Please enter the business name"),
      employerAddress: Yup.string().nullable().required("Please enter the employer's address"),
      employerCityOrTown: Yup.string().nullable().required("Please enter the employer's city or town"),
      employerState: Yup.string().nullable().required("Please enter the employer's state"),
      employerZip: Yup.string().nullable().required("Please enter the employer's zip code"),
    });
  }

  getW4Step1Validation() {
    return Yup.object().shape({
      firstName: Yup.string().required("Please enter your first name."),
      middleInitial: Yup.string(),
      lastName: Yup.string().required("Please enter your last name."),
      address: Yup.string().required("Please enter your address."),
      cityOrTown: Yup.string().required("Please enter your city or town."),
      state: Yup.string().required("Please enter your state."),
      zip: Yup.string().required("Please enter your zip code."),
      filingType: Yup.string().required("Please select a filing type."),
      ssn: Yup.string().required("Please enter your social security number."),
    });
  }

  tickItem(item, options) {
    const {onboardingChecklist} = this.state;
    const {values} = options;

    request("checklists/" + onboardingChecklist.ID + "/tick/" + item.ID + "/content", "PATCH", {
      ...values,
      ssn: null,
    });

    return true;
  }

  addLanguageSelect(steps, W4Employee) {
    steps.push({
      id: 0,
      name: <Trans i18nKey={this.getI18NextKey("select-language")} />,
      initialValues: {
        language: i18next.language ?? "en",
      },
      yup: Yup.object({}),
      customValidation: () => {
        if (W4Employee) {
          return this.tickItem(W4Employee, {
            values: {language: i18next.language},
          });
        }

        return true;
      },
      render: (formikOptions) => {
        return (
          <LanguageSelectPage options={formikOptions} setLanguage={(language) => this.setState({language})} />
        );
      },
    });

    return steps;
  }

  addI9Part1(steps, I9Part1, employee) {
    const completedI9Values = I9Part1.TICK;

    steps.push(
      ...[
        {
          id: steps.length,
          name: <Trans i18nKey={this.getI18NextKey("employee-name")} />,
          initialValues: {
            firstName: completedI9Values?.firstName,
            middleInitial: completedI9Values?.middleInitial,
            lastName: completedI9Values?.lastName,
            otherLastNames: completedI9Values?.otherLastNames,
          },
          customValidation: (options) => {
            return this.tickItem(I9Part1, options);
          },
          yup: this.getEmployeeNameValidation(),
          render: (formikOptions) => {
            return <EmployeeNamePage options={formikOptions} />;
          },
        },
        {
          id: steps.length + 1,
          name: <Trans i18nKey={this.getI18NextKey("employee-address")} />,
          initialValues: {
            address: completedI9Values?.address,
            aptNumber: completedI9Values?.aptNumber,
            cityOrTown: completedI9Values?.cityOrTown,
            state: completedI9Values?.state,
            zip: completedI9Values?.zip,
          },
          yup: this.getEmployeeAddressValidation(),
          render: (formikOptions) => {
            return <EmployeeAddressPage options={formikOptions} />;
          },
          customValidation: (options) => {
            return this.tickItem(I9Part1, options);
          },
        },
        {
          id: steps.length + 2,
          name: <Trans i18nKey={this.getI18NextKey("employee-contact")} />,
          initialValues: {
            dateOfBirth: completedI9Values?.dateOfBirth,
            ssn: null,
            email: completedI9Values?.email,
            phone: completedI9Values?.phone,
          },
          yup: this.getEmployeeContactValidation(),
          render: (formikOptions) => {
            return <EmployeeContactPage options={formikOptions} />;
          },
          customValidation: (options) => {
            return this.tickItem(I9Part1, options);
          },
        },
        {
          id: steps.length + 3,
          name: <Trans i18nKey={this.getI18NextKey("employee-attestation")} />,
          initialValues: {
            validationType: completedI9Values?.validationType,
            authorizedAlienValidation: completedI9Values?.authorizedAlienValidation,
            authorizedWorkExpiration: completedI9Values?.authorizedWorkExpiration,
            registrationNumber: completedI9Values?.registrationNumber,
            authorizedAlienNumber: completedI9Values?.authorizedAlienNumber,
            admissionNumber: completedI9Values?.admissionNumber,
            passportNumber: completedI9Values?.passportNumber,
            countryOfIssuance: completedI9Values?.countryOfIssuance,
          },
          yup: this.getEmployeeAttestationValidation(),
          render: (formikOptions) => {
            return <EmployeeAttestationPage options={formikOptions} />;
          },
          customValidation: (options) => {
            return this.tickItem(I9Part1, options);
          },
        },
        {
          id: steps.length + 4,
          name: <Trans i18nKey={this.getI18NextKey("sign-i-9")} />,
          initialValues: {
            i9Document: completedI9Values?.i9Document,
            i9DocumentSigned: completedI9Values?.i9DocumentSigned,
          },
          yup: Yup.object({
            i9DocumentSigned: Yup.boolean().test(
              "SignedTest",
              "Sign your form I9 before continuing. Scroll if the signature box is hidden.",
              (value) => {
                return !!value;
              }
            ),
          }),
          customValidation: () => {
            return this.i9SignPart1Page.handleSubmit();
          },
          render: (formikOptions) => {
            return (
              <I9SignPage
                employee={employee}
                ref={(e) => (this.i9SignPart1Page = e)}
                options={formikOptions}
                details={I9Part1}
              />
            );
          },
        },
      ]
    );
  }

  addI9Part2(steps, I9Part2, employee) {
    const completedI9Values = I9Part2.TICK;

    steps.push(
      ...[
        {
          id: steps.length,
          name: "Review Part 1",
          description: "Review I9 Part 1",
          initialValues: {},
          yup: Yup.object().shape({}),
          render: () => {
            return <ReviewI9Part1Page employee={employee} />;
          },
        },
        {
          id: steps.length + 1,
          name: "Employee Information",
          description: "Enter the Employee's Information",
          initialValues: {
            employeeLastName: completedI9Values?.employeeLastName,
            employeeMiddleInitial: completedI9Values?.employeeMiddleInitial,
            employeeFirstName: completedI9Values?.employeeFirstName,
            employeeImmigrationStatus: completedI9Values?.employeeImmigrationStatus,
          },
          yup: this.getEmployeeInformationValidationPart2(),
          customValidation: (options) => {
            return this.tickItem(I9Part2, options);
          },
          render: (formikOptions) => {
            return <EmployeeInformationForm formikOptions={formikOptions} />;
          },
        },
        {
          id: steps.length + 2,
          name: "Document Verification",
          description: "Complete Document Verification",
          initialValues: {
            documentType: completedI9Values?.documentType,
            listADocumentTitle: completedI9Values?.listADocumentTitle,
            listADocumentIssuingAuthority: completedI9Values?.listADocumentIssuingAuthority,
            listADocumentNumber: completedI9Values?.listADocumentNumber,
            listADocumentExpirationDate: completedI9Values?.listADocumentExpirationDate,
            listBDocumentTitle: completedI9Values?.listBDocumentTitle,
            listBDocumentIssuingAuthority: completedI9Values?.listBDocumentIssuingAuthority,
            listBDocumentNumber: completedI9Values?.listBDocumentNumber,
            listBDocumentExpirationDate: completedI9Values?.listBDocumentExpirationDate,
            listCDocumentTitle: completedI9Values?.listCDocumentTitle,
            listCDocumentIssuingAuthority: completedI9Values?.listCDocumentIssuingAuthority,
            listCDocumentNumber: completedI9Values?.listCDocumentNumber,
            listCDocumentExpirationDate: completedI9Values?.listCDocumentExpirationDate,
          },
          yup: this.getDocumentVerificationValidationPart2(),
          customValidation: (options) => {
            return this.tickItem(I9Part2, options);
          },
          render: (formikOptions) => {
            return <DocumentVerificationForm formikOptions={formikOptions} />;
          },
        },
        {
          id: steps.length + 3,
          name: "Employer Information Verification",
          description: "Enter Employer Information",
          initialValues: {
            employeesFirstDay: null,
            employerLastName: completedI9Values?.employerLastName,
            employerFirstName: completedI9Values?.employerFirstName,
            employerTitle: completedI9Values?.employerTitle,
            employerBusinessName: completedI9Values?.employerBusinessName,
            employerAddress: completedI9Values?.employerAddress,
            employerCityOrTown: completedI9Values?.employerCityOrTown,
            employerState: completedI9Values?.employerState,
            employerZip: completedI9Values?.employerZip,
          },
          yup: this.getEmployerInformationValidationPart2(),
          customValidation: (options) => {
            return this.tickItem(I9Part2, options);
          },
          render: (formikOptions) => {
            return <EmploymentVerificationForm formikOptions={formikOptions} />;
          },
        },
        {
          id: steps.length + 4,
          name: "Complete I9 Part 2",
          description: "Sign and Complete Part 2 of Form I9",
          initialValues: {i9SignedPart2: false, i9DocumentPart2: null},
          yup: Yup.object({
            i9SignedPart2: Yup.boolean().test(
              "SignedTest",
              "Sign your form I9 before continuing. Scroll if the signature box is hidden.",
              (value) => {
                return !!value;
              }
            ),
          }),
          customValidation: async () => this.i9SignPart2Page.handleSubmit(),
          render: (formikOptions) => {
            return (
              <I9Part2SignPage
                ref={(e) => (this.i9SignPart2Page = e)}
                formikOptions={formikOptions}
                employee={employee}
                details={I9Part2}
              />
            );
          },
        },
      ]
    );
  }

  addVideo(steps, item, employee) {
    const {onboardingChecklist} = this.state;

    steps.push({
      id: steps.length,
      name: item.NAME,
      description: "Review Video",
      customValidation: () => this["VIDEO_PAGE" + item.ID].checkVideo(),
      render: () => (
        <VideoLandingPage
          instanceId={onboardingChecklist.ID}
          ref={(e) => (this["VIDEO_PAGE" + item.ID] = e)}
          item={item}
          employee={employee}
          key={"video-page-" + item.ID}
        />
      ),
    });
  }

  addW4Employee(steps, W4, employee) {
    const completedW4Values = W4?.TICK;

    steps.push(
      ...[
        {
          id: steps.length,
          name: "W4 Step 1",
          description: "W4 Step 1",
          yup: this.getW4Step1Validation(),
          initialValues: {
            firstName: completedW4Values?.firstName,
            middleInitial: completedW4Values?.middleInitial,
            lastName: completedW4Values?.lastName,
            address: completedW4Values?.address,
            cityOrTown: completedW4Values?.cityOrTown,
            state: completedW4Values?.state,
            zip: completedW4Values?.zip,
            filingType: completedW4Values?.filingType,
          },
          customValidation: (options) => {
            return this.tickItem(W4, options);
          },
          render: (formikOptions) => <W4Step1 options={formikOptions} />,
        },
        {
          id: steps.length + 1,
          name: "W4 Step 2",
          description: "W4 Step 2",
          yup: Yup.object().shape({}),
          initialValues: {
            step2Type: completedW4Values?.step2Type,
            numberOfJobs: completedW4Values?.numberOfJobs,
            highestPaying: completedW4Values?.highestPaying,
            secondHighestPaying: completedW4Values?.secondHighestPaying,
            lowestPaying: completedW4Values?.lowestPaying,
            numPayPeriods: completedW4Values?.numPayPeriods,
          },
          customValidation: (options) => {
            return this.tickItem(W4, options);
          },
          render: (formikOptions) => <W4Step2 options={formikOptions} />,
        },
        {
          id: steps.length + 2,
          name: "W4 Step 3",
          description: "W4 Step 3",
          initialValues: {
            numberQualifyingDependents: completedW4Values?.numberQualifyingDependents,
            numberOtherDependents: completedW4Values?.numberOtherDependents,
          },
          yup: Yup.object().shape({}),
          customValidation: (options) => {
            return this.tickItem(W4, options);
          },
          render: (formikOptions) => <W4Step3 options={formikOptions} />,
        },
        {
          id: steps.length + 3,
          name: "W4 Step 4",
          description: "W4 Step 4",
          yup: Yup.object().shape({}),
          initialValues: {
            otherIncome: completedW4Values?.otherIncome,
            deductionsBoolean: completedW4Values?.deductionsBoolean,
            extraWitholding: completedW4Values?.extraWitholding,
            itemizedEstimate: completedW4Values?.itemizedEstimate,
            studentLoanEstimate: completedW4Values?.studentLoanEstimate,
          },
          customValidation: (options) => {
            return this.tickItem(W4, options);
          },
          render: (formikOptions) => <W4Step4 options={formikOptions} />,
        },
        {
          id: steps.length + 4,
          name: "Sign W4",
          description: "Sign W4",
          yup: Yup.object().shape({}),
          customValidation: () => {
            return this.w4SignPage.handleSubmit();
          },
          render: (formikOptions) => (
            <W4SignPage
              ref={(e) => (this.w4SignPage = e)}
              details={W4}
              employee={employee}
              options={formikOptions}
            />
          ),
        },
      ]
    );
  }

  addW4Company(steps, W4, employee) {
    const completedW4Values = W4?.TICK;
    const {onboardingChecklist} = this.state;

    steps.push(
      ...[
        {
          id: steps.length,
          name: "Employer Information",
          description: "Employer Information",
          customValidation: (options) => {
            return this.tickItem(W4, options);
          },
          initialValues: {
            employerAddress:
              completedW4Values?.employerAddress ??
              onboardingChecklist.LOCATION.ADDRESS +
                ", " +
                onboardingChecklist.LOCATION.CITY +
                ", " +
                onboardingChecklist.LOCATION.STATE +
                " " +
                onboardingChecklist.LOCATION.ZIPCODE,
            employerName: completedW4Values?.employerName ?? onboardingChecklist.LOCATION.NAME,
            ein: completedW4Values?.ein,
            firstDateOfEmployment: completedW4Values?.firstDateOfEmployment,
          },
          yup: Yup.object().shape({
            employerAddress: Yup.string().required("Employer Address is required."),
            employerName: Yup.string().required("Employer Name is required."),
            ein: Yup.string().required("EIN is required."),
            firstDateOfEmployment: Yup.string().required("First Date of Employment is required."),
          }),
          render: (formikOptions) => <W4CompanyInfoPage employee={employee} options={formikOptions} />,
        },
        {
          id: steps.length + 1,
          name: "Confirm W4",
          description: "Confirm W4",
          customValidation: () => {
            return this.w4ConfirmPage.handleSubmit();
          },
          render: (formikOptions) => (
            <W4ConfirmPage
              ref={(e) => (this.w4ConfirmPage = e)}
              employee={employee}
              options={formikOptions}
              details={W4}
            />
          ),
        },
      ]
    );
  }

  render() {
    const {onboardingChecklist, employee} = this.state;
    const {account} = this.props.user;
    const {location} = this.props.shop;

    if (!onboardingChecklist || !employee) {
      return <Loading />;
    }

    const customForms = onboardingChecklist.ITEMS.filter((item) => item.TYPE === "CUS_FORM");

    const videos = onboardingChecklist.ITEMS.filter((item) => item.TYPE === "VIDEO");

    const I9Part1 = onboardingChecklist.ITEMS.find((item) => item.TYPE === "I9_P1");

    const I9Part2 = onboardingChecklist.ITEMS.find((item) => item.TYPE === "I9_P2");

    const W4Employee = onboardingChecklist.ITEMS.find((item) => item.TYPE === "W4_EMP");

    const W4Company = onboardingChecklist.ITEMS.find((item) => item.TYPE === "W4_COMP");

    const steps = [];
    videos.forEach((video) => this.addVideo(steps, video, employee));

    if (I9Part1 || W4Employee) {
      this.addLanguageSelect(steps, W4Employee);
    }

    if (I9Part1) {
      this.addI9Part1(steps, I9Part1, employee);
    }

    if (I9Part2) {
      this.addI9Part2(steps, I9Part2, employee);
    }

    if (W4Employee) {
      console.log("ADDING EMPLOYEE");
      this.addW4Employee(steps, W4Employee, employee);
    }

    if (W4Company && !W4Employee) {
      this.addW4Company(steps, W4Company, employee);
    }

    steps.push(
      ...customForms.map((item, index) => {
        return {
          id: index + steps.length,
          name: item.NAME,
          initialValues: {},
          customValidation: () => this["CUSTOM_FORM_" + item.OPTIONS[0].NAME].submitForm(),
          render: (formikOptions) => {
            return (
              <CustomFormPage
                ref={(e) => (this["CUSTOM_FORM_" + item.OPTIONS[0].NAME] = e)}
                ID={item.OPTIONS[0].NAME}
                instanceId={onboardingChecklist.ID}
                key={index + steps.length}
                options={formikOptions}
                employee={employee}
              />
            );
          },
          didUnmount: () => {
            this["CUSTOM_FORM_" + index] && this["CUSTOM_FORM_" + index].submitForm();
          },
        };
      })
    );

    return (
      <div className={"px-5 py-5 bg-white h-screen"}>
        <div className={"text-3xl font-bold my-5"}>
          {account ? (
            <Trans
              i18nKey={this.getI18NextKey("welcome-message")}
              values={{
                shopName: location.NAME,
                employeeName: account.FULL_NAME,
              }}
              shouldUnescape={true}
            />
          ) : (
            "Welcome!"
          )}
        </div>

        <Wizard
          flexHeader
          className={"mx-20 h-screen"}
          ref={(e) => (this.wizardRef = e)}
          steps={steps}
          onSubmit={async () => this.submitOnboarding()}
          submitLabel={<Trans i18nKey={this.getI18NextKey("finish")} />}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(withRouter(OnboardingLandingPage));

import React, {Component} from "react";
import {Card, Tab} from "@frostbyte-technologies/frostbyte-tailwind";

class SupplyComingSoonPage extends Component {
  render() {
    let tabs = [
      {id: "inventory", label: "Full-Service Inventory"},
      {id: "vendors", label: "Vendor Management"},
      {id: "cogs", label: "Cost of Goods Reporting"},
    ];

    return (
      <Card className="w-full h-full p-6">
        <div>
          <div className={"lg:w-2/3 m-auto space-y-6"}>
            <div>
              <div className={"text-right w-32"}>
                <img
                  className=" h-auto rounded-lg"
                  src={require("../../../assets/images/logo_type.png")}
                  alt="image description"
                />
                <div className="font-bold text-lg">Supply Chain</div>
              </div>
            </div>

            <div className="w-full flex flex-row items-center m-auto">
              <button
                type="button"
                className="m-auto space-x-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-indigo-600"
              >
                Contact Support to Learn More!
              </button>
            </div>

            <Tab data={tabs}>
              {(id) => {
                if (id === "inventory") {
                  return (
                    <div className="mt-7 flex flex-row">
                      <div className="w-1/2 space-y-4 pr-5">
                        <div className="text-xl font-bold">
                          Manage recipes for products and track ingredient inventory
                        </div>

                        <div className="text-gray-800">
                          You will be able to create recipes for products and modifiers that dynamically track
                          inventory. After setup, our automated inventory system will tell you when your
                          products are out of stock and disable online ordering.
                        </div>
                      </div>

                      <div className=" w-1/2">
                        <img
                          className="w-auto h-auto rounded-lg"
                          src={require("../../../assets/images/recipes.png")}
                          alt="image description"
                        />
                      </div>
                    </div>
                  );
                }

                if (id === "vendors") {
                  return (
                    <div className="mt-7 flex flex-row">
                      <div className="w-1/2 space-y-5 pr-5">
                        <div className="text-xl font-bold">Manage vendors with ease</div>

                        <div className="text-gray-800">
                          We offer an integrated purchase order management system. You will be able to request
                          items from vendors and manage your inventory when purchase orders are fulfilled.
                        </div>
                      </div>

                      <div className=" w-1/2">
                        <img
                          className="w-auto h-auto rounded-lg"
                          src={require("../../../assets/images/vendors.png")}
                          alt="image description"
                        />
                      </div>
                    </div>
                  );
                }

                return (
                  <div className="mt-7 space-y-5">
                    <div className="text-xl font-bold ">Understand your Cost of Goods Sold</div>

                    <div className="text-gray-800">
                      We handle the heavy work: calculating the cost of all ingredients sold so you can find
                      problem areas in your menu. You will quickly be able to find which products are priced
                      incorrectly and adjust them to meet your expected margin.
                    </div>

                    <img
                      className="w-auto h-auto rounded-lg"
                      src={require("../../../assets/images/cogs-report.png")}
                      alt="image description"
                    />
                  </div>
                );
              }}
            </Tab>
          </div>
        </div>
      </Card>
    );
  }
}

export default SupplyComingSoonPage;

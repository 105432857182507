import React, {Component} from "react";
import PayrollInfoAccordion from "./payroll-info-accordion-component";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import b64toBlob from "b64-to-blob";
import {getPaystub} from "../../utils/payroll-helper";

class PayrollInformationComponent extends Component {
  async openPaystub(employeeID) {
    const {ID} = this.props.payroll;
    const pdf = await getPaystub(employeeID, ID);

    const blob = b64toBlob(pdf, "application/pdf");

    window.open(URL.createObjectURL(blob), "_blank");
  }

  renderBenefits() {
    let {BENEFITS} = this.props.payroll;

    return (
      <Table
        noHeader
        columns={[
          {
            label: "Employee",
            value: "EMPLOYEE_NAME",
          },
          {
            label: "Name",
            value: "DESCRIPTION",
          },
          {
            label: "Employee Contribution Amount",
            value: "EMPLOYEE_CONTRIBUTION_AMOUNT",
          },
          {
            label: "Company Contribution Amount",
            value: "COMPANY_CONTRIBUTION_AMOUNT",
          },
        ]}
        data={BENEFITS}
      />
    );
  }

  renderTaxes() {
    let {EMPLOYEE_TAXES, COMPANY_TAXES} = this.props.payroll;

    let TAXES_TOTALS = {
      TAX: "Totals",
      EMP_AMOUNT: toDollars(
        EMPLOYEE_TAXES.reduce((previousValue, curr) => previousValue + curr.AMOUNT, 0),
        true
      ),
      COMP_AMOUNT: toDollars(
        COMPANY_TAXES.reduce((previousValue, curr) => previousValue + curr.AMOUNT, 0),
        true
      ),
    };

    EMPLOYEE_TAXES = EMPLOYEE_TAXES.map((item) => {
      return {
        TAX: item.TAX,
        EMP_AMOUNT: toDollars(item.AMOUNT, true),
      };
    });

    COMPANY_TAXES = COMPANY_TAXES.map((item) => {
      return {
        TAX: item.TAX,
        COMP_AMOUNT: toDollars(item.AMOUNT, true),
      };
    });

    let TAXES = EMPLOYEE_TAXES.concat(COMPANY_TAXES);

    return <Table data={TAXES} columns={TAX_COLUMNS} />;
  }

  renderPayment() {
    let {EMPLOYEE_BREAKDOWN, TOTALS, STATUS} = this.props.payroll;

    let PAY_COLUMNS = [
      {
        label: "Employee Name",
        value: "name",
        format: (value, row) => (row.FULL_NAME ? row.FULL_NAME : "Total"),
      },
      {
        label: "Gross Pay",
        value: "grossPay",
        format: (value, row) => toDollars(row.AMOUNT_GROSS_PAY, true),
      },
      {
        label: "Reimbursements",
        value: "reimbursements",
        format: (value, row) => toDollars(row.AMOUNT_REIMBURSEMENT, true),
      },
      {
        label: "Company Taxes",
        value: "taxes",
        format: (value, row) => toDollars(row.AMOUNT_TAX, true),
      },
      {
        label: "Benefits",
        value: "benefits",
        format: (value, row) => toDollars(row.AMOUNT_BENEFIT, true),
      },
      {
        label: "Subtotal",
        value: "subtotal",
        width: 1,
        format: (value, row) => {
          if (row.EMPLOYEE && STATUS !== "draft") {
            return (
              <span
                className="text-indigo-500 font-bold cursor-pointer"
                onClick={() => this.openPaystub(row.CHECK_EMPLOYEE_ID)}
              >
                {toDollars(row.AMOUNT_SUBTOTAL, true)}
              </span>
            );
          } else {
            return <span>{toDollars(row.AMOUNT_SUBTOTAL, true)}</span>;
          }
        },
      },
    ];

    let finalData = [...EMPLOYEE_BREAKDOWN, TOTALS];

    return (
      <div>
        <Table data={finalData} columns={PAY_COLUMNS} />
        <div className="text-right mt-3 mr-4 pb-3">
          <div className="text-lg font-semibold">{toDollars(TOTALS.AMOUNT_SUBTOTAL, true)}</div>
          <div className="text-md text-gray-600">Total Payroll</div>
        </div>
      </div>
    );
  }

  renderContractorPayroll() {
    let {CONTRACTOR_BREAKDOWN} = this.props.payroll;

    let columns = [
      {
        label: "Name",
        value: "NAME",
      },
      {
        label: "Reimbursement Amount",
        format: (value, row) => {
          return toDollars(row.REIMBURSEMENT_AMOUNT, true);
        },
      },
      {
        label: "Payment Amount",
        value: "AMOUNT",
        format: (value, row) => {
          return toDollars(row.AMOUNT, true);
        },
      },
    ];

    return <Table data={CONTRACTOR_BREAKDOWN} columns={columns} />;
  }

  render() {
    let {className} = this.props;
    let {EMPLOYEE_BREAKDOWN, BENEFITS} = this.props.payroll;

    if (EMPLOYEE_BREAKDOWN.length > 0) {
      return (
        <div className={className}>
          {BENEFITS.length > 0 && (
            <div className="mb-2">
              <PayrollInfoAccordion label={"Benefits"}>
                <div className="mt-3">{this.renderBenefits()}</div>
              </PayrollInfoAccordion>
            </div>
          )}

          <div className="mb-2">
            <PayrollInfoAccordion label={"What Gets Taxed and Debited"}>
              <div className="mt-3">{this.renderTaxes()}</div>
            </PayrollInfoAccordion>
          </div>

          <PayrollInfoAccordion label={"What Your Company Pays"} open={true}>
            <div className="mt-3">{this.renderPayment()}</div>
          </PayrollInfoAccordion>
        </div>
      );
    }

    return this.renderContractorPayroll();
  }
}

const TAX_COLUMNS = [
  {
    label: "Tax Description",
    value: "TAX",
  },
  {
    label: "By Your Employees",
    value: "EMP_AMOUNT",
  },
  {
    label: "By Your Company",
    value: "COMP_AMOUNT",
  },
];

export default PayrollInformationComponent;

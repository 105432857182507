import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import LocationsDropdown from "../../../dropdowns/team/locations-dropdown";
import {setupReduxConnection} from "../../../redux";
import {showErrorAlert, showSuccessAlert} from "../../../utils/alert-helper";
import {showErrorNotification} from "../../../utils/notification-helper";

class ProductSyncModal extends Component {
  state = {product: null, categories: null};

  open(product = null) {
    this.setState({product, categories: null}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  saveProduct = async ({locations}) => {
    const {product} = this.state;

    let productPayload = {
      LOCATIONS: locations,
      PRODUCTS: [product.ID],
    };

    showSuccessAlert(
      "Product Sync Starting",
      "Syncing your product will take a while. Don't leave this page or start another sync until you receive a success notification."
    );

    try {
      setTimeout(() => {
        showSuccessAlert("Product Sync Complete", "The product sync has been completed successfully!");
      }, 250);

      const serverResponse = await request("sync/products", "POST", productPayload);

      this.props.updateState && this.props.updateState();
    } catch (e) {
      this.modal.stopLoading();
      this.modal.close();

      if (e?.error === "MENU_SYNC_IN_PROGRESS") {
        setTimeout(() => {
          showErrorNotification(
            "Menu Sync in Progress.",
            "There is already a menu sync in progress for this company. Please wait for it to finish before starting another."
          );
        }, 250);

        return;
      }

      setTimeout(() => {
        showErrorAlert("Product Sync Error", "There was an error during the product sync");
      }, 250);
    }
  };

  render() {
    const {location} = this.props.shop;

    return (
      <Modal
        buttonLabel="Sync Product"
        label="Sync Product"
        description="Sync a product to a different location"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            locations: [],
            category: null,
          }}
          validationSchema={Yup.object({})}
          onSubmit={this.saveProduct}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <LocationsDropdown
                  isCompany
                  label="Location"
                  name="locations"
                  exclude={[location.ID]}
                  options={formikOptions}
                  onChangeSoft={async ({id}) => {
                    setFieldValue("category", null);

                    const categories = await request("sync/categories/" + id, "GET");

                    this.setState({categories});
                  }}
                  multi
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(ProductSyncModal);

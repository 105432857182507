import {COMPENSATION_TYPES} from "../team-constants";
import {HOURLY_METHOD_TYPES} from "../../modals/team/employee/employee-details-modal";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

export function getIsShowBackDate(employee, compensation, hourlyMethod, defaultHourlyRate, salaryAmount) {
  const isHourly = compensation === COMPENSATION_TYPES.HOUR.VALUE;
  const isSalary = compensation === COMPENSATION_TYPES.SAL.VALUE;

  if (compensation !== employee?.TYPE) {
    return true;
  }

  if (isSalary) {
    return employee?.RATE !== decimalToDollars(salaryAmount);
  }

  if (isHourly) {
    const initialHourlyMethod = !employee?.RATE ? HOURLY_METHOD_TYPES.PAY_RATES : HOURLY_METHOD_TYPES.DEFAULT;
    const isEmployeeSpecificHourly = HOURLY_METHOD_TYPES.DEFAULT;
    const isDifferentEmployeeSpecificRate = employee?.RATE !== decimalToDollars(defaultHourlyRate);

    return (
      initialHourlyMethod !== hourlyMethod || (isEmployeeSpecificHourly && isDifferentEmployeeSpecificRate)
    );
  }
}

import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import ChecklistModal from "../../../modals/operations/checklists/checklist-modal";

class ChecklistsPage extends Component {
  state = {
    isLoading: true,
    checklists: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let checklists = await request("checklists", "GET", {});

    this.setState({isLoading: false, checklists});
  }

  render() {
    const {isLoading, checklists} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Checklists"
          description="Create checklists of tasks for employees to follow during their shifts"
          button={{
            label: "Create Checklist",
            onClick: () => this.checklistModal.open(),
          }}
        />

        <ChecklistModal
          ref={(e) => (this.checklistModal = e)}
          addState={(checklist) => this.props.router.navigate("/checklist/" + checklist.UNIQUE_ID)}
        />

        <Table
          pagination
          className="mt-4"
          data={checklists}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/checklist/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "ITEMS",
              label: "Number of Tasks",
              format: (tasks) => tasks.length,
            },
            {
              width: 1,
              label: "Created",
              value: "DATE_CREATED",
              format: (value, row) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              width: 1,
              label: "Updated",
              value: "DATE_UPDATED",
              format: (value, row) => {
                if (!value) {
                  value = row.DATE_CREATED;
                }

                return moment(value).format("MM/DD/YY");
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ChecklistsPage));

import React, {Component} from "react";
import {Card, Loading, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";
import {withRouter} from "../../../utils/navigation";
import SupplyComingSoonPage from "../supply-chain/supply-coming-soon-page";
import {setupReduxConnection} from "../../../redux";
import {isSupplyChainWhitelisted} from "../../../utils/util";

class InventoryCountsPage extends Component {
  state = {counts: null};

  render() {
    const {counts} = this.props.supply;

    if (!isSupplyChainWhitelisted()) {
      return <SupplyComingSoonPage />;
    }

    if (!counts) {
      return <Loading />;
    }

    return (
      <Card
        label="Inventory Counts"
        buttons={[
          {
            label: "Create New Count",
            onClick: () => this.props.router.navigate("/count/new"),
          },
        ]}
      >
        <Table
          data={counts.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/count/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {label: "Name", value: "NAME"},
            {
              value: "DATE_CREATED",
              label: "Created",
              format: (date) => (date ? moment(date).format("MM/DD/YY hh:mmA") : "-"),
            },
            {
              value: "DATE_COMPLETED",
              label: "Completed",
              format: (date) => (date ? moment(date).format("MM/DD/YY hh:mmA") : "-"),
            },
          ]}
        />
      </Card>
    );
  }
}

export default setupReduxConnection(["shop", "supply"])(withRouter(InventoryCountsPage));

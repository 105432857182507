import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import PropTypes from "prop-types";

class TagDetailsModal extends Component {
  state = {tag: null};

  open(tag = null) {
    this.setState({tag}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createTag({name}) {
    let serverTag = {
      NAME: name,
    };

    const tag = await request("tag", "POST", serverTag);

    this.props.updateState(tag);
    this.modal.close();
  }

  async deleteTag() {
    const {tag} = this.state;

    await request("tag/" + tag.ID, "DELETE", null);

    this.setState({tag: null});

    this.props.onDelete && this.props.onDelete();

    this.modal && this.modal.close();
  }

  async saveTag({name}) {
    const {tag} = this.state;

    let payload = {
      NAME: name,
    };

    const serverTag = await request("tag/" + tag.ID, "POST", payload);

    this.props.updateState(serverTag[0]);
    this.modal.close();
  }

  render() {
    const {tag} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label={tag ? "Edit Tag" : "Create Tag"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteLabel={tag ? "Delete" : null}
        deleteOnClick={() => {
          this.deleteTag();
        }}
      >
        <Formik
          onSubmit={tag ? this.saveTag.bind(this) : this.createTag.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: tag?.NAME,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

TagDetailsModal.propTypes = {
  updateState: PropTypes.func,
};

export default TagDetailsModal;

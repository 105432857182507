import React, {Component} from "react";
import {Modal, FormSelect, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class SupplementalModal extends Component {
  state = {
    payrollId: null,
    employeeId: null,
    name: null,
    employeeLocations: null,
    supplementalTypeValue: null,
  };

  openNew(payrollId, employeeId, name, supplementalTypeValue) {
    this.setState(
      {
        payrollId,
        employeeId,
        name,
        supplemental: {AMOUNT: ""},
        supplementalTypeValue,
      },
      this.modal.open()
    );
  }

  open(payrollId, employeeId, name, supplemental, supplementalTypeValue) {
    this.setState({payrollId, employeeId, name, supplemental, supplementalTypeValue}, this.modal.open());
  }

  delete = async () => {
    await this.submitSupplemental(null, 0);
    await this.props.refresh();

    this.modal.close();
  };

  handleSubmit = async (values) => {
    let {type, amount, hours} = values;

    if (this.submitted) {
      return;
    }

    this.submitted = true;

    await this.submitSupplemental(type, amount, hours);
    await this.props.refresh();

    this.modal.close();

    setTimeout(() => (this.submitted = false), 1000);
  };

  submitSupplemental = async (type, amount, hours) => {
    let {payrollId, employeeId, supplemental} = this.state;

    let payload = {
      CHECK_PAYROLL_ID: payrollId,
      EMPLOYEE_ID: employeeId,
      TIP_TYPE: type,
      AMOUNT: decimalToDollars(amount),
      ID: supplemental?.ID,
      TYPE: type ? SUPPLEMENTAL_TYPES[type].type : null,
      HOURS: hours,
    };

    await request("payroll/run-payroll/set-supplemental", "POST", payload);
  };

  render() {
    const {name, supplemental, supplementalTypeValue} = this.state;
    return (
      <Modal
        label={`Other Earnings for ${name}`}
        buttonLabel="Save"
        deleteLabel="Delete"
        deleteOnClick={() => this.delete()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            type: supplementalTypeValue,
            amount: supplemental ? toDollars(supplemental.AMOUNT) : "",
            hours: supplemental ? supplemental.HOURS : 0,
          }}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            const showHoursField = values.type === "PAID_HOLIDAY";
            const typeValues = Object.values(SUPPLEMENTAL_TYPES).map((type) => ({
              label: type.label,
              value: type.value,
            }));
            return (
              <form onSubmit={handleSubmit}>
                <FormSelect label="Type" name="type" options={formikOptions} data={typeValues} />
                <FormInput label={"Amount"} prefix="$" name={"amount"} options={formikOptions} />
                {showHoursField && <FormInput label={"Hours"} name={"hours"} options={formikOptions} />}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  amount: Yup.string()
    .required()
    .matches(/^[+-]?(\d*\.)?\d+$/, "Amount must be a valid number"),
  hours: Yup.string()
    .nullable()
    .matches(/^[+-]?(\d*\.)?\d+$/, "Hours must be a valid number"),
});

export const SUPPLEMENTAL_TYPES = {
  BONUSES: {
    label: "Bonus",
    value: "BONUSES",
    type: "bonus",
    abbreviation: "B",
  },
  COMMISSIONS: {
    label: "Commission",
    value: "COMMISSIONS",
    type: "commission",
    abbreviation: "C",
  },
  GROUP_TERM_LIFE: {
    label: "Group Term Life",
    value: "GROUP_TERM_LIFE",
    type: "group_term_life",
    abbreviation: "GL",
  },
  SEVERANCE: {
    label: "Severance",
    value: "SEVERANCE",
    type: "severance",
    abbreviation: "S",
  },
  NON_HOURLY_REGULAR: {
    label: "Non Hourly Regular",
    value: "NON_HOURLY_REGULAR",
    type: "non_hourly_regular",
    abbreviation: "NH",
  },
  OTHER_IMPUTED: {
    label: "Other Imputed",
    value: "OTHER_IMPUTED",
    type: "other_imputed",
    abbreviation: "OI",
  },
  TIP_CREDIT_ADJUSTMENT: {
    label: "Tip Credit Adjustment",
    value: "TIP_CREDIT_ADJUSTMENT",
    type: "tip_credit_adjustment_to_minimum_wage",
    abbreviation: "TC",
  },
};

export default SupplementalModal;

import React, {Component} from "react";
import {Card, Filter, Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import LocationModal from "../../../modals/admin/records/location-modal";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

class LocationsPage extends Component {
  state = {locations: null};

  componentDidMount() {
    request("admin/locations", "GET").then((locations) => {
      this.setState({locations});
    });
  }

  render() {
    const {locations} = this.state;

    if (locations === null) {
      return <Loading />;
    }

    const activeLocations = locations.filter((item) => {
      return item.ENABLED === 1;
    });

    const disabledLocations = locations.filter((item) => {
      return item.ENABLED === 0;
    });

    return (
      <>
        <LocationModal ref={(e) => (this.locationModal = e)} />

        <PageHeadings
          label="Locations"
          description="Create and manage locations"
          button={{
            label: "Create Location",
            onClick: () => this.locationModal.open(),
          }}
        />

        <Tab
          data={[
            {label: "All", id: "all", badge: locations.length},
            {
              label: "Active",
              id: "active",
              badge: activeLocations.length,
            },
            {
              label: "Disabled",
              id: "disabled",
              badge: disabledLocations.length,
            },
          ]}
        >
          {(tab) => {
            let sortedLocations = locations;
            if (tab === "active") {
              sortedLocations = activeLocations;
            }

            if (tab === "disabled") {
              sortedLocations = disabledLocations;
            }

            return (
              <Card label="Locations" searchParams searchable>
                {({search}) => {
                  return (
                    <Table
                      pagination
                      search={search}
                      searchFields={["NAME", "COMPANY_NAME"]}
                      data={sortedLocations}
                      actionButtons={[
                        {
                          label: "View",
                          onClick: (row) => {
                            this.props.router.navigate("/admin/location/" + row.ID);
                          },
                        },
                      ]}
                      columns={[
                        {
                          value: "NAME",
                          label: "Name",
                        },
                        {
                          value: "COMPANY_NAME",
                          label: "Company Name",
                        },
                        {
                          value: "ENABLED",
                          label: "Status",
                        },
                      ]}
                    />
                  );
                }}
              </Card>
            );
          }}
        </Tab>
      </>
    );
  }
}

export default withRouter(LocationsPage);

import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import AdminMarketingModal from "../../../modals/admin/marketing/admin-marketing-modal";

import {Loading, PageHeadings, Tab, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {showConfirmAlert, showErrorAlert, showSuccessAlert} from "../../../utils/alert-helper";
import AdminTollFreeVerificationModal from "../../../modals/admin/marketing/admin-toll-free-verification-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";

class CompanyPage extends Component {
  state = {company: null, loading: false};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;
    this.setState({isLoading: true});

    let company = await request("admin/company/" + id, "GET", null);

    if (company.SETTINGS.MARKETING_PHONE_NUMBER) {
      const tollFreeVerification = await request("admin/company/phone/verification/" + id);

      company.SETTINGS.TOLL_FREE_VERIFICATION = tollFreeVerification;
    }

    this.setState({
      company,
      isLoading: false,
    });
  }

  render() {
    const {company, isLoading} = this.state;

    if (!company || isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings label={company.NAME} />

        <AdminMarketingModal
          ref={(e) => (this.marketingModal = e)}
          company={company}
          onUpdate={() => {
            this.syncState();
          }}
        />

        <AdminTollFreeVerificationModal
          ref={(e) => (this.tollFreeModal = e)}
          company={company}
          onUpdate={() => {
            setTimeout(() => {
              this.syncState();
            }, 2000);
          }}
        />

        <TwoColumnList
          label="Company Details"
          description="Details about the contact and the information about them"
          data={[
            {
              label: "ID",
              value: company.ID,
            },
            {
              label: "Name",
              value: company.NAME,
            },
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                console.log("HI");
              },
            },
          ]}
        />

        <Tab data={[{id: "marketing", label: "Marketing Settings"}]}>
          {(id) => {
            if (id === "marketing") {
              const buttonItems = [
                {
                  id: "info",
                  label: "Edit All",
                  description: "Edit Marketing Settings",
                },
              ];

              if (!company.SETTINGS.MARKETING_PHONE_NUMBER) {
                buttonItems.push({
                  id: "phone",
                  label: "Buy Phone",
                  description: "Provision a Twilio phone number",
                });
              }

              if (company.SETTINGS.MARKETING_PHONE_NUMBER && !company.SETTINGS.TOLL_FREE_VERIFICATION) {
                buttonItems.push({
                  id: "verification",
                  label: "Verify Phone Number",
                  description: "Submit a toll-free verification request to twilio",
                });
              }

              return (
                <>
                  <TwoColumnList
                    label="Marketing Settings"
                    description="The Email and Text Marketing Settings for this Company"
                    data={[
                      {
                        label: "Points Name",
                        value: company.SETTINGS.LOYALTY_POINTS_VERBIAGE,
                      },
                      {
                        label: "Marketing Email Name",
                        value: company.SETTINGS.MARKETING_EMAIL_FROM,
                      },
                      {
                        label: "Marketing Email Username",
                        value: company.SETTINGS.MARKETING_EMAIL_USER,
                      },
                      {
                        label: "Marketing Email Subdomain",
                        value: company.SETTINGS.MARKETING_EMAIL_SUBDOMAIN,
                      },
                      {
                        label: "Marketing Phone Number",
                        value: company.SETTINGS.MARKETING_PHONE_NUMBER,
                        type: "phone",
                      },
                      {
                        label: "Marketing Monthly Free Credits",
                        value: company.SETTINGS.TEXT_MARKETING_AUTO_AMOUNT,
                        type: "reloadAmount",
                      },
                    ]}
                    button={{
                      theme: "primary",
                      label: "Options",
                      icon: "plus",
                      type: "list",
                      onChange: ({id}) => {
                        if (id === "info") {
                          this.marketingModal.open();
                        }

                        if (id === "phone") {
                          // Alert
                          showConfirmAlert(
                            "Purchase Phone Number",
                            `Are you sure you would like to purchase a phone number?`
                          )
                            .then(() => {
                              this.setState({isLoading: true});

                              return request("admin/company/phone/" + company.ID, "POST");
                            })
                            .then((data) => {
                              showSuccessAlert("Number Purchased!", "The phone number is " + data);
                            })
                            .then(() => {
                              this.syncState();
                            })
                            .catch((err) => {
                              if (!err) {
                                return;
                              }

                              showErrorAlert("Error buying phone number. Talk to Ben.", err);
                            });
                        }

                        if (id === "verification") {
                          this.tollFreeModal.open();
                        }
                      },
                      items: buttonItems,
                    }}
                  />

                  {!!company.SETTINGS.MARKETING_PHONE_NUMBER && (
                    <TwoColumnList
                      label={
                        <div className={"flex flex-row items-center"}>
                          <div>Toll-Free Verification Information</div>
                          {!company.SETTINGS.TOLL_FREE_VERIFICATION ? (
                            <DangerBadge className="ml-2 mt-1 h-1/2">Submission Needed</DangerBadge>
                          ) : company.SETTINGS.TOLL_FREE_VERIFICATION.status === "PENDING_REVIEW" ? (
                            <DangerBadge yellow className="ml-2 mt-1 h-1/2">
                              Pending Review
                            </DangerBadge>
                          ) : company.SETTINGS.TOLL_FREE_VERIFICATION.status === "IN_REVIEW" ? (
                            <DangerBadge yellow className="ml-2 mt-1 h-1/2">
                              In Review
                            </DangerBadge>
                          ) : company.SETTINGS.TOLL_FREE_VERIFICATION.status === "TWILIO_APPROVED" ? (
                            <SuccessBadge className="ml-2 mt-1 h-1/2">Approved</SuccessBadge>
                          ) : company.SETTINGS.TOLL_FREE_VERIFICATION.status === "TWILIO_REJECTED" ? (
                            <DangerBadge className="ml-2 mt-1 h-1/2">Rejected</DangerBadge>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      description="Information about the status of your toll-free verification submission"
                      data={[
                        {
                          label: "Legal Entity Name",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.businessName ?? "-",
                        },
                        {
                          label: "Business Address",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.businessStreetAddress ?? "-",
                        },
                        {
                          label: "Business Address (line 2)",
                          hint: "Optional",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.businessStreetAddress2 ?? "-",
                        },
                        {
                          label: "Business City",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.businessCity ?? "-",
                        },
                        {
                          label: "Business State",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.businessStateProvinceRegion ?? "-",
                        },
                        {
                          label: "Business Zip",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.businessPostalCode ?? "-",
                        },
                        {
                          label: "Contact Name",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION
                            ? company.SETTINGS.TOLL_FREE_VERIFICATION.businessContactFirstName +
                              " " +
                              company.SETTINGS.TOLL_FREE_VERIFICATION.businessContactLastName
                            : "-",
                        },
                        {
                          label: "Contact Email",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.businessContactEmail ?? "-",
                        },
                        {
                          label: "Contact Phone",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION
                            ? getFormattedPhone(company.SETTINGS.TOLL_FREE_VERIFICATION.businessContactPhone)
                            : "-",
                        },
                        {
                          label: "Aprox. Monthly Volume",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.messageVolume ?? "-",
                        },
                        {
                          label: "Use Case Summary",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.useCaseSummary ?? "-",
                        },
                        {
                          label: "Sample Text",
                          value: company.SETTINGS.TOLL_FREE_VERIFICATION?.productionMessageSample ?? "-",
                        },
                      ]}
                    />
                  )}
                </>
              );
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(CompanyPage);

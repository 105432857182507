import React, {Component} from "react";
import {Formik} from "formik";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import RolePermissions from "../../../features/team/role-permissions";

class RolePermissionForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({permissions}) {
    return {permissions};
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, permissions = []} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        initialValues={{
          permissions,
        }}
      >
        {(formikOptions) => {
          const {setFieldValue, values} = formikOptions;

          return (
            <div className="mt-4">
              <Card
                label={"Permissions"}
                tooltip={{
                  data: "Role permissions provide a way to limit which employees can access which pages on the Partner Dashboard Website. Permissions are granted on a role by role basis, so what ever roles an employee has will determine which pages they can view.",
                }}
                description="Configure which pages employees with this role have access to view"
              >
                <RolePermissions
                  permissions={values.permissions}
                  onSubmit={(data) => {
                    const permKeys = Object.keys(data);
                    const newPermissions = values.permissions.filter((item) => {
                      return permKeys.indexOf(item) === -1;
                    });

                    for (let key of permKeys) {
                      if ("" + data[key] === "1") {
                        newPermissions.push(key);
                      }
                    }

                    setFieldValue("permissions", newPermissions);
                  }}
                />
              </Card>
            </div>
          );
        }}
      </Formik>
    );
  }
}

RolePermissionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

export default RolePermissionForm;

import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Formik} from "formik";
import {Modal, FormBoolean, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class TimeCardNotificationModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {threshold} = values;

    let payload = {
      TIME_CLOCK_NOTIFICATION_THRESHOLD: threshold,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {TIME_CLOCK_NOTIFICATION_THRESHOLD} = this.props.shop.settings;

    const validationSchema = Yup.object().shape({
      threshold: Yup.string()
        .nullable()
        .test(
          "Invalid Late Threshold",
          "Please input a number between 1 and 60 minutes",
          (val) => val === null || val === undefined || (parseInt(val) >= 1 && parseInt(val) <= 60)
        ),
    });

    return (
      <Modal
        label="Time Clock Notification Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            threshold: TIME_CLOCK_NOTIFICATION_THRESHOLD,
          }}
          validationSchema={validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  label="Minutes Late Threshold"
                  name="threshold"
                  tooltip={
                    "Number of minutes after which an employee will be considered late to their shift."
                  }
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(TimeCardNotificationModal);

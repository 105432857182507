import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../utils/navigation";
class StoreBanksTable extends Component {
  render() {
    return (
      <Table
        pagination
        route="storebanks/v2/dumb"
        actionButtons={[
          {
            label: "View",
            onClick: (row) => this.props.router.navigate("/bank/" + row.UNIQUE_ID),
          },
        ]}
        columns={[
          {
            value: "DATE_OPEN",
            label: "Date Opened",
            format: (date) => moment(date).format("M/D/YYYY"),
          },
          {
            value: "NAME",
            label: "Location Name",
          },
          {
            value: "cashDrawers",
            label: "Number of Cash Drawers",
            format: (drawers) => drawers.length,
          },
        ]}
      />
    );
  }
}

export default withRouter(StoreBanksTable);

import React, {Component} from "react";
import ReviewAndConfirmFileModal from "../../../../../modals/team/employee/onboarding/review-and-confirm-file-modal";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Document, Page} from "react-pdf";
import {request} from "../../../../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {PDFDocument} from "pdf-lib";
import {W4_COMPANY_PREFILL_FIELDS, W4_COMPANY_PREFILL_FIELDS_SPANISH} from "../../../../../utils/constants";
import {cloneDeep} from "lodash";
import i18next from "i18next";

class W4ConfirmPage extends Component {
  state = {document: null};

  componentDidMount() {
    const {details} = this.props;

    request("company-documents/w4/pair/" + details.CHECKLIST_INSTANCE_ID, "GET").then(async (document) => {
      document = await this.applyAnnotations(document);

      this.setState({document});
    });
  }

  async applyAnnotations(document) {
    const {values} = this.props.options;

    const editablePDF = await PDFDocument.load(new Uint8Array(document.DATA.data).buffer);

    const page = editablePDF.getPages()[0];
    const FIELDS = document.LANGUAGE === "en" ? W4_COMPANY_PREFILL_FIELDS : W4_COMPANY_PREFILL_FIELDS_SPANISH;

    Object.keys(FIELDS).forEach((field) => {
      const fieldValue = values[field];

      if (!fieldValue) {
        return;
      }

      const fieldDetails = FIELDS[field];

      page.drawText(fieldValue, {
        x: fieldDetails.X_COORDINATE + 5,
        y: page.getHeight() - fieldDetails.Y_COORDINATE - fieldDetails.HEIGHT + 5,
        width: fieldDetails.WIDTH,
        height: fieldDetails.HEIGHT,
        size: 12,
      });
    });

    const pdfBytes = await editablePDF.save();

    return {
      ...document,
      DATA: {
        type: "Buffer",
        data: pdfBytes,
      },
    };
  }

  async submitDocument(document) {
    const {employee, details} = this.props;
    await request("employees/file/" + employee.ID, "POST", document);

    await request(
      "checklists/instances/" + details.CHECKLIST_INSTANCE_ID + "/item/" + details.ID + "/resolve",
      "POST"
    );
  }

  async handleSubmit() {
    const {document} = this.state;
    const documentCopy = cloneDeep(document);
    documentCopy.FILE_NAME = "W4.pdf";
    const w4Document = await this.confirmModal.open(documentCopy);

    if (!!w4Document) {
      this.submitDocument({
        KEY: w4Document.KEY,
        FILE_NAME: "W4.pdf",
        BUCKET: "onboarding-company-documents",
      });

      this.props.options.setFieldValue("i9Document", w4Document);
    }

    return !!w4Document;
  }

  render() {
    const {employee} = this.props;
    const {document} = this.state;

    if (!document) {
      return <Loading />;
    }

    return (
      <>
        <ReviewAndConfirmFileModal
          ref={(e) => (this.confirmModal = e)}
          employee={employee}
          key={randomString(24)}
        />

        <div className={"relative w-full flex flex-col justify-center align-middle items-center"}>
          <div className={"h-[32rem] w-full sm:w-auto relative overflow-y-scroll overflow-x-scroll"}>
            <Document file={document.DATA}>
              <Page pageNumber={1} />
            </Document>
          </div>
        </div>
      </>
    );
  }
}

export default W4ConfirmPage;

import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment";
import {request} from "../../../../utils/request";
import PaymentTypeDetailsModal from "../../../../modals/sales/payments/payment-type-details-modal";

class PaymentTypesPage extends Component {
  state = {
    isLoading: true,
    types: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let types = await request("partner/ptypes", "GET");

    this.setState({isLoading: false, types});
  }

  render() {
    const {isLoading, types} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PaymentTypeDetailsModal
          ref={(e) => (this.paymentModal = e)}
          addState={(state) => this.props.router.navigate("/flow/payment/" + state.UNIQUE_ID)}
        />

        <PageHeadings
          label="Payment Types"
          description="View and modify the payment types that are available at checkout"
          button={{
            label: "Create Payment Type",
            onClick: () => this.paymentModal.open(),
          }}
        />

        <Table
          pagination
          data={types}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/flow/payment/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value, row) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              value: "DATE_UPDATED",
              label: "Updated",
              mobile: "lg:table-cell",
              format: (value) => {
                return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(PaymentTypesPage));

//lol this needs to be rewritten

import {ISO_DAYS} from "./constants";
import moment from "moment";

export function getHours(hours, day, defaultStart, defaultEnd) {
  let storeOpenEpoch = hours[ISO_DAYS[day].toUpperCase() + "_OPEN"];
  let storeCloseEpoch = hours[ISO_DAYS[day].toUpperCase() + "_CLOSE"];

  let open =
    storeOpenEpoch !== "CLOSED" &&
    storeCloseEpoch !== "CLOSED" &&
    storeCloseEpoch !== 0 &&
    storeOpenEpoch !== 0 &&
    storeOpenEpoch &&
    storeCloseEpoch;

  let storeOpenTime, storeCloseTime;
  if (open) {
    storeOpenTime = moment(storeOpenEpoch).hours() * 60 + moment(storeOpenEpoch).minutes();
    storeCloseTime = moment(storeCloseEpoch).hours() * 60 + moment(storeCloseEpoch).minutes();
  } else {
    storeOpenTime = defaultStart;
    storeCloseTime = defaultEnd;
  }

  return {open, storeOpenTime, storeCloseTime, empty: storeOpenEpoch === null};
}

const STATUS = {
  OPENED: "opened",
  CLOSED: "closed",
  DELIVERY_ENABLED: "enabled",
  DELIVERY_DISABLED: "disabled",
};

export function minutesToHourString(minutes) {
  let hour = Math.floor(minutes / 60);
  let minute = minutes % 60;
  let isAM = hour < 12;

  if (hour > 12) {
    hour -= 12;
  }

  return `${hour === 0 ? 12 : hour}:${minute === 0 ? "00" : minute} ${isAM ? "AM" : "PM"}`;
}

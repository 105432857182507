import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {setupReduxConnection} from "../../redux";

class VendorItemsDropdown extends Component {
  renderData(vendorItems) {
    return [...vendorItems].map((item) => {
      return {
        id: item.ID,
        name: item.ID,
        label: item.NAME,
        ...item,
      };
    });
  }

  renderDataWithSections(vendorItems) {
    return Object.values(
      vendorItems.reduce((dict, item) => {
        if (!dict[item.VENDOR_ID]) {
          dict[item.VENDOR_ID] = [item];
        } else {
          dict[item.VENDOR_ID].push(item);
        }

        return dict;
      }, {})
    ).map((item) => {
      return {
        title: item[0].VENDOR_NAME,
        data: item.map((item) => {
          return {item, id: item.ID, label: item.NAME, name: item.NAME};
        }),
      };
    });
  }

  render() {
    const {shouldShowSections = false, vendorItems} = this.props;
    const data = shouldShowSections ? this.renderDataWithSections(vendorItems) : this.renderData(vendorItems);

    const sectionProps = shouldShowSections ? {sections: data} : {data};

    return <ComboBox label="Vendor Item" {...this.props} {...sectionProps} />;
  }
}

export default setupReduxConnection(["supply"])(VendorItemsDropdown);

import React, {Component} from "react";
import {request} from "../../../utils/request";
import b64toBlob from "b64-to-blob";
import LoadingSpinner from "../../../components/loading-spinner";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {Download} from "react-feather";
import {getPayrollEmployees} from "../../../utils/payroll-helper";
import PayrollWhitelistWrapper from "../../../features/payroll/payroll-whitelist-wrapper";
import {withRouter} from "../../../utils/navigation";

class PayrollContractorTaxDocumentsPage extends Component {
  state = {isLoading: true, docData: null, payrollEmployees: null};

  async componentDidMount() {
    let docData = await request("payroll/contractor-tax-documents");
    let payrollContractors = await request("payroll/contractors", "GET", null);

    docData = docData.map((doc) => {
      let contractor = payrollContractors.find(
        (emp) => emp.CHECK_CONTRACTOR_ID === doc.contractor
      ).CONTRACTOR;

      return {
        ...doc,
        name: contractor.first_name + " " + contractor.last_name,
      };
    });

    this.setState({isLoading: false, docData});
  }

  downloadTaxDocument = async (checkDocumentId) => {
    let pdf = await request("payroll/contractor-tax-documents/" + checkDocumentId + "/download");

    let blob = b64toBlob(pdf, "application/pdf");
    let blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };

  render() {
    let {isLoading, docData} = this.state;

    let columns = [
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Label",
        value: "label",
      },
      {
        label: "Jurisdiction",
        value: "jurisdiction",
      },
      {
        label: "Year",
        value: "year",
      },
      {
        label: "Filed On",
        value: "filed_on",
      },
      {
        label: "Download",
        value: "id",
        format: (value) => {
          return (
            <div style={{cursor: "pointer"}} onClick={() => this.downloadTaxDocument(value)}>
              <Download />
            </div>
          );
        },
      },
    ];

    return (
      <PayrollWhitelistWrapper router={this.props.router}>
        <PageHeadings label={"Contractor Tax Documents"} />
        <Card>
          {isLoading && (
            <div className={"py-10"}>
              <LoadingSpinner />
            </div>
          )}

          {!isLoading && (
            <div>
              <Table pagination columns={columns} data={docData} />
            </div>
          )}
        </Card>
      </PayrollWhitelistWrapper>
    );
  }
}

export default withRouter(PayrollContractorTaxDocumentsPage);

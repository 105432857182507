import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {PageHeadings, Tab, Filter} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import ModifierModal from "../../../modals/sales/modifiers/modifier-modal";
import EmployeeTable from "../../../tables/team/employee-table";
import EmployeeDetailsModal from "../../../modals/team/employee/employee-details-modal";

class EmployeesPage extends Component {
  state = {data: null, locations: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverEmployees = await request("employees/v2", "GET", {
      ROLES: true,
      ACTIVE: 0,
    });

    const serverLocations = await request("partner/franchise/locations", "GET");

    this.setState({data: serverEmployees, locations: serverLocations});
  }

  render() {
    const {location} = this.props.shop;
    const {data, locations} = this.state;

    const activeEmployees = data?.filter((item) => item.ACTIVE === 1);
    const inactiveEmployees = data?.filter((item) => item.ACTIVE === 0);

    return (
      <div>
        <ModifierModal ref={(e) => (this.modifierModal = e)} />

        <EmployeeDetailsModal
          addState={(employee) => this.props.router.navigate("/employee/" + employee.UNIQUE_ID)}
          ref={(e) => (this.employeeModal = e)}
        />

        <PageHeadings
          label="Employees"
          description="View and edit employees"
          button={{
            label: "Create Employee",
            onClick: () => this.employeeModal.open(),
          }}
        />

        <Tab
          data={[
            {
              id: "active",
              label: "Active Employees",
              badge: activeEmployees ? "" + activeEmployees.length : "",
            },
            {
              id: "inactive",
              label: "Inactive Employees",
              badge: inactiveEmployees ? "" + inactiveEmployees.length : "",
            },
          ]}
        >
          {(id) => {
            let finalData = null;

            if (data) {
              if (id === "inactive") {
                finalData = inactiveEmployees;
              } else if (id === "active") {
                finalData = activeEmployees;
              }
            }

            return (
              <Filter
                csv={{
                  file: "employees",
                  data: finalData,
                  headers: [
                    {label: "Name", key: "FULL_NAME"},
                    {label: "Phone", key: "PHONE"},
                    {label: "Email", key: "EMAIL"},
                    {label: "Start Date", key: "DATE_STARTED", type: "date"},
                  ],
                }}
                searchable
                selectAll
                defaultFilters={[{filter: "location", id: location.ID, label: location.NAME}]}
                data={[
                  {
                    id: "location",
                    label: "Home Location",
                    options: locations.map((item) => {
                      return {id: item.ID, label: item.NAME};
                    }),
                    onFilter: (options, data) => {
                      if (options.length === 0) {
                        return data;
                      }

                      return data.filter((item) => options.includes(item.LOCATION_ID));
                    },
                  },
                ]}
              >
                {(filters, search) => {
                  return (
                    <EmployeeTable
                      sortable
                      pagination
                      striped
                      searchFields={["FULL_NAME", "PHONE"]}
                      filters={filters}
                      search={search}
                      data={finalData}
                    />
                  );
                }}
              </Filter>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(EmployeesPage));

import React, {Component} from "react";
import {PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import CashAccountCreateCardModal from "../../../modals/cash-account/cards/cash-account-create-card-modal";
import {request} from "../../../utils/request";
import {
  faCircleXmark,
  faCreditCard,
  faLocationXmark,
  faLock,
  faRecycle,
  faUnlock,
} from "@fortawesome/pro-light-svg-icons";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import VisaBadge from "../../../features/finance/cash-account/visa-badge";
import PopperDropdown from "../../../components/popper-dropdown";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import moment from "moment";
import GrayBadge from "../../../components/badges/gray-badge";
import CardLostOrStolenModal from "../../../modals/cash-account/cards/card-lost-or-stolen-modal";
import WarningBadge from "../../../components/badges/warning-badge";
import ActivateCardModal from "../../../modals/cash-account/cards/activate-card-modal";

class CashAccountCardsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {cards: null};
    this.id = "fs_" + randomString(24);
  }

  state = {cards: null};

  async componentDidMount() {
    await this.syncState();
  }

  async syncState() {
    const cards = await request("cash-account/cards");

    this.setState({cards});
  }

  async performCardAction(value, card) {
    switch (value) {
      case CARD_ACTIONS.ACTIVATE.value:
        this.activateCardModal.open();
        break;
      case CARD_ACTIONS.FREEZE.value:
        showLoadingConfirmAlert(
          "Are you sure you want to freeze this card?",
          "You won’t be able to use your card while it is frozen, but you can unfreeze it at any time."
        )
          .then(async (close) => {
            await this.freezeCard(card.UNIQUE_ID);
            await this.syncState();
            close();
          })
          .catch();
        break;
      case CARD_ACTIONS.UNFREEZE.value:
        showLoadingConfirmAlert(
          "Are you sure you want to unfreeze this card?",
          "This card will become active and you can use it as usual."
        )
          .then(async (close) => {
            await this.unfreezeCard(card.UNIQUE_ID);
            await this.syncState();
            close();
          })
          .catch();
        break;
      case CARD_ACTIONS.CLOSE.value:
        showLoadingConfirmAlert("Are you sure you want to close this card?", "You cannot undo this action.")
          .then(async (close) => {
            await this.closeCard(card.UNIQUE_ID);
            await this.syncState();
            close();
          })
          .catch();
        break;
      case CARD_ACTIONS.REPORT.value:
        this.lostOrStolenModal.open(card);
        break;
    }
  }

  async freezeCard(cardUniqueId) {
    await request(`cash-account/cards/${cardUniqueId}/freeze`, "POST");
  }

  async unfreezeCard(cardUniqueId) {
    await request(`cash-account/cards/${cardUniqueId}/unfreeze`, "POST");
  }

  async closeCard(cardUniqueId) {
    await request(`cash-account/cards/${cardUniqueId}/close`, "POST");
  }

  render() {
    const {cards} = this.state;

    return (
      <div>
        <CashAccountCreateCardModal ref={(e) => (this.createCardModal = e)} />

        <ActivateCardModal ref={(e) => (this.activateCardModal = e)} />

        <CardLostOrStolenModal ref={(e) => (this.lostOrStolenModal = e)} syncState={this.syncState} />

        <PageHeadings
          label={"Cards"}
          button={{
            label: "Create Card",
            onClick: () => this.createCardModal.open(),
          }}
        />

        <div id={"cardsTable"}>
          <Table
            className="mt-4 overflow-auto"
            data={cards}
            columns={[
              {
                label: "Card Details",
                format: (value, row) => {
                  const cardTypeStr = row.IS_PHYSICAL ? "Visa Debit" : "Visa Virtual Debit";

                  return (
                    <div className={"flex flex-row space-x-5"}>
                      <VisaBadge
                        inactive={[CARD_STATUSES.FROZEN].includes(row.STATUS)}
                        disabled={[CARD_STATUSES.CLOSED, CARD_STATUSES.LOST, CARD_STATUSES.STOLEN].includes(
                          row.STATUS
                        )}
                      />
                      <div className={"flex flex-col justify-center"}>
                        <div className={"text-black"}>•••{row.LAST_4}</div>
                        <div className={"text-xs"}>{cardTypeStr}</div>
                      </div>
                    </div>
                  );
                },
              },
              {
                label: "Cardholder",
                value: "FULL_NAME",
              },
              {
                label: "Type",
                value: "IS_PHYSICAL",
                format: (value) => (value ? "Physical" : "Virtual"),
              },
              {
                label: "Exp. Date",
                value: "EXPIRATION_DATE",
                format: (value) => moment(value).format("M/YY"),
              },
              {
                label: "Status",
                value: "STATUS",
                format: (value) => {
                  if (value === "ACTIVE") {
                    return <SuccessBadge>Active</SuccessBadge>;
                  }

                  if (value === "FROZEN") {
                    return <DangerBadge blue={true}>Frozen</DangerBadge>;
                  }

                  if (value === "CLOSED") {
                    return <GrayBadge>Closed</GrayBadge>;
                  }

                  if (value == "LOST") {
                    return <GrayBadge>Lost</GrayBadge>;
                  }

                  if (value == "STOLEN") {
                    return <GrayBadge>Lost</GrayBadge>;
                  }

                  if (value === "INACTIVE") {
                    return <WarningBadge>Inactive</WarningBadge>;
                  }
                },
              },
              {
                label: "",
                value: "",
                width: 1,
                format: (value, row) => (
                  <PopperDropdown
                    pepe={this.id}
                    className="-mt-2"
                    container={"cardsTable"}
                    data={Object.values(CARD_ACTIONS).filter((_action) =>
                      _action.statuses.includes(row.STATUS)
                    )}
                    onChange={({value}) => this.performCardAction(value, row)}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const CARD_STATUSES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  FROZEN: "FROZEN",
  LOST: "LOST",
  STOLEN: "STOLEN",
  CLOSED: "CLOSED",
};

const CARD_ACTIONS = {
  ACTIVATE: {
    label: "Activate Card",
    value: "ACTIVATE",
    icon: faCreditCard,
    statuses: [CARD_STATUSES.INACTIVE],
  },
  FREEZE: {
    label: "Freeze Card",
    value: "FREEZE",
    icon: faLock,
    statuses: [CARD_STATUSES.ACTIVE],
  },
  UNFREEZE: {
    label: "Unfreeze",
    value: "UNFREEZE",
    icon: faUnlock,
    statuses: [CARD_STATUSES.FROZEN],
  },
  PIN: {
    label: "Manage PIN",
    icon: faCreditCard,
    value: "PIN",
    statuses: [],
  },
  REPLACE: {
    label: "Replace Card",
    icon: faRecycle,
    value: "REPLACE",
    statuses: [CARD_STATUSES.ACTIVE],
  },
  REPORT: {
    label: "Report Lost or Stolen",
    icon: faLocationXmark,
    value: "REPORT",
    statuses: [CARD_STATUSES.ACTIVE, CARD_STATUSES.FROZEN],
  },
  CLOSE: {
    label: "Close Card",
    icon: faCircleXmark,
    value: "CLOSE",
    statuses: [CARD_STATUSES.ACTIVE, CARD_STATUSES.FROZEN],
  },
};

export default CashAccountCardsPage;

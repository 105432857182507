import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getStore, setupReduxConnection} from "../../../redux";
import KioskLogosModal from "../../../modals/shop/shop-settings/kiosk-logos-modal";
import {updateShop} from "../../../redux/shop";

class KioskSettingsPage extends Component {
  render() {
    const {settings, location} = this.props.shop;

    return (
      <>
        <KioskLogosModal
          ref={(e) => (this.kioskModal = e)}
          updateState={(updated) => {
            getStore().dispatch(updateShop({location, settings: {...settings, ...updated}}));
          }}
        />

        <TwoColumnList
          label="Kiosk Settings"
          description="Configure the images displayed on the kiosk"
          button={{
            label: "Edit Logos",
            onClick: () => {
              this.kioskModal.open(settings.KIOSK_LOGO, settings.KIOSK_BANNER);
            },
          }}
          data={[
            {
              label: "Kiosk Logo",
              value: (
                <img
                  className="h-60 w-120 mt-12"
                  src={getObjectImage(settings, "KIOSK_LOGO", "appicon.png")}
                  alt=""
                />
              ),
            },
            {
              label: "Kiosk Banner",
              value: (
                <img
                  className="h-60 w-120 mt-12"
                  src={getObjectImage(settings, "KIOSK_BANNER", "appicon.png")}
                  alt=""
                />
              ),
            },
          ]}
        />
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(KioskSettingsPage);

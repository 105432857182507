import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PayrollImportTable from "./payroll-import-table";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class CsvMapper extends Component {
  state = {steps: [], currentIndex: 0};

  componentDidMount() {
    this.setState({steps: this.props.initialSteps});

    //TODO mix steps in with redux
  }

  setSteps = (steps) => {
    this.setState({steps});
  };

  getSteps = () => {
    return this.state.steps;
  };

  handleSkip = () => {
    let {steps, currentIndex} = this.state;

    if (currentIndex >= steps.length) {
      return;
    }

    steps[currentIndex].value = {skip: true};

    this.setState((prevState) => ({steps, currentIndex: prevState.currentIndex + 1}));
  };

  handleCellClick = (row, col) => {
    let {steps, currentIndex} = this.state;

    if (currentIndex >= steps.length) {
      return;
    }

    steps[currentIndex].value = {row, col, skip: false};

    this.setState((prevState) => ({steps, currentIndex: prevState.currentIndex + 1}));
  };

  render() {
    let {showFirstPaycheck, allowSkips} = this.props;
    let {firstPaycheckStartRow, paycheckIncrementer} = this.props.payroll.importData;
    let {steps, currentIndex} = this.state;

    let startRow = 0;
    let maxRows = 50;

    if (showFirstPaycheck) {
      startRow = firstPaycheckStartRow;
      maxRows = paycheckIncrementer;
    }

    let stepsDict = steps.reduce((dict, step) => {
      if (step.value) {
        dict[step.value.row + " " + step.value.col] = true;
      }
      return dict;
    }, {});

    return (
      <div className={"flex flex-row space-x-8 px-6"}>
        <div style={{width: 300}} className={"border-gray-100"}>
          <div className="mb-3 font-semibold">Steps</div>

          <div
            className="flex flex-col space-y-2 overflow-y-auto"
            style={{
              maxHeight: 500,
            }}
          >
            {steps.map((step, index) => (
              <div
                className={classNames(
                  currentIndex === index && "border-indigo-400 border-2",
                  "justify-between import-step items-center flex flex-row px-2 py-2 hover:bg-gray-100 cursor-pointer"
                )}
                onClick={() => this.setState({currentIndex: index})}
              >
                <div>
                  {!step.value && <span className="mr-3">{index + 1}.</span>}

                  {step.value && (
                    <FontAwesomeIcon className="text-indigo-500 mr-3" icon="fa-solid fa-check" />
                  )}

                  {step.label}
                </div>

                {step?.value?.skip && <div>Skip</div>}
              </div>
            ))}
          </div>
        </div>

        <div className={"flex flex-col"}>
          <PayrollImportTable
            handleClick={this.handleCellClick}
            stepsDict={stepsDict}
            currentStepValue={steps[currentIndex]?.value}
            startRow={startRow}
            maxRows={maxRows}
          />
          {allowSkips && (
            <button
              type="button"
              className="w-48 mb-2 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
              onClick={this.handleSkip}
            >
              Skip
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(CsvMapper);

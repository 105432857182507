import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Formik} from "formik";
import {Modal, FormBoolean} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";

class TimeCardApproveAllModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {noPayEarned} = values;

    let payload = {
      TIME_CARD_NO_PAY_EARNED: noPayEarned,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {TIME_CARD_NO_PAY_EARNED} = this.props.shop.settings;

    return (
      <Modal
        label="Time Card Approve All Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            noPayEarned: TIME_CARD_NO_PAY_EARNED,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean name="noPayEarned" label={"Approve no pay time cards"} options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(TimeCardApproveAllModal);

import React, {Component} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DraggableBox from "./draggable-box";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfViewer extends Component {
  state = {numPages: null, page: 1};

  onDocumentLoad(params) {
    const {numPages} = params;

    this.setState((prevState) => {
      if (prevState.numPages !== numPages) {
        return {numPages, page: 1};
      }
    });
  }

  renderFields() {
    const {fields, deleteField, fieldsDisabled, currentField, onFieldClick} = this.props;
    const {page} = this.state;

    if (!fields) {
      return;
    }

    return fields
      .filter((field) => !!field && field.PAGE === page)
      .map((field) => (
        <DraggableBox
          field={field}
          deleteField={(field) => deleteField(field)}
          disabled={fieldsDisabled}
          selected={field.ID === currentField}
          onFieldClick={(field) => onFieldClick(field)}
        />
      ));
  }

  render() {
    const {document, extraStyle, updatePage} = this.props;
    const {numPages, page} = this.state;

    return (
      <>
        <div className={"relative flex flex-col justify-center align-middle items-center"}>
          <div className={"h-[32rem] relative overflow-y-scroll " + extraStyle}>
            <Document file={document.DATA} onLoadSuccess={(params) => this.onDocumentLoad(params)}>
              <Page pageNumber={page} />
              {this.renderFields()}
            </Document>
          </div>

          <div className={"flex flex-row justify-center mt-4 space-x-6"}>
            <button
              className={classNames(
                "border  px-3 py-2 rounded-md",
                page === 1
                  ? "bg-gray-500 text-gray-100 border-gray-500"
                  : "bg-indigo-500 text-white border-indigo-500"
              )}
              onClick={() => {
                this.setState((prevState) => {
                  updatePage && updatePage(Math.max(prevState.page - 1, 1));

                  return {page: Math.max(prevState.page - 1, 1)};
                });
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-left" />
            </button>

            <button
              className={classNames(
                "border  px-3 py-2 rounded-md",
                page === numPages
                  ? "bg-gray-500 text-gray-100 border-gray-500"
                  : "bg-indigo-500 text-white border-indigo-500"
              )}
              onClick={() => {
                this.setState((prevState) => {
                  updatePage && updatePage(Math.min(prevState.page + 1, numPages));

                  return {page: Math.min(prevState.page + 1, numPages)};
                });
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-right" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default PdfViewer;

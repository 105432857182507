import React, {Component} from "react";
import {FormInput, FormTextArea, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import * as Yup from "yup";
import {Formik} from "formik";
import {request} from "../../utils/request";
import LocationsDropdown from "../../dropdowns/team/locations-dropdown";
import FormRow from "../../components/form-row";

class MemoDetailsModal extends Component {
  state = {memo: null};

  open(memo) {
    this.setState({memo}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createMemo({location, content, subject, reply}) {
    const memoPayload = {
      REPLY_TO: reply,
      CONTENT: content,
      SUBJECT: subject,
      LOCATION_ID: location,
    };

    const serverMemo = await request("memos", "POST", memoPayload);

    this.props.addState(serverMemo);
    this.modal.close();
  }

  async saveMemo({location, content, subject, reply}) {
    const {memo} = this.state;

    const dataLocation = this.locationRef.fetchLocation();
    const memoPayload = {
      LOCATION_ID: location,
      REPLY_TO: reply,
      CONTENT: content,
      SUBJECT: subject,
    };

    await request("memos/" + memo.ID, "PATCH", memoPayload);

    this.props.updateState({
      ...memo,
      ...memoPayload,
      LOCATION_NAME: dataLocation?.label ?? null,
    });

    this.modal.close();
  }

  render() {
    const {memo} = this.state;

    return (
      <Modal
        large
        buttonLabel={memo ? "Save" : "Create"}
        label={memo ? "Edit Memo" : "Create Memo"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={memo ? this.saveMemo.bind(this) : this.createMemo.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            reply: memo?.REPLY_TO ?? "",
            subject: memo?.SUBJECT ?? "",
            content: memo?.CONTENT ?? "",
            location: memo?.LOCATION_ID ?? null,
          }}
          validationSchema={Yup.object({
            reply: Yup.string().required("Reply email is required"),
            subject: Yup.string().required("Subject is required"),
            content: Yup.string().required("Content is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <LocationsDropdown
                    allowNull
                    ref={(e) => (this.locationRef = e)}
                    label="Target Location"
                    options={formikOptions}
                    name="location"
                    flex
                  />

                  <FormInput label="Reply Email" name="reply" options={formikOptions} flex />
                </FormRow>

                <FormInput label="Subject" name="subject" options={formikOptions} />

                <FormTextArea
                  style={{minHeight: 200}}
                  options={formikOptions}
                  label="Content"
                  name="content"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default MemoDetailsModal;

export function fetchEmployeeHomeLocationsString(locationsArr, homeLocation = "") {
  if (!locationsArr || locationsArr.length === 0) {
    return homeLocation;
  }

  if (locationsArr.length === 1) {
    return locationsArr[0].NAME;
  }

  let toReturn = locationsArr.reduce((accum, item) => {
    accum += item.NAME + ", ";

    return accum;
  }, "");

  return toReturn.slice(0, toReturn.length - 2);
}

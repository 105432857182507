import React, {Component, Fragment} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {Menu} from "@headlessui/react";
import {ROLE_COLORS} from "../../utils/team-constants";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReactDOM from "react-dom";
import {Manager, Popper, Reference} from "react-popper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ColorDropdown extends Component {
  constructor(props) {
    super(props);

    this.id = "cd_" + randomString(24);
  }

  renderOptions(options, name, removable) {
    return (
      <div className="flex flex-wrap flex-row p-2 justify-items-start">
        {removable && (
          <Menu.Item>
            <div
              className={
                "h-7 w-7 cursor-pointer m-2 bg-gray-200 text-center text-red-600 flex-col flex justify-center"
              }
              onClick={() => options.setFieldValue(name, null)}
            >
              <FontAwesomeIcon icon="fa-solid fa-xmark-large" />
            </div>
          </Menu.Item>
        )}

        {ROLE_COLORS.map((color) => {
          return (
            <Menu.Item>
              <div
                style={{backgroundColor: `#${color}`}}
                className={"h-7 w-7 cursor-pointer m-2"}
                onClick={() => {
                  options.setFieldValue(name, color);
                }}
              />
            </Menu.Item>
          );
        })}
      </div>
    );
  }

  renderButton(value) {
    return (
      <Menu.Button
        className={
          "w-full rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-50"
        }
      >
        <div
          style={{
            backgroundColor: value ? `#${value}` : "transparent",
          }}
          className={"h-5 w-5 rounded-sm "}
        />
      </Menu.Button>
    );
  }

  render() {
    const {relative, removable} = this.props;

    return (
      <FormElement id={this.id} {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft, {options, name}) => {
          return (
            <Manager>
              <Menu as="div" className="relative inline-block text-left">
                {({open}) => {
                  return (
                    <>
                      <Reference>
                        {({ref}) => (
                          <div className={"flex flex-row"} ref={ref}>
                            {this.renderButton(value)}
                          </div>
                        )}
                      </Reference>

                      {open &&
                        ReactDOM.createPortal(
                          <Popper
                            innerRef={(e) => (this.popperRef = e)}
                            placement={"bottom-start"}
                            strategy={"absolute"}
                          >
                            {({ref, style, placement, arrowProps}) => (
                              <Menu.Items
                                ref={ref}
                                data-placement={placement}
                                style={style}
                                unmount={false}
                                className={classNames(
                                  relative ? "relative" : "absolute",
                                  "origin top-right mt-2 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                                )}
                              >
                                {this.renderOptions(options, name, removable)}
                              </Menu.Items>
                            )}
                          </Popper>,
                          document.querySelector("#" + this.id)
                        )}
                    </>
                  );
                }}
              </Menu>
            </Manager>
          );
        }}
      </FormElement>
    );
  }
}

export default ColorDropdown;

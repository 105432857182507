import {request} from "../../request";

export const MarketingRequests = {
  fetchList: async (uniqueId) => {
    return request("text/marketing/list", "GET", {UNIQUE_ID: uniqueId});
  },
  fetchDefaultList: async () => {
    return request("text/marketing/list", "GET");
  },
  deleteList: async (listId) => {
    return request("text/marketing/list/" + listId, "DELETE", null);
  },
  addPatronsToList: async (listId, patrons) => {
    return request("text/marketing/list/patrons/", "POST", {
      LIST_ID: listId,
      PATRON_IDS: patrons,
    });
  },
  deletePatronsFromList: async (listId, patronIds) => {
    return request("text/marketing/list/patrons", "DELETE", {
      LIST_ID: listId,
      PATRON_IDS: patronIds,
    });
  },
  getMarketingLists: async () => {
    return request("text/marketing/lists", "GET");
  },
};

export const GiftCardRequests = {
  archive: async (locationId) => {
    return request("cards/archive/" + locationId, "PATCH");
  },
};

import React, {Component} from "react";
import {Modal, FormBoolean} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import HoursRange from "../../features/shop/shop-page/hours-range";
import {request} from "../../utils/request";
import {HOURS_TYPES} from "../../utils/shop-constants";

class HoursModal extends Component {
  state = {
    hoursType: null,
    storeHours: null,
    selectedHours: null,
    useStoreHours: false,
  };

  async open(hoursType) {
    let {
      HOURS: storeHours,
      DELIVERY_HOURS: deliveryHours,
      THIRD_PARTY_HOURS: thirdPartyHours,
    } = await request("hours", "GET");

    let selectedHours = storeHours;

    if (hoursType === HOURS_TYPES.THIRD_PARTY) {
      selectedHours = thirdPartyHours;
    }

    if (hoursType === HOURS_TYPES.DELIVERY) {
      selectedHours = deliveryHours;
    }

    let useStoreHours = selectedHours.USE_STORE_HOURS ? "1" : "0";

    this.setState({hoursType, storeHours, selectedHours, useStoreHours}, () => {
      this.modal.open();
    });
  }

  saveHours = async () => {
    let {isStoreHours, hours, hoursType, selectedHours, useStoreHours} = this.state;

    for (let item of this.hourRefs) {
      if (!item) {
        continue;
      }

      let hour = await item.getValue();

      selectedHours = {...selectedHours, ...hour};
    }

    let endpoint = "hours";

    if (hoursType === HOURS_TYPES.DELIVERY) {
      endpoint = "delivery-hours";
    } else if (hoursType === HOURS_TYPES.THIRD_PARTY) {
      selectedHours.USE_STORE_HOURS = useStoreHours;
      endpoint = "third-party-hours";
    }

    await request(endpoint, "POST", selectedHours);

    await this.props.handeUpdate();
    this.modal.close();
  };

  render() {
    let {hoursType, storeHours, selectedHours, useStoreHours} = this.state;
    this.hourRefs = [];

    let label = "Store";

    if (hoursType === HOURS_TYPES.THIRD_PARTY) {
      label = "Third Party";
    } else if (hoursType === HOURS_TYPES.DELIVERY) {
      label = "Delivery";
    }

    console.log(typeof useStoreHours);

    return (
      <Modal
        label={`${label} Hours`}
        ref={(e) => (this.modal = e)}
        buttonLabel="Save"
        buttonOnClick={this.saveHours}
      >
        <div>
          {hoursType === HOURS_TYPES.THIRD_PARTY && (
            <FormBoolean
              label={"Use Store Hours"}
              value={useStoreHours}
              onChange={(val) => this.setState({useStoreHours: val})}
            />
          )}

          {(hoursType !== HOURS_TYPES.THIRD_PARTY || useStoreHours === "0") && (
            <div>
              {[0, 1, 2, 3, 4, 5, 6].map((item) => (
                <HoursRange
                  day={item}
                  storeHours={storeHours}
                  hoursType={hoursType}
                  selectedHours={selectedHours}
                  ref={(e) => this.hourRefs.push(e)}
                />
              ))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(HoursModal);

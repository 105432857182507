import React, {Component} from "react";
import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
  Filter,
} from "@frostbyte-technologies/frostbyte-tailwind";

import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import CardAlert from "../../../../features/card-alert";
import {showConfirmAlert, showErrorAlert} from "../../../../utils/alert-helper";
import TextCampaignModal from "../../../../modals/marketing/campaigns/text-campaign-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import TextSendTestModal from "../../../../modals/marketing/campaigns/text-send-test-modal";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import ScheduleTextModal from "../../../../modals/marketing/campaigns/schedule-text-modal";
import {TEXT_CAMPAIGN_TYPES} from "../../../../utils/marketing-constants";
import {setupReduxConnection} from "../../../../redux";

class TextCampaignPage extends Component {
  state = {
    campaign: null,
    instances: [],
    subList: null,
    isLoading: false,
    lists: [],
  };

  componentDidMount() {
    this.setState({isLoading: true});
    this.syncState();
  }

  _processContent(campaign) {
    const {EXPIRATION_DATE_FORMAT} = this.props.shop.companySettings;

    let content = campaign.CONTENT_DISPLAY;

    content = content.replace(
      "{{UNSUBSCRIBE}}",
      "\nReply UNSUBSCRIBE to opt-out from receiving these messages."
    );

    const pattern = campaign.PROMOTION ? /({{CODE}}|{{EXPIRATION}}|{{PATRON}})/g : /({{PATRON}})/g;

    // do this for {{CODE}} and {{PATRON}}
    const parts = content.split(pattern);
    const randomCode = randomString(5, "ABCDEFGHJKLMNOPQRSTUVWXYZ23456789");

    return (
      <span>
        {parts.map((part) => {
          if (part === "{{CODE}}") {
            return <span className="font-bold text-indigo-500">{randomCode}</span>;
          } else if (part === "{{PATRON}}") {
            return <span className="font-bold text-indigo-500">John Doe</span>;
          } else if (part === "{{EXPIRATION}}") {
            const expirationText = this.fetchExpirationText(campaign, EXPIRATION_DATE_FORMAT);

            return <span className="font-bold text-indigo-500">{expirationText}</span>;
          }

          return <span>{part}</span>;
        })}
      </span>
    );
  }

  fetchExpirationText(campaign, dateFormat = "MM/DD/YY hh:mm a") {
    const promotion = campaign.PROMOTION;

    if (!promotion) {
      return "";
    }

    if (promotion.DATE_START) {
      if (promotion.DATE_END) {
        return `(coupon valid from ${moment(promotion.DATE_START).format(dateFormat)} until ${moment(
          promotion.DATE_END
        ).format(dateFormat)})`;
      } else {
        return `(coupon valid starting ${moment(promotion.DATE_START).format(dateFormat)})`;
      }
    }

    if (promotion.DATE_END) {
      return `(coupon valid until ${moment(promotion.DATE_END).format(dateFormat)})`;
    }

    if (promotion.DAYS_VALID) {
      return `(coupon valid for ${promotion.DAYS_VALID} ${promotion.DAYS_VALID > 1 ? "days" : "day"})`;
    }

    return "";
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let serverCampaign = await request("text/marketing/campaign/" + id, "GET", null);

    serverCampaign.CONTENT_DISPLAY = serverCampaign.CONTENT;

    serverCampaign.CONTENT_DISPLAY = this._processContent(serverCampaign);

    let subList = await request("text/marketing/list", "GET", {
      LIST_ID: serverCampaign.LIST_ID,
    });

    const lists = await request("text/marketing/lists", "GET");

    this.setState({
      campaign: serverCampaign,
      instances: serverCampaign.INSTANCES,
      subList,
      isLoading: false,
      lists: lists ?? [],
    });
  }

  async sendTestText(phoneNumber, replacements) {
    const {campaign} = this.state;

    await request("text/marketing/campaign/test/" + campaign.ID, "POST", {
      PHONE: phoneNumber,
      REPLACEMENTS: replacements,
    });

    showSuccessNotification("Test Text Sent", "The test text is sent. Check your phone!");
  }

  deactivateCampaign() {
    const {campaign} = this.state;
    request("text/marketing/campaign/deactivate/" + campaign.ID, "POST", null)
      .then(() => {
        showSuccessNotification("Sent", "Campaign Deactivated!");
        this.props.router.navigate("/campaigns?tab=text");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async executeCampaign() {
    const {campaign} = this.state;

    try {
      await showConfirmAlert(
        campaign.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF
          ? "Send Campaign Now"
          : campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY
          ? "Activate Birthday Campaign"
          : campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
          ? "Activate Welcome Campaign"
          : "Send Campaign",
        campaign.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF
          ? "Are you sure you would like to send this text campaign? This will send a message to all of the patrons attached to this campaign now."
          : campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY
          ? "Are you sure you would like to activate this birthday campaign? This will send this message to the subscribed patrons on their birthday."
          : campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
          ? "Are you sure you would like to activate this welcome campaign? This will send this message to any patron who starts subscribing to your campaigns."
          : "Are you sure you would like to send this text campaign? This will send a message to all of the patrons attached to this campaign now."
      );
    } catch (_) {
      return;
    }

    try {
      await request("text/marketing/campaign/execute/" + campaign.ID, "POST", null);

      showSuccessNotification("Sent", "Campaign Sent Successfully!");
      this.props.router.navigate("/campaigns?tab=text");
    } catch (err) {
      if (err?.error === "INSUFFICIENT_TEXT_CREDITS") {
        showErrorAlert(
          "Insufficient Credits",
          "You don't have enough credits to send a text to everyone on the marketing list. You may purchase more" +
            " credits on the credits page."
        );
      } else {
        showErrorAlert("Unknown Error", "An unknown error occurred when attempting to execute this campaign");
      }
    }
  }

  async deleteCampaign() {
    const {campaign} = this.state;

    showConfirmAlert(
      "Delete Campaign",
      "Are you sure you would like to delete this text campaign? This operation cannot be undone."
    )
      .then(() => {
        return request("text/marketing/campaign/" + campaign.ID, "DELETE");
      })
      .then(() => {
        showSuccessNotification("Deleted", "Campaign Deleted Successfully!");
        this.props.router.navigate("/campaigns?tab=text");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async duplicateCampaign() {
    const {campaign} = this.state;

    await request("text/marketing/campaign/duplicate/" + campaign.ID, "POST", {});

    this.props.router.navigate("/campaigns?tab=text");
  }

  convertToDraft() {
    const {campaign} = this.state;

    showConfirmAlert(
      "Convert to Draft?",
      "Are you sure you would like to convert this text campaign back to a draft? It will no longer be scheduled to be sent."
    ).then(() => {
      this.setState({isLoading: true});

      request("text/marketing/campaign/" + campaign.ID, "PATCH", {
        ...campaign,
        DATE_SCHEDULED: null,
      }).then(() => {
        this.setState({
          campaign: {...campaign, DATE_SCHEDULED: null},
          isLoading: false,
        });
      });
    });
  }

  renderImageAttachment(imageUrl) {
    return imageUrl ? (
      <div
        className="cursor-pointer text-indigo-600 font-medium"
        onClick={() => {
          window.location.href = `https://drip-drinks.s3.us-east-1.amazonaws.com/${imageUrl}`;
        }}
      >
        Download
      </div>
    ) : (
      "None"
    );
  }

  renderSent() {
    const {campaign, instances} = this.state;

    const buttons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: [
              {
                label: "Duplicate Campaign",
                onClick: () => this.duplicateCampaign(),
              },
            ],
          },
        ],
      },
    ];

    // Deactivate campaign for birthdays and welcomes
    if (campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY || campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME) {
      buttons[0].sections[0].items.push({
        label: "Deactivate Temporarily",
        onClick: () => this.deactivateCampaign(),
      });
    }

    const instanceColumns = [
      {
        value: "FULL_NAME",
        label: "Name",
        format: (value, row) => {
          return (
            <div>
              <div className="text-sm font-medium text-gray-900">{value || "-"}</div>
              <div className="text-sm text-gray-500">{row.EMAIL}</div>
            </div>
          );
        },
      },
      {
        value: "STATUS",
        label: "Status",
      },
      {
        value: "PHONE",
        label: "Phone Number",
        format: (value, _) => getFormattedPhone(value),
      },
    ];

    if (campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY || campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME) {
      instanceColumns.splice(1, 0, {
        value: "DATE_SENT",
        label: "Date Sent",
        type: "datetime",
      });
    }

    return (
      <div>
        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>{campaign.NAME}</div>
              <SuccessBadge className="ml-2 mt-2">
                {campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY ||
                campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
                  ? "Active"
                  : "Sent"}
              </SuccessBadge>
            </div>
          }
          description={`View the metrics of this sent text campaign`}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Campaigns Page", url: "/campaigns"},
          ]}
          buttons={buttons}
        />
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
          <CardAlert
            label="Number Sent"
            icon="mail-bulk"
            value={instances.length}
            subtext={"total texts have been sent"}
            tooltip={{
              label: "Number Sent",
              data: "The total number of patrons we attempted to send the campaign to.",
            }}
            hideView
          />

          <CardAlert
            label="Patrons Returned"
            icon="house-return"
            value={campaign.NUMBER_RETURNED}
            subtext={"returned after receiving this text"}
            tooltip={{
              label: "Patrons Returned",
              data: "This shows how many of the patrons who have received this text, have been attached to an order after they have received the text.",
            }}
            hideView
          />

          <CardAlert
            label="Unsubscribed Patrons"
            icon="delete-right"
            value={campaign.NUMBER_UNSUBSCRIBED}
            subtext={"have unsubscribed"}
            tooltip={{
              label: "Unsubscribed Patrons",
              data: "The total number of patrons who unsubscribed to text marketing after receiving this message.",
            }}
            hideView
          />

          <CardAlert
            label="Coupons Redeemed"
            icon="tags"
            value={!campaign.PROMOTION ? "N/a" : campaign.PROMOTION.REDEEMS}
            subtext={"coupons have been redeemed"}
            tooltip={{
              label: "Coupons Redeemed",
              data: "If there was a coupon attached to the campaign, this will show how many patrons have redeemed their coupon.",
            }}
            hideView
          />

          <CardAlert
            label="Texts Delivered"
            tooltip={{
              label: "Texts Delivered",
              data: "These are the texts that our text message provider has confirmed have been delivered to the patron.",
            }}
            icon="mailbox"
            value={instances.filter((_text) => _text.STATUS === "DELIV").length}
            subtext={"texts have been deliverd"}
            hideView
          />

          <CardAlert
            label="Texts Failed"
            icon="seal-exclamation"
            value={
              instances.filter((_text) => _text.STATUS === "FAILED" || _text.STATUS === "UNDELIV").length
            }
            tooltip={{
              label: "Texts Failed",
              data: "These are texts that failed to be sent to the patron's phone. This could have happened for a number or reasons including having the incorrect patron phone number or sending to an unsubscribed patron.",
            }}
            subtext={"texts failed to send"}
            hideView
          />
        </div>

        <>
          <TwoColumnList
            label="Campaign Details"
            description="View the campaign audience, dates, and subject line"
            data={[
              {label: "Name", value: campaign.NAME},
              {
                label: "Type",
                value:
                  campaign.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF
                    ? "One Off Campaign"
                    : campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY
                    ? "Birthday Campaign"
                    : campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
                    ? "Welcome Campaign"
                    : "",
              },
              {
                label: "Date Created",
                value: campaign.DATE_CREATED,
                type: "datetime",
              },
              {
                label: "Date Sent",
                value: campaign.DATE_EXECUTED,
                type: "datetime",
              },
              {
                label: "Coupon Attached",
                value: campaign?.PROMOTION?.UNIQUE_ID ? (
                  <div
                    className="cursor-pointer text-indigo-600 font-medium"
                    onClick={() => {
                      this.props.router.navigate("/promotion/" + campaign.PROMOTION.UNIQUE_ID);
                    }}
                  >
                    View
                  </div>
                ) : (
                  "None"
                ),
              },
              {
                label: "Content",
                value: (
                  <div className="shadow-md rounded-md flex p-4 whitespace-pre-wrap">
                    {campaign.CONTENT_DISPLAY}
                  </div>
                ),
              },
              {
                label: "Image Attached",
                value: this.renderImageAttachment(campaign?.IMAGE_URL),
              },
              // TODO add dollars from attached tickets
            ]}
          />

          <Card label="Text Recipients" description="View the patrons the text was sent to">
            <Filter className={"ml-4"} searchable>
              {(_, search) => {
                return (
                  <Table
                    search={search}
                    searchFields={["FULL_NAME", "PHONE"]}
                    pagination
                    data={instances}
                    className={"-ml-4"}
                    ref={(e) => (this.tableRef = e)}
                    actionButtons={[
                      {
                        label: "View Text",
                        onClick: (row) => this.props.router.navigate("/campaign/texts/instances/" + row.ID),
                      },
                      {
                        label: "View Patron",
                        onClick: (row) => this.props.router.navigate("/patron/" + row.PATRON_UNIQUE_ID),
                      },
                    ]}
                    columns={instanceColumns}
                  />
                );
              }}
            </Filter>
          </Card>
        </>
      </div>
    );
  }

  renderUnsent() {
    const {campaign, subList, lists} = this.state;
    const {TEXT_MARKETING_PURCHASED_CREDITS, TEXT_MARKETING_AUTO_CREDITS} = this.props.shop.companySettings;
    const buttonOptions = [
      {label: "Delete Campaign", onClick: () => this.deleteCampaign()},
      {
        label: "Duplicate Campaign",
        onClick: () => this.duplicateCampaign(),
      },
      {
        label: "Send Test Text",
        ref: (e) => (this.executeTestRef = e),
        onClick: () => {
          this.sendTestModal.open();
        },
      },
    ];

    if (campaign.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF) {
      if (!campaign.DATE_SCHEDULED) {
        buttonOptions.push({
          label: "Schedule Text Campaign",
          ref: (e) => (this.executeRef = e),
          onClick: () => {
            this.scheduleModal.open(campaign);
          },
        });
      } else {
        buttonOptions.push({
          label: "Change Scheduled Time",
          ref: (e) => (this.executeRef = e),
          onClick: () => {
            this.scheduleModal.open(campaign);
          },
        });

        buttonOptions.push({
          label: "Convert to Draft",
          ref: (e) => (this.executeRef = e),
          onClick: () => {
            this.convertToDraft();
          },
        });
      }

      buttonOptions.push({
        label: "Send Campaign Now",
        ref: (e) => (this.executeRef = e),
        onClick: () => {
          this.executeCampaign();
        },
      });
    } else {
      buttonOptions.push({
        label: "Activate Campaign",
        ref: (e) => (this.executeRef = e),
        onClick: () => {
          this.executeCampaign();
        },
      });
    }

    const buttons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: buttonOptions,
          },
        ],
      },
    ];

    const twoColumnData = [
      {label: "Name", value: campaign.NAME},
      {
        label: "Type",
        value:
          campaign.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF
            ? "One Off Campaign"
            : campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY
            ? "Birthday Campaign"
            : campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
            ? "Welcome Campaign"
            : "",
      },
      {
        label: "Date Created",
        value: campaign.DATE_CREATED,
        type: "datetime",
      },
      {
        label: "Date Updated",
        value: campaign.DATE_UPDATED,
        type: "datetime",
      },
      {
        label: "Coupon Attached",
        value: campaign?.PROMOTION?.UNIQUE_ID ? (
          <div
            className="cursor-pointer text-indigo-600 font-medium"
            onClick={() => {
              this.props.router.navigate("/promotion/" + campaign.PROMOTION.UNIQUE_ID);
            }}
          >
            View
          </div>
        ) : (
          "None"
        ),
      },
      {
        label: "Preview",
        value: (
          <div className="shadow-md rounded-md flex p-4 whitespace-pre-wrap">{campaign.CONTENT_DISPLAY}</div>
        ),
        tooltip: {
          data: [
            {
              label: "Content",
              data: "This is the text content that will be sent to your subscribers.",
            },
            {
              label: "Text Tags",
              data: "The following tabs contain tags that can be used in your campaign content and will be replaced when sent out.",
            },
            {
              label: "{{PATRON}}",
              data: '{{PATRON}} in the text content will be replaced with the Patron\'s name if available. Otherwise it will be replaced with "You". It is represented as John Doe in indigo in the example text.',
            },
            {
              label: "{{CODE}}",
              data: '{{CODE}} in the text content will be replaced with a unique coupon code for each patron. In order for this to work, you must press "+ Add Coupon" button and fill out the coupon information. It is represented as a random sequence of indigo characters in the example text.',
            },
          ],
        },
      },
      {
        label: "Image Attached",
        value: this.renderImageAttachment(campaign?.IMAGE_URL),
      },
    ];

    if (campaign.DATE_SCHEDULED) {
      twoColumnData.splice(2, 0, {
        label: "Date Scheduled",
        value: campaign.DATE_SCHEDULED,
        span: true,
        type: "datetime",
      });
    }

    return (
      <div>
        <TextCampaignModal
          updateState={() => {
            this.setState({isLoading: true});
            this.syncState();
          }}
          subscriptionLists={lists?.filter(({EMAIL_ONLY}) => EMAIL_ONLY === 0)}
          ref={(e) => (this.campaignModal = e)}
        />

        <ScheduleTextModal
          updateState={() => {
            this.setState({isLoading: true});
            this.syncState();
          }}
          ref={(e) => (this.scheduleModal = e)}
          creditsAvailable={TEXT_MARKETING_AUTO_CREDITS + TEXT_MARKETING_AUTO_CREDITS}
          subList={subList}
        />

        <TextSendTestModal
          sendText={(phone) =>
            this.sendTestText(phone, {
              PATRON: "<Patron FULL NAME>",
              UNSUBSCRIBE: "Reply UNSUBSCRIBE to opt-out from receiving these messages.",
              CODE: "<Unique Coupon Code>",
            })
          }
          ref={(e) => (this.sendTestModal = e)}
        />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>{campaign.NAME}</div>
              {campaign.DATE_ARCHIVED ? (
                <DangerBadge className="ml-2 mt-2">Archived</DangerBadge>
              ) : campaign.DATE_SCHEDULED ? (
                <SuccessBadge className="ml-2 mt-2">Scheduled</SuccessBadge>
              ) : (
                <DangerBadge className="ml-2 mt-2" yellow>
                  Draft
                </DangerBadge>
              )}
            </div>
          }
          description={`View and edit this text campaign`}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Campaigns Page", url: "/campaigns"},
          ]}
          buttons={buttons}
        />

        <TwoColumnList
          label="Campaign Details"
          description="View the campaign audience, dates, and content"
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.campaignModal.open(campaign);
              },
            },
          ]}
          data={twoColumnData}
        />

        {campaign.TYPE !== TEXT_CAMPAIGN_TYPES.WELCOME && (
          <Tab
            data={[
              {
                label: "Subscribers",
                id: "instances",
              },
            ]}
          >
            {(id) => {
              if (id === "instances") {
                return (
                  <Card label="Text Subscribers" description="View the patrons that will receive this text">
                    <Table
                      pagination
                      data={subList?.Patrons}
                      ref={(e) => (this.tableRef = e)}
                      actionButtons={[
                        {
                          label: "View Patron",
                          onClick: (row) => this.props.router.navigate("/patron/" + row.ID),
                        },
                      ]}
                      columns={[
                        {
                          value: "FULL_NAME",
                          label: "Name",
                          format: (value, row) => {
                            return (
                              <div>
                                <div className="text-sm font-medium text-gray-900">{value || "-"}</div>
                                <div className="text-sm text-gray-500">{row.EMAIL}</div>
                              </div>
                            );
                          },
                        },
                        {
                          value: "PHONE",
                          label: "Phone Number",
                          format: (value, _) => getFormattedPhone(value),
                        },
                      ]}
                    />
                  </Card>
                );
              }
            }}
          </Tab>
        )}
      </div>
    );
  }

  render() {
    const {campaign, isLoading} = this.state;

    if (campaign === null || isLoading) {
      return <Loading />;
    }

    if (!campaign.DATE_EXECUTED) {
      return this.renderUnsent();
    } else {
      return this.renderSent();
    }
  }
}

export default setupReduxConnection(["shop"])(withRouter(TextCampaignPage));

import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {setupReduxConnection} from "../../../redux";
import {loadBrandedApps} from "../../../redux/admin";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import FormDropZone from "../../../components/form-drop-zone";
import {BrandedAppRequests} from "../../../utils/request-helpers/admin/branded-app-requests";

class EditBrandedAppInformationModal extends Component {
  state = {brandedApp: {}};

  open(brandedApp = null) {
    this.setState({brandedApp}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  save = async ({name, subtitle, description, keywords, marketingUrl, supportUrl}) => {
    const {UNIQUE_ID} = this.state.brandedApp;

    const payload = {
      APP_NAME: name,
      APP_SUBTITLE: subtitle,
      APP_DESCRIPTION: description,
      KEYWORDS: keywords,
      MARKETING_URL: marketingUrl,
      SUPPORT_URL: supportUrl,
    };

    await BrandedAppRequests.upsertBrandedApp(UNIQUE_ID, payload);

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    const {APP_NAME, APP_SUBTITLE, APP_DESCRIPTION, KEYWORDS, MARKETING_URL, SUPPORT_URL} =
      this.state.brandedApp;

    return (
      <Modal
        buttonLabel={"Save"}
        label={"Edit App Information"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.save}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({})}
          initialValues={{
            name: APP_NAME,
            subtitle: APP_SUBTITLE,
            description: APP_DESCRIPTION,
            keywords: KEYWORDS,
            marketingUrl: MARKETING_URL,
            supportUrl: SUPPORT_URL,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name={"name"} label={"App Name"} options={formikOptions} />

                <FormInput
                  name={"subtitle"}
                  label={"App Subtitle (max 30 characters)"}
                  options={formikOptions}
                  tooltip={
                    "The short one sentence tagline to display on the listing. Ex: “Order ahead, earn rewards, and visit X Cafe”"
                  }
                />

                <FormTextArea
                  label={"App Description (max 4000 characters)"}
                  name={"description"}
                  options={formikOptions}
                  tooltip={
                    "A description of your app, detailing features and functionality. This displays on the app store listing so should mention order ahead, rewards, and your business name."
                  }
                />

                <FormTextArea
                  label={"Keywords (max 100 characters)"}
                  name={"keywords"}
                  options={formikOptions}
                />

                <FormInput
                  name={"marketingUrl"}
                  label={"Marketing URL"}
                  options={formikOptions}
                  tooltip={"A link to your website if a customer wants more info from the app store listing."}
                />

                <FormInput
                  name={"supportUrl"}
                  label={"Support URL"}
                  options={formikOptions}
                  tooltip={"A link to a place where customers could reach out to you if needed."}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["admin"])(withRouter(EditBrandedAppInformationModal));

import React, {Component} from "react";
import {Formik} from "formik";
import PatronDropdown from "../../dropdowns/marketing/patron-dropdown";
import * as Yup from "yup";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {showErrorNotification, showSuccessNotification} from "../../utils/notification-helper";
import {withRouter} from "../../utils/navigation";

class MergePatronModal extends Component {
  open() {
    this.modal.open();
  }

  mergePatron(values) {
    const {patron} = this.props;
    const {patronId} = values;

    request("patrons/merge/" + patronId, "POST", {PATRON_ID: patron.ID})
      .then((data) => {
        showSuccessNotification("Patron Merged Successfully.", "The patrons have been successfully merged.");

        this.props.router.navigate("/patron/" + data.UNIQUE_ID);
      })
      .catch((err) => {
        showErrorNotification("Failed to Merge Patrons.", "The patron merge failed. Please try again.");
      });
  }

  render() {
    const {patron} = this.props;

    return (
      <Modal
        buttonLabel="Merge"
        label="Merge Patron Into"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={(values) => this.mergePatron(values)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            patronId: Yup.number().nullable().required("Please select a patron to merge into."),
          })}
          initialValues={{
            patronId: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <PatronDropdown
                  label="Select Patron to Merge Into:"
                  formikOptions={formikOptions}
                  usePatronId
                  exclude={[patron.ID]}
                  tooltip="Select a patron to transfer all transaction history and loyalty points to. The current patron will be archived after merging."
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(MergePatronModal);

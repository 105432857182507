import React, {Component} from "react";
import {Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {minutesToHours, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import TimeCardModal from "../../../modals/team/time-card-modal";
import {doLocationsHaveDoubleOvertime} from "./reporting-constants";
import {setupReduxConnection} from "../../../redux";
import {fetchUnpaidBreaksTotalMinutes} from "../../../utils/breaks-helper";
import DangerBadge from "../../../components/badges/danger-badge";

class TimeCardSummaryExpansionComponent extends Component {
  getTabs(showOvertime) {
    let {groupedByRole} = this.props;

    let tabs = [{id: "timecards", label: "Time Cards"}];

    if (
      showOvertime &&
      !groupedByRole &&
      (this.props.row?.PAY_TOTALS?.AMOUNT_OVERTIME_MINUTES > 0 ||
        this.props.row?.PAY_SUMMARY?.AMOUNT_OVERTIME_MINUTES > 0)
    ) {
      tabs.push({id: "overtime-calculations", label: "Overtime Calculations"});
    }

    if (!groupedByRole) {
      tabs.push({id: "payrates", label: "Pay Rates"});
    }

    return tabs;
  }

  getPayRatesColumns = (showOvertime) => {
    const payRatesHeaders = [
      {
        label: "Name",
        value: "PAY_RATE_NAME",
      },
      {
        label: "Rate",
        value: "PAY_RATE_AMOUNT",
        format: (value) => toDollars(value ?? 0, true),
      },
      {
        label: showOvertime ? "Regular" : "Totals",
        selector: "AMOUNT_REGULAR_MINUTES",
        format: (value, row) => {
          let displayMinutes = row?.AMOUNT_REGULAR_MINUTES ?? 0;
          let displayAmount = row?.AMOUNT_REGULAR_GROSS ?? 0;

          if (!showOvertime) {
            displayMinutes += row?.AMOUNT_OVERTIME_MINUTES ?? 0 + row?.AMOUNT_DOUBLE_OVERTIME_MINUTES ?? 0;

            displayAmount = row?.AMOUNT_PAYED ?? 0;
          }

          return (
            <div>
              <div>{`${minutesToHours(displayMinutes ?? 0)} hrs`}</div>

              <div>{toDollars(displayAmount, true)}</div>
            </div>
          );
        },
      },
    ];

    if (showOvertime) {
      payRatesHeaders.push({
        label: "Overtime",
        selector: "AMOUNT_REGULAR_MINUTES",
        format: (value, row) => {
          return (
            <div>
              <div>{`${minutesToHours(row?.AMOUNT_OVERTIME_MINUTES ?? 0)} hrs`}</div>

              <div>{toDollars(row?.AMOUNT_OVERTIME_GROSS ?? 0, true)}</div>
            </div>
          );
        },
      });
    }

    return payRatesHeaders;
  };

  getTimeCardsColumns(showOvertime) {
    let {groupedByRole, breakOptions, highlightNoPay} = this.props;
    let {showTips} = this.props;
    let {SHOW_BREAKS_OUT_OF_COMPLIANCE} = this.props.shop.settings;
    showTips = true;

    const {location, payrollGroup} = this.props.shop;

    const showDoubleOvertime = doLocationsHaveDoubleOvertime(location, payrollGroup);

    const TIMECARDS_HEADERS = [
      {
        label: "Location",
        value: "TIMESHEET_LOCATION_NAME",
      },
      {
        label: !groupedByRole ? "Role" : "Name",
        value: "ROLE",
        format: (value, row) => {
          if (groupedByRole) {
            return row?.FULL_NAME;
          }

          return row?.ROLE_NAME ?? "No Role";
        },
      },
      {
        label: "Date",
        selector: "DATE_START",
        format: (value, row) => (
          <div>
            <div>{`${moment(row.DATE_START).format("MM/DD")}`}</div>

            <div>{`${moment(row.DATE_START).format("h:mm A")} - ${moment(row.DATE_END).format(
              "h:mm A"
            )}`}</div>
          </div>
        ),
      },
      {
        label: "Scheduled Date",
        value: "SHIFT_DATE_START",
        format: (value, row) => {
          if (!value || !row.SHIFT_DATE_END) {
            return <div />;
          }

          return (
            <div>
              <div>{`${moment(row.SHIFT_DATE_START).format("MM/DD")}`}</div>

              <div>{`${moment(row.SHIFT_DATE_START).format("h:mm A")} - ${moment(row.SHIFT_DATE_END).format(
                "h:mm A"
              )}`}</div>
            </div>
          );
        },
      },
      {
        label: "Total",
        value: "AMOUNT_MINUTES",
        format: (value, row) => (
          <div>
            <div>{`${minutesToHours(row?.AMOUNT_TOTAL_MINUTES ?? 0)} hrs`}</div>

            {highlightNoPay && !row?.AMOUNT_PAYED ? (
              <DangerBadge>$0.00</DangerBadge>
            ) : (
              <div>{toDollars(showOvertime ? row?.AMOUNT_TOTAL_GROSS : row?.AMOUNT_PAYED ?? 0, true)}</div>
            )}
          </div>
        ),
        sortable: true,
        sortFunction: (a, b) => {
          return (a?.AMOUNT_MINUTES ?? 0) - (b?.AMOUNT_MINUTES ?? 0);
        },
      },
      {
        label: "Tips",
        value: "AMOUNT_TIPS",
        type: "dollars",
      },
    ];

    if (showOvertime) {
      TIMECARDS_HEADERS.splice(
        TIMECARDS_HEADERS.length - 1,
        0,
        ...[
          {
            label: "Regular",
            value: "AMOUNT_MINUTES",
            format: (value, row) => (
              <div>
                <div>{`${minutesToHours(row?.AMOUNT_REGULAR_MINUTES ?? 0)} hrs`}</div>

                <div>{toDollars(row?.AMOUNT_REGULAR_GROSS ?? 0, true)}</div>
              </div>
            ),
          },
          {
            label: "OT",
            value: "AMOUNT_MINUTES",
            format: (value, row) => (
              <div>
                <div>{`${minutesToHours(row?.AMOUNT_OVERTIME_MINUTES ?? 0)} hrs`}</div>

                <div>{toDollars(row?.AMOUNT_OVERTIME_GROSS ?? 0, true)}</div>
              </div>
            ),
          },
        ]
      );
    }

    if (showOvertime && showDoubleOvertime) {
      TIMECARDS_HEADERS.splice(
        TIMECARDS_HEADERS.length - 1,
        0,
        ...[
          {
            label: "2x OT",
            value: "AMOUNT_MINUTES",
            format: (value, row) => (
              <div>
                <div>{`${minutesToHours(row?.AMOUNT_DOUBLE_OVERTIME_MINUTES ?? 0)} hrs`}</div>

                <div>{toDollars(row?.AMOUNT_DOUBLE_OVERTIME_GROSS ?? 0, true)}</div>
              </div>
            ),
          },
        ]
      );
    }

    if (showTips) {
      // TIMECARDS_HEADERS.push({
      //   label: "Tips",
      //   selector: "AMOUNT_TIPS",
      //   format: (value, row) => toDollars(row?.AMOUNT_TIPS ?? 0, true),
      // });
    }

    if (breakOptions && breakOptions.length > 0) {
      TIMECARDS_HEADERS.splice(TIMECARDS_HEADERS.length - 1, 0, {
        label: "Unpaid Breaks",
        value: "UNPAID_BREAK_DURATION",
        format: (value, row) => {
          let displayStr = `${fetchUnpaidBreaksTotalMinutes(row.BREAKS ?? [])} mins`;

          if (SHOW_BREAKS_OUT_OF_COMPLIANCE && row.BREAKS_OUT_OF_COMPLIANCE) {
            return <DangerBadge className={"text-sm font-normal"}>{displayStr}</DangerBadge>;
          }

          return displayStr;
        },
        sortable: true,
        sortFunction: (a, b) => {
          return (a?.AMOUNT_MINUTES ?? 0) - (b?.AMOUNT_MINUTES ?? 0);
        },
      });
    }

    return TIMECARDS_HEADERS;
  }

  renderOvertimePay() {
    let {PAY_WEEK_BREAKDOWN} = this.props.row;
    const {location, payrollGroup} = this.props.shop;

    const showDoubleOvertime = doLocationsHaveDoubleOvertime(location, payrollGroup);

    const multiPayrollGroup =
      Object.keys(
        PAY_WEEK_BREAKDOWN.reduce((dict, _week) => {
          dict[_week.PAYROLL_GROUP_ID] = true;

          return dict;
        }, {})
      ).length > 1;

    let columns = [
      {
        label: "Work Week",
        value: "WEEK_START_EPOCH",
        format: (value, row) => {
          return `${moment.utc(parseInt(value)).format("MM/DD")} - ${moment
            .utc(parseInt(value))
            .add(6, "days")
            .format("MM/DD")}`;
        },
      },

      {
        label: "Regular",
        format: (value, row) => {
          return (
            <div className="text-left">
              <div>{minutesToHours(row.AMOUNT_REGULAR_MINUTES)} hrs</div>
              <div>{toDollars(row.AMOUNT_REGULAR_WAGE, true)}/hr</div>
            </div>
          );
        },
      },
      {
        label: "Overtime",
        format: (value, row) => {
          return (
            <div className="text-left">
              <div>{minutesToHours(row.AMOUNT_OVERTIME_MINUTES)} hrs</div>
              <div>{toDollars(row.AMOUNT_OVERTIME_WAGE, true)}/hr</div>
            </div>
          );
        },
      },
    ];

    if (showDoubleOvertime) {
      columns.push({
        label: "Double Overtime",
        format: (value, row) => {
          return (
            <div className="text-left">
              <div>{minutesToHours(row.AMOUNT_DOUBLE_OVERTIME_MINUTES)} hrs</div>
              <div>{toDollars(row.AMOUNT_DOUBLE_OVERTIME_WAGE, true)}/hr</div>
            </div>
          );
        },
      });
    }

    if (multiPayrollGroup) {
      columns.unshift({
        label: "Company",
        value: "PAYROLL_GROUP_NAME",
      });
    }

    return <Table columns={columns} data={PAY_WEEK_BREAKDOWN} />;
  }

  render() {
    const {breakOptions} = this.props;

    const showOvertime = this.props.shop.settings.TIME_CARDS_CALCULATE_OVERTIME === "1";

    return (
      <div className="px-10 py-3 pb-20 text-sm">
        <TimeCardModal
          onChange={async (card) => {
            if (this.props.refresh) {
              await this.props.refresh();
            }

            if (this.props.updateCard) {
              this.props.updateCard(card);
            }
          }}
          breakOptions={breakOptions}
          ref={(e) => (this.cardModal = e)}
        />

        <Tab data={this.getTabs(showOvertime)}>
          {(id) => {
            return (
              <div className="border border-gray-200 mt-4">
                {id === "payrates" && (
                  <Table columns={this.getPayRatesColumns(showOvertime)} data={this.props.row.PAY_RATES} />
                )}

                {id === "timecards" && (
                  <Table
                    actionButtons={[]}
                    columns={this.getTimeCardsColumns(showOvertime)}
                    data={this.props.row.TIMESHEETS?.sort((a, b) => a.DATE_START - b.DATE_START)}
                    onClick={(row) => this.cardModal.open(row)}
                  />
                )}

                {id === "overtime-calculations" && this.renderOvertimePay()}
              </div>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TimeCardSummaryExpansionComponent);

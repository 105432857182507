import {FormBoolean, Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class SmallTicketTippingModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {
      smallTicketTipsEnabled,
      maximumSmallTicketAmount,
      smallTicketTipOption1,
      smallTicketTipOption2,
      smallTicketTipOption3,
      smallTicketDefaultTipValue,
    } = values;

    let payload = {
      SMALL_TICKET_TIPS: smallTicketTipsEnabled,
      MAXIMUM_SMALL_TICKET_AMOUNT: decimalToDollars(maximumSmallTicketAmount),
      SMALL_TICKET_TIP_OPTIONS: JSON.stringify([
        smallTicketTipOption1,
        smallTicketTipOption2,
        smallTicketTipOption3,
      ]),
      DEFAULT_SMALL_TIP_VALUE: smallTicketDefaultTipValue,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {SMALL_TICKET_TIPS, SMALL_TICKET_TIP_OPTIONS, MAXIMUM_SMALL_TICKET_AMOUNT, DEFAULT_SMALL_TIP_VALUE} =
      this.props.shop.settings;

    SMALL_TICKET_TIP_OPTIONS = JSON.parse(SMALL_TICKET_TIP_OPTIONS);

    return (
      <Modal
        label="Small Ticket Tipping Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            smallTicketTipsEnabled: SMALL_TICKET_TIPS,
            maximumSmallTicketAmount: toDollars(MAXIMUM_SMALL_TICKET_AMOUNT),
            smallTicketTipOption1: SMALL_TICKET_TIP_OPTIONS[0],
            smallTicketTipOption2: SMALL_TICKET_TIP_OPTIONS[1],
            smallTicketTipOption3: SMALL_TICKET_TIP_OPTIONS[2],
            smallTicketDefaultTipValue: DEFAULT_SMALL_TIP_VALUE,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="smallTicketTipsEnabled"
                  label="Small Ticket Tips Enabled"
                  options={formikOptions}
                />

                <FormInput
                  label={"Maximum Small Ticket Amount"}
                  name="maximumSmallTicketAmount"
                  options={formikOptions}
                />

                <FormInput
                  label={"Small Ticket Tip Option 1"}
                  name="smallTicketTipOption1"
                  options={formikOptions}
                />

                <FormInput
                  label={"Small Ticket Tip Option 2"}
                  name="smallTicketTipOption2"
                  options={formikOptions}
                />

                <FormInput
                  label={"Small Ticket Tip Option 3"}
                  name="smallTicketTipOption3"
                  options={formikOptions}
                />

                <FormInput
                  label={"Small Ticket Default Tip Value"}
                  name={"smallTicketDefaultTipValue"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(SmallTicketTippingModal);

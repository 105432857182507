import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import React, {Fragment, Component} from "react";
import CheckboxGroup from "../../../components/form-elements/checkbox-group";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";

class LocationDropdown extends Component {
  state = {serverLocations: null};

  async componentDidMount() {
    let {locationIdArray} = this.props.reporting;

    const serverLocations = await request("employees/locations", "GET");

    this.setState({serverLocations});

    if (locationIdArray.length === 0) {
      locationIdArray.push(this.props.shop.location.ID);
      this.props.updateLocationIdArray(locationIdArray);
    }
  }

  render() {
    let {name} = this.props;
    let {location} = this.props.shop;
    let {locationIdArray} = this.props.reporting;
    let {serverLocations} = this.state;

    let label = "Locations";

    if (!serverLocations) {
      return <div />;
    }

    if (locationIdArray.length > 1) {
      label = `${locationIdArray.length} Locations`;
    } else if (locationIdArray.length === 1) {
      label = serverLocations.find((indexedLocation) => indexedLocation.ID === locationIdArray[0]).NAME;
    }

    return (
      <Menu as="div" className="relative inline-block text-left ml-5">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-50">
            {" "}
            {label}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          unmount={false}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            unmount={false}
            className="origin-top-right absolute left-0 mt-2 w-96 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          >
            <div className="p-2">
              <CheckboxGroup
                name={name}
                defaultSelectedArray={[location.ID]}
                cannotBeEmpty={true}
                onChange={(val) => this.props.updateLocationIdArray(val)}
                data={serverLocations.map((location) => {
                  return {
                    name: location.NAME,
                    value: location.ID,
                  };
                })}
              />
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(LocationDropdown);

import React, {Component} from "react";
import {ACCOUNT_SUB_TYPES, FINANCIAL_ACCOUNT_TYPES} from "../../utils/accounting-constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class LedgerDescription extends Component {
  render() {
    const {ledger} = this.props;

    if (ledger.FINANCIAL_ACCOUNT_TYPE) {
      return (
        <div>
          <span>{FINANCIAL_ACCOUNT_TYPES[ledger.FINANCIAL_ACCOUNT_TYPE].label}</span>

          <FontAwesomeIcon icon={"link-simple"} className={"ml-1"} />
        </div>
      );
    }

    return ACCOUNT_SUB_TYPES[ledger.SUB_TYPE]?.label;
  }
}

export default LedgerDescription;

import React, {Component} from "react";
import FormRow from "../../../../../components/form-row";
import FormStateSelect from "../../../../../components/form-state-select";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Trans} from "react-i18next";

class EmployeeAddressPage extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.i9-part1.employee-address-page." + path;
  }

  render() {
    const {options} = this.props;

    return (
      <form>
        <FormInput
          name={"address"}
          label={<Trans i18nKey={this.getI18NextKey("address.label")} />}
          options={options}
        />

        <FormInput
          name={"aptNumber"}
          label={<Trans i18nKey={this.getI18NextKey("aptNumber.label")} />}
          options={options}
        />

        <FormRow>
          <FormInput
            name={"cityOrTown"}
            label={<Trans i18nKey={this.getI18NextKey("city.label")} />}
            flex
            options={options}
          />

          <FormStateSelect
            name={"state"}
            label={<Trans i18nKey={this.getI18NextKey("state.label")} />}
            flex
            options={options}
          />

          <FormInput
            name={"zip"}
            label={<Trans i18nKey={this.getI18NextKey("zip.label")} />}
            flex
            options={options}
          />
        </FormRow>
      </form>
    );
  }
}

export default EmployeeAddressPage;

import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import React, {Component} from "react";
import PropTypes from "prop-types";

class BillingSubscriptions extends Component {
  render() {
    const {subscriptions, products} = this.props;

    if (subscriptions?.length === 0) {
      return <></>;
    }

    return subscriptions[0]?.items?.data?.map((sub) => {
      const subProductId = sub?.plan?.product;
      const subProductName = products[subProductId]?.name ?? "[Subscription] - Unknown";
      const discountAmount = sub?.discount?.coupon?.amount_off ?? 0;

      let term = sub.plan.interval;
      if (term === "month") {
        term = "Monthly";
      } else if (term === "year") {
        term = "Annually";
      } else if (term === "day") {
        term = "Daily";
      } else if (term === "week") {
        term = "Weekly";
      }

      return (
        <TwoColumnList
          label={subProductName}
          data={[
            {
              label: "Amount",
              value: (
                <div>
                  {discountAmount > 0 && (
                    <>
                      <span className="line-through">{toDollars(sub.plan.amount, true)}</span>{" "}
                    </>
                  )}
                  <span>{toDollars(sub.plan.amount - discountAmount, true)}</span>
                </div>
              ),
            },
            {
              label: "Quantity",
              value: sub.quantity,
            },
            {
              label: "Term",
              value: term,
            },
            {
              label: "Status",
              value: sub?.plan?.active ? "Active" : "Inactive",
            },
          ]}
        />
      );
    });
  }
}

BillingSubscriptions.propTypes = {
  subscriptions: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
};

export default BillingSubscriptions;

import React, {Component} from "react";
import {
  FormEmail,
  FormInput,
  FormPhone,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import FormRow from "../../../../components/form-row";
import {VendorRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {upsertVendor} from "../../../../redux/supply";
import {upsert} from "../../../../utils/util";

class VendorContactModal extends Component {
  state = {contact: null};

  open(contact = null) {
    this.setState({contact}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createContact({first, last, phone, email, notes}) {
    const {vendor, syncState} = this.props;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    const contactPayload = {
      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,

      IS_PRIMARY_CONTACT: vendor?.CONTACTS?.length === 0,
    };

    const serverContact = await VendorRequests.createVendorContact(vendor.ID, contactPayload);

    const newVendor = {...vendor, CONTACTS: [...vendor.CONTACTS, serverContact]};
    getStore().dispatch(upsertVendor(newVendor));

    syncState();
    this.modal.close();
  }

  async saveContact({first, last, phone, email, notes}) {
    const {contact} = this.state;
    const {vendor, syncState} = this.props;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    const serverContact = await VendorRequests.updateVendorContact(vendor.ID, contact.ID, {
      CUSTOMER_GROUP_ID: null,
      ADDRESS_ID: null,

      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,
    });

    const newVendor = {...vendor, CONTACTS: upsert(vendor.CONTACTS, serverContact)};
    await getStore().dispatch(upsertVendor(newVendor));

    syncState();
    this.modal.close();
  }

  render() {
    const {contact, name} = this.state;

    return (
      <Modal
        buttonLabel={contact ? "Save" : "Add"}
        label={contact ? "Edit Contact" : "Create Contact"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={contact ? this.saveContact.bind(this) : this.createContact.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            first: Yup.string().required("First is required"),
            last: Yup.string().required("Last is required"),
            email: Yup.string().required("Email is required"),
            phone: Yup.string(),
          })}
          initialValues={{
            first: contact?.FIRST_NAME ?? name?.split(" ")[0] ?? "",
            last: contact?.LAST_NAME ?? name?.split(" ")[1] ?? "",
            email: contact?.EMAIL ?? "",
            phone: contact?.PHONE?.slice(1) ?? "",
            notes: contact?.NOTES ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput
                    options={formikOptions}
                    placeholder="Donald"
                    label="First name"
                    name="first"
                    flex
                  />

                  <FormInput options={formikOptions} placeholder="Duck" label="Last name" name="last" flex />
                </FormRow>

                <FormEmail
                  options={formikOptions}
                  placeholder="don@test.com"
                  label="Email Address"
                  name="email"
                />

                <FormPhone options={formikOptions} label="Phone Number" name="phone" hint="optional" />

                <FormTextArea
                  options={formikOptions}
                  hint="optional"
                  label="Notes"
                  name="notes"
                  tooltip={{
                    label: "Internal Notes",
                    data: "These are internal notes that can be used to save arbitrary information about your contact. The contact does not have access to view this.",
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default VendorContactModal;

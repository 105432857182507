import React, {Component, Fragment} from "react";
import {Menu, Popover, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import {HEADER_MENU} from "../../settings/menu";
import Flyout from "./flyout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../redux";
import GlobalSearch from "./global-search";
import SwitchLocationModal from "../../modals/shop/switch-location-modal";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {showConfirmAlert} from "../../utils/alert-helper";
import {withRouter} from "../../utils/navigation";
import TestBanner from "../../components/banners/test-banner";
import AdminBanner from "../../components/banners/admin-banner";

class Header extends Component {
  logout() {
    showConfirmAlert("Logout of Dripos", "Are you sure you want to logout of Dripos?", "Confirm").then(() => {
      window.localStorage.removeItem("TOKEN");
      window.localStorage.removeItem("CURRENT_LOCATION");
      localStorage.removeItem("SCHEDULE_LOCATION_ARR");

      window.location.reload();
    });
  }

  render() {
    const {account, ranks, permissions: userPermissions, showDashboardHub} = this.props.user;
    const {isFromAdmin} = this.props.layout;
    const {location} = this.props.shop;

    let isPayroll = location.TYPE === "PAYROLL";
    let headerMenu = HEADER_MENU;

    if (isPayroll) {
      headerMenu = [];
    }

    const menuItems = [
      {
        onClick: () => this.props.router.navigate("/shop"),
        name: "Restaurant & Devices",
      },
      {
        onClick: () => this.switchModal.open(),
        name: "Change Locations",
      },
      {
        onClick: () => this.props.router.navigate("/settings"),
        name: "Settings",
      },
      {
        onClick: () => this.logout(),
        name: "Logout",
      },
    ];

    if (showDashboardHub) {
      menuItems.splice(3, 0, {
        onClick: () => this.props.router.navigate("/hub/payroll"),
        name: "Switch to your Employee Hub",
      });
    }

    if (ranks.includes("ADMIN")) {
      menuItems.unshift({
        onClick: () => this.props.router.navigate("/admin"),
        name: "Admin Dashboard",
      });
    }

    return (
      <>
        <header className="bg-white shadow">
          <SwitchLocationModal ref={(e) => (this.switchModal = e)} />

          <div className="mx-auto px-2 sm:px-4 lg:px-8">
            <Popover className="flex justify-between h-16">
              {({open, close}) => {
                return (
                  <>
                    <div className="flex px-2 lg:px-0">
                      <div className="flex-shrink-0 flex items-center">
                        <Link to="/" className="flex">
                          <span className="sr-only">Workflow</span>

                          <img
                            className="h-8 w-auto"
                            src={require("./../../assets/images/logo.png")}
                            alt=""
                          />
                        </Link>
                      </div>

                      <nav
                        aria-label="Global"
                        className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4"
                      >
                        <Popover.Group as="nav" className="flex lg:space-x-4 xl:space-x-6">
                          {headerMenu.map((item) => {
                            const {url, label, routes} = item;

                            if (routes) {
                              return <Flyout key={item.url + item.label} item={item} />;
                            }

                            return (
                              <a
                                href="#"
                                key={item.url + item.label}
                                className="text-base font-medium text-gray-500 hover:text-gray-900"
                              >
                                {label}
                              </a>
                            );
                          })}
                        </Popover.Group>
                      </nav>
                    </div>

                    {!isPayroll && <GlobalSearch />}

                    <div className="flex items-center lg:hidden">
                      <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                        <span className="sr-only">Open main menu</span>

                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="duration-200 ease-out"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="duration-100 ease-in"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Popover.Panel
                        className="absolute top-0 z-30 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
                        focus
                      >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                          <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                              <div>
                                <img
                                  className="h-8 w-auto"
                                  src={require("./../../assets/images/logo.png")}
                                  alt="Workflow"
                                />
                              </div>

                              <div className="-mr-2">
                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                  <span className="sr-only">Close menu</span>

                                  <XIcon className="h-6 w-6" aria-hidden="true" />
                                </Popover.Button>
                              </div>
                            </div>
                          </div>

                          <div className="pb-6 px-5">
                            {headerMenu.map((item) => {
                              const {label, permissions, routes} = item;

                              if (routes) {
                                const finalRoutes = routes.filter((item) => {
                                  if (!item.live && process.env.REACT_APP_ENV !== "development") {
                                    return false;
                                  }

                                  if (userPermissions.includes("ADMIN_FULL")) {
                                    return true;
                                  }

                                  if (permissions.length === 0) {
                                    return true;
                                  }

                                  const possiblePermissions = [...item.permissions, ...permissions];

                                  if (possiblePermissions.some((x) => userPermissions.includes(x))) {
                                    return true;
                                  }

                                  return false;
                                });

                                if (finalRoutes.length === 0) {
                                  return null;
                                }

                                return (
                                  <div key={item.label} className="mt-6 pb-6 border-b-2 border-gray-100">
                                    <nav className="grid gap-6">
                                      {finalRoutes.map((item) => (
                                        <div onClick={() => close()}>
                                          <Link
                                            to={item.url}
                                            key={item.url + item.label}
                                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                          >
                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white">
                                              <FontAwesomeIcon icon={item.icon} className="h-6 w-6" />
                                            </div>

                                            <div className="ml-4 text-base font-medium text-gray-900">
                                              {item.name}
                                            </div>
                                          </Link>
                                        </div>
                                      ))}
                                    </nav>
                                  </div>
                                );
                              }

                              return (
                                <div
                                  key={item.url + item.label}
                                  className="mt-6 pb-6 border-b-2 border-gray-100"
                                >
                                  <a className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                                    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white">
                                      <FontAwesomeIcon icon={item.icon} className="h-6 w-6" />
                                    </div>

                                    <div className="ml-4 text-base font-medium text-gray-900">{label}</div>
                                  </a>
                                </div>
                              );
                            })}

                            <div className="mt-6">
                              <a
                                href="#"
                                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                              >
                                Contact Support
                              </a>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>

                    <div className="lg:ml-4 flex items-center">
                      <div className="hidden lg:flex lg:flex-col">
                        <div className="text-gray-900 text-sm font-medium truncate text-right">
                          {account.FULL_NAME}
                        </div>
                        <div className="text-gray-500 text-sm truncate">{location.NAME}</div>
                      </div>
                      {/*<button*/}
                      {/*  type="button"*/}
                      {/*  className="flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"*/}
                      {/*>*/}
                      {/*  <span className="sr-only">View notifications</span>*/}
                      {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                      {/*</button>*/}

                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-4 relative flex-shrink-0">
                        <div>
                          <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={getObjectImage(account, "LOGO", "logo_mark-01.jpg")}
                              alt=""
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right z-20 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {menuItems.map((item) => (
                              <Menu.Item key={item.name}>
                                {({active}) => (
                                  <a
                                    onClick={item.onClick}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block cursor-pointer px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </>
                );
              }}
            </Popover>
          </div>
        </header>

        {isFromAdmin && <AdminBanner />}

        {process.env.REACT_APP_API_URL === "https://testapi.dripos.com/" && <TestBanner />}
      </>
    );
  }
}

export default setupReduxConnection(["user", "shop", "layout"])(withRouter(Header));

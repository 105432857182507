import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import {InvoiceRequests} from "../../../utils/request-helpers/invoices/invoice-requests";

class RemindInvoiceModal extends Component {
  state = {invoices: null};
  constructor(props) {
    super(props);
    this.modalRef = React.createRef(null);
    this.buttonRef = React.createRef(null);
  }

  open(invoices = null) {
    this.setState({invoices: invoices}, () => {
      this.modalRef.current.open();
    });
  }

  async sendInvoices(button, disableButton) {
    const {invoices} = this.state;

    button && button.startLoading();
    disableButton && disableButton.disable();

    const filteredInvoices = invoices.filter(
      (invoice) => invoice.STATUS !== "DRAFT" && invoice.STATUS !== "PAID"
    );
    const requests = filteredInvoices.map((invoice) => InvoiceRequests.remindInvoice(invoice.ID));

    await Promise.all(requests);
    this.modalRef?.current && this.modalRef.current.close();
  }

  render() {
    const {invoices} = this.state;

    return (
      <Modal
        hideClose
        deleteOnClick={() => this.modalRef.current.close()}
        deleteType="gray"
        deleteLabel="Cancel"
        buttons={[
          {
            label: "Send Now",
            ref: this.buttonRef,
            onClick: () => this.sendInvoices(this.buttonRef.current),
          },
        ]}
        label="Send Invoices"
        ref={this.modalRef}
      >
        <div className="font-medium">
          Send invoice reminder for
          <span className="font-bold">
            {" "}
            {invoices?.length || 0} invoice{invoices?.length > 1 && "s"}
          </span>
        </div>

        <div className="mt-4 text-sm text-gray-700">
          Are you sure you want to send these invoice reminders? Reminders will only be sent to Open / Overdue
          invoices.
        </div>
      </Modal>
    );
  }
}

export default withRouter(RemindInvoiceModal);

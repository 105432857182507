import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class TicketSettingsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {register, kiosk, mobile, web, saved, custom} = values;

    let payload = {
      AUTO_COMPLETE_REGISTER_ORDERS: register,
      AUTO_COMPLETE_KIOSK_ORDERS: kiosk,
      AUTO_COMPLETE_MOBILE_ORDERS: mobile,
      AUTO_COMPLETE_WEB_ORDERS: web,
      AUTO_COMPLETE_TAB_ORDERS: saved,
      AUTO_COMPLETE_CUSTOM_PRODUCTS: custom,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    let {location} = this.props.shop;

    this.props.updateShop({location});
    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {
      AUTO_COMPLETE_REGISTER_ORDERS,
      AUTO_COMPLETE_KIOSK_ORDERS,
      AUTO_COMPLETE_MOBILE_ORDERS,
      AUTO_COMPLETE_WEB_ORDERS,
      AUTO_COMPLETE_TAB_ORDERS,
      AUTO_COMPLETE_CUSTOM_PRODUCTS,
    } = this.props.shop.settings;

    return (
      <Modal
        label="Ticket Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            register: AUTO_COMPLETE_REGISTER_ORDERS,
            kiosk: AUTO_COMPLETE_KIOSK_ORDERS,
            mobile: AUTO_COMPLETE_MOBILE_ORDERS,
            web: AUTO_COMPLETE_WEB_ORDERS,
            saved: AUTO_COMPLETE_TAB_ORDERS,
            custom: AUTO_COMPLETE_CUSTOM_PRODUCTS,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean name="register" label="Register Orders" options={formikOptions} />

                <FormBoolean name="kiosk" label="Kiosk Orders" options={formikOptions} />

                <FormBoolean name="mobile" label="Mobile App Orders" options={formikOptions} />

                <FormBoolean label="Online Orders" name="web" options={formikOptions} />

                <FormBoolean name="saved" label="Saved Orders" options={formikOptions} />

                <FormBoolean name={"custom"} label="Custom Products" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(TicketSettingsModal);

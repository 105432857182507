import React, {Component, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import Checkbox from "./form-elements/checkbox";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class FilterDropdown extends Component {
  state = {values: this.props.initialValues ? this.props.initialValues : []};

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {enableReinitialize} = this.props;

    if (
      enableReinitialize &&
      JSON.stringify(prevProps.initialValues) !== JSON.stringify(this.props.initialValues)
    ) {
      this.setState({values: this.props.initialValues});
      this.props.onChange && this.props.onChange(this.props.initialValues);
    }
  }

  render() {
    let {values} = this.state;
    const {className} = this.props;

    let {data, emptyText, plural, cannotBeEmpty, initialText, noMl, selectAll} = this.props;

    let label = "";

    if (values.length === 0) {
      label = `No ${plural}`;
    } else if (values.length === data.length) {
      label = `All ${plural}`;
    } else if (values.length === 1) {
      let selectedObj = data.find((item) => {
        return item.value === values[0];
      });

      if (selectedObj?.name) {
        label = selectedObj.name;
      } else {
        label = initialText;
      }
    } else {
      label = `${values.length} ${plural}`;
    }

    const checked = values.length === data.length && data.length > 0;

    return (
      <Menu
        as="div"
        className={classNames(
          `relative inline-block text-left ${noMl ? " " : "ml-5"} `,
          this.props.className
        )}
      >
        <div className={className}>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-50">
            {" "}
            {label}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          unmount={false}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            unmount={false}
            className="origin-top-right absolute text-sm w-64 left-0 mt-2 shadow-lg rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          >
            <div className="p-2">
              {selectAll && (
                <div>
                  <div className="mx-1 my-1">
                    <Checkbox
                      label={"Select All"}
                      checked={checked}
                      onChange={() => {
                        let {values} = this.state;

                        if (values.length !== data.length) {
                          values = data.map((_data) => _data.value);
                        } else {
                          values = [this.props.defaultValue];
                        }

                        this.setState({values});
                        this.props.onChange(values);
                      }}
                    />
                  </div>

                  <div className={"my-2 h-0.5 w-fill bg-gray-300"}></div>
                </div>
              )}

              {data.map((item) => (
                <div className=" mx-1 my-1">
                  <Checkbox
                    label={item.name}
                    checked={values.includes(item.value)}
                    onChange={() => {
                      let {values} = this.state;

                      values = [...values];

                      let selectedIndex = values.findIndex((currItem) => {
                        return currItem === item.value;
                      });

                      if (cannotBeEmpty && values.length === 1 && selectedIndex > -1) {
                        return;
                      } else if (selectedIndex === -1) {
                        values.push(item.value);
                      } else {
                        values.splice(selectedIndex, 1);
                      }

                      this.setState({values});
                      this.props.onChange(values);
                    }}
                  />
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
}

export default FilterDropdown;

import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {PageHeadings, Loading, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import ServiceFeeModal from "../../../../modals/sales/fees/service-fee-modal";

class ServiceFeesPage extends Component {
  state = {isLoading: true, fees: []};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const fees = await request("fees", "GET");

    this.setState({isLoading: false, fees});
  }

  render() {
    const {isLoading, fees} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <ServiceFeeModal
          ref={(e) => (this.serviceFeeModal = e)}
          addState={(item) => this.props.router.navigate("/fee/" + item.UNIQUE_ID)}
        />

        <PageHeadings
          label="Service Fees"
          description="Add an extra fee on top of tickets"
          button={{
            label: "Create Service Fee",
            onClick: () => this.serviceFeeModal.open(),
          }}
        />

        <Table
          pagination
          data={fees}
          className="mt-2"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => {
                this.props.router.navigate("/fee/" + row.UNIQUE_ID);
              },
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "IS_PRE_TAX",
              label: "Calculated on subtotal",
              type: "bool",
            },
            {
              value: "TYPE",
              label: "Type",
            },
            {
              value: "AMOUNT",
              label: "Amount",
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ServiceFeesPage));

import React, {Component} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";

// For some reason I decided it was a good idea to use ISO day here. So make sure you make the adjustment
const DAYS = [
  {label: "Mon", id: 1},
  {label: "Tue", id: 2},
  {label: "Wed", id: 3},
  {label: "Thu", id: 4},
  {label: "Fri", id: 5},
  {label: "Sat", id: 6},
  {label: "Sun", id: 7},
];

class MultiDaySelect extends Component {
  render() {
    const {days} = this.props.options.values;
    const {disableDays = [], startDay = 1} = this.props;
    const displayDays = [...DAYS];
    displayDays.sort((a, b) => {
      a = a.id;
      b = b.id;

      if (a < b && b >= startDay && a < startDay) {
        return 1;
      } else if (a > b && a >= startDay && b < startDay) {
        return -1;
      } else {
        return a - b;
      }
    });

    return (
      <FormElement {...this.props} id={this.id}>
        {() => {
          return (
            <span className="flex relative z-0 inline-flex shadow-sm rounded-md">
              {displayDays.map(({id, label}) => {
                const idx = days.indexOf(id);
                const idxDisabled = disableDays.indexOf(id);
                return (
                  <button
                    key={id}
                    type="button"
                    className={`relative - ml - px relative inline-flex items-center px-2 py-2 ${
                      id === startDay ? "rounded-l-md" : id % 7 === (startDay + 6) % 7 ? "rounded-r-md" : ""
                    } border border-gray-300 ${
                      idxDisabled !== -1 ? "bg-gray-300" : idx !== -1 ? "bg-indigo-300" : "bg-white"
                    } text-sm font-medium text-gray-700 ${
                      idxDisabled !== -1
                        ? "hover:bg-gray-300 cursor-default"
                        : idx === -1
                        ? "hover:bg-gray-50"
                        : "hover:bg-indigo-300"
                    }`}
                    onClick={() => {
                      if (idxDisabled !== -1) {
                        return;
                      }
                      if (idx === -1) {
                        this.props.onChange([...days, id]);
                      } else {
                        days.splice(idx, 1);
                        this.props.onChange(days);
                      }
                    }}
                  >
                    {label}
                  </button>
                );
              })}
            </span>
          );
        }}
      </FormElement>
    );
  }
}

export default MultiDaySelect;

import React, {Component} from "react";
import {FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormDateSpecify from "../../../../../components/form-date-specify";
import FormRow from "../../../../../components/form-row";
import {Trans} from "react-i18next";

class EmployeeAttestationPage extends Component {
  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.i9-part1.employee-attestation-page." + path;
  }

  renderAlienFields() {
    const {options} = this.props;
    const {values} = options;

    switch (values.authorizedAlienValidation) {
      case "FOREIGN_PASSPORT":
        return (
          <FormRow>
            <FormInput
              flex
              label={<Trans i18nKey={this.getI18NextKey("passportNumber.label")} />}
              name={"passportNumber"}
              options={options}
            />

            <FormInput
              flex
              label={<Trans i18nKey={this.getI18NextKey("countryOfIssuance.label")} />}
              name={"countryOfIssuance"}
              options={options}
            />
          </FormRow>
        );
      case "ADMISSION_NUMBER":
        return (
          <FormInput
            label={<Trans i18nKey={this.getI18NextKey("admissionNumber.label")} />}
            name={"admissionNumber"}
            options={options}
          />
        );
      case "ALIEN_REGISTRATION_NUMBER":
        return (
          <FormInput
            label={<Trans i18nKey={this.getI18NextKey("authorizedAlienNumber.label")} />}
            name={"authorizedAlienNumber"}
            options={options}
          />
        );
    }
  }

  renderVerificationFields() {
    const {options} = this.props;
    const {values} = options;

    switch (values.validationType) {
      case "PERMANENT_RESIDENT":
        return (
          <FormInput
            name={"registrationNumber"}
            label={<Trans i18nKey={this.getI18NextKey("authorizedAlienNumber.label")} />}
            options={options}
          />
        );
      case "AUTHORIZED_ALIEN":
        return (
          <div>
            <div className={"my-3 block text-sm font-medium text-gray-700"}>
              <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />{" "}
              <Trans i18nKey={this.getI18NextKey("alienPhrase.label")} />
            </div>

            <FormDateSpecify
              name={"authorizedWorkExpiration"}
              options={options}
              mdy
              label={<Trans i18nKey={this.getI18NextKey("authorizedWorkExpiration.label")} />}
            />

            <FormSelect
              name={"authorizedAlienValidation"}
              label={<Trans i18nKey={this.getI18NextKey("authorizedAlienValidation.label")} />}
              data={[
                {
                  label: <Trans i18nKey={this.getI18NextKey("authorizedAlienNumber.label")} />,
                  value: "ALIEN_REGISTRATION_NUMBER",
                },
                {
                  label: <Trans i18nKey={this.getI18NextKey("admissionNumber.label")} />,
                  value: "ADMISSION_NUMBER",
                },
                {
                  label: <Trans i18nKey={this.getI18NextKey("foreignPassport.label")} />,
                  value: "FOREIGN_PASSPORT",
                },
              ]}
              options={options}
            />

            {this.renderAlienFields()}
          </div>
        );
    }
  }

  render() {
    const {options} = this.props;
    console.log(options.values);

    return (
      <div>
        <div className={"text-sm font-medium text-gray-700 mt-5"}>
          <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />{" "}
          <Trans i18nKey={this.getI18NextKey("penaltyPhrase.label")} />
        </div>
        <form>
          <FormSelect
            name={"validationType"}
            label={<Trans i18nKey={this.getI18NextKey("attestationPhrase.label")} />}
            data={[
              {
                label: <Trans i18nKey={this.getI18NextKey("citizen.label")} />,
                value: "CITIZEN",
              },
              {
                label: <Trans i18nKey={this.getI18NextKey("noncitizenNational.label")} />,
                value: "NONCITIZEN_NATIONAL",
              },
              {
                label: <Trans i18nKey={this.getI18NextKey("lawfulPermanentResident.label")} />,
                value: "PERMANENT_RESIDENT",
              },
              {
                label: <Trans i18nKey={this.getI18NextKey("alienAuthorizedToWork.label")} />,
                value: "AUTHORIZED_ALIEN",
              },
            ]}
            options={options}
          />

          {this.renderVerificationFields()}
        </form>
      </div>
    );
  }
}

export default EmployeeAttestationPage;

import React, {Component} from "react";
import {Button, Card, Modal, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {withRouter} from "../../utils/navigation";
import ShiftTagsDetailsModal from "./shift-tags-details-modal";
import {TrashIcon} from "@heroicons/react/outline";
import {showConfirmAlert} from "../../utils/alert-helper";

class ShiftTagsModal extends Component {
  state = {serverShiftTags: null};

  componentDidMount() {
    this.syncState().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  syncState = async () => {
    const serverShiftTags = await request("team/shift-tags", "GET");

    this.setState({serverShiftTags});
  };

  open = () => {
    this.modal.open();
  };

  close() {
    this.modal.close();
  }

  deleteShiftTag = ({UNIQUE_ID, NAME}) => {
    showConfirmAlert(
      `Deleting ${NAME}`,
      `Are you sure you want to delete ${NAME}? \nThis will remove ${NAME} from all previous and future shifts`
    ).then(async (r) => {
      await request(`team/shift-tags/${UNIQUE_ID}`, "DELETE");

      await this.syncState();
    });
  };

  render() {
    const {serverShiftTags} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        closeLabel={"Close"}
        label={`Shift Tags`}
        description={
          "Shift Tags can be attached to shifts and will override the Role's color on the Scheduler"
        }
        large
        full
        className="px-5"
        buttonOnClick={() => this.saveBoth()}
      >
        <ShiftTagsDetailsModal ref={(e) => (this.shiftTagsModal = e)} syncState={this.syncState} />

        <div className={"flex flex-col justify-end"}>
          <div>
            <Button
              className={"w-32 float-right"}
              label={"Add Shift Tag"}
              onClick={() => this.shiftTagsModal.open()}
            />
          </div>

          <Table
            className="mt-4"
            data={serverShiftTags}
            actionButtons={[
              {
                label: "Edit",
                onClick: (row) => {
                  this.shiftTagsModal.open(row);
                },
              },
            ]}
            columns={[
              {
                label: "Name",
                value: "NAME",
              },
              {
                label: "Color",
                value: "COLOR",
                format: (value) => (
                  <div
                    className={"h-5 w-5"}
                    style={{
                      height: 5,
                      width: 5,
                      backgroundColor: `#${value}`,
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </Modal>
    );
  }
}

export default withRouter(ShiftTagsModal);

import React, {Component} from "react";
import {Card, FormInput, PageHeadings, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../components/loading-spinner";
import {request} from "../../utils/request";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import AutomatedEntryModal from "../../modals/accounting/automated-entry-modal";
import {DRIPOS_MONTHLY_ENTRY, PAYROLL_ENTRY, SALES_ENTRY} from "../../utils/accounting-constants";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class AutomatedEntriesPage extends Component {
  state = {mappings: null};

  async componentDidMount() {
    this.props.loadLedgers();

    this.syncState();
  }

  syncState = async () => {
    const mappings = await request("accounting/mappings");

    this.setState({mappings});
  };

  renderEntry({label, dict}) {
    const {mappings} = this.state;

    const mappingsDict = parseIdDict(mappings, "MAPPING");

    return (
      <Card
        label={`${label} Entry`}
        button={{
          label: "Edit",
          onClick: () => this.automatedEntryModal.open(label, dict),
        }}
      >
        <div className={"flex flex-col p-6 space-y-2"}>
          {Object.keys(dict).map((_key) => (
            <div className={"flex flex-row items-center"}>
              <div className={"font-semibold text-sm w-52"}>{dict[_key].label}</div>

              <div>
                <FormInput label={""} value={mappingsDict[_key]?.LEDGER_NAME ?? "None"} disabled={true} />
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }

  render() {
    const {mappings} = this.state;

    const entriesDict = {
      sales: {
        label: "Daily Sales",
        dict: SALES_ENTRY,
      },
      payroll: {
        label: "Payroll",
        dict: PAYROLL_ENTRY,
      },
      driposMonthly: {
        label: "Dripos Monthly",
        dict: DRIPOS_MONTHLY_ENTRY,
      },
    };

    return (
      <div>
        <AutomatedEntryModal
          ref={(e) => (this.automatedEntryModal = e)}
          mappings={mappings}
          syncState={this.syncState}
        />

        <PageHeadings label={"Automated Entries"} />

        {!mappings && <LoadingSpinner />}

        {mappings && (
          <Tab
            className="mt-2"
            data={[
              // {label: "Daily Sales", id: "sales"},
              {label: "Payroll", id: "payroll"},
              {label: "Dripos Monthly Fee", id: "driposMonthly"},
            ]}
          >
            {(id) => {
              return this.renderEntry(entriesDict[id ?? "sales"]);
            }}
          </Tab>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["accounting"])(withRouter(AutomatedEntriesPage));

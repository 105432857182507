import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {PageHeadings, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import TaxModal from "../../../../modals/sales/tax/tax-modal";
import TaxesTable from "../../../../tables/sales/taxes-table";

class TaxesPage extends Component {
  state = {isLoading: true, taxes: []};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const taxes = await request("tax", "GET");

    this.setState({isLoading: false, taxes});
  }

  render() {
    const {isLoading, taxes} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <TaxModal
          ref={(e) => (this.taxModal = e)}
          addState={(item) => this.props.router.navigate("/tax/" + item.UNIQUE_ID)}
        />

        <PageHeadings
          label="Tax Rates"
          description="Create tax rates for your products and categories"
          button={{
            label: "Create Tax Rate",
            onClick: () => this.taxModal.open(),
          }}
        />

        <TaxesTable className="mt-4" taxes={taxes} />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(TaxesPage));

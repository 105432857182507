import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";

class TicketTypeDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {exclude = [], unique, forHere = false} = this.props;

    const serverTypes = await request("types/order", "GET");

    const data = serverTypes
      .filter((item) => !exclude.includes(item.ID))
      .filter((item) => {
        return forHere ? item.TYPE === "IN-STORE" : true;
      })
      .map((item) => {
        return {
          value: unique ? item.UNIQUE_ID : item.ID,
          label: item.NAME,
        };
      });

    this.setState({data, raw: serverTypes});
  }

  fetchProduct(useRaw) {
    let {data, raw} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    if (useRaw) {
      return raw.find((item) => item.ID === parseInt(value));
    }

    return data.find((item) => item.id === parseInt(value));
  }

  render() {
    const {data} = this.state;

    return <FormSelect label="Ticket Types" {...this.props} data={data} />;
  }
}

export default TicketTypeDropdown;

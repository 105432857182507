import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import {showErrorAlert} from "../../../utils/alert-helper";
import {showErrorNotification} from "../../../utils/notification-helper";
import {TEXT_CAMPAIGN_TYPES} from "../../../utils/marketing-constants";

class EmailCampaignModal extends Component {
  state = {campaign: null};

  open(campaign = null) {
    this.setState({campaign}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createCampaign({name, subject, listId, subheader}) {
    let serverCampaign;

    try {
      serverCampaign = await request("marketing/campaigns/email", "POST", {
        SUBJECT: subject,
        NAME: name,
        LIST_ID: listId,
        SUBHEADER: subheader,
      });
    } catch (err) {
      this.modal.close();

      return showErrorNotification("Create Campaign Failure", "Please make sure you have patrons.");
    }

    this.props.addState(serverCampaign);
    this.modal.close();
  }

  async saveCampaign({name, subject, listId, subheader}) {
    const {campaign} = this.state;

    const serverCampaign = await request("marketing/campaigns/email/" + campaign.ID, "PATCH", {
      NAME: name,
      SUBJECT: subject,
      LIST_ID: listId,
      SUBHEADER: subheader,
    });

    this.props.updateState(serverCampaign);
    this.modal.close();
  }

  render() {
    const {campaign} = this.state;
    const {subLists} = this.props;

    const formSublists = subLists.map((list) => ({
      label: list.NAME,
      value: list.ID,
    }));

    const initialValues = {
      name: campaign?.NAME ?? "",
      subject: campaign?.SUBJECT ?? null,
      listId: campaign?.LIST_ID ?? null,
      subheader: campaign?.SUBHEADER ?? null,
    };

    const subscriberListTooltip =
      "Select Default Mail List for all patrons that have opted into e-mail marketing.";

    const subheaderTooltip =
      "A brief preview text that appears in the recipient's inbox but is not shown when the email is opened.";

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={campaign ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
        label={campaign ? "Edit Email Campaign" : "Create Email Campaign"}
      >
        <Formik
          onSubmit={campaign ? this.saveCampaign.bind(this) : this.createCampaign.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={initialValues}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="name" label="Name" options={formikOptions} />

                <FormInput options={formikOptions} label="Subject" name="subject" />

                <FormSelect
                  data={formSublists}
                  options={formikOptions}
                  label="Subscriber List"
                  name="listId"
                  tooltip={subscriberListTooltip}
                />

                <FormInput
                  label="Subheader"
                  name="subheader"
                  options={formikOptions}
                  tooltip={subheaderTooltip}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EmailCampaignModal;

import React, {Component} from "react";
import {AdvancedFilter, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";

class ChecksPage extends Component {
  render() {
    return (
      <div>
        <PageHeadings description={"Filter to find current and future checks"} label={"Checks"} />

        <AdvancedFilter
          defaultOpen
          data={[
            {
              type: "array",
              data: [
                {
                  label: "Search by Server Name, Table Name, Ticket Number, Last 4, $ Amount",
                  placeholder: "Search",
                  type: "search",
                  id: "search",
                },
                {label: "Start Date", type: "datetime", id: "start"},
                {label: "End Date", type: "datetime", id: "end"},
              ],
            },
          ]}
        >
          {(filters, search) => {
            return (
              <div>
                <Table
                  route="check/dumb"
                  search={search}
                  filters={filters}
                  actionButtons={[
                    {
                      label: "View",
                      onClick: (check) => this.props.router.navigate("/check/" + check.UNIQUE_ID),
                    },
                    {
                      label: <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />,
                      onClick: (ticket) => this.props.router.navigateNewTab("/ticket/" + ticket.UNIQUE_ID),
                    },
                  ]}
                  columns={[
                    {
                      label: "Table Name",
                      value: "TABLE_NAME",
                      sortable: true,
                    },
                    {
                      label: "Total",
                      value: "TOTAL",
                      format: (val) => toDollars(val, true),
                      sortable: true,
                    },
                    {label: "Server", value: "FULL_NAME", sortable: true},
                    {
                      label: "Date Created",
                      value: "DATE_CREATED",
                      sortable: true,
                      format: (val, row) => moment(val).format("h:mma ddd MMM Do"),
                    },
                  ]}
                  pagination
                />
              </div>
            );
          }}
        </AdvancedFilter>
      </div>
    );
  }
}

export default withRouter(ChecksPage);

import Banner from "../../../components/banner";
import React from "react";

export default function OnboardingConstructionBanner() {
  return (
    <Banner
      className="my-4"
      label="Onboarding Construction"
      description="We’re enhancing our onboarding module to better serve your needs. Existing company documents will transition seamlessly, but we recommend pausing new processes until the updated module is live. Thank you for your patience!"
    />
  );
}

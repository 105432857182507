import React, {Component} from "react";
import {FormDate, FormInput, FormPhone, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import * as Yup from "yup";
import PropTypes from "prop-types";
import moment from "moment-timezone";

class PatronDetailsModal extends Component {
  state = {discount: null, product: 0};

  open(patron = null) {
    this.setState({patron}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async addPatron({name, phone, email}) {
    let payload = {
      FULL_NAME: name,
      PHONE: "1" + phone.replaceAll(" ", ""),
      EMAIL: email,
    };

    const newPatron = await request("patrons/create", "POST", payload);

    this.props.updateState(newPatron);
    this.modal.close();
  }

  async savePatron({points, name, birthday}) {
    let {patron} = this.state;

    let payload = {
      FULL_NAME: name,
    };

    if (points) {
      payload.POINTS = points;
    }

    if (birthday) {
      const momentTime = moment(birthday);

      const month = momentTime.format("M");
      const day = momentTime.format("D");
      const year = momentTime.format("YYYY");

      payload.BIRTH_DAY = day;
      payload.BIRTH_MONTH = month;
      payload.BIRTH_YEAR = year;
    }

    const patrons = await request("patrons/update/" + patron.ID, "POST", payload);

    patron.POINTS = patrons.POINTS;
    patron.LIFETIME = patrons.LIFETIME;
    patron.FULL_NAME = patrons.FULL_NAME;
    patron.BIRTH_DAY = patrons.BIRTH_DAY;
    patron.BIRTH_MONTH = patrons.BIRTH_MONTH;
    patron.BIRTH_YEAR = patrons.BIRTH_YEAR;

    this.props.updateState({patron});
    this.modal.close();
  }

  render() {
    const {patron} = this.state;

    const schema = !patron
      ? Yup.object({
          name: Yup.string().required("Name is required"),
          phone: Yup.string().required("Phone number is required"),
          email: Yup.string().nullable(),
        })
      : Yup.object({
          name: Yup.string().required("Name is required"),
          points: Yup.number().nullable(),
        });

    return (
      <Modal
        buttonLabel="Save"
        label={patron ? "Edit Patron" : "Create Patron"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={patron ? this.savePatron.bind(this) : this.addPatron.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: patron?.FULL_NAME,
            phone: patron?.PHONE,
            email: patron?.EMAIL,
            birthday: patron?.BIRTH_MONTH
              ? moment(
                  patron?.BIRTH_MONTH + "/" + patron?.BIRTH_DAY + "/" + patron?.BIRTH_YEAR,
                  "M/D/YYYY"
                )?.valueOf()
              : null,
            points: null,
          }}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {!patron ? (
                  <>
                    <FormInput label="Name" name="name" options={formikOptions} placeholder={"John Doe"} />

                    <FormPhone label="Phone Number" name="phone" options={formikOptions} />

                    <FormInput label="Email" name="email" hint={"optional"} options={formikOptions} />
                  </>
                ) : (
                  <>
                    <FormInput label="Name" name="name" options={formikOptions} placeholder={"John Doe"} />

                    <FormDate
                      label="Birthday"
                      name="birthday"
                      hint={"Optional"}
                      options={formikOptions}
                      placeholder={""}
                    />

                    <FormInput
                      label="Points to Add"
                      name="points"
                      hint={"Optional"}
                      options={formikOptions}
                      placeholder={"100"}
                    />
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PatronDetailsModal.propTypes = {
  updateState: PropTypes.func,
};

export default PatronDetailsModal;

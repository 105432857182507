import React, {Component} from "react";
import StepNavCard from "../../features/step-nav-card";
import PayrollImportFirstTab from "../../features/success/payroll-import/payroll-import-first-tab";
import PaycheckBoundsTab from "../../features/success/payroll-import/paycheck-bounds-tab";
import EmployeesTab from "../../features/success/payroll-import/check-mapping-tab";
import MappingTab from "../../features/success/payroll-import/mapping-tab";
import DownloadSquareImportTab from "../../features/success/payroll-import/download-square-import";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import ADPMappingTab from "../../features/success/payroll-import/adp/adp-mapping-tab";
import DownloadADPImportTab from "../../features/success/payroll-import/adp/download-adp-import";
import DownloadGustoImport from "../../features/success/payroll-import/gusto/download-gusto-import";
import DownloadToastExport from "../../features/success/payroll-import/toast/download-toast-export";

class ImportTab extends Component {
  state = {checkTemplateData: null, exportData: null};

  componentDidMount() {}

  updateState = (payload) => {
    this.setState((prevState) => ({...prevState, ...payload}));
  };

  getSteps = () => {
    let pastProvider = this.props.payroll.importData?.pastProvider;

    let steps = [
      {
        label: "Setup",
        component: PayrollImportFirstTab,
      },
    ];

    if (pastProvider === "toast") {
      steps.push(
        {
          label: "Map to Check",
          component: EmployeesTab,
        },
        {
          label: "Mapping",
          component: MappingTab,
        },
        {
          label: "Download",
          component: DownloadToastExport,
        }
      );
    }

    if (pastProvider === "square") {
      steps.push(
        {
          label: "Paycheck Bounds",
          component: PaycheckBoundsTab,
        },
        {
          label: "Mapping",
          component: MappingTab,
        },
        {
          label: "Emps + Workplaces",
          component: EmployeesTab,
        },
        {
          label: "Download Import",
          component: DownloadSquareImportTab,
        }
      );
    }

    if (pastProvider === "adp") {
      steps.push(
        {
          label: "ADP Mapping",
          component: ADPMappingTab,
        },
        {
          label: "Download Import",
          component: DownloadADPImportTab,
        }
      );
    }

    if (pastProvider?.startsWith("gusto")) {
      steps.push(
        {
          label: "Map to Check",
          component: EmployeesTab,
        },
        {
          label: "Field Mapping",
          component: MappingTab,
        },
        {
          label: "Gusto",
          component: DownloadGustoImport,
        }
      );
    }

    return steps;
  };

  render() {
    return <StepNavCard updateState={this.updateState} label={`Payroll Import`} steps={this.getSteps()} />;
  }
}

export default setupReduxConnection(["payroll"])(withRouter(ImportTab));

import React, {Component} from "react";
import {request} from "../../../utils/request";
import {showConfirmAlert} from "../../../utils/alert-helper";
import {withRouter} from "../../../utils/navigation";
import {PageHeadings, Card, Table, Tab, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import BillingModal from "../../../modals/general/billing-modal";
import LoadingSpinner from "../../../components/loading-spinner";
import BillingSubscriptions from "../../../components/billing/billing-subscriptions";
import BillingHistoryTable from "../../../tables/billing/billing-history-table";
import BillingPaymentMethodsTable from "../../../tables/billing/billing-payment-methods-table";
import BillingAddress from "../../../components/billing/billing-address";

class BillingPage extends Component {
  state = {
    subscriptions: null,
    sources: null,
    invoices: null,
    products: {},
    address: null,
    isLoading: false,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    this.setState({isLoading: true});

    request("partner/billing", "GET", null).then((data) => {
      if (!data) {
        return this.setState({
          isLoading: false,
          subscriptions: [],
          sources: [],
          invoices: [],
          products: {},
          address: {},
        });
      }

      const {subscriptions, sources, invoices, products, address} = data;

      const productsDict = products?.reduce((accum, item) => {
        accum[item.id] = item;

        return accum;
      }, {});

      this.setState({
        isLoading: false,
        subscriptions,
        sources,
        invoices: invoices?.data?.filter((inv) => inv.status !== "draft"),
        products: productsDict ?? {},
        address,
      });
    });
  }

  renderPaymentMethods() {
    const {sources} = this.state;

    return (
      <Card
        label="Payment Methods"
        description="Your current saved payment methods"
        button={{
          label: "Add Payment Method",
          onClick: () => this.billingModal.open(),
        }}
      >
        <BillingPaymentMethodsTable sources={sources} syncState={() => this.syncState()} />
      </Card>
    );
  }

  renderHistory() {
    const {invoices} = this.state;

    return (
      <Card label="Payment History" description="Your previous and upcoming payments">
        <BillingHistoryTable invoices={invoices} />
      </Card>
    );
  }

  renderPlans() {
    const {isLoading, subscriptions, products} = this.state;

    return (
      <>
        <Card label="Subscription Plans" description="Your Dripos subscriptions">
          {!isLoading && subscriptions.length === 0 && (
            <div className="px-6 py-5 font-small text-sm">Could not find any Dripos Subscriptions</div>
          )}

          {isLoading && (
            <div className="py-5">
              <LoadingSpinner size={24} />
            </div>
          )}
        </Card>

        {!isLoading && <BillingSubscriptions products={products} subscriptions={subscriptions} />}
      </>
    );
  }

  renderAddress() {
    const {isLoading, address} = this.state;

    return (
      <>
        {isLoading && (
          <div className="py-5">
            <LoadingSpinner size={24} />
          </div>
        )}

        {!isLoading && (
          <BillingAddress address={address} updateAddress={(address) => this.setState({address})} />
        )}
      </>
    );
  }

  render() {
    let {sources, invoices, subscriptions, products, address, isLoading} = this.state;

    return (
      <div>
        <PageHeadings
          label={"Billing"}
          description={"Update the payment method to which your Dripos subscription will be billed"}
        />

        <BillingModal ref={(e) => (this.billingModal = e)} refresh={() => this.syncState()} />

        <Tab
          data={[
            {label: "Payment Methods", id: "methods"},
            {label: "History", id: "history"},
            {label: "Subscriptions", id: "plan"},
            {label: "Billing Address", id: "address"},
          ]}
        >
          {(id) => {
            if (id === "methods") {
              return this.renderPaymentMethods();
            }

            if (id === "history") {
              return this.renderHistory();
            }

            if (id === "plan") {
              return this.renderPlans();
            }

            if (id === "address") {
              return this.renderAddress();
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(BillingPage);

import React, {Component} from "react";
import {Table, Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {Download} from "react-feather";
import {request} from "../../../../utils/request";
import {getPayrollEmployees} from "../../../../utils/payroll-helper";
import b64toBlob from "b64-to-blob";
import LoadingSpinner from "../../../../components/loading-spinner";

class PayrollEmployeeTaxDocumentsPage extends Component {
  state = {isLoading: true, docData: null, payrollEmployees: null};

  async componentDidMount() {
    let docData = await request(`payroll/employee-tax-documents`);
    let payrollEmployees = await getPayrollEmployees();

    docData = docData.map((doc) => {
      return {
        ...doc,
        name: payrollEmployees.find((emp) => emp.CHECK_EMPLOYEE_ID === doc.employee)?.FULL_NAME,
      };
    });

    this.setState({isLoading: false, docData, payrollEmployees});
  }

  downloadTaxDocument = async (checkDocumentId) => {
    let pdf = await request("payroll/employee-tax-documents/" + checkDocumentId + "/download");

    let blob = b64toBlob(pdf, "application/pdf");
    let blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };

  render() {
    let {isLoading, docData} = this.state;

    let columns = [
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Label",
        value: "label",
      },
      {
        label: "Jurisdiction",
        value: "jurisdiction",
      },
      {
        label: "Year",
        value: "year",
      },
      {
        label: "Filed On",
        value: "filed_on",
      },
      {
        label: "Download",
        value: "id",
        format: (value) => {
          return (
            <div style={{cursor: "pointer"}} onClick={() => this.downloadTaxDocument(value)}>
              <Download />
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageHeadings label={"Employee Tax Documents"} />

        {/*<Card>*/}
        {isLoading && (
          <div className="py-10">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && <Table pagination columns={columns} data={docData} />}
        {/*</Card>*/}
      </div>
    );
  }
}

export default PayrollEmployeeTaxDocumentsPage;

import React, {Component} from "react";
import PayrollGroupOnboardingPage from "./payroll-group-onboarding-page";
import PayrollOnboardingPage from "./payroll-onboarding-page";
import {setupReduxConnection} from "../../../../redux";
import PayrollDashboardPage from "./payroll-dashboard-page";
import PayrollLandingPage from "./payroll-landing-page";
import {PAYROLL_ONBOARDING_STATUSES} from "../../../../utils/payroll-constants";
import PayrollRedirectPage from "./payroll-redirect-page";

class PayrollMainPage extends Component {
  render() {
    const {PAYROLL_WHITELISTED} = this.props.shop.settings;
    const {payrollGroup} = this.props.shop;

    if (PAYROLL_WHITELISTED !== "1") {
      return <PayrollLandingPage />;
    }

    if (
      !payrollGroup?.STATUS ||
      PAYROLL_ONBOARDING_STATUSES[payrollGroup?.STATUS] < PAYROLL_ONBOARDING_STATUSES["WORKPLACES_CREATED"]
    ) {
      return <PayrollGroupOnboardingPage />;
    }

    if (payrollGroup?.STATUS !== "LIVE") {
      return <PayrollOnboardingPage />;
    }

    if (!payrollGroup.RUNS_PAYROLL) {
      return <PayrollRedirectPage />;
    }

    return <PayrollDashboardPage />;
  }
}

export default setupReduxConnection(["shop", "payroll"])(PayrollMainPage);

import {getStore} from "../redux";
import {addNotificationData, removeNotification, updateNotification} from "../redux/notification";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {CheckCircleIcon} from "@heroicons/react/solid";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 * Adds a notification
 *
 * @param params
 * @param params.label
 * @param params.description
 * @param params.timeout
 * @param params.icon
 * @param params.buttons
 * @param params.undo
 *
 */
export function addNotification(params) {
  let {timeout, icon} = params;
  let {dispatch} = getStore();

  const notificationPayload = {
    id: "note_" + randomString(24),
    start: Date.now(),
    ...params,
  };

  if (timeout) {
    notificationPayload.dismiss = timeout;

    let intervalId = setInterval(() => {
      dispatch(
        updateNotification(notificationPayload.id, {
          dismiss: Math.round((timeout * 1000 + notificationPayload.start - Date.now()) / 1000),
        })
      );
    }, 1000);

    let timeoutId = setTimeout(() => {
      dispatch(removeNotification(notificationPayload));
    }, 1000 * timeout);

    notificationPayload.onDelete = () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }

  if (icon === "check") {
    notificationPayload.icon = <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
  } else if (icon === "error") {
    notificationPayload.icon = (
      <FontAwesomeIcon className="text-red-500" icon="fa-solid fa-circle-exclamation" />
    );
  }

  dispatch(addNotificationData(notificationPayload));
}

export function showSuccessNotification(label, description, ...params) {
  addNotification({label, description, timeout: 5, icon: "check", ...params});
}

export function showErrorNotification(label, description, ...params) {
  addNotification({label, description, timeout: 5, icon: "error", ...params});
}

import React, {Component} from "react";
import {FormInput, FormTextArea, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import LabelerDropdown from "../../../dropdowns/operations/labeler-dropdown";
import LabelCategoryDropdown from "../../../dropdowns/operations/label-category-dropdown";

class LabelModal extends Component {
  state = {label: null};

  open(label = null) {
    this.setState({label}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createLabel({name, notes, category, labeler}) {
    let serverLabel = await request("labels", "POST", {
      NAME: name,
      NOTES: notes,
      LABEL_CATEGORY_ID: category,
      LABELER_ID: labeler,
    });

    this.props.addState(serverLabel);
    this.modal.close();
  }

  async saveLabel({name, notes, category, labeler, labelerName, categoryName}) {
    const {label} = this.state;

    let serverLabel = await request("labels/" + label.ID, "PATCH", {
      NAME: name,
      NOTES: notes,
      LABEL_CATEGORY_ID: category,
      LABELER_ID: labeler,
    });

    serverLabel.LABELER_NAME = labelerName;
    serverLabel.LABEL_CATEGORY_NAME = categoryName;

    this.props.updateState(serverLabel);
    this.modal.close();
  }

  render() {
    const {label} = this.state;

    return (
      <Modal
        buttonLabel={label ? "Save" : "Add"}
        label={label ? "Edit Label" : "Create Label"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={label ? this.saveLabel.bind(this) : this.createLabel.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Label name is required"),
          })}
          initialValues={{
            name: label?.NAME ?? "",
            notes: label?.NOTES ?? "",
            labeler: label?.LABELER_ID,
            labelerName: label?.LABELER_NAME ?? "",
            category: label?.LABEL_CATEGORY_ID,
            categoryName: label?.LABEL_CATEGORY_NAME ?? "",
          }}
        >
          {(formikOptions) => {
            const {setFieldValue, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="My Cool Label"
                  label="Label Name"
                  name="name"
                />

                <LabelerDropdown
                  onChangeSoft={({label}) => setFieldValue("labelerName", label)}
                  options={formikOptions}
                  hint="optional"
                  name="labeler"
                />

                <LabelCategoryDropdown
                  onChangeSoft={({label}) => setFieldValue("categoryName", label)}
                  options={formikOptions}
                  hint="optional"
                  name="category"
                />

                <FormTextArea options={formikOptions} label="Label Notes" name="notes" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default LabelModal;

import React, {Component} from "react";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import AlertService from "../../../layouts/services/alert-service";
import StepNavCard from "../../../features/step-nav-card";
import PayrollEmployeeFormSetupComponent from "../../../features/payroll/onboard/employee/payroll-employee-form-setup-component";
import PayrollEmployeeTaxAuthSetupComponent from "../../../features/payroll/onboard/employee/payroll-employee-tax-auth-setup-component";
import PayrollWelcomeSetupComponent from "../../../features/payroll/onboard/employee/payroll-welcome-setup-component";
import {withRouter} from "../../../utils/navigation";

class PayrollEmployeeOnboardPage extends Component {
  state = {isLoading: true, fullName: null, defaultStep: 0};

  async componentDidMount() {
    const {UNIQUE_ID} = this.props.router.params;

    const {CHECK_EMPLOYEE_ID, FULL_NAME} = await request(`payroll/hub/employees/${UNIQUE_ID}`, "GET", null);

    let defaultStep = 0;
    if (CHECK_EMPLOYEE_ID) {
      defaultStep = 2;
    }

    this.setState({isLoading: false, fullName: FULL_NAME, defaultStep});
  }

  render() {
    let {isLoading, fullName, defaultStep} = this.state;

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <div className="px-10">
        <AlertService />

        <StepNavCard
          router={this.props.router}
          defaultStep={defaultStep}
          label={`Payroll Setup for ${fullName}`}
          steps={[
            {
              label: "Welcome",
              component: PayrollWelcomeSetupComponent,
            },
            {
              label: "Basic Information",
              component: PayrollEmployeeFormSetupComponent,
              hideBack: true,
            },
            {
              label: "Tax and Authorization Setup",
              hideBack: true,
              component: PayrollEmployeeTaxAuthSetupComponent,
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(PayrollEmployeeOnboardPage);

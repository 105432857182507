import {Card} from "@frostbyte-technologies/frostbyte-tailwind";

export const PatronsTab = () => {
  return (
    <>
      <Card className={"mt-8"}>
        <p className={"m-8 py-8"}>patron tools coming soon!!!!!!!!!!!!!!!!!!</p>
      </Card>
    </>
  );
};

import React, {Component} from "react";
import ChoosePaydayComponent from "../choose-payday-component";
import {createOffCyclePayroll, hasStartDateError} from "../../../../utils/payroll-helper";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {PAYMENT_METHOD_TYPES} from "../../../../utils/payroll-constants";
import {readableDate} from "../../../../utils/util";

class OffCycleChoosePaydayComponent extends Component {
  state = {
    loading: false,
    payday: null,
    periodStart: null,
    periodEnd: null,
    paymentMethod: PAYMENT_METHOD_TYPES.DIRECT_DEPOSIT.value,
  };

  componentDidMount() {
    this.props.loadPayroll();
  }

  async handleClick() {
    let {periodStart, periodEnd, payday, paymentMethod} = this.state;

    const {loaded} = this.props.payroll;

    if (loaded && hasStartDateError(this.props.payroll)) {
      const start_date = this.props.payroll.company;
      await showErrorAlert(
        "Invalid Payday",
        `Your first allowed payday is your company start date, ${readableDate(
          start_date
        )}. Please contact Dripos Support if you would like to pay your emplyoees sooner.`
      );
      return;
    }

    let payload = {
      PERIOD_START: periodStart,
      PERIOD_END: periodEnd,
      PAYDAY: payday,
      IS_MANUAL: paymentMethod === PAYMENT_METHOD_TYPES.MANUAL.value,
    };

    try {
      await createOffCyclePayroll(payload);
      return true;
    } catch (error) {
      let label = "Invalid Dates";
      let description = "The dates selected are invalid. Please correct them to run an off-cycle payroll";

      if (error === "APPROVAL_DEADLINE_PASSED") {
        label = "Approval Deadline Passed";
        description =
          "The approval deadline for the specified dates has passed. Please choose a different paydate";
      }

      if (error?.error === "PAYDAY_NOT_IN_FUTURE") {
        label = "Payday Invalid";
        description = "Payday must be today or in future";
      }

      await showErrorAlert(label, description, "Ok");
    }
  }

  render() {
    let {loading} = this.state;

    return (
      <ChoosePaydayComponent
        loading={loading}
        onPeriodStartChange={(newPeriodStart) => {
          this.setState({periodStart: newPeriodStart});
        }}
        onPeriodEndChange={(newPeriodEnd) => {
          this.setState({periodEnd: newPeriodEnd});
        }}
        onPaydayChange={(newPayday) => {
          this.setState({payday: newPayday});
        }}
        onPaymentMethodChange={(paymentMethod) => {
          this.setState({paymentMethod});
        }}
        chooseManual={true}
        chooseWorkPeriod={true}
      />
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(OffCycleChoosePaydayComponent));

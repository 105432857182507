import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ImageFormDropZone from "../../../../components/image-form-drop-zone";
import {request} from "../../../../utils/request";
import {showErrorNotification} from "../../../../utils/notification-helper";

class OcrModal extends Component {
  state = {resolve: null};

  open() {
    return new Promise((resolve) => {
      this.setState({resolve}, () => {
        this.formikRef && this.formikRef.resetForm();
        this.modal.open();
      });
    });
  }

  handleSubmit = async (values) => {
    const {image} = values;
    const {resolve} = this.state;

    request("aws/textract/", "POST", {
      URL: image,
    })
      .then((data) => {
        resolve(data);
        this.modal.close();
      })
      .catch((err) => {
        console.log("ERROR", err);

        showErrorNotification("Error analyzing invoice.");
        this.modal.close();
      });
  };

  render() {
    return (
      <Modal
        buttonLabel="Upload"
        label="Upload Invoice"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{image: null}}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ImageFormDropZone label="Invoice Image" options={formikOptions} name="image" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default OcrModal;

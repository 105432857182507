import React, {Component} from "react";
import {Modal, FormSelect, FormInput, FormBoolean} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import {showErrorNotification} from "../../../utils/notification-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormRow from "../../../components/form-row";
import {CHECK_POLICY_TYPE} from "./extra-pay-modal";

class PTOModal extends Component {
  state = {
    payrollId: null,
    employeeId: null,
    checkEmployeeId: null,
    employeeHomeLocationId: null,
    name: null,
    pay: null,
    rates: [],
    type: null,
    ratesDict: {},
    policies: [],
    policiesDict: {},
  };

  open(payrollId, row, type = "pto") {
    const {ID, CHECK_EMPLOYEE_ID, LOCATION_ID, NAME, RATES = [], POLICIES = []} = row;

    this.setState(
      {
        payrollId,
        employeeId: ID,
        checkEmployeeId: CHECK_EMPLOYEE_ID,
        employeeHomeLocationId: LOCATION_ID,
        name: NAME,
        pay: row.ADDITIONAL_PTO[type],
        rates: RATES,
        type,
        policies: POLICIES.filter((_policy) => CHECK_POLICY_TYPE[_policy.TYPE] === type),
      },
      () => {
        this.modal.open();
        this.createRateDict(RATES);
        this.createPoliciesDict(POLICIES);
      }
    );
  }

  createRateDict(rates) {
    const ratesDict = rates.reduce((accum, rate) => {
      accum[rate.ID] = rate.AMOUNT;
      return accum;
    }, {});

    this.setState({ratesDict});
  }

  createPoliciesDict(policies) {
    const policiesDict = policies.reduce((accum, policy) => {
      accum[policy.ID] = {BALANCE: policy.BALANCE, UNLIMITED: policy.UNLIMITED};
      return accum;
    }, {});

    this.setState({policiesDict});
  }

  delete = async () => {
    let {employeeHomeLocationId} = this.state;
    const {employeeId, type} = this.state;

    await this.submitRegularPay(0, 0, employeeHomeLocationId);

    if (this.props.updateBreakdown) {
      this.upsertAdditionalPTO(employeeId, type, null);
    }

    this.modal.close();
  };

  handleSubmit = async (values) => {
    let {hours, rate, customRate, location, policy} = values;
    const {ratesDict} = this.state;

    if (this.submitted) {
      return;
    }

    this.submitted = true;

    let amount = rate === -1 ? hours * (customRate * 100) : hours * ratesDict[rate];

    if (!amount || amount === 0) {
      showErrorNotification(
        "Error Submitting PTO",
        "No pay detected for this submission. Please correct the hour amount or pay rate to continue."
      );

      return this.modal.stopLoading();
    }

    await this.submitRegularPay(hours, amount, policy, rate, customRate);

    this.modal.close();

    setTimeout(() => (this.submitted = false), 1000);
  };

  submitRegularPay = async (hours, amount, policy, rate = null, customRate = null) => {
    const {employeeId, type} = this.state;

    let {payrollId, employeeHomeLocationId, checkEmployeeId, pay} = this.state;

    let payload = {
      CHECK_PAYROLL_ID: payrollId,
      EMPLOYEE_ID: checkEmployeeId,
      LOCATION_ID: employeeHomeLocationId,
      HOURS: hours,
      TYPE: type,
      AMOUNT: amount,
      ID: pay?.ID,
      METADATA: {
        ...(policy && {employeePolicyId: policy + ""}),
        ...(rate && {
          rateId: rate + "",
        }),
      },
    };

    const itemMetaId = await request(`payroll/run-payroll/set-regular`, "POST", payload);

    if (this.props.updateBreakdown) {
      this.upsertAdditionalPTO(employeeId, type, {
        ID: itemMetaId,
        AMOUNT: amount,
        HOURS: hours,
        EMPLOYEE_POLICY_ID: policy,
        RATE_ID: rate,
        ...(customRate && {CUSTOM_RATE: customRate}),
      });
    }
  };

  upsertAdditionalPTO(employeeId, type, replaceValue) {
    const {breakdown} = this.props;

    const employeePTOEntry = breakdown.find(({ID}) => ID === employeeId);
    employeePTOEntry.ADDITIONAL_PTO[type] = replaceValue;

    this.props.updateBreakdown(breakdown);
  }

  getRemainingHours(total, current, unlimited) {
    if (unlimited) {
      return "Unlimited";
    }

    if (!current) {
      return total > 0 ? total : 0;
    }

    return Math.max(total - current, 0);
  }

  render() {
    let {name, pay, rates, hours, policies, policiesDict} = this.state;

    const rateData = [
      ...rates.map((rate) => ({
        label: `${rate.NAME} ($${toDollars(rate.AMOUNT)})`,
        value: rate.ID,
      })),
    ];

    rateData.push({label: "Custom", value: -1});

    const policyData = policies?.map(({ID, POLICY_NAME}) => ({
      value: ID,
      label: POLICY_NAME,
    }));

    policyData.unshift({value: null, label: "None"});

    return (
      <Modal
        label={`PTO Pay for ${name}`}
        buttonLabel={"Save"}
        deleteLabel={pay ? "Delete" : undefined}
        deleteOnClick={() => (pay ? this.delete() : undefined)}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            policy: pay?.EMPLOYEE_POLICY_ID ?? null,
            hours: pay?.HOURS ?? "",
            rate: pay?.RATE_ID ?? null,
            customRate: pay?.CUSTOM_RATE ?? null,
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object({
            hours: Yup.number().typeError("Hours is a required field").required("Hours is a required field"),
            rate: Yup.number().typeError("Pay rate is required").required("Pay rate is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            const {rate, policy, hours} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label={"Employee Policy"}
                  name={"policy"}
                  options={formikOptions}
                  data={policyData}
                  tooltip={
                    "If empty, policy balance will not be reduced, but employee will still be paid for time off."
                  }
                  flex
                />

                {!!policy && (
                  <div className={"mt-1 text-sm text-gray-400 italic"}>{`Remaining: ${this.getRemainingHours(
                    policiesDict[policy].BALANCE,
                    hours,
                    policiesDict[policy].UNLIMITED
                  )} Hrs`}</div>
                )}

                <FormInput label={"Hours"} name={"hours"} options={formikOptions} />

                <FormSelect
                  label={`Select Pay Rate for ${name}'s PTO`}
                  name={"rate"}
                  options={formikOptions}
                  data={rateData}
                />

                {rate === -1 && (
                  <FormInput label={"Custom Rate ($)"} name={"customRate"} options={formikOptions} />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PTOModal;

import GrayBadge from "../../../components/badges/gray-badge";
import WarningBadge from "../../../components/badges/warning-badge";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";

function BrandedAppBadge(props) {
  const {status} = props;

  switch (status) {
    case "DRAFT":
      return <GrayBadge>Draft</GrayBadge>;
    case "BUILDING":
      return <WarningBadge>Building</WarningBadge>;
    case "FAILED":
      return <DangerBadge>Failed</DangerBadge>;
    case "READY_FOR_SUBMIT":
      return <DangerBadge blue={true}>Ready for Submission</DangerBadge>;
    case "LIVE":
      return <SuccessBadge>Live</SuccessBadge>;
  }
}

export default BrandedAppBadge;

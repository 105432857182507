import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {deepClone} from "../../../../utils/util";
import {setupReduxConnection} from "../../../../redux";

const REQUEST_STATUSES = {
  REQUESTED: "REQUESTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
  FULFILLED: "FULFILLED",
  PARTIAL_FULFILL: "PARTIAL_FULFILL",
};

const REQUEST_STATUS_DISPLAYS = {
  REQUESTED: "Requested",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
  FULFILLED: "Fulfilled",
  PARTIAL_FULFILL: "Partially Fulfilled",
};

class TransferTableExpandableComponent extends Component {
  handleClick(item) {}

  updateRequestStatus(item, newStatus) {
    const {request: oldRequest} = this.props;

    request("inventory-transfers/items/requests/" + item.ID, "PATCH", {
      STATUS: newStatus,
    });

    this.props.syncState({
      ...oldRequest,
      REQUESTS: oldRequest.REQUESTS.map((oldItem) => {
        return oldItem.ID === item.ID ? {...item, STATUS: newStatus} : oldItem;
      }),
    });
  }

  renderButtons(item) {
    const buttons = [];

    if (item.STATUS === REQUEST_STATUSES.REQUESTED || item.STATUS === REQUEST_STATUSES.CANCELLED) {
      buttons.push(
        <button
          type="button"
          onClick={() => this.updateRequestStatus(item, REQUEST_STATUSES.REJECTED)}
          className="mr-3 text-sm text-red-500 hover:text-red-700 font-semibold"
        >
          Reject
        </button>,
        <button
          type="button"
          onClick={() => this.updateRequestStatus(item, REQUEST_STATUSES.ACCEPTED)}
          className="text-sm text-green-500 hover:text-green-700 font-semibold"
        >
          Accept
        </button>
      );
    }

    if (item.STATUS === REQUEST_STATUSES.PARTIAL_FULFILL) {
      buttons.push(
        <button
          type="button"
          onClick={() => this.updateRequestStatus(item, REQUEST_STATUSES.ACCEPTED)}
          className="text-sm text-green-500 hover:text-green-700 font-semibold"
        >
          Accept
        </button>
      );
    }

    if (item.STATUS === REQUEST_STATUSES.ACCEPTED) {
      buttons.push(
        <button
          type="button"
          onClick={() => this.updateRequestStatus(item, REQUEST_STATUSES.CANCELLED)}
          className="text-sm text-red-500 hover:text-red-700 font-semibold"
        >
          Cancel
        </button>
      );
    }

    return <div>{buttons}</div>;
  }

  render() {
    const {request} = this.props;
    const {location, companyLocations: locations} = this.props.shop;

    const data = deepClone(
      request.REQUESTS.filter(
        (_item) => _item.STATUS !== REQUEST_STATUSES.REJECTED && _item.STATUS !== REQUEST_STATUSES.FULFILLED
      )
    );

    return (
      <div className="px-10 py-3 text-sm">
        <Table
          className="mt-2"
          data={data}
          columns={[
            {value: "NAME", label: "Ingredient Name"},
            {
              value: "SUPPLIER_LOCATION_ID",
              label: "Supplying Location",
              format: (val) => {
                return locations.find((l) => l.ID === val)?.NAME;
              },
            },
            {
              value: "RECEIVER_LOCATION_ID",
              label: "Receiving Location",
              format: (val) => {
                return locations.find((l) => l.ID === val)?.NAME;
              },
            },
            {
              value: "DISPLAY_REQUESTED_QUANTITY",
              label: "Requested Quantity",
              format: (val, row) => {
                return (
                  (row.DISPLAY_REQUESTED_QUANTITY - (row.DISPLAY_FULFILLED_QUANTITY || 0)).toFixed(2) +
                  " " +
                  row.INGREDIENT.DISPLAY_UNIT.NAME
                );
              },
            },
            {
              value: "STATUS",
              label: "Status",
              format: (val) => {
                return REQUEST_STATUS_DISPLAYS[val];
              },
            },
            {
              value: "",
              label: "",
              format: (_, item) => {
                if (item.SUPPLIER_LOCATION_ID !== location.ID) {
                  return <div />;
                }

                return this.renderButtons(item);
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TransferTableExpandableComponent);

import React, {Component} from "react";
import {Card, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import ShopImagesModal from "../../../modals/shop/shop-images-modal";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {setupReduxConnection} from "../../../redux";

class ImagesTab extends Component {
  render() {
    return (
      <div>
        <ShopImagesModal ref={(e) => (this.shopImagesModal = e)} />

        <Card>
          <TwoColumnList
            buttons={[
              {
                label: "Edit",
                onClick: () => this.shopImagesModal.open(),
              },
            ]}
            label="Images"
            description="Edit your location's logos and images"
            data={[
              {
                label: "Logo",
                value: (
                  <img
                    className="h-24 w-24 rounded-md"
                    src={getObjectImage(this.props.shop.location, "ICON", "appicon.png")}
                    alt=""
                  />
                ),
              },
              {
                label: "Shop Image",
                value: (
                  <img
                    className="h-24 w-24 rounded-md"
                    src={getObjectImage(this.props.shop.location, "LOGO", "appicon.png")}
                    alt=""
                  />
                ),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(ImagesTab);

import {
  faAward,
  faBadgeDollar,
  faBasketShopping,
  faBrowser,
  faCalendarCircleUser,
  faCashRegister,
  faChairOffice,
  faCheese,
  faClipboard,
  faCoffee,
  faCreditCard,
  faDollarSign,
  faEnvelopeOpenDollar,
  faFileInvoice,
  faFileInvoiceDollar,
  faGiftCard,
  faHatChef,
  faHeart,
  faLayerGroup,
  faMoneyBill,
  faMoneyCheckDollarPen,
  faMoneyCheckPen,
  faPercent,
  faPiggyBank,
  faPrint,
  faReceipt,
  faSackDollar,
  faShelves,
  faShoppingCart,
  faStar,
  faStore,
  faTags,
  faTicket,
  faTruck,
  faTypewriter,
  faUserCheck,
  faUserClock,
  faUserDoctor,
  faUserGroup,
  faUserHeadset,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import {PERMISSIONS} from "../utils/constants";
import {TEAM_ROUTES} from "../layouts/side-navs/team-side-nav";
import {SALES_ROUTES} from "../layouts/side-navs/sales-side-nav";
import {OPERATION_ROUTES} from "../layouts/side-navs/operations-side-nav";

export const SEARCHABLE_SIDE_MENUS = [...SALES_ROUTES, ...TEAM_ROUTES, ...OPERATION_ROUTES];
export const HEADER_MENU = [
  {
    url: "/sales",
    label: "Sales",
    permissions: [PERMISSIONS.SALES.FULL.id],
    routes: [
      {
        live: true,
        name: "Menu",
        description: "View and create products and categories for customers to buy.",
        url: "/menu",
        icon: faCoffee,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        name: "Tickets",
        description: "Find any past, present, or future ticket.",
        url: "/tickets",
        icon: faTicket,
        permissions: [PERMISSIONS.SALES.TICKETS_FULL.id],
      },
      {
        live: true,
        name: "Checkout Flow",
        description: "Alter your ticket types and payment types.",
        url: "/flow/tickets",
        icon: faMoneyCheckPen,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        name: "Discounts",
        description: "Manage discounts to be applied on checkout.",
        url: "/discounts",
        icon: faBadgeDollar,
        permissions: [PERMISSIONS.SALES.DISCOUNTS_FULL.id],
      },
      {
        live: true,
        name: "Tags",
        description: "Manage product tags for filtering reports.",
        url: "/tags",
        icon: faTags,
        permissions: [PERMISSIONS.SALES.TAGS_FULL.id],
      },
      {
        live: true,
        name: "Third Party Delivery",
        description: "Manage third party delivery services.",
        url: "/third",
        icon: faTruck,
        permissions: [PERMISSIONS.SALES.TAGS_FULL.id],
      },
      // {
      //   live: false,
      //   name: "Table Service",
      //   description: "Manage tables and dining.",
      //   url: "/tables",
      //   icon: faTablePicnic,
      //   permissions: [PERMISSIONS.SALES.TABLE_FULL.id],
      // },
    ],
  },
  {
    url: "/team",
    label: "Team",
    permissions: [PERMISSIONS.TEAM.FULL.id],
    routes: [
      {
        live: true,
        name: "Approval Center",
        description: "View Time Cards, Time Off Requests, and Trade Requests that require approval.",
        url: "/approvals",
        icon: faUserCheck,
        permissions: [
          PERMISSIONS.TEAM.APPROVALS_FULL.id,
          PERMISSIONS.TEAM.APPROVALS_TIME_CARDS.id,
          PERMISSIONS.TEAM.APPROVALS_TIME_OFF.id,
          PERMISSIONS.TEAM.APPROVALS_TRADE_REQUESTS.id,
          PERMISSIONS.TEAM.APPROVALS_OPEN_SHIFT_REQUESTS.id,
        ],
      },
      {
        live: true,
        name: "My Team",
        description: "Manage members of your team and their roles.",
        url: "/team",
        icon: faUserGroup,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        name: "Scheduling",
        description: "Create and manage your teams' schedule.",
        url: "/scheduling",
        icon: faCalendarCircleUser,
        permissions: [PERMISSIONS.TEAM.SCHEDULING_FULL.id],
      },
      {
        live: true,
        name: "Time Off Policies",
        description: "Create and manage your teams' schedule.",
        url: "/policies",
        icon: faCalendarCircleUser,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        name: "Time Cards",
        description: "View and edit your employees' time cards.",
        url: "/cards",
        icon: faChairOffice,
        permissions: [PERMISSIONS.TEAM.TIME_FULL.id],
      },
      {
        live: true,
        name: "Breaks",
        description: "View and edit your employees' break options.",
        url: "/breaks",
        icon: faUserClock,
        permissions: [PERMISSIONS.TEAM.TIME_FULL.id],
      },
      {
        live: true,
        name: "Memos",
        description: "Post messages for your employees to see.",
        url: "/memos",
        icon: faTypewriter,
        permissions: [PERMISSIONS.TEAM.MEMOS_FULL.id],
      },
      {
        live: true,
        name: "Onboarding",
        description: "Manage the hiring of employees",
        url: "/onboarding/overview",
        icon: faUsers,
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
      },
    ],
  },
  {
    url: "/menu",
    label: "Marketing",
    permissions: [PERMISSIONS.MARKETING.FULL.id],
    routes: [
      {
        live: true,
        name: "Marketing Campaigns",
        description: "Create campaigns to reach more customers.",
        url: "/campaigns",
        icon: faAward,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        name: "Promotions & Coupons",
        description: "Create promotions and coupons for your patrons to use.",
        url: "/deals",
        icon: faBadgeDollar,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
      {
        live: true,
        name: "Loyalty",
        description:
          "Keep customers coming back more often with the loyalty programs like challenges and tasks.",
        url: "/loyalty",
        icon: faStar,
        permissions: [PERMISSIONS.MARKETING.LOYALTY_FULL.id],
      },
      {
        live: true,
        name: "Gift Cards",
        description: "Award your patrons by creating physical and digital gift cards.",
        url: "/gifts",
        icon: faGiftCard,
        permissions: [PERMISSIONS.MARKETING.GIFT_CARDS_FULL.id],
      },
      {
        live: true,
        name: "Patrons",
        description: "View all of the customers that have ordered from your shop with Patrons.",
        url: "/patrons",
        icon: faUsers,
        permissions: [PERMISSIONS.MARKETING.PATRONS_FULL.id],
      },
      {
        live: true,
        name: "Fundraisers",
        description: "Help others support great causes with Fundraisers.",
        url: "/fundraisers",
        icon: faHeart,
        permissions: [PERMISSIONS.MARKETING.FUNDRAISERS_FULL.id],
      },
      // {
      //   live: true,
      //   name: "Events",
      //   description: "Manage your marketing events.",
      //   url: "/events",
      //   icon: faCalendarDay,
      //   permissions: [PERMISSIONS.MARKETING.EVENTS_FULL.id],
      // },
    ],
  },
  {
    url: "/menu",
    label: "Operations",
    permissions: [PERMISSIONS.OPERATIONS.FULL.id],
    routes: [
      {
        live: true,
        name: "Cash Management",
        description: "Track your cash from both cash drawers and tip buckets.",
        url: "/cash",
        icon: faCashRegister,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        name: "(Legacy) Ingredients",
        description: "Edit the ingredients that are used to create your menu items.",
        url: "/ingredients",
        icon: faCheese,
        permissions: [PERMISSIONS.OPERATIONS.INGREDIENTS_FULL.id],
      },
      {
        live: true,
        name: "Labels",
        description: "Make labels for your in store products that can be printed with your label printer.",
        url: "/labels",
        icon: faPrint,
        permissions: [PERMISSIONS.OPERATIONS.LABELS_FULL.id],
      },
      {
        live: true,
        name: "Checklists",
        description: "Ensure your employees follow a list of tasks during their shifts with Checklists.",
        url: "/checklists",
        icon: faClipboard,
        permissions: [PERMISSIONS.OPERATIONS.CHECKLISTS_FULL.id],
      },
      {
        live: true,
        name: "Simple Inventory",
        description: "Manage availability of your products with Inventory.",
        url: "/inventory",
        icon: faShelves,
        permissions: [PERMISSIONS.OPERATIONS.INVENTORY_FULL.id],
      },
      {
        live: true,
        name: "Invoicing",
        description: "Create and manage invoices with Invoicing.",
        url: "/invoicing",
        icon: faFileInvoice,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        name: "Billing Accounts",
        description:
          "Manage your billing accounts, send payment links, and view billing tickets with Billing Accounts.",
        url: "/billing-accounts",
        icon: faFileInvoiceDollar,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        name: "Customer Chats",
        description: "Engage with your customers directly and enhance their experiences with Customer Chats.",
        url: "/chats",
        icon: faUserHeadset,
        permissions: [PERMISSIONS.OPERATIONS.SUPPORT_TEXTS_FULL.id],
      },
      {
        live: true,
        name: "Supply Chain",
        description: "Manage your inventory, purchase orders, and cost of goods.",
        url: "/supply",
        icon: faHatChef,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
    ],
  },
  {
    url: "/menu",
    label: "Reports",
    permissions: [PERMISSIONS.REPORTS.FULL.id],
    routes: [
      {
        live: true,
        name: "Sales",
        description: "Understand trends in your sales over time.",
        url: "/reports/sales",
        icon: faDollarSign,
        permissions: [PERMISSIONS.REPORTS.SALES_REPORT.id],
      },
      {
        live: true,
        name: "Compare Sales",
        description: "Compare sales over different periods of time",
        url: "/reports/compare",
        permissions: [],
        icon: faCoffee,
      },
      {
        live: true,
        name: "Payment Methods",
        description: "Understand how your customers are paying for tickets.",
        url: "/reports/payment-methods",
        icon: faCreditCard,
        permissions: [PERMISSIONS.REPORTS.PAYMENT_METHODS_REPORT.id],
      },
      {
        live: true,
        name: "All Products Sales",
        description: "Understand how different products are selling in the same time period.",
        url: "/reports/all-products-sales",
        icon: faShoppingCart,
        permissions: [PERMISSIONS.REPORTS.PRODUCTS_REPORT.id],
      },
      // {
      //   live: true,
      //   name: "Individual Product Sales",
      //   description:
      //     "Understand the popularity trends of a specific product over time",
      //   url: "/reports/individual-product-sales",
      //   icon: faBasketShopping,
      //   permissions: [PERMISSIONS.REPORTS.PRODUCTS_REPORT.id],
      // },
      {
        live: true,
        name: "Modifier Sales",
        description: "Understand the sales of different modifiers.",
        url: "/reports/modifier-sales",
        icon: faBasketShopping,
        permissions: [PERMISSIONS.REPORTS.MODIFIER_REPORT.id],
      },
      {
        live: true,
        name: "Inventory Sales",
        description: "Understand the sales of different product inventory.",
        url: "/reports/inventory-sales",
        icon: faBasketShopping,
        permissions: [PERMISSIONS.REPORTS.INVENTORY_REPORT.id],
      },
      {
        live: true,
        name: "Ingredient Sales",
        description: "Understand the sales of different ingredients.",
        url: "/reports/ingredient-sales",
        icon: faBasketShopping,
        permissions: [PERMISSIONS.REPORTS.INGREDIENT_REPORT.id],
      },
      {
        live: true,
        name: "Category Sales",
        description: "Understand how different categories are performing in the same time period.",
        url: "/reports/category-sales",
        icon: faLayerGroup,
        permissions: [PERMISSIONS.REPORTS.CATEGORY_REPORT.id],
      },
      {
        live: true,
        name: "Platform Sales",
        description: "Understand what platforms your customers are ordering from.",
        url: "/reports/platform-sales",
        icon: faBrowser,
        permissions: [PERMISSIONS.REPORTS.PLATFORM_REPORT.id],
      },
      {
        live: true,
        name: "Discounts Report",
        description: "Understand which discounts are being applied.",
        url: "/reports/discounts",
        icon: faPercent,
        permissions: [PERMISSIONS.REPORTS.PLATFORM_REPORT.id],
      },
      {
        live: true,
        name: "Ticket Type Sales",
        description: "Understand the popularity of your Ticket Types.",
        url: "/reports/order-type-sales",
        icon: faStore,
        permissions: [PERMISSIONS.REPORTS.ORDER_TYPE_REPORT.id],
      },
      {
        live: true,
        name: "Gift Card Sales",
        description: "Understand trends in gift cards sales.",
        url: "/reports/gift-card-sales",
        icon: faGiftCard,
        permissions: [PERMISSIONS.REPORTS.GIFT_CARDS_REPORT.id],
      },
      {
        live: true,
        name: "Taxable Sales",
        description: "Understand how much of your sales is taxable.",
        url: "/reports/taxable",
        icon: faUserClock,
        permissions: [PERMISSIONS.REPORTS.TAXES_REPORT.id],
      },
      {
        live: true,
        name: "Tax Categories",
        description: "Understand the breakdown of your taxes by tax rate.",
        url: "/reports/tax-categories",
        icon: faUserDoctor,
        permissions: [PERMISSIONS.REPORTS.TAXES_REPORT.id],
      },
      // {
      //   live: false,
      //   name: "Taxes",
      //   description: "Understand how much of each tax you've collected.",
      //   url: "/reports/taxes",
      //   icon: faUserClock,
      //   permissions: [PERMISSIONS.REPORTS.TAXES_REPORT.id],
      // },
      {
        live: true,
        name: "Team Sales",
        description: "Understand what items each employee is selling.",
        url: "/reports/team-sales",
        icon: faUserClock,
        permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
      },
      {
        live: true,
        name: "Time Cards",
        description: "Understand your employees' time worked.",
        url: "/reports/time-cards",
        icon: faUserClock,
        permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
      },
      {
        live: true,
        name: "Labor vs Sales",
        description: "Understand your labor costs compared to sales.",
        url: "/reports/labor-vs-sales",
        icon: faPercent,
        permissions: [PERMISSIONS.REPORTS.LABOR_VS_SALES.id],
      },
      {
        live: true,
        name: "Cash Drawers",
        description: "Understand your cash drawers.",
        url: "/reports/cash-drawers",
        icon: faCashRegister,
        permissions: [PERMISSIONS.REPORTS.CASH_DRAWERS_REPORT.id],
      },
      {
        live: true,
        name: "Cash Flow",
        description: "Understand your cash flow.",
        url: "/reports/cash-flow",
        icon: faMoneyBill,
        permissions: [PERMISSIONS.REPORTS.CASH_FLOW_REPORT.id],
      },
      {
        live: true,
        name: "Custom Payment Types Tips",
        description: "Understand where tips are coming from when paid with custom types",
        url: "reports/custom",
        icon: faSackDollar,
        permissions: [PERMISSIONS.REPORTS.PAYMENT_METHODS_REPORT.id],
      },
      {
        live: true,
        name: "Invoicing",
        description: "Understand your invoicing revenues and payment patterns",
        url: "reports/invoicing",
        icon: faFileInvoice,
        permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
      },
      {
        live: true,
        name: "Ticket Completion",
        description: "Understand your efficiency in producing items",
        url: "reports/completion",
        icon: faFileInvoice,
        permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
      },
      {
        live: true,
        name: "Checklists",
        description: "Understand your operational tasks",
        url: "reports/checklists",
        icon: faFileInvoice,
        permissions: [PERMISSIONS.REPORTS.CHECKLISTS_REPORT.id],
      },
      {
        live: true,
        name: "Cost of Goods Sold",
        description: "Understand the cost of your products.",
        url: "reports/product_sales_to_cogs",
        icon: faFileInvoice,
        permissions: [PERMISSIONS.REPORTS.CHECKLISTS_REPORT.id],
      },
      {
        live: true,
        name: "Ingredient Usage",
        description: "Understand the cost of your products.",
        url: "reports/ingredient-usage",
        icon: faFileInvoice,
        permissions: [PERMISSIONS.REPORTS.CHECKLISTS_REPORT.id],
      },
    ],
  },
  {
    url: "/menu",
    label: "Finance",
    permissions: [PERMISSIONS.FINANCE.FULL.id],
    routes: [
      {
        live: true,
        name: "Payouts",
        description: "View payouts from Dripos to your attached bank account",
        url: "/payouts",
        icon: faEnvelopeOpenDollar,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        name: "Payroll",
        description: "Pay your employees in 3 clicks!",
        url: "/payroll",
        icon: faMoneyCheckDollarPen,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        name: "Accounting",
        description: "Simplify and automate your books",
        url: "/accounting",
        icon: faMoneyCheckDollarPen,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: false,
        name: "Cash Account",
        description: "Run your finances with Dripos",
        url: "/cash-account",
        icon: faPiggyBank,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        name: "Billing",
        description: "Manage your Dripos subscription!",
        url: "/billing",
        icon: faReceipt,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        name: "Dripos Capital",
        description:
          "Dripos Capital provides access to fast, flexible financing so you can manage cash flow and invest in growth",
        url: "/capital",
        icon: faReceipt,
        permissions: [PERMISSIONS.FINANCE.FULL.id],
      },
      {
        live: true,
        name: "Business Insurance",
        description:
          "Customized coverage offerings for Dripos customers to save up to 25% on business insurance",
        url: "/insurance",
        icon: faReceipt,
        permissions: [PERMISSIONS.FINANCE.FULL.id],
      },
    ],
  },
];

import React, {Component} from "react";
import {
  FormEmail,
  FormInput,
  FormPhone,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import FormRow from "../../components/form-row";
import {VendorRequests} from "../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../redux";
import {updateVendor} from "../../redux/supply";

class VendorContactModal extends Component {
  state = {contact: null};

  open(contact = null) {
    this.setState({contact}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  createContact = async ({first, last, phone, email, notes}) => {
    const {vendor} = this.props;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }
    const contactPayload = {
      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,

      IS_PRIMARY_CONTACT: vendor?.CONTACTS?.length === 0 || 0,
    };

    await VendorRequests.createVendorContact(vendor.ID, contactPayload);
    await getStore().dispatch(updateVendor(vendor.ID));

    this.props.syncState && (await this.props.syncState());
    this.modal.close();
  };

  saveContact = async ({first, last, phone, email, notes}) => {
    const {contact} = this.state;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    const serverContact = await VendorRequests.updateVendorContact(contact.VENDOR_ID, contact.ID, {
      CUSTOMER_GROUP_ID: null,
      ADDRESS_ID: null,

      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,
    });

    this.props.syncState && (await this.props.syncState());
    this.modal.close();
  };

  render() {
    const {contact, name} = this.state;

    return (
      <Modal
        buttonLabel={contact ? "Save" : "Add"}
        deleteLabel={contact ? "Delete" : ""}
        label={contact ? "Edit Contact" : "Create Contact"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={contact ? this.saveContact : this.createContact}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            first: Yup.string().required("First is required"),
            last: Yup.string().required("Last is required"),
            email: Yup.string().required("Email is required"),
            phone: Yup.string(),
          })}
          initialValues={{
            first: contact?.FIRST_NAME ?? name?.split(" ")[0],
            last: contact?.LAST_NAME ?? name?.split(" ")[1],
            email: contact?.EMAIL ?? "",
            phone: contact?.PHONE?.slice(1) ?? "",
            notes: contact?.NOTES ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput options={formikOptions} label="First name" name="first" flex />

                  <FormInput options={formikOptions} label="Last name" name="last" flex />
                </FormRow>

                <FormEmail options={formikOptions} label="Email Address" name="email" />

                <FormPhone options={formikOptions} label="Phone Number" name="phone" hint="optional" />

                <FormTextArea
                  options={formikOptions}
                  hint="optional"
                  label="Notes"
                  name="notes"
                  tooltip={{
                    label: "Internal Notes",
                    data: "These are internal notes that can be used to save arbitrary information about your contact. The contact does not have access to view this.",
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default VendorContactModal;

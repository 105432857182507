import React, {Component} from "react";
import Wizard from "../../../../components/wizard";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import PromotionForm from "../../../../forms/marketing/promotions/promotion-form";
import PromotionCard from "../../../../features/marketing/promotion-card";

class CreatePromotionPage extends Component {
  variant = null;

  async submitPromotion({promotion}) {
    delete promotion.PRODUCT_NAME;
    delete promotion.CATEGORY_NAME;
    delete promotion.RECURRING_PERIOD;
    delete promotion.RECURRING_START_DATE;
    delete promotion.RECURRING_END_DATE;

    const serverPromotion = await request("promotions", "POST", promotion);

    this.props.router.navigate("/promotion/" + serverPromotion.UNIQUE_ID);
  }

  render() {
    return (
      <Wizard
        ref={(e) => (this.wizardRef = e)}
        onSubmit={this.submitPromotion.bind(this)}
        onQuit={() => this.props.router.navigate("/promotions")}
        steps={[
          {
            id: 0,
            name: "Promotion info",
            description: "The details about the promotion and discount",
            render: ({values}, {handleSubmit, setupRef}) => {
              return (
                <PromotionForm
                  ref={(e) => setupRef(e)}
                  handleSubmit={handleSubmit}
                  promotion={values?.promotion}
                />
              );
            },
          },
          {
            id: 1,
            name: "Preview",
            description: "Confirm the details of the promotion",
            render: ({values}) => {
              const {promotion} = values;

              return (
                <>
                  <PromotionCard handleCard={() => this.wizardRef.setStep(0)} promotion={promotion} />
                </>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withRouter(CreatePromotionPage);

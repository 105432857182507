import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setEmployeeSettings} from "../../utils/payroll-helper";
import LoadingSpinner from "../../components/loading-spinner";
import {request} from "../../utils/request";
import FormStateSelect from "../../components/form-state-select";
import * as Yup from "yup";

class HubPayrollEmployeeModal extends Component {
  state = {
    payrollEmployee: null,
    fullName: "",
    emailSent: false,
  };

  open(checkEmployeeUniqueId) {
    this.setState({payrollEmployee: null, emailSent: false}, () => this.slide.open());

    request(`payroll/hub/employees/${checkEmployeeUniqueId}`).then((res) =>
      this.setState({payrollEmployee: res})
    );

    this.slide.open();
  }

  openHosted = () => {
    let {URL} = this.state.payrollEmployee;

    const handler = window.Check.create({
      link: URL,
    });

    handler.open();

    setTimeout(() => this.slide.close(), 3000);
  };

  handleSubmit = async ({firstName, lastName, email, line1, line2, city, state, zip, day, month, year}) => {
    const {UNIQUE_ID} = this.state.payrollEmployee;

    await request(`payroll/hub/employees/${UNIQUE_ID}`, "PATCH", {
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      EMAIL: email,
      ADDRESS_1: line1,
      ADDRESS_2: line2,
      CITY: city,
      STATE: state,
      ZIP: zip,
      DOB: `${year}-${month}-${day}`,
    });

    this.slide.close();
  };

  render() {
    let {payrollEmployee} = this.state;

    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      line1: Yup.string().required("Must Provide an Address"),
      city: Yup.string().required("Must Provide a City"),
      state: Yup.string().required("Must Provide a State"),
      zip: Yup.number().required("A Valid Zipcode is Required"),
    });

    let checkEmployee = this.state.payrollEmployee?.CHECK_EMPLOYEE;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`Payroll Settings`}
        buttonLabel="Save"
        buttonOnClick={() => this.formikRef.submitForm()}
      >
        {!payrollEmployee && <LoadingSpinner />}

        {payrollEmployee && (
          <div>
            <div className="flex flex-row mb-3">
              <button
                type="button"
                className="w-full inline-flex flex flex-col items-center px-2.5 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
                onClick={this.openHosted}
              >
                <div>
                  <div>Open Personal Information Popup</div>
                </div>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={{
                firstName: checkEmployee?.first_name,
                lastName: checkEmployee?.last_name,
                month: checkEmployee?.dob?.split("-")[1],
                day: checkEmployee?.dob?.split("-")[2],
                year: checkEmployee?.dob?.split("-")[0],
                line1: checkEmployee?.residence?.line1,
                line2: checkEmployee?.residence?.line2,
                city: checkEmployee?.residence?.city,
                state: checkEmployee?.residence?.state,
                zip: checkEmployee?.residence?.postal_code,
              }}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              innerRef={(e) => (this.formikRef = e)}
            >
              {(formikOptions) => {
                let {include} = formikOptions.values;

                return (
                  <div>
                    <FormInput name={"firstName"} label={"Legal First Name"} options={formikOptions} />

                    <FormInput name={"lastName"} label={"Legal Last Name"} options={formikOptions} />

                    <div className="flex flex-row">
                      <FormInput name={"month"} className="w-14" placeholder={"MM"} options={formikOptions} />

                      <FormInput
                        name={"day"}
                        className="w-14 ml-5"
                        placeholder={"DD"}
                        options={formikOptions}
                      />

                      <FormInput
                        name={"year"}
                        className="w-16 ml-5"
                        placeholder={"YYYY"}
                        options={formikOptions}
                      />
                    </div>

                    <FormInput name={"line1"} label={"Address Line 1"} options={formikOptions} />

                    <FormInput name={"line2"} label={"Address Line 2"} options={formikOptions} />

                    <div className="flex flex-row">
                      <FormInput name={"city"} label={"City"} options={formikOptions} />

                      <FormStateSelect
                        className="ml-5"
                        name="state"
                        label={"State"}
                        options={formikOptions}
                      />

                      <FormInput
                        className="ml-5 w-24"
                        name={"zip"}
                        label={"Zipcode"}
                        options={formikOptions}
                      />
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}
      </Modal>
    );
  }
}

export default HubPayrollEmployeeModal;

import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {Loading, PageHeadings, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import PaymentTypeDetailsModal from "../../../../modals/sales/payments/payment-type-details-modal";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";

class PaymentTypePage extends Component {
  state = {type: null};

  componentDidMount() {
    const {ID: id} = this.props.router.params;

    request("other/payments/" + id, "GET", null).then(async (type) => {
      this.setState({type: type, verbiages: type.VERBIAGES});
    });
  }

  async deletePaymentType() {
    const {type} = this.state;

    showLoadingConfirmAlert("Delete Payment Type", "Are you sure you want to delete this payment type?").then(
      async (close) => {
        await request("other/payments/" + type.ID, "DELETE", {});

        close();

        this.props.router.navigate("/flow/payments");
        this.modal.close();
      }
    );
  }

  render() {
    const {type} = this.state;

    if (type === null) {
      return <Loading />;
    }

    return (
      <div>
        <PaymentTypeDetailsModal
          ref={(e) => (this.paymentTypeDetailsModal = e)}
          updateState={(e) => this.setState(e)}
        />

        <PageHeadings
          label={type?.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Duplicate",
                      onClick: () => this.duplicateModal.open(),
                    },
                  ],
                },
                {items: [{label: "Archive", onClick: () => this.deletePaymentType()}]},
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Checkout Flow", url: "/flow/payments"},
            {label: "Payment Types Page", url: "/flow/payments"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.paymentTypeDetailsModal.open(type),
            },
          ]}
          label={"Payment Type Details"}
          description="Information about this payment type"
          data={[
            {label: "Name", value: type.NAME},
            {label: "Tax Disabled", value: type.TAX_DISABLED, type: "bool"},
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(PaymentTypePage));

import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import BlackoutDatesModal from "../../../modals/team/policies/blackout-dates-modal";

class BlackoutDatesPage extends Component {
  state = {dates: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let dates = await request("timeoff/blackout/", "GET");

    this.setState({dates});
  }

  updateDates(date, isDelete) {
    let {dates} = this.state;

    const idx = dates.findIndex((_date) => _date.ID === date.ID);

    if (idx !== -1) {
      if (isDelete) {
        dates.splice(idx, 1);
      } else {
        dates.splice(idx, 1, date);
      }
    } else {
      dates = [...dates, date];
    }

    this.setState({dates});
  }

  render() {
    const {dates} = this.state;

    if (dates === null) {
      return <Loading />;
    }

    return (
      <div>
        <BlackoutDatesModal
          updateDates={(date, isDelete = null) => this.updateDates(date, isDelete)}
          ref={(e) => (this.blackoutModal = e)}
        />

        <PageHeadings
          label="Blackout Dates"
          description="Create blackout dates for your shop when employees will not be allowed to request time off"
          buttons={[
            {
              label: "+ Create",
              onClick: () => this.blackoutModal.open(),
            },
          ]}
        />

        <Table
          pagination
          data={dates}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.blackoutModal.open(row),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
              minWidth: "10px",
            },
            {
              label: "Start Date",
              value: "DATE_START",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              label: "End Date",
              value: "DATE_END",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              label: "Date Created",
              value: "DATE_CREATED",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection([""])(withRouter(BlackoutDatesPage));

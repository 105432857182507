import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import moment from "moment-timezone";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {showConfirmAlert} from "../../../utils/alert-helper";

class ScheduleEmailModal extends Component {
  state = {campaign: null};

  open(campaign = null) {
    this.setState({campaign}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async scheduleEmailCampaign(values) {
    const {campaign} = this.state;
    const {date} = values;

    const serverCampaign = await request("marketing/campaigns/email/" + campaign.ID, "PATCH", {
      ...campaign,
      DATE_SCHEDULED: date,
    });

    this.props.updateState(serverCampaign);
    this.modal?.close();
  }

  render() {
    const {campaign} = this.state;

    const validationSchema = Yup.object({
      date: Yup.string()
        .required()
        .test("future-time", "Send Date cannot be in the past.", (value, _) => value > Date.now()),
    });

    const initialValues = {
      date:
        campaign?.DATE_SCHEDULED ??
        moment().add(1, "day").endOf("day").subtract(5, "hours").add(1, "minute").valueOf(),
    };

    return (
      <Modal
        buttonLabel="Schedule Email"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        label={"Schedule Email Campaign"}
      >
        <Formik
          onSubmit={(values) => {
            const confirmMessage =
              "Are you sure you would like to schedule this email campaign? An email will be sent to all patrons associated with this email campaign at the scheduled time.";

            showConfirmAlert("Schedule Email Campaign?", confirmMessage)
              .then(() => {
                this.scheduleEmailCampaign(values);
              })
              .catch(() => {
                this.modal.stopLoading();
              });
          }}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormDateTimeSelect
                  name="date"
                  label="Send Date"
                  options={formikOptions}
                  flex
                  buttonText={(epoch) => moment(epoch).format("dddd, MMMM Do, h:mm A")}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ScheduleEmailModal;

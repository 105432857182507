import React, {Component, Fragment} from "react";
import {MoreVertical} from "react-feather";
import {Menu, Transition} from "@headlessui/react";
import {CARD_TYPES} from "../../../utils/finance-constants";
import {ClipboardListIcon, LockClosedIcon, LockOpenIcon, XCircleIcon} from "@heroicons/react/solid";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import CancelCardModal from "../../../modals/banking/cancel-card-modal";

class CardBlock extends Component {
  state = {loading: false};

  activateCard = async () => {
    let {id} = this.props.card;
    this.setState({loading: true});
    await request(`banking2/cards/activate/${id}`, "POST");
    await this.props.handleUpdate();
    this.setState({loading: false});
  };

  deactivateCard = async () => {
    let {id} = this.props.card;
    this.setState({loading: true});

    await request(`banking2/cards/deactivate/${id}`, "POST");
    await this.props.handleUpdate();
    this.setState({loading: false});
  };

  cancelCard = async () => {
    let {id} = this.props.card;
    this.setState({loading: true});

    await request(`banking2/cards/cancel/${id}`, "POST");
    await this.props.handleUpdate();
    this.setState({loading: false});
  };

  renderCreditCard() {
    let {type} = this.props.card;
    let margin = 2.5;

    let typeStr, cardNumberStr, expStr, cvvStr;
    let {exp_month, exp_year} = this.props.card;

    if (type === CARD_TYPES.PHYSICAL) {
      typeStr = "Physical";
      let {last4} = this.props.card;

      cardNumberStr = `•••• •••• •••• ${last4}`;
      cvvStr = "•••";
    } else {
      let {number, cvc} = this.props.card;

      typeStr = "Virtual";
      cardNumberStr = [...number]
        .map((d, i) => (i % 4 === 0 ? " " + d : d))
        .join("")
        .trim();
      cvvStr = cvc;
    }

    expStr = `${exp_month}/${exp_year.toString().slice(-2)}`;

    return (
      <div className={"h-40 bg-zinc-50 rounded-md shadow-md border relative flex flex-col justify-center"}>
        <div className={`bg-no-repeat bg-contain h-12 w-16 bg-dripos-dark absolute top-2.5 left-2.5`} />

        <div className={`text-xs font-semibold absolute right-2.5 top-2.5`}>{typeStr}</div>

        <div className={`bg-no-repeat bg-contain h-7 w-12 bg-visa absolute bottom-0 right-2.5`} />

        {/*<div*/}
        {/*  className={`text-indigo-700 text-xs left-${margin} bottom-${margin} absolute`}*/}
        {/*>*/}
        {/*  $50.00 Spent*/}
        {/*</div>*/}

        <div className={`text-sm ml-${margin}`}>
          <div>{cardNumberStr}</div>
          <div className="flex flex-row mt-2">
            <div className="flex flex-col">
              <div className="text-xs text-gray-700 font-semibold">Expires</div>
              <div>{expStr}</div>
            </div>
            <div className="flex flex-col ml-2">
              <div className="text-xs text-gray-700 font-semibold">CVV</div>
              <div>{cvvStr}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOverflow() {
    let {status} = this.props.card;

    let isCanceled = status === "canceled";

    return (
      <Menu as="div" className="relative inline-block text-left ml-5 z-10">
        <div>
          <Menu.Button>
            <MoreVertical className="h-5 w-5" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="-top-1 transform -translate-y-full absolute right-0 w-40 origin-top-right bg-white divide-y divide-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="text-gray-700 text-sm cursor-pointer">
              {status === "active" && !isCanceled && (
                <Menu.Item onClick={() => this.deactivateCard()}>
                  <div className="p-1 pl-2 flex flex-row items-center hover:bg-zinc-100 p-1 pl-2">
                    <LockClosedIcon className="h-4 w-4 mr-1" />

                    <div>Lock</div>
                  </div>
                </Menu.Item>
              )}

              {status === "inactive" && !isCanceled && (
                <Menu.Item onClick={() => this.activateCard()}>
                  <div className="flex flex-row items-center hover:bg-zinc-100 p-1 pl-2">
                    <LockOpenIcon className="h-4 w-4 mr-1" />

                    <div>Unlock</div>
                  </div>
                </Menu.Item>
              )}

              <div className="flex flex-row items-center hover:bg-zinc-100 p-1 pl-2">
                <ClipboardListIcon className="h-4 w-4 mr-1" />
                <div>View Transactions</div>
              </div>

              {!isCanceled && (
                <Menu.Item onClick={() => this.cancelCardModal.open()}>
                  <div className="flex flex-row items-center hover:bg-zinc-100 p-1 pl-2">
                    <XCircleIcon className="h-4 w-4 mr-1" />
                    <div>Terminate</div>
                  </div>
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  render() {
    let {loading} = this.state;
    let {name, status} = this.props.card;

    let isInactive = status === "inactive";
    let isCanceled = status === "canceled";

    return (
      <div className="p-2">
        <CancelCardModal
          confirm={async () => {
            await this.cancelCard();
          }}
          ref={(e) => (this.cancelCardModal = e)}
        />

        <div className="w-72 flex flex-col m-6">
          {this.renderCreditCard()}

          <div className="mt-4 flex flex-row justify-between">
            <div className="flex flex-row text-sm items-center">
              <div>{`${name}`}</div>

              {loading && (
                <div className={"ml-4"}>
                  <LoadingSpinner size={15} />
                </div>
              )}

              {!loading && isInactive && (
                <div className="flex flex-row items-center">
                  <div className="mx-1">-</div>

                  <LockClosedIcon className="h-4 w-4 mr-1 text-red-700" />

                  <span className="text-red-700 font-semibold">Locked</span>
                </div>
              )}

              {!loading && isCanceled && (
                <div className="flex flex-row items-center">
                  <div className="mx-1">-</div>

                  <XCircleIcon className="h-4 w-4 mr-1 text-black" />

                  <span className="text-black font-semibold">Terminated</span>
                </div>
              )}
            </div>

            {this.renderOverflow()}
          </div>
        </div>
      </div>
    );
  }
}

export default CardBlock;

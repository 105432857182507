import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {Card, Loading, PageHeadings, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import MemoDetailsModal from "../../../modals/team/memo-details-modal";
import {withTranslation} from "react-i18next";
import {showSuccessAlert} from "../../../utils/alert-helper";

class MemoPage extends Component {
  state = {memo: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const memo = await request("memos/" + id, "GET");

    this.setState({
      memo,
    });
  }

  async sendMemo() {
    const {memo} = this.state;

    this.sendRef.startLoading();

    await request("memos/" + memo.UNIQUE_ID, "POST", {});

    this.setState({memo: {...memo, DATE_EXECUTED: Date.now()}}, () => {
      showSuccessAlert("Memo sent", "Memo has been sent");
    });
  }

  deleteMemo = async () => {
    const {memo} = this.state;

    await request("memos/" + memo.UNIQUE_ID, "DELETE", null);

    showSuccessAlert("Memo Deleted", `Memo with the subject of \"${memo.SUBJECT}\" has been deleted`);

    this.props.router.navigate("/memos");
  };

  render() {
    const {memo} = this.state;
    const sent = memo?.DATE_EXECUTED ? 1 : 0;

    if (memo === null) {
      return <Loading />;
    }

    const pageButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [{items: [{label: "Delete Memo", onClick: this.deleteMemo}]}],
      },
    ];

    if (memo.DATE_EXECUTED === null) {
      pageButtons.unshift({
        label: "Send Memo",
        ref: (e) => (this.sendRef = e),
        onClick: this.sendMemo.bind(this),
      });
    }

    return (
      <div>
        <MemoDetailsModal updateState={(memo) => this.setState({memo})} ref={(e) => (this.memoModal = e)} />

        <PageHeadings
          label={memo.SUBJECT}
          buttons={pageButtons}
          breadcrumbs={[
            {label: "Team", url: "/"},
            {label: "Memos Page", url: "/memos"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.memoModal.open(memo),
            },
          ]}
          label="Memo Information"
          description="Information about the contents of the memo"
          data={[
            {
              label: "Target Location",
              value: memo.LOCATION_NAME || "All Locations",
            },
            {label: "Reply email", value: memo.REPLY_TO},
            {label: "Sent", value: sent.toString(), type: "bool"},
            {label: "Subject", value: memo.SUBJECT},
            {label: "Content", value: memo.CONTENT, span: true},
          ]}
        />

        <Card
          label="Memo Recipients"
          description="Information about the recipients of this memo"
          tooltip={{
            data: "If this memo has not been sent, this table will show everyone who will receive the memo when it is sent. If the memo has been sent, it will show information about those who have received it.",
          }}
        >
          <Table
            pagination
            columns={[
              {
                value: "NAME",
                label: "Name",
                format: (value, row) => {
                  return (
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={getObjectImage(row, "LOGO", "appicon.png")}
                          alt=""
                        />
                      </div>

                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">{row.FULL_NAME}</div>

                        <div className="text-sm text-gray-500">{row.EMPLOYEE_ROLE_NAME}</div>
                      </div>
                    </div>
                  );
                },
              },
              {
                width: 1,
                value: "DATE_VIEWED",
                label: "Date Viewed",
                format: (value) => {
                  if (!value) {
                    return "Not Viewed";
                  }
                  return moment(value).format("MM/DD/YY");
                },
              },
            ]}
            placeholders={{noDataTitle: "No Participants"}}
            actionButtons={[
              {
                label: "View Employee",
                onClick: (row) => {
                  this.props.router.navigate(`/employee/${sent ? row.EMPLOYEE_ID : row.ID}`);
                },
              },
            ]}
            data={memo.DATE_EXECUTED ? memo.RECIPIENTS : memo.FUTURE_RECIPIENTS}
          />
        </Card>
      </div>
    );
  }
}

export default withTranslation()(withRouter(MemoPage));

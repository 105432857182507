import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import DeploymentGroupModal from "../../../../modals/admin/tools/deployment-group-modal";

class AppVersionsPage extends Component {
  state = {groups: null};

  componentDidMount() {
    request("readers/deployments/groups", "GET").then((groups) => {
      this.setState({groups});
    });
  }

  render() {
    const {groups} = this.state;

    if (groups === null) {
      return <Loading />;
    }

    return (
      <>
        <DeploymentGroupModal
          addState={(group) => this.setState({groups: [...groups, group]})}
          ref={(e) => (this.groupModal = e)}
        />

        <PageHeadings
          label="Groups"
          description="Create and manage companies"
          button={{
            label: "Add Group",
            onClick: () => this.groupModal.open(),
          }}
        />

        <Table
          pagination
          data={groups}
          className="mt-4"
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate("/admin/company/" + row.ID);
              },
            },
          ]}
          columns={[
            {
              value: "id",
              label: "ID",
            },
            {
              value: "name",
              label: "Name",
            },
            {
              value: "device_type",
              label: "Device",
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(AppVersionsPage);

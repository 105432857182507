import React, {Component} from "react";
import {request} from "../../../utils/request";
class WelcomeComponent extends Component {
  async handleClick() {
    await request("accounting/groups", "POST");

    return true;
  }
  render() {
    return (
      <div style={{height: 600}} className="py-20 flex flex-col justify-center  items-center">
        <div className="text-2xl font-semibold">Welcome to Dripos Accounting!</div>

        <div className="text-gray-700 font-semibold mt-2">Click Next to begin onboarding</div>
      </div>
    );
  }
}

export default WelcomeComponent;

import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {Loading, PageHeadings, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PaymentTypeDetailsModal from "../../../../modals/sales/prompts/prompt-details-modal";

const PLATFORM = {
  KIOSK_ENABLED: "Kiosk",
  MOBILE_ENABLED: "Mobile",
  POS_ENABLED: "Point of Sale",
  WEB_ENABLED: "Web",
};

class PromptPage extends Component {
  state = {
    prompt: null,
    options: null,
    platform: null,
    type: null,
    serverTypes: [],
  };

  componentDidMount() {
    const {ID: id} = this.props.router.params;

    request("types/order", "GET").then((serverTypes) => {
      this.setState({serverTypes});
    });

    request("prompts/" + id, "GET", null).then(async (data) => {
      this.populateState(data);
    });
  }

  populateState(data) {
    this.setState({
      platform: data.PLATFORM_TOGGLE,
      options: data.PROMPT_OPTIONS,
      type: data.TICKET_TYPE_TOGGLES,
      prompt: data.PROMPT,
    });
  }

  async deletePrompt() {
    const {prompt} = this.state;

    await request("prompts/archive/" + prompt.ID, "POST");

    this.props.router.navigate("/prompts");
  }

  render() {
    const {prompt, platform, options, serverTypes, type} = this.state;

    if (prompt === null) {
      return <Loading />;
    }

    const typeDict = parseIdDict(serverTypes);

    const required = prompt.REQUIRED ? "Yes" : "No";

    return (
      <div>
        <PaymentTypeDetailsModal
          ref={(e) => (this.paymentModal = e)}
          updateState={(state) => this.populateState(state)}
        />

        <PageHeadings
          label={prompt?.FULL_NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Prompt",
                      onClick: () => this.deletePrompt(),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Checkout Flow", url: "/prompts"},
            {label: "Prompts Page", url: "/prompts"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.paymentModal.open(this.state),
            },
          ]}
          label={"Prompt Details"}
          description="Information about this checkout prompt"
          data={[
            {label: "Full Name", value: prompt.FULL_NAME},
            {label: "Short Name", value: prompt.SHORT_NAME},
            {
              label: "Platforms",
              value: platform
                ? Object.keys(PLATFORM)
                    .filter((item) => platform[item])
                    .map((item) => {
                      return PLATFORM[item];
                    })
                    .join(", ")
                : "",
            },
            {
              label: "Ticket Types",
              value: type
                .map((item) => {
                  return typeDict[item.TICKET_TYPE_ID]?.NAME;
                })
                .join(", "),
            },
            {label: "Prompt Questions", value: options[0]?.OPTION_TEXT},
            {label: "Required", value: required},
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(PromptPage));

import {FormBoolean, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {REPORTING_DATING_LABELS} from "../../../utils/settings-constants";

class ReportTabModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {pinProtect, reportingMode, reportingDating} = values;

    let payload = {
      PIN_PROTECT_REPORTS_POS: pinProtect,
      REPORTING_MODE: reportingMode,
      REPORTING_DATING: reportingDating,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {PIN_PROTECT_REPORTS_POS, REPORTING_MODE, REPORTING_DATING} = this.props.shop.settings;

    return (
      <Modal
        label="Report Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.handleSubmit}
          initialValues={{
            pinProtect: PIN_PROTECT_REPORTS_POS,
            reportingMode: REPORTING_MODE,
            reportingDating: REPORTING_DATING,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            let {reportingMode} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="pinProtect"
                  label="Pin Protect Reports on Point of Sale"
                  options={formikOptions}
                />

                <FormSelect
                  name="reportingMode"
                  label="Reporting Mode"
                  data={[
                    {
                      label: "Legacy",
                      value: "LEGACY",
                    },
                    {
                      label: "Audit",
                      value: "AUDIT",
                    },
                  ]}
                  options={formikOptions}
                />

                {reportingMode === "AUDIT" && (
                  <FormSelect
                    name="reportingDating"
                    label={"Report Ticket"}
                    data={Object.keys(REPORTING_DATING_LABELS).map((key) => ({
                      label: REPORTING_DATING_LABELS[key],
                      value: key,
                    }))}
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(ReportTabModal);

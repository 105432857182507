import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Formik} from "formik";
import {Modal, FormInput, FormSelect, FormBoolean} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";

class BreaksSettingsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {showBreaksOutOfCompliance, flagBreakBefore, flagBreakAfter} = values;

    let payload = {
      SHOW_BREAKS_OUT_OF_COMPLIANCE: showBreaksOutOfCompliance,
      FLAG_BREAK_BEFORE: flagBreakBefore ?? "",
      FLAG_BREAK_AFTER: flagBreakAfter ?? "",
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {SHOW_BREAKS_OUT_OF_COMPLIANCE, FLAG_BREAK_BEFORE, FLAG_BREAK_AFTER} = this.props.shop.settings;

    return (
      <Modal
        label="Breaks Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            showBreaksOutOfCompliance: SHOW_BREAKS_OUT_OF_COMPLIANCE,
            flagBreakBefore: FLAG_BREAK_BEFORE,
            flagBreakAfter: FLAG_BREAK_AFTER,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="showBreaksOutOfCompliance"
                  label={"Mark Time Cards as Out of Compliance"}
                  tooltip={
                    "Checks breaks taken using your state's break laws, and flags the time card if out of compliance"
                  }
                  options={formikOptions}
                />

                <FormInput
                  name="flagBreakBefore"
                  label={"Flag Time Card if Break is Under (in Minutes)"}
                  tooltip={
                    "Flag time card if break is short by a certain amount of minutes, leave empty to disable"
                  }
                  options={formikOptions}
                />

                <FormInput
                  name="flagBreakAfter"
                  label={"Flag Time Card if Break is Over (in Minutes)"}
                  tooltip={
                    "Flag time card if break is over by a certain amount of minutes, leave empty to disable"
                  }
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(BreaksSettingsModal);

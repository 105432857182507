import React, {Component} from "react";
import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";

class LocationFinanceModal extends Component {
  state = {location: null, settings: null};

  open(location, settings = null) {
    this.setState({location, settings}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  saveLocation = async ({payroll, cashAccount}) => {
    const {location} = this.state;

    await Promise.all([
      request(`admin/dashboard/location/${location.ID}/partner-settings`, "PUT", {
        KEY: "PAYROLL_WHITELISTED",
        VALUE: payroll,
      }),
      request(`admin/dashboard/location/${location.ID}/partner-settings`, "PUT", {
        KEY: "CASH_ACCOUNT_WHITELISTED",
        VALUE: cashAccount,
      }),
    ]);

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    const {settings} = this.state;

    return (
      <Modal
        buttonLabel={"Save"}
        label={"Edit Location Payroll Settings"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.saveLocation}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({})}
          initialValues={{
            payroll: settings?.PAYROLL_WHITELISTED ?? "0",
            cashAccount: settings?.CASH_ACCOUNT_WHITELISTED ?? "0",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean label={"Payroll Whitelisted"} name={"payroll"} options={formikOptions} />

                <FormBoolean
                  label={"Cash Account Whitelisted"}
                  name={"cashAccount"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(LocationFinanceModal);

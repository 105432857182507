import React, {Component} from "react";
import {Modal, FormBoolean, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {showErrorNotification} from "../../../utils/notification-helper";

class TerminateEmployeeModal extends Component {
  state = {employee: null};

  open(employee = null) {
    this.setState({employee}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async terminateEmployee({notes, voluntary}) {
    const {employee} = this.state;

    try {
      await request("termination/employee/" + employee.ID, "POST", {
        VOLUNTARY: voluntary === "1",
        NOTES: notes,
      });
    } catch (err) {
      showErrorNotification(
        "Terminating Employee Failed.",
        "Please try again or contact Dripos support for assistance."
      );

      this.modal.close();
    }

    this.props.updateState();
    this.modal.close();
  }

  render() {
    return (
      <Modal
        label="Terminate Employee"
        buttonLabel="Terminate"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.terminateEmployee.bind(this)}
          validationSchema={Yup.object().shape({
            notes: Yup.string().required("Termination notes are required"),
          })}
          initialValues={{
            notes: "",
            voluntary: "1",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean options={formikOptions} name="voluntary" label="Voluntary Departure" />

                <FormTextArea
                  name="notes"
                  options={formikOptions}
                  label="Termination Notes"
                  placeholder="Notes about why the employee was terminated."
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

TerminateEmployeeModal.propTypes = {
  onSave: PropTypes.func,
};

export default TerminateEmployeeModal;

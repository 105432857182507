import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import MultiSelectCombobox from "../../components/multi-select-combobox";

class ProductDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {exclude = [], unique, multi} = this.props;

    const serverProducts = await request("products/soft", "GET");

    if (multi) {
      const data = Object.values(
        serverProducts.reduce((dict, item) => {
          if (!dict[item.CATEGORY_ID]) {
            dict[item.CATEGORY_ID] = [item];
          } else {
            dict[item.CATEGORY_ID].push(item);
          }

          return dict;
        }, {})
      ).map((item) => {
        return {
          title: "Select all " + item[0].CATEGORY_NAME,
          data: item
            .filter((item) => !exclude.includes(item.ID))
            .map((item) => {
              return {
                id: item.ID,
                label: item.NAME,
                unique: item.UNIQUE_ID,
              };
            }),
        };
      });

      this.setState({data});
    } else {
      const data = serverProducts
        .filter((item) => !exclude.includes(item.ID))
        .map((item) => {
          return {
            id: unique ? item.UNIQUE_ID : item.ID,
            label: item.NAME,
          };
        });

      this.setState({data, raw: serverProducts});
    }
  }

  fetchProduct(useRaw) {
    let {data, raw} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    if (useRaw) {
      return raw.find((item) => item.ID === parseInt(value));
    }

    return data.find((item) => item.id === parseInt(value));
  }

  render() {
    const {data} = this.state;
    const {multi} = this.props;

    if (multi) {
      return <MultiSelectCombobox data={data} headerIcon={"fa-solid fa-layer-group"} {...this.props} />;
    }

    return <ComboBox label="Product" {...this.props} data={data} />;
  }
}

export default ProductDropdown;

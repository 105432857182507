import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../utils/navigation";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {TIME_PERIODS} from "../../features/accounting/timespan-dropdown";
import ProfitLossBlock from "../../features/accounting/main-blocks/profit-loss-block";
import {request} from "../../utils/request";
import LoadingSpinner from "../../components/loading-spinner";

class AccountingDashboardPage extends Component {
  state = {invoiceData: null, billData: null, bankConnections: null};
  async componentDidMount() {
    this.syncState();
  }

  syncState = async () => {
    const {INVOICE_DATA, BILL_DATA, BANK_CONNECTIONS} = await request("accounting/reports/dashboard", "GET");

    this.setState({
      invoiceData: INVOICE_DATA,
      billData: BILL_DATA,
      bankConnections: BANK_CONNECTIONS,
    });
  };

  renderBankAccountCard() {
    let {bankConnections} = this.state;
    const acc = [
      // {
      //   name: "Chase (2433)",
      //   lastUpdated: "20 hours ago",
      //   balance: 96969,
      //   toReview: 4,
      // },
      // {
      //   name: "SoFi Checking (4244)",
      //   lastUpdated: "19 hours ago",
      //   balance: 69696,
      //   toReview: 0,
      // },
    ];

    // bankConnections = [];

    return (
      <div className={"py-5 px-2 bg-white shadow h-full flex flex-col justify-between"}>
        <div>
          <div className={"px-3 font-semibold pb-4"}>Bank Connections</div>

          <div>
            {!bankConnections.length && (
              <div className={"mt-10 text-lg px-3 text-center"}>
                <div className={"font-semibold"}>No Bank Connections</div>
                <div
                  className={"text-sm font-semibold text-indigo-500 cursor-pointer"}
                  onClick={() => this.props.router.navigate("/accounting/bank-connections")}
                >
                  Create Connections
                </div>
              </div>
            )}

            {bankConnections.length > 0 &&
              bankConnections.map(
                ({NAME, LAST_UPDATED, BANK_BALANCE, BOOK_BALANCE, UNREVIEWED_TRANSACTION_COUNT}) => (
                  <div
                    className={"cursor-pointer hover:bg-gray-100 space-y-0.5 px-3"}
                    onClick={() => this.props.router.navigate("/accounting/transactions")}
                  >
                    <div className={"border-b py-2"}>
                      <div className={"flex flex-row justify-between text-sm font-semibold"}>
                        <div>{NAME}</div>

                        {UNREVIEWED_TRANSACTION_COUNT > 0 && (
                          <div
                            className={"font-semibold text-indigo-500 cursor-pointer "}
                            onClick={() => this.props.router.navigate("transactions")}
                          >
                            {UNREVIEWED_TRANSACTION_COUNT} to review
                          </div>
                        )}

                        {!UNREVIEWED_TRANSACTION_COUNT && (
                          <div>
                            <FontAwesomeIcon
                              icon="check"
                              className={
                                "mr-2 text-xs rounded-full bg-green-500 p-1 text-white h-2 w-2 font-medium"
                              }
                            />
                            Reviewed
                          </div>
                        )}
                      </div>

                      {/*<div className={"text-xs text-gray-500"}>*/}
                      {/*  <i>Updated {lastUpdated}</i>*/}
                      {/*</div>*/}

                      <div>
                        <div className={"flex flex-row justify-between text-sm"}>
                          <div>Bank balance</div>
                          <div>{toDollars(BANK_BALANCE, true)}</div>
                        </div>

                        <div className={"flex flex-row justify-between text-sm"}>
                          <div>In Dripos</div>
                          <div>{toDollars(BOOK_BALANCE, true)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>

        <div
          className={"px-3 mt-3 text-indigo-500 font-semibold text-sm cursor-pointer"}
          onClick={() => this.props.router.navigate("bank-connections")}
        >
          Manage Accounts
        </div>
      </div>
    );
  }

  renderProfitLossCard() {
    return <ProfitLossBlock />;
  }

  renderExpensesCard() {
    return <div></div>; //<div className={"p-5 bg-white shadow font-semibold"}>Expenses</div>;
  }

  getOustandingStr(sectionName, count) {
    if (count > 1) {
      return `View ${count} oustanding ${sectionName}s`;
    } else if (count === 1) {
      return `View 1 outstanding ${sectionName}`;
    }

    return `No Outstanding ${sectionName}s`;
  }

  renderBills() {
    return this.renderTracking("bill", this.state.billData, "/accounting/bills");
  }

  renderTracking(sectionName, {CURRENT_TOTAL, OVERDUE_TOTAL, COUNT}) {
    const {accountingGroup} = this.props.shop;
    const title = `${sectionName.charAt(0).toUpperCase() + sectionName.slice(1)}s ${
      accountingGroup.TYPE === "CASH" ? "Tracked" : ""
    }`;

    return (
      <div className={"p-5 bg-white shadow space-y-3"}>
        <div className={"font-semibold"}>{title}</div>

        <div className={"text-sm pt-4 space-y-3 font-medium"}>
          <div className={"flex flex-row justify-between "}>
            <div>Current</div>
            <div className={""}>{toDollars(CURRENT_TOTAL, true)}</div>
          </div>

          <div className={"flex flex-row justify-between text-red-700"}>
            <div>Past Due</div>
            <div>{toDollars(OVERDUE_TOTAL, true)}</div>
          </div>

          <div className={"flex flex-row justify-between text-black"}>
            <div>Total Outstanding</div>
            <div className={""}>{toDollars(0, true)}</div>
          </div>
        </div>

        <div
          className={"pt-4 text-sm text-indigo-500 cursor-pointer font-semibold"}
          onClick={() => this.props.router.navigate("/accounting/bills")}
        >
          {this.getOustandingStr(sectionName, COUNT)}
        </div>
      </div>
    );
  }

  render() {
    const {invoiceData} = this.state;

    return (
      <div>
        <PageHeadings
          label={"Accounting Overview"}
          breadcrumbs={[{label: "Accounting", url: "/accounting"}]}
        />

        {!invoiceData && <LoadingSpinner />}

        {invoiceData && (
          <div className={"grid grid-rows-2 grid-cols-3 gap-x-3 gap-y-3 mt-5 h-full"}>
            <div className={"col-span-2"}>{this.renderProfitLossCard()}</div>
            <div className={"col-span-1 row-span-2"}>{this.renderBankAccountCard()}</div>

            <div className={"col-span-1"}>{this.renderTracking("invoice", invoiceData, "/invoicing")}</div>

            <div className={"col-span-1"}>{this.renderBills()}</div>
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(AccountingDashboardPage));

import React, {Component} from "react";
import {FormBoolean, FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import FormRow from "../../../components/form-row";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {PROMOTION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/src/helpers/promotion-helper";
import FormProductComboSelect from "../../../components/form-product-combo-select";
import FormCategoryComboSelect from "../../../components/form-category-combo-select";
import * as Yup from "yup";
import DaysOfWeekSelect from "../../../components/form-elements/days-of-week-select";
import FormFromToTime from "../../../components/form-from-to-time";

class PromotionForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({
    name,
    start,
    end,
    products,
    categories,
    percentage,
    flat,
    oncePer,
    company,
    discount,
    type,
    days,
    durationType,
    recurringDays,
    recurringStartDate,
    recurringEndDate,
    recurringPeriod,
    from,
    to,
  }) {
    return {
      promotion: {
        NAME: name,
        DATE_END:
          durationType !== "recurring" ? end : recurringPeriod === "date_range" ? recurringEndDate : null,
        DATE_START:
          durationType !== "recurring"
            ? start
            : recurringPeriod === "date_range"
            ? recurringStartDate
            : Date.now(),
        DAYS_VALID: days,
        PRODUCTS: products,
        CATEGORIES: categories,
        ONCE_PER_ORDER: oncePer === "1",
        TYPE: type,
        DISCOUNT: {
          PERCENTAGE: discount === "percentage" ? percentage : null,
          FLAT_FEE: discount === "flat" ? decimalToDollars(flat) : null,
        },
        IS_COMPANY_WIDE: company === "1",
        RECURRING_DAYS: durationType === "recurring" ? recurringDays : null,
        RECURRING_START_TIME: durationType === "recurring" ? from : null,
        RECURRING_END_TIME: durationType === "recurring" ? to : null,
        IS_RECURRING: durationType === "recurring",
        DURATION_TYPE: durationType,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      if (this.props.noSubmit) return true;

      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  renderType(options) {
    const {values} = options;

    if (values.type === PROMOTION_TYPES.PRODUCT) {
      return (
        <FormProductComboSelect
          label="Products"
          name="products"
          options={options}
          ref={(e) => (this.productRef = e)}
          tooltip={{
            data: [
              {
                label: "Products",
                data: "Select which products this promotion should apply to.",
              },
              {
                label: "Trash Button",
                data: "The trash icon will clear all of your currently selected products or categories so you don't have to un-click each of them.",
              },
            ],
          }}
          multi
          company={values.company === "1"}
          flex
        />
      );
    }

    if (values.type === PROMOTION_TYPES.CATEGORY) {
      return (
        <FormCategoryComboSelect
          label="Categories"
          name="categories"
          options={options}
          ref={(e) => (this.categoryRef = e)}
          tooltip={{
            data: [
              {
                label: "Categories",
                data: "Select which categories of products this promotion should apply to.",
              },
              {
                label: "Trash Button",
                data: "The trash icon will clear all of your currently selected products or categories so you don't have to un-click each of them.",
              },
            ],
          }}
          company={values.company === "1"}
          flex
          multi
        />
      );
    }
  }

  renderDiscount(options) {
    const {values} = options;

    if (values.discount === "flat") {
      return <FormInput label="Flat Amount" name="flat" options={options} flex />;
    }

    if (values.discount === "percentage") {
      return <FormInput label="Discount Percentage" name="percentage" options={options} flex />;
    }
  }

  renderRecurringPromotion(options) {
    const {values, setFieldValue} = options;

    const recurringPeriodOptions = [
      {value: "forever", label: "Forever"},
      {value: "date_range", label: "Date Range"},
    ];

    const recurringPeriodTooltip = {
      data: [
        {
          label: "Recurring Period",
          data: "The recurring period determines how long the promotion will run.",
        },
        {
          label: "Forever",
          data: "If you select forever, the promotion will run indefinitely.",
        },
        {
          label: "Date Range",
          data: "If you select date range, the promotion will run between the start and end dates.",
        },
      ],
    };

    const fromToTimeTooltip = {
      label: "Time Entry",
      data: "Separate times with a -",
    };

    return (
      <>
        <FormSelect
          options={options}
          label="Recurring Period"
          name="recurringPeriod"
          tooltip={recurringPeriodTooltip}
          data={recurringPeriodOptions}
        />

        {values.recurringPeriod === "date_range" && (
          <FormRow className="mb-4">
            <FormDateTimeSelect
              label="Recurring Start Date"
              options={options}
              name="recurringStartDate"
              flex
              hideTime
            />

            <FormDateTimeSelect
              label="Recurring End Date"
              options={options}
              name="recurringEndDate"
              flex
              hideTime
            />
          </FormRow>
        )}

        <DaysOfWeekSelect
          name="recurringDays"
          label="Recurring Days"
          value={values.recurringDays}
          onChange={(newValue) => setFieldValue("recurringDays", newValue)}
          options={options}
        />
        <FormRow>
          <FormFromToTime label="Time" name="time" options={options} flex tooltip={fromToTimeTooltip} />
        </FormRow>
      </>
    );
  }

  renderWithFormik() {
    const {handleSubmit, promotion} = this.props;

    let defaultDiscount = "flat";
    if (promotion?.DISCOUNT?.PERCENTAGE) {
      defaultDiscount = "percentage";
    }

    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      durationType: Yup.string().nullable().required("Duration is required"),
      flat: Yup.number()
        .nullable()
        .test(
          "flat-req",
          "Flat Amount is required.",
          (value, ctx) =>
            ctx.parent.discount !== "flat" || (value !== null && value !== undefined && value !== "")
        ),
      percentage: Yup.number()
        .nullable()
        .test(
          "pct-req",
          "Percentage is required.",
          (value, ctx) =>
            ctx.parent.discount !== "percentage" || (value !== null && value !== undefined && value !== "")
        ),
      products: Yup.array()
        .nullable()
        .test(
          "product-req",
          "You must select at least one product.",
          (value, ctx) =>
            ctx.parent.type !== PROMOTION_TYPES.PRODUCT ||
            (value !== null && value !== undefined && value.length > 0)
        ),
      categories: Yup.array()
        .nullable()
        .test(
          "cat-req",
          "You must select at least one category.",
          (value, ctx) =>
            ctx.parent.type !== PROMOTION_TYPES.CATEGORY ||
            (value !== null && value !== undefined && value.length > 0)
        ),
      recurringDays: Yup.array()
        .nullable()
        .test(
          "recurring-days-req",
          "You must select at least one day for recurring promotions.",
          (value, ctx) =>
            ctx.parent.durationType !== "recurring" ||
            (value !== null && value !== undefined && value.length > 0)
        ),
      from: Yup.string()
        .nullable()
        .test(
          "recurring-start-time-req",
          "Start time is required for recurring promotions.",
          (value, ctx) =>
            ctx.parent.durationType !== "recurring" || (value !== null && value !== undefined && value !== "")
        ),
      to: Yup.string()
        .nullable()
        .test(
          "recurring-end-time-req",
          "End time is required for recurring promotions.",
          (value, ctx) =>
            ctx.parent.durationType !== "recurring" || (value !== null && value !== undefined && value !== "")
        ),
      recurringStartDate: Yup.number()
        .nullable()
        .test(
          "recurring-start-date-req",
          "Start date is required for date range recurring promotions.",
          (value, ctx) =>
            ctx.parent.durationType !== "recurring" ||
            ctx.parent.recurringPeriod !== "date_range" ||
            (value !== null && value !== undefined)
        ),
      recurringEndDate: Yup.number()
        .nullable()
        .test(
          "recurring-end-date-req",
          "End date is required for date range recurring promotions.",
          (value, ctx) =>
            ctx.parent.durationType !== "recurring" ||
            ctx.parent.recurringPeriod !== "date_range" ||
            (value !== null && value !== undefined)
        ),
    });

    const initialValues = {
      name: promotion?.NAME,
      company: promotion?.IS_COMPANY_WIDE ? "1" : "0",
      durationType: promotion?.DURATION_TYPE || "forever",
      start: promotion?.DATE_START ?? promotion?.RECURRING_START_TIME,
      end: promotion?.DATE_END ?? promotion?.RECURRING_END_TIME,
      days: promotion?.DAYS_VALID,
      oncePer: promotion?.ONCE_PER_ORDER ? "1" : "0",
      products: promotion?.PRODUCTS?.map((_promotion) => _promotion?.ID ?? _promotion) ?? [],
      categories: promotion?.CATEGORIES?.map((_promotion) => _promotion?.ID ?? _promotion) ?? [],
      discount: defaultDiscount,
      type: promotion?.TYPE ?? PROMOTION_TYPES.TICKET,
      flat: toDollars(promotion?.DISCOUNT?.FLAT_FEE || 0),
      percentage: promotion?.DISCOUNT?.PERCENTAGE,
      recurringDays: promotion?.RECURRING_DAYS || [],
      recurringStartTime: promotion?.RECURRING_START_TIME || "",
      recurringEndTime: promotion?.RECURRING_END_TIME || "",
      recurringPeriod: promotion?.RECURRING_PERIOD || "forever",
      recurringStartDate: promotion?.DATE_START || null,
      recurringEndDate: promotion?.DATE_END || null,
      from: promotion?.RECURRING_START_TIME || null,
      to: promotion?.RECURRING_END_TIME || null,
      isRecurring: promotion?.IS_RECURRING || false,
    };

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return <form onSubmit={handleSubmit}>{this.renderForm(formikOptions)}</form>;
        }}
      </Formik>
    );
  }

  renderWithoutFormik() {
    const {options: formikOptions} = this.props;

    return this.renderForm(formikOptions);
  }

  renderForm(formikOptions) {
    const {fromTextCampaign, hideRange} = this.props;
    const {values} = formikOptions;

    const companyWidePromotionTooltip = {
      label: "Company-wide Promotions",
      data: "With company-wide promotions, you can create a single promotion that can be used at any of your shops.",
    };

    const durationTooltip = {
      data: [
        {
          label: "Promotion Duration",
          data: "The promotion duration defines how long the promotion or coupon is valid for.",
        },
        {
          label: "Recurring",
          data: "You can set up a recurring promotion that runs on specific days of the week and during specific hours.",
        },
        {
          label: "Date Range",
          data: "You may define a start and/or end time for the promotion or coupon. If set, the promotion or coupon will only be valid in between the start and end dates.",
        },
      ],
    };

    const durationOptions = [
      {value: "forever", label: "Forever"},
      {value: "recurring", label: "Recurring"},
    ];

    const promotionTypeTooltip = {
      data: [
        {
          label: "Promotion Type",
          data: "There are three types that your promotion or coupon could have: Full Order, Product or Category.",
        },
        {
          label: "Full Order",
          data: 'If your promotion or coupon has type "Full Order" then the discount will be applied to the whole order.',
        },
        {
          label: "Product",
          data: 'If your promotion or coupon has type "Product" then the discount will only be applied to the selected products.',
        },
        {
          label: "Category",
          data: 'If your promotion or coupon has type "Category" then the discount will only be applied to products in the selected categories.',
        },
      ],
    };

    const promotionTypeOptions = [
      {
        label: "Full Order",
        value: PROMOTION_TYPES.TICKET,
      },
      {
        label: "Product",
        value: PROMOTION_TYPES.PRODUCT,
      },
      {
        label: "Category",
        value: PROMOTION_TYPES.CATEGORY,
      },
    ];

    const oncePerOrderTooltip = {
      label: "Apply Promotion once per Order",
      data: 'If this is enabled, the promotion will only apply to one product per order. This option is only available for the "Product" and "Category" promotion types.',
    };

    const discountTypeTooltip = {
      data: [
        {
          label: "Discount Type",
          data: "The discount type determines how the products or full order is discounted. There are two options: Flat Rate and Percentage.",
        },
        {
          label: "Flat Rate",
          data: "If you select flat rate, then the discount will be in the form of a dollar amount (e.g. $5 off the order).",
        },
        {
          label: "Percentage",
          data: "If you select percentage, then the discount will be in the form of a percentage amount (e.g. 20% off coffees).",
        },
      ],
    };

    const discountTypeOptions = [
      {label: "Flat Rate", value: "flat"},
      {label: "Percentage", value: "percentage"},
    ];

    if (!hideRange) {
      durationOptions.push({value: "range", label: "Date Range"});
    }

    if (fromTextCampaign) {
      durationOptions.push({value: "days", label: "Days After Creation"});

      durationTooltip.data.push({
        label: "Days After Creation",
        data: "You may define a set number of days that this coupon is valid for. If you are sending the coupon via text campaign, the coupon will only be valid for this many days after the coupon is sent.",
      });
    }

    return (
      <>
        <FormRow>
          <FormInput
            options={formikOptions}
            label="Name"
            name="name"
            flex
            disabled={!!this.props.disableName}
          />

          <FormBoolean
            name="company"
            label="Company-wide Promotion"
            options={formikOptions}
            hint="Beta"
            tooltip={companyWidePromotionTooltip}
            flex
          />
        </FormRow>

        <FormSelect
          options={formikOptions}
          label="Promotion Duration"
          name="durationType"
          tooltip={durationTooltip}
          data={durationOptions}
        />

        {values.durationType === "range" && (
          <FormRow>
            <FormDateTimeSelect label="Promotion Start" options={formikOptions} name="start" flex />

            <FormDateTimeSelect label="Promotion End" options={formikOptions} name="end" flex />
          </FormRow>
        )}

        {values.durationType === "recurring" && this.renderRecurringPromotion(formikOptions)}

        {values.durationType === "days" && !!this.props.fromTextCampaign && (
          <FormInput label="Expire Coupon After" name="days" options={formikOptions} trailingIcon={"days"} />
        )}

        <FormRow>
          <FormSelect
            flex
            options={formikOptions}
            label="Promotion Type"
            name="type"
            tooltip={promotionTypeTooltip}
            data={promotionTypeOptions}
          />

          {(values.type === PROMOTION_TYPES.PRODUCT || values.type === PROMOTION_TYPES.CATEGORY) && (
            <FormBoolean
              name="oncePer"
              label="Once per Order"
              options={formikOptions}
              tooltip={oncePerOrderTooltip}
              flex
            />
          )}

          {this.renderType(formikOptions)}
        </FormRow>

        <FormRow>
          <FormSelect
            options={formikOptions}
            label="Discount Type"
            name="discount"
            tooltip={discountTypeTooltip}
            data={discountTypeOptions}
            flex
          />

          {this.renderDiscount(formikOptions)}
        </FormRow>
      </>
    );
  }

  render() {
    const {options} = this.props;

    if (options) {
      return this.renderWithoutFormik();
    }

    return this.renderWithFormik();
  }
}

PromotionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  promotion: PropTypes.object.isRequired,
};

export default PromotionForm;

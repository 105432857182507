import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import PayrollTimeOffTable from "./payroll-time-off-table";
import PayrollRunTableFilter from "../payroll-run-table-filter";
import {
  fetchEmployeeRequestedHours,
  fetchPayrollTabs,
  fetchPayrollTimeOffAmount,
  fetchPayrollTotalPTOAmount,
  showPayrollError,
} from "../../../../utils/payroll-helper";
import Fuse from "fuse.js";
import CardAlert from "../../../card-alert";
import {parseIdDict, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {request} from "../../../../utils/request";
import {CHECK_POLICY_TYPE} from "../../../../modals/payroll/earnings/extra-pay-modal";

class PayrollTimeOffComponent extends Component {
  state = {
    breakdown: [],
    search: "",
    selectedLocations: "all",
    selectedEmployees: "with-pay",
    tabs: [],
  };

  componentDidMount() {
    this.getTimeOffBreakdown();
  }

  componentDidUpdate(prevProps, prevState) {
    const {search, selectedLocations, selectedEmployees} = this.state;

    if (
      prevState.search !== search ||
      prevState.selectedLocations !== selectedLocations ||
      prevState.selectedEmployees !== selectedEmployees
    ) {
      this.getTimeOffBreakdown();
    }
  }

  getTimeOffBreakdown() {
    const {payrollDraft = {}} = this.props.navState?.metadata;

    const {
      SALARIED_EMPLOYEES_BREAKDOWN,
      EMPLOYEE_TIMESHEET_BREAKDOWN,
      OTHER_EMPLOYEE_BREAKDOWN,
      DBA_LOCATIONS,
    } = payrollDraft;

    let combinedBreakdown = [
      ...SALARIED_EMPLOYEES_BREAKDOWN,
      ...EMPLOYEE_TIMESHEET_BREAKDOWN,
      ...OTHER_EMPLOYEE_BREAKDOWN,
    ];

    const filteredBreakdown = this.getFilteredBreakdown(combinedBreakdown);

    const tabs = fetchPayrollTabs(DBA_LOCATIONS);

    this.setState({breakdown: filteredBreakdown, tabs});
  }

  handleClick = async () => {
    const canContinue = await this.checkDefaultRates();

    if (!canContinue) {
      return false;
    }

    return await this.submitPTOStep();
  };

  async checkDefaultRates() {
    const {breakdown} = this.state;

    for (const empRow of breakdown) {
      const totalRequestedHours = fetchEmployeeRequestedHours(empRow);
      const hasDefaultRate = empRow.DEFAULT_RATE;

      if (!hasDefaultRate && totalRequestedHours > 0) {
        return showPayrollError(
          "PTO With No Pay Rate Detected",
          "One or more employees require a time off pay rate before proceeding. Please find and fix the entries marked in red by selecting a default rate to use for PTO.",
          false
        );
      }
    }

    return true;
  }

  async submitPTOStep() {
    const {breakdown} = this.state;
    const {metadata} = this.props.navState;
    const {CHECK_PAYROLL_ID: checkPayrollId} = this.props.navState?.metadata?.payrollDraft;

    if (!breakdown) {
      showErrorAlert(
        "Time Off Submission Error",
        "Please contact support at (781)-583-3699 for more information."
      );

      return false;
    }

    try {
      await request("payroll/run-payroll/pto", "POST", {
        CHECK_PAYROLL_ID: checkPayrollId,
        PTO_BREAKDOWN: breakdown,
      });
    } catch (err) {
      showErrorAlert(
        "Time Off Submission Error",
        "Please contact support at (781)-583-3699 for more information."
      );

      return false;
    }

    this.props.updateNavState({
      metadata: {...metadata, ptoBreakdown: breakdown},
    });

    return true;
  }

  getFilteredBreakdown(breakdown) {
    const {payrollDraft, hideEmployeeSelector = false} = this.props.navState?.metadata;
    let {breakdown: existingBreakdown, search, selectedLocations, selectedEmployees} = this.state;

    const {EXTRAS} = payrollDraft;

    if (search.length > 0) {
      breakdown = new Fuse(breakdown, {
        keys: ["FULL_NAME"],
        useExtendedSearch: true,
        threshold: 0.1,
      })
        .search(search)
        .map(({item}) => item);
    }

    if (selectedLocations !== "all") {
      breakdown = breakdown.filter((breakdownObj) => breakdownObj.LOCATION_ID === selectedLocations);
    }

    if (selectedEmployees !== "all" && !hideEmployeeSelector) {
      breakdown = breakdown.filter(
        (breakdownObj) =>
          breakdownObj?.PAY_SUMMARY?.AMOUNT_TOTAL_GROSS ??
          0 + EXTRAS[breakdownObj.ID]?.AMOUNT_GROSS_PAY ??
          0 > 0
      );
    }

    const existingBreakdownDict = parseIdDict(existingBreakdown);

    return breakdown.map((_empRow) => {
      const findEmployeeEntry = existingBreakdownDict[_empRow.ID];

      if (findEmployeeEntry) {
        return {...findEmployeeEntry};
      }

      return {
        ID: _empRow.ID,
        CHECK_EMPLOYEE_ID: _empRow.CHECK_EMPLOYEE_ID,
        NAME: _empRow.FULL_NAME,
        LOCATION_ID: _empRow.LOCATION_ID,
        DEFAULT_RATE: _empRow.PAY_SUMMARY?.PTO_RATE,
        RATES: _empRow.ALL_PAY_RATES,
        REQUESTED_PTO: {...(_empRow.PAY_SUMMARY?.PTO_BREAKDOWN ?? {})},
        ADDITIONAL_PTO: {
          sick: EXTRAS[_empRow.ID]?.SICK_PAY?.length > 0 ? EXTRAS[_empRow.ID]?.SICK_PAY[0] : null,
          pto: EXTRAS[_empRow.ID]?.ADDITIONAL_PTO?.length > 0 ? EXTRAS[_empRow.ID]?.ADDITIONAL_PTO[0] : null,
        },
        POLICIES: _empRow.POLICIES,
        TYPE: _empRow.TYPE,
      };
    });
  }

  renderSummary() {
    const {breakdown} = this.state;

    const ptoAmount = fetchPayrollTimeOffAmount(breakdown);

    return (
      <div className={"mb-6"}>
        <CardAlert
          label="Total"
          icon="badge-dollar"
          value={"$" + toDollars(ptoAmount)}
          subtext={"attributed to time off this pay run"}
          hideView
        />
      </div>
    );
  }

  renderTable() {
    const {payrollDraft = {}, hideEmployeeSelector = false} = this.props.navState?.metadata;
    const {breakdown, search, selectedLocations, selectedEmployees} = this.state;

    return (
      <div className={"pt-4"}>
        <PayrollTimeOffTable
          search={search}
          selectedLocations={selectedLocations}
          selectedEmployees={selectedEmployees}
          breakdown={breakdown}
          payrollDraft={payrollDraft}
          hideEmployeeSelector={hideEmployeeSelector}
          updateBreakdown={(breakdown) => this.setState({breakdown})}
        />
      </div>
    );
  }

  render() {
    const {payrollDraft = {}, hideEmployeeSelector = false} = this.props.navState?.metadata;
    const {DBA_LOCATIONS} = payrollDraft;
    const {tabs} = this.state;

    return (
      <div className={"p-6"}>
        <div className="text-xl font-semibold">Time Off</div>

        <div className="block text-sm text-gray-700 font-normal mb-5">
          <span>{`Enter additional paid time off taken by your employees for this pay cycle.`}</span>

          <div
            className={"mt-2"}
          >{`Please remember to set a default rate for employees who have requested time off. To do this, click the alert badge next to an employee's name. This can be changed later on the employee's profile.`}</div>
        </div>

        {this.renderSummary()}

        <PayrollRunTableFilter
          dbaLocations={DBA_LOCATIONS}
          tabs={tabs ?? []}
          setSearch={(input) => this.setState({search: input})}
          setSelectedLocations={(input) => this.setState({selectedLocation: input})}
          setSelectedEmployees={(input) => this.setState({selectedEmployees: input})}
          hideEmployeeSelector={hideEmployeeSelector}
        />

        {this.renderTable()}
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(PayrollTimeOffComponent);

import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../../utils/request";

class CreateProcessModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  createProcess(values) {
    const {name} = values;
    const {syncState} = this.props;

    request("checklists/onboarding", "POST", {
      NAME: name,
    }).then(() => {
      syncState();
      this.modal.close();
    });
  }

  render() {
    return (
      <Modal
        buttonLabel={"Create"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        label={"Create Process"}
      >
        <Formik
          onSubmit={(values) => this.createProcess(values)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Please enter a name"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;
            return (
              <form onSubmit={handleSubmit}>
                <FormInput name={"name"} label={"Process Name"} options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default CreateProcessModal;

import React, {Component} from "react";
import {Card, Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import PropTypes from "prop-types";
import moment from "moment";
import TimeOffModal from "../../../../modals/team/time-off-modal";
import {request} from "../../../../utils/request";
import {setupReduxConnection} from "../../../../redux";
import {timeOffStatusToBadge} from "../../../../utils/team-helper";
import {CheckIcon, XIcon} from "@heroicons/react/solid";
import {withRouter} from "../../../../utils/navigation";
import {renderNoPermission} from "../../../../utils/approval-center-helper";

class TimeOffTab extends Component {
  blessRequest = async (requestId, isAccepted, reviewerNotes) => {
    let {requests, updateRequests} = this.props;

    const blessPayload = {
      NOTES: reviewerNotes,
    };

    let serverRequest = null;

    if (isAccepted) {
      serverRequest = await request("timeoff/requests/" + requestId + "/approve", "POST", blessPayload);
    } else {
      serverRequest = await request("timeoff/requests/" + requestId + "/deny", "POST", blessPayload);
    }

    const requestIndex = requests.findIndex((item) => {
      return item.ID === serverRequest.ID;
    });

    if (requestIndex !== -1) {
      requests.splice(requestIndex, 1, serverRequest);

      updateRequests(requests);
    }
  };

  render() {
    const {requests, updateRequests, hasPermissions} = this.props;
    let {locations} = this.props;
    const {location} = this.props.shop;

    if (!hasPermissions) {
      return renderNoPermission();
    }

    return (
      <Card label="Time Off Requests" description={"Unapproved requests from employees for time off"}>
        <TimeOffModal
          ref={(e) => (this.timeOffModal = e)}
          blessRequest={this.blessRequest}
          archiveRequest={(serverRequest) => {
            const requestIndex = requests.findIndex((item) => {
              return item.ID === serverRequest.ID;
            });

            if (requestIndex !== -1) {
              requests.splice(requestIndex, 1);

              updateRequests(requests);
            }
          }}
        />

        <Filter
          className="mt-0"
          defaultFilters={[
            {filter: "status", label: "Pending", id: "PENDING"},
            {filter: "location", id: location.ID},
          ]}
          data={[
            {
              id: "location",
              label: "Location",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.LOCATION_ID);
                }),
              options: locations ?? [],
            },
            {
              id: "status",
              label: "Request Status",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.STATUS);
                }),
              options: [
                {id: "PENDING", label: "Pending"},
                {id: "APPROVED", label: "Accepted"},
                {id: "DENIED", label: "Denied"},
              ],
            },
          ]}
        >
          {(filters) => (
            <Table
              pagination
              data={requests}
              filters={filters}
              sortable
              actionButtons={[
                {
                  label: "View Details",
                  onClick: (row) => this.timeOffModal.open(row),
                },
              ]}
              columns={[
                {
                  value: "NAME",
                  label: "Employee",
                  sortable: false,
                  format: (value, row) => {
                    return (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            src={getObjectImage(row, "LOGO", "appicon.png")}
                            className="h-10 w-10 rounded-full"
                            alt=""
                          />
                        </div>

                        <div className="ml-4">
                          <div
                            onClick={() => {
                              this.props.router.navigate("/employee/" + row.EMPLOYEE_UNIQUE_ID);
                            }}
                            className="text-sm font-medium cursor-pointer text-indigo-600"
                          >
                            {row.EMPLOYEE_NAME}
                          </div>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  value: "POLICY_NAME",
                  label: "Policy Name",
                  sortable: false,
                },
                {
                  value: "DATE_START",
                  label: "Dates Off",
                  format: (value, row) => {
                    return <div className="text-sm text-gray-500">{formatDatesOff(row)}</div>;
                  },
                  sortable: true,
                  sortFunction: (a, b) => {
                    if (!a?.DATE_START || b.DATE_START) {
                      return 0;
                    }
                    return a?.DATE_START < b?.DATE_START ? -1 : 1;
                  },
                },
                {
                  value: "AMOUNT",
                  label: "Hours Requested Off",
                  sortable: false,
                },
                {
                  value: "STATUS",
                  label: "Status",
                  sortable: false,
                  format: (value) => {
                    return timeOffStatusToBadge(value);
                  },
                },
                {
                  value: "STATUS",
                  mobile: "lg:table-cell",
                  width: 1,
                  sortable: false,
                  format: (value, row) => {
                    return (
                      <div>
                        {row.STATUS === "PENDING" && (
                          <div className="flex flex-row">
                            <div
                              className="text-green-600 hover:text-green-900 cursor-pointer font-medium mr-7"
                              onClick={async () => {
                                await this.blessRequest(row.ID, true, "");
                              }}
                            >
                              <CheckIcon className="h-6 w-6" />
                            </div>

                            <div
                              className="text-red-600 hover:text-red-900 cursor-pointer font-medium"
                              onClick={async () => {
                                await this.blessRequest(row.ID, false, "");
                              }}
                            >
                              <XIcon className="h-6 w-6" />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
            />
          )}
        </Filter>
      </Card>
    );
  }
}

TimeOffTab.propTypes = {
  requests: PropTypes.array.isRequired,
};

export default setupReduxConnection(["user"])(withRouter(TimeOffTab));

export function formatDatesOff(request) {
  let dateDisplay = null;
  let start = moment(request.DATE_START);
  let end = moment(request.DATE_END);

  if (request.PARTIAL_DAYS) {
    dateDisplay = start.format("ddd, MMM Do h:mm A") + " - " + end.format("ddd, MMM Do h:mm A");
  } else {
    dateDisplay = start.format("ddd, MMM D");
    if (!start.isSame(end, "date")) {
      dateDisplay = dateDisplay + " - " + end.format("ddd, MMM D");
    }
  }

  return dateDisplay;
}

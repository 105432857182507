import React, {Component} from "react";
import {Modal, FormSelect, FormBoolean} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";
import EmployeeDropdown from "../../dropdowns/team/employee-dropdown";
import {TIP_CALCULATION_METHOD_TYPES} from "../../utils/settings-constants";
import {STATES_WITH_NO_TIP_CREDIT_ADJUSTMENT} from "../../utils/payroll-constants";

class PayrollSettingsModal extends Component {
  state = {notificationEmployeeId: null};

  async componentDidMount() {
    let [notificationEmployeeId] = await Promise.all([request("payroll/notification-employees", "GET")]);

    this.setState({notificationEmployeeId});
  }

  open() {
    this.modal.open();
  }

  handleSubmit = async ({
    payoutCalculatedTips,
    paycheckTips,
    notificationEmployeeId,
    tipCalculationMethod,
    overtimeWorkweekStart,
    automaticTipCredit,
  }) => {
    let settingsPayload = {
      PAYROLL_PAY_CALCULATED_TIPS: payoutCalculatedTips,
      PAYROLL_PAYCHECK_TIPS: paycheckTips,
      TIP_CALCULATION_METHOD: tipCalculationMethod,
      AUTOMATIC_TIP_CREDIT: automaticTipCredit,
    };

    const payrollGroupPayload = {
      OVERTIME_WORKWEEK_START_ISODAY: overtimeWorkweekStart,
    };

    let notificationPayload = {
      EMPLOYEE_ID_ARRAY: [notificationEmployeeId],
    };

    await Promise.all([
      request("partner/settings/v2", "PATCH", {SETTINGS: settingsPayload}),
      request("payroll/groups", "PATCH", payrollGroupPayload),
    ]);

    if (notificationEmployeeId) {
      await request("payroll/notification-employees", "POST", notificationPayload);
    }

    const [settings, payrollGroup] = await Promise.all([
      request("settings/shop", "GET"),
      request("payroll/groups", "GET"),
    ]);

    this.props.updateShop({settings, payrollGroup});

    this.props.refresh();
    this.modal.close();
  };

  render() {
    const {PAYROLL_PAY_CALCULATED_TIPS, PAYROLL_PAYCHECK_TIPS, TIP_CALCULATION_METHOD, AUTOMATIC_TIP_CREDIT} =
      this.props.shop.settings;
    const {OVERTIME_WORKWEEK_START_ISODAY} = this.props.shop.payrollGroup;
    const {showTipCreditAdjustment} = this.props;

    const {notificationEmployeeId} = this.state;
    return (
      <Modal
        label="Payroll Settings"
        buttonLabel="Save"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            payoutCalculatedTips: PAYROLL_PAY_CALCULATED_TIPS,
            paycheckTips: PAYROLL_PAYCHECK_TIPS,
            notificationEmployeeId,
            tipCalculationMethod: TIP_CALCULATION_METHOD,
            overtimeWorkweekStart: OVERTIME_WORKWEEK_START_ISODAY,
            automaticTipCredit: AUTOMATIC_TIP_CREDIT,
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {payoutCalculatedTips} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  label={"Calculate Tips with Dripos"}
                  name={"payoutCalculatedTips"}
                  options={formikOptions}
                />

                {payoutCalculatedTips === "1" && (
                  <FormSelect
                    label={"Tip Calculation Method"}
                    name={"tipCalculationMethod"}
                    data={Object.keys(TIP_CALCULATION_METHOD_TYPES).map((key) => ({
                      label: TIP_CALCULATION_METHOD_TYPES[key],
                      value: key,
                    }))}
                    options={formikOptions}
                  />
                )}

                {payoutCalculatedTips === "1" && (
                  <FormSelect
                    label={"Tip Payout"}
                    name={"paycheckTips"}
                    data={[
                      {
                        label: "Paycheck Tips",
                        value: "1",
                      },
                      {
                        label: "Cash Tips",
                        value: "0",
                      },
                    ]}
                    options={formikOptions}
                  />
                )}

                <EmployeeDropdown
                  label="Notification Employee"
                  tooltip={"Reminders to run payroll will be sent to this employee's email"}
                  onChangeSoft={({label}) => setFieldValue("employeeName", label)}
                  options={formikOptions}
                  name="notificationEmployeeId"
                />

                <FormSelect
                  name={"overtimeWorkweekStart"}
                  label={"Workweek Start"}
                  options={formikOptions}
                  data={[
                    {value: 1, label: "Monday"},
                    {value: 2, label: "Tuesday"},
                    {value: 3, label: "Wednesday"},
                    {value: 4, label: "Thursday"},
                    {value: 5, label: "Friday"},
                    {value: 6, label: "Saturday"},
                    {value: 7, label: "Sunday"},
                  ]}
                />

                {showTipCreditAdjustment && (
                  <FormBoolean
                    label={"Automatic Tip Credit Adjustment to Minimum Wage"}
                    name={"automaticTipCredit"}
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(PayrollSettingsModal);

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import React, {Component} from "react";
import {
  INGREDIENT_TYPE_DISPLAYS,
  INGREDIENT_TYPES,
} from "../../../modals/operations/supply-chain/recipes/ingredient-modal";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";

class SupplyChainInventoryTable extends Component {
  fetchNameColumn() {
    return {
      width: 1,
      value: "NAME",
      label: "Item/Ingredient",
      format: (val, row) => {
        const outOfStock = row.DISPLAY_STOCK <= 0;
        return (
          <>
            {" "}
            {outOfStock && <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />} {val}{" "}
          </>
        );
      },
    };
  }

  fetchCurrentStockColumn() {
    return {
      width: 1,
      value: "DISPLAY_STOCK",
      label: "Current Inventory",
      format: (val, row) => {
        return `${val} ${row.DISPLAY_UNIT?.NAME ?? row.UNIT?.NAME ?? ""}`;
      },
    };
  }

  fetchCategoriesColumn() {
    return {
      value: "CATEGORIES",
      sortable: false,
      label: "Groups",
      format: (categories) => {
        return categories.length === 0 ? "-" : categories.map((category) => category.NAME).join(", ");
      },
    };
  }

  fetchCreatedColumn() {
    return {
      width: 1,
      value: "DATE_UPDATED",
      label: "Edited On",
      format: (val) => moment(val).format("MMM Do hh:mmA"),
    };
  }

  handleCostChange(ingredient, key, value) {
    const {updateState} = this.props;
    updateState({[ingredient.ID]: {COST: value}});
  }

  fetchCostColumn() {
    return {
      value: "COST",
      label: "Default Cost",
      editable: true,
      onChangeRow: (val, row, thing) => this.handleCostChange(val, row, thing),
      tooltip: "The cost per unit of this item. This field is editable.",
      format: (val, row) => `$${val} per ${row?.UNIT?.NAME ?? "Unit"}`,
    };
  }

  fetchColumns() {
    return [
      this.fetchNameColumn(),
      this.fetchCurrentStockColumn(),
      this.fetchCostColumn(),
      this.fetchCategoriesColumn(),
      this.fetchCreatedColumn(),
    ];
  }

  fetchCsvFormat() {
    return {
      file: "ingredients",
      data: this.props.supply.ingredients,
      headers: [
        {label: "Name", key: "NAME"},
        {label: "Current Stock", key: "CURRENT_STOCK"},
        {label: "Edited On", key: "DATE_UPDATED", type: "datetime"},
      ],
    };
  }

  filterOutOfStock(options, data) {
    if (options.length === 0) {
      return data;
    }

    return data.filter((item) => {
      return item.DISPLAY_STOCK <= 0;
    });
  }

  filterCategory(options, data) {
    if (options.length === 0) {
      return data;
    }

    return data.filter((item) => {
      return item.CATEGORIES.find((c) => options.includes(c.ID));
    });
  }

  filterType(options, data) {
    if (options.length === 0) {
      return data;
    }

    return data.filter((item) => {
      return options.includes(item.TYPE);
    });
  }

  fetchFilters() {
    const {categories} = this.props.supply;

    return [
      {
        id: "inventory",
        label: "Inventory",
        onFilter: (options, data) => this.filterOutOfStock(options, data),
        options: [{id: "outOfStock", label: "Out of Stock", value: "outOfStock"}],
      },
      {
        id: "category",
        label: "Groups",
        onFilter: (options, data) => this.filterCategory(options, data),
        options: categories.map((item) => ({
          id: item.ID,
          label: item.NAME,
          value: item.ID,
        })),
      },
      {
        id: "type",
        label: "Type",
        onFilter: (options, data) => this.filterType(options, data),
        options: Object.values(INGREDIENT_TYPES).map((item) => ({
          id: item,
          label: INGREDIENT_TYPE_DISPLAYS[item],
          value: item,
        })),
      },
    ];
  }

  render() {
    return (
      <Filter csv={this.fetchCsvFormat()} searchable searchParams data={this.fetchFilters()}>
        {(filters, search) => {
          return (
            <Table
              sorting
              pagination
              searchParams
              searchFields={["NAME"]}
              search={search}
              filters={filters}
              rowsPerPage
              defaultSort="DATE_UPDATED"
              ref={(e) => (this.tableRef = e)}
              columns={this.fetchColumns()}
              {...this.props}
            />
          );
        }}
      </Filter>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainInventoryTable));

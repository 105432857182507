import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import {RecurringInvoiceRequests} from "../../../utils/request-helpers/invoices/recurring-invoice-requests";

class ConfirmInvoiceModal extends Component {
  state = {invoice: null};

  open(invoice = null) {
    this.setState({invoice}, () => {
      this.modal.open();
    });
  }

  async createInvoice(status, button, disableButtons, send = true) {
    const {schedule, invoice, lines, serviceFees, discounts, recipients, reminders = []} = this.state.invoice;
    const {isRecurring} = this.props;

    button && button.startLoading();

    for (const disableButton of disableButtons) {
      disableButton && disableButton.disable();
    }

    const invoicePayload = {
      STATUS: status,
      INVOICE_NUMBER: invoice.INVOICE_NUMBER,

      CONTACT_ID: invoice.CONTACT_ID,
      NOTES: invoice.NOTES,

      COLLECTION_METHOD: invoice.COLLECTION_METHOD,
      CUSTOMER_ID: invoice.CUSTOMER_ID,
      SOURCE_ID: invoice.SOURCE_ID,

      DATE_SCHEDULE: null,
      DATE_SERVICE: invoice.DATE_SERVICE,
      DATE_DUE: invoice.DATE_DUE,

      ITEMS: [
        ...lines.map((line, index) => ({...line, SEQ: index})),
        ...serviceFees.map((fee, index) => ({
          ...fee,
          SEQ: index + lines.length,
        })),
      ],
      DISCOUNTS: discounts,
      REMINDERS: reminders,
      RECIPIENTS: recipients,
      REQUEST_TIP: invoice.REQUEST_TIP,
      SEND_TO_KDS: invoice.SEND_TO_KDS,
      SEND_TO_CUSTOMER: send,
    };

    if (isRecurring) {
      const payload = {...schedule, INVOICE: invoicePayload};
      const serverInvoice = await RecurringInvoiceRequests.create(payload);

      this.props.router.navigate("/recurring-invoice/" + serverInvoice.UNIQUE_ID);
    } else {
      const serverInvoice = await request("invoices", "POST", invoicePayload);

      this.props.router.navigate("/invoice/" + serverInvoice.UNIQUE_ID);
    }

    this.modal && this.modal.close();
  }

  fetchActionButtons() {
    const {isRecurring} = this.props;

    if (isRecurring) {
      return [
        {
          label: "Create Recurring Invoice",
          ref: (e) => (this.sendButton = e),
          onClick: () =>
            this.createInvoice("OPEN", this.sendButton, [this.noSendButton, this.draftButton], true),
        },
      ];
    } else {
      return [
        {
          type: "gray",
          label: "Save as Draft",
          ref: (e) => (this.draftButton = e),
          onClick: () =>
            this.createInvoice("DRAFT", this.draftButton, [this.sendButton, this.noSendButton], false),
        },
        {
          label: "Create Without Sending",
          ref: (e) => (this.noSendButton = e),
          onClick: () =>
            this.createInvoice("OPEN", this.noSendButton, [this.sendButton, this.draftButton], false),
        },
        {
          label: "Create & Send",
          ref: (e) => (this.sendButton = e),
          onClick: () =>
            this.createInvoice("OPEN", this.sendButton, [this.noSendButton, this.draftButton], true),
        },
      ];
    }
  }

  renderConfirmationText() {
    const {isRecurring} = this.props;

    return isRecurring ? (
      <div className="mt-4 text-sm text-gray-700">Would you like to create this recurring invoice now?</div>
    ) : (
      <div className="mt-4 text-sm text-gray-700">
        Would you like to <span className="font-bold">Save as Draft</span> and send the invoice at another
        date or would you like to <span className="font-bold">Send Now</span> and notify the customer
        immediately?
      </div>
    );
  }

  render() {
    const {invoice: invoiceObj = {}} = this.state;
    const {invoice} = invoiceObj || {};
    const {isRecurring} = this.props;

    return (
      <Modal
        hideClose
        deleteOnClick={() => this.modal.close()}
        deleteType="gray"
        large
        deleteLabel="Cancel"
        buttons={this.fetchActionButtons()}
        label="Send Invoice"
        ref={(e) => (this.modal = e)}
      >
        <div className="font-medium">
          Creating {isRecurring ? "recurring invoice" : "invoice"} for{" "}
          <span className="font-bold">
            {invoice?.CONTACT?.FIRST_NAME} {invoice?.CONTACT?.LAST_NAME}
          </span>
        </div>

        {this.renderConfirmationText()}
      </Modal>
    );
  }
}

export default withRouter(ConfirmInvoiceModal);

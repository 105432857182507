import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";

class IngredientDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverProducts = await request("ingredients", "GET");
    const data = serverProducts.map((item) => {
      return {
        id: item.ID,
        label: item.NAME + " (" + item.INGREDIENT_UNIT_NAME + ")",
        ...item,
      };
    });

    this.setState({data});
  }

  fetchProduct() {
    const {data} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    return data.find((item) => item.id === parseInt(value));
  }

  render() {
    const {data} = this.state;

    return <ComboBox label="Ingredient" {...this.props} data={data} />;
  }
}

export default IngredientDropdown;

import React, {Component} from "react";
import {request} from "../../../utils/request";
import {PageHeadings, Loading, TwoColumnList, Table, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment/moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class CheckPage extends Component {
  state = {check: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    const {UNIQUE_ID} = this.props.router.params;

    request("check/" + UNIQUE_ID, "GET").then((check) => this.setState({check}));
  }

  render() {
    const {check} = this.state;

    if (!check) {
      return <Loading />;
    }

    const duration = moment.duration(moment(check.DATE_COMPLETED).diff(moment(check.DATE_CREATED)));

    const hours = Math.round(duration.asHours());
    const minutes = Math.round(duration.asMinutes() % 60);

    const checkInformation = [
      {
        label: "Table",
        value: check.TABLE.NAME,
      },
      {
        label: "Served by",
        value: check.EMPLOYEE_NAME,
      },
      {
        label: "Unique Identifier",
        value: check.UNIQUE_ID,
      },
      {
        label: "Total Check Time",
        value: hours + ":" + minutes,
      },
    ];

    return (
      <div>
        <PageHeadings
          label={"Check for " + check.TABLE.NAME}
          description={"Served by " + check.EMPLOYEE_NAME}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Checks Page", url: "/checks"},
          ]}
        />

        <TwoColumnList label={"Check Information"} data={checkInformation} />

        <Tab data={[{id: "tickets", label: "Tickets"}]}>
          {(id) => {
            if (id === "tickets") {
              return (
                <Table
                  key={id}
                  className="mt-2"
                  data={check.TICKETS}
                  ref={(e) => (this.tableRef = e)}
                  actionButtons={[
                    {
                      label: "View",
                      onClick: (ticket) => this.props.router.navigate("/ticket/" + ticket.UNIQUE_ID),
                    },
                  ]}
                  columns={[
                    {
                      value: "NAME",
                      label: "Ticket Name",
                    },

                    {
                      value: "ITEMS",
                      label: "Items",
                      format: (items) => items.length,
                    },
                    {
                      value: "NAME",
                      label: "Subtotal",
                      format: (_, row) => toDollars(row.PAYMENT_INTENT.AMOUNT, true),
                    },
                    {
                      value: "NAME",
                      label: "Taxes",
                      format: (_, row) => toDollars(row.PAYMENT_INTENT.AMOUNT_TAX, true),
                    },
                    {
                      value: "NAME",
                      label: "Tips",
                      format: (_, row) => toDollars(row.PAYMENT_INTENT.AMOUNT_TIP, true),
                    },
                    {
                      value: "NAME",
                      label: "Total",
                      format: (_, row) => toDollars(row.PAYMENT_INTENT.TOTAL, true),
                    },
                  ]}
                />
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}
export default withRouter(CheckPage);

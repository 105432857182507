import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import PropTypes from "prop-types";
import {
  getChatEmployees,
  getConversationImage,
  getConversationName,
  getReadRecipients,
  getUnreadRecipients,
} from "../../utils/messaging/message-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Message from "./message";

class ChatWindow extends Component {
  state = {message: null};

  renderMessages() {
    const {messages, conversation, employees} = this.props;
    const {account} = this.props.user;

    if (!conversation) {
      return (
        <div className={"flex flex-1 justify-center items-center text-lg text-gray-500"}>
          Create a new message to get started 💬
        </div>
      );
    }

    const selfId = conversation?.PARTICIPANTS?.find(
      (participant) => participant.FINAL_ACCOUNT_ID === account.ID
    )?.ID;

    return (
      <div className="flex flex-col-reverse">
        {messages.map((_message, index) => {
          const isSelf = _message.PARTICIPANT_ID === selfId;
          const nextMessage = index < messages.length - 1 ? messages[index + 1] : null;

          const {readRecipients, unreadRecipients} = getReadRecipients(conversation, _message);

          let readEmployees = [];
          let unreadEmployees = [];

          if (readRecipients) {
            readEmployees = getChatEmployees(readRecipients, employees);
          }

          if (unreadRecipients) {
            unreadEmployees = getChatEmployees(unreadRecipients, employees);
          }

          return (
            <Message
              nextMessage={nextMessage}
              data={_message}
              isSelf={isSelf}
              isFirst={nextMessage === null || nextMessage.PARTICIPANT_ID !== _message.PARTICIPANT_ID}
              isGroup={conversation.PARTICIPANTS?.length > 2}
              readEmployees={readEmployees}
              unreadEmployees={unreadEmployees}
              readRecipients={readRecipients}
              unreadRecipients={unreadRecipients}
              openReadReceipts={this.props.openReadReceipts}
            />
          );
        })}
      </div>
    );
  }

  render() {
    return <div className="flex flex-1 flex-col h-full">{this.renderMessages()}</div>;
  }
}

ChatWindow.propTypes = {
  conversation: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
};

export default setupReduxConnection(["user"])(withRouter(ChatWindow));

import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  fetchValidatedPhone,
  fetchValidatedToken,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Button, FormPhone, FormToken} from "@frostbyte-technologies/frostbyte-tailwind";
import {PHONE_YUP} from "@frostbyte-technologies/frostbyte-tailwind/dist/components/input/form-phone";
import {TOKEN_YUP} from "@frostbyte-technologies/frostbyte-tailwind/dist/components/input/form-token";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import ComboBox from "../../components/combobox";
import {MoonLoader} from "react-spinners";
import {PERMISSION_ARRAY} from "../../utils/constants";

class AuthPage extends Component {
  state = {
    open: false,
    locations: null,
    companies: null,
    unique: null,
    session: null,
    field: "",
    phone: null,
    application: null,
  };

  componentDidMount() {
    request("auth/v1/authorize/validate", "GET", Object.fromEntries(this.props.router.searchParams))
      .then((application) => {
        this.setState({application});
        console.log("app", application);
      })
      .catch((err) => {
        console.log("err", err);
        alert("Error setting up authorization");
      });
  }

  submitPhone({phone}) {
    // this.buttonRef.startLoading();

    let phoneFormatted = fetchValidatedPhone(phone);

    request("login/initiate", "POST", {PHONE: phoneFormatted})
      .then(async (unique) => {
        this.setState({unique, phone: phoneFormatted});
      })
      .catch(() => {
        return Promise.reject("SERVER_ERROR");
      });
  }

  submitEmail(phone) {
    alert("Email Sent!");

    request("login/initiate/email", "POST", {PHONE: phone})
      .then(async (unique) => {
        if (unique) {
          this.setState({unique, phone});
        }
      })
      .catch(() => {
        return Promise.reject("SERVER_ERROR");
      });
  }

  async completeAuth({location, company}) {
    const {session} = this.state;
    const queryParams = this.props.router.searchParams;
    const type = queryParams.get("auth_type") ?? "COMPANY";

    const requestPayload = {
      TYPE: type,
      REDIRECT_URI: queryParams.get("redirect_uri"),
      CLIENT_ID: queryParams.get("client_id"),
      SESSION_ID: session,
    };

    if (type === "LOCATION") {
      requestPayload.LOCATION_ID = location;
    } else if (type === "COMPANY") {
      requestPayload.COMPANY_ID = company;
    }

    window.location.href = await request("auth/v1/authorize", "POST", requestPayload, {
      Authentication: session,
    });
  }

  completeLogin({token}) {
    const {unique} = this.state;

    request("login/complete", "POST", {
      TOKEN: fetchValidatedToken(token),
      UNIQUE: unique,
      CLIENT: {
        NAME: "ORDER.DRIPOS.COM",
        INFO: "Website",
        TYPE: 2,
      },
    })
      .then(async (payload) => {
        this.setState({session: payload});

        const queryParams = this.props.router.searchParams;
        const type = queryParams.get("auth_type") ?? "COMPANY";

        if (type === "LOCATION") {
          const serverLocations = await request("employees/locations", "GET", null, {
            Authentication: payload,
          });

          this.setState({locations: serverLocations});
        } else if (type === "COMPANY") {
          const serverCompanies = await request("auth/v1/authorize/companies", "GET", null, {
            Authentication: payload,
          });

          this.setState({companies: serverCompanies});
        }
      })
      .catch((err) => {
        this.formikRef.setFieldError("token", err?.message ?? "Error validating token");

        this.buttonRef.stopLoading();

        return Promise.reject("SERVER_ERROR");
      });
  }

  renderAcceptLocations() {
    const {locations, application} = this.state;

    if (locations === null) {
      return (
        <div className="flex flex-grow justify-center items-center">
          <MoonLoader color={"blue"} loading={true} size={50} />
        </div>
      );
    }

    return (
      <div>
        <Formik
          enableReinitialize
          onSubmit={this.completeAuth.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object({
            location: Yup.number().typeError("Location is required").required("Location is required"),
          })}
          initialValues={{
            location: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, isValid} = formikOptions;

            return (
              <form className="space-y-6 border-b border-neutral-border" onSubmit={handleSubmit}>
                <div className="pb-4 border-b border-neutral-border">
                  <p className="text-lg font-bold tracking-tight text-gray-900">
                    Authorize {application.NAME}
                  </p>

                  <p className="mt-1 text-base text-gray-600 pb-4 border-b border-neutral-border">
                    By clicking the authorize button below, you are agreeing to give {application.NAME} access
                    to the following permissions.
                  </p>

                  <dl className="mt-3 space-y-6 text-base text-gray-600">
                    {application.PERMISSIONS.map((feature) => (
                      <div key={feature} className="relative">
                        <div className="text-base font-semibold text-gray-900">
                          {PERMISSION_ARRAY[feature]?.label ?? "No Description"}
                        </div>

                        <div className="text-sm">
                          {PERMISSION_ARRAY[feature]?.description?.replaceAll("employee", application.NAME) ??
                            "No Description"}
                        </div>
                      </div>
                    ))}
                  </dl>
                </div>

                <ComboBox
                  label="Locations"
                  name="location"
                  strategy="absolute"
                  options={formikOptions}
                  data={locations.map((item) => {
                    return {
                      id: item.ID,
                      label: item.NAME,
                    };
                  })}
                />

                <Button
                  disabled={!isValid}
                  ref={(e) => (this.buttonRef = e)}
                  label="Authorize"
                  type="submit"
                  flex
                />
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }

  renderAcceptCompanies() {
    const {companies, application} = this.state;

    if (companies === null) {
      return (
        <div className="flex flex-grow justify-center items-center">
          <MoonLoader color={"blue"} loading={true} size={50} />
        </div>
      );
    }

    return (
      <div>
        <Formik
          enableReinitialize
          onSubmit={this.completeAuth.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object({
            company: Yup.number().typeError("Company is required").required("Company is required"),
          })}
          initialValues={{
            company: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, isValid} = formikOptions;

            return (
              <form className="space-y-6 border-b border-neutral-border" onSubmit={handleSubmit}>
                <div className="pb-4 border-b border-neutral-border">
                  <p className="text-lg font-bold tracking-tight text-gray-900">
                    Authorize {application.NAME}
                  </p>

                  <p className="mt-1 text-base text-gray-600 pb-4 border-b border-neutral-border">
                    By clicking the authorize button below, you are agreeing to give {application.NAME} access
                    to the following permissions.
                  </p>

                  <dl className="mt-3 space-y-6 text-base text-gray-600">
                    {application.PERMISSIONS.map((feature) => (
                      <div key={feature} className="relative">
                        <div className="text-base font-semibold text-gray-900">
                          {PERMISSION_ARRAY[feature]?.label ?? "No Description"}
                        </div>

                        <div className="text-sm">
                          {PERMISSION_ARRAY[feature]?.description?.replaceAll("employee", application.NAME) ??
                            "No Description"}
                        </div>
                      </div>
                    ))}
                  </dl>
                </div>

                <ComboBox
                  label="Companies"
                  name="company"
                  strategy="absolute"
                  options={formikOptions}
                  data={companies.map((item) => {
                    return {
                      id: item.ID,
                      label: item.NAME,
                    };
                  })}
                />

                <Button
                  disabled={!isValid}
                  ref={(e) => (this.buttonRef = e)}
                  label="Authorize"
                  type="submit"
                  flex
                />
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }

  renderForm() {
    const {session, unique, application} = this.state;

    if (application === null) {
      return (
        <div className="flex flex-grow justify-center items-center">
          <MoonLoader color={"blue"} loading={true} size={50} />
        </div>
      );
    }

    if (session !== null) {
      const queryParams = this.props.router.searchParams;
      const type = queryParams.get("auth_type") ?? "COMPANY";

      if (type === "COMPANY") {
        return this.renderAcceptCompanies();
      } else if (type === "LOCATION") {
        return this.renderAcceptLocations();
      }

      return (
        <div className="flex flex-grow justify-center items-center">
          <MoonLoader color={"blue"} loading={true} size={50} />
        </div>
      );
    }

    if (unique) {
      return (
        <Formik
          initialValues={{token: ""}}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.completeLogin.bind(this)}
          validationSchema={Yup.object({
            token: TOKEN_YUP.required("Authentication token is required to login"),
          })}
        >
          {(formikValues) => {
            const {handleSubmit} = formikValues;

            return (
              <form className="space-y-6" onSubmit={handleSubmit}>
                <FormToken label="Validation Token" options={formikValues} name="token" />

                <div>
                  {this.state.phone && (
                    <div
                      className="mb-5 cursor-pointer text-primary-text text-sm"
                      onClick={() => {
                        this.submitEmail(this.state.phone);
                      }}
                    >
                      Didn't receive token? Send via email.
                    </div>
                  )}

                  <Button flex type="submit" label="Complete Sign-In" ref={(e) => (this.buttonRef = e)} />
                </div>
              </form>
            );
          }}
        </Formik>
      );
    }

    return (
      <Formik
        initialValues={{phone: ""}}
        onSubmit={this.submitPhone.bind(this)}
        validationSchema={Yup.object({
          phone: PHONE_YUP.required("Phone number is required to login"),
        })}
      >
        {(formikValues) => {
          const {handleSubmit} = formikValues;

          return (
            <form className="space-y-6" onSubmit={handleSubmit}>
              <FormPhone
                placeholder="781 583 3699"
                label="Phone Number"
                options={formikValues}
                innerRef={(e) => (this.phoneField = e)}
                name="phone"
              />

              <div>
                <Button ref={(e) => (this.buttonRef = e)} type="submit" label="Sign In" flex />

                <div className="text-center text-sm font-small px-6 pt-2 text-gray-500">
                  By clicking sign in, you opt-in to get a one-time verification text sent to the provided
                  phone
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }

  render() {
    const {application} = this.state;

    return (
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            src={require("./../../assets/images/logo.png")}
            className="mx-auto h-12 w-auto"
            alt="Workflow"
          />

          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account to link to {application?.NAME ?? "Platform"}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{this.renderForm()}</div>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(AuthPage));

import React, {Component} from "react";
import {PageHeadings, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../../components/loading-spinner";
import {request} from "../../../utils/request";
import CardBlock from "../../../features/finance/banking/card-block";
import {CreditCard} from "react-feather";
import {CreditCardIcon, SearchIcon} from "@heroicons/react/solid";
import CreateCardModal from "../../../modals/banking/create-card-modal";

class CardsPage extends Component {
  state = {loading: true, cards: null};

  async componentDidMount() {
    let cards = await request("banking2/cards", "GET");

    this.setState({loading: false, cards});
  }

  refresh = async (loading = true) => {
    this.setState({loading});

    let cards = await request("banking2/cards", "GET");

    this.setState({loading: false, cards});
  };

  render() {
    let {loading, cards} = this.state;

    return (
      <div>
        <CreateCardModal handleNewCard={this.refresh} ref={(e) => (this.createCardModal = e)} />

        <div className="flex flex-row justify-between">
          <PageHeadings label="Cards" />

          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => this.createCardModal.open()}
          >
            + New Card
          </button>
        </div>

        <Card>
          {loading && (
            <div className="py-10">
              <LoadingSpinner />
            </div>
          )}

          {!loading && (
            <div className="flex flex-row flex-wrap">
              {cards.map((card) => {
                return <CardBlock card={card} handleUpdate={() => this.refresh(false)} />;
              })}

              {cards.length === 0 && (
                <div className="flex flex-row text-gray-700 justify-center items-center py-10 w-full">
                  <CreditCardIcon className="h-5 w-5" />

                  <div className="ml-2 font-semibold text-md">Create Your First Card!</div>
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default CardsPage;

import React, {Component} from "react";
import {FormInput, FormSelect, FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import * as Yup from "yup";

import RECEIPT from "./receipt.json";
import MAKE_RECEIPT from "./make-receipt.json";
import FormRow from "../../../components/form-row";
import ReceiptEditor from "../../../features/sales/flow/receipt-editor";

class ReceiptModal extends Component {
  state = {receipt: null};

  open(receipt = null) {
    this.setState({receipt}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createReceipt({name, type, itemSpecific, template}) {
    const {templates} = this.props;
    const receiptPayload = {
      NAME: name,
      ITEM_SPECIFIC: itemSpecific,
      TYPE: type,
    };

    if (type === "MAKE") {
      receiptPayload.CONTENT = JSON.stringify(MAKE_RECEIPT);
    } else if (type === "RECEIPT") {
      receiptPayload.CONTENT = JSON.stringify(RECEIPT);
    }

    const foundTemplate = templates.find((item) => {
      return item.ID === template;
    });

    console.log(foundTemplate);

    if (foundTemplate) {
      receiptPayload.CONTENT = foundTemplate.CONTENT;
    }

    const serverReceipt = await request("receipts", "POST", receiptPayload);

    this.props.addState(serverReceipt);
    this.modal.close();
  }

  async saveReceipt({name, type, itemSpecific}) {
    const {receipt} = this.state;

    const receiptPayload = {
      NAME: name,
      ITEM_SPECIFIC: itemSpecific,
      TYPE: type,
    };

    const serverReceipt = await request("receipts/" + receipt.ID, "PATCH", receiptPayload);

    this.props.updateState(serverReceipt);
    this.modal.close();
  }

  render() {
    const {templates} = this.props;
    const {receipt} = this.state;

    return (
      <Modal
        buttonLabel={receipt ? "Save" : "Add"}
        label={receipt ? "Edit Receipt" : "Create Receipt"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={receipt ? this.saveReceipt.bind(this) : this.createReceipt.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: receipt?.NAME ?? "",
            type: receipt?.TYPE ?? "MAKE",
            itemSpecific: receipt?.ITEM_SPECIFIC ?? "0",
            template: templates?.length > 0 ? templates[0].ID : null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Receipt name is required."),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue, values} = formikOptions;

            const template = templates?.find((item) => {
              return item.ID === values.template;
            });

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="name" label="Receipt Name" options={formikOptions} />

                <FormSelect
                  label="Receipt Type"
                  name="type"
                  options={formikOptions}
                  onChangeSoft={({value}) => {
                    const currentTypeTemplates = templates.filter((item) => {
                      return item.TYPE === value;
                    });

                    if (currentTypeTemplates.length > 0) {
                      setFieldValue("template", currentTypeTemplates[0].ID);
                    }
                  }}
                  data={[
                    {label: "Make Ticket", value: "MAKE"},
                    {label: "Front of House Receipt", value: "RECEIPT"},
                  ]}
                />

                <FormBoolean
                  name="itemSpecific"
                  label="Print Items Singular"
                  options={formikOptions}
                  tooltip="Will this receipt print each item on the ticket on it's own separate receipt"
                />

                {receipt === null && (
                  <>
                    <FormSelect
                      name="template"
                      label="Receipt Template"
                      options={formikOptions}
                      data={templates
                        .filter((item) => item.TYPE === values.type)
                        .map((item) => ({
                          label: item.NAME,
                          value: item.ID,
                        }))}
                    />

                    {template && (
                      <div
                        key={values.template}
                        style={{
                          marginTop: 12,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ReceiptEditor viewOnly receipt={JSON.parse(template.CONTENT)} />
                      </div>
                    )}
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ReceiptModal.propTypes = {
  templates: PropTypes.array,
  updateState: PropTypes.func,
  addState: PropTypes.func,
};

export default ReceiptModal;

import React, {Component} from "react";
import {Modal, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import InvoiceLinesForm from "../../../forms/operations/invoices/invoice-lines-form";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {
  fetchLineItem,
  fetchLineItemSelection,
  updateCartItem,
} from "@frostbyte-technologies/frostbyte-tickets/dist/helpers/cart-helper";
import {putItemsAndDiscounts} from "@frostbyte-technologies/frostbyte-tickets/dist/helpers/ticket-helper";

class InvoiceLinesModal extends Component {
  state = {invoice: null, serviceFees: [], products: null, discounts: []};

  componentDidMount() {
    request("products", "GET").then((products) => this.setState({products}));
  }

  open(invoice = null) {
    const serviceFees = invoice?.TICKET?.ITEMS.filter(
      (item) => item.TYPE === "FEE" && item.NOTES === "Service Fee"
    );

    const discounts = invoice?.TICKET?.DISCOUNTS;

    this.setState({invoice, serviceFees, discounts}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  initializeCart(lines = [], discounts = []) {
    const {products} = this.state;
    const productDict = parseIdDict(products);
    const items = [];

    for (const line of lines) {
      let product = productDict[line.OBJECT_ID];

      if (!product) {
        product = {
          ID: line.OBJECT_ID,
          NAME: line.NAME,
          PRICE: (line.AMOUNT + line.AMOUNT_DISCOUNT) / line.QUANTITY,
          ONE_TIME: true,
          CUSTOMIZATIONS: [],
          TAX_RATES: line.TAX_RATES,
        };
      }

      const modifierDict = parseIdDict(product.CUSTOMIZATIONS);

      const selections = line.SELECTIONS.filter(
        (s) => s.MODIFIER_TYPE !== CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT
      ).map((selection) =>
        fetchLineItemSelection({
          modifier: modifierDict[selection.MODIFIER_ID],
          option: modifierDict[selection.MODIFIER_ID].OPTIONS.find(
            (option) => option.ID === selection.OPTION_ID
          ),
          quantity: selection.QUANTITY ?? 1,
          rates: line.TAX_RATES.map((rate) => rate.TAX_RATE),
        })
      );

      items.push({
        ...fetchLineItem({
          product: product, // this will brick with custom products
          discounts: [],
          rates: line.TAX_RATES.map((rate) => rate.TAX_RATE),
          selections,
          quantity: line.QUANTITY,
        }),
        ID: line.ID,
      });
    }

    const {items: newItems, discounts: newDiscounts} = updateCartItem({
      items,
      cartItem: null,
      discounts,
    });

    return {
      items: newItems,
      discounts: newDiscounts,
    };
  }

  saveInvoice = async ({...options}) => {
    const {invoice} = this.state;
    let {lines, discounts, serviceFees} = this.formikRef.fetchFormData(options);
    lines = [...lines, ...serviceFees];

    lines = lines.map((line) =>
      line.NAME
        ? line
        : {
            ...line,
            NAME: "Custom Product",
            OBJECT_ID: -Math.round(Math.random() * 1000),
          }
    );

    invoice.TICKET = await request("invoices/" + invoice.ID + "/items", "PATCH", {
      ITEMS: lines,
      DISCOUNTS: discounts,
    });

    this.props.updateState(invoice);
    this.modal.close();
  };

  render() {
    const {invoice, serviceFees, discounts, products} = this.state;

    if (!products) {
      return <Loading />;
    }

    const {items, discounts: newDiscounts} = this.initializeCart(
      invoice?.TICKET?.ITEMS.filter((item) => item.NOTES !== "Service Fee" && item.TYPE !== "FEE"),
      discounts
    );

    return (
      <Modal
        xlarge
        buttonLabel="Save"
        label="Edit Invoice Lines"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <InvoiceLinesForm
          handleSubmit={this.saveInvoice}
          ref={(e) => (this.formikRef = e)}
          lines={items}
          serviceFees={serviceFees}
          discounts={newDiscounts}
        />
      </Modal>
    );
  }
}

InvoiceLinesModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default InvoiceLinesModal;

import React, {Component} from "react";
import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import PurchaseOrderPreview from "../../../../features/operations/supply-chain/purchase-order-preview";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../../redux";
import SendPurchaseOrderModal from "../../../../modals/operations/supply-chain/purchase-orders/send-purchase-order-modal";
import PurchaseOrderRecipientsModal from "../../../../modals/operations/supply-chain/purchase-orders/purchase-order-recipients-modal";
import {
  PurchaseOrderRequests,
  VendorRequests,
} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

const PURCHASE_ORDER_STATUSES = {
  DRAFT: "DRAFT",
  SENT: "SENT",
  DELIVERED: "DELIV",
  COMPLETE: "COMPLETE",
  CANCELLED: "CANCELLED",
};

const TAB_IDS = {
  ITEMS: "items",
  RECIPIENTS: "recipients",
  PREVIEW: "preview",
};

class PurchaseOrderPage extends Component {
  state = {
    purchaseOrder: null,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: uniqueId} = this.props.router.params;
    const purchaseOrder = await PurchaseOrderRequests.fetchPurchaseOrder(uniqueId);
    const vendorContacts = await VendorRequests.fetchVendorContacts(purchaseOrder.VENDOR_ID);
    this.setState({
      purchaseOrder: {
        ...purchaseOrder,
        VENDOR_CONTACTS: vendorContacts,
      },
    });
  }

  async deletePurchaseOrder() {
    const {purchaseOrder} = this.state;
    await PurchaseOrderRequests.deletePurchaseOrder(purchaseOrder.ID);

    this.props.router.navigate("/purchase-orders");
  }

  includeModals() {
    const {purchaseOrder} = this.state;

    return (
      <div>
        <SendPurchaseOrderModal
          purchaseOrder={purchaseOrder}
          ref={(e) => (this.sendPurchaseOrderModal = e)}
          updateState={() => {
            this.syncState();
          }}
        />

        <PurchaseOrderRecipientsModal
          ref={(e) => (this.recipientsModal = e)}
          syncState={() => this.syncState()}
          purchaseOrder={purchaseOrder}
        />
      </div>
    );
  }

  fetchDownloadPDFButton() {
    const {purchaseOrder} = this.state;

    return {
      label: "Download PDF",
      onClick: () => PurchaseOrderRequests.savePurchaseOrderPDF(purchaseOrder.UNIQUE_ID),
    };
  }

  fetchSendPurchaseOrderButton() {
    return {
      label: "Send Purchase Order",
      onClick: () => this.sendPurchaseOrderModal.open(),
    };
  }

  fetchDeletePurchaseOrderButton() {
    return {
      label: "Delete Purchase Order",
      onClick: () => {
        this.deletePurchaseOrder();
      },
    };
  }

  fetchReceivePurchaseOrderButton() {
    const {purchaseOrder} = this.state;

    return {
      label: "Receive by Invoice",
      onClick: () => this.props.router.navigate("/purchase-order-receive/" + purchaseOrder.UNIQUE_ID),
    };
  }

  fetchResendPurchaseOrderButton() {
    return {
      label: "Resend Purchase Order",
      onClick: () => this.sendPurchaseOrderModal.open(),
    };
  }

  fetchDraftPurchaseOrderButtons() {
    return [
      this.fetchDownloadPDFButton(),
      this.fetchSendPurchaseOrderButton(),
      this.fetchDeletePurchaseOrderButton(),
    ];
  }

  fetchDefaultButtonConfig(buttons) {
    return [
      buttons[0],
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: buttons,
          },
        ],
      },
    ];
  }

  fetchPurchaseOrderActionButtons() {
    const {purchaseOrder} = this.state;

    switch (purchaseOrder.STATUS) {
      case PURCHASE_ORDER_STATUSES.DRAFT: {
        return this.fetchDefaultButtonConfig([
          this.fetchSendPurchaseOrderButton(),
          this.fetchDeletePurchaseOrderButton(),
          this.fetchDownloadPDFButton(),
        ]);
      }
      case PURCHASE_ORDER_STATUSES.SENT:
        return this.fetchDefaultButtonConfig([
          this.fetchReceivePurchaseOrderButton(),
          this.fetchResendPurchaseOrderButton(),
          this.fetchDeletePurchaseOrderButton(),
        ]);

      case PURCHASE_ORDER_STATUSES.DELIVERED:
        return this.fetchDefaultButtonConfig([this.fetchReceivePurchaseOrderButton()]);
    }
  }

  fetchPoNumber() {
    const {purchaseOrder} = this.state;
    const poNumber = "" + purchaseOrder.PO_NUMBER;

    return "#" + poNumber.padStart(7 - poNumber.length, "0");
  }

  fetchStatus() {
    const {purchaseOrder} = this.state;

    switch (purchaseOrder.STATUS) {
      case PURCHASE_ORDER_STATUSES.COMPLETE:
        return "Complete";
      case PURCHASE_ORDER_STATUSES.CANCELLED:
        return "Canceled";
      case PURCHASE_ORDER_STATUSES.DELIVERED:
        return "Delivered";
      case PURCHASE_ORDER_STATUSES.SENT:
        return "Sent";
      default:
        return "Draft";
    }
  }

  renderPageHeadings() {
    return (
      <PageHeadings
        label="Purchase Order"
        breadcrumbs={[
          {label: "Operations", url: "/"},
          {label: "Purchase Orders", url: "/purchase-orders"},
        ]}
        buttons={this.fetchPurchaseOrderActionButtons()}
      />
    );
  }

  fetchVendorNameField() {
    const {purchaseOrder} = this.state;
    return {label: "Vendor Name", value: purchaseOrder.VENDOR_NAME};
  }

  fetchPoNumberField() {
    return {
      value: this.fetchPoNumber(),
      label: "Order Number",
    };
  }

  fetchStatusField() {
    return {
      value: this.fetchStatus(),
      label: "Status",
    };
  }

  fetchTotalField() {
    const {purchaseOrder} = this.state;

    return {
      label: "Total Price",
      value: toDollars(purchaseOrder.TOTAL, true),
    };
  }

  fetchPurchaseOrderInformationFields() {
    return [
      this.fetchVendorNameField(),
      this.fetchPoNumberField(),
      this.fetchStatusField(),
      this.fetchTotalField(),
    ];
  }

  isEditable() {
    const {purchaseOrder} = this.state;

    return ![PURCHASE_ORDER_STATUSES.CANCELLED, PURCHASE_ORDER_STATUSES.COMPLETE].includes(
      purchaseOrder.STATUS
    );
  }

  fetchEditInformationButton() {
    const {purchaseOrder} = this.state;

    return {
      label: "Edit Information",

      onClick: () =>
        this.props.router.navigate("/purchase-order-create?purchase_order=" + purchaseOrder.UNIQUE_ID),
    };
  }

  fetchPurchaseOrderInformationButtons() {
    return this.isEditable() ? [this.fetchEditInformationButton()] : [];
  }

  renderPurchaseOrderInformation() {
    return (
      <TwoColumnList
        label="Purchase Order Information"
        data={this.fetchPurchaseOrderInformationFields()}
        buttons={this.fetchPurchaseOrderInformationButtons()}
      />
    );
  }

  fetchItemNameColumn() {
    return {value: "NAME", label: "Vendor Item"};
  }

  fetchItemQuantityColumn() {
    return {value: "QUANTITY", label: "Ordered Quantity"};
  }

  fetchItemTotalColumn() {
    return {value: "TOTAL", label: "Cost Per Item", type: "dollars"};
  }

  fetchItemReceivedQuantityColumn() {
    return {
      value: "RECEIVED_QUANTITY",
      label: "Received Quantity",
      format: (val) => val ?? "Not Yet Received",
    };
  }

  fetchItemReceivedTotalColumn() {
    return {
      value: "RECEIVED_TOTAL",
      label: "Received Total",
      format: (val) => (val ? toDollars(val, true) : "Not Yet Received"),
    };
  }

  fetchItemsTableColumns() {
    return [
      this.fetchItemNameColumn(),
      this.fetchItemQuantityColumn(),
      this.fetchItemTotalColumn(),
      this.fetchItemReceivedQuantityColumn(),
      this.fetchItemReceivedTotalColumn(),
    ];
  }

  renderItemsTable() {
    const {purchaseOrder} = this.state;

    return <Table data={purchaseOrder.ITEMS} columns={this.fetchItemsTableColumns()} />;
  }

  renderItemsTab() {
    return (
      <Card label="Purchase Order Vendor Items" description="See the items on this purchase order">
        {this.renderItemsTable()}
      </Card>
    );
  }

  fetchAddRecipientButton() {
    return {
      label: "Add Recipient",
      onClick: () => this.recipientsModal.open(),
    };
  }

  fetchRecipientsTabButtons() {
    return [this.fetchAddRecipientButton()];
  }

  fetchRecipientFirstNameColumn() {
    return {
      value: "FIRST_NAME",
      label: "Name",
      format: (value, row) => {
        return value + " " + row.LAST_NAME;
      },
    };
  }

  fetchRecipientEmailColumn() {
    return {
      value: "EMAIL",
      label: "Email",
    };
  }

  fetchRecipientPhoneColumn() {
    return {
      value: "PHONE",
      label: "Phone Number",
      format: (phone) => {
        return getFormattedPhone(phone);
      },
    };
  }

  fetchRecipientsTableColumns() {
    return [
      this.fetchRecipientFirstNameColumn(),
      this.fetchRecipientEmailColumn(),
      this.fetchRecipientPhoneColumn(),
    ];
  }

  renderRecipientsTable() {
    const {purchaseOrder} = this.state;

    return (
      <Table
        data={purchaseOrder.RECIPIENTS}
        actionButtons={[]}
        columns={this.fetchRecipientsTableColumns()}
      />
    );
  }

  renderRecipientsTab() {
    return (
      <Card
        label="Purchase Order Recipients"
        description="See the contacts that will receive this purchase order"
        buttons={this.fetchRecipientsTabButtons()}
      >
        {this.renderRecipientsTable()}
      </Card>
    );
  }

  renderPurchaseOrderPreview() {
    const {purchaseOrder} = this.state;

    return <PurchaseOrderPreview purchaseOrder={purchaseOrder} {...this.props} />;
  }

  renderCurrentTab(tab) {
    switch (tab) {
      case TAB_IDS.ITEMS:
        return this.renderItemsTab();
      case TAB_IDS.RECIPIENTS:
        return this.renderRecipientsTab();
      case TAB_IDS.PREVIEW:
        return this.renderPurchaseOrderPreview();
      default:
        return <div />;
    }
  }

  fetchTabDefinitions() {
    return [
      {id: TAB_IDS.ITEMS, label: "Vendor Items"},
      {id: TAB_IDS.RECIPIENTS, label: "Recipients"},
      {id: TAB_IDS.PREVIEW, label: "Preview"},
    ];
  }

  renderTab() {
    return (
      <Tab data={this.fetchTabDefinitions()}>
        {(tab) => {
          return this.renderCurrentTab(tab);
        }}
      </Tab>
    );
  }

  render() {
    const {purchaseOrder} = this.state;

    if (!purchaseOrder) {
      return <Loading />;
    }

    return (
      <div>
        {this.includeModals()}
        {this.renderPageHeadings()}
        {this.renderPurchaseOrderInformation()}
        {this.renderTab()}
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(withRouter(PurchaseOrderPage));

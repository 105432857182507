import {updateLocation} from "./shop";
import {getCurrentPayrun, getPayrollCompany} from "../utils/payroll-helper";
import {request} from "../utils/request";

const defaultState = {
  loaded: false,
  account: null,
  bankAccount: {},
};

const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";

export function updateAccount(payload) {
  return {type: UPDATE_ACCOUNT, payload};
}

export function updateBankAccount(payload) {
  return {type: UPDATE_BANK_ACCOUNT, payload};
}

export function loadBanking() {
  return async (dispatch, getState) => {
    let {account} = getState().banking;

    if (!account) {
      account = await request("/stripe/connect/account", "GET");
      dispatch(updateAccount(account));

      for (let bankAcc in account.external_accounts.data) {
        if (account.external_accounts.data[bankAcc].default_for_currency) {
          let bankAccount = account.external_accounts.data[bankAcc];
          let {account_holder_name, bank_name, routing_number, last4} = bankAccount;

          dispatch(
            updateBankAccount({
              accountHolderName: account_holder_name,
              bankName: bank_name,
              accountNumber: `****${last4}`,
              routingNumber: routing_number,
            })
          );
        }
      }
    }
  };
}

export const bankingReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_ACCOUNT:
      return {...state, account: payload};
    case UPDATE_BANK_ACCOUNT:
      return {...state, bankAccount: payload};
  }
};

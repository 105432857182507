import React, {Component} from "react";
import ComboBox from "../../../components/combobox";
import {
  decimalToDollars,
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LedgerDropdown from "../../../dropdowns/accounting/ledger-dropdown";

class BillLinesForm extends Component {
  addLine() {
    const {values, setFieldValue} = this.props.options;

    setFieldValue("lines", [...values.lines, {}]);
  }

  deleteLine(index) {
    const {values, setFieldValue} = this.props.options;
    const {lines} = values;

    if (lines.length === 1) {
      return;
    }

    lines.splice(index, 1);

    setFieldValue("lines", values.lines);
  }

  readableDollars(amountStr) {
    const cleanedString = amountStr.replace(/[^\d.]/g, "");

    const amount = parseFloat(cleanedString);

    if (amountStr.length !== cleanedString.length || isNaN(amount)) {
      return "";
    }

    const formattedAmount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

    return formattedAmount;
  }

  render() {
    const {options} = this.props;
    const {lines} = options.values;

    const total = lines.reduce((total, line) => total + (line.amount ? decimalToDollars(line.amount) : 0), 0);

    return (
      <div>
        <div style={{minHeight: 200, maxHeight: 600}} className={"overflow-scroll"}>
          <table className="border-b w-full border ">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className=" w-12 py-2 pl-4 pr-2 text-right text-sm font-semibold text-gray-900 sm:pl-6 border border-neutral-border"
                >
                  #
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm font-semibold text-gray-900 border border-neutral-border"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm font-semibold text-gray-900 border border-neutral-border"
                >
                  Description
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-right text-sm font-semibold text-gray-900 border border-neutral-border"
                >
                  Amount
                </th>
                <th scope="col" className="w-10 relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
              </tr>
            </thead>

            <tbody className="w-full">
              {lines.map((line, index) => {
                return (
                  <tr className="border-t bg-white">
                    <td headers="#" className="w-12 ">
                      <div className={"text-sm text-right pr-2"}>{index + 1}</div>
                    </td>

                    <td
                      headers="category"
                      className="border-l border-r-0 border-t-0 border-b-0 border-neutral-border"
                    >
                      {/*<LedgerDropdown*/}
                      {/*  name={`lines.${index}.category`}*/}
                      {/*  options={options}*/}
                      {/*  formLine={true}*/}
                      {/*/>*/}
                      <LedgerDropdown name={`lines.${index}.category`} inLine={true} options={options} />
                    </td>

                    <td
                      headers="description"
                      className="border-l border-r-0 border-t-0 border-b-0 border-neutral-border"
                    >
                      <input
                        type="text"
                        id={"description_" + index}
                        name={"description"}
                        autoComplete="off"
                        key={"description_" + index}
                        value={line.description}
                        data-event-off="mouseleave"
                        data-event="mouseenter click"
                        className="w-full text-sm focus:ring-2 border-0 focus:ring-indigo-500 "
                        onChange={(e) => {
                          const {values, setFieldValue} = this.props.options;

                          const newLines = [...values.lines];
                          newLines[index].description = e.target.value;

                          setFieldValue("lines", newLines);
                        }}
                      />
                    </td>

                    <td
                      headers="amount"
                      className="w-28 border-l border-r-0 border-t-0 border-b-0 border-neutral-border"
                    >
                      <input
                        type="text"
                        id={"amount" + index}
                        value={line.amount}
                        autoComplete="off"
                        data-event-off="mouseleave"
                        data-event="mouseenter click"
                        className="w-full text-sm focus:ring-2 border-0 focus:ring-indigo-500 text-right"
                        onChange={(e) => {
                          const {values, setFieldValue} = this.props.options;

                          const newLines = [...values.lines];
                          newLines[index].amount = e.target.value;

                          setFieldValue("lines", newLines);
                        }}
                        onBlur={(e) => {
                          const {values, setFieldValue} = this.props.options;

                          const newLines = [...values.lines];
                          newLines[index].amount = this.readableDollars(e.target.value);

                          setFieldValue("lines", newLines);
                        }}
                      />
                    </td>

                    <td className="flex w-10 border-l border-r-0 border-t-0 border-b-0 border-neutral-border hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                      <button
                        type="button"
                        className="flex-1 items-center text-indigo-500 hover:text-indigo-600 px-1 py-2 bg-white text-base font-medium  sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          return this.deleteLine(index);
                        }}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={"flex flex-row justify-between my-2 pr-12 mr-1"}>
          <button
            className={
              "flex w-28 text-right text-sm font-bold text-indigo-500 hover:text-indigo-600 transition-all"
            }
            onClick={() => this.addLine()}
            type="button"
          >
            + Add Line
          </button>

          <div className={"text-sm font-bold"}>Total: {toDollars(total, true)}</div>
        </div>
      </div>
    );
  }
}

export default BillLinesForm;

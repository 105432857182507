import React, {Component} from "react";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {ChevronRightIcon, RefreshIcon} from "@heroicons/react/solid";
import ReactDOM from "react-dom";
import {Manager, Popper, Reference} from "react-popper";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SHIFT_VIEWS, VIEWS} from "./scheduler";

const optionsToShow = [
  {label: "Refresh", multi: false},
  {
    label: "Show / Hide",
    popperOptions: ["Show Scheduled Only", "Hide Preferences", "Hide Labor Cost"],
    multi: true,
  },
  {label: "Print Friendly View", multi: false, toggle: false},
  {
    label: "Sort By",
    popperOptions: ["First Name", "Last Name", "Custom"],
    multi: true,
  },
  {label: "Copy Week", multi: false},
  {label: "Shift Tags", multi: false},
  {label: "Templates", multi: false},
  {label: "Clear Drafts", multi: false},
  {label: "Settings", multi: false},
];

class SchedulerDropdown extends Component {
  state = {
    showHide: [],
    showDropdown: false,
    coords: null,
  };

  constructor(props) {
    super(props);

    this.id = "cd_" + randomString(24);

    this.state = {
      showHide: [],
      showDropdown: false,
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", (e) => this.handleClick(e));
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", (e) => this.handleClick(e));
  }

  handleClick = (e) => {
    let {showDropdown} = this.state;

    if (!showDropdown) {
      return;
    }

    const dropdownNode = ReactDOM.findDOMNode(this.dropdownRef);
    const buttonNode = ReactDOM.findDOMNode(this.buttonRef);

    if (dropdownNode && !dropdownNode.contains(e.target) && !buttonNode.contains(e.target)) {
      this.setState({showDropdown: false});
    }
  };

  async handleInnerFunction(option) {
    const {refresh, clearDrafts, openSettings, copy, templateHandler, openShiftTags, togglePrintView} =
      this.props;

    switch (option.label) {
      case "Clear Drafts":
        clearDrafts();
        break;
      case "Refresh":
        await refresh();
        break;
      case "Settings":
        openSettings();
        break;
      case "Copy Week":
        copy();
        break;
      case "Templates":
        templateHandler();
        break;
      case "Shift Tags":
        openShiftTags();
        break;
      case "Print Friendly View":
        togglePrintView();

        const toFindIndex = optionsToShow.findIndex(({label}) => label === "Print Friendly View");

        optionsToShow[toFindIndex].toggle = !optionsToShow[toFindIndex].toggle;

        break;
    }
  }

  selectOption(option) {
    const {
      toggleUnavailability,
      toggleShowEmployees,
      toggleLaborCost,
      handleAlphabeticalOrder,
      refresh,
      openReorder,
    } = this.props;

    switch (option) {
      case "Show Scheduled Only":
        this.handleShowHideToggle(option);
        toggleShowEmployees();
        break;
      case "Hide Preferences":
        this.handleShowHideToggle(option);
        toggleUnavailability();
        break;
      case "Hide Labor Cost":
        this.handleShowHideToggle(option);
        toggleLaborCost();
      case "First Name":
        handleAlphabeticalOrder(1);
        break;
      case "Last Name":
        handleAlphabeticalOrder(2);
        break;
      case "Custom":
        openReorder();
        break;
    }
  }

  handleShowHideToggle(option) {
    let {showHide} = this.state;

    if (!showHide.includes(option)) {
      showHide.push(option);
    } else {
      const idx = showHide.findIndex((item) => item === option);
      showHide.splice(idx, 1);
    }

    this.setState({showHide});
  }

  getOptionsToShow() {
    let {currentView, shiftView} = this.props;

    let currentOptionsToShow = [...optionsToShow];

    if (currentView !== VIEWS.WEEK) {
      currentOptionsToShow = currentOptionsToShow.filter(
        (option) => option.label !== "Copy Week" && option.label !== "Templates"
      );
    }

    if (currentView !== VIEWS.WEEK && currentView !== VIEWS.DAY) {
      currentOptionsToShow = currentOptionsToShow.filter((option) => option.label !== "Clear Drafts");
    }

    if (shiftView === SHIFT_VIEWS.ROLES) {
      currentOptionsToShow[2].popperOptions = ["Custom"];
    } else {
      currentOptionsToShow[2].popperOptions = ["First Name", "Last Name", "Custom"];
    }

    return currentOptionsToShow;
  }

  render() {
    const {currentBox, showDropdown, showHide} = this.state;

    let optionsToShow = this.getOptionsToShow();

    return (
      <div id={this.id}>
        <button
          ref={(e) => (this.buttonRef = e)}
          type="button"
          className="mr-3 border border-gray-300 py-2 px-3 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => this.setState({showDropdown: !showDropdown})}
        >
          <FontAwesomeIcon className="mr-2" icon="gear" />
          Tools
        </button>

        {showDropdown && (
          <ul
            ref={(e) => (this.dropdownRef = e)}
            style={{minWidth: 160}}
            className="mt-2 flex-col z-20 absolute bg-white rounded-md text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {optionsToShow.map((option, index) => {
              const {label, popperOptions, multi, toggle = false} = option;

              return (
                <Manager>
                  <Reference>
                    {({ref}) => (
                      <li
                        ref={ref}
                        key={index}
                        onClick={() => (multi ? console.log("success") : this.handleInnerFunction(option))}
                        className={classNames(
                          multi ? "cursor-default" : "cursor-pointer",
                          "ignoreClose px-3 dropdown relative flex flex-row justify-between py-2 pl-3 text-left bg-white text-gray-900 hover:bg-indigo-600 hover:text-white"
                        )}
                        onMouseEnter={(e) => {
                          const rect = e.target.getBoundingClientRect();
                          this.setState({
                            currentBox: label,
                            coords: {
                              left: rect.x,
                              top: rect.y,
                            },
                          });
                        }}
                      >
                        <div className={classNames("ignoreClose")}>
                          {label}
                          {toggle && <FontAwesomeIcon icon="check" className="ml-2" />}
                        </div>

                        {multi && <ChevronRightIcon className={"ignoreClose h-5 w-5"} />}

                        {multi &&
                          ReactDOM.createPortal(
                            <Popper
                              innerRef={(node) => (this.popperNode = node)}
                              placement={"right-start"}
                              strategy="absolute"
                            >
                              {({ref, style, placement, arrowProps}) => (
                                <ul
                                  ref={ref}
                                  data-placement={placement}
                                  className={classNames(
                                    label === currentBox ? "block" : "hidden",
                                    "cursor-pointer z-10 shadow-md absolute text-gray-700"
                                  )}
                                  style={style}
                                >
                                  {popperOptions.map((option) => (
                                    <li
                                      className="ignoreClose py-2 px-5 text-left bg-white text-gray-900 hover:bg-indigo-600 hover:text-white text-sm"
                                      onClick={() => this.selectOption(option)}
                                    >
                                      {option}
                                      {showHide.includes(option) && (
                                        <FontAwesomeIcon icon="check" className="ml-2" />
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </Popper>,
                            document.querySelector(`#${this.id}`)
                          )}
                      </li>
                    )}
                  </Reference>
                </Manager>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default SchedulerDropdown;

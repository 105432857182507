import BillingModal from "../modals/general/billing-modal";
import moment from "moment-timezone";

export function setupPayroll(data) {
  let payrollItems = [];
  let payrollBadge = 0;
  let {PAYROLL_SUBMITTED, PAYROLL_EMPLOYEES_NEEDED_BEFORE_NEXT_PAYRUN_COUNT, CURRENT_PAYRUN} = data;

  if (!PAYROLL_SUBMITTED) {
    payrollBadge += 1;
    payrollItems.push({
      label: "Submit for Review",
      description: "Gather the required information and submit your location",
      path: "/payroll/onboard",
    });
  }

  if (CURRENT_PAYRUN?.BADGE_STATUS === "needs_run") {
    payrollBadge += 1;
    payrollItems.push({
      label: "Run Payroll",
      description: `You need to run payroll before ${moment(1632182400000).format("h:mm A")} on ${moment(
        CURRENT_PAYRUN.APPROVAL_DEADLINE
      ).format("dddd")}`,
      path: "/payroll",
    });
  }

  if (PAYROLL_EMPLOYEES_NEEDED_BEFORE_NEXT_PAYRUN_COUNT) {
    payrollBadge += PAYROLL_EMPLOYEES_NEEDED_BEFORE_NEXT_PAYRUN_COUNT;
    payrollItems.push({
      label: "Employees Need to Complete Onboarding",
      description: `${PAYROLL_EMPLOYEES_NEEDED_BEFORE_NEXT_PAYRUN_COUNT} ${
        PAYROLL_EMPLOYEES_NEEDED_BEFORE_NEXT_PAYRUN_COUNT > 1 ? "employees need" : "employee needs"
      } to complete their onboarding process before you run payroll`,
      path: "/payroll/employees",
    });
  }

  return {payrollItems, payrollBadge};
}

export function setupOperations(data) {
  let operationsItems = [];
  let operationsBadge = 0;
  let {IS_OPEN_MESSAGES, NUM_LATE_CHECKLISTS, NUM_OUT_OF_STOCK_PRODUCTS} = data;

  if (IS_OPEN_MESSAGES) {
    operationsBadge += 1;
    operationsItems.push({
      label: "Open Customer Messages",
      description: `You have some open conversations with customers.`,
      path: "/chats",
    });
  }

  if (NUM_LATE_CHECKLISTS > 0) {
    operationsBadge += 1;
    operationsItems.push({
      label: "Incomplete Checklists",
      description: `You have ${NUM_LATE_CHECKLISTS} incomplete checklists from this week`,
      path: "/checklists",
    });
  }

  if (NUM_OUT_OF_STOCK_PRODUCTS > 0) {
    operationsBadge += 1;
    operationsItems.push({
      label: "Out of Stock Products",
      description: `You have ${NUM_OUT_OF_STOCK_PRODUCTS} products that are currently out of stock`,
      path: "/inventory",
    });
  }

  return {operationsItems, operationsBadge};
}

export function setupOnboarding(data, refreshFunc) {
  let onboardingItems = [];
  let onboardingBadge = 0;
  let {
    IS_ROLE_CREATED,
    IS_EMPLOYEE_CREATED,
    STRIPE_ACCOUNT_CREATED,
    STRIPE_ACCOUNT_COMPLETE,
    BANK_ACCOUNT_ADDED,
    IS_HOURS_SET,
    ARE_SHOP_IMAGES_SET,
    HAS_FIRST_MENU_ITEM_BEEN_CREATED,
    ADDED_BILLING_INFO,
    HAVE_SHIFTS_EVER_BEEN_PUBLISHED,
    IS_CHECKLIST_CREATED,
  } = data;

  if (!ADDED_BILLING_INFO) {
    let billingModal;

    onboardingItems.push({
      label: (
        <div>
          <BillingModal ref={(e) => (billingModal = e)} refresh={refreshFunc} />
          {"Add Billing Payment Method"}
        </div>
      ),
      description: `Add your payment information for Dripos billing`,
      onClick: () => billingModal.open(),
    });
  }

  if (!STRIPE_ACCOUNT_CREATED) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Setup Stripe for Payouts",
      description: `Setup your Stripe account so you may receive your payouts.`,
      path: "/payouts/settings",
    });
  }

  if (STRIPE_ACCOUNT_CREATED && !STRIPE_ACCOUNT_COMPLETE) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Complete Stripe Onboarding",
      description: `Add the required information to Stripe to receive payouts.`,
      path: "/payouts/settings",
    });
  }

  if (STRIPE_ACCOUNT_CREATED && !BANK_ACCOUNT_ADDED) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Add Bank Account",
      description: `Add your bank account information to receive payouts.`,
      path: "/payouts/settings",
    });
  }

  if (!HAS_FIRST_MENU_ITEM_BEEN_CREATED) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Create Menu",
      description: `Create your store's menu by either creating products or importing an existing menu.`,
      path: "/menu",
    });
  }

  if (!IS_HOURS_SET) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Set Store Hours",
      description: `Set the hours of the day that mobile ordering is available for your location.`,
      path: "/hours",
    });
  }

  if (!ARE_SHOP_IMAGES_SET) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Set Store Images",
      description: `Set the logo and shop image that customers will see when ordering online.`,
      path: "/shop?tab=images",
    });
  }

  if (!IS_ROLE_CREATED) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Create your first role",
      description: `Create your first role to start rolling.`,
      path: "/roles",
    });
  }

  if (!IS_EMPLOYEE_CREATED) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Create your first employee",
      description: `Add your first employee.`,
      path: "/employees",
    });
  }

  // if(!HAVE_SHIFTS_EVER_BEEN_PUBLISHED){
  //   onboardingBadge += 1;
  //   onboardingItems.push({
  //     label: "Publish your first schedule",
  //     description: `Create and publish your first schedule on Dripos.`,
  //     path: "/scheduling",
  //   });
  // }

  if (!IS_CHECKLIST_CREATED) {
    onboardingBadge += 1;
    onboardingItems.push({
      label: "Create your first checklist",
      description: `Create your first checklist to check things off.`,
      path: "/employees",
    });
  }

  return {onboardingItems, onboardingBadge};
}

export function setupMarketing(data) {
  let {IS_EMAIL_CAMPAIGN, IS_TEXT_CAMPAIGN, IS_RETENTION_CAMPAIGN} = data;
  let marketingBadge = 0;
  let marketingItems = [];

  if (!IS_EMAIL_CAMPAIGN) {
    marketingBadge += 1;
    marketingItems.push({
      label: "Create an Email Campaign",
      description: `Start an email campaign to market to customers via email.`,
      path: "/campaigns/email",
    });
  }

  // if (!IS_TEXT_CAMPAIGN) {
  //   marketingBadge += 1;
  //   marketingItems.push({
  //     label: "Create a Text Campaign",
  //     description: `Start a text campaign to market to customers via text.`,
  //     path: "/campaigns/text",
  //   });
  // }
  //
  // if (!IS_RETENTION_CAMPAIGN) {
  //   marketingBadge += 1;
  //   marketingItems.push({
  //     label: "Create a Retention Campaign",
  //     description: `Start a retention campaign to increase your recurring customers.`,
  //     path: "/campaigns/retention",
  //   });
  // }

  return {marketingItems, marketingBadge};
}

export function setupTeam(data) {
  let {NUM_TIMECARDS, NUM_TRADE_REQUESTS, NUM_TIME_OFF_REQUESTS, IS_SCHEDULE_PUBLISHED_NEXT_WEEK} = data;

  let teamItems = [];
  let teamBadge = 0;

  if (NUM_TIMECARDS > 0) {
    teamBadge += NUM_TIMECARDS;
    teamItems.push({
      label: "Review Time Cards",
      description: `You have ${NUM_TIMECARDS} time cards to approve.`,
      path: "/approvals?tab=cards",
    });
  }

  if (NUM_TRADE_REQUESTS > 0) {
    teamBadge += NUM_TRADE_REQUESTS;
    teamItems.push({
      label: "Review Shift Trade Requests",
      description: `You have ${NUM_TRADE_REQUESTS} Shift Trade Requests to approve.`,
      path: "/approvals?tab=trade",
    });
  }

  if (NUM_TIME_OFF_REQUESTS > 0) {
    teamBadge += NUM_TIME_OFF_REQUESTS;
    teamItems.push({
      label: "Review Time Off Requests",
      description: `You have ${NUM_TIME_OFF_REQUESTS} time off requests to approve.`,
      path: "/approvals?tab=timeOff",
    });
  }

  if (IS_SCHEDULE_PUBLISHED_NEXT_WEEK > 0) {
    teamItems.push({
      label: "Publish Next Week's Schedule",
      description:
        "No shifts have been published for next week. Head to the scheduler to create your schedule",
      path: "/scheduling",
    });
  }

  return {teamItems, teamBadge};
}

import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {VendorRequests} from "../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../redux";
import {updateVendor} from "../../redux/supply";

class VendorDropdown extends Component {
  state = {vendors: []};
  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverVendors = await VendorRequests.fetchVendors();

    const data = serverVendors.map((item) => {
      return {
        id: item.ID,
        label: item.NAME,
        ...item,
      };
    });

    this.setState({vendors: data});
  }

  render() {
    const {vendors} = this.state;

    return <ComboBox label="Vendor" {...this.props} data={vendors} />;
  }
}

export default VendorDropdown;

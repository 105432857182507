import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {getStore} from "../redux";
import {removeAlert} from "../redux/alert";
import {Dialog, Transition} from "@headlessui/react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {ExclamationIcon} from "@heroicons/react/solid";
import {Button} from "@frostbyte-technologies/frostbyte-tailwind";

class Alert extends Component {
  state = {open: false};

  componentDidMount() {
    setTimeout(() => {
      this.setState({open: true});
    }, 1);
  }

  removeAlert() {
    getStore().dispatch(removeAlert(this.props));

    return true;
  }

  renderConfirm() {
    const {open} = this.state;
    const {label, loading, description, buttonLabel, buttonClick, buttonReject} = this.props;

    return (
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto ignore-click"
          onClose={() => {
            this.removeAlert() && buttonReject();
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>

                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {label}
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    type="error"
                    className="ml-2"
                    label={buttonLabel}
                    ref={(e) => (this.buttonRef = e)}
                    onClick={() => {
                      if (loading) {
                        this.buttonRef.startLoading();

                        return buttonClick(() => {
                          this.removeAlert();
                        });
                      }

                      this.removeAlert() && buttonClick();
                    }}
                  />

                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => this.removeAlert() && buttonReject()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  }

  render() {
    const {label, description, buttonLabel, closeLabel, large, icon, type} = this.props;
    const {open} = this.state;

    if (type === "confirm") {
      return this.renderConfirm();
    }

    return (
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => this.removeAlert()}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={classNames(
                  large ? "sm:max-w-lg" : "sm:max-w-sm",
                  "relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6"
                )}
              >
                <div>
                  {icon}

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {label}
                    </Dialog.Title>

                    {description && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={classNames(
                    "mt-5 sm:mt-6",
                    buttonLabel && closeLabel ? "sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense" : ""
                  )}
                >
                  {buttonLabel && (
                    <button
                      type="button"
                      className={classNames(
                        type === "error"
                          ? "bg-red-600 hover:bg-red-700 focus:ring-red-500"
                          : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      )}
                      onClick={() => this.removeAlert()}
                    >
                      {buttonLabel}
                    </button>
                  )}

                  {closeLabel && (
                    <button
                      type="button"
                      onClick={() => this.removeAlert()}
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    >
                      {closeLabel}
                    </button>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

Alert.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default Alert;

import React, {Component} from "react";
import {request} from "../../utils/request";
import moment from "moment-timezone";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import FormDateTimeSelect from "../../components/form-date-time-select";
import {
  classNames,
  decimalToDollars,
  parseIdDict,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReportingGraphContainer from "../../features/reporting/graphs/reporting-graph-container";
import ReportingDayOfWeekGraph from "../../features/reporting/graphs/reporting-day-of-week-graph";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ReportingToolTip from "../../features/reporting/graphs/tooltips/reporting-tool-tip";
import {DownloadIcon} from "@heroicons/react/solid";

class IncomeStatementPage extends Component {
  state = {
    startDate: moment().subtract(1, "week").valueOf(),
    endDate: moment().valueOf(),
    report: null,
  };

  async componentDidMount() {
    this.generate();
  }

  async generateReport() {
    const {startDate, endDate} = this.state;

    const report = await request("accounting/reports/income-statement", "GET", {
      START_DATE: moment(startDate).format("YYYY-MM-DD"),
      END_DATE: moment(endDate).format("YYYY-MM-DD"),
    });

    this.setState({report});
  }

  async generate() {
    await Promise.all([this.generateReport(), this.generateGraph()]);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {type: "application/pdf"});
    return blob;
  }

  download() {
    const {startDate, endDate} = this.state;
    request(
      "accounting/reports/income-statement/pdf",
      "GET",
      {
        START_DATE: moment(startDate).format("YYYY-MM-DD"),
        END_DATE: moment(endDate).format("YYYY-MM-DD"),
      },
      {Accept: "application/pdf"}
    ).then((data) => {
      // this.setState({data: btoa(String.fromCharCode(...new Uint8Array(data)))});
      const blob = this.dataURItoBlob(btoa(String.fromCharCode(...new Uint8Array(data))));
      const url = URL.createObjectURL(blob);

      // to open the PDF in a new window
      window.open(url, "_blank");
    });
  }

  async generateGraph() {
    const {startDate, endDate} = this.state;

    const summary = await request("accounting/reports/summary", "GET", {
      START_DATE: moment(startDate).subtract(1, "year").format("YYYY-MM-DD"),
      END_DATE: moment(endDate).format("YYYY-MM-DD"),
      PERIOD: "MONTH",
    });

    this.setState({summary});
  }

  getRows() {
    const {report} = this.state;

    const toReturn = [];

    for (const {name, total_amount} of report.records.filter(
      ({name}) => !["Total Net Sales"].includes(name)
    )) {
      toReturn.push({
        name: name,
        amount: total_amount,
      });
    }

    return toReturn;
  }

  renderTooltip(payload) {
    let rows = [];

    if (!payload) {
      return <div></div>;
    }

    if (payload?.payload?.length > 0) {
      rows = [
        {
          stroke: payload.payload[1]?.fill,
          label: "Revenue",
          value: toDollars(payload.payload[1].payload["revenue"] * 100, true),
        },
        {
          stroke: payload.payload[0]?.fill,
          label: "Expenses",
          value: toDollars(payload.payload[1].payload["expense"] * -100, true),
        },
        {
          stroke: payload.payload[2]?.stroke,
          label: "Net Profit",
          value: toDollars(payload.payload[1].payload["net_profit"] * 100, true),
        },
      ];
    }

    return (
      <ReportingToolTip
        payload={payload.payload}
        datasets={[]}
        rows={rows}
        formatLabel={() => moment(payload?.label).format("MMMM YYYY")}
      />
    );
  }

  renderGraph() {
    let {summary} = this.state;

    summary = summary?.map((entry) => ({
      ...entry,
      expense: -1 * entry.expense,
    }));

    return (
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart data={summary} margin={{left: 25, top: 10}} stackOffset="sign">
          <CartesianGrid strokeDasharray="3 3" vertical={false} />

          <Bar dataKey={"expense"} fill={"#b4b4b4"} barSize={25} stackId={"profitability"} />

          <Bar dataKey={"revenue"} fill={"#6366f1"} barSize={25} stackId={"profitability"} />

          <Line dataKey="net_profit" stroke="#000000" isAnimationActive={false} />

          <XAxis
            dataKey="period"
            axisLine={{stroke: "#cccccc"}}
            tickLine={false}
            tickMargin={10}
            tickLine={{stroke: "#cccccc"}}
            tick={{fontSize: "0.75rem", fontWeight: "bold"}}
            tickFormatter={(tick) => moment(tick).format("MMM YYYY")}
          />

          <YAxis
            tick={{fontSize: "0.75rem", fontWeight: "bold"}}
            tickMargin={10}
            tickFormatter={(tick) => toDollars(tick, true)}
            axisLine={{stroke: "#cccccc"}}
            tickLine={{stroke: "#cccccc"}}
            tickCount={3}
          />

          <Tooltip content={this.renderTooltip} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  getIncomeRows({children, total_amount}) {
    const childrenDict = parseIdDict(children, "name");
    const accountOrder = ["Sales", "Discounts", "Uncategorized Cash Inflow"];

    const accounts = accountOrder.reduce((arr, _key) => {
      if (childrenDict[_key]?.total_amount > 0) {
        arr.push({
          name: childrenDict[_key].name,
          amount: childrenDict[_key].total_amount,
        });
      }

      return arr;
    }, []);

    return [...accounts, {name: "Total Income", amount: total_amount, type: "bold"}];
  }

  getCostOfGoodsSoldRows({total_amount}) {
    return [{name: "Total Cost of Goods Sold", amount: total_amount, type: "bold"}];
  }

  getExpensesRows({children, total_amount}) {
    return [
      ...children
        .filter(({total_amount}) => total_amount > 0)
        .sort((a, b) => b.total_amount - a.total_amount)
        .map(({name, total_amount}) => ({
          name,
          amount: total_amount,
        })),
      {name: "Total Expenses", amount: total_amount, type: "bold"},
    ];
  }

  renderLine(name, value) {
    return (
      <div className={"bg-white rounded-sm shadow-sm justify-between flex flex-row items-center"}>
        <div className={"ml-5 py-2 font-semibold text-sm"}>{name}</div>

        <div className={"mr-6 text-sm font-semibold"}>{toDollars(decimalToDollars(value + ""), true)}</div>
      </div>
    );
  }

  renderBreakdown(name, rows) {
    return (
      <div className={"bg-white"}>
        <div className={"ml-5 py-2 font-semibold text-md"}>{name}</div>

        <Table
          data={rows}
          columns={[
            {
              label: "",
              value: "name",
              format: (val, {type}) => (
                <div
                  className={classNames(
                    "text-black",
                    type === "secondary" && "pl-5",
                    type === "bold" && "font-semibold"
                  )}
                >
                  {val}
                </div>
              ),
            },
            {
              label: `${moment().format("M/D")}`,
              value: "amount",
              width: 1,
              format: (val, {type}) => (
                <div className={classNames("text-black float-right", type === "bold" && "font-semibold")}>
                  {toDollars(decimalToDollars(val + ""), true)}
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }

  renderIncomeStatement() {
    const {startDate, endDate, report} = this.state;

    return (
      <div>
        <ReportingGraphContainer
          label={`${moment(startDate).format("MMM D, YYYY")} - ${moment(endDate).format("MMM D, YYYY")}`}
          stats={[]}
          graph={this.renderGraph()}
        />

        <div className={"space-y-2 mt-5"}>
          {this.renderBreakdown("Income", this.getIncomeRows(report.INCOME))}
          {this.renderBreakdown("Cost of Goods Sold", this.getCostOfGoodsSoldRows(report.COGS))}
          {this.renderLine("Gross Profit", report.GROSS_PROFIT)}
          {this.renderBreakdown("Expenses", this.getExpensesRows(report.EXPENSES))}
          {this.renderLine("Net Profit", report.NET_PROFIT)}
        </div>
      </div>
    );
  }

  render() {
    const {startDate, endDate, report} = this.state;

    console.log(report);

    return (
      <div>
        <PageHeadings label={"Profit & Loss"} />

        <div className={"flex flex-row items-end justify-between"}>
          <div className={"flex flex-row space-x-4"}>
            <FormDateTimeSelect
              label="Start Date"
              hideTime={true}
              value={startDate}
              onChange={(startDate) => this.setState({startDate}, () => this.generate())}
            />

            <FormDateTimeSelect
              label="End Date"
              hideTime={true}
              value={endDate}
              onChange={(endDate) => this.setState({endDate}, () => this.generate())}
            />
          </div>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={() => this.download()}
          >
            <DownloadIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="my-4 h-0.5 w-full bg-gray-200"></div>

        {report && this.renderIncomeStatement()}
      </div>
    );
  }
}

export default IncomeStatementPage;

import {Component} from "react";
import {withRouter} from "../../utils/navigation";

class AdminBanner extends Component {
  render() {
    return (
      <div className="group" onClick={() => this.props.router.navigate("/admin")}>
        <div className="flex justify-center text-center z-50 border-t-4 border-indigo-500 group-hover:border-indigo-700 absolute w-full group-hover:cursor-pointer">
          <div className="z-50 bg-indigo-500 text-white text-sm font-medium px-3 py-1 rounded-b-md group-hover:bg-indigo-700 group-hover:cursor-pointer">
            Admin Mode - Click to go back
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminBanner);

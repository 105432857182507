import React, {Component} from "react";
import PropTypes from "prop-types";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";

class InvoicesTable extends Component {
  fetchLabel(label) {
    const {hideColumnLabels} = this.props;
    return hideColumnLabels ? " ".repeat(label.length) : label;
  }

  render() {
    const {data} = this.props;

    let columns = [
      {
        width: 1,
        minWidth: "10px",
        value: "TICKET",
        label: this.fetchLabel("Total"),
        format: (ticket) => "$" + toDollars(ticket.PAYMENT_INTENT.TOTAL),
      },
      {
        noLeftPad: true,
        value: "CONTACT",
        label: this.fetchLabel("Invoice"),
        format: (_, invoice) => (
          <div>
            <div className="text-sm font-medium text-gray-900">
              {invoice.CONTACT.FIRST_NAME + " " + invoice.CONTACT.LAST_NAME}
            </div>

            <div className="text-sm text-gray-500">#{invoice.INVOICE_NUMBER}</div>
          </div>
        ),
      },
      {
        value: "TESt",
        label: this.fetchLabel("Email Recipients"),
        format: (_, invoice) => {
          const reminders = invoice.REMINDERS.filter((item) => {
            return item.DATE_EXECUTED === null && item.DATE_SCHEDULED > Date.now();
          }).map((item) => moment(item.DATE_SCHEDULED).format("MM/DD/YY"));

          return (
            <div>
              <div className="text-sm font-medium text-gray-900">
                {[invoice.CONTACT, ...invoice.RECIPIENTS].map((item) => item.EMAIL).join(", ")}
              </div>

              <div className="text-sm text-gray-500">
                {reminders.length > 0
                  ? "Upcoming Reminders: " + reminders.join(", ")
                  : "No reminders scheduled"}
              </div>
            </div>
          );
        },
      },
      {
        value: "COMPANY",
        label: "Company",
        format: (_, invoice) => (
          <div>
            <div className="text-sm font-medium text-gray-900">{invoice?.CONTACT?.COMPANY || ""}</div>
          </div>
        ),
      },

      {
        value: "EMAIL",
        label: this.fetchLabel("Status"),
        style: {textAlign: "right"},
        format: (_, invoice) => {
          let status = invoice.STATUS.toLowerCase().capitalize();
          let paidMessage = "No due date";

          if (invoice.STATUS === "PAID") {
            paidMessage = "On " + moment(invoice.TICKET.PAYMENT_INTENT.DATE_PAYED).format("MM/DD/YY");
          } else if (invoice.STATUS === "REFUNDED") {
            if (invoice.TICKET?.PAYMENT_INTENT?.DATE_REFUNDED) {
              paidMessage = "On " + moment(invoice.TICKET.PAYMENT_INTENT.DATE_REFUNDED).format("MM/DD/YY");
            }
          } else if (invoice.DATE_DUE === null) {
            // my feet smell so good
          } else if (invoice.DATE_DUE > Date.now()) {
            paidMessage = "Due in " + Math.abs(moment(invoice.DATE_DUE).diff(Date.now(), "days")) + " days";
          } else if (invoice.DATE_DUE < Date.now()) {
            status = "Overdue";
            paidMessage = "By " + Math.abs(moment(invoice.DATE_DUE).diff(Date.now(), "days")) + " days";
          }

          return (
            <div className="text-left">
              <div className="text-sm font-medium text-gray-900">{status}</div>

              <div className="text-sm text-gray-500">{paidMessage}</div>
            </div>
          );
        },
      },
      {
        value: "DATE_DUE",
        label: this.fetchLabel("Due Date"),
        sortable: true,
        format: (date) => (date ? moment(date).format("MM/DD/YY") : "-"),
      },
      {
        value: "DATE_CREATED",
        label: this.fetchLabel("Date Created"),
        sortable: true,
        format: (created) => moment(created).format("MM/DD/YY"),
      },
    ];

    return (
      <Table
        {...this.props}
        pagination
        rowsPerPage
        cursors={[10, 25, 50, 100]}
        data={data}
        columns={columns}
        ref={(e) => (this.tableRef = e)}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => this.props.router.navigate("/invoice/" + row.UNIQUE_ID),
          },
        ]}
      />
    );
  }
}

InvoicesTable.propTypes = {
  ...Table.propTypes,

  data: PropTypes.array.isRequired,
};

export default withRouter(InvoicesTable);

import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import PayrollImportTable from "../payroll-import-table";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CsvMapper from "../csv-mapper";

class PaycheckBoundsTab extends Component {
  handleClick = () => {
    let steps = this.csvMapper.getSteps();
    let {importData} = this.props.payroll;

    if (steps.filter((step) => step.value).length === steps.length) {
      let firstPaycheckStartRow = steps.find((step) => step.key === "firstPaycheckStart").value.row;
      let firstPaycheckEndRow = steps.find((step) => step.key === "firstPaycheckEnd").value.row;

      let paycheckIncrementer = firstPaycheckEndRow - firstPaycheckStartRow + 1;

      let newImport = {
        steps: {steps: {...importData.steps, ...steps}},
        firstPaycheckStartRow,
        paycheckIncrementer,
      };

      this.props.updateImport(newImport);

      return true;
    }

    return false;
  };

  render() {
    return (
      <CsvMapper ref={(e) => (this.csvMapper = e)} initialSteps={STEPS} handleClick={this.handleClick} />
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(PaycheckBoundsTab));

const STEPS = [
  {label: "Start of first paycheck", key: "firstPaycheckStart"},
  {label: "First paycheck end", key: "firstPaycheckEnd"},
];

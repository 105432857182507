import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  Tooltip,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import CategoryDetailsModal from "../../../../modals/sales/categories/category-details-modal";
import moment from "moment";
import DuplicateCategoryModal from "../../../../modals/sales/duplicate-category-modal";
import ProductsTable from "../../../../tables/sales/products-table";
import {showErrorAlert, showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import PresetModal from "../../../../modals/sales/modifiers/preset-modal";
import SelectProductDiscountModal from "../../../../modals/sales/product/select-product-discount-modal";
import LogoDropZoneModal from "../../../../modals/general/logo-drop-zone-modal";
import CategorySyncModal from "../../../../modals/sales/categories/category-sync-modal";
import TaxesTable from "../../../../tables/sales/taxes-table";
import CategoryTaxModal from "../../../../modals/sales/tax/category-tax-modal";
import {updateStateDelegator} from "../../../../utils/util";
import CategoryDiscountModal from "../../../../modals/sales/discounts/category-discount-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {hasPermission} from "../../../../redux/user";
import CategorySchedulingModal from "../../../../modals/sales/category/category-scheduling-modal";
import {ISO_DAYS, SYNC_STATUS_DICT} from "../../../../utils/constants";
import {setupReduxConnection} from "../../../../redux";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import {cloneDeep} from "lodash";
import PresetProductModal from "../../../../modals/sales/modifiers/preset-product-modal";

const TYPE_DICT = {
  DRIPOS: "Store Order",
  SILKOS: "Ecommerce",
};

class CategoryPage extends Component {
  state = {
    taxes: [],
    products: [],
    schedule: null,
    category: null,
    selectedProducts: [],
    deleteItems: false,
    discounts: [],
    syncStatus: "",
    outOfSyncItems: [],
  };

  componentDidMount() {
    this.syncState();
  }

  getCategoryStatus(category, products) {
    const statuses = [];

    if (!category.SYNC_VERSION) {
      return {STATUS: null};
    }

    const _checkIsOutOfDate = (item) =>
      item.SYNC_VERSION < item.MAX_SYNC_VERSION
        ? SYNC_STATUS_DICT.OUT_OF_DATE
        : item.DATE_UPDATED > item.DATE_SYNCED
        ? SYNC_STATUS_DICT.AHEAD
        : SYNC_STATUS_DICT.SYNCED;

    statuses.push({
      ITEM: cloneDeep(category),
      TYPE: "CATEGORY",
      STATUS: _checkIsOutOfDate(category),
    });

    for (const product of products) {
      statuses.push({
        ITEM: cloneDeep(product),
        TYPE: "PRODUCT",
        STATUS: _checkIsOutOfDate(product),
      });

      for (const customization of product.CUSTOMIZATIONS) {
        statuses.push({
          ITEM: cloneDeep(customization),
          TYPE: "MODIFIER",
          STATUS: _checkIsOutOfDate(customization),
        });

        for (const option of customization.OPTIONS) {
          statuses.push({
            ITEM: cloneDeep(option),
            TYPE: "OPTION",
            STATUS: _checkIsOutOfDate(option),
          });
        }
      }

      for (const availability of product.AVAILABILITY) {
        statuses.push({
          ITEM: cloneDeep(availability),
          TYPE: "AVAILABILITY",
          STATUS: _checkIsOutOfDate(availability),
        });
      }

      for (const defaultObj of product.DEFAULTS) {
        statuses.push({
          ITEM: cloneDeep(defaultObj),
          TYPE: "DEFAULT",
          STATUS: _checkIsOutOfDate(defaultObj),
        });
      }
    }

    const outOfSyncItems = statuses.filter(({STATUS}) => STATUS === SYNC_STATUS_DICT.OUT_OF_DATE);

    const aheadItems = statuses.filter(({STATUS}) => STATUS === SYNC_STATUS_DICT.AHEAD);

    if (outOfSyncItems.length > 0) {
      return {STATUS: SYNC_STATUS_DICT.OUT_OF_DATE, ITEMS: outOfSyncItems};
    } else if (statuses.find(({STATUS}) => STATUS === SYNC_STATUS_DICT.AHEAD)) {
      return {STATUS: SYNC_STATUS_DICT.AHEAD, ITEMS: aheadItems};
    }

    return {STATUS: SYNC_STATUS_DICT.SYNCED, ITEMS: []};
  }

  syncState = async () => {
    const {ID: id} = this.props.router.params;
    const category = await request("category/" + id, "GET", null);

    const [products, taxes, discounts, schedule] = await Promise.all([
      request("category/" + category.ID + "/products", "GET"),
      request("tax/category/" + category.ID, "GET"),
      request("discounts/category/" + category.ID, "GET"),
      request("category/" + category.ID + " /schedule", "GET"),
    ]);

    products.sort((a, b) => {
      return (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ);
    });

    const {STATUS: syncStatus, ITEMS: outOfSyncItems} = this.getCategoryStatus(category, products);

    this.setState({
      products,
      taxes,
      schedule,
      category,
      discounts,
      syncStatus,
      outOfSyncItems,
    });
  };

  async deleteProducts(toDelete) {
    const productStr = toDelete.map((item) => item.NAME).join(", ");
    const {products} = this.state;

    showLoadingConfirmAlert(
      "Delete Products",
      "Are you sure you want to delete the following products: " + productStr
    ).then(async (close) => {
      await request("products", "DELETE", {
        PRODUCTS: toDelete.map((item) => item.ID),
      });

      const deletedSet = new Set(toDelete.map((item) => item.ID));

      this.setState(
        {
          products: products.filter((item) => {
            return !deletedSet.has(item.ID);
          }),
        },
        () => {
          close();
        }
      );
    });
  }

  delete = async (category) => {
    showLoadingConfirmAlert("Delete Category", "Are you sure you want to delete this category?").then(
      async (close) => {
        await request("category/" + category.ID, "DELETE", null);

        close();

        this.props.router.navigate("/categories");
      }
    );
  };

  archiveItems = async () => {
    const {category} = this.state;

    showLoadingConfirmAlert(
      "Archive Category Items & Delete Category",
      "Are you sure you want archive all products in this category and delete the category?"
    ).then(async (close) => {
      await request("category/v2/" + category.ID + "/archive", "DELETE", null);

      close();
      this.props.router.navigate("/categories");
    });
  };

  duplicateItems = async () => {
    const {category} = this.state;

    showLoadingConfirmAlert("Duplicate Items", "Are you sure you want to duplicate this category?")
      .then((close) => {
        request("category/duplicate/" + category.ID, "POST", {})
          .then((newCategory) => {
            this.props.router.navigate("/category/" + newCategory.UNIQUE_ID);
            setTimeout(() => {
              window.location.reload();
            }, 100);
            close();
          })
          .catch((err) => {
            close();
            console.log(err);

            showErrorAlert(
              "Could Not Duplicate",
              "Oops! Something went wrong while trying to duplicate this category. Please contact support for help!",
              "Ok"
            );
          });
      })
      .catch(() => {});
  };

  deleteRate(tax) {
    const {taxes} = this.state;

    showLoadingConfirmAlert(
      "Archive Category Tax Rate",
      "Are you sure you want to delete this tax rate. It will remove all tax from this categories products?"
    ).then(async (close) => {
      await request("tax/category/" + tax.ID, "DELETE", null);

      this.setState({
        taxes: updateStateDelegator(taxes, tax.ID),
      });

      close();
    });
  }

  deleteCategoryDiscount(discount) {
    const {discounts} = this.state;

    showLoadingConfirmAlert(
      "Remove Category Discount",
      "Are you sure you want to remove this category discount? It will remove the discount from all products in this category."
    ).then(async (close) => {
      await request("discounts/category/" + discount.CATEGORY_DISCOUNT_ID, "DELETE", null);

      this.setState({
        discounts: updateStateDelegator(discounts, discount.ID),
      });

      close();
    });
  }

  async sortProducts() {
    const {products} = this.state;

    showLoadingConfirmAlert(
      "Sort Products Alphabetically",
      "Are you sure you want to sort products in this category alphabetically? It will overwrite your current order."
    ).then(async (close) => {
      const sortedProducts = await Promise.all(
        products
          .sort((a, b) => a.NAME.localeCompare(b.NAME))
          .map((value, index) => request("products/" + value.ID, "PATCH", {SEQ: index + 1}))
      );

      this.setState({
        products: sortedProducts,
      });

      close();
    });
  }

  renderSyncStatus() {
    const {syncStatus, outOfSyncItems = []} = this.state;

    if (syncStatus === SYNC_STATUS_DICT.AHEAD) {
      return (
        <div className="flex ml-2 flex-row">
          <DangerBadge className="mr-2">Ahead</DangerBadge>

          <Tooltip
            data={[
              {
                label: "Ahead",
                data: "This category contains changes that have not been synced to other locations",
              },
              {
                label: "Pending Changes",
                data:
                  "The following items have pending changes: " +
                  outOfSyncItems.map(({ITEM: item}) => item.NAME),
              },
            ]}
          />
        </div>
      );
    } else if (syncStatus === SYNC_STATUS_DICT.SYNCED) {
      return (
        <div className="flex ml-2 flex-row">
          <SuccessBadge className="mr-2">Synced</SuccessBadge>

          <Tooltip
            data={[
              {
                label: "Synced",
                data: "This category is properly synced with other locations.",
              },
            ]}
          />
        </div>
      );
    } else if (syncStatus === SYNC_STATUS_DICT.OUT_OF_DATE) {
      return (
        <div className="flex ml-2 flex-row">
          <DangerBadge className="mr-2">Out of Sync</DangerBadge>

          <Tooltip
            data={[
              {
                label: "Behind",
                data: "This category has not been synced with other locations.",
              },
              {
                label: "Missing Changes",
                data:
                  "The following items have not been synced: " +
                  outOfSyncItems.map(({ITEM: item}) => item.NAME),
              },
            ]}
          />
        </div>
      );
    }

    return <div />;
  }

  render() {
    const {products, category, schedule, deleteItems, taxes, discounts, syncStatus, outOfSyncItems} =
      this.state;

    if (category === null) {
      return <Loading />;
    }

    const buttonSections = [
      // {
      //   items: [
      //     {
      //       label: "Duplicate",
      //       onClick: () => this.duplicateModal.open(category),
      //     },
      //   ],
      // },
      {
        items: [{label: "Archive Items", onClick: () => this.archiveItems()}],
      },
      {
        items: [{label: "Duplicate Category", onClick: () => this.duplicateItems()}],
      },
    ];

    if (products.length === 0) {
      buttonSections.push({
        items: [
          {
            label: "Delete Category",
            onClick: () => this.delete(category),
          },
        ],
      });
    }

    const headerButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: buttonSections,
      },
    ];

    if (hasPermission("MENU_SYNC_FULL")) {
      headerButtons.unshift({
        label: "Sync Category",
        onClick: () => this.syncModal.open(category),
      });
    }

    return (
      <div>
        <CategoryDetailsModal
          ref={(e) => (this.categoryDetailsModal = e)}
          syncStatus={syncStatus}
          updateState={(e) => this.setState(e)}
        />

        <CategoryTaxModal
          ref={(e) => (this.taxModal = e)}
          addState={(tax) => this.setState({taxes: [...taxes, tax]})}
          updateState={(id) =>
            this.setState({
              taxes: updateStateDelegator(taxes, id),
            })
          }
          exclude={taxes.map((tax) => tax.TAX_ID)}
        />

        <CategorySchedulingModal
          ref={(e) => (this.categorySchedulingModal = e)}
          category={category}
          syncState={() => this.syncState()}
        />

        <CategoryDiscountModal
          ref={(e) => (this.categoryDiscountModal = e)}
          updateState={async (discount) => {
            this.setState({discounts: [...discounts, discount]});
          }}
          exclude={discounts.map((d) => d.ID)}
          category={category}
        />

        <LogoDropZoneModal
          ref={(e) => (this.dropZoneModal = e)}
          updateState={async (logo) => {
            const {selectedProducts} = this.state;

            await request("products/logos", "PATCH", {
              PRODUCTS: selectedProducts,
              LOGO: logo,
            });

            this.syncState();
          }}
        />

        <SelectProductDiscountModal
          ref={(e) => {
            this.selectDiscountModal = e;
          }}
          remove={deleteItems}
          updateState={async (values, close) => {
            const {selectedProducts} = this.state;
            const {discount} = values;
            if (deleteItems) {
              await request("product/discount/products/" + discount, "DELETE", {
                PRODUCTS: selectedProducts,
              });
            } else {
              await request("product/discount/" + discount, "POST", {
                PRODUCTS: selectedProducts,
              });
            }

            close();
          }}
        />

        <PresetModal
          ref={(e) => (this.presetModal = e)}
          updateState={async ({type, modifier}, close) => {
            const {selectedProducts} = this.state;

            if (deleteItems) {
              await request("preset/" + modifier + "/remove", "DELETE", {
                PRODUCTS: selectedProducts,
              });
            } else {
              await request("v2/preset/" + modifier + "/products", "PUT", {
                PRODUCTS: selectedProducts,
              });
            }

            close();
          }}
        />

        <DuplicateCategoryModal ref={(e) => (this.duplicateModal = e)} />

        <CategorySyncModal ref={(e) => (this.syncModal = e)} updateState={() => this.syncState()} />

        <PresetProductModal
          ref={(e) => (this.addProductModal = e)}
          label="Select Category Product"
          description="Select a product to add to this category"
          dropdownLabel="Category Product"
          exclude={products.map((p) => p.ID)}
          updateState={async ({products: _products}, callback) => {
            await Promise.all(
              _products.map((_product) => {
                return request("products/" + _product, "PATCH", {
                  CATEGORY_ID: category.ID,
                });
              })
            );

            const products = await request("category/" + category.ID + "/products", "GET");

            this.setState({products});
            callback();
          }}
        />

        <PageHeadings
          label={category?.NAME}
          buttons={headerButtons}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Menu Page", url: "/menu"},
            {label: "Categories Page", url: "/categories"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.categoryDetailsModal.open(category, schedule),
            },
          ]}
          label={
            <div className={"flex flex-row items-center"}>
              {`${category?.NAME} Information`} {this.renderSyncStatus()}
            </div>
          }
          description="Information about this product category"
          data={[
            {label: "Name", value: category.NAME},
            {label: "Type", value: TYPE_DICT[category.TYPE]},
            {
              label: "Enable on Point of Sale",
              value: category.POS_ENABLED,
              type: "bool",
            },
            {
              label: "Enable on Mobile",
              value: category.APP_ENABLED,
              type: "bool",
            },
            {
              label: "Enable on Kiosk",
              value: category.KIOSK_ENABLED,
              type: "bool",
            },
            {
              label: "Enable on Third Party",
              value: category.THIRD_PARTY_ENABLED,
              type: "bool",
            },
            {
              label: "Scheduled Category",
              value: schedule.length > 0 ? "Yes" : "No",
            },
            {
              label: "Auto Complete Products",
              tooltip:
                "When enabled, products in this category will not be displayed on the Ticket Screen. This only applies to orders placed on the POS.",
              value: category.AUTO_COMPLETE,
              type: "bool",
            },
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Products", id: "products"},
            {label: "Tax Rates", id: "taxes"},
            {label: "Discounts", id: "discounts"},
            {label: "Schedule", id: "schedule"},
          ]}
        >
          {(id) => {
            if (id === "taxes") {
              return (
                <Card
                  label="Tax Rates"
                  description="The default tax rates that will get applied to products in this category"
                  button={{
                    label: "Add Tax Rate",
                    onClick: () => this.taxModal.open(category),
                  }}
                >
                  <TaxesTable
                    taxes={taxes.map((item) => ({
                      ...item.TAX_RATE,
                      ID: item.ID,
                    }))}
                    actionButtons={[
                      {
                        label: "Delete Rate",
                        onClick: (tax) => this.deleteRate(tax),
                      },
                      {
                        label: "View Tax Rate",
                        onClick: (row) => {
                          this.props.router.navigate("/tax/" + row.UNIQUE_ID);
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "products") {
              return (
                <Card
                  label="Products"
                  description="Products that are in this category"
                  buttons={[
                    {
                      label: "Add Product",
                      onClick: () => {
                        this.addProductModal.open();
                      },
                    },
                    {
                      label: "Create Product",
                      onClick: () => this.props.router.navigate("/product?category=" + category.ID),
                    },
                    {
                      label: "Sort Products",
                      onClick: () => this.sortProducts(),
                    },
                  ]}
                >
                  <ProductsTable
                    data={products}
                    pagination
                    selectable
                    updateProduct={(serverProduct) => {
                      const productIndex = products.findIndex((item) => {
                        return item.ID === serverProduct.ID;
                      });

                      if (productIndex !== -1) {
                        products.splice(productIndex, 1, {
                          ...products[productIndex],
                          ...serverProduct,
                        });
                      }

                      const {ITEMS, STATUS} = this.getCategoryStatus(category, products);

                      this.setState({
                        products,
                        syncStatus: STATUS,
                        outOfSyncItems: ITEMS,
                      });
                    }}
                    updatePricing={() => this.syncState()}
                    updateInventory={({product, inventory}) => {
                      const productIndex = products.findIndex((item) => {
                        return item.ID === product.ID;
                      });

                      if (productIndex !== -1) {
                        products.splice(productIndex, 1, {
                          ...products[productIndex],
                          INVENTORY: inventory.QUANTITY,
                        });
                      }

                      this.setState({products});
                    }}
                    selectButtons={[
                      {
                        label: "Delete Product(s)",
                        onClick: (products) => {
                          this.deleteProducts(products);
                        },
                      },
                      {
                        label: "Add Global Modifier",
                        onClick: (products) => {
                          this.setState(
                            {
                              selectedProducts: products.map((product) => product.ID),
                              deleteItems: false,
                            },
                            () => {
                              this.presetModal.open();
                            }
                          );
                        },
                      },
                      {
                        label: "Delete Global Modifier",
                        onClick: (products) => {
                          this.setState(
                            {
                              selectedProducts: products.map((product) => product.ID),
                              deleteItems: true,
                            },
                            () => {
                              this.presetModal.open();
                            }
                          );
                        },
                      },
                      {
                        label: "Add Product Discount",
                        onClick: (products) => {
                          this.setState(
                            {
                              selectedProducts: products.map((product) => product.ID),
                              deleteItems: false,
                            },
                            () => {
                              this.selectDiscountModal.open();
                            }
                          );
                        },
                      },
                      {
                        label: "Delete Product Discount",
                        onClick: (products) => {
                          this.setState(
                            {
                              selectedProducts: products.map((product) => product.ID),
                              deleteItems: true,
                            },
                            () => {
                              this.selectDiscountModal.open();
                            }
                          );
                        },
                      },
                      {
                        label: "Add Product Logo",
                        onClick: (products) => {
                          this.setState(
                            {
                              selectedProducts: products.map((product) => product.ID),
                              deleteItems: false,
                            },
                            () => {
                              this.dropZoneModal.open();
                            }
                          );
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "discounts") {
              return (
                <Card
                  label="Discounts"
                  description="Attach discounts to products in this category"
                  tooltip={{
                    label: "Category Discounts",
                    data: "Adding a discount to a category will allow you to apply the discount to any product in the category on the POS. You can still manually remove these discounts from the products they are attached to from the product's page or the product discount's page.",
                  }}
                  button={{
                    label: "Add Discount",
                    onClick: () => this.categoryDiscountModal.open(),
                  }}
                >
                  <Table
                    pagination
                    data={discounts}
                    ref={(e) => (this.discountTableRef = e)}
                    actionButtons={[
                      {
                        label: "Remove",
                        onClick: (row) => this.deleteCategoryDiscount(row),
                      },
                    ]}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                      },
                      {
                        label: "Amount",
                        value: "CONTENT",
                        format: (value, row) => (row.TYPE === 0 ? `$${toDollars(value)}` : `${value}%`),
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "schedule") {
              return (
                <Card
                  label="Schedule"
                  description="The scheduling for this category"
                  button={{
                    label: "Add Schedule",
                    onClick: () => this.categorySchedulingModal.open(),
                  }}
                >
                  <Table
                    data={schedule}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.categorySchedulingModal.open(row),
                      },
                    ]}
                    columns={[
                      {
                        value: "ISO_DAY",
                        label: "Day of the Week",
                        format: (day) => (day !== null ? ISO_DAYS[day] : "Every day"),
                      },
                      {
                        value: "DATE_OPEN",
                        label: "Start",
                        format: (start) =>
                          moment().startOf("day").add(start, "milliseconds").format("hh:mmA"),
                      },
                      {
                        value: "DATE_CLOSE",
                        label: "End",
                        format: (end) => moment().startOf("day").add(end, "milliseconds").format("hh:mmA"),
                      },
                    ]}
                  />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(CategoryPage));

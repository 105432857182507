import React, {Component} from "react";
import TaxesTable from "../../../../../tables/sales/taxes-table";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../../utils/request";
import {showLoadingConfirmAlert} from "../../../../../utils/alert-helper";
import {updateStateDelegator} from "../../../../../utils/util";
import ProductTaxModal from "../../../../../modals/sales/tax/product-tax-modal";

class ProductTaxesTab extends Component {
  state = {taxes: []};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {product} = this.props;
    const taxes = await request("tax/product/" + product.ID, "GET", null);
    this.setState({taxes});
  }

  deleteRate(tax) {
    const {taxes} = this.state;

    showLoadingConfirmAlert(
      "Archive Product Tax Rate",
      "Are you sure you want to delete this tax rate?"
    ).then(async (close) => {
      await request("tax/product/" + tax.ID, "DELETE", null);

      this.setState({
        taxes: updateStateDelegator(taxes, tax.ID),
      });

      close();
    });
  }

  render() {
    const {taxes} = this.state;
    const {product} = this.props;

    return (
      <>
        <ProductTaxModal
          ref={(e) => (this.taxModal = e)}
          addState={(tax) => this.setState({taxes: [...taxes, tax]})}
          updateState={(id) =>
            this.setState({
              taxes: updateStateDelegator(taxes, id),
            })
          }
          exclude={taxes.map((tax) => tax.TAX_ID)}
        />

        <Card
          label="Tax Rates"
          description="The default tax rates that will get applied this product"
          button={{
            label: "Add Tax Rate",
            onClick: () => this.taxModal.open(product),
          }}
        >
          <TaxesTable
            taxes={taxes.map((item) => ({
              ...item.TAX_RATE,
              ID: item.ID,
            }))}
            actionButtons={[
              {
                label: "Delete Rate",
                onClick: (tax) => this.deleteRate(tax),
              },
              {
                label: "View Tax Rate",
                onClick: (row) => this.props.router.navigate("/tax/" + row.UNIQUE_ID),
              },
            ]}
          />
        </Card>
      </>
    );
  }
}

export default ProductTaxesTab;

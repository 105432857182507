import React, {Component} from "react";
import {Formik} from "formik";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import * as Yup from "yup";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import FormTimeSelect from "../../../../components/form-time-select";
import {ISO_DAYS} from "../../../../utils/constants";
import {IngredientRulesRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import LocationsDropdown from "../../../../dropdowns/team/locations-dropdown";
import {getStore, setupReduxConnection} from "../../../../redux";
import {upsertIngredient} from "../../../../redux/supply";
import {upsert} from "../../../../utils/util";
import FormCheckbox from "../../../../components/form-elements/form-checkbox";

const STOCK_TYPES = {
  ADD: "ADD",
  RESET: "RESET",
};

const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

class IngredientRulesModal extends Component {
  state = {rule: null};

  open(rule = null) {
    this.setState({rule}, () => this.modal.open());
  }

  fetchInitialValues(rule) {
    const {companyLocations: locations = []} = this.props.shop;

    return {
      days: this.convertToDaysAndTime(rule?.CRON)?.DAYS ?? [],
      quantity: rule?.QUANTITY ?? 1,
      unit: rule?.UNIT_ID ?? null,
      time: this.convertToDaysAndTime(rule?.CRON)?.TIME ?? 0,
      locations: rule?.LOCATIONS.map((l) => l.ID) ?? [],
      type: rule?.TYPE ?? STOCK_TYPES.ADD,
      recordWaste: rule?.RECORD_WASTE ?? false,
    };
  }

  fetchValidationSchema() {
    return Yup.object().shape({
      days: Yup.array().min(1, "Please select a frequency."),
      quantity: Yup.number().nullable().required("Please select the quantity you'd like to add"),
      unit: Yup.number().nullable().required("Please select the unit"),
      locations: Yup.array().min(1, "Please select a location"),
      time: Yup.number().nullable().required("Please select the time you'd like stock events to occur."),
      type: Yup.string().nullable().required("Please select the way the stocking should behave."),
      recordWaste: Yup.boolean(),
    });
  }

  convertToCron(days, time) {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const daysOfWeek = days.join(",");

    return `${minutes} ${hours} * * ${daysOfWeek}`;
  }

  convertToDaysAndTime(cron) {
    if (!cron) {
      return null;
    }

    const [minutes, hours, dayOfMonth, month, daysOfWeek] = cron.split(" ");

    return {
      DAYS: daysOfWeek.split(",").map((day) => parseInt(day)),
      TIME: parseInt(hours) * 60 + parseInt(minutes),
    };
  }

  fetchPayload(values, ingredient) {
    const {time, days, quantity, unit, type, locations, recordWaste} = values;

    const cron = this.convertToCron(days, time);

    return {
      QUANTITY: quantity,
      UNIT_ID: unit,
      TYPE: type,
      LOCATIONS: locations,
      CRON: cron,
      INGREDIENT_ID: ingredient.ID,
      RECORD_WASTE: recordWaste,
    };
  }

  async sendUpdateRuleRequest(id, payload) {
    try {
      return IngredientRulesRequests.updateRule(id, payload);
    } catch (e) {
      showErrorNotification("Error updating rule");
    }
  }

  async updateRule(values) {
    const {ingredient} = this.props;
    const {rule} = this.state;

    const payload = this.fetchPayload(values, ingredient);
    const serverRule = await this.sendUpdateRuleRequest(rule.ID, payload);

    getStore().dispatch(upsertIngredient({...ingredient, RULES: upsert(ingredient.RULES, serverRule)}));

    this.modal.close();
  }

  async sendCreateRuleRequest(payload) {
    const {ingredient} = this.props;

    try {
      const rule = await IngredientRulesRequests.createRule(payload);

      getStore().dispatch(upsertIngredient({...ingredient, RULES: [...ingredient.RULES, rule]}));

      showSuccessNotification("Rule created successfully.");
    } catch (e) {
      showErrorNotification("Error creating ingredient stocking rule.");
    } finally {
      this.modal.close();
    }
  }

  async createRule(values) {
    const {ingredient} = this.props;
    const payload = this.fetchPayload(values, ingredient);
    return this.sendCreateRuleRequest(payload);
  }

  async sendDeleteRuleRequest(id) {
    try {
      return IngredientRulesRequests.deleteRule(id);
    } catch (e) {
      showErrorNotification("Error deleting rule");
    }
  }

  async deleteRule() {
    const {rule} = this.state;
    const {ingredient} = this.props;

    await this.sendDeleteRuleRequest(rule.ID);

    getStore().dispatch(
      upsertIngredient({
        ...ingredient,
        RULES: ingredient.RULES.filter((_rule) => _rule.ID !== rule.ID),
      })
    );

    this.modal.close();
  }

  renderQuantityInput(formikOptions) {
    return (
      <FormInput
        label="Quantity"
        name="quantity"
        options={formikOptions}
        tooltip="The quantity of the selected unit to stock."
      />
    );
  }

  renderUnitDropdown(formikOptions) {
    const {ingredient} = this.props;

    return (
      <UnitDropdown
        label="Unit"
        name="unit"
        tooltip="The unit you are measuring the quantity in."
        options={formikOptions}
        ingredient={ingredient}
        unit={ingredient.UNIT_ID}
      />
    );
  }

  renderTypeDropdown(formikOptions) {
    return (
      <FormSelect
        label="Stocking Type"
        name="type"
        options={formikOptions}
        data={[
          {value: STOCK_TYPES.RESET, label: "Reset"},
          {value: STOCK_TYPES.ADD, label: "Add"},
        ]}
        tooltip={{
          data: [
            {
              label: "Reset",
              data:
                "The current stock of the ingredient will reset to the specified value upon execution " +
                "with the default cost per unit.",
            },
            {
              label: "Add",
              data:
                "The specified value will be added to the current stock upon execution " +
                "with the default cost per unit.",
            },
          ],
        }}
      />
    );
  }

  renderWasteToggle(formikOptions) {
    return (
      <FormCheckbox
        className={"pb-4"}
        name={"recordWaste"}
        label={"Record Waste"}
        options={formikOptions}
        onChange={(value) => this.formikRef.setFieldValue("recordWaste", value)}
        tooltip={"When stock is reset, record removed stock as waste."}
      />
    );
  }

  renderLocationSelector(formikOptions) {
    return (
      <LocationsDropdown
        label="Locations"
        name="locations"
        options={formikOptions}
        tooltip="Locations that will be stocked by this rule."
        isCompany
        multi
      />
    );
  }

  renderTimeSelector(formikOptions) {
    return (
      <FormTimeSelect
        options={formikOptions}
        label="Time"
        name="time"
        tooltip="The time of day when the stock should execute"
      />
    );
  }

  fetchDaysOptions() {
    return DAYS.map((day, i) => ({value: i, label: day}));
  }

  renderDaySelector(formikOptions) {
    return (
      <FormSelect
        multi
        data={this.fetchDaysOptions()}
        label="Days"
        name="days"
        tooltip="The days of the week you would like the rule to execute on."
        options={formikOptions}
      />
    );
  }

  renderFormBody(formikOptions) {
    return (
      <form>
        {this.renderQuantityInput(formikOptions)}
        {this.renderUnitDropdown(formikOptions)}
        {this.renderTypeDropdown(formikOptions)}
        {formikOptions?.values?.type === "RESET" && this.renderWasteToggle(formikOptions)}
        {this.renderLocationSelector(formikOptions)}
        {this.renderTimeSelector(formikOptions)}
        {this.renderDaySelector(formikOptions)}
      </form>
    );
  }

  renderFormik(rule) {
    return (
      <Formik
        innerRef={(e) => (this.formikRef = e)}
        initialValues={this.fetchInitialValues(rule)}
        validationSchema={this.fetchValidationSchema()}
        onSubmit={rule ? this.updateRule.bind(this) : this.createRule.bind(this)}
      >
        {(formikOptions) => {
          return this.renderFormBody(formikOptions);
        }}
      </Formik>
    );
  }

  render() {
    const {rule} = this.state;

    return (
      <Modal
        large
        formikOnClick={() => this.formikRef}
        label={rule ? "Edit Rule" : "Create Rule"}
        closeLabel="Close"
        buttonLabel={rule ? "Save" : "Create"}
        deleteOnClick={this.deleteRule.bind(this)}
        deleteLabel="Delete"
        ref={(e) => (this.modal = e)}
      >
        {this.renderFormik(rule)}
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(IngredientRulesModal);

import {Modal, FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../../redux";
import {request} from "../../../../utils/request";
import FormCategorySelect from "../../../../components/form-category-select";
import FormProductSelect from "../../../../components/form-product-select";
import * as Yup from "yup";
import {EARN_RULE_TYPES} from "../../../../utils/loyalty-constants";

class EarningRuleModal extends Component {
  state = {rule: null};

  open(rule = null) {
    this.setState({rule}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    const {rule} = this.state;
    const {
      name: NAME,
      pointAmount: POINT_AMOUNT,
      type: TYPE,
      categories: CATEGORIES,
      products: PRODUCTS,
      isFlatAmount: IS_FLAT,
    } = values;
    const IS_MULTIPLIER = IS_FLAT === 1;

    const body = {NAME, TYPE, POINT_AMOUNT, IS_MULTIPLIER};

    // product discount
    if (TYPE === EARN_RULE_TYPES.PRODUCT) {
      body.PRODUCTS = PRODUCTS;
    }

    // Category discount
    if (TYPE === EARN_RULE_TYPES.CATEGORY) {
      body.CATEGORIES = CATEGORIES;
    }

    if (rule) {
      await request("loyalty/rules/earn/" + rule.ID, "PATCH", body);
    } else {
      await request("loyalty/rules/earn", "POST", body);
    }
    this.props.onChange && this.props.onChange();
    this.modal.close();
  };

  handleDelete = async () => {
    const {ID} = this.state.rule;

    await request("loyalty/rules/earn/" + ID, "DELETE", null);

    this.props.onChange && this.props.onChange();
    this.modal.close();
  };

  render() {
    const {rule} = this.state;
    const {LOYALTY_POINTS_VERBIAGE} = this.props.shop.companySettings;
    const terminology = LOYALTY_POINTS_VERBIAGE
      ? LOYALTY_POINTS_VERBIAGE.charAt(0).toUpperCase() + LOYALTY_POINTS_VERBIAGE.slice(1)
      : "Points";

    const schema = Yup.object({
      name: Yup.string().nullable().required("Rule Name is required."),
      type: Yup.string().nullable().required("Earnings Type is required."),
      pointAmount: Yup.number()
        .nullable()
        .required(`${terminology} Amount is required.`)
        .typeError(`${terminology} Amount must be a number.`),
      isFlatAmount: Yup.number().nullable().required("Flat Amount is required."),
      categories: Yup.array().nullable(),
      products: Yup.array().nullable(),
    });

    return (
      <Modal
        label="Earning Rule"
        buttonLabel={rule ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        deleteLabel={rule ? "Delete" : null}
        deleteOnClick={this.handleDelete}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: rule?.NAME,
            type: rule?.TYPE,
            pointAmount: rule?.POINT_AMOUNT,
            isFlatAmount: rule?.IS_MULTIPLIER ? 1 : 0,
            categories: rule?.CATEGORIES ? rule.CATEGORIES.map((cat) => cat.CATEGORY_ID || cat.ID) : [],
            products: rule?.PRODUCTS ? rule.PRODUCTS.map((prod) => prod.PRODUCT_ID || prod.ID) : [],
          }}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Rule Name"
                  name="name"
                  options={formikOptions}
                  placeholder={"Earn 10 points per Espresso Drink"}
                  tooltip={{
                    data: `This will be displayed to the user to explain how they can earn their ${terminology}. E.g. "Earn 10 points per Espresso Drink"`,
                  }}
                />

                <FormInput
                  label={`${terminology} Amount`}
                  name="pointAmount"
                  options={formikOptions}
                  placeholder={`15 ${terminology}`}
                  tooltip={{
                    data: `The amount of ${terminology} to be earned for this rule.`,
                  }}
                />

                <FormSelect
                  label="Earnings Type"
                  name="type"
                  data={REWARD_TYPES}
                  options={formikOptions}
                  tooltip={{
                    data: REWARD_TYPES,
                  }}
                />

                <FormSelect
                  label="Flat Amount"
                  name="isFlatAmount"
                  data={[
                    {label: "Flat Amount", value: 0},
                    {label: "Multiplier", value: 1},
                  ]}
                  options={formikOptions}
                  tooltip={{
                    data: [
                      {
                        label: "Flat Amount",
                        data: `If flat amount is selected, then this rule will apply a constant number of ${terminology} for each order satisfying this rule. "${terminology} Amount" will be the amount added to the Patron's total.`,
                      },
                      {
                        label: "Multiplier",
                        data: `If multiplier is selected, then "${terminology} Amount" will be interpreted as a multiplier. For example, if "${terminology} Amount" is 2, then patrons will earn 2x ${terminology} for each dollar spent that satisfies this rule.`,
                      },
                    ],
                  }}
                />

                {values.type === EARN_RULE_TYPES.CATEGORY && (
                  <FormCategorySelect
                    label="Categories"
                    name="categories"
                    options={formikOptions}
                    tooltip={{
                      data: "Select which categories this earning rule should apply to.",
                    }}
                    multi
                  />
                )}

                {values.type === EARN_RULE_TYPES.PRODUCT && (
                  <FormProductSelect
                    label="Products"
                    name="products"
                    options={formikOptions}
                    tooltip={{
                      data: "Select which products this earning rule should apply to.",
                    }}
                    multi
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const REWARD_TYPES = [
  {
    label: "Order Based",
    value: EARN_RULE_TYPES.TICKET,
    data: "With this reward type, earnings are accrued for each order at a flat rate per order.",
  },
  {
    label: "Product Based",
    value: EARN_RULE_TYPES.PRODUCT,
    data: "With this reward type, earnings are accrued based on orders of specific products.",
  },
  {
    label: "Category Based",
    value: EARN_RULE_TYPES.CATEGORY,
    data: "With this reward type, earnings are accrued based on orders of products in specific categories.",
  },
];

export default setupReduxConnection(["shop"], null, {forwardRef: true})(EarningRuleModal);

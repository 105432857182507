import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {PERMISSIONS} from "../../utils/constants";
import GrayBadge from "../../components/badges/gray-badge";

class MarketingSideNav extends Component {
  render() {
    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title="Marketing"
              permissions={[PERMISSIONS.MARKETING.FULL.id]}
              navigation={[
                {
                  live: true,
                  id: "campaigns",
                  name: (
                    <div className={"flex flex-row items-center"}>
                      <div>Campaigns</div>

                      <GrayBadge darkGrey className={"ml-3"}>
                        Beta
                      </GrayBadge>
                    </div>
                  ),
                  permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
                  children: [
                    {
                      live: true,
                      id: "campaign-lists",
                      name: "Campaign List",
                      href: "/campaigns",
                      permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
                    },
                    {
                      live: true,
                      id: "subscriber-lists",
                      name: "Subscribers",
                      href: "/subscriber-lists",
                      permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
                    },
                    {
                      live: true,
                      id: "credits",
                      name: "Credits",
                      href: "/credits",
                      permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
                    },
                  ],
                },

                {
                  live: true,
                  id: "deals",
                  name: "Deals",
                  href: "/deals",
                  permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
                  children: [
                    {
                      live: true,
                      name: "Coupons",
                      href: "/coupons",
                      paths: ["/coupons", "/coupon"],
                      permissions: [],
                    },
                    {
                      live: true,
                      name: "Promotions",
                      href: "/promotions",
                      paths: ["/promotions", "/promotion"],
                      permissions: [],
                    },
                  ],
                },
                {
                  live: true,
                  id: "loyalty",
                  name: "Loyalty",
                  href: "/loyalty",
                  permissions: [PERMISSIONS.MARKETING.LOYALTY_FULL.id],
                  children: [
                    {
                      live: true,
                      name: "Redemptions",
                      href: "/redemptions",
                      paths: ["/redemptions"],
                      permissions: [],
                    },
                    {
                      live: true,
                      name: "Rules",
                      href: "/rules",
                      paths: ["/rules"],
                      permissions: [],
                    },
                  ],
                },
                {
                  live: true,
                  id: "gifts",
                  name: "Gift Cards",
                  href: "/gifts",
                  permissions: [PERMISSIONS.MARKETING.GIFT_CARDS_FULL.id],
                },
                {
                  live: true,
                  id: "patrons",
                  name: "Patrons",
                  href: "/patrons",
                  paths: ["/patrons", "/patron"],
                  permissions: [PERMISSIONS.MARKETING.PATRONS_FULL.id],
                },
                {
                  live: true,
                  id: "fundraisers",
                  name: "Fundraisers",
                  href: "/fundraisers",
                  permissions: [PERMISSIONS.MARKETING.FUNDRAISERS_FULL.id],
                },
                // {
                //   live: true,
                //   id: "events",
                //   name: "Events",
                //   href: "/events",
                //   paths: ["/events", "/event"],
                //   permissions: [PERMISSIONS.MARKETING.EVENTS_FULL.id],
                // },
              ]}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default MarketingSideNav;

import React, {Component} from "react";
import {PageHeadings, Tab, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {getCheckContractorOnboardingLink} from "../../../../utils/payroll-helper";
import LoadingSpinner from "../../../../components/loading-spinner";
import {Download} from "react-feather";
import {withRouter} from "../../../../utils/navigation";
import b64toBlob from "b64-to-blob";

class PayrollContractorLandingPage extends Component {
  state = {contractor: null, payments: null, link: ""};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let {CHECK_CONTRACTOR, PAYMENTS} = await request("payroll/contractors/" + ID, "GET");

    const payments = PAYMENTS.map(({payroll, payday, net_pay}) => ({
      payroll,
      payday: payday,
      netPay: `$${net_pay}`,
    }));

    this.setState({contractor: CHECK_CONTRACTOR, payments});
  }

  openHosted() {
    const {ID} = this.props.router.params;

    getCheckContractorOnboardingLink(ID).then((res) => {
      const handler = window.Check.create({
        link: res.url,
        onClose: this.onClose,
      });
      handler.open();
    });
  }

  openPaystub = async (payrollID) => {
    const {ID} = this.props.router.params;

    const pdf = await request(`payroll/contractors/${ID}/payments/${payrollID}`);

    let blob = b64toBlob(pdf, "application/pdf");
    let blobUrl = URL.createObjectURL(blob);

    await this.setState({link: blobUrl}, () => this.linkRef.click());
  };

  render() {
    let {contractor, payments, link} = this.state;

    let tabs = [
      {id: "info", label: "Personal Information"},
      {id: "payments", label: "Payments"},
    ];

    if (!contractor) {
      return <LoadingSpinner />;
    }

    return (
      <div className="p-6">
        <a ref={(e) => (this.linkRef = e)} href={link} target="_blank" />

        <PageHeadings label={`Your Payroll Portal`} />

        <Tab data={tabs}>
          {(id) => {
            if (id === "info") {
              return (
                <div>
                  {contractor?.onboard.status === "blocking" && (
                    <div role="alert">
                      <div className="mt-3 bg-red-500 text-white font-bold px-4 py-2">Incomplete</div>
                      <div className="border border-t-0 border-red-400 bg-red-100 px-4 py-3 text-red-700">
                        <p>
                          Please click on "Open Bank & Legal Information" and fill out the required
                          information in order to get paid!
                        </p>
                      </div>
                    </div>
                  )}

                  <TwoColumnList
                    label={"Personal Information"}
                    buttons={[
                      // {
                      //   label: "Edit",
                      //   onClick: () => this.editContractorModal.open(),
                      // },
                      {
                        label: "Open Bank & Legal Information",
                        onClick: () => this.openHosted(),
                      },
                    ]}
                    data={[
                      {
                        label: "Name",
                        value: `${contractor?.first_name} ${contractor?.last_name}`,
                      },
                      {
                        label: "Date of Birth",
                        value: contractor?.dob ?? "None",
                      },
                      {
                        label: "Address",
                        value: `${contractor?.address.line1}${
                          contractor?.address.line2 ? contractor?.address.line1 : ""
                        }, ${contractor?.address.city}, ${contractor?.address.state} ${
                          contractor?.address.postal_code
                        }`,
                      },
                    ]}
                  />
                </div>
              );
            }

            if (id === "payments") {
              return (
                <Table
                  columns={[
                    {
                      label: "Payday",
                      value: "payday",
                    },
                    {
                      label: "Net Pay",
                      value: "netPay",
                    },
                    {
                      name: "Open",
                      value: "id",
                      format: (id, row) => {
                        return (
                          <div style={{cursor: "pointer"}} onClick={() => this.openPaystub(row.payroll)}>
                            <Download />
                          </div>
                        );
                      },
                    },
                  ]}
                  data={payments}
                />
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(PayrollContractorLandingPage);

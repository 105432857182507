import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import * as Yup from "yup";
import LocationsDropdown from "../../dropdowns/team/locations-dropdown";
import {showConfirmAlert, showErrorAlert, showSuccessAlert} from "../../utils/alert-helper";
import {showErrorNotification, showSuccessNotification} from "../../utils/notification-helper";

class MenuSyncModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  saveProduct = async ({location}) => {
    let productPayload = {
      LOCATIONS: [{LOCATION_ID: location}],
    };

    try {
      await showConfirmAlert(
        "Confirm Menu Sync",
        "You cannot undo a menu sync. This is a beta feature and the data it create or deletes cannot be undone. If you are wanting to just change specific categories or products, use the sync function on that item."
      );

      showSuccessNotification(
        "Menu Sync Starting",
        "Syncing your menu will take a while. Don't leave this page or start another sync until you receive a success notification.",
        {timeout: 10}
      );

      await request("sync/menu", "POST", productPayload);

      this.modal.stopLoading();
      this.modal.close();

      setTimeout(() => {
        showSuccessAlert("Menu Sync Complete", "The menu sync has been completed successfully!");
      }, 250);
    } catch (e) {
      this.modal.stopLoading();
      this.modal.close();

      if (e?.error === "MENU_SYNC_IN_PROGRESS") {
        setTimeout(() => {
          showErrorNotification(
            "Menu Sync in Progress.",
            "There is already a menu sync in progress for this company. Please wait for it to finish before starting another."
          );
        }, 250);

        return;
      }

      setTimeout(() => {
        showErrorAlert("Menu Sync Error", "There was an error during the menu sync");
      }, 250);
    }
  };

  render() {
    return (
      <Modal
        buttonLabel="Sync"
        label="Sync Menu - Beta"
        description="Sync a menu to a different location. This will override any existing categories, products, or modifiers that have already been synced, will create categories, products, or modifiers that don't exist, and will delete categories, products, or modifiers that exist on the target location that don't match this location. We highly suggest syncing specific categories or products instead of doing a full menu sync."
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            location: null,
            category: null,
          }}
          validationSchema={Yup.object({})}
          onSubmit={this.saveProduct}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <LocationsDropdown label="Location" name="location" options={formikOptions} isCompany />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default MenuSyncModal;

import React, {Component, Fragment} from "react";
import Calendar from "./calendar";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../../redux";
import {ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {Menu, Transition} from "@headlessui/react";
import {request} from "../../../utils/request";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TimeSelect from "../../../components/form-elements/time-select";
import {getPayoutTicketPeriod, payoutEpochToMoment} from "../../../utils/payout-util";
import {addBreadcrumb} from "@sentry/react";

class ReportingDateComponent extends Component {
  state = {startString: "", endString: "", payouts: null};

  async componentDidMount() {
    let {enableStripePayouts} = this.props;

    if (enableStripePayouts) {
      await this.fetchPayouts();
    }
  }

  backInTime = () => {
    let {startEpoch, endEpoch} = this.props.reporting;

    let dayDiff = moment(endEpoch).diff(moment(startEpoch), "days") + 1;

    let newStartEpoch = moment(startEpoch).subtract(dayDiff, "days").valueOf();
    let newEndEpoch = moment(endEpoch).subtract(dayDiff, "days").valueOf();

    this.calRef.setEpochs({startEpoch: newStartEpoch, endEpoch: newEndEpoch});
  };

  forwardInTime = () => {
    let {startEpoch, endEpoch} = this.props.reporting;

    let dayDiff = moment(endEpoch).diff(moment(startEpoch), "days") + 1;

    if (dayDiff === 0) {
      dayDiff = 1;
    }

    let newStartEpoch = moment(startEpoch).add(dayDiff, "days").valueOf();
    let newEndEpoch = moment(endEpoch).add(dayDiff, "days").valueOf();

    this.calRef.setEpochs({startEpoch: newStartEpoch, endEpoch: newEndEpoch});
  };

  setToToday = () => {
    this.calRef.setEpochs({
      startEpoch: moment().startOf("day").valueOf(),
      endEpoch: moment().startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  setToYesterday = () => {
    this.calRef.setEpochs({
      startEpoch: moment().subtract(1, "day").startOf("day").valueOf(),
      endEpoch: moment().subtract(1, "day").startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  setToThisWeek = () => {
    this.calRef.setEpochs({
      startEpoch: moment().startOf("week").valueOf(),
      endEpoch: moment().endOf("week").startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  setToLastWeek = () => {
    this.calRef.setEpochs({
      startEpoch: moment().subtract(1, "week").startOf("week").valueOf(),
      endEpoch: moment().subtract(1, "week").endOf("week").startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  setToThisMonth = () => {
    this.calRef.setEpochs({
      startEpoch: moment().startOf("month").valueOf(),
      endEpoch: moment().endOf("month").startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  setToLastMonth = () => {
    this.calRef.setEpochs({
      startEpoch: moment().subtract(1, "month").startOf("month").valueOf(),
      endEpoch: moment().subtract(1, "month").endOf("month").startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  setToThisYear = () => {
    this.calRef.setEpochs({
      startEpoch: moment().startOf("year").valueOf(),
      endEpoch: moment().endOf("year").startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  setToLastYear = () => {
    this.calRef.setEpochs({
      startEpoch: moment().subtract(1, "year").startOf("year").valueOf(),
      endEpoch: moment().subtract(1, "year").endOf("year").startOf("day").valueOf(),
      resetTimeOfDay: true,
    });
  };

  fetchPayouts = () => request("payouts", "GET").then((payouts) => this.setState({payouts}));

  render() {
    let {enableStripePayouts, hideTime} = this.props;
    let {NAME} = this.props.shop.location;
    let {startEpoch, endEpoch, selectedStripePayout} = this.props.reporting;

    let {payouts} = this.state;

    let startMinutes = moment(startEpoch).diff(moment(startEpoch).startOf("day"), "minutes");

    let endMinutes = moment(endEpoch).diff(moment(endEpoch).startOf("day"), "minutes");

    if (moment(endEpoch).startOf("day").isDST() !== moment(endEpoch).startOf("day").add(5, "hours").isDST()) {
      if (!moment(endEpoch).startOf("day").isDST()) {
        endMinutes += 60;
      } else {
        endMinutes -= 60;
      }
    }

    let payoutSelectOptions =
      (payouts && payouts.length) > 0
        ? [
            {
              label: "Select By Payout",
              value: "-1",
            },
            ...payouts.map((item) => ({
              label: `${moment(item.DATE_ARRIVED).format("MMM D, YYYY")} - ${toDollars(item.TOTAL, true)}`,
              value: item.ID,
            })),
          ]
        : null;

    let reportingDateString =
      moment(startEpoch).startOf("day").valueOf() === moment(endEpoch).startOf("day").valueOf()
        ? moment(startEpoch).format("MM/DD/YYYY")
        : `${moment(startEpoch).format("MM/DD/YYYY")} - ${moment(endEpoch).format("MM/DD/YYYY")}`;

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex flex-row">
          <button
            type="button"
            className="inline-flex items-center px-1.5 py-1.5 rounded-l-md border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none "
            onClick={this.backInTime}
          >
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>

          <Menu.Button className="inline-flex justify-center w-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
            {reportingDateString}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <button
            type="button"
            className="inline-flex items-center px-1.5 py-1.5 rounded-r-md border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            onClick={this.forwardInTime}
          >
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        <Transition
          as={Fragment}
          unmount={false}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            unmount={false}
            className="p-5 origin-top-right z-50 absolute left-9 mt-2 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="flex flex-row">
              <div className="flex flex-col items-center hidden md:block">
                <Calendar
                  ref={(e) => (this.calRef = e)}
                  onChange={(updateObj) => {
                    let {startEpoch, endEpoch, resetTimeOfDay, changeTimeOfDay, payout} = updateObj;

                    if (!payout && selectedStripePayout) {
                      this.props.updateSelectedPayout(-1);
                    }

                    this.props.setEpochs({
                      startEpoch,
                      endEpoch,
                      resetTimeOfDay,
                      changeTimeOfDay,
                    });
                  }}
                  initialEpochs={{startEpoch, endEpoch}}
                />

                <div className="flex flex-col">
                  {!hideTime && (
                    <div className="mt-3 flex flex-row justify-center">
                      <div className="flex flex-col gap-y-2">
                        <input
                          type="email"
                          value={moment(startEpoch).format("MM/DD/YYYY")}
                          className="bg-gray-100 text-center shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          disabled
                        />

                        <TimeSelect
                          className="shadow-sm text-center focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          value={startMinutes}
                          onChange={(e) =>
                            this.props.setMinutes({
                              startMinutes: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className={"px-5 mt-1"}>-</div>

                      <div className="flex flex-col gap-y-2">
                        <input
                          type="email"
                          value={moment(endEpoch).format("MM/DD/YYYY")}
                          className="bg-gray-100 shadow-sm text-center focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          disabled
                        />

                        <TimeSelect
                          className="shadow-sm text-center focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          value={endMinutes}
                          onChange={(e) => this.props.setMinutes({endMinutes: e.target.value})}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {enableStripePayouts && payouts && payouts.length > 0 && (
                  <FormSelect
                    className={"w-full"}
                    label={`Stripe Payout Ranges (${NAME})`}
                    value={selectedStripePayout}
                    data={payoutSelectOptions}
                    onChange={(e) => {
                      this.props.setSelectedPayout(e);
                      let {DATE_CAPTURED_START: startEpoch, DATE_CAPTURED_END: endEpoch} = payouts?.find(
                        (p) => p.ID === e
                      );

                      this.calRef.setEpochs({
                        startEpoch,
                        endEpoch,
                        changeTimeOfDay: true,
                        payout: true,
                      });
                    }}
                  />
                )}
              </div>

              <div className="flex flex-col ml-3 w-36 gap-y-2">
                <Menu.Item>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToToday}
                  >
                    Today
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToYesterday}
                  >
                    Yesterday
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToThisWeek}
                  >
                    This Week
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    type="button"
                    className="text-center inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToLastWeek}
                  >
                    Last Week
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToThisMonth}
                  >
                    This Month
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToLastMonth}
                  >
                    Last Month
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToThisYear}
                  >
                    This Year
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={this.setToLastYear}
                  >
                    Last Year
                  </button>
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(ReportingDateComponent);

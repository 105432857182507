import React, {Component} from "react";
import {AdvancedFilter, Card, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import ScheduledProducts from "../../../features/sales/tickets/ScheduledProducts";
import ScheduledTickets from "../../../features/sales/tickets/ScheduledTickets";
import {Download} from "react-feather";
import CSVDownloadButton from "../../../features/sales/tickets/CSVDownloadButton";

class ScheduledTicketsPage extends Component {
  state = {start: null, end: null, categories: null, data: null};

  async componentDidMount() {
    this.setState(
      {
        start: moment().startOf("day").valueOf(),
        end: moment().endOf("day").valueOf(),
      },
      async () => await this.fetchScheduledOrders()
    );
  }

  async fetchScheduledOrders() {
    let {start, end} = this.state;

    let data = await request("partnerwebsite/ticket/scheduled/products", "GET", {
      start,
      end,
    });

    this.setState({data});
  }

  render() {
    let {start, end, data} = this.state;
    let formatCsvByProduct = [],
      formatCsvByTicket = [];

    let scheduledTicketsByDate = data;

    if (scheduledTicketsByDate) {
      for (const record of scheduledTicketsByDate) {
        for (const product of record.PRODUCTS) {
          formatCsvByProduct.push({
            DATE: record.DATE,
            ...product,
          });
        }

        record.TICKETS = record.TICKETS.sort((a, b) => a.TICKET_DATE_SCHEDULED - b.TICKET_DATE_SCHEDULED);

        for (const ticket of record.TICKETS) {
          for (const lineItem of ticket.LINE_ITEMS) {
            formatCsvByTicket.push({
              ...ticket,
              ...lineItem,
            });
          }
          formatCsvByTicket.push({});
        }
      }
    }

    return (
      <div>
        <PageHeadings
          label="Scheduled Tickets"
          description="View and modify tickets scheduled for a later time"
        />

        <div className="flex flex-row mt-5 justify-between items-end">
          <div className="flex flex-row">
            <FormDateTimeSelect
              value={start}
              label="From"
              name="from"
              onChange={(newValue) => {
                this.setState({start: newValue}, async () => await this.fetchScheduledOrders());
              }}
            />

            <FormDateTimeSelect
              value={end}
              className="ml-2"
              label="To"
              name="to"
              onChange={(newValue) => {
                this.setState({end: newValue}, async () => await this.fetchScheduledOrders());
              }}
            />
          </div>
        </div>

        <div className="relative">
          <Tab
            className="mt-2"
            data={[
              {
                id: "product",
                label: <div className="ml-2">By Product</div>,
                // badge: "" + cards?.length ?? "0",
              },
              {
                id: "ticket",
                label: "By Ticket",
                // badge: "" + requests?.length ?? "0",
              },
            ]}
          >
            {(t) => {
              if (t === "product") {
                return (
                  <Card>
                    <CSVDownloadButton
                      dataTableData={formatCsvByProduct}
                      dataTableColumns={CSV_BY_PRODUCT_COLUMN_HEADERS}
                      startEpoch={start}
                      endEpoch={end}
                      filenamePrefix={"scheduled-orders-by-product-"}
                    />

                    <Table
                      columns={[
                        {
                          label: "Date",
                          value: "DATE",
                        },
                      ]}
                      data={data}
                      expandable={(row, index) => <ScheduledProducts day={row} />}
                    />
                  </Card>
                );
              }

              return (
                <Card>
                  {data && (
                    <CSVDownloadButton
                      dataTableData={formatCsvByTicket}
                      dataTableColumns={CSV_BY_TICKET_COLUMN_HEADERS}
                      startEpoch={start}
                      endEpoch={end}
                      filenamePrefix={"scheduled-orders-by-product-"}
                    />
                  )}

                  <Table
                    columns={[
                      {
                        label: "Date",
                        value: "DATE",
                      },
                    ]}
                    data={data}
                    expandable={(row, index) => <ScheduledTickets day={row} />}
                  />
                </Card>
              );
            }}
          </Tab>

          <div className="absolute right-0 top-0 p-4 text-indigo-600 cursor-pointer">
            {/*<CSVDownloadButton*/}
            {/*  dataTableData={formatCsvByTicket}*/}
            {/*  dataTableColumns={CSV_BY_TICKET_COLUMN_HEADERS}*/}
            {/*  startEpoch={start}*/}
            {/*  endEpoch={end}*/}
            {/*  filenamePrefix={"scheduled-orders-by-ticket-"}*/}
            {/*/>*/}
            {/*<Download className="h-6 w-6"/>*/}
          </div>
        </div>
      </div>
    );
  }
}

const CSV_BY_PRODUCT_COLUMN_HEADERS = [
  {
    name: "Date",
    selector: "DATE",
  },
  {
    name: "Product",
    selector: "PRODUCT_NAME",
  },
  {
    name: "Quantity",
    selector: "LINE_ITEM_QUANTITY",
  },
  {
    name: "Category",
    selector: "CATEGORY_NAME",
  },
];

const CSV_BY_TICKET_COLUMN_HEADERS = [
  {
    name: "Date Scheduled",
    selector: "TICKET_DATE_SCHEDULED",
    format: (row) =>
      row.TICKET_DATE_SCHEDULED ? moment(row.TICKET_DATE_SCHEDULED).format("MM/DD/YYYY h:mm A") : " ",
  },
  {
    name: "Ticket Unique",
    selector: "TICKET_UNIQUE_ID",
  },
  {
    name: "Ticket Number",
    selector: "TICKET_NUMBER",
  },
  {
    name: "Name",
    selector: "TICKET_NAME",
  },
  {
    name: "Phone",
    selector: "TICKET_PHONE",
  },
  {
    name: "Product",
    selector: "PRODUCT_NAME",
  },
  {
    name: "Quantity",
    selector: "LINE_ITEM_QUANTITY",
  },
  {
    name: "Notes",
    selector: "LINE_ITEM_NOTES",
    format: (row) => row.LINE_ITEM_NOTES ?? "-",
  },
  {
    name: "Category",
    selector: "CATEGORY_NAME",
  },
];

export default setupReduxConnection(["menu"])(withRouter(ScheduledTicketsPage));

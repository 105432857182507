import {ReadOnlyTable} from "../shared";

const recipeColumns = (unitDict) => [
  {key: "VARIANT_OPTION", label: "Variant Name", format: (it) => it?.NAME ?? "All Variants"},
  {key: "OPTION", label: "Modifier Option", format: (it) => it?.NAME ?? "All Options"},
  {key: "NAME", label: "Ingredient"},
  {
    key: "RECIPE_COST",
    label: "Quantity",
    formatRow: (row) => `${row.RECIPE_COST} ${unitDict[row.UNIT_ID]?.NAME}`,
  },
];

export const RecipeView = ({unitDict, product, onRowClick, className = ""}) => {
  console.log(product.RECIPES);
  return (
    <ReadOnlyTable
      className={className}
      columns={recipeColumns(unitDict)}
      data={product.RECIPES}
      onRowClick={onRowClick}
    />
  );
};

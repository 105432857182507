import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import IngredientUnitDropdown from "../../../dropdowns/operations/ingredient-unit-dropdown";

class StockUnitModal extends Component {
  state = {stock: null};

  open(stock = null) {
    this.setState({stock}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createIngredientUnit({name, unit, stock}) {
    let serverUnit = await request("ingredients/stock", "POST", {
      NAME: name,
      INGREDIENT_UNIT_ID: unit,
      STOCK: parseFloat(stock),
    });

    this.props.addState(serverUnit);
    this.modal.close();
  }

  async saveIngredientUnit({name, unit, stock: stocking}) {
    const {stock} = this.state;

    let serverUnit = await request("ingredients/stock/" + stock.ID, "PATCH", {
      NAME: name,
      INGREDIENT_UNIT_ID: unit,
      STOCK: parseFloat(stocking),
    });

    this.props.updateState(serverUnit);
    this.modal.close();
  }

  render() {
    const {stock} = this.state;

    return (
      <Modal
        buttonLabel={stock ? "Save" : "Add"}
        label={stock ? "Edit Stock Unit" : "Save Stock Unit"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={stock ? this.saveIngredientUnit.bind(this) : this.createIngredientUnit.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Unit name is required"),
          })}
          initialValues={{
            name: stock?.NAME ?? "",
            unit: stock?.INGREDIENT_UNIT_ID,
            stock: stock?.STOCK ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput options={formikOptions} placeholder="Ounces" label="Unit Name" name="name" />

                <IngredientUnitDropdown options={formikOptions} name="unit" />

                <FormInput options={formikOptions} placeholder="2.5" label="Unit Quantity" name="stock" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default StockUnitModal;

import React, {Component} from "react";
import {FormInput, FormTextArea, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class BillingAccountModal extends Component {
  state = {billingAccount: null};

  open(billingAccount = null) {
    this.setState({billingAccount});
    this.modal.open();
  }

  async createBillingAccount({name, pin, email, phone, notes}) {
    if (phone[0] !== "1") {
      phone = "1" + phone;
    }

    const payload = {
      NAME: name,
      EMAIL: email,
      PHONE: phone,
      NOTES: notes,
      PIN: pin,
    };

    const serverAccount = await request("billing", "POST", payload);

    this.props.updateState && this.props.updateState({...payload, ...serverAccount});
    this.modal.close();
  }

  async saveBillingAccount({name, pin, email, phone, notes}) {
    const {billingAccount} = this.state;

    const payload = {
      NAME: name,
      EMAIL: email,
      PHONE: phone,
      NOTES: notes,
      PIN: pin,
    };

    const serverAccount = await request("billing/" + billingAccount.UNIQUE_ID, "PATCH", payload);

    this.props.updateState && this.props.updateState(serverAccount);

    this.modal.close();
  }

  render() {
    const {billingAccount} = this.state;

    return (
      <Modal
        buttonLabel={billingAccount ? "Save" : "Create"}
        label={billingAccount ? "Edit Billing Account" : "Create Billing Account"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            billingAccount ? this.saveBillingAccount.bind(this) : this.createBillingAccount.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required."),
            pin: Yup.number("PIN must be a number.").required("PIN is required"),
            notes: Yup.string(),
            phone: Yup.string().required("Phone is required."),
            email: Yup.string().matches(/^\S+@\S+[.]\S+$/, "Email incorrect format."),
          })}
          initialValues={{
            name: billingAccount?.NAME,
            pin: billingAccount?.PIN,
            notes: billingAccount?.NOTES,
            phone: billingAccount?.PHONE,
            email: billingAccount?.EMAIL,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput options={formikOptions} label="Account Name" name="name" />

                <FormInput options={formikOptions} label="Account PIN" name="pin" />

                <FormInput
                  options={formikOptions}
                  label="Phone"
                  name="phone"
                  tooltip="This phone number is used as the default when sending invoices for purchases made on this account."
                />

                <FormInput
                  options={formikOptions}
                  label="Email"
                  name="email"
                  hint="Optional"
                  tooltip="This email is used as the default when sending invoices for purchases made on this account."
                />

                <FormTextArea options={formikOptions} hint="Optional" label="Notes" name="notes" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default BillingAccountModal;

import React, {Component} from "react";
import {Card, PageHeadings, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {getPayrollCompanyOnboardingLink, getPaySchedules} from "../../../utils/payroll-helper";
import {setupReduxConnection} from "../../../redux";
import PayScheduleSetupModal from "../../../modals/payroll/pay-schedule-setup-modal";
import PayrollSettingsModal from "../../../modals/payroll/payroll-settings-modal";
import {isQE, isLocal, request} from "../../../utils/request";
import {TIP_CALCULATION_METHOD_TYPES} from "../../../utils/settings-constants";
import {DAYS} from "@frostbyte-technologies/frostbyte-core/dist/helpers/location-helper";
import RunPayrollSettingsModal from "../../../modals/payroll/run-payroll-settings-modal";
import PayrollWhitelistWrapper from "../../../features/payroll/payroll-whitelist-wrapper";
import {positiveModulo} from "../../../utils/util";
import {setStats} from "../../../redux/scheduling-stats";
import LoadingSpinner from "../../../components/loading-spinner";
import {STATES_WITH_NO_TIP_CREDIT_ADJUSTMENT} from "../../../utils/payroll-constants";

class PayrollSettingsPage extends Component {
  state = {
    paySchedules: null,
    notificationEmployeeId: null,
    serverEmployees: null,
    isDemoLoading: false,
    showTipCreditAdjustment: false,
  };

  componentDidMount() {
    const shouldShowTipCredit = !STATES_WITH_NO_TIP_CREDIT_ADJUSTMENT.has(this.props.shop.location.STATE);
    this.setState({showTipCreditAdjustment: shouldShowTipCredit});
    this.syncState();
  }

  syncState = async () => {
    let [paySchedules, notificationEmployeeId, serverEmployees] = await Promise.all([
      getPaySchedules(),
      request("payroll/notification-employees", "GET"),
      request("employees/v2", "GET", {
        ROLES: true,
        ACTIVE: 0,
      }),
      // request("payroll/dba", "GET"),
    ]);

    this.setState({paySchedules, notificationEmployeeId, serverEmployees});
  };

  openHosted() {
    this.setState({loading: true});

    let {ID} = this.props.user.account;

    getPayrollCompanyOnboardingLink({ID}).then((res) => {
      const handler = window.Check.create({
        link: res.url,
      });
      handler.open();
    });
  }

  render() {
    let {
      PAYROLL_PAY_CALCULATED_TIPS,
      PAYROLL_PAYCHECK_TIPS,
      TIP_CALCULATION_METHOD,
      RUN_PAYROLL_SHOW_COMMISSION,
      AUTOMATIC_TIP_CREDIT,
    } = this.props.shop.settings;

    const {OVERTIME_WORKWEEK_START_ISODAY} = this.props.shop.payrollGroup;
    let {notificationEmployeeId, serverEmployees, isDemoLoading, showTipCreditAdjustment} = this.state;

    let empStr = "None";
    if (notificationEmployeeId) {
      empStr = serverEmployees.find((emp) => emp.ID === notificationEmployeeId)?.FULL_NAME;
    }

    let data = [
      {
        label: "Calculate Tips with Dripos",
        value: PAYROLL_PAY_CALCULATED_TIPS,
        type: "bool",
      },
      {
        label: "Payrun Email Notifications",
        value: empStr,
      },
    ];

    if (PAYROLL_PAY_CALCULATED_TIPS === "1") {
      data.push({
        label: "Tip Calculation Method",
        value: TIP_CALCULATION_METHOD_TYPES[TIP_CALCULATION_METHOD],
      });

      data.push({
        label: "Tip Payout",
        value: PAYROLL_PAYCHECK_TIPS === "1" ? "Paycheck Tips" : "Cash Tips",
      });
    }

    if (showTipCreditAdjustment)
      data.push({
        label: "Automatic Tip Credit Adjustment to Minimum Wage",
        value: AUTOMATIC_TIP_CREDIT === "1" ? "Yes" : "No",
      });

    data.push({
      label: "Workweek Start",
      value:
        DAYS[positiveModulo(OVERTIME_WORKWEEK_START_ISODAY - 1, 7)].charAt(0) +
        DAYS[positiveModulo(OVERTIME_WORKWEEK_START_ISODAY - 1, 7)].toLowerCase().substring(1),
    });

    const demoButtonContents = isDemoLoading ? <LoadingSpinner /> : "Reset Demo";
    return (
      <PayrollWhitelistWrapper router={this.props.router}>
        <PageHeadings label="Payroll Settings" />
        <PayrollSettingsModal
          ref={(e) => (this.payrollSettingsModal = e)}
          refresh={this.syncState}
          showTipCreditAdjustment={showTipCreditAdjustment}
        />
        <RunPayrollSettingsModal ref={(e) => (this.runPayrollSettingsModal = e)} refresh={this.syncState} />

        <TwoColumnList
          label="Settings"
          description=""
          buttons={[
            {
              label: "Open Tax & Authorization Settings",
              onClick: () => this.openHosted(),
            },
            {
              label: "Edit Settings",
              onClick: () => this.payrollSettingsModal.open(),
            },
          ]}
          data={data}
          showTipCreditAdjustment={showTipCreditAdjustment}
        />

        <TwoColumnList
          label="Run Payroll Settings"
          description=""
          buttons={[
            {
              label: "Edit",
              onClick: () => this.runPayrollSettingsModal.open(),
            },
          ]}
          data={[
            {
              label: "Commission",
              type: "bool",
              tooltip:
                "Enabling will allow you to add commission to your employee's pay while running payroll",
              value: RUN_PAYROLL_SHOW_COMMISSION,
            },
          ]}
        />

        {(isQE() || isLocal()) && (
          <Card label={"Demo"}>
            <div className={"p-6 flex flex-row space-x-3 items-center"}>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={async () => {
                  this.setState({isDemoLoading: true});
                  await request("payroll-testing/demo", "POST");
                  this.setState({isDemoLoading: false});
                }}
              >
                {demoButtonContents}
              </button>
            </div>
          </Card>
        )}
      </PayrollWhitelistWrapper>
    );
  }
}

export default setupReduxConnection(["user", "shop", "payroll"])(PayrollSettingsPage);

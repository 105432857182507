import React, {Component} from "react";
import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";

import {request} from "../../../../utils/request";
import {withTranslation} from "react-i18next";
import {withRouter} from "../../../../utils/navigation";
import EmailEditor from "react-email-editor";
import Template from "../../../../assets/templates/email.json";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import EmailCampaignModal from "../../../../modals/marketing/campaigns/email-campaign-modal";
import EmailSendModal from "../../../../modals/marketing/campaigns/email-send-modal";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import CardAlert from "../../../../features/card-alert";
import EmailSendTestModal from "../../../../modals/marketing/campaigns/email-send-test-modal";
import {showConfirmAlert} from "../../../../utils/alert-helper";
import ClearEmailCampaignModal from "../../../../modals/marketing/campaigns/clear-email-campaign-modal";
import ScheduleEmailModal from "../../../../modals/marketing/campaigns/schedule-email-modal";

class EmailCampaignPage extends Component {
  state = {campaign: null, instances: [], lists: [], isLoading: false};
  autoSaveInterval = null;

  componentDidMount() {
    this.setState({isLoading: true});
    this.syncState();
    this.startAutoSave();
  }

  componentWillUnmount() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
  }

  startAutoSave() {
    this.autoSaveInterval = setInterval(() => {
      if (this.state.campaign && !this.state.campaign.DATE_EXECUTED) {
        this.saveContent({showAlert: false, reloadState: false});
        showSuccessNotification("Auto Save", "Campaign has been automatically saved");
      }
    }, 60 * 1000); // every 1 minute, in the future can make this a setting
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let serverCampaign = await request("marketing/campaigns/email/" + id, "GET", null);

    const serverLists = await request("text/marketing/lists", "GET", null);

    const emailLists = serverLists.filter(({EMAIL_ONLY}) => !!EMAIL_ONLY);

    this.setState({
      campaign: serverCampaign,
      instances: serverCampaign.INSTANCES,
      isLoading: false,
      lists: emailLists,
    });
  }

  getEmailHtml() {
    return new Promise((resolve) => {
      this.editor.exportHtml(({html}) => {
        resolve(html);
      });
    });
  }

  getEmailJson() {
    return new Promise((resolve) => {
      this.editor.saveDesign((data) => {
        resolve(data);
      });
    });
  }

  async saveContent(params) {
    const {campaign} = this.state;
    const {showAlert, reloadState} = params;

    if (reloadState) {
      this.contentRef.startLoading();
    }

    const html = await this.getEmailHtml();
    const json = await this.getEmailJson();

    const serverCampaign = await request("marketing/campaigns/email/contents/" + campaign.ID, "PATCH", {
      CONTENT: html,
      JSON_CONTENT: JSON.stringify(json),
    });

    if (reloadState) {
      this.setState({campaign: serverCampaign}, () => {
        this.contentRef.stopLoading();

        showAlert && showSuccessNotification("Campaign Saved", "Email campaign content has been saved");
      });
    }
  }

  async sendTestEmail(email, replacements) {
    const {campaign} = this.state;

    request("marketing/campaigns/email/test/" + campaign.ID, "POST", {
      EMAIL: email,
      REPLACEMENTS: replacements,
    })
      .then(() => {
        showSuccessNotification("Test Email Sent", "The test email is sent. Check your email!");
      })
      .catch(() => {
        showErrorNotification(
          "Cannot Send",
          "Please ensure the email campaign has non-empty content and the sending domain is properly formatted. (Only alphanumeric characters, ., -, and _ are allowed."
        );
      });
  }

  async executeCampaign() {
    const {campaign} = this.state;

    const serverEmails = await request("marketing/campaigns/email/execute/" + campaign.UNIQUE_ID, "POST", {});

    this.setState({
      campaign: {...campaign, DATE_EXECUTED: Date.now()},
      instances: serverEmails ?? [],
    });

    showSuccessNotification(
      "Email Campaign Sent",
      "The email campaign has been sent out to customers. You can see the instances sent in the instance tab."
    );
  }

  syncPatrons() {
    const {campaign} = this.state;

    showConfirmAlert(
      "Sync Patrons",
      "Are you sure you would like to sync patrons? This will add back any patrons that have been deleted from this email campaign."
    )
      .then(() => {
        return request("marketing/campaigns/email/sync/" + campaign.ID, "POST", {});
      })
      .then(() => {
        this.setState({campaign: null, instances: []});
      })
      .then(() => {
        return this.syncState();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async clearCampaignPatrons(name, email) {
    const {campaign} = this.state;

    await request("marketing/campaigns/email/clear/" + campaign.ID, "POST", {
      NAMES: name,
      EMAILS: email,
    }).catch((err) => {
      console.log(err);
    });

    return this.syncState();
  }

  async deleteCampaign() {
    const {campaign} = this.state;

    showConfirmAlert(
      "Delete Campaign",
      "Are you sure you would like to delete this email campaign? This operation cannot be undone."
    )
      .then(() => {
        return request("marketing/campaigns/email/" + campaign.ID, "DELETE");
      })
      .then(() => {
        showSuccessNotification("Deleted", "Campaign Deleted Successfully!");
        this.props.router.navigate("/campaigns");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async duplicateCampaign() {
    const {campaign} = this.state;

    await request("marketing/campaigns/email/duplicate/" + campaign.ID, "POST", {});

    this.props.router.navigate("/campaigns");
  }

  convertToDraft() {
    const {campaign} = this.state;

    showConfirmAlert(
      "Convert to Draft?",
      "Are you sure you would like to convert this email campaign back to a draft? It will no longer be scheduled to be sent."
    ).then(() => {
      this.setState({isLoading: true});

      request("marketing/campaigns/email/" + campaign.ID, "PATCH", {
        ...campaign,
        DATE_SCHEDULED: null,
      }).then(() => {
        this.setState({
          campaign: {...campaign, DATE_SCHEDULED: null},
          isLoading: false,
        });
      });
    });
  }

  renderSent() {
    const {campaign, instances} = this.state;

    const buttons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: [
              {
                label: "Duplicate Campaign",
                onClick: () => this.duplicateCampaign(),
              },
            ],
          },
        ],
      },
    ];

    const campaignDetails = [
      {label: "Name", value: campaign.NAME},
      {
        label: "Email Subject",
        value: campaign.SUBJECT,
      },
      {
        label: "Date Created",
        value: campaign.DATE_CREATED,
        type: "datetime",
      },
      // TODO add dollars from attached tickets
    ];

    if (campaign.DATE_SCHEDULED) {
      campaignDetails.push({
        label: "Date Scheduled",
        value: campaign.DATE_SCHEDULED,
        type: "datetime",
      });
    }

    campaignDetails.push(
      {
        label: "Date Sent",
        value: campaign.DATE_EXECUTED,
        type: "datetime",
      },
      {
        label: "Subheader",
        value: campaign.SUBHEADER ? campaign.SUBHEADER : "-",
      },
      {label: "Instances Attempted", value: instances.length},
      {
        label: "Instances Sent",
        value: campaign.NUMBER_SENT,
      },
      {
        label: "Instances Delivered",
        value: campaign.NUMBER_DELIVERED,
      }
    );

    return (
      <div>
        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>{campaign.NAME}</div>
              <SuccessBadge className="ml-2 mt-2" yellow>
                Sent
              </SuccessBadge>
            </div>
          }
          description={`View the metrics of this sent email campaign`}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Campaigns Page", url: "/campaigns"},
          ]}
          buttons={buttons}
        />
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
          <CardAlert
            label="Number Sent"
            icon="mail-bulk"
            value={campaign.NUMBER_SENT}
            subtext={"total emails have been sent"}
            hideView
          />

          <CardAlert
            label="Open Percentage"
            icon="envelope-open"
            value={Math.round((campaign.NUMBER_OPENED / campaign.NUMBER_SENT) * 100) + "%"}
            subtext={"of sent messages were opened"}
            hideView
          />

          <CardAlert
            label="Bounce Rate"
            icon="seal-exclamation"
            value={Math.round((100 * campaign.NUMBER_BOUNCED) / campaign.NUMBER_SENT) || 0 + "%"}
            subtext={"of sent emails bounced"}
            hideView
          />

          <CardAlert
            label="Patrons Returned"
            icon="house-return"
            value={campaign.NUMBER_RETURNED}
            subtext={"returned after opening the email"}
            hideView
          />

          <CardAlert
            label="Clicks"
            icon="mouse-pointer"
            value={campaign.CLICKS}
            subtext={"clicks on links inside the email"}
            hideView
          />

          <CardAlert
            label="Unsubscribed Patrons"
            icon="delete-right"
            value={campaign.NUMBER_UNSUBSCRIBED}
            subtext={"have unsubscribed from emails"}
            hideView
          />
        </div>

        <Tab
          data={[
            {
              label: "Campaign Content",
              id: "content",
            },
            {
              label: "Recipients",
              id: "instances",
            },
          ]}
        >
          {(id) => {
            if (id === "content") {
              return (
                <>
                  <TwoColumnList
                    label="Campaign Details"
                    description="View the campaign audience, dates, and subject line"
                    data={campaignDetails}
                  />

                  <Card label="Email Content" className="hidden sm:block" description="View the sent email">
                    <div dangerouslySetInnerHTML={{__html: campaign.CONTENT}}></div>
                  </Card>
                </>
              );
            }

            if (id === "instances") {
              return (
                <Card label="Email Recipients" description="View the patrons the email was sent to">
                  <Table
                    pagination
                    data={instances}
                    ref={(e) => (this.tableRef = e)}
                    actionButtons={[
                      {
                        label: "View Patron",
                        onClick: (row) => this.props.router.navigate("/patron/" + row.PATRON_UNIQUE_ID),
                      },
                    ]}
                    columns={[
                      {
                        value: "FULL_NAME",
                        label: "Name",
                        format: (value, row) => {
                          return (
                            <div>
                              <div className="text-sm font-medium text-gray-900">{value || "-"}</div>
                              <div className="text-sm text-gray-500">{row.EMAIL}</div>
                            </div>
                          );
                        },
                      },
                      {
                        value: "STATUS",
                        label: "Status",
                        width: 1,
                      },
                      {
                        value: "STATUS",
                        // label: "retry",
                        width: 1,
                        format: (value, row) => {
                          if ((value === "NO_EMAIL" || value === "BOUNCE") && !!row.EMAIL) {
                            return (
                              <div
                                className="text-indigo-700 cursor-pointer"
                                onClick={() => {
                                  this.setState({isLoading: true});
                                  // TODO retry functionality
                                  console.log("Retrying...");
                                  this.setState({isLoading: false});
                                }}
                              >
                                Retry
                              </div>
                            );
                          }
                          return <></>;
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }

  renderDraft() {
    const {campaign, instances, lists} = this.state;

    const buttonOptions = [
      {label: "Delete Campaign", onClick: () => this.deleteCampaign()},
      {
        label: "Duplicate Campaign",
        onClick: () => this.duplicateCampaign(),
      },
      {
        label: "Send Test Email",
        ref: (e) => (this.executeTestRef = e),
        onClick: () => {
          this.sendTestModal.open();
        },
      },
      {
        label: "Send Campaign",
        ref: (e) => (this.executeRef = e),
        onClick: () => {
          this.sendModal.open();
        },
      },
    ];

    if (!campaign.DATE_SCHEDULED) {
      buttonOptions.push({
        label: "Schedule Campaign",
        ref: (e) => (this.executeRef = e),
        onClick: () => {
          this.scheduleModal.open(campaign);
        },
      });
    } else {
      buttonOptions.push({
        label: "Change Scheduled Time",
        ref: (e) => (this.executeRef = e),
        onClick: () => {
          this.scheduleModal.open(campaign);
        },
      });
      buttonOptions.push({
        label: "Convert to Draft",
        ref: (e) => (this.executeRef = e),
        onClick: () => {
          this.convertToDraft();
        },
      });
    }

    const buttons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: buttonOptions,
          },
        ],
      },
    ];

    const campaignDetails = [
      {label: "Name", value: campaign.NAME},
      {label: "Email Subject", value: campaign.SUBJECT},
      {
        label: "Date Created",
        value: campaign.DATE_CREATED,
        type: "datetime",
      },
      {
        label: "Date Sent",
        value: campaign.DATE_EXECUTED ? campaign.DATE_EXECUTED : "Not Sent",
        type: campaign.DATE_EXECUTED && "datetime",
      },
      {
        label: "Subheader",
        value: campaign.SUBHEADER ? campaign.SUBHEADER : "-",
      },
    ];

    if (campaign.DATE_SCHEDULED) {
      campaignDetails.push({
        label: "Date Scheduled",
        value: campaign.DATE_SCHEDULED,
        type: "datetime",
      });
    }

    return (
      <div>
        <EmailCampaignModal
          updateState={(campaign) => this.setState({campaign})}
          ref={(e) => (this.campaignModal = e)}
          subLists={lists ?? []}
        />

        <EmailSendModal sendCampaign={this.executeCampaign.bind(this)} ref={(e) => (this.sendModal = e)} />

        <EmailSendTestModal
          sendEmail={(email) => this.sendTestEmail(email, {PATRON: "&lt;Patron FULL NAME&gt;"})}
          ref={(e) => (this.sendTestModal = e)}
        />

        <ClearEmailCampaignModal
          clearCampaignPatrons={(name, email) => this.clearCampaignPatrons(name, email)}
          ref={(e) => (this.clearModal = e)}
        />

        <ScheduleEmailModal
          updateState={() => {
            this.setState({isLoading: true});
            this.syncState();
          }}
          ref={(e) => (this.scheduleModal = e)}
        />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>{campaign.NAME}</div>
              {campaign.DATE_SCHEDULED ? (
                <SuccessBadge className="ml-2 mt-2">Scheduled</SuccessBadge>
              ) : (
                <DangerBadge className="ml-2 mt-2" yellow>
                  Draft
                </DangerBadge>
              )}
            </div>
          }
          description={`View and edit this email campaign`}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Campaigns Page", url: "/campaigns"},
          ]}
          buttons={buttons}
        />

        <TwoColumnList
          label="Campaign Details"
          description="View the campaign audience, dates, and subject line"
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.campaignModal.open(campaign);
              },
            },
          ]}
          data={campaignDetails}
        />

        <Tab
          data={[
            {
              label: "Campaign Content",
              id: "content",
            },
            {
              label: "Recipients",
              id: "instances",
            },
          ]}
        >
          {(id) => {
            if (id === "content") {
              return (
                <>
                  <Card
                    label="Email Content"
                    className="hidden sm:block"
                    description="Edit the email here"
                    tooltip={{
                      data: [
                        {
                          label: "Edit Email",
                          data: "You may use this custom email editor to edit your emails and save your changes before testing.",
                        },
                        {
                          label: "Replacements",
                          data: 'We allow you to use your patron\'s name within an email. To do this simply type "{{PATRON}}" where you want the name to appear. To ensure you have formatted it correctly, you may want to send a test email to yourself using the actions button in the top right. If formatted correctly, the {{PATRON}} will be replaced with <Patron FULL NAME>',
                        },
                      ],
                    }}
                    button={{
                      label: "Save Content",
                      onClick: () => this.saveContent({showAlert: true, reloadState: true}),
                      ref: (e) => (this.contentRef = e),
                    }}
                  >
                    <EmailEditor
                      style={{minHeight: "75vh", width: "500px"}}
                      projectId="8321"
                      ref={(e) => {
                        this.editor = e;

                        setTimeout(() => {
                          if (e && e.editor) {
                            e.loadDesign(
                              campaign.JSON_CONTENT ? JSON.parse(campaign.JSON_CONTENT) : Template
                            );
                          }
                        }, 500);
                      }}
                    />
                  </Card>

                  <div className="block sm:hidden">Email editing disabled on mobile</div>
                </>
              );
            }

            if (id === "instances") {
              return (
                <Card
                  label="Email Recipients"
                  description="View the patrons that will receive this email"
                  buttons={[
                    {
                      theme: "primary",
                      label: "Actions",
                      icon: "plus",
                      type: "list",
                      onChange: ({id}) => {
                        if (id === "sync") {
                          this.syncPatrons();
                        }

                        if (id === "clear") {
                          this.clearModal.open();
                        }
                      },
                      items: [
                        {
                          id: "sync",
                          label: "Sync Patrons",
                          description: "Add all company patrons to this email campaign",
                        },
                        {
                          id: "clear",
                          label: "Clear Options",
                          description: "Remove patrons with no email or no name from this campaign",
                        },
                      ],
                    },
                  ]}
                >
                  <Table
                    pagination
                    data={campaign?.LIST?.Patrons}
                    ref={(e) => (this.tableRef = e)}
                    actionButtons={[
                      {
                        label: "View Patron",
                        onClick: (row) => this.props.router.navigate("/patron/" + row.PATRON_UNIQUE_ID),
                      },
                    ]}
                    columns={[
                      {
                        value: "FULL_NAME",
                        label: "Name",
                        format: (value, row) => {
                          return (
                            <div>
                              <div className="text-sm font-medium text-gray-900">{value || "-"}</div>
                              <div className="text-sm text-gray-500">{row.EMAIL}</div>
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }

  render() {
    const {campaign, isLoading} = this.state;

    if (campaign === null || isLoading) {
      return <Loading />;
    }

    if (!campaign.DATE_EXECUTED) {
      return this.renderDraft();
    } else {
      return this.renderSent();
    }
  }
}

export default withTranslation()(withRouter(EmailCampaignPage));

import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import FormRow from "../../../components/form-row";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";

export const NON_TEXT_LINE_TYPES = {BARCODE: "BARCODE", IMAGE: "IMAGE"};

class LabelLineModal extends Component {
  state = {line: null, seq: -1};

  open(line = null, seq = -1) {
    this.setState({line, seq}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createLabelLine({content, type, size, style, paddingLeft, paddingTop, paddingRight, paddingBottom}) {
    const {seq} = this.state;
    const {label} = this.props;

    const serverLine = await request("labels/lines", "POST", {
      LABEL_ID: label.ID,

      TYPE: type,
      CONTENT: content,
      SEQ: seq,

      PADDING: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
      FONT_SIZE: parseInt(size),
      FONT_STYLE: style,
    });

    this.props.addState(serverLine);
    this.modal.close();
  }

  async saveLabelLine({content, type, size, style, paddingLeft, paddingTop, paddingRight, paddingBottom}) {
    const {label} = this.props;
    const {line} = this.state;

    const serverLine = await request("labels/lines/" + line.ID, "PATCH", {
      LABEL_ID: label.ID,

      TYPE: type,
      CONTENT: content,
      SEQ: line.SEQ,

      PADDING: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
      FONT_SIZE: parseInt(size),
      FONT_STYLE: style,
    });

    this.props.updateState(serverLine.ID, serverLine);
    this.modal.close();
  }

  async deleteLabelLine() {
    const {line} = this.state;

    showLoadingConfirmAlert("Delete Label Line", "Are you sure you want to delete this label line?").then(
      async (close) => {
        await request("labels/lines/" + line.ID, "DELETE", {});

        close();

        this.props.updateState(line.ID);
        this.modal.close();
      }
    );
  }

  render() {
    const {line} = this.state;

    let initialValues = {
      content: "",
      type: "TEXT",
      size: "16",
      style: "n",
      paddingLeft: "0",
      paddingTop: "0",
      paddingRight: "0",
      paddingBottom: "0",
    };

    if (line) {
      const [top, right, bottom, left] = line.PADDING.split(" ");

      initialValues = {
        content: line.CONTENT,
        type: line.TYPE,
        size: "" + line.FONT_SIZE,
        style: line.FONT_STYLE,
        paddingLeft: "" + left,
        paddingTop: "" + top,
        paddingRight: "" + right,
        paddingBottom: "" + bottom,
      };
    }

    return (
      <Modal
        large
        ref={(e) => (this.modal = e)}
        label={line ? "Edit Label Category" : "Save Label Category"}
        deleteLabel={line && "Delete"}
        deleteOnClick={() => this.deleteLabelLine()}
        formikOnClick={() => this.formikRef}
        buttonLabel={line ? "Save" : "Add"}
      >
        <Formik
          onSubmit={line ? this.saveLabelLine.bind(this) : this.createLabelLine.bind(this)}
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={initialValues}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  options={formikOptions}
                  label="Line Type"
                  name="type"
                  data={[
                    {label: "Text", value: "TEXT"},
                    {label: "Today's Date", value: "DATE"},
                    {label: "Employee Name", value: "EMPLOYEE"},
                    {label: "Device Name", value: "DEVICE"},
                    {label: "Barcode", value: "BARCODE"},
                    {label: "Image", value: "IMAGE"},
                  ]}
                />

                {values.type === "IMAGE" && (
                  <FormInput options={formikOptions} label={"Image URL"} name={"content"} />
                )}

                {values.type === "BARCODE" && (
                  <FormInput
                    options={formikOptions}
                    placeholder={"123123"}
                    label={"Barcode"}
                    tooltip={"This number will be converted to a barcode when the label is printed"}
                    name={"content"}
                  />
                )}

                {values.type === "TEXT" && (
                  <FormInput
                    options={formikOptions}
                    placeholder="Ounces"
                    label="Text Content"
                    name="content"
                  />
                )}

                {!NON_TEXT_LINE_TYPES[values.type] && (
                  <FormRow>
                    <FormInput flex options={formikOptions} placeholder="16" label="Font Size" name="size" />

                    <FormSelect
                      flex
                      options={formikOptions}
                      label="Font Style"
                      name="style"
                      data={[
                        {value: "n", label: "Normal"},
                        {value: "b", label: "Bold"},
                        {value: "i", label: "Italic"},
                        {value: "s", label: "Strikethrough"},
                        {value: "u", label: "Underline"},
                      ]}
                    />
                  </FormRow>
                )}

                <FormRow>
                  <FormInput
                    flex
                    options={formikOptions}
                    placeholder="16"
                    label="Padding Top"
                    name="paddingTop"
                  />

                  <FormInput
                    flex
                    options={formikOptions}
                    placeholder="16"
                    label="Padding Right"
                    name="paddingRight"
                  />

                  <FormInput
                    flex
                    options={formikOptions}
                    placeholder="16"
                    label="Padding Bottom"
                    name="paddingBottom"
                  />

                  <FormInput
                    flex
                    options={formikOptions}
                    placeholder="16"
                    label="Padding Left"
                    name="paddingLeft"
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default LabelLineModal;

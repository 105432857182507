import React, {Component, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {CheckIcon, ChevronDownIcon, DownloadIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {CSVLink} from "react-csv";

class CSVDropdown extends Component {
  render() {
    let {data, reportData, className} = this.props;

    return (
      <div className={classNames(className, "block text-sm font-medium text-gray-700")}>
        <Menu as="div" className="relative inline-block text-left z-2">
          <div>
            <Menu.Button className="items-center inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
              <DownloadIcon className="h-5 w-5" />

              <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div>
              {reportData && (
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div>
                    {data.map((item) => {
                      let {csvHeaders, csvData} = item.dataConvertFunction(reportData);

                      return (
                        <CSVLink filename={item.filename} data={csvData} headers={csvHeaders}>
                          <Menu.Item
                            as={"div"}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            }
                          >
                            {item.label}
                          </Menu.Item>
                        </CSVLink>
                      );
                    })}
                  </div>
                </Menu.Items>
              )}
            </div>
          </Transition>
        </Menu>
      </div>
    );
  }
}

export default CSVDropdown;

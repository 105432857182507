import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Table, Filter, Loading, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import ProductInventoryModal from "../../../modals/operations/inventory/product-inventory-modal";

class InventoriesPage extends Component {
  state = {
    isLoading: true,
    products: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let products = await request("inventory/products/tracked", "GET", {});

    this.setState({isLoading: false, products});
  }

  render() {
    const {isLoading, products} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Inventory"
          description="Track the current inventory of your products"
          button={{
            label: "Add Product",
            onClick: () => this.inventoryModal.open(products),
          }}
        />

        <ProductInventoryModal
          ref={(e) => (this.inventoryModal = e)}
          addState={(item) => this.props.router.navigate("/inventory/" + item.UNIQUE_ID)}
        />

        <Filter className="mt-4" searchable>
          {(filters, search) => {
            return (
              <Table
                pagination
                searchFields={["NAME"]}
                search={search}
                data={products}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => this.props.router.navigate("/inventory/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "NAME",
                    label: "Name",
                  },
                  {
                    value: "INVENTORY",
                    label: "Stock",
                  },
                ]}
              />
            );
          }}
        </Filter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(InventoriesPage));

import React, {Component} from "react";
import {CELL_TYPES} from "./reporting-constants";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class ReportingLabelCell extends Component {
  render() {
    let {style} = this.props;
    let {label} = this.props.row;

    if (!style) {
      style = [];
    }

    let text = label;
    if (style.includes(CELL_TYPES.NEGATIVE)) {
      text = `(${text})`;
    }

    return (
      <div
        className={classNames(
          "",
          style.includes(CELL_TYPES.BOLD) && "font-bold",
          style.includes(CELL_TYPES.BOLD) && !style.includes(CELL_TYPES.SECONDARY) && "text-black",
          style.includes(CELL_TYPES.SECONDARY) && "ml-4 text-gray-500",
          !style.includes(CELL_TYPES.SECONDARY) && "text-gray-900"
        )}
      >
        {text}
      </div>
    );
  }
}

export default ReportingLabelCell;

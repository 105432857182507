import TimeCardModal from "../../modals/team/time-card-modal";
import React, {Component} from "react";
import {CheckIcon} from "@heroicons/react/solid";
import TimeCardsTable from "./time-cards-table";
import {showConfirmAlert, showLoadingConfirmAlert, showSuccessAlert} from "../../utils/alert-helper";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import moment from "moment";
import AutoclockModal from "../../modals/timesheets/autoclock-modal";

class TimesheetsApprovalTable extends Component {
  state = {loadingTimesheets: []};

  async approveTimeCard(card) {
    //Even though it's checked on the server side, we also check here so the popup can appear without any lag
    // if (!CHECK_COMPANY_ID) {
    const end = card.DATE_END;
    const start = card.DATE_START;

    if (moment(end).diff(start, "hours") >= 24) {
      return this.autoclockModal.open(card);
    }

    let {cards} = this.props;
    await request("team/timesheet/" + card.ID + "/approve", "POST", null);

    const cardIndex = cards.findIndex((item) => item.ID === card.ID);

    if (cardIndex !== -1) {
      cards.splice(cardIndex, 1);

      this.props.updateCards(cards);
    }
    //}
    // else {
    //   console.log(CHECK_COMPANY_ID)
    //   showErrorAlert(
    //     "Invalid Timesheet",
    //     "This timesheet has no pay earned. Please change before approving.",
    //     "Ok"
    //   );
    // }
  }

  approveAll = () => {
    showConfirmAlert("Are you sure you want to approve all time cards?").then(async () => {
      let invalidTimesheets = await request("team/timesheet/approve-all", "POST", null);

      showSuccessAlert(
        "Time Cards Approved",
        `All Time Cards were approved except for ${invalidTimesheets.length}. Please correct them in order to approve them.`,
        "Ok"
      );

      this.props.updateCards();
    });
  };

  render() {
    let {cards, filters, search, searchFields, breakOptions} = this.props;

    return (
      <div>
        <AutoclockModal ref={(e) => (this.autoclockModal = e)} {...this.props} />

        <TimeCardModal
          ref={(e) => (this.cardModal = e)}
          onChange={(card) => {
            const cardIndex = cards.findIndex((item) => item.ID === card.ID);

            if (cardIndex !== -1) {
              if (card) {
                cards.splice(cardIndex, 1, card);
              } else {
                cards.splice(cardIndex, 1);
              }

              this.props.updateCards(cards);
            }
          }}
          onDelete={(card) => {
            const cardIndex = cards.findIndex((item) => item.ID === card.ID);

            if (cardIndex !== -1) {
              cards.splice(cardIndex, 1);
            }

            this.props.updateCards(cards);
          }}
          breakOptions={breakOptions}
        />

        {(cards?.length > 0 || (cards?.length === 0 && !this.props.emptyComponent)) && (
          <TimeCardsTable
            approval
            searchFields={searchFields}
            sortable
            filters={filters}
            search={search}
            cards={cards ? [...cards] : null}
            actionButtons={[
              {
                label: <CheckIcon className="h-6 w-6 inline text-green-600" />,
                onClick: (row) => this.approveTimeCard(row),
              },
              {label: "Edit", onClick: (row) => this.cardModal.open(row)},
            ]}
          />
        )}

        {cards?.length === 0 && this.props.emptyComponent}
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TimesheetsApprovalTable);

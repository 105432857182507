export const CUSTOM_BENEFIT_TYPES = {
  "125_medical": "125 Medical",
  "125_medical_other": "125 Medical (Other)",
  "125_vision": "125 Vision",
  "125_dental": "125 Dental",
  "125_disability": "125 Disability",
  "125_accident": "125 Accident",
  "125_cancer": "125 Cancer",
  "125_critical_illness": "125 Critical Illness",
  "125_hospital": "125 Hospital",
  "125_life": "125 Life",
  "401k": "401k",
  "403b": "403b",
  457: "457",
  roth_401k: "Roth 401k",
  roth_403b: "Roth 403b",
  roth_457: "Roth 457",
  fsa_medical: "FSA Medical",
  fsa_dependent_care: "FSA Dependent Care",
  hsa: "HSA",
  simple_ira: "Simple IRA",
};

export const PAYROLL_ONBOARDING_STATUSES = {
  NO_GROUP: 1,
  NOT_STARTED: 2,
  COMPANY_CREATED: 3,
  GROUP_CREATED: 4,
  WORKPLACES_CREATED: 5,
  SUBMITTED: 6,
  LIVE: 7,
};

export const STATES_WITH_NO_TIP_CREDIT_ADJUSTMENT = new Set(["AK", "CA", "GU", "MN", "MT", "NV", "OR", "WA"]);

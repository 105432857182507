import React, {Component} from "react";
import {Card, Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import ContactModal from "../../../modals/operations/invoices/contact-modal";
import ContactsTable from "../../../tables/operations/invoices/contacts-table";
import InvoiceEmailModal from "../../../modals/operations/invoices/invoice-email-modal";

class ContactsPage extends Component {
  state = {customers: null, invoices: null, emails: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let invoices = await request("invoices", "GET", null);
    let customers = await request("contacts", "GET", null);
    const emails = await request("invoices/emails", "GET", null).catch((err) => console.log("HERE", err));

    this.setState({customers, invoices, emails});
  }

  render() {
    const {customers, invoices, emails} = this.state;

    if (customers === null || invoices === null || emails === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Contacts"
          description="View your invoice contacts"
          button={{
            label: "Add Customer",
            onClick: () => this.customerModal.open(),
          }}
        />

        <InvoiceEmailModal ref={(e) => (this.invoiceEmailModal = e)} syncState={() => this.syncState()} />

        <Card
          label="Invoice Notification Emails"
          tooltip={{data: "These emails will be notified whenever an invoice is paid."}}
          buttons={[{label: "Add Email", onClick: () => this.invoiceEmailModal.open()}]}
        >
          <Table
            data={emails}
            columns={[
              {
                value: "EMAIL",
                label: "Email",
              },
            ]}
          />
        </Card>

        <ContactModal
          ref={(e) => (this.customerModal = e)}
          addState={(item) => this.props.router.navigate("/contact/" + item.UNIQUE_ID)}
        />

        <ContactsTable className="mt-4" contacts={customers} invoices={invoices} />
      </div>
    );
  }
}

export default withRouter(ContactsPage);

import React, {Component} from "react";
import {
  Card,
  Filter,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import IngredientModal, {
  CALCULATION_TYPE_DISPLAYS,
  INGREDIENT_TYPE_DISPLAYS,
  INGREDIENT_TYPES,
} from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";
import AttachIngredientModal from "../../../../modals/operations/supply-chain/recipes/attach-ingredient-modal";
import StockIngredientModal from "../../../../modals/operations/supply-chain/recipes/stock-ingredient-modal";
import moment from "moment-timezone";
import {parseIdDict, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import RecipeTable from "../../../../tables/operations/recipes/recipe-table";
import RecipeUnitModal from "../../../../modals/operations/supply-chain/recipes/recipe-unit-modal";
import GrayBadge from "../../../../components/badges/gray-badge";
import ActionButton from "../../../../components/buttons/action-button";
import {getStore, setupReduxConnection} from "../../../../redux";
import {View} from "@react-pdf/renderer";
import QuickStockIngredientModal from "../../../../modals/operations/supply-chain/recipes/quick-stock-ingredient-modal";
import IngredientRulesModal from "../../../../modals/operations/supply-chain/recipes/ingredient-rules-modal";
import cronstrue from "cronstrue";
import {updateIngredients} from "../../../../redux/supply";
import {StockRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";

class RecipePage extends Component {
  STOCK_TYPES = ["RECIPE", "QUICK_ADD", "PO_INGEST"];
  ACTIVITY_TYPES = [
    "RECIPE",
    "RECIPE_INGEST",
    "QUICK_ADD",
    "SALE",
    "WASTE",
    "PO_INGEST",
    "COUNT",
    "TRANSFER",
    "RESET",
  ];
  ACTIVITY_TYPE_DISPLAY = {
    RECIPE: "Production Event (+)",
    RECIPE_INGEST: "Production Event (-)",
    QUICK_ADD: "Quick Stock",
    PO_INGEST: "Purchase Order",
    WASTE: "Waste",
    COUNT: "Count",
    TRANSFER: "Transfer",
    RESET: "Reset",
    SALE: "Sale",
  };

  state = {
    editedActivities: {},
  };

  includeModals(ingredient) {
    return (
      <View>
        <AttachIngredientModal
          ref={(e) => (this.attachIngredientModal = e)}
          syncState={() => this.syncState()}
          source={ingredient}
        />

        <IngredientModal ref={(e) => (this.ingredientModal = e)} syncState={() => this.syncState()} />

        <StockIngredientModal
          ref={(e) => (this.stockIngredientModal = e)}
          ingredient={ingredient}
          syncState={() => this.syncState()}
        />

        <QuickStockIngredientModal
          ref={(e) => (this.quickStockIngredientModal = e)}
          syncState={() => this.syncState()}
        />

        <RecipeUnitModal
          ref={(e) => (this.unitModal = e)}
          ingredient={ingredient}
          syncState={() => this.syncState()}
        />

        <IngredientRulesModal ref={(e) => (this.ingredientRulesModal = e)} ingredient={ingredient} />
      </View>
    );
  }

  renderPageHeader(ingredient) {
    return (
      <PageHeadings
        label={ingredient?.NAME}
        breadcrumbs={[
          {label: "Supply Chain", url: "/supply"},
          {label: "Items/Ingredients", url: "/supply-chain-inventory"},
        ]}
      />
    );
  }

  fetchCalculationTypeField(ingredient) {
    return {
      label: "Calculation Type",
      value: CALCULATION_TYPE_DISPLAYS[ingredient.CALCULATION_TYPE],
    };
  }

  fetchCostPerUnitField(ingredient) {
    return {
      label: "Default Cost",
      tooltip: "Default cost per unit of measurement.",
      value: ingredient.COST_PER_UNIT ? (
        toDollars(ingredient.COST_PER_UNIT, true)
      ) : (
        <GrayBadge className="font-semibold ml-1">Disabled</GrayBadge>
      ),
    };
  }

  fetchIngredientTypeField(ingredient) {
    return {label: "Type", value: INGREDIENT_TYPE_DISPLAYS[ingredient.TYPE] ?? INGREDIENT_TYPE_DISPLAYS.ITEM};
  }

  fetchIngredientPARField(ingredient) {
    return {
      label: "PAR",
      value: ingredient?.PAR ? (
        ingredient.PAR + " " + ingredient.UNIT_NAME
      ) : (
        <GrayBadge className="font-semibold ml-1">Disabled</GrayBadge>
      ),
    };
  }

  fetchIngredientStockField(ingredient) {
    return {
      label: "Current Inventory",
      value: `${ingredient.DISPLAY_STOCK} ${ingredient.DISPLAY_UNIT?.NAME ?? ingredient.UNIT?.NAME ?? ""}`,
    };
  }

  fetchIngredientUnitDisplay(ingredient) {
    return (
      <div>
        {ingredient.UNIT_NAME}
        <GrayBadge className="font-semibold ml-1">Measurement</GrayBadge>
      </div>
    );
  }

  fetchIngredientDisplayUnitDisplay(ingredient) {
    return (
      <div>
        {ingredient.DISPLAY_UNIT?.NAME}
        <GrayBadge className="font-semibold ml-1">Display</GrayBadge>
      </div>
    );
  }

  fetchIngredientUnitsDisplay(ingredient) {
    return (
      <div className="space-y-2">
        {this.fetchIngredientUnitDisplay(ingredient)}
        {this.fetchIngredientDisplayUnitDisplay(ingredient)}
      </div>
    );
  }

  fetchIngredientUnitsField(ingredient) {
    return {
      label: "Units",
      value: this.fetchIngredientUnitsDisplay(ingredient),
    };
  }

  fetchGroupsField(ingredient) {
    return {
      label: "Groups",
      value: ingredient.CATEGORIES.map((category) => category?.NAME).join(", "),
    };
  }

  fetchExpirationField(ingredient) {
    return {
      label: "Expiration",
      value: ingredient.TIME_TO_EXPIRATION ? (
        `${ingredient.TIME_TO_EXPIRATION} minutes`
      ) : (
        <GrayBadge className="font-semibold ml-1">Disabled</GrayBadge>
      ),
    };
  }

  fetchIngredientInformationFields(ingredient) {
    return [
      this.fetchIngredientTypeField(ingredient),
      this.fetchIngredientPARField(ingredient),
      this.fetchIngredientStockField(ingredient),
      this.fetchIngredientUnitsField(ingredient),
      this.fetchCalculationTypeField(ingredient),
      this.fetchCostPerUnitField(ingredient),
      this.fetchGroupsField(ingredient),
      this.fetchExpirationField(ingredient),
    ];
  }

  fetchQuickStockButton(ingredient) {
    return {
      label: "Quick Stock",
      onClick: () => this.quickStockIngredientModal.open(ingredient),
    };
  }

  fetchEditButton(ingredient) {
    return {
      label: "Edit",
      onClick: () => this.ingredientModal.open(ingredient),
    };
  }

  fetchItemActionButtons(ingredient) {
    return [this.fetchQuickStockButton(ingredient), this.fetchEditButton(ingredient)];
  }

  fetchIngredientActionButtons(ingredient) {
    return [
      this.fetchCreateProductionEvent(ingredient),
      this.fetchQuickStockButton(ingredient),
      this.fetchEditButton(ingredient),
    ];
  }

  fetchIngredientInformationTooltip() {
    return {data: "Ingredient configuration"};
  }

  renderIngredientInformation(ingredient) {
    const actionButtons =
      ingredient.TYPE === INGREDIENT_TYPES.ITEM
        ? this.fetchItemActionButtons(ingredient)
        : this.fetchIngredientActionButtons(ingredient);
    return (
      <TwoColumnList
        label={INGREDIENT_TYPE_DISPLAYS[ingredient.TYPE]}
        tooltip={this.fetchIngredientInformationTooltip()}
        data={this.fetchIngredientInformationFields(ingredient)}
        buttons={actionButtons}
      />
    );
  }

  fetchTabs(ingredient) {
    const {TYPE} = ingredient;
    const baseTabs = [
      {id: "production", label: "Activity Log"},
      {id: "pricing", label: "Vendor Items"},
      {id: "rules", label: "Rules"},
    ];
    const ingredientTabs = [{id: "recipe", label: "Sub-Recipe"}];
    return TYPE === INGREDIENT_TYPES.ITEM ? baseTabs : [...baseTabs, ...ingredientTabs];
  }

  fetchUnitTabButtons() {
    return [
      {
        label: "Add Unit",
        onClick: () => {
          this.unitModal.open();
        },
      },
    ];
  }

  fetchUnitNameColumn() {
    return {
      label: "Unit Name",
      value: "NAME",
    };
  }

  formatConversion(val, row, ingredient) {
    const conversion = row.CONVERSIONS?.find((unit) => unit.TO_UNIT === ingredient.UNIT_ID);

    return conversion ? `${conversion.RATE} ${ingredient.UNIT.NAME}` : "";
  }

  fetchConversionRateColumn(ingredient) {
    return {
      label: "Conversion Rate",
      value: "RATE",
      format: (val, row) => {
        return this.formatConversion(val, row, ingredient);
      },
    };
  }

  fetchEditUnitColumn() {
    return {
      label: "Edit",
      width: 1,
      value: "NAME",
      format: (val, row) => {
        return <ActionButton label="Edit" onClick={() => this.unitModal.open(row)} />;
      },
    };
  }

  fetchUnitTableColumns(ingredient) {
    return [
      this.fetchUnitNameColumn(),
      this.fetchConversionRateColumn(ingredient),
      this.fetchEditUnitColumn(),
    ];
  }

  renderUnitTable(ingredient) {
    return <Table key="units" data={ingredient.UNITS} columns={this.fetchUnitTableColumns(ingredient)} />;
  }

  renderUnitTab(ingredient) {
    return (
      <Card label="Units" buttons={this.fetchUnitTabButtons()}>
        {this.renderUnitTable(ingredient)}
      </Card>
    );
  }

  fetchRecipeTabButtons() {
    return [
      {
        label: "Add Ingredient",
        onClick: () => {
          this.attachIngredientModal.open();
        },
      },
    ];
  }

  renderRecipeTab(ingredient, unitDict) {
    return (
      <Card label="Sub-Recipe" buttons={this.fetchRecipeTabButtons()}>
        <RecipeTable ingredient={ingredient} syncState={() => this.syncState()} unitDict={unitDict} />
      </Card>
    );
  }

  fetchVendorNameColumn() {
    return {label: "Vendor", value: "VENDOR_NAME"};
  }

  fetchItemPriceColumn() {
    return {
      label: "Price Per Case",
      value: "PRICE_PER_CASE",
      type: "dollars",
    };
  }

  fetchCaseSizeColumn() {
    return {label: "Vendor Item Quantity", value: "CASE_SIZE"};
  }

  fetchPricingColumns() {
    return [this.fetchVendorNameColumn(), this.fetchItemPriceColumn(), this.fetchCaseSizeColumn()];
  }

  renderPricingTable(ingredient) {
    return <Table key="pricing" data={ingredient.VENDOR_ITEMS ?? []} columns={this.fetchPricingColumns()} />;
  }

  renderPricingTab(ingredient) {
    return (
      <Card label="Vendor Items" description="A list of all of the purchase options for this ingredient">
        {this.renderPricingTable(ingredient)}
      </Card>
    );
  }

  isStockActivity(activity) {
    return this.STOCK_TYPES.includes(activity.TYPE);
  }

  activityTypeDisplay(type) {
    switch (type) {
      case "RECIPE":
        return "Production Event (+)";
      case "RECIPE_INGEST":
        return "Production Event (-)";
      case "QUICK_ADD":
        return "Quick Stock";
      case "PO_INGEST":
        return "Purchase Order";
      case "WASTE":
        return "Waste";
      case "COUNT":
        return "Count";
      case "TRANSFER":
        return "Transfer";
      case "RESET":
        return "Reset";
      case "SALE":
        return "Sale";
      default:
        return "N/A";
    }
  }

  fetchProductionTypeColumn() {
    return {
      label: "Type",
      value: "TYPE",
      format: (value) => this.activityTypeDisplay(value),
    };
  }

  activityQuantityDisplay(quantity, row, ingredient) {
    return quantity + " " + ingredient.UNIT?.NAME;
  }

  recordEdit = (row) => {
    this.state.editedActivities[row.ID] = row;
  };

  fetchProductionQuantityColumn(ingredient) {
    return {
      label: "Quantity",
      value: "QUANTITY",
      editable: true,
      format: (val, row) => this.activityQuantityDisplay(val, row, ingredient),
      onChangeRow: this.recordEdit,
    };
  }

  fetchProductionCostColumn() {
    return {
      label: "Real Cost per Unit",
      value: "COST",
      editable: true,
      format: (val) => {
        return toDollars(val, true);
      },
      onChangeRow: this.recordEdit,
    };
  }

  fetchProductionCreationDateColumn() {
    return {
      label: "Activity On",
      value: "ACTIVITY_ON",
      format: (val) => moment(val).format("MMM Do hh:mmA"),
    };
  }

  fetchProductionEmployeeNameColumn() {
    return {
      label: "Employee",
      value: "EMPLOYEE",
    };
  }

  fetchProductionTableColumns(ingredient) {
    return [
      this.fetchProductionTypeColumn(),
      this.fetchProductionQuantityColumn(ingredient),
      this.fetchProductionCostColumn(),
      this.fetchProductionCreationDateColumn(),
      this.fetchProductionEmployeeNameColumn(),
    ];
  }

  filterType(options, data) {
    if (options.length === 0) {
      return data;
    }

    return data.filter((item) => {
      return options.includes(item.TYPE);
    });
  }

  fetchFilters() {
    return [
      {
        id: "type",
        label: "Type",
        onFilter: (options, data) => this.filterType(options, data),
        options: Object.values(this.ACTIVITY_TYPES).map((item) => ({
          id: item,
          label: this.ACTIVITY_TYPE_DISPLAY[item],
          value: item,
        })),
      },
    ];
  }

  transformToActivity = (data) => ({
    ID: data.ID,
    TYPE: data.TYPE,
    QUANTITY: this.isStockActivity(data) ? data.INITIAL_QUANTITY : data.QUANTITY * -1,
    COST: data.COST,
    ACTIVITY_ON: data.DATE_CREATED,
    EMPLOYEE: data.FULL_NAME,
  });

  transformToServerData = (activity) => {
    return this.isStockActivity(activity)
      ? this.transformActivityToStockData(activity)
      : this.transformActivityToUsageData(activity);
  };

  transformActivityToStockData(activity) {
    return {
      ID: activity.ID,
      TYPE: activity.TYPE,
      INITIAL_QUANTITY: activity.QUANTITY,
      COST: activity.COST,
    };
  }

  transformActivityToUsageData(activity) {
    return {
      ID: activity.ID,
      TYPE: activity.TYPE,
      QUANTITY: activity.QUANTITY * -1,
      COST: activity.COST,
    };
  }

  renderProductionTable(ingredient) {
    const transformedStocks = ingredient.STOCKS.map(this.transformToActivity);
    const transformedUsages = ingredient.USAGES.map(this.transformToActivity);

    const activityData = [...transformedStocks, ...transformedUsages].sort(
      (a, b) => b.ACTIVITY_ON - a.ACTIVITY_ON
    );

    return (
      <Filter data={this.fetchFilters()}>
        {(filters) => {
          return (
            <Table
              key="activity"
              data={activityData}
              columns={this.fetchProductionTableColumns(ingredient)}
              pagination
              rowsPerPage
              filters={filters}
            />
          );
        }}
      </Filter>
    );
  }

  fetchCreateProductionEvent(ingredient) {
    return {
      label: "Create Production Event",
      onClick: () => {
        this.stockIngredientModal.open(ingredient);
      },
    };
  }

  async saveActivityLogEdits() {
    const {editedActivities} = this.state;

    showLoadingConfirmAlert(
      "Save Activity Log Edits",
      "Are you sure you want to save your edited stock and usage events?"
    ).then(async (close) => {
      const editedStocks = {};
      const editedUsages = {};

      for (const [key, value] of Object.entries(editedActivities)) {
        if (this.isStockActivity(value)) {
          editedStocks[key] = this.transformToServerData(value);
        } else {
          editedUsages[key] = this.transformToServerData(value);
        }
      }

      await Promise.all([
        StockRequests.updateStocks(editedStocks),
        StockRequests.updateStockUsages(editedUsages),
      ]);

      getStore().dispatch(updateIngredients());

      this.setState({
        editedActivities: {},
      });

      close();
    });
  }

  fetchProductionTabButtons() {
    return [
      {
        label: "Save Edits",
        onClick: () => {
          this.saveActivityLogEdits();
        },
      },
    ];
  }

  renderProductionTab(ingredient) {
    return (
      <Card label="Activity Log" buttons={this.fetchProductionTabButtons()}>
        {this.renderProductionTable(ingredient)}
      </Card>
    );
  }

  fetchRulesTabButtons() {
    return [
      {
        label: "Create Rule",
        onClick: () => this.ingredientRulesModal.open(),
      },
    ];
  }

  formatCron(cron) {
    if (!cron) {
      return "";
    }

    return cronstrue.toString(cron);
  }

  fetchRuleQuantityColumn() {
    return {
      label: "Quantity",
      value: "QUANTITY",
      format: (qty, rule) => {
        return `${qty} ${rule?.UNIT_NAME}`;
      },
    };
  }

  fetchRuleTypeColumn() {
    return {label: "Type", value: "TYPE", format: (val) => val.capitalize()};
  }

  fetchRuleScheduleColumn() {
    return {label: "Schedule", value: "CRON", format: (cron) => this.formatCron(cron)};
  }

  fetchRuleLocationsColumn() {
    return {
      label: "Locations",
      value: "LOCATIONS",
      format: (locations, rule) => {
        return rule.LOCATIONS.map((l) => l?.NAME).join(", ");
      },
    };
  }

  fetchRulesTableColumns() {
    return [
      this.fetchRuleQuantityColumn(),
      this.fetchRuleTypeColumn(),
      this.fetchRuleScheduleColumn(),
      this.fetchRuleLocationsColumn(),
    ];
  }

  fetchRuleEditButton() {
    return {
      label: "Edit",
      onClick: (rule) => this.ingredientRulesModal.open(rule),
    };
  }

  fetchRulesTableActionButtons() {
    return [this.fetchRuleEditButton()];
  }

  renderRulesTabTable(ingredient) {
    return (
      <Table
        key="rules"
        data={ingredient.RULES}
        columns={this.fetchRulesTableColumns(ingredient)}
        actionButtons={this.fetchRulesTableActionButtons()}
      />
    );
  }

  renderRulesTab(ingredient) {
    return (
      <Card label="Stocking Rules" buttons={this.fetchRulesTabButtons(ingredient)}>
        {this.renderRulesTabTable(ingredient)}
      </Card>
    );
  }

  renderCurrentTab(tab, ingredient, unitDict) {
    if (tab === "units") {
      return this.renderUnitTab(ingredient);
    } else if (tab === "recipe") {
      return this.renderRecipeTab(ingredient, unitDict);
    } else if (tab === "pricing") {
      return this.renderPricingTab(ingredient);
    } else if (tab === "production") {
      return this.renderProductionTab(ingredient);
    } else if (tab === "rules") {
      return this.renderRulesTab(ingredient);
    }
  }

  renderBottomTab(ingredient, unitDict) {
    return (
      <Tab className="ml-2" data={this.fetchTabs(ingredient)}>
        {(tab) => {
          return this.renderCurrentTab(tab, ingredient, unitDict);
        }}
      </Tab>
    );
  }

  render() {
    const {ingredients, units} = this.props.supply;
    const unitDict = parseIdDict(units);

    const ingredient = ingredients.find(
      (_ingredient) => _ingredient.UNIQUE_ID === this.props.router.params.UNIQUE_ID
    );

    if (!ingredient) {
      return <Loading />;
    }

    return (
      <>
        {this.includeModals(ingredient)}
        {this.renderPageHeader(ingredient)}
        {this.renderIngredientInformation(ingredient)}
        {this.renderBottomTab(ingredient, unitDict)}
      </>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(RecipePage));

import React, {Component} from "react";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";

class ProcessingGraph extends Component {
  state = {
    totalProcessed: null,
    allTimeByDay: null,
    biweekly: null,
    startDates: null,
  };

  componentDidMount() {
    this.syncState();
  }

  syncState = async () => {
    let {TOTAL_PROCESSED, ALL_TIME_BY_DAY, BIWEEKLY, START_DATES} = await request(
      "admin/dashboard/payroll/processed",
      "GET"
    );

    const biweekly = BIWEEKLY.map(({x, y}) => ({
      weekStart: x,
      processing: y,
    }));

    this.setState({
      totalProcessed: TOTAL_PROCESSED,
      allTimeByDay: ALL_TIME_BY_DAY,
      biweekly,
      startDates: START_DATES,
    });
  };

  render() {
    const {biweekly} = this.state;

    if (!biweekly) {
      return <LoadingSpinner />;
    }

    return (
      <Card className={"bg-white"} label={"Total Processed (Biweekly Period)"}>
        <div className={"p-6"}>
          <ResponsiveContainer width="100%" height={400} className={"mt-3 pr-5"}>
            <BarChart height={400} width={"100%"} data={biweekly} margin={{left: 50, right: 35, top: 10}}>
              <CartesianGrid strokeDasharray="3 3" />

              <Bar
                dataKey={"processing"}
                fill={"#ffffff"}
                fillOpacity={0.7}
                stroke={"#4e46e5"}
                strokeWidth={2}
                fill={"#ccc9f7"}
              />

              <XAxis
                type={"number"}
                dataKey={"weekStart"}
                domain={["dataMin", `dataMax`]}
                tickFormatter={(value) => moment(parseInt(value)).format("MMM")}
                tick={{fontSize: "0.75rem", fontWeight: "bold"}}
                tickMargin={10}
                tickLine={{stroke: "#cccccc"}}
                axisLine={false}
                tickCount={10}
              />

              <YAxis
                dataKey={"processing"}
                tick={{fontSize: "0.75rem", fontWeight: "bold"}}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => toDollars(value, true)}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>
    );
  }
}

export default ProcessingGraph;

import React, {Component} from "react";
import {request} from "../utils/request";
import MultiSelectCombobox from "./multi-select-combobox";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showConfirmAlert} from "../utils/alert-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FormProductComboSelect extends Component {
  state = {data: [], isLoading: false, dataDict: {}, autoSelectCompany: true};

  componentDidMount() {
    this.setState({isLoading: true}, () => {
      this.syncData().catch((err) => {
        console.log("Problem fetching employee data", err);
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {company} = this.props;
    const {isLoading} = this.state;
    const {company: oldCompany} = prevProps;

    if (company !== oldCompany) {
      this.setState({isLoading: true}, () => {
        this.syncData();
      });
    }
  }

  onSelectItem(currSelections, selectedItem, remove) {
    const {company, options, name} = this.props;
    const {dataDict = {}, data, autoSelectCompany} = this.state;

    if (!company || !autoSelectCompany) {
      return;
    }

    if (!remove) {
      const newSelections = [];
      const item = dataDict[selectedItem.id];

      if (item) {
        const allData = data.reduce((accum, {data}) => [...accum, ...data], []);

        for (const prod of allData) {
          if (item.NAME === prod.item.NAME && selectedItem.id !== prod.id) {
            newSelections.push(prod.id);
          }
        }
      }

      if (newSelections.length > 0) {
        options.setFieldValue(name, [...currSelections, ...newSelections]);
      }
    } else {
      const newSelections = new Set();
      const item = dataDict[selectedItem.id];

      if (item) {
        const allData = data.reduce((accum, {data}) => [...accum, ...data], []);

        for (const prod of allData) {
          if (item.NAME === prod.item.NAME) {
            newSelections.add(prod.id);
          }
        }

        options.setFieldValue(name, [...currSelections.filter((prodId) => !newSelections.has(prodId))]);
      }
    }
  }

  getSelectedNames() {
    const {options, name} = this.props;
    const {dataDict = {}} = this.state;
    const selectedIds = options.values[name];

    const selectedProducts = selectedIds.reduce((accum, id) => {
      if (dataDict[id]) {
        accum.add(dataDict[id].NAME);
      }
      return accum;
    }, new Set());

    return [...selectedProducts.values()];
  }

  clearSelections() {
    const {options, name} = this.props;

    options.setFieldValue(name, []);
  }

  selectMenuSyncedItems() {
    const {options, name} = this.props;
    const {dataDict = {}, data} = this.state;
    const newSelections = [];

    const selectedIds = options.values[name];

    const menuSyncSet = selectedIds.reduce((accum, id) => {
      if (dataDict[id]) {
        accum.add(dataDict[id].NAME); // Doing it by menu sync id was a bit janky
      }
      return accum;
    }, new Set());

    if (menuSyncSet.size === 0) {
      return;
    }

    const allData = data.reduce((accum, {data}) => [...accum, ...data], []);

    for (const prod of allData) {
      if (menuSyncSet.has(prod.item.NAME)) {
        newSelections.push(prod.id);
      }
    }

    options.setFieldValue(name, newSelections);
  }

  async syncData() {
    const {exclude = [], unique, company} = this.props;

    let serverProducts;

    if (company) {
      serverProducts = await request("products/soft/company", "GET");

      const data = Object.values(
        serverProducts.reduce((dict, item) => {
          if (item.DATE_ARCHIVED || !item.CATEGORY_ID) {
            return dict;
          }
          if (!dict[item.LOCATION_ID]) {
            dict[item.LOCATION_ID] = [item];
          } else {
            dict[item.LOCATION_ID].push(item);
          }

          return dict;
        }, {})
      ).map((item) => {
        return {
          title: item[0].LOCATION_NAME,
          data: item
            .filter((item) => !exclude.includes(item.ID))
            .map((item) => {
              return {
                id: item.ID,
                label: item.NAME,
                unique: item.UNIQUE_ID,
                item,
              };
            }),
        };
      });

      const dataDict = parseIdDict(serverProducts);

      this.setState({data, raw: serverProducts, isLoading: false, dataDict}, () => {
        this.selectMenuSyncedItems();
      });
    } else {
      serverProducts = await request("products/soft", "GET");
      serverProducts.sort((a, b) => a.NAME.localeCompare(b.NAME));

      const data = [
        {
          title: "All Products",
          data: serverProducts
            .filter((item) => !item.DATE_ARCHIVED && item.CATEGORY_ID)
            .filter((item) => !exclude.includes(item.ID))
            .map((item) => {
              return {
                id: item.ID,
                unique: item.UNIQUE_ID,
                label: item.NAME,
                item,
              };
            }),
        },
      ];

      const dataDict = parseIdDict(serverProducts);

      this.setState({data, raw: serverProducts, isLoading: false, dataDict});
    }
  }

  render() {
    const {data} = this.state;
    const {options, name} = this.props;
    const showClear = options.values[name].length > 0;

    return (
      <div className="flex flex-1 flex-row justify-between">
        <MultiSelectCombobox
          {...this.props}
          popOver
          data={data}
          headerIcon={"fa-solid fa-store"}
          maxLen={1}
          onChangeSoft={(currItems, item, wasRemoved) => {
            this.onSelectItem(currItems, item, wasRemoved);
          }}
        />

        {showClear && (
          <div
            onClick={async () => {
              await showConfirmAlert(
                "Clear All Selected Products?",
                `Are you sure you would like to clear all of the selected products?`
              );

              this.clearSelections();
            }}
            className="hover: cursor-pointer ml-4 mt-10"
          >
            <FontAwesomeIcon icon="fa-regular fa-trash" color={"red"} />
          </div>
        )}
      </div>
    );
  }
}

export default FormProductComboSelect;

import React, {Component} from "react";
import {withRouter} from "../../../utils/navigation";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import BillingAccountModal from "../../../modals/operations/billing-accounts/billing-account-modal";

class BillingAccountsPage extends Component {
  state = {accounts: null};

  async componentDidMount() {
    await this.syncState();
  }

  // Please let me know asap when a report is available that lists the transactions to include
  // Name, Date of purchase, gross sales, discounts, tips, tax, Net Sale, employee

  syncState = async () => {
    let accounts = await request("billing/", "GET", {});

    this.setState({accounts});
  };

  render() {
    let {accounts} = this.state;

    return (
      <div>
        <BillingAccountModal
          ref={(e) => (this.billingModal = e)}
          updateState={({UNIQUE_ID}) => {
            this.props.router.navigate("/billing-accounts/" + UNIQUE_ID);
          }}
        />

        <PageHeadings label={"Billing Accounts"} description={"Manage your billing accounts"} />

        <Card
          label={"Billing Accounts"}
          button={{
            label: "Create Account",
            onClick: () => {
              this.billingModal.open();
            },
          }}
        >
          <Table
            columns={[
              {
                label: "Name",
                value: "NAME",
              },
              {
                label: "Outstanding Balance",
                value: "TOTAL",
                format: (value) => toDollars(value, true),
                width: 1,
              },
            ]}
            data={accounts}
            actionButtons={[
              {
                label: "View",
                onClick: (row) => this.props.router.navigate(`/billing-accounts/${row.UNIQUE_ID}`),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(BillingAccountsPage);

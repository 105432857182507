import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class AdvancedSettingsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async ({enabled}) => {
    let {location} = this.props.shop;

    const enableInt = parseInt(enabled);
    if (enableInt !== location.ENABLED) {
      if (enableInt === 1) {
        await request("online", "POST", {});
      } else {
        await request("offline", "POST", {});
      }

      location.ENABLED = enableInt;
    }

    this.props.updateShop({location});
    this.modal.close();
  };

  render() {
    let {location} = this.props.shop;

    return (
      <Modal
        label="Ticket Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            enabled: location.ENABLED,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="enabled"
                  label="Location Online"
                  options={formikOptions}
                  tooltip="Will this location be able to take orders."
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(AdvancedSettingsModal);

import React, {Component, Fragment} from "react";
import {Popover, Transition} from "@headlessui/react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {setupReduxConnection} from "../../redux";

class Flyout extends Component {
  state = {open: false};

  render() {
    const {label, routes, dashboard, permissions = []} = this.props.item;
    const {permissions: userPermissions} = this.props.user;

    const finalRoutes = routes.filter((item) => {
      if (!item.live && process.env.REACT_APP_ENV !== "development") {
        return false;
      }

      if (userPermissions.includes("ADMIN_FULL")) {
        return true;
      }

      if (permissions.length === 0) {
        return true;
      }

      const possiblePermissions = [...(item.permissions || []), ...permissions];

      if (possiblePermissions.some((x) => userPermissions.includes(x))) {
        return true;
      }

      return false;
    });

    if (finalRoutes.length === 0) {
      return null;
    }

    return (
      <Popover className="relative">
        {({open, close}) => (
          <>
            <Popover.Button
              className={classNames(
                open ? "text-gray-900" : "text-gray-900",
                "group bg-white rounded-md inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
            >
              <span>{label}</span>

              <ChevronDownIcon
                className={classNames(
                  open ? "text-gray-600" : "text-gray-900",
                  "ml-2 h-4 w-4 group-hover:text-gray-500"
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 -ml-4 mt-3 transform w-screen max-w-md xl:max-w-3xl">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 xl:grid-cols-2">
                    {finalRoutes.map((item) => (
                      <div onClick={() => close()}>
                        <Link
                          to={item.url}
                          key={item.name}
                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                            <FontAwesomeIcon icon={item.icon} className="h-6 w-6" />
                          </div>

                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>

                  {dashboard && (
                    <div className="p-5 bg-gray-50 sm:p-8">
                      <a href="#" className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                        <div className="flex items-center">
                          <div className="text-base font-medium text-gray-900">{dashboard.label}</div>
                        </div>

                        <p className="mt-1 text-sm text-gray-500">{dashboard.description}</p>
                      </a>
                    </div>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }
}

export default setupReduxConnection(["user"])(Flyout);

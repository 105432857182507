import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Formik} from "formik";
import {Modal, FormInput, FormSelect, FormBoolean} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";

class TimeCardAutoApprovalModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {clockInBefore, clockInAfter, clockOutBefore, clockOutAfter, enabled} = values;

    let payload = {
      TIMESHEET_AUTO_APPROVE_CLOCK_IN_BEFORE_BUFFER: clockInBefore,
      TIMESHEET_AUTO_APPROVE_CLOCK_IN_AFTER_BUFFER: clockInAfter,
      TIMESHEET_AUTO_APPROVE_CLOCK_OUT_BEFORE_BUFFER: clockOutBefore,
      TIMESHEET_AUTO_APPROVE_CLOCK_OUT_AFTER_BUFFER: clockOutAfter,
      TIMESHEET_AUTO_APPROVE: enabled,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {
      TIMESHEET_AUTO_APPROVE_CLOCK_IN_BEFORE_BUFFER,
      TIMESHEET_AUTO_APPROVE_CLOCK_IN_AFTER_BUFFER,
      TIMESHEET_AUTO_APPROVE_CLOCK_OUT_BEFORE_BUFFER,
      TIMESHEET_AUTO_APPROVE_CLOCK_OUT_AFTER_BUFFER,
      TIMESHEET_AUTO_APPROVE,
    } = this.props.shop.settings;

    return (
      <Modal
        label="Time Card Auto-Approval"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            clockInBefore: TIMESHEET_AUTO_APPROVE_CLOCK_IN_BEFORE_BUFFER,
            clockInAfter: TIMESHEET_AUTO_APPROVE_CLOCK_IN_AFTER_BUFFER,
            clockOutBefore: TIMESHEET_AUTO_APPROVE_CLOCK_OUT_BEFORE_BUFFER,
            clockOutAfter: TIMESHEET_AUTO_APPROVE_CLOCK_OUT_AFTER_BUFFER,
            enabled: TIMESHEET_AUTO_APPROVE,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="enabled"
                  label={"Enable Time Card Auto Approval"}
                  tooltip={
                    "If enabled, time cards will be auto-approved if they fall within the designated threshold of shift start and end times"
                  }
                  options={formikOptions}
                />

                {values?.enabled === "1" && (
                  <>
                    <FormInput
                      name="clockInBefore"
                      label={"Clock-In Before Shift Start (in Minutes)"}
                      options={formikOptions}
                    />
                    <FormInput
                      name="clockInAfter"
                      label={"Clock-In After  Shift Start (in Minutes)"}
                      options={formikOptions}
                    />
                    <FormInput
                      name="clockOutBefore"
                      label={"Clock-Out Before Shift End (in Minutes)"}
                      options={formikOptions}
                    />
                    <FormInput
                      name="clockOutAfter"
                      label={"Clock-Out After Shift End (in Minutes)"}
                      options={formikOptions}
                    />
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(TimeCardAutoApprovalModal);

import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import EmployeeDropdown from "../../../dropdowns/team/employee-dropdown";

class DepartmentModal extends Component {
  state = {department: null};

  open(department = null) {
    this.setState({department}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveDepartment({name, employee, employeeName, employeeUnique}) {
    const {department} = this.state;

    const departmentPayload = {
      MANAGER_EMPLOYEE_ID: employee,
      NAME: name,
    };

    let serverDepartment = await request("departments/" + department.ID, "PATCH", departmentPayload);

    serverDepartment.MANAGER_EMPLOYEE_NAME = employeeName;
    serverDepartment.MANAGER_EMPLOYEE_UNIQUE_ID = employeeUnique;

    this.props.updateState(serverDepartment);
    this.modal.close();
  }

  async createDepartment({name, employee}) {
    const departmentPayload = {
      MANAGER_EMPLOYEE_ID: employee,
      NAME: name,
    };

    let serverDepartment = await request("departments", "POST", departmentPayload);

    this.props.addState(serverDepartment);
    this.modal.close();
  }

  async deleteDepartment() {
    const {department} = this.state;

    await request("departments/" + department.ID, "DELETE", {});

    this.props.updateState(department.ID);
    this.modal.close();
  }

  render() {
    const {department} = this.state;

    return (
      <Modal
        buttonLabel={department ? "Save" : "Create"}
        label={department ? "Edit Department" : "Add Department"}
        formikOnClick={() => this.formikRef}
        deleteOnClick={this.deleteDepartment.bind(this)}
        deleteLabel={department && "Remove"}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: department?.NAME ?? "",
            employee: department?.MANAGER_EMPLOYEE_ID ?? null,
            employeeName: department?.MANAGER_EMPLOYEE_NAME ?? null,
            employeeUnique: department?.MANAGER_EMPLOYEE_UNIQUE_ID ?? null,
          }}
          onSubmit={department ? this.saveDepartment.bind(this) : this.createDepartment.bind(this)}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Department name"
                  name="name"
                  placeholder="Front of House"
                  options={formikOptions}
                />

                <EmployeeDropdown
                  tooltip="Who is in charge of everyone in this department"
                  label="Department Manager"
                  name="employee"
                  options={formikOptions}
                  onChangeSoft={({label, unique}) => {
                    setFieldValue("employeeUnique", unique);
                    setFieldValue("employeeName", label);
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

DepartmentModal.propTypes = {
  addState: PropTypes.func,
  updateState: PropTypes.func,
};

export default DepartmentModal;

import React, {Component} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {Menu} from "@headlessui/react";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReactDOM from "react-dom";
import {Manager, Popper, Reference} from "react-popper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PopperDropdown extends Component {
  constructor(props) {
    super(props);

    this.id = "cd_" + randomString(24);
  }

  renderOptions(options, name, removable) {
    const {data, onChange} = this.props;

    return (
      <div className="flex flex-wrap flex-col justify-items-start">
        {data.map((item) => {
          return (
            <Menu.Item>
              <div
                className={
                  "flex flex-row space-x-4 p-3 py-2 items-center hover:bg-gray-100 hover:text-gray-600"
                }
                onClick={() => {
                  options && options.setFieldValue(name, item);
                  onChange && onChange(item);
                }}
              >
                <FontAwesomeIcon icon={item.icon} className={"w-4 h-4"} />

                <div className={"font-semibold text-sm text-gray-500 "}>{item.label}</div>
              </div>
            </Menu.Item>
          );
        })}

        {removable && (
          <Menu.Item>
            <div
              className={"px-2 py-1 font-bold text-sm text-red-500 hover:bg-gray-100 hover:text-red-600"}
              onClick={() => {
                options.setFieldValue(name, null);
                onChange && onChange(null);
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-xmark-large" />
            </div>
          </Menu.Item>
        )}
      </div>
    );
  }

  renderButton(value) {
    return <Menu.Button>Edit</Menu.Button>;
  }

  render() {
    const {relative, removable, container} = this.props;

    return (
      <FormElement id={this.id} {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft, {options, name}) => {
          return (
            <Manager>
              <Menu as="div" className="relative inline-block text-left">
                {({open}) => {
                  return (
                    <>
                      <Reference>
                        {({ref}) => (
                          <div className={"flex flex-row"} ref={ref}>
                            {this.renderButton(value)}
                          </div>
                        )}
                      </Reference>

                      {open &&
                        ReactDOM.createPortal(
                          <Popper
                            innerRef={(e) => (this.popperRef = e)}
                            placement={"bottom-start"}
                            strategy={"absolute"}
                          >
                            {({ref, style, placement}) => (
                              <Menu.Items
                                ref={ref}
                                data-placement={placement}
                                style={style}
                                unmount={false}
                                className={classNames(
                                  false ? "relative" : "absolute",
                                  "rounded-md origin bottom-right mt-1 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 cursor-pointer"
                                )}
                              >
                                {this.renderOptions(options, name, removable)}
                              </Menu.Items>
                            )}
                          </Popper>,
                          document.querySelector(`#${container ? container : this.id}`)
                        )}
                    </>
                  );
                }}
              </Menu>
            </Manager>
          );
        }}
      </FormElement>
    );
  }
}

export default PopperDropdown;

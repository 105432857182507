import React, {Component} from "react";
import Scheduler, {
  RESOURCE_IDS,
  SHIFT_VIEWS,
  SHIFT_VIEWS_DATA,
  SORT_BY,
  SORT_BY_ALPHABETICAL,
  SORT_BY_DATA,
  VIEWS,
  VIEWS_DATA,
} from "../../../features/scheduling/scheduler";
import {ChevronLeftIcon, ChevronRightIcon, ClipboardCheckIcon, PlusIcon} from "@heroicons/react/solid";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import FilterDropdown from "../../../components/filter-dropdown";
import SchedulerDropdown from "../../../features/scheduling/scheduler-dropdown";
import moment from "moment-timezone";
import {classNames, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {setSchedulingStats, updateLoading} from "../../../redux/scheduling-stats";
import LoadingSpinner from "../../../components/loading-spinner";
import CurrentViewButton from "../../../features/scheduling/current-view-button";
import PublishModal from "../../../modals/scheduling/publish-modal";
import TemplatesModal from "../../../modals/scheduling/templates-modal";
import UnavailabilityModal from "../../../modals/scheduling/unavailability-modal";
import PreferredModal from "../../../modals/scheduling/preferred-modal";
import {dayToIsoDay} from "../../../utils/util";
import ShiftModal from "../../../modals/scheduling/shift-modal";
import SchedulerTabModal from "../../../modals/shop/shop-settings/scheduler-tab-modal";
import ReorderModal from "../../../modals/scheduling/reorder-modal";
import ShiftTagsModal from "../../../modals/team/shift-tags-modal";
import {fetchToggleLocations} from "../../../utils/scheduling-helper";
import MultiTemplatesModal from "../../../modals/scheduling/multi-templates-modal";
import ReactToPrint from "react-to-print";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SchedulingPage extends Component {
  state = {locations: [], location: null};

  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();

    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    this.fetchLocations();
  }

  async setLocation(locationArr) {
    await this.props.setScheduler({locationArr});

    await this.calendarRef.current?.handleFetchingCurrentCalendarDatesSchedule();
  }

  async fetchLocations() {
    let {locations: franchiseLocations} = this.props.shop;

    franchiseLocations = franchiseLocations?.map((_location) => ({
      value: _location.ID,
      label: _location.NAME,
    }));

    this.setState({locations: franchiseLocations ?? []});
  }

  backInTime = () => {
    this.updateLoading(true);
    let {isLoading, currentView, currentDateString} = this.props.scheduling;

    if (isLoading) {
      return;
    }

    let currentMoment = moment(currentDateString);

    switch (currentView) {
      case VIEWS.DAY:
        currentMoment.subtract(1, "day");
        break;
      case VIEWS.MONTH:
        currentMoment.subtract(1, "month");
        break;
      default:
        currentMoment.subtract(1, "week");
        break;
    }

    this.calendarRef.current?.updateCalendarEpoch(currentMoment.valueOf());
  };

  forwardInTime = () => {
    this.updateLoading(true);
    let {currentView, currentDateString} = this.props.scheduling;

    let currentMoment = moment(currentDateString);

    switch (currentView) {
      case VIEWS.DAY:
        currentMoment.add(1, "day");
        break;
      case VIEWS.MONTH:
        currentMoment.add(1, "month");
        break;
      default:
        currentMoment.add(1, "week");
        break;
    }

    // this.currentViewButton.setEpochs(updateObj);
    this.calendarRef.current?.updateCalendarEpoch(currentMoment.valueOf());
  };

  handleTemplates = () => {
    let startOfWeek = this.calendarRef.current?.getCalendarWeekStartEpoch();
    this.templatesModal.open(startOfWeek);
  };

  handleSortAlphabetical = () => {
    const {sortByAlphabetical} = this.props.scheduling;
    let {employees, employeesWithoutRoles} = this.props.scheduling.schedule;
    if (sortByAlphabetical === SORT_BY_ALPHABETICAL.FIRST) {
      employees.sort((a, b) => a.FULL_NAME.localeCompare(b.FULL_NAME));
      for (let i = 1; i <= employees.length; i++) {
        employees[i - 1].SEQ = i;
      }
      employeesWithoutRoles.sort((a, b) => a.FULL_NAME.localeCompare(b.FULL_NAME));
      for (let i = 1; i <= employeesWithoutRoles.length; i++) {
        employeesWithoutRoles[i - 1].SEQ = i;
      }
    } else if (sortByAlphabetical === SORT_BY_ALPHABETICAL.LAST) {
      employees.sort((a, b) =>
        this.calendarRef.current
          ?.fetchLast(a.FULL_NAME)
          .localeCompare(this.calendarRef.current?.fetchLast(b.FULL_NAME))
      );
      for (let i = 1; i <= employees.length; i++) {
        employees[i - 1].SEQ = i;
      }
      employeesWithoutRoles.sort((a, b) =>
        this.calendarRef.current
          ?.fetchLast(a.FULL_NAME)
          .localeCompare(this.calendarRef.current?.fetchLast(b.FULL_NAME))
      );
      for (let i = 1; i <= employeesWithoutRoles.length; i++) {
        employeesWithoutRoles[i - 1].SEQ = i;
      }
    }

    return {employees, employeesWithoutRoles};
  };

  fetchFilterData() {
    let {employeesWithoutRoles, employees} = this.props.scheduling.schedule;
    const {shiftView, sortByAlphabetical} = this.props.scheduling;
    let isEmployeeView = shiftView === SHIFT_VIEWS.EMPLOYEES;
    let listToFilter;

    if (sortByAlphabetical !== 0) {
      const sortedEmployees = this.handleSortAlphabetical();
      employees = sortedEmployees?.employees;
      employeesWithoutRoles = sortedEmployees?.employeesWithoutRoles;
    }

    if (isEmployeeView) {
      listToFilter = employeesWithoutRoles.reduce((prev, curr) => {
        if (prev.findIndex((item) => curr.ACCOUNT_ID === item.value) === -1) {
          return [
            ...prev,
            {
              value: curr.ACCOUNT_ID,
              name: curr.FULL_NAME,
              SEQ: curr.SEQ,
            },
          ];
        }
        return prev;
      }, []);

      listToFilter.sort((a, b) => a.SEQ - b.SEQ);
    } else {
      listToFilter = employees.reduce((prev, curr) => {
        if (prev.findIndex((item) => curr.ROLE_ID === item.value) === -1) {
          return [
            ...prev,
            {
              value: curr.ROLE_ID,
              name: curr.ROLE_NAME,
              ROLE_SEQ: curr.ROLE_SEQ,
            },
          ];
        }
        return prev;
      }, []);

      listToFilter.sort((a, b) => a.ROLE_SEQ - b.ROLE_SEQ);
    }

    return listToFilter;
  }

  fetchToggleLocationArr() {
    return fetchToggleLocations(this.props.scheduling.locationArr, this.props.shop.location);
  }

  isToggledLocation() {
    const {locationArr: locationIdArr} = this.props.scheduling;

    if (locationIdArr === null || locationIdArr.length === 0) {
      return false;
    }

    if (
      Array.isArray(locationIdArr) &&
      locationIdArr.length === 1 &&
      locationIdArr[0] === this.props.shop.location.ID
    ) {
      return false;
    }

    return true;
  }

  updateLoading = (isLoading) => {
    this.props.updateLoading(isLoading);
  };

  render() {
    const {locations} = this.state;
    let {isLoading, currentView} = this.props.schedulingStats;
    let {shiftView, payViewPermission, sortBy, showLaborCost} = this.props.scheduling;
    let {ID: locationId, NAME: locationName} = this.props.shop.location;

    let isGroupView = shiftView === SHIFT_VIEWS.GROUP;
    let isRoleView = shiftView === SHIFT_VIEWS.ROLES;
    let isEmployeeView = shiftView === SHIFT_VIEWS.EMPLOYEES;
    let filterData = this.fetchFilterData();

    const isDayView = currentView === VIEWS.DAY;
    const renderPublish = currentView === VIEWS.DAY || currentView === VIEWS.WEEK;

    let hoursString = `${this.props.scheduling.schedule.locationShiftStats.totalHours.toFixed(2)} hrs`;

    let moneyString = ` - $${toDollars(this.props.scheduling.schedule.locationShiftStats.totalCost)}`;

    const selectedLocations = this.fetchToggleLocationArr();
    const isToggledLocation = this.isToggledLocation();

    return (
      <div>
        <ReorderModal
          refresh={this.calendarRef.current?.handleFetchingCurrentCalendarDatesSchedule}
          ref={(e) => (this.reorderModal = e)}
          shop={this.props.shop}
          locationId={this.props.scheduling.location ?? this.props.shop.location.ID}
        />
        <PublishModal
          locationIdArr={selectedLocations}
          locations={this.props.shop.locations}
          signedInLocationId={this.props.shop.location.ID}
          isToggledLocation={isToggledLocation}
          ref={(e) => (this.publishModal = e)}
        />
        <MultiTemplatesModal
          ref={(e) => (this.templatesModal = e)}
          locationIdArr={selectedLocations}
          locations={this.props.shop.locations}
          isToggledLocation={isToggledLocation}
          refresh={this.calendarRef.current?.handleFetchingCurrentCalendarDatesSchedule}
        />
        <UnavailabilityModal ref={(e) => (this.unavailabilityModal = e)} />
        <PreferredModal ref={(e) => (this.preferredModal = e)} />
        <ShiftModal
          ref={(e) => (this.shiftModal = e)}
          startDay={dayToIsoDay(this.props.shop.settings.SCHEDULER_FIRST_DAY)}
          locations={locations}
          isToggledLocation={isToggledLocation}
          locationIdArr={selectedLocations}
          upsertShifts={this.props.upsertShifts}
        />
        <SchedulerTabModal
          fetchSchedule={this.calendarRef.current?.handleFetchingCurrentCalendarDatesSchedule}
          locationIdArr={selectedLocations}
          ref={(e) => (this.schedulerSettingsModal = e)}
        />
        <ShiftTagsModal ref={(e) => (this.shiftTagsModal = e)} />

        <div>
          {locations && locations.length > 1 ? (
            <div className="flex flex-1">
              <FormSelect
                style={{width: 250}}
                className="text-sm mb-3"
                value={selectedLocations}
                data={locations}
                onChange={(selectedLocations) => {
                  if (selectedLocations.length === 0) {
                    this.setLocation([this.props.shop.location.ID]);
                  }

                  this.setLocation(selectedLocations);

                  localStorage.setItem("SCHEDULE_LOCATION_ARR", JSON.stringify(selectedLocations));
                }}
                multi
              />
            </div>
          ) : (
            <div className="text-lg font-semibold">{locationName}</div>
          )}

          {showLaborCost && (
            <div className="h-6">
              {!isLoading && hoursString}
              {payViewPermission && !isLoading && moneyString}
            </div>
          )}

          <div className="flex flex-row flex-wrap justify-between mt-1 mb-1 items-center">
            <div className="flex flex-row items-center flex-wrap">
              <div className="flex flex-row">
                <button
                  disabled={isLoading}
                  type="button"
                  className={`${
                    isLoading ? "bg-gray-100" : "bg-white"
                  } rounded-l-md inline-flex items-center px-1.5 py-0 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none}`}
                  onClick={this.backInTime}
                >
                  <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                </button>

                <CurrentViewButton
                  ref={(e) => (this.currentViewButton = e)}
                  updateView={(newEpoch) => this.calendarRef.current?.updateCalendarEpoch(newEpoch)}
                />
                {/*<button className="inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 cursor-default">*/}
                {/*  {this.props.schedulingStats.currentViewString}*/}
                {/*</button>*/}

                <button
                  type="button"
                  disabled={isLoading}
                  className={`${
                    isLoading ? "bg-gray-100" : "bg-white"
                  } rounded-r-md inline-flex items-center px-1.5 py-0 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none`}
                  onClick={this.forwardInTime}
                >
                  <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              </div>

              <div className="flex flex-row items-center">
                <FormSelect
                  style={{minWidth: 100}}
                  className="hidden md:inline mb-2.5 text-sm mr-3 md:ml-3"
                  value={currentView}
                  data={VIEWS_DATA}
                  onChange={async (newView) => {
                    this.updateLoading(true);
                    this.props.setSchedulingStats({currentView: newView});
                    await this.calendarRef.current?.handleCurrentViewChange(newView);
                  }}
                />
                {!isDayView && (
                  <FormSelect
                    style={{minWidth: 150}}
                    className="mb-2.5 text-sm"
                    value={shiftView}
                    data={SHIFT_VIEWS_DATA}
                    onChange={async (newView) => {
                      await this.props.setScheduler({shiftView: newView});
                      this.calendarRef.current?.handleFetchRouteParams(null, newView);
                    }}
                  />
                )}
                {isDayView && (
                  <FormSelect
                    style={{minWidth: 100}}
                    className="mb-2.5 text-sm"
                    value={sortBy}
                    data={SORT_BY_DATA}
                    onChange={(sortByView) => this.props.setScheduler({sortBy: sortByView})}
                  />
                )}
                {(isGroupView || isRoleView) && !isDayView && (
                  <FilterDropdown
                    enableReinitialize
                    initialValues={filterData.map((role) => role.value)}
                    plural={"Roles"}
                    initialText={"Filter Roles"}
                    className="text-sm ml-3"
                    data={filterData}
                    onChange={(rolesToView) => this.props.setScheduler({rolesToView})}
                  />
                )}
                {isEmployeeView && (
                  <FilterDropdown
                    enableReinitialize
                    initialValues={filterData.map((employee) => employee.value)}
                    plural={"Employees"}
                    initialText={"Filter Roles"}
                    className="text-sm ml-3"
                    data={filterData}
                    onChange={(employeesToView) => this.props.setScheduler({employeesToView})}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col align-middle md:flex-row items-center mt-4 mb-2 lg:mb-0 lg:mt-0">
              <>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <button
                        className="inline-flex items-center align-middle leading-4 mr-3 flex px-3 py-2.5 font-medium border border-gray-300 shadow-sm text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        type="button"
                      >
                        <FontAwesomeIcon className="h-4 w-4 mr-2" icon={"print"} />
                        Print
                      </button>
                    );
                  }}
                  content={() => this.printRef}
                />

                <SchedulerDropdown
                  shiftView={shiftView}
                  currentView={currentView}
                  handleAlphabeticalOrder={this.calendarRef.current?.handleSetAlphabetical}
                  templateHandler={this.handleTemplates}
                  copy={this.calendarRef.current?.handleCopyWeek}
                  toggleShowEmployees={this.calendarRef.current?.handleToggleShowEmployeesWithShifts}
                  toggleUnavailability={this.calendarRef.current?.handleToggleUnavailability}
                  toggleLaborCost={this.calendarRef.current?.handleToggleShowLaborCost}
                  togglePrintView={this.props.togglePrintView}
                  refresh={this.calendarRef.current?.handleFetchingCurrentCalendarDatesSchedule}
                  clearDrafts={this.calendarRef.current?.clearDrafts}
                  openSettings={() => {
                    this.schedulerSettingsModal.open();
                  }}
                  openReorder={() => {
                    this.reorderModal.open();
                  }}
                  openShiftTags={() => {
                    this.shiftTagsModal.open();
                  }}
                  className="inline-flex items-center align-middle leading-4 px-3"
                />

                <button
                  type="button"
                  className={`${
                    renderPublish && "mr-3"
                  } inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4
                    font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                  onClick={() => {
                    let currentDate = this.calendarRef.current?.fetchDate();
                    this.calendarRef.current?.handleEventCreation({
                      start: currentDate,
                      end: currentDate,
                      resource: null,
                    });
                  }}
                >
                  <PlusIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                  New Shift
                </button>

                {renderPublish && (
                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={async () => {
                      let {currentView, currentDateString} = this.props.scheduling;
                      const isDayView = currentView === VIEWS.DAY;

                      if (isDayView) {
                        this.publishModal.open(moment(currentDateString).valueOf());
                      } else {
                        this.publishModal.open(await this.props.getCalendarWeekStartEpoch());
                      }
                    }}
                  >
                    <ClipboardCheckIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                    Publish
                  </button>
                )}
              </>
            </div>
          </div>
        </div>
        <div ref={(e) => (this.printRef = e)} className={"relative overflow-visible"}>
          {isLoading && (
            <div className={"opacity-90 absolute h-full w-full bg-gray-100 top-0 z-10"}>
              <div className="mt-72">
                <LoadingSpinner color={"black"} size={40} borderSize={3} />
              </div>
            </div>
          )}

          <Scheduler
            ref={this.calendarRef}
            updateLoading={this.updateLoading}
            setCurrentViewString={this.setCurrentViewString}
            locationIdArr={selectedLocations}
            isToggledLocation={isToggledLocation}
            locations={locations}
          />
        </div>
      </div>
    );
  }
}

export default setupReduxConnection([
  "scheduling",
  "schedulingStats",
  "management",
  "shop",
  "user",
  "toolbar",
])(withRouter(SchedulingPage));

import React, {Component} from "react";
import {FormElement, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {getStripeKey, request} from "../../utils/request";
import {showSuccessAlert} from "../../utils/alert-helper";
import PropTypes from "prop-types";

import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(getStripeKey());

class BillingModal extends Component {
  open() {
    this.modal.open();
  }

  updateExternalAccount = async ({id}) => {
    await request("partner/billing/card", "POST", {
      paymentMethod: id,
    });

    this.props.refresh && this.props.refresh();
    this.modal.close();

    showSuccessAlert("Billing Information Saved", "Your billing information has been saved!");
  };

  render() {
    return (
      <Modal
        buttonLabel="Save"
        label="Billing Payment Information"
        buttonOnClick={() => this.cardForm.handleSubmit()}
        ref={(e) => (this.modal = e)}
      >
        <Elements stripe={stripePromise}>
          <FinalForm
            onSubmit={(paymentMethod) => this.updateExternalAccount(paymentMethod)}
            resetButton={() => {
              this.modal.fetchModalButton().stopLoading();
            }}
            ref={(e) => (this.cardForm = e)}
          />
        </Elements>
      </Modal>
    );
  }
}

class CardSetupForm extends Component {
  state = {error: null};

  handleSubmit = async (event) => {
    const {stripe, elements} = this.props;

    event && event.preventDefault();

    if (!stripe || !elements) {
      return console.log("No Stripe");
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      card: elements.getElement(CardElement),
      type: "card",
    });

    if (error) {
      this.props.resetButton(error.message);

      return this.setState({error: error.message});
    }

    this.props.onSubmit(paymentMethod);
  };

  render() {
    const {error} = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          <FormElement error={error} label="Credit Card">
            {() => (
              <div className="mt-2 px-4 py-3 bg-white rounded-md">
                <CardElement
                  onFocus={() => this.setState({error: null})}
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        padding: "8px",
                        backgroundColor: "white",
                        color: "#32325d",
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#fa755a",
                        iconColor: "#fa755a",
                      },
                    },
                  }}
                />
              </div>
            )}
          </FormElement>
        </label>
      </form>
    );
  }
}

const FinalForm = InjectedCardSetupForm(CardSetupForm);

function InjectedCardSetupForm(Component) {
  function ComponentWithProp({forwardedRef, ...props}) {
    return (
      <ElementsConsumer>
        {({stripe, elements}) => (
          <Component ref={forwardedRef} elements={elements} stripe={stripe} {...props} />
        )}
      </ElementsConsumer>
    );
  }

  return React.forwardRef((props, ref) => {
    return <ComponentWithProp {...props} forwardedRef={ref} />;
  });
}

BillingModal.propTypes = {
  refresh: PropTypes.func.isRequired,
};

export default BillingModal;

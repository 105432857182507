import React, {Component} from "react";
import FilterDropdown from "./filter-dropdown";
import {request} from "../utils/request";
import {setupReduxConnection} from "../redux";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class LocationFilterDropdown extends Component {
  state = {locations: []};

  async componentDidMount() {
    let {COMPANY_ID} = this.props.shop.location;

    let serverLocations = await request("report/locations", "GET");

    const locations = serverLocations.filter((item) => item.COMPANY_ID === COMPANY_ID);

    const locationDict = parseIdDict(locations);
    this.props.updateReportingLocations(locationDict);

    this.setState({locations});
  }

  render() {
    let {ID, NAME, COMPANY_ID} = this.props.shop.location;
    let {locationIdArray} = this.props.reporting;
    let {locations} = this.state;

    return (
      <FilterDropdown
        selectAll={true}
        noMl={true}
        cannotBeEmpty={true}
        initialText={NAME}
        enableReinitialize={true}
        initialValues={locationIdArray}
        defaultValue={ID}
        plural="Locations"
        data={locations
          .filter((location) => location.COMPANY_ID === COMPANY_ID)
          .map((location) => {
            return {
              name: location.NAME,
              value: location.ID,
            };
          })}
        onChange={this.props.onChange}
        {...this.props}
      />
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(LocationFilterDropdown);

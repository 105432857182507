import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../utils/navigation";
import VendorModal from "../../modals/accounting/vendor-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import SupplyComingSoonPage from "../operations/supply-chain/supply-coming-soon-page";
import {setupReduxConnection} from "../../redux";
import VendorGraph from "../../features/operations/supply-chain/vendors/vendor-graph";
import {VendorRequests} from "../../utils/request-helpers/supply-chain/supply-chain-requests";
import {isSupplyChainWhitelisted} from "../../utils/util";

class AccountingVendorsPage extends Component {
  state = {vendors: null};

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const vendors = await VendorRequests.fetchVendors();

    this.setState({vendors});
  }

  render() {
    const {vendors} = this.state;
    const {ACCOUNTING_WHITELISTED} = this.props.shop.settings;

    if (!vendors) {
      return <Loading />;
    }

    if (!isSupplyChainWhitelisted() && ACCOUNTING_WHITELISTED !== "1") {
      return <SupplyComingSoonPage />;
    }

    return (
      <div>
        <VendorModal ref={(e) => (this.vendorModal = e)} syncState={() => this.syncData()} />

        <PageHeadings
          label="Vendors"
          button={{
            label: "Create Vendor",
            onClick: () => this.vendorModal.open(),
          }}
          breadcrumbs={[{label: "Accounting", url: "/accounting"}]}
        />

        <Table
          className={"pt-5"}
          data={vendors}
          columns={[
            {value: "NAME", label: "Name"},
            {
              label: "Open Balance",
              format: (value) => {
                return toDollars(0, true);
              },
            },
          ]}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate(row.UNIQUE_ID);
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(AccountingVendorsPage));

import React, {Component} from "react";
import {request} from "../../../utils/request";
import {Card, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import CompareGraph from "./compare-graph";
import {setupReduxConnection} from "../../../redux";
import moment from "moment-timezone";
import DatePeriodSelect from "../../../components/date-period-select";
import LoadingSpinner from "../../../components/loading-spinner";

class CompareBlock extends Component {
  state = {
    basePeriod: "TODAY",
    baseStartEpoch: null,
    baseEndEpoch: null,
    baseSalesSummary: {},

    comparePeriod: "PRIOR",
    compareStartEpoch: null,
    compareEndEpoch: null,
    compareSalesSummary: {},

    toDateCompareSalesSummary: {},

    showGraph: false,
    loading: false,
  };

  async componentDidMount() {
    let {locationIdArray} = this.props.reporting;
    let {location} = this.props.shop;

    if (locationIdArray.length === 0) {
      this.props.updateLocationIdArray([location.ID]);
    }

    await this.generateEpochs();
    this.generateReport();
  }

  async generateEpochs() {
    let {basePeriod, comparePeriod, baseStartEpoch, baseEndEpoch} = this.state;

    if (basePeriod !== BASE_OPTIONS.CUSTOM.value) {
      baseStartEpoch = BASE_OPTIONS[basePeriod].getEpochs().baseStartEpoch;
      baseEndEpoch = BASE_OPTIONS[basePeriod].getEpochs().baseEndEpoch;
    }

    if (COMPARE_OPTIONS[comparePeriod].seq < BASE_OPTIONS[basePeriod].seq) {
      comparePeriod = COMPARE_OPTIONS.PRIOR.value;
    }

    const {compareStartEpoch, compareEndEpoch} = COMPARE_OPTIONS[comparePeriod].convertBase(
      baseStartEpoch,
      baseEndEpoch
    );

    await this.setState({
      baseStartEpoch,
      baseEndEpoch,

      compareStartEpoch,
      compareEndEpoch,
      comparePeriod,

      showGraph: false,
    });
  }

  getComparisonPeriodOptions() {
    let {basePeriod, baseStartEpoch, baseEndEpoch} = this.state;

    const options = Object.values(COMPARE_OPTIONS).filter(
      (_option) => _option.seq >= BASE_OPTIONS[basePeriod].seq
    );

    const dayCount = moment(baseEndEpoch).diff(moment(baseStartEpoch), "days") + 1;

    let priorStr = `Prior ${dayCount} days`;

    if (
      moment(baseStartEpoch).startOf("day").valueOf() === moment().startOf("day").valueOf() ||
      !baseStartEpoch
    ) {
      priorStr = "Yesterday";
    } else if (dayCount === 0) {
      priorStr = "Prior day";
    } else if (dayCount % 7 === 0 && dayCount / 7 > 1) {
      const weekCount = dayCount / 7;

      priorStr = `Prior ${weekCount} weeks`;
    }

    options[0].label = priorStr;

    return options;
  }

  generateReport = async () => {
    let {locationIdArray} = this.props.reporting;
    const {baseStartEpoch, baseEndEpoch, compareStartEpoch, compareEndEpoch} = this.state;

    this.setState({showGraph: false, loading: true});

    let [baseSalesSummary, compareSalesSummary, toDateCompareSalesSummary] = await Promise.all([
      request("report/salessummary", "POST", {
        START_EPOCH: baseStartEpoch,
        END_EPOCH: baseEndEpoch,
        LOCATION_ID_ARRAY: locationIdArray,
        EXECUTE_REPORTS: ["HOUR"],
        POPULATE_MISSING_DATES: false,
      }),
      request("report/salessummary", "POST", {
        START_EPOCH: compareStartEpoch,
        END_EPOCH: compareEndEpoch,
        LOCATION_ID_ARRAY: locationIdArray,
        EXECUTE_REPORTS: ["HOUR"],
        POPULATE_MISSING_DATES: false,
      }),
      request("report/salessummary", "POST", {
        START_EPOCH: compareStartEpoch,
        END_EPOCH:
          compareStartEpoch + Math.min(moment().valueOf() - baseStartEpoch, baseEndEpoch - baseStartEpoch),
        LOCATION_ID_ARRAY: locationIdArray,
        EXECUTE_REPORTS: ["HOUR"],
        POPULATE_MISSING_DATES: false,
      }),
    ]);

    this.setState({
      baseSalesSummary,
      compareSalesSummary,
      toDateCompareSalesSummary,
      showGraph: true,
      loading: false,
    });
  };

  render() {
    const {
      basePeriod,
      comparePeriod,
      showGraph,
      baseStartEpoch,
      baseEndEpoch,
      baseSalesSummary,

      compareStartEpoch,
      compareSalesSummary,
      compareEndEpoch,

      toDateCompareSalesSummary,

      loading,
    } = this.state;

    return (
      <div>
        <Card className={"pb-3 pl-6"}>
          <div className={"flex flex-row items-center"}>
            <FormSelect
              className={"w-36"}
              value={basePeriod}
              data={Object.values(BASE_OPTIONS)}
              onChange={async (value) => this.setState({basePeriod: value}, () => this.generateEpochs())}
            />

            {basePeriod === BASE_OPTIONS.CUSTOM.value && (
              <DatePeriodSelect
                className={"mt-3 ml-3"}
                onChange={({startEpoch, endEpoch}) => {
                  let payload = {
                    showGraph: false,
                  };

                  if (startEpoch) {
                    payload.baseStartEpoch = startEpoch;
                  }

                  if (endEpoch) {
                    payload.baseEndEpoch = moment(endEpoch).endOf("day").valueOf();
                  }

                  this.setState(payload, () => this.generateEpochs());
                }}
                value={{
                  startEpoch: baseStartEpoch,
                  endEpoch: baseEndEpoch,
                }}
              />
            )}

            <div className={"mt-3 mx-2 text-gray-600 font-semibold text-sm"}>compared to</div>

            <FormSelect
              className={"w-60"}
              value={comparePeriod}
              data={this.getComparisonPeriodOptions()}
              onChange={(value) => {
                this.setState({comparePeriod: value}, () => this.generateEpochs());
              }}
            />

            <div
              type="button"
              className="cursor-pointer mt-3 ml-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => this.generateReport()}
            >
              Generate
            </div>
          </div>
        </Card>

        <Card className={"p-6"}>
          {showGraph && (
            <CompareGraph
              baseStartEpoch={baseStartEpoch}
              baseEndEpoch={baseEndEpoch}
              baseSalesSummary={baseSalesSummary}
              compareStartEpoch={compareStartEpoch}
              compareEndEpoch={compareEndEpoch}
              compareSalesSummary={compareSalesSummary}
              toDateCompareSalesSummary={toDateCompareSalesSummary}
            />
          )}

          {loading && <LoadingSpinner />}
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "shop", "reporting"])(CompareBlock);

const PERIOD_SEQUENCES = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  DEFAULT: 4,
};

// getEpochs() are so moment-timezone can initialize
const BASE_OPTIONS = {
  TODAY: {
    seq: PERIOD_SEQUENCES.DAY,
    label: "Today",
    value: "TODAY",
    getEpochs: () => {
      return {
        baseStartEpoch: moment().startOf("day").valueOf(),
        baseEndEpoch: moment().endOf("day").valueOf(),
      };
    },
  },
  LAST_7: {
    seq: PERIOD_SEQUENCES.WEEK,
    label: "Last 7 days",
    value: "LAST_7",
    getEpochs: () => {
      return {
        baseStartEpoch: moment().subtract(7, "days").startOf("day").valueOf(),
        baseEndEpoch: moment().subtract(1, "day").endOf("day").valueOf(),
      };
    },
  },
  LAST_4_WEEKS: {
    seq: PERIOD_SEQUENCES.MONTH,
    label: "Last 4 weeks",
    value: "LAST_4_WEEKS",
    getEpochs: () => {
      return {
        baseStartEpoch: moment().subtract(4, "weeks").startOf("day").valueOf(),
        baseEndEpoch: moment().subtract(1, "day").endOf("day").valueOf(),
      };
    },
  },
  THIS_WEEK: {
    seq: PERIOD_SEQUENCES.WEEK,
    label: "This week",
    value: "THIS_WEEK",
    getEpochs: () => {
      return {
        baseStartEpoch: moment().startOf("week").valueOf(),
        baseEndEpoch: moment().endOf("week").valueOf(),
      };
    },
  },
  THIS_MONTH: {
    seq: PERIOD_SEQUENCES.MONTH,
    label: "This month",
    value: "THIS_MONTH",
    getEpochs: () => {
      return {
        baseStartEpoch: moment().startOf("month").valueOf(),
        baseEndEpoch: moment().endOf("month").valueOf(),
      };
    },
  },
  CUSTOM: {
    seq: PERIOD_SEQUENCES.DEFAULT,
    label: "Custom",
    value: "CUSTOM",
  },
};

const COMPARE_OPTIONS = {
  PRIOR: {
    value: "PRIOR",
    seq: PERIOD_SEQUENCES.DEFAULT,
    convertBase: (baseStartEpoch, baseEndEpoch) => {
      const dayDifference = moment(baseEndEpoch).diff(moment(baseStartEpoch), "days") + 1;

      return {
        compareStartEpoch: moment(baseStartEpoch).subtract(dayDifference, "day").startOf("day").valueOf(),
        compareEndEpoch: moment(baseEndEpoch).subtract(dayDifference, "day").endOf("day").valueOf(),
      };
    },
  },
  PREV_WEEK: {
    label: "Same period previous week",
    value: "PREV_WEEK",
    seq: PERIOD_SEQUENCES.WEEK,
    convertBase: (baseStartEpoch, baseEndEpoch) => {
      return {
        compareStartEpoch: moment(baseStartEpoch).subtract(7, "days").startOf("day").valueOf(),
        compareEndEpoch: moment(baseEndEpoch).subtract(7, "days").endOf("day").valueOf(),
      };
    },
  },
  PREV_MONTH: {
    label: "Same period previous month",
    value: "PREV_MONTH",
    seq: PERIOD_SEQUENCES.MONTH,
    convertBase: (baseStartEpoch, baseEndEpoch) => {
      const dayDifference = moment(baseEndEpoch).diff(moment(baseStartEpoch), "days");

      const compareStartEpoch = moment(baseStartEpoch).subtract(1, "month").startOf("day").valueOf();

      return {
        compareStartEpoch,
        compareEndEpoch: moment(compareStartEpoch).add(dayDifference, "day").endOf("day").valueOf(),
      };
    },
  },
};

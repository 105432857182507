import {getStore} from "../redux";
import {companyNameToSubdomain} from "./util";
import {DEFAULT_GIFT_CARDS} from "./marketing-constants";

export function getFullEmail(companyName) {
  const {MARKETING_EMAIL_USER, MARKETING_EMAIL_SUBDOMAIN, MARKETING_EMAIL_FROM} =
    getStore().getState().shop.companySettings;

  return `${MARKETING_EMAIL_FROM || companyName} <${MARKETING_EMAIL_USER}@${
    MARKETING_EMAIL_SUBDOMAIN ? MARKETING_EMAIL_SUBDOMAIN : companyNameToSubdomain(companyName)
  }.dripos.com>`;
}

export function isDefaultGiftCardImage(giftCardImage) {
  return DEFAULT_GIFT_CARDS.has(giftCardImage.LOGO);
}

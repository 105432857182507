import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {CASH_DRAWERS_COLUMNS, CELL_TYPES} from "../../features/reporting/reports/reporting-constants";
import moment from "moment";
import {withRouter} from "../../utils/navigation";

class CashDrawerReportPage extends Component {
  convertDataToRows = (reportData) => {
    let toReturn = [];

    if (reportData) {
      let {BREAKDOWN, TOTALS} = reportData;

      for (let drawer of BREAKDOWN) {
        drawer.AMOUNT_EXPECTED = drawer.AMOUNT_EXPECTED ?? 0;
        drawer.DEVICE_NAME = drawer.DEVICE_NAME ?? "Location Based Drawer";
        drawer.TOTAL_COLLECTED = drawer.TOTAL_COLLECTED ?? 0;
        drawer.DIFFERENCE = drawer.AMOUNT_CLOSE
          ? drawer.DIFFERENCE ?? drawer.AMOUNT_CLOSE - drawer.AMOUNT_EXPECTED
          : null;
        drawer.AMOUNT_VERIFIED = drawer.AMOUNT_VERIFIED ?? null;
        drawer.VERIFIED_DIFFERENCE = drawer.AMOUNT_VERIFIED
          ? drawer.AMOUNT_VERIFIED - drawer.AMOUNT_CLOSE
          : null;

        toReturn.push({
          label: moment(drawer.DATE_OPEN).format("MMM DD, hh:mm A"),
          numbers: drawer,
          rowType: CELL_TYPES.REGULAR,
        });
      }

      toReturn.push({
        label: "Totals",
        numbers: TOTALS,
        style: [CELL_TYPES.BOLD],
      });
    }

    return toReturn;
  };

  render() {
    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Cash Drawers Report"} />
        </div>

        <FixedColumnReport
          firstColLabel={"Open Time"}
          firstColTooltip="This column lists the time that the corresponding cash drawer was opened."
          filenamePrefix="cash-drawers"
          endpoint="report/cashdrawers"
          constantColumns={CASH_DRAWERS_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          locationPicker={false}
          onRowClick={(row) => {
            this.props.router.navigate(`/drawer/${row.numbers.ID}`);
          }}
          isRowClickable={(row) => {
            return row.numbers.ID;
          }}
        />
      </div>
    );
  }
}

export default withRouter(CashDrawerReportPage);

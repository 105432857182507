import React, {Component} from "react";
import {PageHeadings, Card, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {Formik} from "formik";
import FormCheckbox from "../../../components/form-elements/form-checkbox";
import {request} from "../../../utils/request";
import moment from "moment";
import {withRouter} from "../../../utils/navigation";
import PayrollWhitelistWrapper from "../../../features/payroll/payroll-whitelist-wrapper";

class PayrollJournalPage extends Component {
  handleSubmit = async ({startEpoch, endEpoch}) => {
    let body = {
      START_STRING: moment.utc(startEpoch).format("YYYY-MM-DD"),
      END_STRING: moment.utc(endEpoch).format("YYYY-MM-DD"),
    };

    this.setState({loading: true});

    request("payroll/journal", "POST", body)
      .then((journalData) => {
        downloadFile({
          data: journalData,
          fileName:
            "PayrollJournal" +
            moment(startEpoch).format("MMMDD") +
            "-" +
            moment(endEpoch).format("MMMDD") +
            ".csv",
          fileType: "text/csv",
        });
      })
      .catch(() => {
        alert("An error occurred generating your report. Please refresh the page and try again.");
      });
  };

  render() {
    return (
      <PayrollWhitelistWrapper router={this.props.router}>
        <PageHeadings
          label={"Payroll Journal"}
          description={"Get your payroll costs for a given date range"}
        />

        <Card>
          <Formik
            initialValues={{
              sections: "both",
              startEpoch: moment().valueOf(),
              endEpoch: moment().valueOf(),
            }}
            onSubmit={this.handleSubmit}
          >
            {(formikOptions) => {
              let {handleSubmit} = formikOptions;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="py-10 w-96 space-y-3 flex justify-center flex-col m-auto">
                    <div className="flex flex-row justify-between">
                      <FormDateTimeSelect
                        label="Start"
                        name="startEpoch"
                        options={formikOptions}
                        hideTime={true}
                        tooltip={{
                          label: "Start",
                          data: "Payruns with paydays after this date will be considered",
                        }}
                      />

                      <FormDateTimeSelect
                        label="End"
                        name="endEpoch"
                        options={formikOptions}
                        hideTime={true}
                        tooltip={{
                          label: "End",
                          data: "Payruns with paydays before this date will be considered",
                        }}
                      />
                    </div>

                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Download as CSV
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      </PayrollWhitelistWrapper>
    );
  }
}

const downloadFile = ({data, fileName, fileType}) => {
  const blob = new Blob([data], {type: fileType});

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export default withRouter(PayrollJournalPage);

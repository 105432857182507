import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";

class OrderWebsiteLinksModal extends Component {
  open() {
    this.modal.open();
  }

  handleSubmit = async (values) => {
    let {locationSlug, companySlug, passcode} = values;

    await request("partner/website", "POST", {SLUG: companySlug, PASSCODE: passcode});
    await request("location", "PATCH", {SLUG: locationSlug});

    this.formikRef && this.formikRef.resetForm();
    this.props.updateSlugs(values);
    this.modal.close();
  };

  render() {
    const {locationSlug, companySlug} = this.props.slugs;
    const {passcode} = this.props;

    return (
      <Modal
        label="Order Website Links"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{locationSlug, companySlug, passcode}}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="locationSlug" label="Location Slug" options={formikOptions} />

                <FormInput name="companySlug" label="Company Slug" options={formikOptions} />

                <FormInput
                  name="passcode"
                  label="Passcode"
                  options={formikOptions}
                  placeholder="Enter words or numbers"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default OrderWebsiteLinksModal;

import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {Formik} from "formik";
import ComboBox from "../../../../components/combobox";
import * as Yup from "yup";
import {PurchaseOrderRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class PurchaseOrderRecipientsModal extends Component {
  state = {recipient: null};

  open(recipient = null) {
    this.setState({recipient}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  addRecipient({contact}) {
    const {purchaseOrder} = this.props;
    const {recipient} = this.state;
    const contactObj = purchaseOrder.VENDOR_CONTACTS.find((c) => c.ID === contact);

    const recipientIndex = purchaseOrder.RECIPIENTS.findIndex((r) => r.ID === recipient?.ID && recipient);

    const recipientPayload = {
      FIRST_NAME: contactObj.FIRST_NAME,
      LAST_NAME: contactObj.LAST_NAME,
      PHONE: contactObj.PHONE,
      EMAIL: contactObj.EMAIL,
    };

    if (recipientIndex === -1) {
      purchaseOrder.RECIPIENTS.push(recipientPayload);
    } else {
      purchaseOrder.RECIPIENTS.splice(recipientIndex, 1, recipientPayload);
    }

    PurchaseOrderRequests.updatePurchaseOrder(purchaseOrder.ID, {
      ...purchaseOrder,
      ITEMS: null,
    })
      .then(() => {
        return this.props.syncState && this.props.syncState();
      })
      .then(() => {
        this.modal && this.modal.close();
      });
  }

  deleteRecipient() {
    const {purchaseOrder} = this.props;
    const {recipient} = this.state;

    const recipientIndex = purchaseOrder.RECIPIENTS.findIndex((r) => r.ID === recipient?.ID && recipient);

    if (recipientIndex !== -1) {
      purchaseOrder.RECIPIENTS.splice(recipientIndex, 1);

      PurchaseOrderRequests.updatePurchaseOrder(purchaseOrder.ID, {
        ...purchaseOrder,
        ITEMS: null,
      })
        .then(() => {
          return this.props.syncState && this.props.syncState();
        })
        .finally(() => {
          this.modal && this.modal.close();
        });
    }
  }

  render() {
    const {purchaseOrder} = this.props;
    const {recipient} = this.state;

    return (
      <Modal
        hideClose
        label="Add Purchase Order Recipient"
        buttonLabel="Add"
        deleteLabel={recipient ? "Delete" : ""}
        deleteOnClick={() => this.deleteRecipient()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={(values) => this.addRecipient(values)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            contact: null,
          }}
          validationSchema={Yup.object().shape({
            contact: Yup.number().nullable().required("Please select a contact."),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ComboBox
                  label="Vendor Contact"
                  options={formikOptions}
                  name="contact"
                  data={purchaseOrder.VENDOR_CONTACTS.map((contact) => ({
                    id: contact.ID,
                    label: `${contact.FIRST_NAME} ${contact.LAST_NAME} (${contact.EMAIL})`,
                    contact,
                  }))}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(PurchaseOrderRecipientsModal);

import React, {Component} from "react";
import {isLocal, isQE, request} from "../../utils/request";
import {PlaidLink} from "react-plaid-link";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {groupBy} from "lodash";
import LoadingSpinner from "../../components/loading-spinner";
import {FINANCIAL_ACCOUNT_TYPES} from "../../utils/accounting-constants";
import {setupReduxConnection} from "../../redux";
import AddBankConnectionsModal from "../../modals/accounting/add-bank-connections-modal";
import PlaidUpdateModal from "../../modals/accounting/plaid-update-modal";

class BankConnectionsPage extends Component {
  state = {banks: null, token: null, loading: false};

  componentDidMount() {
    this.props.loadLedgers();

    request("accounting/link", "POST", {
      REDIRECT: "https://dripos.ngrok.io/accounting/plaid",
    })
      .then(({link_token}) => {
        this.setState({token: link_token});
      })
      .catch((e) => console.log(e));

    this.syncState();
  }

  syncState = () => {
    const banks = request("accounting/ledgers/bank-connections", "GET").then((banks) => {
      this.setState({banks});
    });
  };

  onSuccess = (public_token, metadata) => {
    this.addBankConnectionsModal.open(metadata);
  };

  render() {
    const {banks, token} = this.state;

    const bankDict = groupBy(banks, "INSTITUTION");

    return (
      <div>
        <AddBankConnectionsModal syncState={this.syncState} ref={(e) => (this.addBankConnectionsModal = e)} />

        <PlaidUpdateModal ref={(e) => (this.plaidUpdateModal = e)} />

        <PageHeadings label={"Bank Connections"} />

        {!banks && <LoadingSpinner />}

        {banks && (
          <Card
            label={"Linked Accounts"}
            buttons={[
              {
                label: (
                  <PlaidLink
                    token={token}
                    style={{backgroundColor: "#6365f1", border: "none"}}
                    onSuccess={this.onSuccess}
                    // onEvent={...}
                    onExit={this.onExit}
                  >
                    + Add
                  </PlaidLink>
                ),
              },
            ]}
          >
            <Table
              columns={[
                {
                  label: "Institution",
                  value: "NAME",
                  format: (value, row) => (
                    <div>
                      <div className={"font-semibold text-black"}>{value}</div>
                      <div>
                        {row.COUNT} {`Connection${row.COUNT > 1 ? "s" : ""}`}
                      </div>
                    </div>
                  ),
                },
              ]}
              data={Object.keys(bankDict).map((_key) => ({
                NAME: _key,
                COUNT: bankDict[_key].length,
                CONNECTIONS: bankDict[_key],
              }))}
              expandable={(row) => {
                return (
                  <div>
                    <Table
                      className={"p-5"}
                      actionButtons={[
                        {
                          label: "Update",
                          onClick: async (row) => {
                            this.plaidUpdateModal.open(row);
                          },
                        },
                        {
                          label: "Remove",
                          onClick: async (row) => {
                            await request(`accounting/ledgers/${row.UNIQUE_ID}/bank-connection`, "DELETE");
                          },
                        },
                      ]}
                      columns={[
                        {
                          label: "Name",
                          value: "NAME",
                          format: (value) => value,
                        },
                        {
                          label: "Type",
                          value: "FINANCIAL_ACCOUNT_TYPE",
                          format: (value) => FINANCIAL_ACCOUNT_TYPES[value].label,
                        },
                      ]}
                      data={JSON.parse(JSON.stringify(row.CONNECTIONS))}
                    />
                  </div>
                );
              }}
            />
          </Card>
        )}

        {(isLocal() || isQE()) && (
          <Card label={"Demo"}>
            <div className={"p-6 flex flex-row space-x-3 items-center"}>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={async () => {
                  await request("accounting/demo", "POST");
                }}
              >
                Reset Demo
              </button>
            </div>
          </Card>
        )}
      </div>
    );
  }
}

const LINK_TYPES = {
  CREDIT_CARD: "Credit Card",
};

export default setupReduxConnection(["accounting"])(BankConnectionsPage);

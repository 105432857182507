import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import DeviceDropdown from "../../../dropdowns/operations/device-dropdown";
import * as Yup from "yup";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";

class AddDrawerModal extends Component {
  state = {template: null};

  open(template) {
    this.setState({template}, () => this.modal.open());
  }

  async addDrawer(values) {
    const {template} = this.state;
    const {syncState} = this.props;
    const {amount, device} = values;

    await request("storebanks/templates/drawers", "POST", {
      TEMPLATE: template.ID,
      AMOUNT: decimalToDollars(amount.toString()),
      DEVICE: device,
    });

    syncState();
    this.modal.close();
  }

  render() {
    return (
      <Modal
        label={"Add Drawer"}
        buttonLabel={"Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={(values) => this.addDrawer(values)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            device: null,
            amount: null,
          }}
          validationSchema={Yup.object().shape({
            device: Yup.string().nullable().required("Please enter a drawer number"),
            amount: Yup.string().nullable().required("Please enter an opening amount"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <DeviceDropdown name={"device"} options={formikOptions} />

                <FormInput name={"amount"} label={"Opening Amount"} options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default AddDrawerModal;

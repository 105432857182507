import React, {Component} from "react";
import {request} from "../../../../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import ReviewAndConfirmFileModal from "../../../../../modals/team/employee/onboarding/review-and-confirm-file-modal";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import SignatureModal from "../../../../../modals/team/employee/onboarding/signature-modal";
import {Document, Page} from "react-pdf";
import {I9_PART_2_PREFILL_FIELDS, I9_PART_2_SPANISH_PREFILL_FIELDS} from "../../../../../utils/constants";
import {PDFDocument} from "pdf-lib";
import moment from "moment/moment";
import SignatureField from "../../../../../components/pdf-editor/signature-field";
import {cloneDeep} from "lodash";
import i18next from "i18next";

class I9Part2SignPage extends Component {
  state = {
    document: null,
    field: {
      X_COORDINATE: 36,
      Y_COORDINATE: 504,
      WIDTH: 232,
      HEIGHT: 28,
      ID: -1,
    },
  };

  componentDidMount() {
    request("company-documents/i9/part2", "GET").then(async (document) => {
      document = await this.applyAnnotations(document);

      this.setState({document});
    });
  }
  async submitDocument(document) {
    const {employee, details} = this.props;
    await request("employees/file/" + employee.ID, "POST", document);

    return request(
      "checklists/instances/" + details.CHECKLIST_INSTANCE_ID + "/item/" + details.ID + "/resolve",
      "POST"
    );
  }

  async handleSubmit() {
    const {document, field} = this.state;
    const documentCopy = cloneDeep(document);
    documentCopy.FILE_NAME = "I9_Part_2.pdf";
    documentCopy.DATA.data = await this.writeSignature(field, documentCopy);

    const result = await this.confirmModal.open(documentCopy);

    if (!!result) {
      this.submitDocument({
        KEY: result.KEY,
        FILE_NAME: "I9_Part2.pdf",
        BUCKET: "onboarding-company-documents",
      });

      this.props.formikOptions.setFieldValue("i9DocumentPart2", result);
    }

    return !!result;
  }

  async writeSignature(field, document) {
    const editablePDF = await PDFDocument.load(new Uint8Array(document.DATA.data).buffer);

    const {employee} = this.props;

    const imageBytes = await fetch(field.VALUE).then((data) => data.arrayBuffer());

    const pngImage = await editablePDF.embedPng(imageBytes);
    const page = editablePDF.getPages()[field.PAGE ? field.PAGE - 1 : 0];

    page.drawImage(pngImage, {
      x: field.X_COORDINATE,
      y: page.getHeight() - field.Y_COORDINATE - field.HEIGHT,
      width: field.WIDTH,
      height: field.HEIGHT,
    });

    request("company-documents/signature/image", "POST", {
      FILE: Array.from(new Uint8Array(imageBytes)),
    }).then(({url}) => {
      request("company-documents/signatures", "POST", {
        DATE_SIGNED: Date.now(),
        DOCUMENT_FIELD_ID: null,
        SIGNATURE_IMAGE_URL: url,
        ACCOUNT_ID: employee.ACCOUNT_ID,
      });
    });

    return editablePDF.save();
  }

  writeTodaysDate(page) {
    page.drawText(moment().format("M/D/YYYY"), {
      x: 267,
      y: page.getHeight() - 530,
      width: 114,
      height: 28,
      size: 12,
    });
  }

  async applyAnnotations(document) {
    const {values} = this.props.formikOptions;

    const editablePDF = await PDFDocument.load(new Uint8Array(document.DATA.data).buffer);

    for (const field of editablePDF.getForm().getFields()) {
      editablePDF.getForm().removeField(field);
    }

    const isEnglish = i18next.language === "en";

    const page = isEnglish ? editablePDF.getPages()[0] : editablePDF.getPages()[1];

    const I9_FIELDS = isEnglish ? I9_PART_2_PREFILL_FIELDS : I9_PART_2_SPANISH_PREFILL_FIELDS;

    Object.keys(I9_FIELDS).forEach((field) => {
      const fieldValue = values[field];

      if (!fieldValue) {
        return;
      }

      const {X_COORDINATE, Y_COORDINATE, WIDTH, HEIGHT} = I9_FIELDS[field];

      page.drawText(fieldValue, {
        x: X_COORDINATE + 4,
        y: page.getHeight() - Y_COORDINATE - HEIGHT + 5,
        width: WIDTH,
        height: HEIGHT,
        size: 12,
        blendMode: "Overlay",
      });
    });

    this.writeTodaysDate(page);

    const pdfBytes = await editablePDF.save();

    return {
      ...document,
      DATA: {
        type: "Buffer",
        data: pdfBytes,
      },
    };
  }

  renderFields() {
    const {signed, field} = this.state;

    return (
      <SignatureField
        field={field}
        onClick={async () => {
          const result = await this.applySignature(field);

          this.setState({signed: !!result}, () => {
            this.props.formikOptions.setFieldValue("i9SignedPart2", !!result);
          });
        }}
        selected={false}
        key={"SIGNATURE"}
        completed={signed}
        message={"Click here to sign"}
      />
    );
  }

  async applySignature() {
    const {field} = this.state;

    field.VALUE = await this.signatureModal.open(field);

    if (!field.VALUE) {
      return false;
    }

    this.setState({field});
    return true;
  }

  render() {
    const {document} = this.state;
    const {employee} = this.props;

    if (!document) {
      return <Loading />;
    }

    return (
      <>
        <ReviewAndConfirmFileModal
          ref={(e) => (this.confirmModal = e)}
          employee={employee}
          key={randomString(24)}
        />

        <SignatureModal
          key={randomString(24)}
          ref={(e) => (this.signatureModal = e)}
          document={document}
          employee={employee}
        />

        <div className={"relative w-full flex flex-col justify-center align-middle items-center"}>
          <div className={"h-[32rem] relative overflow-y-scroll"}>
            <Document file={document.DATA}>
              <Page pageNumber={i18next.language === "en" ? 1 : 2} />
              {this.renderFields()}
            </Document>
          </div>
        </div>
      </>
    );
  }
}

export default I9Part2SignPage;

import {FormInput, Modal, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";

class GiftCardFormatModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async ({min, max, chars}) => {
    let payload = {
      GIFT_CARD_PAN_MIN_LENGTH: min ?? "",
      GIFT_CARD_PAN_MAX_LENGTH: max ?? "",
      GIFT_CARD_PAN_CHARACTERS: chars ?? "",
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});
    let settings = await request("settings/shop", "GET");

    this.props.updateShop({
      settings,
    });

    this.modal.close();
  };

  render() {
    const {GIFT_CARD_PAN_MIN_LENGTH, GIFT_CARD_PAN_MAX_LENGTH, GIFT_CARD_PAN_CHARACTERS} =
      this.props.shop.settings;

    return (
      <Modal
        label="Gift Card Format Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          // TODO yup validation for these names i.e. no slashes or numbers etc where applicable
          validationSchema={Yup.object({
            min: Yup.number().integer().nullable(),
            max: Yup.number().integer().nullable(),
            chars: Yup.string().nullable(),
          })}
          initialValues={{
            min: GIFT_CARD_PAN_MIN_LENGTH,
            max: GIFT_CARD_PAN_MAX_LENGTH,
            chars: GIFT_CARD_PAN_CHARACTERS,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput
                    flex
                    name="min"
                    label="Minimum Length"
                    options={formikOptions}
                    placeholder={"10"}
                  />

                  <FormInput
                    flex
                    name="max"
                    label="Maximum Length"
                    options={formikOptions}
                    placeholder={"20"}
                  />
                </FormRow>

                <FormTextArea
                  name="chars"
                  label="Gift Card Number Characters"
                  placeholder={"ABCabc1234567890-"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(GiftCardFormatModal);

import React, {Component} from "react";
import {FormInput, FormTextArea, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import FormStateSelect from "../../components/form-state-select";
import FormRow from "../../components/form-row";
import {VendorRequests} from "../../utils/request-helpers/supply-chain/supply-chain-requests";

class VendorModal extends Component {
  state = {vendor: null};

  open(vendor = null) {
    this.setState({vendor}, () => this.modal.open());
  }

  async saveVendor({name, address, city, state, zip, website, notes}) {
    const {vendor} = this.state;

    const payload = {
      NAME: name,
      ADDRESS: address,
      CITY: city,
      STATE: state,
      ZIPCODE: zip,
      WEBSITE: website,
      NOTES: notes,
    };

    await VendorRequests.updateVendor(vendor.ID, payload);
    this.props.syncState && (await this.props.syncState());
    this.modal.close();
  }

  async createVendor({name, address, city, state, zip, website, notes}) {
    const payload = {
      NAME: name,
      ADDRESS: address,
      CITY: city,
      STATE: state,
      ZIPCODE: zip,
      WEBSITE: website,
      NOTES: notes,
    };

    await VendorRequests.createVendor(payload);

    this.props.syncState && (await this.props.syncState());
    this.modal.close();
  }

  render() {
    const {vendor} = this.state;

    return (
      <Modal
        buttonLabel={vendor ? "Save" : "Create"}
        label={vendor ? "Edit Vendor" : "Create Vendor"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={(values) => (vendor ? this.saveVendor(values) : this.createVendor(values))}
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: vendor?.NAME,
            address: vendor?.ADDRESS,
            city: vendor?.CITY,
            state: vendor?.STATE,
            zip: vendor?.ZIPCODE,
            website: vendor?.WEBSITE,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            address: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zip: Yup.number().integer().positive(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Vendor Name (Required)" name="name" options={formikOptions} />

                <FormInput label="Address" name="address" options={formikOptions} />

                <FormInput label="City" name="city" options={formikOptions} />

                <FormRow>
                  <FormStateSelect label="State" name="state" options={formikOptions} />

                  <FormInput label="Zip" name="zip" options={formikOptions} flex />
                </FormRow>

                <FormInput label={"Website"} name={"website"} options={formikOptions} />

                <FormTextArea label={"Notes"} name={"notes"} options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default VendorModal;

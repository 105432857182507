import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment";
import {request} from "../../../../utils/request";
import TicketTypeDetailsModal from "../../../../modals/sales/types/ticket-type-details-modal";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";

class TicketTypesPage extends Component {
  state = {
    isLoading: true,
    types: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let types = await request("types/order", "GET");

    types.sort((a, b) => {
      if (a.SEQ === 0) return 1;
      if (b.SEQ === 0) return -1;
      return a.SEQ - b.SEQ;
    });

    this.setState({isLoading: false, types});
  }

  saveSequence() {
    const {types} = this.state;

    request("types/order", "PATCH", {
      TYPES: types,
    });
  }

  render() {
    const {isLoading, types} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Ticket Types"
          description="View and modify the ticket types available at checkout"
          button={{
            label: "Create Ticket Type",
            onClick: () => this.ticketTypeModal.open(),
          }}
        />

        <TicketTypeDetailsModal
          ref={(e) => (this.ticketTypeModal = e)}
          addState={(type) => {
            this.props.router.navigate("/flow/ticket/" + type.UNIQUE_ID);
          }}
        />

        <Table
          draggable
          onDrag={this.saveSequence.bind(this)}
          pagination
          data={types}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/flow/ticket/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "TYPE",
              label: "Type",
            },
            {
              value: "ENABLED",
              label: "Status",
              format: (value, row) => {
                return (
                  <div className="flex-col">
                    <div>
                      {value === 1 || value === "1" ? (
                        <SuccessBadge>Enabled</SuccessBadge>
                      ) : (
                        <DangerBadge>Disabled</DangerBadge>
                      )}
                    </div>

                    {(row.TAX_DISABLED === 1 || row.TAX_DISABLED === "1") && (
                      <div className="mt-1">
                        <DangerBadge yellow>Tax Disabled</DangerBadge>
                      </div>
                    )}
                  </div>
                );
              },
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value, _) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              value: "DATE_UPDATED",
              label: "Updated",
              mobile: "lg:table-cell",
              format: (value) => {
                return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(TicketTypesPage));

import React, {Component} from "react";
import ReactDOM from "react-dom";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./i18n/config";
import "./index.scss";
import "./layouts/icons";
import "@frostbyte-technologies/frostbyte-core/dist/utils/prototype-injector";

import PagesIndex from "./pages";
import {Provider} from "react-redux";
import {setupStore} from "./redux";
import ErrorWrapper from "./layouts/wrappers/error-wrapper";
import nodePackage from "./../package.json";

import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

if (process.env.REACT_APP_ENV !== "development") {
  Sentry.init({
    release: nodePackage.name + "-@" + nodePackage.version,
    dsn: "https://c0ad7463538647cb88477fe8569cb44d@o434870.ingest.sentry.io/4504874871881728",
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [
          "aapi.dripos.com",
          "bapi.dripos.com",
          "testapi.dripos.com",
          "api.dripdrinks.co",
          "localhost",
        ],
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: ["dripos.com", "checkhq.com"],
        blockAllMedia: false,
        maskAllText: false,
      }),
    ],
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    normalizeDepth: 8,
  });
}

class SafeWrapper extends Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

let ReadWrapper = ErrorWrapper;
if (process.env.REACT_APP_ENV === "development") {
  ReadWrapper = SafeWrapper;
}

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={setupStore()}>
        <ReadWrapper>
          <PagesIndex />
        </ReadWrapper>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import {applyMiddleware, bindActionCreators, combineReducers, compose, createStore} from "redux";
import {connect} from "react-redux";
import thunk from "redux-thunk";

import * as ShopActions from "./shop";
import {shopReducer} from "./shop";
import * as MenuActions from "./menu";
import {menuReducer} from "./menu";
import * as UserActions from "./user";
import {userReducer} from "./user";
import * as ReportingActions from "./reporting";
import {reportingReducer} from "./reporting";
import * as PayrollActions from "./payroll";
import {payrollReducer} from "./payroll";
import * as BankingActions from "./banking";
import {bankingReducer} from "./banking";
import * as NotificationActions from "./notification";
import {notificationReducer} from "./notification";
import * as AlertActions from "./alert";
import {alertReducer} from "./alert";
import * as SchedulingActions from "./scheduling";
import {schedulingReducer} from "./scheduling";
import * as SchedulingStatsActions from "./scheduling-stats";
import {schedulingStatsReducer} from "./scheduling-stats";
import * as LayoutActions from "./layout";
import {layoutReducer} from "./layout";
import * as ChatActions from "./chat";
import {chatReducer} from "./chat";
import * as AccountingActions from "./accounting";
import {accountingReducer} from "./accounting";
import {supplyReducer} from "./supply";
import {adminReducer} from "./admin";
import * as AdminActions from "./admin";

let store = null;

export function setupStore() {
  if (!store) {
    store = createStore(
      combineReducers({
        shop: shopReducer,
        menu: menuReducer,
        user: userReducer,
        reporting: reportingReducer,
        payroll: payrollReducer,
        banking: bankingReducer,
        notification: notificationReducer,
        alert: alertReducer,
        scheduling: schedulingReducer,
        schedulingStats: schedulingStatsReducer,
        layout: layoutReducer,
        chat: chatReducer,
        accounting: accountingReducer,
        supply: supplyReducer,
        admin: adminReducer,
      }),
      compose(
        applyMiddleware(thunk),
        typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : (f) => f
      )
    );
  }

  return store;
}

export function getStore() {
  return store;
}

export function getActions() {
  return {
    ...ShopActions,
    ...MenuActions,
    ...UserActions,
    ...ReportingActions,
    ...PayrollActions,
    ...BankingActions,
    ...NotificationActions,
    ...AlertActions,
    ...SchedulingActions,
    ...SchedulingStatsActions,
    ...LayoutActions,
    ...ChatActions,
    ...AccountingActions,
    ...AdminActions,
  };
}

export function setupReduxConnection(getProps, mergeProps = null, connectOptions = {}) {
  function mapStateToProps(state) {
    if (Array.isArray(getProps)) {
      return getProps.reduce((dict, item) => {
        dict[item] = state[item];
        return dict;
      }, {});
    }

    return getProps(state);
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(getActions(), dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    forwardRef: true,
    ...connectOptions,
  });
}

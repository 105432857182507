import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import RecurringInvoiceForm from "../../../forms/operations/invoices/recurring-invoice-form";
import {RecurringInvoiceRequests} from "../../../utils/request-helpers/invoices/recurring-invoice-requests";

class RecurringInvoiceModal extends Component {
  state = {schedule: null};

  open(schedule = null) {
    this.setState({schedule}, () => {
      this.modal.open();
    });
  }

  async saveInvoice({interval, frequency, anchor, due, start, end}) {
    const {schedule} = this.state;

    const serverSchedule = await RecurringInvoiceRequests.update(schedule.ID, {
      INTERVAL_TYPE: interval,
      FREQUENCY: frequency,

      BILLING_ANCHOR: anchor,
      DAYS_UNTIL_DUE: due,

      DATE_START: start,
      DATE_END: end,
    });

    this.props.updateState(serverSchedule);
    this.modal.close();
  }

  render() {
    const {schedule} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Recurring Invoice"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <RecurringInvoiceForm
          handleSubmit={this.saveInvoice.bind(this)}
          ref={(e) => (this.formikRef = e)}
          schedule={schedule}
        />
      </Modal>
    );
  }
}

RecurringInvoiceModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default RecurringInvoiceModal;

import {Modal, FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FormCategorySelect from "../../../components/form-category-select";
import FormProductSelect from "../../../components/form-product-select";
import * as Yup from "yup";

class GratuityModal extends Component {
  state = {rule: null};

  open(rule = null) {
    this.setState({rule}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async ({name, type, isCount, threshold, gratuityAmt, products, categories}) => {
    const {rule} = this.state;

    const gratuityRule = {
      NAME: name,
      TYPE: type,
      THRESHOLD: parseInt(isCount) === 1 ? threshold : decimalToDollars("" + threshold),
      IS_COUNT: parseInt(isCount) === 1,
      GRATUITY_AMOUNT: gratuityAmt,
      IS_PERCENT: true,
      PRODUCTS: type === "PRODUCT" ? products : [],
      CATEGORIES: type === "CATEGORY" ? categories : [],
    };

    if (rule) {
      await request("gratuity/" + rule.ID, "PATCH", {
        ...gratuityRule,
      });
    } else {
      await request("gratuity/", "POST", {
        ...gratuityRule,
      });
    }

    this.props.onSubmit && this.props.onSubmit();
    this.modal.close();
  };

  handleDelete = async () => {
    const {ID} = this.state.rule;

    await request("gratuity/" + ID, "DELETE");

    this.props.onDelete && this.props.onDelete();
    this.modal.close();
  };

  render() {
    const {rule} = this.state;

    return (
      <Modal
        label="Auto-Gratuity Rule"
        buttonLabel={rule ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        deleteLabel={rule ? "Delete" : null}
        deleteOnClick={this.handleDelete}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            type: Yup.string().required("Type is required"),
            threshold: Yup.number()
              .integer()
              .positive()
              .required("Threshold is required")
              .typeError("Please only enter a number"),
            isCount: Yup.number().required("Count is required"),
            gratuityAmt: Yup.number()
              .integer()
              .positive()
              .required("Gratuity Amount is required")
              .typeError("Please only enter a number"),
          })}
          initialValues={{
            name: rule?.NAME,
            type: rule?.TYPE,
            threshold:
              rule?.IS_COUNT === 1 || rule?.IS_COUNT === "1"
                ? rule?.THRESHOLD
                : rule?.THRESHOLD
                ? toDollars(rule.THRESHOLD)
                : null,
            isCount: rule?.IS_COUNT,
            gratuityAmt: rule?.GRATUITY_AMOUNT,
            products: rule?.PRODUCTS?.map((product) => product.PRODUCT_ID) ?? [],
            categories: rule?.CATEGORIES?.map((category) => category.CATEGORY_ID) ?? [],
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  label="Gratuity Rule Name"
                  name="name"
                  placeholder={"Gratuity added for more than 5 coffees"}
                  tooltip={{
                    label: "Rule Name",
                    data: "A name to easily identify this rule.",
                  }}
                />

                <FormSelect
                  options={formikOptions}
                  label="Type"
                  name="type"
                  data={[
                    {value: "ALL", label: "All"},
                    {value: "PRODUCT", label: "Product Based"},
                    {value: "CATEGORY", label: "Category Based"},
                  ]}
                  tooltip={{
                    data: [
                      {
                        label: "All",
                        data: 'Rules with type "All" will be applied to all products on an order.',
                      },
                      {
                        label: "Product Based",
                        data: "Product based rules will be applied only to a selected set of products.",
                      },
                      {
                        label: "Category Based",
                        data: "Category based rules will be applied only to products in a selected set of categories.",
                      },
                    ],
                  }}
                />

                <FormSelect
                  options={formikOptions}
                  label="Threshold Type"
                  name="isCount"
                  data={[
                    {value: "1", label: "Count"},
                    {value: "0", label: "Dollar"},
                  ]}
                  tooltip={{
                    label: "Threshold Type",
                    data:
                      "If the threshold type is count, then the threshold will be interpreted as a count (e.g. 5" +
                      " coffees). Otherwise, threshold will be interpreted as a dollar amount.",
                  }}
                />

                <FormInput
                  options={formikOptions}
                  label="Apply Threshold"
                  name="threshold"
                  placeholder={values?.isCount === "1" ? "5 items" : "$50"}
                  tooltip={{
                    label: "Threshold",
                    data: "The threshold that if reached will automatically apply gratuity.",
                  }}
                />

                <FormInput
                  options={formikOptions}
                  label="Gratuity Amount (Percent)"
                  name="gratuityAmt"
                  placeholder="10%"
                  tooltip={{
                    label: "Gratuity Amount",
                    data:
                      "The amount of gratuity that should be automatically applied if this rule is satisfied. This" +
                      " number is interpreted as a percentage.",
                  }}
                />

                {values.type === "PRODUCT" && (
                  <FormProductSelect
                    label="Products"
                    name="products"
                    options={formikOptions}
                    tooltip={{
                      data: "Select which products this auto gratuity rule should apply to.",
                    }}
                    multi
                  />
                )}

                {values.type === "CATEGORY" && (
                  <FormCategorySelect
                    label="Categories"
                    name="categories"
                    options={formikOptions}
                    tooltip={{
                      data: "Select which categories this auto gratuity rule should apply to.",
                    }}
                    multi
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default GratuityModal;

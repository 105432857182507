import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import FormStateSelect from "../../../../components/form-state-select";
import FormRow from "../../../../components/form-row";
import * as Yup from "yup";
import {VendorRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class VendorModal extends Component {
  state = {vendor: null};

  open(vendor = null) {
    this.setState({vendor}, () => this.modal.open());
  }

  async saveVendor({name, address, city, state, zip}) {
    const {syncState} = this.props;
    const {vendor} = this.state;

    const payload = {
      NAME: name,
      ADDRESS: address,
      CITY: city,
      STATE: state,
      ZIPCODE: zip,
    };

    await VendorRequests.updateVendor(vendor.ID, payload);
    syncState();
    this.modal.close();
  }

  async createVendor({name, address, city, state, zip}) {
    const {syncState} = this.props;
    const payload = {
      NAME: name,
      ADDRESS: address,
      CITY: city,
      STATE: state,
      ZIPCODE: zip,
    };

    await VendorRequests.createVendor(payload);
    syncState();
    this.modal.close();
  }

  render() {
    const {vendor} = this.state;

    return (
      <Modal
        buttonLabel={vendor ? "Save" : "Create"}
        label={vendor ? "Edit Vendor" : "Create Vendor"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        tooltip={{
          label: "Vendor Information",
          data: "This is the information that will be listed on the purchase orders sent to this vendor.",
        }}
      >
        <Formik
          onSubmit={(values) => (vendor ? this.saveVendor(values) : this.createVendor(values))}
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: vendor?.NAME,
            address: vendor?.ADDRESS,
            city: vendor?.CITY,
            state: vendor?.STATE,
            zip: vendor?.ZIPCODE,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            address: Yup.string().nullable().default(null),
            city: Yup.string().nullable().default(null),
            state: Yup.string().nullable().default(null),
            zip: Yup.number().integer().positive().nullable().default(null),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Vendor Name" name="name" options={formikOptions} />

                <FormInput label="Address" name="address" options={formikOptions} />

                <FormInput label="City" name="city" options={formikOptions} />

                <FormRow>
                  <FormStateSelect label="State" name="state" options={formikOptions} />

                  <FormInput label="Zip" name="zip" options={formikOptions} flex />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default VendorModal;

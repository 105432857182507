import React, {Component} from "react";
import VendorDropdown from "../../../../dropdowns/operations/vendor-dropdown";
import FormCheckbox from "../../../../components/form-elements/form-checkbox";
import FormRow from "../../../../components/form-row";
import VendorItemsDropdown from "../../../../dropdowns/operations/vendor-items-dropdown";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import {TrashIcon} from "@heroicons/react/outline";
import {setupReduxConnection} from "../../../../redux";
import {VendorRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";

function getElementValue(values, name) {
  if (!name.includes(".") && !name.includes("[")) {
    return values[name];
  }

  const split = name.replaceAll("]", "").split(/[.[]/);
  let currentValue = values;

  for (const key of split) {
    currentValue = currentValue[key];
  }

  return currentValue;
}

class VendorItemForm extends Component {
  state = {vendor: {ITEMS: []}, fromDropdown: false};

  componentDidMount() {
    const {vendors = []} = this.props.supply;
    const {index, formikOptions} = this.props;
    const {values} = formikOptions;
    const vendorId = getElementValue(values, `pricing.${index}.vendor`);
    const newVendor = vendors.find((fullVendor) => fullVendor.ID === vendorId) ?? {ITEMS: []};
    this.setState({vendor: newVendor});
  }

  renderVendorDropdown(index, formikOptions) {
    const {vendors} = this.props.supply;
    return (
      <VendorDropdown
        flex
        options={formikOptions}
        name={`pricing.${index}.vendor`}
        onChangeSoft={(vendor) => {
          const newVendor = vendors.find((fullVendor) => fullVendor.ID === vendor.ID);
          this.setState({vendor: newVendor});
        }}
      />
    );
  }

  renderVendorDefaultCheckbox(index, formikOptions) {
    const options = {
      ...formikOptions,
      setFieldValue: (name, value) => {
        const newPricing = formikOptions.values.pricing.map((price, idx) => ({
          ...price,
          isDefault: idx === index ? value : false,
        }));
        formikOptions.setFieldValue("pricing", newPricing);
      },
    };
    return (
      <FormCheckbox
        label={"Quick Stock Default"}
        className="justify-end mt-0"
        options={options}
        name={`pricing.${index}.isDefault`}
      />
    );
  }

  renderVendorSelection(index, formikOptions) {
    return (
      <FormRow>
        {this.renderVendorDropdown(index, formikOptions)}
        {this.renderVendorDefaultCheckbox(index, formikOptions)}
      </FormRow>
    );
  }

  renderVendorItemNameInput(index, formikOptions) {
    return <FormInput label="Vendor Item Name" flex name={`pricing.${index}.name`} options={formikOptions} />;
  }

  renderVendorItemSkuInput(index, formikOptions) {
    return <FormInput label="Vendor SKU #" flex name={`pricing.${index}.sku`} options={formikOptions} />;
  }

  renderVendorItemInfoInput(index, formikOptions) {
    return (
      <FormRow>
        {this.renderVendorItemNameInput(index, formikOptions)}
        {this.renderVendorItemSkuInput(index, formikOptions)}
      </FormRow>
    );
  }

  renderPricePerCaseInput(index, formikOptions) {
    return (
      <FormInput label="Vendor Item Price" flex name={`pricing.${index}.price`} options={formikOptions} />
    );
  }

  renderCaseCount(index, formikOptions) {
    return (
      <FormInput
        label="Vendor Item Quantity"
        flex
        name={`pricing.${index}.caseSize`}
        options={formikOptions}
      />
    );
  }

  fetchCurrentUnit(formikOptions) {
    const {values} = formikOptions;
    const {unit, unitName} = values;
    let currentUnit = null;

    if (unit) {
      currentUnit = unit;
    } else if (unitName) {
      currentUnit = -1;
    }

    return currentUnit;
  }

  renderVendorItemUnitDropdown(index, formikOptions) {
    const {values} = formikOptions;
    const {unit, unitName} = values;
    const {ingredient} = this.props;
    const currentUnit = this.fetchCurrentUnit(formikOptions);

    return (
      <UnitDropdown
        label="Vendor Item Unit"
        units={!unit && unitName ? [{ID: -1, NAME: unitName, CONVERSIONS: []}] : null}
        unit={currentUnit}
        ingredient={ingredient}
        options={formikOptions}
        name={`pricing.${index}.caseUnit`}
      />
    );
  }

  renderVendorItemPricingInformation(index, formikOptions) {
    return (
      <FormRow>
        {this.renderPricePerCaseInput(index, formikOptions)}
        {this.renderCaseCount(index, formikOptions)}
        {this.renderVendorItemUnitDropdown(index, formikOptions)}
      </FormRow>
    );
  }

  renderDeleteVendorItemButton(remove, index, item) {
    return (
      <div className="flex flex-row justify-end mt-3">
        <TrashIcon
          className="h-5 w-5 text-red-600 cursor-pointer"
          onClick={() => {
            showLoadingConfirmAlert(
              "Delete Vendor Item",
              "Are you sure you want to delete this vendor item?"
            ).then(async (close) => {
              await VendorRequests.deleteVendorItem(item.vendor, item.id);
              remove(index);
              close();
            });
          }}
        />
      </div>
    );
  }

  render() {
    const {remove, index, formikOptions, item} = this.props;
    return (
      <div key={index} className="flex flex-col shadow mb-2 p-6 pt-2 pb-3">
        {this.renderVendorSelection(index, formikOptions)}
        {this.renderVendorItemInfoInput(index, formikOptions)}
        {this.renderVendorItemPricingInformation(index, formikOptions)}
        {this.renderDeleteVendorItemButton(remove, index, item)}
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "supply"])(VendorItemForm);

import {FormBoolean, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class AddBankConnectionModal extends Component {
  state = {metadata: null};
  open = (metadata) => {
    this.setState({metadata});

    this.modal.open();
  };
  handleSubmit = async (values) => {
    const {metadata} = this.state;

    await request("accounting/pair", "POST", {METADATA: metadata, MAPPINGS: values}).then(() => {
      this.props.syncState();
    });
  };

  render() {
    const {ledgers} = this.props.accounting;
    const {metadata} = this.state;

    const data = [{label: "Create new account", value: null}];

    if (ledgers) {
      data.push(
        ...ledgers?.map(({NAME, TP_ID}) => ({
          label: NAME,
          value: TP_ID,
        }))
      );
    }

    let accounts = [];

    if (metadata) {
      accounts = metadata.accounts;
    }

    return (
      <Modal
        buttonLabel={"Add"}
        label={"Select Accounts"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          initialValues={{
            ...accounts.reduce((initial, {id}) => {
              initial[id] = null;
              return initial;
            }, {}),
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            return (
              <div>
                {accounts.map(({id, name}) => (
                  <FormSelect name={id} label={name} data={data} options={formikOptions} />
                ))}
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["accounting"], null, {forwardRef: true})(
  withRouter(AddBankConnectionModal)
);

import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import React, {Component} from "react";
import moment from "moment-timezone";
import {getStore} from "../../redux";
import PdfViewer from "../../components/pdf-viewer";
import {Document, Page, pdfjs} from "react-pdf";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

export function getConversationName(account, chat) {
  if (chat.NAME) {
    return chat.NAME;
  }

  if (chat.DEPARTMENTS.length > 0) {
    return chat.DEPARTMENTS.map((item) => item.NAME).join(", ");
  }

  if (chat.ROLES.length > 0) {
    return chat.ROLES.map((item) => item.NAME).join(", ");
  }

  if (chat.LOCATIONS.length > 0) {
    return chat.LOCATIONS.map((item) => item.NAME).join(", ");
  }

  const participantClones = JSON.parse(JSON.stringify(chat.PARTICIPANTS));

  const validParticipants = participantClones.filter((item) => {
    if (item.FINAL_ACCOUNT_ID === account.ID) {
      return false;
    }

    return item.FULL_NAME && item.FULL_NAME?.trim()?.length > 0;
  });

  validParticipants.sort((a, b) => a.FULL_NAME.localeCompare(b.FULL_NAME));

  return validParticipants
    .map((item) => {
      return item.FULL_NAME;
    })
    .join(", ");
}

export function getConversationImage(account, chat) {
  if (!chat) {
    return "";
  }

  const activeParticipants = chat.PARTICIPANTS.filter((item) => {
    return item.FINAL_ACCOUNT_ID !== account.ID;
  });

  const noParticipants = activeParticipants.length;

  if (chat.LOGO !== null) {
    return getAccountImage(chat);
  }

  if (noParticipants === 0) {
    return getAccountImage(chat);
  }

  if (noParticipants === 1) {
    return getAccountImage(activeParticipants[0]);
  }

  if (noParticipants === 2) {
    return (
      <div className="flex flex-row">
        {getAccountImage(activeParticipants[0], 6)}

        {getAccountImage(activeParticipants[1], 6)}
      </div>
    );
  }

  return (
    <div className="flex flex-row">
      {getAccountImage(activeParticipants[0], 6)}

      {getAccountImage(activeParticipants[1], 6)}
    </div>
  );
}

export function getAccountImage(row, size) {
  return (
    <img
      className={`${size ? `h-${size} w-${size}` : "h-10 w-10"} rounded-full`}
      src={getObjectImage(row, "LOGO", "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg")}
      alt=""
    />
  );
}

export function getConversationLastMessage(chat) {
  if (chat.LAST_MESSAGE === "") {
    return "Sent a photo";
  }

  return chat.LAST_MESSAGE;
}

export function getLastMessageDate(chat) {
  const lastMessageDate = chat.DATE_LAST_MESSAGE;

  const fromNow = moment(Date.now()).diff(moment(lastMessageDate), "minutes");

  if (fromNow < 60) {
    return fromNow + "m";
  }

  if (fromNow >= 60 && fromNow < 60 * 24) {
    return Math.trunc(fromNow / 60) + "h";
  }

  if (fromNow >= 60 * 24 && fromNow < 60 * 24 * 7) {
    return Math.trunc(fromNow / 60 / 24) + "d";
  }

  if (fromNow >= 60 * 24 * 7 && fromNow < 60 * 24 * 7 * 52) {
    return Math.trunc(fromNow / 60 / 24 / 7) + "w";
  }

  return Math.trunc(fromNow / 60 / 24 / 7 / 52) + "y";
}

export function getMessageContent(message) {
  if (!message) {
    return "";
  }

  const {LINK: link} = message;

  if (link !== null) {
    const type = link.slice(link.lastIndexOf(".") + 1);

    if (type === "pdf") {
      return (
        <a className={"underline"} target="_blank" href={getObjectImage(message, "LINK")}>
          {link}
        </a>
      );
    }

    return <img className="max-w-sm max-h-sm" src={getObjectImage(message, "LINK", "logo_mark-01.jpg")} />;
  }

  return message.CONTENT;
}

export function getTimeChange(message, nextMessage) {
  const messageDelta = message.DATE_CREATED - nextMessage?.DATE_CREATED;
  if (nextMessage !== null && messageDelta < 1000 * 60 * 15) {
    return null;
  }

  return messageDelta;
}

export function getIsUnread(conversation) {
  const {account} = getStore().getState().user;

  if (!conversation?.PARTICIPANTS) {
    return false;
  }

  const userParticipant = conversation.PARTICIPANTS.find(
    (_participant) => _participant.ACCOUNT_ID === account.ID || _participant.FINAL_ACCOUNT_ID === account.ID
  );

  return userParticipant && conversation.DATE_LAST_MESSAGE > userParticipant.DATE_UPDATED;
}

export function getNewConversation() {
  const {employee} = getStore().getState().user;

  const participantPayload = {
    EMPLOYEE_ID: employee.ID,
    DATE_CREATED: Date.now(),
    DATE_UPDATED: Date.now(),
    AUTO_ADDED: 0,
  };

  return {
    ID: null,
    ARCHIVED: 0,
    AUTOMATIC: 0,
    COMPANY_ID: null,
    DATE_CREATED: Date.now(),
    DATE_LAST_MESSAGE: Date.now(),
    DATE_UPDATED: null,
    DEPARTMENTS: [],
    ENTIRE_COMPANY: 0,
    LAST_MESSAGE: null,
    LAST_MESSAGE_ID: null,
    LOCATIONS: [],
    LOGO: null,
    NAME: "New Chat",
    ON_SHIFT: 0,
    PARTICIPANTS: [{...participantPayload}],
    PENDING_MESSAGES: [],
    PINNED: 0,
    ROLES: [],
    SHIFT_DATE: null,
    STATUS: null,
  };
}

export function findConversation(conversation) {
  const {conversations} = getStore().getState().chat;

  return conversations.find((_conversation) => {
    if (!_conversation.ID) {
      return false;
    }

    const {
      PARTICIPANTS: participants = [],
      DEPARTMENTS: departments = [],
      ROLES: roles = [],
      LOCATIONS: locations = [],
    } = _conversation;

    if (participants.length !== conversation.PARTICIPANTS.length) {
      return false;
    }

    if (departments.length !== conversation.DEPARTMENTS.length) {
      return false;
    }

    if (locations.length !== conversation.LOCATIONS.length) {
      return false;
    }

    if (roles.length !== conversation.ROLES.length) {
      return false;
    }

    const participantSet = new Set(conversation.PARTICIPANTS.map(({EMPLOYEE_ID}) => EMPLOYEE_ID));
    const departmentSet = new Set(conversation.DEPARTMENTS.map(({DEPARTMENT_ID}) => DEPARTMENT_ID));
    const locationSet = new Set(conversation.LOCATIONS.map(({LOCATION_ID}) => LOCATION_ID));
    const roleSet = new Set(conversation.ROLES.map(({ROLE_ID}) => ROLE_ID));

    return (
      (participants.every((ID) => participantSet.has(ID)) &&
        roles.every((ID) => roleSet.has(ID)) &&
        departments.every((ID) => departmentSet.has(ID)) &&
        locations.every((ID) => locationSet.has(ID))) ||
      false
    );
  });
}

export function getNumberUnread() {
  const {conversations} = getStore().getState().chat;

  let toReturn = 0;

  for (let conversation of conversations) {
    if (!!getIsUnread(conversation)) {
      toReturn += 1;
    }
  }

  return toReturn;
}

export function getReadRecipients(conversation, message, isSelf) {
  if (!conversation) {
    return [];
  }

  const participants = mergeParticipants(conversation.PARTICIPANTS);

  const {account} = getStore().getState().user;

  const readRecipients = [];
  const unreadRecipients = [];

  for (let participant of participants) {
    if (participant.ACCOUNT_ID !== account.ID && participant.FINAL_ACCOUNT_ID !== account.ID) {
      if (message.DATE_CREATED < participant.DATE_UPDATED) {
        readRecipients.push(participant);
      } else {
        unreadRecipients.push(participant);
      }
    }
  }

  return {readRecipients, unreadRecipients};
}

function mergeParticipants(arr) {
  const idLookup = new Map();

  for (const obj of arr) {
    if (idLookup.has(obj.EMPLOYEE_ID)) {
      const existingObj = idLookup.get(obj.EMPLOYEE_ID);

      if (obj.DATE_UPDATED > existingObj.DATE_UPDATED) {
        idLookup.set(obj.EMPLOYEE_ID, obj);
      }
    } else {
      idLookup.set(obj.EMPLOYEE_ID, obj);
    }
  }

  return Array.from(idLookup.values());
}

export function getReadReceiptStr(read, isMyself) {
  if (!isMyself) {
    return "Seen";
  } else {
    if (read.length > 1) {
      return "Seen by " + read[0].FULL_NAME + " & Others";
    } else if (read.length === 1) {
      return "Seen by " + read[0].FULL_NAME;
    } else {
      return "Sent";
    }
  }
}

export function getChatEmployees(participants, employees) {
  const employeeIdSet = new Set(participants.map(({EMPLOYEE_ID}) => EMPLOYEE_ID));

  employees = employees.filter(({ID}) => employeeIdSet.has(ID));

  const participantDict = parseIdDict(participants, "EMPLOYEE_ID");

  for (const employee of employees) {
    if (participantDict[employee.ID].AUTO_ADDED !== 0) {
      employee.AUTO_ADDED = 1;
    }
  }

  return employees;
}

export function addObjArrayElem(obj, fieldName, elem) {
  if (!obj[fieldName]) {
    obj[fieldName] = [elem];
  } else {
    obj[fieldName] = [...obj[fieldName], elem];
  }

  return obj;
}

export function removeObjArrayElem(obj, fieldName, elem) {
  if (!obj[fieldName] || !Array.isArray(obj[fieldName])) {
    return;
  }

  const toFindIndex = obj[fieldName].findIndex(({ID}) => elem.ID === ID);

  if (toFindIndex !== -1) {
    obj[fieldName].splice(toFindIndex, 1);
  }

  return obj;
}

export function isNewConversation(conversation) {
  return conversation !== null && !conversation?.ID;
}

export function getParticipantActionButton(employee) {
  const {employee: userEmployee} = getStore().getState().user;

  if (employee.ID === userEmployee.ID) {
    return {label: "", disabled: true};
  } else if (employee.ON_SHIFT) {
    return {
      label: "On Shift",
      disabled: true,
    };
  } else if (employee.AUTO_ADDED === 1) {
    return {
      label: "Auto Added",
      disabled: true,
    };
  } else {
    return {
      label: "Remove",
      disabled: false,
    };
  }
}

export function getIsImage(message) {
  if (!message || !message.LINK) {
    return;
  }

  const {LINK: link} = message;

  const type = link.slice(link.lastIndexOf(".") + 1);

  return type === "jpg" || type === "jpeg" || type === "png" || type === "gif";
}

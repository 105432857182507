import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import TaxDropdown from "../../../dropdowns/sales/tax-dropdown";

class CategoryTaxModal extends Component {
  state = {category: null};

  open(category = null) {
    this.setState({category}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createTax({rate}) {
    const {category} = this.state;

    const taxPayload = {
      CATEGORY_ID: category.ID,
      TAX_ID: rate,
    };

    const serverPayload = await request("tax/category", "POST", taxPayload);

    this.props.addState(serverPayload);
    this.modal.close();
  }

  render() {
    return (
      <Modal
        buttonLabel="Add"
        label="Add Category Tax Rate"
        description="Add a tax rate that will be added to all products in a category"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.createTax.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            rate: null,
          }}
          validationSchema={Yup.object({
            rate: Yup.string().required("Tax rate is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <TaxDropdown name="rate" options={formikOptions} exclude={this.props.exclude} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default CategoryTaxModal;

import React, {Component} from "react";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import CardAlert from "../features/card-alert";

class CardTab extends Component {
  state = {tab: null};

  constructor(props) {
    super(props);

    this.id = "tab_" + randomString(24);
  }

  componentDidMount() {
    const {data} = this.props;

    if (data.length > 0) {
      const hasQuery = this.fetchQueryParams();

      if (hasQuery === null) {
        this.setState({tab: data[0].id});
      } else {
        this.setState({tab: hasQuery});

        const selector = document.querySelector("#" + this.id + "-" + hasQuery);

        if (selector) {
          selector.scrollIntoView();
        }
      }
    }
  }

  fetchQueryParams() {
    const url = new URL(window.location.href);

    if (url.searchParams.has("tab")) {
      let queryVal = url.searchParams.get("tab");
      if (!isNaN(queryVal)) {
        queryVal = parseInt(queryVal);
      }

      return queryVal;
    }

    return null;
  }

  updateQueryParams(id) {
    const url = new URL(window.location.href);

    url.searchParams.set("tab", id);

    window.history.pushState({}, null, url);
  }

  render() {
    const {tab} = this.state;
    const {data, className} = this.props;

    return (
      <div className={classNames(className)}>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>

          <select
            id="tabs"
            name="tabs"
            value={data.find((dataTab) => dataTab.id === tab)?.id}
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            onChange={(e) => {
              let tabVal = e.target.value;

              if (!isNaN(tabVal)) {
                tabVal = parseInt(tabVal);
              }

              this.setState({tab: tabVal}, () => {
                this.updateQueryParams(tabVal);
              });
            }}
          >
            {data.map((tab) => (
              <option value={tab.id} key={tab.id}>
                {tab.label}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              style={{overflowX: "auto", paddingBottom: 2}}
              className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mt-2"
              aria-label="Tabs"
            >
              {data.map((dataTab) => (
                <a
                  id={this.id + "-" + dataTab.id}
                  key={dataTab.id}
                  onClick={() => this.setState({tab: dataTab.id}, () => this.updateQueryParams(dataTab.id))}
                  aria-current={dataTab.id === tab ? "page" : undefined}
                  className={classNames(
                    dataTab.id === tab ? "border-indigo-500" : "border-transparent hover:border-gray-300",
                    "py-4 px-1 border-b-2 font-medium text-sm cursor-pointer whitespace-nowrap"
                  )}
                >
                  <CardAlert
                    label={dataTab.label}
                    value={dataTab.value}
                    link={dataTab.link}
                    subtext={dataTab.subtext}
                    icon={dataTab.icon}
                    viewall={false}
                  />
                </a>
              ))}
            </nav>
          </div>
        </div>

        {this.props.children &&
          this.props.children(
            tab,
            data.find((item) => {
              return "" + item.id === "" + tab;
            })
          )}
      </div>
    );
  }
}

CardTab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      badge: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default CardTab;

import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import LocationFinanceModal from "../../../modals/admin/records/location-payroll-modal";
import {
  Card,
  Table,
  Loading,
  PageHeadings,
  Tab,
  TwoColumnList,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import LocationModal from "../../../modals/admin/records/location-modal";
import PartnerModal from "../../../modals/admin/records/location/partner-modal";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import RoleDropdown from "../../../dropdowns/team/role-dropdown";
import {Formik} from "formik";
import {showConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import {updateStateDelegator} from "../../../utils/util";

class LocationPage extends Component {
  state = {location: null, settings: null, partners: null};

  componentDidMount() {
    this.syncState();
  }

  syncState = async () => {
    const {ID: id} = this.props.router.params;

    const {location, settings} = await request("admin/dashboard/location/" + id, "GET", null);

    const partners = await request("admin/dashboard/location/" + id + "/partners", "GET", null);

    this.setState({
      location,
      settings,
      partners,
    });
  };

  async saveLocation({stream}) {
    const {location} = this.state;
    const locationPayload = {
      COMPANY_ID: location.COMPANY_ID,
      STREAM_ID: null,
    };

    if (stream) {
      locationPayload.STREAM_ID = stream;
    }

    await request("admin/location/" + location.ID, "PATCH", locationPayload);

    showSuccessNotification("Location Saved", "Location has been saved");
  }

  openDashboard() {
    const {location} = this.state;

    localStorage.setItem("CURRENT_LOCATION", "" + location.UNIQUE_ID);
    window.open("/", "_blank").focus();
  }

  renderPartners() {
    const {partners} = this.state;

    return (
      <Card
        label="Partners"
        button={{
          label: "Add Partner",
          onClick: () => this.partnerModal.open(),
        }}
      >
        <Table
          data={partners}
          actionButtons={[{label: "Edit Partner", onClick: (item) => this.partnerModal.open(item)}]}
          columns={[
            {width: 1, label: "Account ID", value: "ACCOUNT_ID"},
            {label: "Name", value: "FULL_NAME"},
            {label: "Phone", value: "PHONE", type: "phone"},
            {label: "Email", value: "EMAIL"},
          ]}
        />
      </Card>
    );
  }

  renderFinance() {
    const {location, settings} = this.state;

    return (
      <TwoColumnList
        label={"Payroll"}
        data={[
          {
            label: "Payroll Whitelisted",
            value: settings?.PAYROLL_WHITELISTED,
            type: "bool",
          },
          {
            label: "Cash Account Whitelisted",
            value: settings?.CASH_ACCOUNT_WHITELISTED,
            type: "bool",
          },
        ]}
        buttons={[
          {
            label: "Edit",
            onClick: (e) => this.locationFinanceModal.open(location, settings),
          },
        ]}
      />
    );
  }

  renderOverview() {
    const {location} = this.state;

    return (
      <Formik
        enableReinitialize
        onSubmit={this.saveLocation.bind(this)}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={{
          stream: location.STREAM_ID ?? "",
        }}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return (
            <Card label="Location Information" button={{label: "Save", onClick: handleSubmit}}>
              <div className="px-4 pb-4">
                <FormInput label="Stream Email" options={formikOptions} name="stream" />
              </div>
            </Card>
          );
        }}
      </Formik>
    );
  }

  render() {
    const {location, partners} = this.state;

    if (location === null) {
      return <Loading />;
    }

    return (
      <div>
        <LocationModal ref={(e) => (this.locationModal = e)} />

        <PartnerModal
          location={location}
          ref={(e) => (this.partnerModal = e)}
          addState={(partner) => this.setState({partners: [...partners, partner]})}
          updateState={(id, partner) => {
            this.setState({partners: updateStateDelegator(partners, id, partner)});
          }}
        />

        <LocationFinanceModal ref={(e) => (this.locationFinanceModal = e)} syncState={this.syncState} />

        <PageHeadings
          label={location.NAME}
          button={{
            label: "View Dashboard",
            onClick: this.openDashboard.bind(this),
          }}
        />

        <TwoColumnList
          label="Location Details"
          description="Details about the contact and the information about them"
          data={[
            {
              label: "ID",
              value: location.ID,
            },
            {
              label: "Name",
              value: location.NAME,
            },
            {
              label: "Connect ID",
              value: location.CONNECT_ID,
            },
            {
              label: "Billing ID",
              value: location.CUSTOMER_ID,
            },
            {
              label: "Company",
              value: location.COMPANY_NAME,
            },
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.locationModal.open(location),
            },
          ]}
        />

        <Tab
          data={[
            {id: "partners", label: "Partners"},
            {id: "finance", label: "Finance"},
            {id: "stream", label: "Stream"},
          ]}
        >
          {(id) => {
            if (id === "finance") {
              return this.renderFinance();
            }

            if (id === "partners") {
              return this.renderPartners();
            }

            if (id === "stream") {
              return this.renderOverview();
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(LocationPage);

import moment from "moment-timezone";

const defaultState = {
  startEpoch: null,
  endEpoch: null,
  lastStartEpoch: null,
  lastEndEpoch: null,
  locationIdArray: [],
  reportingLocations: {},
  groupReports: false,
  timeFrame: "TIMESPAN",
  selectedProductId: null,
  loading: false,
  selectedStripePayout: -1,
  excludeThird: false,
  // stripePayouts: null,
};

const UPDATE_EPOCHS = "UPDATE_EPOCHS";
const UPDATE_LAST_EPOCHS = "UPDATE_LAST_EPOCHS";
const UPDATE_LOCATION_ID_ARRAY = "UPDATE_LOCATION_ID_ARRAY";
const UPDATE_LOCATIONS_ARRAY = "UPDATE_LOCATIONS_ARRAY";
const UPDATE_GROUP_REPORTS = "UPDATE_GROUP_REPORTS";
const UPDATE_TIMEFRAME = "UPDATE_TIMEFRAME";
const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";
const UPDATE_SELECTED_STRIPE_PAYOUT = "UPDATE_SELECTED_STRIPE_PAYOUT";
const UPDATE_STRIPE_PAYOUT_RANGES = "UPDATE_STRIPE_PAYOUT_RANGES";
const UPDATE_STRIPE_PAYOUTS = "UPDATE_STRIPE_PAYOUTS";
const UPDATE_EXCLUDE_THIRD = "UPDATE_EXCLUDE_THIRD";

export function setSelectedStripePayout(payoutId) {
  return (dispatch, getState) => {
    dispatch(updateSelectedStripePayout(payoutId));
  };
}

export function setExcludeThird(excludeThird) {
  return (dispatch, getState) => {
    dispatch(updateExcludeThird(excludeThird));
  };
}

export function setMinutes({startMinutes, endMinutes}) {
  return (dispatch, getState) => {
    let {startEpoch, endEpoch} = getState().reporting;

    if (startMinutes) {
      startEpoch = moment(startEpoch).startOf("day").add(startMinutes, "minutes").valueOf();
      dispatch(updateEpochs({startEpoch}));
    }

    if (endMinutes) {
      endEpoch = moment(endEpoch).startOf("day").add(endMinutes, "minutes").valueOf();
      dispatch(updateEpochs({endEpoch}));
    }
  };
}

export function setLastEpochs({lastStartEpoch, lastEndEpoch}) {
  return (dispatch, getState) => {
    dispatch(updateLastEpochs({lastStartEpoch, lastEndEpoch}));
  };
}

export function setEpochs({startEpoch, endEpoch, changeTimeOfDay = false, resetTimeOfDay = false}) {
  return (dispatch, getState) => {
    let {startEpoch: currentStartEpoch, endEpoch: currentEndEpoch} = getState().reporting;

    if (!currentStartEpoch || resetTimeOfDay) {
      startEpoch = moment(startEpoch).startOf("day").valueOf();
      endEpoch = moment(endEpoch).endOf("day").subtract(1, "second").valueOf();

      dispatch(updateEpochs({startEpoch, endEpoch}));
      return;
    }

    if (startEpoch) {
      if (!changeTimeOfDay) {
        let startMinutes = moment(currentStartEpoch).diff(
          moment(currentStartEpoch).startOf("day"),
          "minutes"
        );

        startEpoch = moment(startEpoch).startOf("day").add(startMinutes, "minutes").valueOf();
      }

      dispatch(updateEpochs({startEpoch}));
    }

    if (endEpoch) {
      if (!changeTimeOfDay) {
        let endMinutes = moment(currentEndEpoch).diff(moment(currentEndEpoch).startOf("day"), "minutes");

        if (
          moment(currentEndEpoch).startOf("day").isDST() !==
          moment(currentEndEpoch).startOf("day").add(5, "hours").isDST()
        ) {
          if (!moment(currentEndEpoch).startOf("day").isDST()) {
            endMinutes += 60;
          } else {
            endMinutes -= 60;
          }
        }

        if (currentEndEpoch !== endEpoch) {
          if (moment(endEpoch).isDST() !== moment(endEpoch).add(5, "hours").isDST()) {
            if (!moment(endEpoch).startOf("day").isDST()) {
              endMinutes -= 60;
            } else {
              endMinutes += 60;
            }
          }
        }

        endEpoch = moment(endEpoch).startOf("day").add(endMinutes, "minutes").valueOf();
      }

      dispatch(updateEpochs({endEpoch}));
    }
  };
}

export function updateSelectedStripePayout(payload) {
  return {type: UPDATE_SELECTED_STRIPE_PAYOUT, payload};
}

export function updateExcludeThird(payload) {
  return {type: UPDATE_EXCLUDE_THIRD, payload};
}

export function updateStripePayouts(payload) {
  return {type: UPDATE_STRIPE_PAYOUTS, payload};
}

export function updateEpochs(payload) {
  return {type: UPDATE_EPOCHS, payload};
}

export function updateLastEpochs(payload) {
  return {type: UPDATE_EPOCHS, payload};
}

export function updateLocationIdArray(payload) {
  return {type: UPDATE_LOCATION_ID_ARRAY, payload};
}

export function updateReportingLocations(payload) {
  return {type: UPDATE_LOCATIONS_ARRAY, payload};
}

export function updateGroupReports(payload) {
  return {type: UPDATE_GROUP_REPORTS, payload};
}

export function updateTimeFrame(payload) {
  return {type: UPDATE_TIMEFRAME, payload};
}

export function updateSelectedProduct(payload) {
  return {type: UPDATE_SELECTED_PRODUCT, payload};
}

export const reportingReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_EPOCHS:
      return {...state, ...payload};
    case UPDATE_LAST_EPOCHS:
      return {...state, ...payload};
    case UPDATE_LOCATION_ID_ARRAY:
      return {...state, locationIdArray: [...payload]};
    case UPDATE_LOCATIONS_ARRAY:
      return {...state, reportingLocations: {...payload}};
    case UPDATE_GROUP_REPORTS:
      return {...state, groupReports: payload};
    case UPDATE_TIMEFRAME:
      return {...state, timeFrame: payload};
    case UPDATE_SELECTED_PRODUCT:
      return {...state, selectedProductId: payload};
    case UPDATE_SELECTED_STRIPE_PAYOUT:
      return {...state, selectedStripePayout: payload};
    case UPDATE_STRIPE_PAYOUTS:
      return {...state, stripePayouts: payload};
    case UPDATE_EXCLUDE_THIRD:
      return {...state, excludeThird: payload};
  }
};

import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";

class AdvancedLoyaltyModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {onePer, ignoreGiftCards, ignoreGiftCardPurchases, ignoreDiscountedItems} = values;

    let payload = {
      ADVANCED_LOYALTY_ONE_RULE_PER_TICKET: onePer,
      IGNORE_LOYALTY_WITH_GIFT_CARDS: ignoreGiftCards,
      IGNORE_LOYALTY_EARN_ON_GIFT_CARDS: ignoreGiftCardPurchases,
      IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS: ignoreDiscountedItems,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    const {PARTNER_SETTINGS: settings} = await request("settings/shop/all", "GET");

    this.props.updateShop({settings});
    this.props.onSubmit && this.props.onSubmit();
    this.modal.close();
  };

  render() {
    let {
      ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
      IGNORE_LOYALTY_WITH_GIFT_CARDS,
      IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
      IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS,
    } = this.props.shop.settings;
    let {LOYALTY_POINTS_VERBIAGE} = this.props.shop.companySettings;

    const terminology = LOYALTY_POINTS_VERBIAGE ? LOYALTY_POINTS_VERBIAGE : "points";

    return (
      <Modal
        label="Loyalty Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            onePer: ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
            ignoreGiftCards: IGNORE_LOYALTY_WITH_GIFT_CARDS,
            ignoreGiftCardPurchases: IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
            ignoreDiscountedItems: IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="ignoreGiftCards"
                  label="Ignore Loyalty for Gift Cards as a Payment"
                  tooltip="If enabled, transactions purchased with a gift card will not earn the patron any loyalty."
                  options={formikOptions}
                />

                <FormBoolean
                  name="ignoreGiftCardPurchases"
                  label="Ignore Loyalty for Purchased Gift Cards"
                  tooltip="If enabled, purchases of gift cards will not grant loyalty points."
                  options={formikOptions}
                />

                <FormBoolean
                  name="ignoreDiscountedItems"
                  label="Ignore Loyalty for Already Discounted Items"
                  tooltip="If enabled, purchases of already discounted items will not grant loyalty points."
                  options={formikOptions}
                />

                <FormBoolean
                  name="onePer"
                  label="One Earn Rule Per Payment"
                  options={formikOptions}
                  tooltip={{
                    label: "One Rule Per Payment",
                    data: `If enabled, only one earn rule will be applied per payment. The rule that generates the maximum amount of ${terminology} will be applied. Otherwise, the patron will instead earn the sum of the ${terminology} for each satisfied earn rule.`,
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(AdvancedLoyaltyModal);

import React, {Component} from "react";
import {Modal, FormInput, FormPhone} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";

class ContactModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  handleSubmit = async (values) => {
    await request("location", "PATCH", values);

    this.props.updateInfo(values);
    this.modal.close();
  };

  render() {
    let {info} = this.props;

    return (
      <Modal
        label="Contact Information"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
          initialValues={info}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Website" name="WEBSITE" options={formikOptions} />
                <FormPhone label="Phone" name="PHONE" options={formikOptions} />
                <FormInput label="Email" name="EMAIL" options={formikOptions} />
                <FormInput label="Facebook" name="FACEBOOK" options={formikOptions} />
                <FormInput label="Instagram" name="INSTAGRAM" options={formikOptions} />
                <FormInput label="Twitter" name="TWITTER" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ContactModal;

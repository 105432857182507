import {FormBoolean, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class OvertimeTabModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {calculateOvertime, workweekStart} = values;

    let payload = {
      TIME_CARDS_CALCULATE_OVERTIME: calculateOvertime,
      OVERTIME_WORKWEEK_START_ISODAY: workweekStart,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {TIME_CARDS_CALCULATE_OVERTIME, OVERTIME_WORKWEEK_START_ISODAY} = this.props.shop.settings;

    return (
      <Modal
        label="Overtime Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.handleSubmit}
          initialValues={{
            calculateOvertime: TIME_CARDS_CALCULATE_OVERTIME,
            workweekStart: OVERTIME_WORKWEEK_START_ISODAY,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;
            const {calculateOvertime} = formikOptions.values;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="calculateOvertime"
                  label="Calculate Overtime With Dripos"
                  options={formikOptions}
                />

                {calculateOvertime === "1" && (
                  <FormSelect
                    name={"workweekStart"}
                    label={"Workweek Start"}
                    options={formikOptions}
                    data={[
                      {value: 1, label: "Monday"},
                      {value: 2, label: "Tuesday"},
                      {value: 3, label: "Wednesday"},
                      {value: 4, label: "Thursday"},
                      {value: 5, label: "Friday"},
                      {value: 6, label: "Saturday"},
                      {value: 7, label: "Sunday"},
                    ]}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(OvertimeTabModal);

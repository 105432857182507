import React, {Component} from "react";
import {Loading, PageHeadings, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";

import {request} from "../../../../utils/request";
import {withTranslation} from "react-i18next";
import {withRouter} from "../../../../utils/navigation";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";

class TextCampaignInstancePage extends Component {
  state = {
    instance: null,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({isLoading: true});
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let instance = await request("text/marketing/campaign/instance/" + id, "GET", null);

    console.log(instance);

    this.setState({
      instance,
      isLoading: false,
    });
  }

  renderBody() {
    const {instance} = this.state;

    const twoColumnData = [
      {
        label: "Recipient",
        value: instance.FULL_NAME ? (
          <div>
            <div className="text-sm font-medium text-gray-900">{instance.FULL_NAME || "Unnamed Patron"}</div>
            <div className="text-sm text-gray-500">{getFormattedPhone(instance.PHONE)}</div>
          </div>
        ) : (
          getFormattedPhone(instance.PHONE)
        ),
      },
      {
        label: "Status",
        tooltip: {
          data: [
            {
              label: "Status",
              data: "There are a few text statuses that a text can have. Click next for more information about any status.",
            },
            {
              label: "Queued/Accepted",
              data: "A status of queued or accepted means that the text has been sent to our text message provider but has not yet been sent to the patron.",
            },

            {
              label: "Sending",
              data: "A status of sending means the text is currently being sent by the text message provider to the patron.",
            },
            {
              label: "Sent/Delivered",
              data: "A status of sent or delivered means the text has been sent to the patron's phone number.",
            },
            {
              label: "Undelivered/Failed",
              data: (
                <div>
                  A status of undelivered or failed means that something went wrong while attempting to send
                  this text to this phone number. There are many reasons a text could have failed including
                  attempting to send to an unsubscribed patron or an invalid phone number. For more
                  information about any individual text, please reach out to our support at{" "}
                  <a href={"mailto:support@dripos.com"} className="text-indigo-600 hover:text-indigo-900">
                    support@dripos.com
                  </a>
                  .
                </div>
              ),
            },
          ],
        },
        value: instance?.TWILIO_CONTENT?.status ?? instance.STATUS,
      },
      {
        label: "Date Sent",
        value: instance.DATE_SENT,
        type: "datetime",
      },
      {
        label: "Coupon Attached",
        value: instance?.COUPON_ID ? (
          <div
            className="cursor-pointer text-indigo-600 font-medium"
            onClick={() => {
              this.props.router.navigate("/coupon/" + instance.COUPON_UNIQUE_ID);
            }}
          >
            View
          </div>
        ) : (
          "None"
        ),
      },
      {
        label: "Text",
        value: (
          <div className="shadow-md rounded-md flex p-4 whitespace-pre-wrap">
            {instance?.TWILIO_CONTENT?.body ?? "-"}
          </div>
        ),
      },
    ];

    return (
      <div>
        <PageHeadings
          label={"Sent Text"}
          description={`View information about this individual text`}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Campaigns Page", url: "/campaigns"},
            {
              label: "Text Campaign",
              url: "/campaigns/text/" + instance.TEXT_CAMPAIGN_UNIQUE_ID,
            },
          ]}
        />

        <TwoColumnList
          label="Text Details"
          description="View the text status, recipeint and other information"
          data={twoColumnData}
        />
      </div>
    );
  }

  render() {
    const {instance, isLoading} = this.state;

    if (instance === null || isLoading) {
      return <Loading />;
    }

    return this.renderBody();
  }
}

export default withTranslation()(withRouter(TextCampaignInstancePage));

import React, {Component} from "react";
import {request} from "../../../utils/request";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PromotionForm from "../../../forms/marketing/promotions/promotion-form";
import {PROMOTION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/src/helpers/promotion-helper";

class PromotionModal extends Component {
  state = {coupon: null};

  open(promotion = null) {
    this.setState({promotion}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async savePromotion({
    name,
    durationType,
    start,
    end,
    days,
    type,
    products,
    categories,
    discount,
    company,
    flat,
    percentage,
    oncePer,
    recurringDays,
    recurringStartDate,
    recurringEndDate,
    recurringPeriod,
    from,
    to,
  }) {
    const {promotion} = this.state;

    let promotionPayload = {
      NAME: name,

      DATE_END:
        durationType !== "recurring" ? end : recurringPeriod === "date_range" ? recurringEndDate : null,
      DATE_START:
        durationType !== "recurring"
          ? start
          : recurringPeriod === "date_range"
          ? recurringStartDate
          : Date.now(),

      DAYS_VALID: durationType === "days" ? days : null,

      TYPE: type,
      IS_COMPANY_WIDE: company === "1",
      ENABLED: true,
      ONCE_PER_ORDER: oncePer === "1",

      DISCOUNT: {
        FLAT_FEE: discount === "flat" ? decimalToDollars(flat) : null,
        PERCENTAGE: discount === "percentage" ? percentage : null,
      },

      RECURRING_DAYS: durationType === "recurring" ? recurringDays : null,
      RECURRING_START_TIME: durationType === "recurring" ? from : null,
      RECURRING_END_TIME: durationType === "recurring" ? to : null,
      IS_RECURRING: durationType === "recurring",
      DURATION_TYPE: durationType,
    };

    if (type === PROMOTION_TYPES.PRODUCT) {
      promotionPayload.PRODUCTS = products;
    }

    if (type === PROMOTION_TYPES.CATEGORY) {
      promotionPayload.CATEGORIES = categories;
    }

    const serverPromotion = await request("promotions/" + promotion.ID, "PATCH", promotionPayload);

    this.props.updateState({
      ...promotion,
      ...serverPromotion,
    });

    this.modal.close();
  }

  render() {
    const {promotion} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Promotion"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        xlarge
      >
        {promotion && (
          <PromotionForm
            handleSubmit={(values) => this.savePromotion(values)}
            ref={(e) => (this.formikRef = e)}
            promotion={promotion}
            noSubmit
          />
        )}
        <div className="my-8" />
      </Modal>
    );
  }
}

export default PromotionModal;

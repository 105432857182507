import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FormRow from "../../../../components/form-row";
import {FormInput, Tooltip} from "@frostbyte-technologies/frostbyte-tailwind";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import Accordion from "../../../../components/form-elements/accordion";
import IngredientCategoryDropdown from "../../../../dropdowns/operations/recipes/ingredient-category-dropdown";
import {FieldArray} from "formik";
import PropTypes from "prop-types";
import {setupReduxConnection} from "../../../../redux";
import VendorItemForm from "./vendor-item-form";
import ComboBox from "../../../../components/combobox";
import {
  CALCULATION_TYPE_DISPLAYS,
  CALCULATION_TYPES,
  INGREDIENT_TYPE_DISPLAYS,
  INGREDIENT_TYPES,
} from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";

class IngredientForm extends Component {
  state = {calculation: CALCULATION_TYPES.STANDARD};

  addPricing({values, setFieldValue}) {
    const {pricing} = values;

    const newPricing = [
      ...pricing,
      {name: null, sku: null, vendor: "", price: null, caseSize: null, unit: null},
    ];
    setFieldValue("pricing", newPricing);
  }

  renderNameInput(formikOptions) {
    return (
      <FormInput
        name="name"
        label="Name"
        tooltip={{
          label: "Name",
          data: "This is the name of the ingredient used internally to your company. There is a separate field for the name that should be used when sending purchase orders to vendors.",
        }}
        options={formikOptions}
        className="w-1/2"
      />
    );
  }

  renderUnitDropdown(formikOptions) {
    const {ingredient} = this.props;

    return (
      <UnitDropdown
        name="unit"
        label="Unit of Measurement"
        createLabel="Create Custom Unit"
        secondaryBlock
        tooltip="The unit you will measure your recipes in. For example, milk will likely be measured in ounces."
        ingredient={ingredient}
        onChangeSoft={(unit) => {
          formikOptions.setFieldValue("displayUnit", unit.id);
        }}
        onCreate={(val) => {
          formikOptions.setFieldValue("unitName", val);
          formikOptions.setFieldValue("unit", null);
          formikOptions.setFieldValue("displayUnit", -1);
        }}
        nameField="unitName"
        options={formikOptions}
        flex
      />
    );
  }

  renderTypeDropdown(formikOptions) {
    const types = Object.values(INGREDIENT_TYPES);
    return (
      <ComboBox
        name="type"
        label="Type"
        tooltip="Items do not have production events or sub recipes. They can only be stocked and decremented. Standard means they can utilize the full recipe system"
        data={types.map((type) => ({
          name: INGREDIENT_TYPE_DISPLAYS[type],
          id: type,
          label: INGREDIENT_TYPE_DISPLAYS[type],
        }))}
        onChangeSoft={(type) => {
          this.setState({type});
        }}
        options={formikOptions}
      />
    );
  }

  renderCalculationTypeDropdown(formikOptions) {
    const types = Object.values(CALCULATION_TYPES);
    return (
      <ComboBox
        name="calculation"
        label="Calculation Type"
        tooltip={`Calculation Type determines if the ingredients stock is used, or if it's sub-recipe is used.`}
        data={types.map((type) => ({
          name: CALCULATION_TYPE_DISPLAYS[type],
          id: type,
          label: CALCULATION_TYPE_DISPLAYS[type],
        }))}
        onChangeSoft={(calculation) => {
          this.setState({calculation});
        }}
        options={formikOptions}
        className="w-1/2"
      />
    );
  }

  renderDefaultCost(options) {
    const {unitName} = options.values;
    const onBlur = (value) => `${value} per ${unitName}`;

    return (
      <FormInput
        label="Default Cost"
        hint="Optional"
        tooltip="The default cost of this ingredient per Unit of Measurement. This is used for Quick Stocking, and a section on your Reports."
        name="cost"
        options={options}
        onBlur={onBlur}
        isDollars={true}
        className="w-1/2"
      />
    );
  }

  fetchCurrentUnit(formikOptions) {
    const {values} = formikOptions;
    const {unit, unitName} = values;
    let currentUnit = null;

    if (unit) {
      currentUnit = unit;
    } else if (unitName) {
      currentUnit = -1;
    }

    return currentUnit;
  }

  renderDisplayUnitDropdown(formikOptions) {
    const {ingredient} = this.props;
    const {values} = formikOptions;
    const {unit, unitName} = values;
    const currentUnit = this.fetchCurrentUnit(formikOptions);

    return (
      <UnitDropdown
        name="displayUnit"
        label="Display Unit"
        units={!unit && unitName ? [{ID: -1, NAME: unitName, CONVERSIONS: []}] : null}
        tooltip="The unit you would like to display quantities in. For example, milk will likely be displayed in gallons."
        unit={currentUnit}
        secondaryBlock
        ingredient={ingredient}
        nameField="unitName"
        options={formikOptions}
        flex
      />
    );
  }

  renderIngredientInformationRow(formikOptions) {
    return (
      <>
        <FormRow classname="justify-between">
          {this.renderNameInput(formikOptions)}
          {this.renderCalculationTypeDropdown(formikOptions)}
        </FormRow>
        <FormRow classname="justify-between">
          {this.renderUnitDropdown(formikOptions)}
          {this.renderDisplayUnitDropdown(formikOptions)}
        </FormRow>
        <FormRow classname="justify-between">
          {this.renderDefaultCost(formikOptions)}
          {this.renderPARInput(formikOptions)}
        </FormRow>
        <FormRow classname="justify-between">
          {this.renderIngredientCategoryDropdown(formikOptions)}
          {this.renderExpirationInput(formikOptions)}
        </FormRow>
      </>
    );
  }

  renderPARInput(formikOptions) {
    return (
      <FormInput
        label="Par"
        hint="Optional"
        name="par"
        tooltip={{
          label: "Par",
          data: "Used with the 'fill to par' feature when ordering.",
        }}
        options={formikOptions}
        className="w-1/2"
      />
    );
  }

  renderIngredientCategoryDropdown(formikOptions) {
    return (
      <IngredientCategoryDropdown
        label="Groups"
        hint="Optional"
        name="categories"
        tooltip="Ingredient Groups to which this ingredient belongs."
        options={formikOptions}
        className="w-1/2"
      />
    );
  }

  renderExpirationInput(formikOptions) {
    return (
      <FormInput
        label="Expiration Time (minutes)"
        hint="Optional"
        name="expirationTime"
        tooltip="If this is set, ingredient stocks will expire after the set number of minutes."
        options={formikOptions}
        className="w-1/2"
      />
    );
  }

  renderVendorItemsHeader() {
    return (
      <div className="flex flex-row mb-2">
        <div className="text-sm font-medium">Vendor Items</div>

        <Tooltip className="ml-2" label="Vendor Items" data="Add pricing options for different vendors" />
      </div>
    );
  }

  renderNoPricing() {
    return <div className="flex shadow mb-2 p-6 justify-center text-sm font-medium">No Pricing Added</div>;
  }

  renderVendorItemRow(remove, index, formikOptions, item) {
    return <VendorItemForm remove={remove} index={index} formikOptions={formikOptions} item={item} />;
  }

  renderPricingArray(formikOptions) {
    const {values} = formikOptions;
    const {pricing} = values;

    return (
      <FieldArray name="pricing" options={formikOptions}>
        {({remove}) => (
          <div>
            {pricing.map((item, index) => {
              return this.renderVendorItemRow(remove, index, formikOptions, item);
            })}
          </div>
        )}
      </FieldArray>
    );
  }

  renderAddVendorItemButton(formikOptions) {
    const {values} = formikOptions;
    const {ingredient} = this.props;

    return (
      <div className="flex flex-row justify-end">
        <button
          onClick={() => this.addPricing(formikOptions)}
          className={classNames(
            "text-indigo-600 text-sm font-medium cursor-pointer",
            !ingredient?.UNIT_ID && !values.unit && "text-gray-500 cursor-auto"
          )}
          type="button"
        >
          {"+ Add Vendor Item"}
        </button>
      </div>
    );
  }

  renderVendorItemsAccordion(formikOptions) {
    const {values} = formikOptions;
    const {pricing} = values;
    const currentUnit = this.fetchCurrentUnit(formikOptions);

    return (
      <Accordion label="Vendor Items" className="mt-4">
        <div className="flex-column justify-between mt-6">
          {this.renderVendorItemsHeader()}
          {pricing.length === 0 ? this.renderNoPricing() : this.renderPricingArray(formikOptions)}
          {this.renderAddVendorItemButton(formikOptions)}
        </div>
      </Accordion>
    );
  }

  render() {
    const {formikOptions} = this.props;
    const {handleSubmit} = formikOptions;

    return (
      <form onSubmit={handleSubmit}>
        {this.renderIngredientInformationRow(formikOptions)}
        {this.renderVendorItemsAccordion(formikOptions)}
      </form>
    );
  }
}

IngredientForm.propTypes = {
  ingredient: PropTypes.object,
  onSubmit: PropTypes.func,
  formikOptions: PropTypes.object.isRequired,
};

export default setupReduxConnection(["shop", "supply"])(IngredientForm);

import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import EmployeeDropdown from "../../../dropdowns/team/employee-dropdown";
import FormStateSelect from "../../../components/form-state-select";
import * as Yup from "yup";
import {request} from "../../../utils/request";
import {showErrorAlert} from "../../../utils/alert-helper";

class CashAccountCreateCardModal extends Component {
  open() {
    this.slide.open();
  }

  handleSubmit = async ({
    type,
    employee,
    firstName,
    lastName,
    day,
    month,
    year,
    address,
    shipping,
    shipppingAddress,
  }) => {
    if (shipping === "same") {
      shipppingAddress = address;
    }

    try {
      await request("cash-account/cards", "POST", {
        TYPE: type,
        EMPLOYEE_ID: employee,
        FIRST_NAME: firstName,
        LAST_NAME: lastName,
        DOB: `${year}-${month}-${day}`,
        ADDRESS: {
          LINE_1: address.line1,
          LINE_2: address.line2,
          CITY: address.city,
          STATE: address.state,
          ZIPCODE: address.zip,
        },
        SHIPPING_ADDRESS: {
          LINE_1: shipppingAddress.line1,
          LINE_2: shipppingAddress.line2,
          CITY: shipppingAddress.city,
          STATE: shipppingAddress.state,
          ZIPCODE: shipppingAddress.zip,
        },
      });
    } catch (e) {
      showErrorAlert("Error", "Could not create card");
    }

    this.slide.close();
  };

  render() {
    const addressValidation = Yup.object().shape({
      line1: Yup.string().required("Line 1 is required"),
      line2: Yup.string().nullable(),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zip: Yup.number("Zipcode must be a number").required("Zip is required"),
    });

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`Create Card`}
        buttonLabel="Create"
        buttonOnClick={() => this.formikRef.submitForm()}
        buttonDisabled={this.formikRef?.isValid}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            shipping: "same",
            shippingAddress: {},
          }}
          validationSchema={Yup.object({
            employee: Yup.string().required("Employee is required"),

            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),

            month: Yup.number().required("Month is required"),
            day: Yup.number().required("Day is required"),
            year: Yup.number().required("Year is required"),

            address: addressValidation,
            shippingAddress: Yup.object()
              .shape()
              .when("shipping", {
                is: (shipping) => shipping === "custom",
                then: addressValidation,
              }),
          })}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {values, setFieldValue, handleSubmit} = formikOptions;
            const {employee, type, shipping} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label={"Type"}
                  name="type"
                  options={formikOptions}
                  data={Object.values(CARD_TYPES)}
                />

                {type && (
                  <div>
                    <div className={"text-sm font-bold mt-4"}>Cardholder Information</div>

                    <EmployeeDropdown
                      name="employee"
                      label={"Employee"}
                      value={employee}
                      options={formikOptions}
                      onChange={(employee) => {
                        const {serverEmployees} = this.state;

                        const emp = serverEmployees.find((_emp) => _emp.ID === employee);

                        const nameArr = emp.FULL_NAME.split(" ");

                        const firstName = nameArr[0];
                        const lastName = nameArr.length > 1 ? nameArr[1] : "";

                        setFieldValue("employee", employee);
                        setFieldValue("firstName", firstName);
                        setFieldValue("lastName", lastName);
                      }}
                    />

                    {employee && (
                      <div>
                        <div className={"flex flex-row justify-between"}>
                          <FormInput label="First Name" name="firstName" options={formikOptions} />

                          <FormInput label="Last Name" name="lastName" options={formikOptions} />
                        </div>

                        <div className="block text-sm font-medium text-gray-700 -mb-2 mt-1">
                          Date of Birth
                        </div>

                        <div className={"flex flex-row"}>
                          <FormInput
                            name={"month"}
                            className="w-14"
                            placeholder={"MM"}
                            options={formikOptions}
                          />

                          <FormInput
                            name={"day"}
                            className="w-14 ml-5"
                            placeholder={"DD"}
                            options={formikOptions}
                          />

                          <FormInput
                            name={"year"}
                            className="w-16 ml-5"
                            placeholder={"YYYY"}
                            options={formikOptions}
                          />
                        </div>

                        <FormInput name={"address.line1"} label={"Address Line 1"} options={formikOptions} />

                        <FormInput name={"address.line2"} label={"Address Line 2"} options={formikOptions} />

                        <div className="flex flex-row">
                          <FormInput name="address.city" label="City" options={formikOptions} />

                          <FormStateSelect
                            className="ml-5"
                            name="address.state"
                            label="State"
                            options={formikOptions}
                          />

                          <FormInput
                            className="ml-5 w-24"
                            name="address.zip"
                            label="Zipcode"
                            options={formikOptions}
                          />
                        </div>

                        {type === CARD_TYPES.PHYSICAL.value && (
                          <div>
                            <FormSelect
                              label={"Shipping Address"}
                              name={"shipping"}
                              data={[
                                {label: "Same Address", value: "same"},
                                {label: "Custom Address", value: "custom"},
                              ]}
                              options={formikOptions}
                            />
                            {shipping === "custom" && (
                              <div>
                                <FormInput
                                  name={"shippingAddress.line1"}
                                  label={"Address Line 1"}
                                  options={formikOptions}
                                />

                                <FormInput
                                  name={"shippingAddress.line2"}
                                  label={"Address Line 2"}
                                  options={formikOptions}
                                />

                                <div className="flex flex-row">
                                  <FormInput
                                    name="shippingAddress.city"
                                    label="City"
                                    options={formikOptions}
                                  />

                                  <FormStateSelect
                                    className="ml-5"
                                    name="shippingAddress.state"
                                    label="State"
                                    options={formikOptions}
                                  />

                                  <FormInput
                                    className="ml-5 w-24"
                                    name="shippingAddress.zip"
                                    label="Zipcode"
                                    options={formikOptions}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const CARD_TYPES = {
  VIRTUAL: {
    label: "Virtual",
    value: "VIRTUAL",
  },
  PHYSICAL: {
    label: "Physical",
    value: "PHYSICAL",
  },
};

export default CashAccountCreateCardModal;

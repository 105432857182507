import React, {Component} from "react";
import {FormInput, FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import ProductDropdown from "../../../dropdowns/sales/product-dropdown";

class ProductInventoryModal extends Component {
  state = {products: []};

  open(products) {
    this.setState({products}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createInventory({product, quantity}) {
    await request("inventory/dashboard/toggle", "POST", {
      PRODUCTS: [{ID: product, INVENTORY: parseInt(quantity)}],
    });

    console.log(this.productDropdown.fetchProduct(true));

    this.props.addState(this.productDropdown.fetchProduct(true));
    this.modal.close();
  }

  render() {
    const {products} = this.state;

    return (
      <Modal
        buttonLabel={"Add"}
        label={"Add Inventory"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.createInventory.bind(this)}
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            product: null,
            quantity: "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ProductDropdown
                  exclude={products.map((item) => item.ID)}
                  ref={(e) => (this.productDropdown = e)}
                  placeholder="Select a product"
                  options={formikOptions}
                  name="product"
                />

                <FormInput
                  label="Starting Quantity"
                  options={formikOptions}
                  placeholder="5"
                  name="quantity"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ProductInventoryModal;

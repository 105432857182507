import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";

class PatronDropdown extends Component {
  state = {data: [], patron: null};

  componentDidMount() {
    // If a card is passed and is associated with a patron
    if (this.props?.card?.PATRON_ID) {
      request("patron/" + this.props.card.PATRON_ID, "GET")
        .then((patron) => {
          return this.setState({patron});
        })
        .then(() => this.syncData(null, true));
      return;
    }
    this.syncData().catch((err) => {
      console.log("Problem fetching patron data", err);
    });
  }

  async syncData(search = null) {
    const {patron} = this.state;
    const {exclude = []} = this.props;

    const serverPatrons = await request("patrons/dumb", "POST", {
      SEARCH: search,
      CURSOR: null,
    });

    let {data} = serverPatrons;

    // Add the patron to the list if it is not already in the list. Used for initial values
    if (patron && data.findIndex((pat) => pat.ACCOUNT_ID === patron.ACCOUNT_ID) === -1) {
      data.push(patron);
    }

    data.sort((a, b) => a.FULL_NAME > b.FULL_NAME);
    data = data.filter((data) => !exclude.includes(data.ID));

    console.log("DATA", data, exclude);

    this.setState({data});
  }

  render() {
    const {data} = this.state;
    const {formikOptions, usePatronId, label} = this.props;

    return (
      <ComboBox
        onQueryChange={(search) => {
          search.length > 0 && this.syncData(search);
        }}
        name="patronId"
        disabled={this.props.disabled}
        label={label ?? "Patron Name"}
        options={formikOptions}
        data={data.map((pat) => {
          return {
            label: `${pat?.FULL_NAME} - ${pat.PHONE ? getFormattedPhone(pat?.PHONE) : ""}`,
            value: usePatronId ? pat.ID : pat.ACCOUNT_ID,
            id: usePatronId ? pat.ID : pat.ACCOUNT_ID,
          };
        })}
        tooltip={this.props.tooltip}
      />
    );
  }
}

PatronDropdown.propTypes = {
  ...ComboBox.propTypes,
};

export default PatronDropdown;

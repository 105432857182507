import React, {Component} from "react";
import {Modal, Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ModifierIngredientModal from "./modifier-ingredient-modal";
import {updateStateDelegator} from "../../../utils/util";

class ModifierInventoryModal extends Component {
  state = {modifier: null, option: null};

  open(modifier = null, option = null) {
    this.setState({modifier, option}, () => {
      this.modal.open();
    });
  }

  render() {
    const {modifier, option} = this.state;

    const tableColumns = [
      {
        value: "INGREDIENT_NAME",
        label: "Ingredient Name",
      },
      {
        width: 1,
        value: "QUANTITY",
        label: "Quantity",
      },
      {
        width: 1,
        value: "INGREDIENT_UNIT_NAME",
        label: "Ingredient Unit",
      },
      {
        value: "ENABLED",
        label: "Enabled",
        type: "bool",
      },
    ];

    const seq = Math.max(...(option?.INGREDIENTS || []).map((item) => item.SEQ), 0);

    return (
      <Modal
        large
        closeLabel="Close"
        buttonLabel="Add Ingredients"
        ref={(e) => (this.modal = e)}
        label={option?.NAME + "'s Ingredients"}
        description="Attach ingredients to certain options on a modifier"
        buttonOnClick={() => {
          this.modal.stopLoading();
          this.ingredientModal.open(null, seq);
        }}
      >
        <ModifierIngredientModal
          option={option}
          modifier={modifier}
          ref={(e) => (this.ingredientModal = e)}
          addState={(item) => {
            option.INGREDIENTS.push(item);

            this.setState({option});
          }}
          updateState={(id, item) => {
            option.INGREDIENTS = updateStateDelegator(option.INGREDIENTS, id, item);

            this.setState({option});
          }}
        />

        <Formik
          initialValues={{modifier: null}}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={(values) => {
            this.props.updateState(values, () => this.modal.close());
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <Table
                  white
                  className="mt-4"
                  columns={tableColumns}
                  data={option?.INGREDIENTS}
                  ref={(e) => (this.modifierTable = e)}
                  actionButtons={[
                    {
                      label: "Edit Ingredient",
                      onClick: (item) => {
                        this.ingredientModal.open(item, seq);
                      },
                    },
                  ]}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ModifierInventoryModal;

import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";

class Gallery extends Component {
  render() {
    const {data, options, name, isModal} = this.props;

    return (
      <FormElement {...this.props}>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <ul
              role="list"
              className={classNames(
                "grid gap-x-2 gap-y-2 ",
                isModal
                  ? "grid-cols-6"
                  : "grid-cols-2 sm:grid-cols-3 sm:gap-x-2 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 xl:gap-x-2"
              )}
            >
              {data.map((file) => {
                const {id, source, label, description} = file;
                const isActive = value === id;

                return (
                  <li
                    onClick={() => {
                      if (options) {
                        options.setFieldValue(name, id);
                      }

                      onChange && onChange(id);
                    }}
                    key={file.name}
                    className="relative"
                  >
                    <div
                      className={classNames(
                        isActive
                          ? "ring-2 ring-offset-2 ring-indigo-500"
                          : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500",
                        "group block w-full aspect-w-10 aspect-h-7 rounded-sm bg-gray-100 overflow-hidden"
                      )}
                    >
                      <img
                        src={source}
                        alt=""
                        className={classNames(
                          isActive ? "" : "group-hover:opacity-75",
                          "object-cover pointer-events-none"
                        )}
                      />

                      <button type="button" className="absolute inset-0 focus:outline-none">
                        <span className="sr-only">View details for {label}</span>
                      </button>
                    </div>

                    {label && (
                      <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                        {label}
                      </p>
                    )}

                    {description && (
                      <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                        {description}
                      </p>
                    )}
                  </li>
                );
              })}
            </ul>
          );
        }}
      </FormElement>
    );
  }
}

Gallery.propTypes = {
  ...FormElement.propTypes,

  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,

      label: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default Gallery;

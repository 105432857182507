import React, {Component} from "react";
import {Filter, Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {getPayrollEmployees} from "../../../utils/payroll-helper";
import {Settings} from "react-feather";
import PayrollEmployeeSettingsModal from "../../../modals/payroll/payroll-employee-settings-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import PayrollInactiveEmployeeAddModal from "../../../modals/payroll/payroll-inactive-employee-add-modal";
import SuccessBadge from "../../../components/badges/success-badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import WarningBadge from "../../../components/badges/warning-badge";
import GrayBadge from "../../../components/badges/gray-badge";
import LoadingSpinner from "../../../components/loading-spinner";
import PayrollWhitelistWrapper from "../../../features/payroll/payroll-whitelist-wrapper";
import {withRouter} from "../../../utils/navigation";

class PayrollEmployeesPage extends Component {
  state = {
    isLoading: true,
    payrollEmployees: null,
    search: "",
    locations: null,
    loadingEmployees: [],
  };

  async componentDidMount() {
    await this.refresh();
  }

  refresh = async () => {
    let emps = await getPayrollEmployees();

    this.setState({
      payrollEmployees: emps,
      isLoading: false,
    });

    return true;
  };

  renderIncludeBadge(row) {
    return (
      <div className={"space-x-2 flex-row items-center float-right"}>
        {!row.IS_ONBOARDED && row.HAS_TIMESHEETS_THIS_PERIOD && (
          <DangerBadge className={""}> Payrun</DangerBadge>
        )}

        {!row.INCLUDE_IN_REGULAR && row?.CHECK_EMPLOYEE_UNIQUE_ID && (
          <GrayBadge>Not Included in Payruns</GrayBadge>
        )}

        {!row.PREF_IS_DIRECT_DEPOSIT && row?.CHECK_EMPLOYEE_UNIQUE_ID && <GrayBadge>Manual Pay</GrayBadge>}

        {!row.ACTIVE && (
          <WarningBadge className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 cursor-pointer">
            Inactive
          </WarningBadge>
        )}
      </div>
    );
  }

  renderStatus(row) {
    const {loadingEmployees} = this.state;

    let {
      FULL_NAME,
      EMPLOYEE_UNIQUE_ID,
      ACTIVE,
      CHECK_EMPLOYEE_ID,
      IS_ONBOARDED,
      CHECK_EMPLOYEE_UNIQUE_ID,
      EMPLOYEE_ID,
    } = row;

    const isLoading = loadingEmployees.includes(EMPLOYEE_UNIQUE_ID);

    return (
      <div className="flex flex-row items-center">
        <div className="w-28 space-x-5 flex flex-row">
          <div className={"w-24"}>
            {isLoading && !CHECK_EMPLOYEE_UNIQUE_ID && <LoadingSpinner size={15} />}

            {!isLoading && !CHECK_EMPLOYEE_UNIQUE_ID && (
              <button
                type="button"
                className="inline-flex items-center px-2.5 py-0.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
                onClick={async () => {
                  if (!ACTIVE) {
                    this.payrollInactiveEmployeeSettingsModal.open(EMPLOYEE_UNIQUE_ID, FULL_NAME);
                  } else {
                    this.setState((prevState) => ({
                      loadingEmployees: [...prevState.loadingEmployees, EMPLOYEE_UNIQUE_ID],
                    }));

                    const uniqueId = await request(
                      `payroll/employees/add/${EMPLOYEE_UNIQUE_ID}`,
                      "POST",
                      null
                    );

                    await this.refresh();

                    row = {
                      ...row,
                      INCLUDE_IN_REGULAR: 1,
                    };

                    this.payrollEmployeesSettingsModal.open(uniqueId, FULL_NAME);
                  }
                }}
              >
                Add
              </button>
            )}
          </div>

          {!IS_ONBOARDED && (
            <DangerBadge className={"h-5"}>
              <FontAwesomeIcon icon={"x"} className="h-3 w-3" />
            </DangerBadge>
          )}

          {CHECK_EMPLOYEE_ID && !!IS_ONBOARDED && (
            <SuccessBadge className={"h-5"}>
              <FontAwesomeIcon icon={"check"} className="h-3 w-3" />
            </SuccessBadge>
          )}
        </div>

        {CHECK_EMPLOYEE_UNIQUE_ID && (
          <Settings
            className="ml-3 h-5 w-5 cursor-pointer"
            onClick={() => {
              this.payrollEmployeesSettingsModal.open(row.CHECK_EMPLOYEE_UNIQUE_ID, row.FULL_NAME);
            }}
          />
        )}
      </div>
    );
  }

  getColumns() {
    return [
      {label: "Name", value: "FULL_NAME"},
      {
        label: "",
        width: 1,
        format: (value, row) => {
          return this.renderIncludeBadge(row);
        },
      },
      {
        label: "Onboarding",
        value: "",
        width: 1,
        format: (value, row) => {
          return this.renderStatus(row);
        },
      },
    ];
  }

  renderAdded() {
    const {payrollEmployees} = this.state;

    return (
      <Filter
        defaultFilters={[{filter: "status", id: 1}]}
        data={[
          {
            id: "status",
            label: "Status",
            onFilter: (options, data) => {
              return data.filter((item) => options.includes(item.ACTIVE));
            },
            options: [
              {label: "Active", id: 1},
              {label: "Inactive", id: 0},
            ],
          },
        ]}
      >
        {(filters) => {
          return (
            <Table
              filters={filters}
              data={payrollEmployees
                .filter((_employee) => _employee.CHECK_EMPLOYEE_UNIQUE_ID)
                .sort((a, b) => {
                  if (a.IS_ONBOARDED && !b.IS_ONBOARDED) {
                    return 1;
                  } else if (!a.IS_ONBOARDED && b.IS_ONBOARDED) {
                    return -1;
                  }

                  return a.FULL_NAME.localeCompare(b.FULL_NAME);
                })}
              columns={this.getColumns()}
            />
          );
        }}
      </Filter>
    );
  }

  renderNotAdded() {
    const {payrollEmployees} = this.state;
    const {companyLocations, payrollGroup} = this.props.shop;

    return (
      <Filter
        defaultFilters={payrollGroup.EMPLOYEE_LOCATIONS.map((_locId) => ({
          filter: "home-location",
          id: _locId,
        }))}
        data={[
          {
            id: "home-location",
            label: "Home Location",
            onFilter: (options, data) => {
              return data.filter((item) => options.includes(item.LOCATION_ID));
            },
            options: companyLocations
              .filter(({TYPE}) => TYPE === "STORE")
              .map((_loc) => ({
                id: _loc.ID,
                label: _loc.NAME,
              })),
          },
        ]}
      >
        {(filters) => {
          return (
            <Table
              filters={filters}
              data={payrollEmployees.filter((_employee) => !_employee.CHECK_EMPLOYEE_UNIQUE_ID)}
              columns={this.getColumns()}
            />
          );
        }}
      </Filter>
    );
  }

  render() {
    let {isLoading} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <PayrollWhitelistWrapper router={this.props.router}>
        <PayrollEmployeeSettingsModal
          onSave={this.refresh}
          ref={(e) => (this.payrollEmployeesSettingsModal = e)}
        />

        <PayrollInactiveEmployeeAddModal
          onSave={this.refresh}
          ref={(e) => (this.payrollInactiveEmployeeSettingsModal = e)}
        />

        <PageHeadings
          label="Payroll Employees"
          description={"Manage your employee's payroll onboarding process"}
        />

        <Tab
          data={[
            {id: "added", label: "Added"},
            {id: "not_added", label: "Not Added"},
          ]}
        >
          {(id) => {
            switch (id) {
              case "added":
                return this.renderAdded();
              case "not_added":
                return this.renderNotAdded();
            }
          }}
        </Tab>
      </PayrollWhitelistWrapper>
    );
  }
}

const STATUS_SEQ = {
  none: 0,
  completed: 1,
  not_started: 3,
  blocking: 3,
  needs_attention: 2,
};

export default setupReduxConnection(["shop"])(withRouter(PayrollEmployeesPage));

import React, {Component} from "react";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Formik} from "formik";
import {Card, FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import {getStore, setupReduxConnection} from "../../../../redux";
import {updateProducts} from "../../../../redux/menu";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import PricingModifierModal from "../../../../modals/sales/modifiers/pricing-modifier-modal";
import RecipeModifierInformation from "./RecipeModifierInformation";

class RecipePricingTab extends Component {
  async savePricing({price, type, weightPrice, cost}) {
    const {product} = this.props;
    const modifiers = product.CUSTOMIZATIONS;

    this.pricingButton.startLoading();

    const productVariantIndex = modifiers.findIndex((item) => {
      return item.TYPE === 5;
    });

    const toSaveObject = {
      PRICE: decimalToDollars(price),
      VARIABLE: parseInt(type) === 1 ? 1 : 0,
      WEIGHT_UNIT_COST: parseInt(type) === 3 && weightPrice ? decimalToDollars(weightPrice) : null,
      ESTIMATED_COST: decimalToDollars(cost),
    };

    await request("products/" + product.ID, "PATCH", toSaveObject);

    if (parseInt(type) === 2) {
      this.variantRef.outsideSave();
    } else if (productVariantIndex !== -1) {
      const productModifiers = modifiers;
      const productVariant = productModifiers[productVariantIndex];

      if (productVariant.PRESET_ID) {
        await request(`preset/${productVariant.ID}/${product.ID}/remove`, "POST", {});
      } else if (productVariant.ID) {
        await request("customization/" + productVariant.ID, "DELETE", {});
      }

      productModifiers.splice(productVariantIndex, 1);

      this.setState({product: {...product, CUSTOMIZATIONS: productModifiers}}, () => {
        this.formikRef.setFieldValue("variant", null);
      });
    } else {
      product.PRICE = decimalToDollars(price);
      this.setState({product: {...product}}, () => {
        this.formikRef.setFieldValue("variant", null);
      });
    }

    showSuccessNotification("Product Pricing Saved", `The pricing for ${product?.NAME} has been saved`);

    this.pricingButton?.stopLoading && this.pricingButton.stopLoading();
    getStore().dispatch(updateProducts());
  }

  fetchTaxRate() {
    const {product} = this.props;

    const rate = product.TAX_RATES.reduce((accum, item) => {
      if (item.IS_INCLUSIVE) {
        return accum;
      }

      return accum + item.RATE;
    }, 0);

    return rate / 100;
  }

  renderPricingContent(type, formikOptions) {
    const {location} = this.props.shop;
    const {product} = this.props;
    const modifiers = product.CUSTOMIZATIONS;

    if (parseInt(type) === 0) {
      const price = decimalToDollars(formikOptions.values.price);

      return (
        <>
          <FormInput name="price" options={formikOptions} label="Product Price" />

          <FormInput
            disabled
            label="Product Price w/ Tax"
            value={toDollars(price + Math.round(price * this.fetchTaxRate()))}
          />

          <FormInput
            name="cost"
            options={formikOptions}
            label="Estimated Cost"
            tooltip={"Estimated cost for the cost of goods report."}
          />
        </>
      );
    }

    if (parseInt(type) === 1) {
      const price = decimalToDollars(formikOptions.values.price);

      return (
        <>
          <FormInput name="price" options={formikOptions} label="Base Price" />

          <FormInput
            disabled
            label="Variable Base Price w/ Tax"
            value={toDollars(price + Math.round(price * (location.TAX_RATE / 100)))}
          />

          <FormInput
            name="cost"
            options={formikOptions}
            label="Estimated Cost"
            tooltip={"Estimated cost for the cost of goods report."}
          />
        </>
      );
    }

    if (parseInt(type) === 2) {
      const variantIndex = modifiers.findIndex((item) => item.TYPE === 5);
      const variant = modifiers[variantIndex];

      if (variant) {
        return (
          <div>
            <RecipeModifierInformation
              variant
              product={product}
              modifier={variant}
              ref={(e) => (this.variantRef = e)}
              updateState={(_, variant) => {
                modifiers.splice(variantIndex, 1, variant);

                this.setState({modifiers});
              }}
              addState={(variant) => {
                modifiers.splice(variantIndex, 1, variant);

                this.setState({modifiers});
              }}
            />
          </div>
        );
      }
    }

    if (parseInt(type) === 3) {
      return (
        <div>
          <FormInput
            name="price"
            placeholder="2.50"
            options={formikOptions}
            label="Base Price"
            tooltip={{
              label: "Base Price",
              data: "This is the base price of the product. The total cost of the product will be this price plus the weighted price.",
            }}
          />

          <FormInput
            name="weightPrice"
            placeholder={"0.10"}
            options={formikOptions}
            label="Price Per Unit Weight"
            tooltip={{
              label: "Weighted Price",
              data: "This is the price per unit of weight. The total cost of the product will be this price times the weight of the item plus the base price. ",
            }}
          />
        </div>
      );
    }

    return <div />;
  }

  async removeVariant() {
    showLoadingConfirmAlert(
      "Remove Sized Pricing",
      "Are you sure you want to delete this products sized pricing?"
    ).then(async (close) => {
      this.variantButton.startLoading();

      this.savePricing({...this.formikRef.values, type: "0"})
        .then(() => {
          this.formikRef && this.formikRef.setFieldValue("variant", null);
          this.formikRef && this.formikRef.setFieldValue("type", "0");
        })
        .finally(() => {
          close();
        });
    });
  }

  render() {
    const {product} = this.props;
    const modifiers = product.CUSTOMIZATIONS;

    let productType = product.VARIABLE ? 1 : 0;
    const productVariant = modifiers.find((item) => {
      return item.TYPE === 5;
    });

    if (productVariant) {
      productType = 2;
    }

    if (product.WEIGHT_UNIT_COST !== null) {
      productType = 3;
    }

    const initialValues = {
      global: "premade",
      variant: productVariant ?? null,
      price: toDollars(product.PRICE),
      cost: toDollars(product.ESTIMATED_COST),
      type: productType,
      weightPrice: productType === 3 ? toDollars(product.WEIGHT_UNIT_COST) : null,
    };

    return (
      <>
        <PricingModifierModal
          ref={(e) => (this.pricingModal = e)}
          clearState={() => this.formikRef.setFieldValue("type", "0")}
          updateState={async ({type, modifier}, close) => {
            if (type === "custom") {
              const variantPayload = {
                ID: null,
                NAME: "Sizes",
                MAX_SELECTIONS: 0,
                MIN_SELECTIONS: 0,
                INTERNAL_NAME: product?.NAME + " Sizes",
                DEFAULT_OPTION: null,
                TYPE: 5,
                REQUIRED: 1,
                ENABLED: 1,
                OPTIONS: [],
                PRESET: 0,
              };

              modifiers.push(variantPayload);

              this.setState({modifiers}, () => {
                this.formikRef.setFieldValue("variant", variantPayload);

                close();
              });
            } else {
              const serverModifier = await request(
                "v2/preset/" + modifier + "/product/" + product.ID,
                "POST",
                {}
              );

              modifiers.push(serverModifier);

              this.setState({modifiers}, () => {
                this.formikRef.setFieldValue("variant", serverModifier);

                close();
              });
            }
          }}
        />

        <Formik
          initialValues={initialValues}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.savePricing.bind(this)}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <Card
                label="Pricing"
                buttons={[
                  ...(values.variant
                    ? [
                        {
                          label: "Remove Sized Pricing",
                          ref: (e) => (this.variantButton = e),
                          onClick: () => this.removeVariant(),
                        },
                      ]
                    : []),
                  {
                    label: "Save Pricing",
                    ref: (e) => (this.pricingButton = e),
                    onClick: handleSubmit,
                  },
                ]}
                tooltip={{
                  data: [
                    {
                      label: "Pricing Modifiers",
                      data: "The pricing type determines how the price of a product is ultimately calculated. There are a few options for the pricing type.",
                    },
                    {
                      label: "Fixed Pricing",
                      data: "Fixed priced items are given a single base price. Modifiers added to this type of item are summed to form the final price.",
                    },
                    {
                      label: "Sized Pricing",
                      data: "Sized priced items have different prices based on the size of the item. You may customize the available sizes and their prices. Sizes are displayed as a required modifier to the item.",
                    },
                    {
                      label: "Variable Pricing",
                      data: "Variable priced items are assigned a price at checkout on the POS. These should not be used on kiosk, mobile app or order website.",
                    },
                    {
                      label: "Weight Pricing",
                      data: "Weight priced items are given a base price and a price per unit. At checkout, a weight must be given on the POS. The final price of the item will be the base price + the price per unit * the weight + price of any modifiers.",
                    },
                  ],
                }}
              >
                <div className="p-2 sm:p-6">
                  <FormSelect
                    name="type"
                    secondaryBlock
                    onChangeSoft={({value}) => {
                      if (parseInt(value) === 2) {
                        if (values.variant === null) {
                          this.pricingModal.open();
                        }
                      }
                    }}
                    options={formikOptions}
                    label="Pricing Type"
                    data={[
                      {
                        value: "0",
                        label: "Fixed Pricing",
                        secondary: "Base price of the product is a fixed rate",
                      },
                      {
                        value: "2",
                        label: "Sized Pricing",
                        secondary: "Set multiple sizes that will change the default price of the product",
                      },
                      {
                        value: "1",
                        label: "Variable Pricing",
                        secondary:
                          "Price will be set at checkout on the point of sale. Should not be used for mobile products.",
                      },
                      {
                        value: "3",
                        label: "Weight Pricing",
                        secondary: "Price is based on weight of the product",
                      },
                    ]}
                  />

                  {this.renderPricingContent(values.type, formikOptions)}
                </div>
              </Card>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(RecipePricingTab);

import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {remindPayrollEmployeeToOnboard, setEmployeeSettings} from "../../utils/payroll-helper";
import LoadingSpinner from "../../components/loading-spinner";
import {ExclamationIcon} from "@heroicons/react/outline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DangerBadge from "../../components/badges/danger-badge";
import {request} from "../../utils/request";
import {CheckIcon} from "@heroicons/react/solid";

class PayrollEmployeeSettingsModal extends Component {
  state = {
    payrollEmployee: null,
    fullName: "",
    emailSent: false,
  };

  open(checkEmployeeUniqueId, fullName) {
    this.setState({fullName, payrollEmployee: null, emailSent: false}, () => this.slide.open());

    request(`payroll/employees/${checkEmployeeUniqueId}`).then((res) =>
      this.setState({payrollEmployee: res})
    );

    this.slide.open();
  }

  handleSubmit = async (values) => {
    let {CHECK_EMPLOYEE_UNIQUE_ID} = this.state.payrollEmployee;

    let {include, payment} = values;

    let payload = {
      INCLUDE_IN_REGULAR: include,
      PREFERRED_PAYMENT_METHOD: payment,
    };

    delete values.include;
    delete values.payment;

    payload = {...payload, COMPANY_DEFINED_ATTRIBUTES: values};

    await setEmployeeSettings(CHECK_EMPLOYEE_UNIQUE_ID, payload);
    await this.props.onSave();

    this.slide.close();
  };

  _convertCheckStep(step) {
    if (step === "ssn") {
      return "SSN";
    }

    const finalStep = step.replaceAll("_", " ");

    return finalStep.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }

  renderOnboardingStatus() {
    let {payrollEmployee} = this.state;

    if (payrollEmployee?.IS_ONBOARDED) {
      return <div></div>;
    }

    return (
      <div className="text-sm font-medium text-gray-500 space-y-1">
        <div className="text-sm font-medium text-gray-700">Onboarding Status</div>

        <div>
          {!payrollEmployee.CHECK_EMPLOYEE_ID && (
            <div className={"flex flex-row items-center"}>
              <DangerBadge className={"mr-2"}>
                <FontAwesomeIcon icon={"x"} className="h-3 w-3" />
              </DangerBadge>
              Not Started
            </div>
          )}

          {payrollEmployee.CHECK_EMPLOYEE_ID && (
            <div className="space-y-1">
              {payrollEmployee?.CHECK_EMPLOYEE.onboard.remaining_steps.map((_step) => {
                return (
                  <div className={"flex flex-row items-center"}>
                    <DangerBadge className={"mr-2"}>
                      <FontAwesomeIcon icon={"x"} className="h-3 w-3" />
                    </DangerBadge>
                    {this._convertCheckStep(_step)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  getInitialCompanyDefined(companyDefined) {
    let toReturn = {};

    if (companyDefined) {
      for (let attribute of companyDefined) {
        let {name, value} = attribute;
        toReturn[name] = value;
      }
    }

    return toReturn;
  }

  //SECONDARY FORM INPUT LABEL
  renderCompanyDefinedAttribute(companyDefinedAttribute, formikOptions) {
    let {name, type, label, options} = companyDefinedAttribute;

    if (type === "boolean") {
      return (
        <FormSelect
          name={name}
          label={label}
          data={[
            {label: "Yes", value: "true"},
            {label: "No", value: "false"},
          ]}
          options={formikOptions}
        />
      );
    }

    if (type === "percent" || type === "string" || type === "number") {
      return <FormInput label={label} name={name} options={formikOptions} />;
    }

    if (type === "select") {
      return <FormSelect label={label} name={name} options={formikOptions} data={options} />;
    }
  }

  render() {
    let {payrollEmployee, fullName, emailSent} = this.state;

    const companyDefinedAttributes = payrollEmployee?.COMPANY_DEFINED_ATTRIBUTES ?? [];

    const include = payrollEmployee?.INCLUDE_IN_REGULAR;
    const payment = payrollEmployee?.CHECK_EMPLOYEE?.payment_method_preference;
    const firstName = fullName?.split(" ")[0];

    let isStarted = payrollEmployee?.CHECK_EMPLOYEE_ID;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`${fullName}'s Payroll Settings`}
        buttonLabel="Save"
        buttonOnClick={() => this.formikRef.submitForm()}
      >
        {!payrollEmployee && <LoadingSpinner />}

        {payrollEmployee && (
          <div>
            {/*<div className="mb-4">*/}
            {/*  {employeeInfoMissing && (*/}
            {/*    <div className="flex flex-row items-center align-middle justify-center text-sm font-semibold mb-2 text-gray-600">*/}
            {/*      <ExclamationIcon className="h-4 w-4 mr-1 " />*/}

            {/*      <div>{`${firstName} still has information to fill out!`}</div>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}

            <div className="flex flex-row mb-3">
              {!emailSent && (
                <button
                  type="button"
                  className="w-36 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
                  onClick={async () => {
                    this.setState({emailSent: true});
                    await remindPayrollEmployeeToOnboard(payrollEmployee.ID);
                  }}
                >
                  <div>
                    <div>Send Payroll Link</div>

                    <div className="text-xs text-gray-500">via Email</div>
                  </div>
                </button>
              )}

              {emailSent && (
                <div className="flex flex-row h-full items-center">
                  <CheckIcon className={"h-4 w-4 items-center mr-1"} /> Sent
                </div>
              )}
            </div>

            {this.renderOnboardingStatus()}

            <Formik
              enableReinitialize
              initialValues={{
                include,
                payment,
                ...this.getInitialCompanyDefined(companyDefinedAttributes),
              }}
              onSubmit={this.handleSubmit}
              innerRef={(e) => (this.formikRef = e)}
            >
              {(formikOptions) => {
                let {include} = formikOptions.values;

                return (
                  <div>
                    <div>
                      <div className="">
                        <FormSelect
                          label="Include in Regular Payruns"
                          name="include"
                          data={[
                            {label: "Yes", value: 1},
                            {label: "No", value: 0},
                          ]}
                          options={formikOptions}
                        />

                        {!!include && isStarted && (
                          <FormSelect
                            label="Default Payment Method"
                            name="payment"
                            data={[
                              {
                                label: "Direct Deposit",
                                value: "direct_deposit",
                              },
                              {label: "Manual", value: "manual"},
                            ]}
                            options={formikOptions}
                          />
                        )}
                      </div>

                      {companyDefinedAttributes &&
                        companyDefinedAttributes.filter((attribute) => !Boolean(attribute.value)).length >
                          0 && (
                          <div className="mt-5 flex flex-row items-center text-red-600 text-sm font-semibold">
                            <ExclamationIcon className="h-10 w-10 mr-1 text-red-600" />
                            <div className="">
                              The below Company Defined Attributes must be completed for {firstName} to be
                              paid
                            </div>
                          </div>
                        )}

                      {companyDefinedAttributes?.map((companyDefinedAttribute) => {
                        return this.renderCompanyDefinedAttribute(companyDefinedAttribute, formikOptions);
                      })}
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}
      </Modal>
    );
  }
}

export default PayrollEmployeeSettingsModal;

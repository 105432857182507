import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../../utils/request";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";

class SendTransferModal extends Component {
  state = {transfer: null};

  open(transfer) {
    this.setState({transfer}, () => this.modal.open());
  }

  sendTransfer() {
    const {transfer} = this.state;

    request("inventory-transfers/send/" + transfer.ID, "POST")
      .then(async () => {
        showSuccessNotification("Inventory Transfer Sent Successfully.");
        this.props.syncState && (await this.props.syncState());
      })
      .catch((e) => {
        if (e.error === "OUT_OF_STOCK") {
          showErrorNotification(
            "One or more items are out of stock.",
            "One or more of your transfer items are out of stock. Please reduce the transfer quantity and try again."
          );

          return;
        }

        showErrorNotification(
          "Something went wrong.",
          "Something went wrong sending the inventory transfer. Please try again."
        );
      })
      .finally(() => {
        this.modal.close();
      });
  }

  render() {
    const {transfer} = this.state;

    if (!transfer) {
      return <div />;
    }

    return (
      <Modal
        deleteOnClick={() => this.modal.close()}
        closeLabel="Cancel"
        buttonLabel="Send Now"
        buttonOnClick={() => this.sendTransfer()}
        label="Send Transfer"
        ref={(e) => (this.modal = e)}
      >
        <div className="font-medium">
          Send transfer for <span className="font-bold">${toDollars(transfer.TOTAL)}</span> to{" "}
          {transfer.RECEIVER_LOCATION.NAME}
        </div>
      </Modal>
    );
  }
}

export default SendTransferModal;

import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class WarningBadge extends Component {
  render() {
    let {className} = this.props;

    return (
      <span
        {...this.props}
        className={classNames(
          className,
          "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800"
        )}
      >
        {this.props.children}
      </span>
    );
  }
}

export default WarningBadge;

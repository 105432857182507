import {request} from "./request";
import moment from "moment";
import {showLoadingConfirmAlert} from "./alert-helper";

export async function createCompany(data) {
  return await request("payroll/company", "POST", data);
}

export async function getPayrollCompany() {
  return await request("payroll/company", "GET", null);
}

export async function getPayrollCompanyOnboardingLink(data) {
  return await request("payroll/company/onboard", "POST", data);
}

export async function generatePayroll() {
  return await request("payroll/generate", "GET", null);
}

export async function setAdditionalPay(id, data) {
  return await request("payroll/additional-pay/" + id, "POST", data);
}

export async function getPayrollEmployeeOnboardingLink(id) {
  return await request(`payroll/hub/employees/${id}/check`, "GET", null);
}

export async function getCheckContractorOnboardingLink(onboardCode) {
  return await request("payroll/contractors/onboard/" + onboardCode, "GET");
}

export async function getCheckTaxChecklistLink() {
  return await request(`payroll/tax-checklist`, "GET");
}

export async function getPayrollEmployee(id) {
  return await request("payroll/employee/" + id, "GET", null);
}

export async function createPaySchedule(data) {
  return await request("payroll/pay-schedules", "POST", data);
}

export async function getPayrollEmployees() {
  return await request("payroll/employees", "GET", null);
}

export async function remindPayrollEmployeeToOnboard(id) {
  return await request("payroll/remind-employee/" + id, "GET", null);
}

export async function onboardCodeToEmployee(code) {
  return await request("payroll/onboard/employee/" + code, "GET", null);
}

export async function setCompanyDefinedAttribute(data) {
  return await request("payroll/employees/company-defined-attributes", "POST", data);
}

export async function getPaySchedule() {
  return await request("payroll/pay-schedule", "GET", null);
}

export async function getPaySchedules() {
  return await request("payroll/pay-schedules", "GET", null);
}

export async function deletePaySchedule(payScheduleID) {
  return await request("payroll/pay-schedules/" + payScheduleID, "DELETE", null);
}

export async function getCurrentPayrun() {
  return await request("payroll/pay-schedules/current", "GET", null);
}

export async function getPayrollDraft(query) {
  return await request("payroll/run-payroll", "GET", query);
}

export async function fetchPayrollPreview(query) {
  return await request("payroll/run-payroll/preview/" + query, "GET", query);
}

export async function submitPayroll(body) {
  return await request("payroll/run-payroll/submit", "POST", body);
}

export async function reopenPayroll(query) {
  return await request("payroll/run-payroll/repoen", "GET", query);
}

export async function createOffCyclePayroll(query) {
  return await request("payroll/off-cycle/create", "POST", query);
}

export async function getOffCycleDraft() {
  return await request("payroll/off-cycle/run", "GET", null);
}

export async function clearOffCycleDraft() {
  return await request("payroll/run-off-cycle-payroll", "DELETE", null);
}

export async function approveTimesheet(timesheetID) {
  return await request("team/timesheet/" + timesheetID + "/approve", "POST", null);
}

export async function getPayrollHistory() {
  return await request("payroll/history", "GET", null);
}

export async function getPayrollByID(ID) {
  return await request("payroll/payrolls/" + ID, "GET", null);
}

export async function editReimbursement(payrun, employee, reimbursement) {
  let payload = {
    PAYRUN: payrun,
    EMPLOYEE: employee,
    REIMBURSEMENT: reimbursement,
  };
  return await request("payroll/run-payroll/set-reimbursement", "POST", payload);
}

export async function editBonus(payrun, employee, bonus) {
  let payload = {
    PAYRUN: payrun,
    EMPLOYEE: employee,
    BONUS: bonus,
  };
  return await request("payroll/run-payroll/set-bonus", "POST", payload);
}

export async function getExtras(payrun) {
  return await request("payroll/run-payroll/extras/" + payrun, "GET", payrun);
}

export async function getPaystub(employeeID, payrollID) {
  return await request("payroll/employee/" + employeeID + "/paystubs/" + payrollID, "GET", null);
}

export async function saveOffCyclePayroll(payload) {
  return await request("payroll/off-cycle/save", "POST", payload);
}

export async function clearItems(currentPayrun, employeeID) {
  return await request("payroll/items/" + employeeID + "/clear", "PATCH", currentPayrun);
}

export async function includeEmployeeInPayroll(employeeID) {
  return await request("payroll/employee/" + employeeID, "POST", null);
}

export async function getEmployeeOnboardLink(employeeID) {
  return await request("payroll/employee-link/" + employeeID, "GET", null);
}

export async function getContractorOnboardLink(contractorOnboardCode) {
  return await request("payroll/contractor-link/" + contractorOnboardCode, "GET", null);
}

export async function setIncludeEmployeeRegular(employeeID, newValue) {
  return await request("payroll/employee/" + employeeID + "/include", "POST", {
    value: newValue,
  });
}

export async function getCompanyDefinedAttributes(checkEmployeeID) {
  return await request("payroll/check-employee/" + checkEmployeeID, "GET", null);
}

export async function setEmployeeSettings(employeeID, values) {
  return await request(`payroll/employees/settings/${employeeID}`, "POST", values);
}

export function formatDateForDayOfWeekDisplay(epoch) {
  let endOfPeriodDaysAhead = moment(epoch).diff(moment().startOf("day"), "days");

  if (endOfPeriodDaysAhead === 1) {
    return "Tomorrow";
  }

  if (endOfPeriodDaysAhead === 0) {
    return "Today";
  }

  return `${moment(epoch).format("dddd")}`;
}

export async function getBreakOptions() {
  const serverBreakOptions = await request("team/break-option/company", "GET");

  return serverBreakOptions;
}

export function hasNoPayTimeCard(timesheets) {
  if (!timesheets) {
    return false;
  }

  return timesheets.some((card) => card.AMOUNT_PAYED === 0);
}

export function showPayrollError(title, message, canContinue) {
  return showLoadingConfirmAlert(title, message)
    .then((close) => {
      close();

      return !!canContinue;
    })
    .catch(() => {});
}

export function fetchPayrollTabs(dbaLocations) {
  if (!dbaLocations) {
    return [];
  }

  const tabs = dbaLocations
    .filter((loc) => loc.TYPE === "STORE")
    .map((loc) => ({
      label: loc.NAME,
      value: loc.ID,
    }));

  tabs.unshift({
    label: "All Locations",
    value: "all",
  });

  return tabs;
}

export function fetchEmployeeRequestedHours(row) {
  if (!row) {
    return 0;
  }

  const {REQUESTED_PTO: requestedPTO} = row;

  if (!requestedPTO) {
    return 0;
  }

  return Object.keys(requestedPTO)?.reduce((accum, item) => {
    return accum + requestedPTO[item];
  }, 0);
}

export function fetchPayrollTimeOffAmount(breakdown) {
  if (!breakdown || breakdown.length === 0) {
    return 0;
  }

  return breakdown.reduce((accum, item) => {
    const {REQUESTED_PTO: requestedPTO, ADDITIONAL_PTO: additionalPTO = {}, DEFAULT_RATE: defaultRate} = item;

    let requestedTotal = 0;

    if (requestedPTO && defaultRate) {
      const requestedHours = fetchEmployeeRequestedHours(item);
      requestedTotal += requestedHours * defaultRate;
    }

    let additionalTotal = Object.keys(additionalPTO).reduce((accum, item) => {
      return accum + (additionalPTO[item]?.AMOUNT ?? 0);
    }, 0);

    return accum + requestedTotal + additionalTotal;
  }, 0);
}

export function hasStartDateError(payroll) {
  const {company, currentPayrun} = payroll;
  const {start_date} = company;
  const {PAYDAY} = currentPayrun;

  return start_date > PAYDAY;
}

import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {getStore} from "../../../../redux";
import {updateIngredient} from "../../../../redux/supply";
import {StockRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import VendorItemsDropdown from "../../../../dropdowns/operations/vendor-items-dropdown";

class QuickStockIngredientModal extends Component {
  state = {
    ingredient: null,
    initialValues: null,
    vendorItem: null,
  };

  open(ingredient = null) {
    const initialValues = {
      quantity: 0,
      cost: "0",
      unit: null,
      item: null,
    };
    let vendorItem = null;
    if (ingredient != null) {
      vendorItem = ingredient.VENDOR_ITEMS.find((vi) => vi.IS_DEFAULT) || {};
      const {
        ID = null,
        PRICE_PER_CASE = ingredient.COST_PER_UNIT,
        CASE_SIZE = 0,
        UNIT_ID = ingredient.UNIT_ID,
      } = vendorItem;
      initialValues.quantity = CASE_SIZE;
      initialValues.cost = toDollars(PRICE_PER_CASE);
      initialValues.unit = UNIT_ID;
      initialValues.item = ID;
    }
    this.setState({ingredient, initialValues, vendorItem}, () => this.modal.open());
  }

  fetchInitialValues() {
    return this.state.initialValues;
  }

  fetchValidationSchema() {
    return Yup.object().shape({
      quantity: Yup.number().nullable().required("Please enter the quantity you wish to stock."),
      cost: Yup.string().nullable(),
      unit: Yup.number().nullable().required("Please select a unit."),
    });
  }

  onQuickStockSuccess(payload) {
    const {ingredient} = this.state;

    showSuccessNotification(`Successfully stocked ${ingredient.NAME}.`);

    return getStore().dispatch(updateIngredient(ingredient.ID));
  }

  onQuickStockFailure() {
    const {ingredient} = this.state;

    showErrorNotification(
      "Unknown error occurred.",
      "Error stocking " + ingredient.NAME + ". Please try again."
    );

    this.modal.close();
  }

  fetchQuickStockPayload(values) {
    const {cost, quantity, unit} = values;

    return {
      COST: decimalToDollars(cost),
      QUANTITY: quantity,
      UNIT_ID: unit,
    };
  }

  async requestQuickStock(payload) {
    const {ingredient} = this.state;

    try {
      await StockRequests.quickStock(ingredient.ID, payload);
      this.onQuickStockSuccess(payload);
    } catch (e) {
      this.onQuickStockFailure();
    } finally {
      this.modal.close();
    }
  }

  submitQuickStocking(values) {
    const payload = this.fetchQuickStockPayload(values);

    return this.requestQuickStock(payload);
  }

  renderQuantityInput(formikOptions) {
    return (
      <FormInput
        name="quantity"
        label="Quantity To Add"
        options={formikOptions}
        tooltip={`The number of the selected unit being stocked.`}
      />
    );
  }

  renderCostInput(formikOptions) {
    const {ingredient} = this.state;

    return (
      <FormInput
        name="cost"
        label="Per Unit Cost"
        hint="Optional"
        tooltip={`The cost for 1 selected unit of ${ingredient.NAME}. For example, if 10 units of ${ingredient.NAME} cost $100, this value would be $10.`}
        options={formikOptions}
        isDollars={true}
      />
    );
  }

  renderUnitDropdown(formikOptions) {
    const {ingredient} = this.state;
    const {setFieldValue} = formikOptions;

    return (
      <UnitDropdown
        name="unit"
        label="Unit"
        ingredient={ingredient}
        tooltip="The unit you wish to use"
        options={formikOptions}
        unit={ingredient.UNIT_ID}
        onChangeSoft={({unit}) => {
          const conversion = unit.CONVERSIONS.find((conversion) => conversion.TO_UNIT === ingredient.UNIT_ID);
          const cost =
            unit.ID === ingredient.UNIT_ID
              ? ingredient.COST_PER_UNIT
              : ingredient.COST_PER_UNIT * conversion?.RATE;
          setFieldValue("cost", toDollars(cost));
        }}
      />
    );
  }

  renderVendorDropdown(formikOptions) {
    const {ingredient, vendorItem} = this.state;
    const {VENDOR_ITEMS: vendorItems} = ingredient;
    const {setFieldValue} = formikOptions;

    return (
      <VendorItemsDropdown
        name="item"
        label="Vendor Item Default"
        hint="Optional"
        tooltip="The vendor item to fill in default values. If this ingredient has a default, this is set to that."
        vendorItems={vendorItems}
        shouldShowSections={true}
        defaultItem={vendorItem}
        options={formikOptions}
        onChangeSoft={({item}) => {
          if (item.ID === -1) return;
          const {PRICE_PER_CASE = "0", CASE_SIZE = 0, UNIT_ID = null} = item;
          setFieldValue("quantity", CASE_SIZE);
          setFieldValue("cost", `${toDollars(PRICE_PER_CASE)}`);
          setFieldValue("unit", UNIT_ID);
        }}
      />
    );
  }

  renderFormBody(formikOptions) {
    return (
      <form>
        {this.renderQuantityInput(formikOptions)}
        {this.renderUnitDropdown(formikOptions)}
        {this.renderCostInput(formikOptions)}
        {this.renderVendorDropdown(formikOptions)}
      </form>
    );
  }

  renderForm() {
    return (
      <Formik
        innerRef={(e) => (this.formikRef = e)}
        initialValues={this.fetchInitialValues()}
        validationSchema={this.fetchValidationSchema()}
        onSubmit={(values) => this.submitQuickStocking(values)}
      >
        {(formikOptions) => {
          return this.renderFormBody(formikOptions);
        }}
      </Formik>
    );
  }

  render() {
    const {ingredient} = this.state;

    return (
      <Modal
        label={`Stock ${ingredient?.NAME}`}
        buttonLabel="Stock"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

export default QuickStockIngredientModal;

import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
const parse = require("html-react-parser");

class InvoiceEmailPreview extends Component {
  state = {data: null};

  componentDidMount() {
    const {invoice, lines} = this.props;

    const amount = lines.reduce((accum, item) => accum + item.AMOUNT, 0);
    const tax = lines.reduce((accum, item) => accum + item.AMOUNT_TAX, 0);
    const total = lines.reduce((accum, item) => accum + item.TOTAL, 0);

    const invoicePayload = {
      ...invoice,

      STATUS: "DRAFT",

      TICKET: {
        NOTES: invoice.NOTES,
        DATE_CREATED: Date.now(),

        PAYMENT_INTENT: {
          PAYMENTS: [],
          DATE_PAYED: null,

          AMOUNT: amount,
          AMOUNT_TAX: tax,

          TOTAL: total,
        },

        ITEMS: lines,
      },
    };

    request("invoices/email/preview", "POST", {INVOICE: invoicePayload}, {Accept: "text/html"}).then(
      (data) => {
        this.setState({data});
      }
    );
  }

  render() {
    const {data} = this.state;

    if (data) {
      return <div>{parse(data)}</div>;
    }

    return <Loading />;
  }
}

export default InvoiceEmailPreview;

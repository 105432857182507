import React, {Component} from "react";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../../utils/navigation";
import SetupProductIngredientsModal from "../../../../../modals/operations/supply-chain/recipes/setup-product-ingredients/setup-product-ingredients-modal";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../../../redux";

class ProductRecipesTab extends Component {
  handleRecipeSync = async () => {
    const {syncState} = this.props;
    await syncState();
  };

  render() {
    const {product} = this.props;
    const {units} = this.props.supply;
    const unitDict = parseIdDict(units);

    return (
      <>
        <SetupProductIngredientsModal
          ref={(e) => (this.ingredientModal = e)}
          handleRecipeSync={this.handleRecipeSync}
          unitDict={unitDict}
        />

        <Card
          label="Recipe"
          description="Configure the recipe for this product"
          buttons={[
            {
              label: "Configure Recipe",
              onClick: () => {
                this.ingredientModal.open(product);
              },
            },
          ]}
        >
          <Table
            data={product.RECIPES ?? []}
            columns={[
              {
                label: "Variant",
                value: "VARIANT_OPTION",
                format: (v) => v?.NAME ?? "All Variants",
              },
              {
                label: "Option",
                value: "OPTION",
                format: (v) => v?.NAME ?? "All Options",
              },
              {
                label: "Ingredient",
                value: "NAME",
                format: (val, row) => {
                  return (
                    <div
                      className="text-indigo-500 hover:text-indigo-800 hover:cursor-pointer transition-colors ease-in-out duration-300"
                      onClick={() => this.props.router.navigate("/recipes/ingredients/" + row.UNIQUE_ID)}
                    >
                      {val}
                    </div>
                  );
                },
              },
              {
                label: "Quantity",
                value: "RECIPE_COST",
              },
            ]}
          />
        </Card>
      </>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(ProductRecipesTab));

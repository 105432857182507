import React, {Component} from "react";
import StepNavCard from "../../features/step-nav-card";
import {withRouter} from "../../utils/navigation";
import PayrollCompanyFormComponent from "../../features/payroll/onboard/group/payroll-company-form-component";
import WelcomeComponent from "../../features/accounting/onboard/welcome-component";
import SettingsComponent from "../../features/accounting/onboard/settings-component";
import GenerateAccountingComponent from "../../features/accounting/onboard/generate-accounting-component";
import {setupReduxConnection} from "../../redux";
import QuickbooksImportComponent from "../../features/accounting/onboard/quickbooks-onboarding-component";
import QuickbooksLinkComponent from "../../features/accounting/onboard/quickbooks-link-component";
class AccountingGroupOnboardingPage extends Component {
  render() {
    const {accountingGroup} = this.props.shop;

    if (accountingGroup?.PREV_PROVIDER === "QUICKBOOKS") {
    }

    const steps = [
      {
        label: "Welcome",
        component: WelcomeComponent,
        onboardingStatus: "NO_GROUP",
        hideBack: true,
      },
      {
        label: "Onboarding Settings",
        component: SettingsComponent,
        onboardingStatus: "GROUP_CREATED",
        hideBack: true,
      },
      {
        label: "Begin Accounting",
        component: GenerateAccountingComponent,
        onboardingStatus: "GENERATE",
        hideBack: true,
      },
    ];
    if (accountingGroup?.PREV_PROVIDER === "QUICKBOOKS") {
      steps.splice(2, 0, {
        label: "Link Quickbooks",
        component: QuickbooksLinkComponent,
        onboardingStatus: "LINK_QUICKBOOKS",
        hideBack: true,
      });

      steps.splice(3, 0, {
        label: "Import Quickbooks",
        component: QuickbooksImportComponent,
        onboardingStatus: "IMPORT_QUICKBOOKS",
        hideBack: true,
      });
    }

    const defaultStep = steps.findIndex((_step) => _step.onboardingStatus === accountingGroup?.STATUS);

    return (
      <StepNavCard
        label={`Accounting Onboarding`}
        router={this.props.router}
        steps={steps}
        defaultStep={defaultStep}
      />
    );
  }
}
export default setupReduxConnection(["shop"])(withRouter(AccountingGroupOnboardingPage));

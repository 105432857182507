import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Link} from "react-router-dom";
import {withRouter} from "../utils/navigation";
import {setupReduxConnection} from "../redux";
import GrayBadge from "./badges/gray-badge";

class SideNav extends Component {
  state = {open: null, routes: []};

  componentDidMount() {
    const {navigation, permissions: adminPermissions = []} = this.props;
    const {permissions: userPermissions} = this.props.user;

    const finalNavigation = navigation
      .map((item) => {
        const {permissions: parentPermissions = [], children} = item;

        if (!children || userPermissions.includes("ADMIN_FULL")) {
          return item;
        }

        const newChildren = children.filter((item) => {
          const {permissions = [], live} = item;

          if (live === false && process.env.REACT_APP_ENV !== "development") {
            return false;
          }

          if (permissions.length === 0) {
            return true;
          }

          const possiblePermissions = [...permissions, ...parentPermissions, ...adminPermissions];

          if (possiblePermissions.some((x) => userPermissions.includes(x))) {
            return true;
          }

          return false;
        });

        return {...item, children: newChildren};
      })
      .filter((item) => {
        const {permissions = [], live} = item;

        if (live === false && process.env.REACT_APP_ENV !== "development") {
          return false;
        }

        if (userPermissions.includes("ADMIN_FULL")) {
          return true;
        }

        if (permissions.length === 0) {
          return true;
        }

        if ([...permissions, ...adminPermissions].some((x) => userPermissions.includes(x))) {
          return true;
        }

        return false;
      });

    this.setState({routes: finalNavigation});
  }

  render() {
    const {pathname: url} = this.props.router.location;
    const {routes, open} = this.state;
    const {title} = this.props;

    return (
      <div className="mt-2">
        <h3 className="font-bold leading-7 text-gray-900">{title}</h3>

        <div className="flex-grow flex flex-col mt-2">
          <nav className="flex-1 space-y-1 border-l-2" aria-label="Sidebar">
            {routes.map((item) => {
              if (item.children) {
                const isOpen = open === item.id;
                const isActive =
                  url.startsWith(item.href) ||
                  (item.paths &&
                    item.paths.some((path) => {
                      return url.startsWith(path);
                    }));
                const isChildActive = item.children.some((item) => {
                  const isPathActive =
                    item.paths &&
                    item.paths.some((path) => {
                      return url.startsWith(path);
                    });

                  return isPathActive || url.startsWith(item.href);
                });

                const isShowing = isActive || isChildActive || isOpen;

                return (
                  <div className="space-y-1" key={item.name}>
                    <>
                      <div
                        onClick={() => {
                          if (!item.href) {
                            return this.setState({open: item.id});
                          }

                          this.setState(
                            {
                              open: null,
                            },
                            () => this.props.router.navigate(item.href)
                          );
                        }}
                        className={classNames(
                          isActive && !isChildActive
                            ? "bg-gray-100 border-indigo-500 font-bold text-indigo-600 border-l-2 -ml-0.5"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "cursor-pointer group w-full flex items-center pl-4 pr-2 py-1 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        )}
                      >
                        <div className="flex flex-row items-center">
                          <div>{item.name}</div>

                          {item.badge && (
                            <GrayBadge darkGrey className="ml-3">
                              {item.badge}
                            </GrayBadge>
                          )}
                        </div>
                      </div>

                      <div className="space-y-1">
                        {isShowing &&
                          item.children.map((subItem) => {
                            const isPathActive =
                              subItem.paths &&
                              subItem.paths.some((path) => {
                                return url.startsWith(path);
                              });

                            const isChild = isPathActive || url.startsWith(subItem.href);

                            return (
                              <div
                                onClick={() => {
                                  this.setState(
                                    {
                                      open: null,
                                    },
                                    () => this.props.router.navigate(subItem.href)
                                  );
                                }}
                                key={subItem.name}
                                className={classNames(
                                  isChild
                                    ? "bg-gray-100 border-indigo-500 font-bold text-indigo-600 border-l-2 -ml-0.5"
                                    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                  "cursor-pointer group w-full flex items-center pl-8 pr-2 py-1 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                )}
                              >
                                <div className="flex flex-row items-center">
                                  <div>{subItem.name}</div>

                                  {subItem.badge && (
                                    <GrayBadge darkGrey className="ml-3">
                                      {subItem.badge}
                                    </GrayBadge>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  </div>
                );
              }

              const isActive =
                (item.paths &&
                  item.paths.some((path) => {
                    return url.startsWith(path);
                  })) ||
                url.startsWith(item.href);

              return (
                <div
                  onClick={() =>
                    this.setState({
                      url: item.href,
                    })
                  }
                  key={item.name}
                >
                  <Link
                    to={item.href}
                    href={item.href}
                    className={classNames(
                      isActive
                        ? "bg-gray-100 border-indigo-500 font-bold text-indigo-600 border-l-2 -ml-0.5"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "cursor-pointer group w-full flex items-center pl-4 pr-2 py-1 text-sm font-medium"
                    )}
                  >
                    <div className="flex flex-row items-center">
                      <div>{item.name}</div>

                      {item.badge && (
                        <GrayBadge darkGrey className="ml-3">
                          {item.badge}
                        </GrayBadge>
                      )}
                    </div>
                  </Link>
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(SideNav));

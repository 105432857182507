import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Tab,
  Card,
  Table,
  Loading,
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import InventoryModal from "../../../modals/operations/inventory/inventory-modal";
import moment from "moment";
import {updateStateDelegator} from "../../../utils/util";
import InventoryRuleModal from "../../../modals/operations/inventory/inventory-rule-modal";
import InventoryButtonModal from "../../../modals/operations/inventory/inventory-button-modal";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";

const LOG_TYPES = {
  0: "Rule",
  1: "Bulk",
  2: "Kitchen Update",
  3: "Product Expiry",
  4: "Product Sold",
};

export const DAY_NAMES = {
  0: "Mon",
  1: "Tue",
  2: "Wed",
  3: "Thu",
  4: "Fri",
  5: "Sat",
  6: "Sun",
};

class InventoryPage extends Component {
  state = {product: null, inventory: null, buttons: null, rules: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const {inventory, product} = await request("inventory/" + id, "GET");

    request("inventory/" + inventory.ID + "/buttons", "GET").then((buttons) => {
      this.setState({buttons});
    });

    request("inventory/dashboard/rule/" + product.ID, "GET").then((rules) => {
      const finalRules = rules.reduce((dict, item) => {
        if (dict[item.ID]) {
          dict[item.ID].push(item);
        } else {
          dict[item.ID] = [item];
        }

        return dict;
      }, {});

      this.setState({
        rules: Object.values(finalRules).map((item) => ({
          ID: item[0].ID,
          RULES: item,
        })),
      });
    });

    console.log(inventory, product);

    this.setState({inventory, product});
  }

  stopTracking() {
    const {product} = this.state;

    showLoadingConfirmAlert(
      "Stop tracking " + product.NAME + "?",
      "Are you sure you want to stop tracking this product?"
    ).then(async (close) => {
      await request("inventory/dashboard/toggle", "POST", {
        PRODUCTS: [{ID: product.ID, INVENTORY: null}],
      });

      this.props.router.navigate("/inventory");

      close();

      showSuccessNotification("Product tracking stopped", "We are no longer tracking " + product.NAME);
    });
  }

  render() {
    const {product, inventory, rules, buttons} = this.state;

    if (inventory === null) {
      return <Loading />;
    }

    return (
      <div>
        <InventoryModal
          updateState={(state) => this.setState(state)}
          ref={(e) => (this.inventoryModal = e)}
        />

        <InventoryRuleModal
          product={product}
          ref={(e) => (this.inventoryRuleModal = e)}
          addState={(rule) => this.setState({rules: [...rules, rule]})}
          updateState={(id, rule) => {
            this.setState({
              rules: updateStateDelegator(rules, id, rule),
            });
          }}
        />

        <InventoryButtonModal
          product={product}
          ref={(e) => (this.inventoryButtonModal = e)}
          addState={(button) => this.setState({buttons: [...buttons, button]})}
          updateState={(id, button) => {
            this.setState({
              buttons: updateStateDelegator(buttons, id, button),
            });
          }}
        />

        <PageHeadings
          label={product.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [{items: [{label: "Stop Tracking", onClick: this.stopTracking.bind(this)}]}],
            },
          ]}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Inventory Page", url: "/inventory"},
          ]}
        />

        <TwoColumnList
          label="Product Inventory"
          description="Modify this product's inventory information"
          data={[
            {label: "Product Name", value: product.NAME},
            {label: "Category Name", value: product.CATEGORY_NAME},
            {label: "Inventory", value: inventory.QUANTITY},
            {
              label: "Expiration",
              tooltip: "How long does this product last",
              value: inventory.LIFETIME ? inventory.LIFETIME : "None",
            },
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.inventoryModal.open(product, inventory),
            },
          ]}
        />

        <Tab
          data={[
            {id: "rules", label: "Inventory Rules"},
            {id: "buttons", label: "Fast Buttons"},
            {id: "usage", label: "Usage Log"},
          ]}
        >
          {(id) => {
            if (id === "usage") {
              return (
                <Card key={id} label="Inventory Usage Log">
                  <Table
                    pagination
                    route={"inventory/product/" + product.ID + "/log"}
                    columns={[
                      {
                        value: "TYPE",
                        label: "Type",
                        format: (val) => LOG_TYPES[val],
                      },
                      {
                        value: "DATE_CREATED",
                        label: "Date Created",
                        format: (val) => moment(val).format("M/D/YY h:mma"),
                      },
                      {
                        value: "DATE_EXPIRED",
                        label: "Date Expires",
                        format: (val) => (val ? moment(val).format("M/D/YY h:mma") : "No expiration"),
                      },
                      {
                        width: 1,
                        value: "STOCK",
                        label: "Stock",
                        format: (val, row) => val + " (" + row.QUANTITY + ")",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "buttons") {
              return (
                <Card
                  key={id}
                  label="Inventory Buttons"
                  button={{
                    label: "Add Button",
                    onClick: () => this.inventoryButtonModal.open(),
                  }}
                >
                  <Table
                    pagination
                    data={buttons}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (item) => this.inventoryButtonModal.open(item),
                      },
                    ]}
                    columns={[
                      {
                        value: "QUANTITY",
                        label: "Quantity",
                      },
                      {
                        value: "TYPE",
                        label: "Button Type",
                        format: () => "Add to quantity",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "rules") {
              return (
                <Card
                  key={id}
                  label="Inventory Rules"
                  button={{
                    label: "Add Rule",
                    onClick: () => this.inventoryRuleModal.open(),
                  }}
                >
                  <Table
                    pagination
                    data={rules}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: ({ID, RULES}) => this.inventoryRuleModal.open(RULES, ID),
                      },
                    ]}
                    columns={[
                      {
                        value: "QUANTITY",
                        label: "Quantity",
                        format: (_, {RULES}) => RULES[0].QUANTITY,
                      },
                      {
                        value: "OFFSET",
                        label: "Time",
                        format: (_, {RULES}) =>
                          moment(moment().startOf("day").valueOf())
                            .add(RULES[0].OFFSET - new Date().getTimezoneMilliOffset(), "milliseconds")
                            .format("h:mm A"),
                      },
                      {
                        value: "DAYS",
                        label: "Days",
                        format: (_, {RULES}) => {
                          return RULES.map((item) => DAY_NAMES[item.DAY]).join(", ");
                        },
                      },
                      {
                        value: "TYPE",
                        label: "Reset Quantity",
                        format: (_, {RULES}) => (parseInt(RULES[0].TYPE) === 0 ? "on" : "off"),
                      },
                    ]}
                  />
                </Card>
              );
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(InventoryPage);

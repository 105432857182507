import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";
import TippingModal from "../../../modals/shop/shop-settings/tipping-modal";
import SmallTicketTippingModal from "../../../modals/shop/shop-settings/small-ticket-tipping-modal";
import TipPayoutModal from "../../../modals/shop/shop-settings/tip-payout-modal";
import AutoGratuitySettingsModal from "../../../modals/shop/shop-settings/auto-gratuity-settings-modal";

class TippingSettingsTab extends Component {
  render() {
    let {
      TIP_ENABLED,
      NO_TIP_DISPLAY_ENABLED,
      TIP_OPTIONS,
      SMALL_TICKET_TIPS,
      SMALL_TICKET_TIP_OPTIONS,
      MAXIMUM_SMALL_TICKET_AMOUNT,
      DEFAULT_TIP_VALUE,
      DEFAULT_SMALL_TIP_VALUE,
      TIPOUT_AFTER_SHIFT,
      ALLOW_TIP_ON_AUTO_GRATUITY,
    } = this.props.shop.settings;

    TIP_OPTIONS = JSON.parse(TIP_OPTIONS);
    SMALL_TICKET_TIP_OPTIONS = JSON.parse(SMALL_TICKET_TIP_OPTIONS);

    return (
      <div>
        <TippingModal ref={(e) => (this.tippingModal = e)} />

        <TipPayoutModal ref={(e) => (this.tipPayoutModal = e)} />

        <SmallTicketTippingModal ref={(e) => (this.smallTicketTippingModal = e)} />

        <AutoGratuitySettingsModal ref={(e) => (this.autogratuityModal = e)} />

        {/*<TwoColumnList*/}
        {/*  buttons={[*/}
        {/*    {*/}
        {/*      label: "Edit",*/}
        {/*      onClick: () => this.tipPayoutModal.open(),*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  label={"Tip Payouts"}*/}
        {/*  description={"Configure when you're paying out tips"}*/}
        {/*  data={[*/}
        {/*    {*/}
        {/*      label: "Payout tips at end of shift",*/}
        {/*      type: "bool",*/}
        {/*      value: TIPOUT_AFTER_SHIFT*/}
        {/*    }*/}
        {/*  ]}*/}
        {/*/>*/}

        <TwoColumnList
          label="Regular Tipping"
          description="Configure regular tipping settings for the Point of Sale"
          tooltip={{
            label: "Regular Tipping",
            data: "Choose default tip amounts to be presented to the customer during checkout. Amounts can either be percentages (add the % character to the end of the amount) or dollar amounts. In addition to these amounts, customers can select a custom option.",
          }}
          data={[
            {
              label: "Tipping Enabled",
              value: TIP_ENABLED,
              type: "bool",
              tooltip: "If disabled, customers will not be able to add a tip to an order.",
            },
            {
              label: "Display No Tip Option",
              value: NO_TIP_DISPLAY_ENABLED,
              type: "bool",
              tooltip: "If enabled, no tip option for customers at checkout will be displayed.",
            },
            {
              label: "Tip Option 1",
              value: TIP_OPTIONS[0],
              tooltip: "The first option presented on the tipping screen for regular priced checkouts.",
            },
            {
              label: "Tip Option 2",
              value: TIP_OPTIONS[1],
              tooltip: "The second option presented on the tipping screen for regular priced checkouts.",
            },
            {
              label: "Tip Option 3",
              value: TIP_OPTIONS[2],
              tooltip: "The third option presented on the tipping screen for regular priced checkouts.",
            },
            {
              label: "Default Tip Value",
              value: DEFAULT_TIP_VALUE,
              tooltip: "The default tip value for online orders.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.tippingModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Small Ticket Tipping"
          description="Configure small order tipping settings for the Point of Sale"
          tooltip={{
            label: "Small Ticket Tipping",
            data: 'Choose tip amounts presented to the customer when a ticket amount is less than the "Maximum Small Ticket Amount." Amounts can either be percentages (add the % character to the end of the amount) or dollar amounts. In addition to these amounts, customers can select a custom option.',
          }}
          data={[
            {
              label: "Small Ticket Tipping Enabled",
              value: SMALL_TICKET_TIPS,
              type: "bool",
              tooltip:
                "If enabled, tickets with smaller total dollar amounts will be presented with different tipping options than than regular tickets.",
            },
            {
              label: "Maximum Small Ticket Size",
              value: toDollars(MAXIMUM_SMALL_TICKET_AMOUNT),
              tooltip:
                'If "Small Ticket Tipping" is enabled, then tickets with totals below this amount will be presented with different tipping options.',
            },
            {
              label: "Small Ticket Tip Option 1",
              value: SMALL_TICKET_TIP_OPTIONS[0],
              tooltip: "The first option presented on the tipping screen for small tickets.",
            },
            {
              label: "Small Ticket Tip Option 2",
              value: SMALL_TICKET_TIP_OPTIONS[1],
              tooltip: "The second option presented on the tipping screen for small tickets.",
            },
            {
              label: "Small Ticket Tip Option 3",
              value: SMALL_TICKET_TIP_OPTIONS[2],
              tooltip: "The third option presented on the tipping screen for small tickets.",
            },
            {
              label: "Small Ticket Default Tip Value",
              value: DEFAULT_SMALL_TIP_VALUE,
              tooltip: "The default tip value on online orders for small tickets.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.smallTicketTippingModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Auto-Gratuity"
          description="Configure auto-gratuity settings"
          data={[
            {
              label: "Allow Tipping on Auto-Gratuity",
              value: ALLOW_TIP_ON_AUTO_GRATUITY,
              type: "bool",
              tooltip:
                "If enabled, you will be able to select a tip from the gratuity step regardless of whether auto-gratuity has been applied.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.autogratuityModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TippingSettingsTab);

import React, {Component} from "react";
import {CELL_TYPES, INGREDIENT_SALES_COLUMNS} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";

class IngredientSalesReportPage extends Component {
  convertDataToRows = (reportData) => {
    let mappedModifiers = this.mapModifierSalesData(reportData);

    return mappedModifiers.map((entry) => ({
      label: entry.name,
      numbers: entry,
      style: entry.modifier ? CELL_TYPES.BOLD : CELL_TYPES.SECONDARY,
    }));
  };

  mapModifierSalesData = (data) => {
    let finalData = [];

    for (let ingredients of data) {
      let {NAME, SKU, COST, LOGS} = ingredients;

      const soldAmount = LOGS.filter((item) => {
        return item.STOCK_DELTA < 0;
      }).reduce((accum, item) => {
        return accum + Math.abs(item.STOCK_DELTA);
      }, 0);

      const stockAmount = LOGS.filter((item) => {
        return item.STOCK_DELTA > 0;
      }).reduce((accum, item) => {
        return accum + Math.abs(item.STOCK_DELTA);
      }, 0);

      finalData.push({
        sku: SKU || "-",
        name: NAME,
        cost: COST,
        soldAmount: soldAmount || 0,
        stockAmount: stockAmount,
        totalCost: soldAmount * COST || 0,
      });
    }

    return finalData;
  };

  render() {
    const {REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} = this.props.shop.settings;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Ingredient Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="ingredient-sales"
          endpoint="report/ingredientsales"
          constantColumns={INGREDIENT_SALES_COLUMNS}
          firstColLabel="Ingredient Name"
          convertDataToRows={this.convertDataToRows}
          searchText={"Search Ingredients by Name"}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(IngredientSalesReportPage);

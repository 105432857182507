import {Filter, Loading, PageHeadings, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {saveAs} from "file-saver";
import React, {Component} from "react";
import RemindInvoicesModal from "../../../modals/operations/invoices/remind-invoices-modal";
import InvoicesTable from "../../../tables/operations/invoices/invoices-table";
import {withRouter} from "../../../utils/navigation";
import {getURL, request} from "../../../utils/request";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import BatchPublishInvoiceModal from "../../../modals/operations/invoices/batch-publish-invoice-modal";
import BatchEditInvoiceModal from "../../../modals/operations/invoices/batch-edit-invoice-modal";
import {InvoiceRequests} from "../../../utils/request-helpers/invoices/invoice-requests";

class InvoicesPage extends Component {
  state = {invoices: null, selectedInvoices: {}};
  constructor(props) {
    super(props);
    this.remindModal = React.createRef(null);
    this.editModal = React.createRef(null);
    this.publishModal = React.createRef(null);
  }

  componentDidMount() {
    this.syncState();
  }

  syncState = async () => {
    let invoices = await request("invoices", "GET", null);
    invoices.reverse();
    this.setState({invoices, selectedInvoices: {}});
  };

  exportBatchPDF(invoices) {
    const downloadFiles = async () => {
      const downloadPromises = invoices.map(async (invoice) => {
        const url = getURL() + "invoices/" + invoice.UNIQUE_ID + "/pdf";
        const response = await fetch(url);
        const blob = await response.blob();
        saveAs(blob, invoice.INVOICE_NUMBER);
      });

      try {
        await Promise.all(downloadPromises);
      } catch (error) {
        console.error("Error downloading one or more invoices:", error);
      }
    };

    downloadFiles();
  }

  deleteBatchInvoices(invoices) {
    showLoadingConfirmAlert(
      "Delete Invoices",
      `Are you sure you want to delete ${invoices.length} invoices? Paid invoices will not be deleted.`
    )
      .then(async (close) => {
        const filteredInvoices = invoices.filter((invoice) => invoice.STATUS !== "PAID");

        const requests = filteredInvoices.map((invoice) => InvoiceRequests.deleteInvoice(invoice.ID));

        await Promise.all(requests);
        close();

        showSuccessNotification("Invoice Delete", `${invoices.length} invoices were successfully deleted.`);

        this.syncState();
      })
      .catch((error) => console.log(error));
  }

  //Display batch action buttons based on which invoices are selected
  //Actions are shown if at least one valid invoice is selected for the given action
  fetchBatchButtons(numInvoices) {
    const {selectedInvoices: invoices} = this.state;
    const invoiceList = Object.values(invoices);

    let batchButtons = [
      {
        label: `Download PDF${numInvoices > 1 ? "s" : ""}`,
        onClick: (invoices) => {
          this.exportBatchPDF(invoices);
        },
      },
    ];

    if (invoiceList.filter((invoice) => invoice.STATUS !== "DRAFT" && invoice.STATUS !== "PAID").length > 0) {
      batchButtons.push({
        label: `Resend Email Reminder${numInvoices > 1 ? "s" : ""}`,
        onClick: (invoices) => {
          this.remindModal.current.open(invoices);
        },
      });
    }

    if (invoiceList.filter((item) => item.STATUS === "DRAFT").length > 0) {
      batchButtons.push({
        label: `Publish Invoice${numInvoices > 1 ? "s" : ""}`,

        onClick: (invoices) => {
          this.publishModal.current.open(invoices);
        },
      });
    }
    if (invoiceList.filter((item) => item.STATUS !== "PAID").length > 0) {
      batchButtons.push({
        label: `Edit Invoice${numInvoices > 1 ? "s" : ""}`,
        onClick: (invoices) => {
          this.editModal.current.open(invoices);
        },
      });
    }

    if (invoiceList.filter((item) => item.STATUS !== "PAID").length > 0) {
      batchButtons.push({
        label: `Delete Invoice${numInvoices > 1 ? "s" : ""}`,
        onClick: (invoices) => {
          this.deleteBatchInvoices(invoices);
        },
      });
    }

    return batchButtons;
  }

  render() {
    const {invoices} = this.state;

    if (invoices === null) {
      return <Loading />;
    }

    const pending = invoices.filter((item) => item.STATUS === "OPEN");
    const due = invoices.filter(
      (item) => item.STATUS === "OPEN" && !!item.DATE_DUE && Date.now() > item.DATE_DUE
    );
    const draft = invoices.filter((item) => item.STATUS === "DRAFT");
    const paid = invoices.filter((item) => item.STATUS === "PAID");

    return (
      <div>
        <RemindInvoicesModal ref={this.remindModal} />
        <BatchPublishInvoiceModal ref={this.publishModal} syncState={this.syncState} />
        <BatchEditInvoiceModal ref={this.editModal} syncState={this.syncState} />
        <PageHeadings
          label="Invoices"
          description="Track and view your invoices"
          button={{
            label: "Create Invoice",
            onClick: () => {
              this.props.router.navigate("/invoice");
            },
          }}
        />

        <Tab
          data={[
            {label: "All Invoices (" + invoices.length + ")", id: "all"},
            {label: "Draft (" + draft.length + ")", id: "draft"},
            {label: "Outstanding (" + pending.length + ")", id: "outstanding"},
            {label: "Past Due (" + due.length + ")", id: "due"},
            {label: "Paid (" + paid.length + ")", id: "paid"},
          ]}
        >
          {(tab) => {
            let data = invoices;
            if (tab === "draft") {
              data = draft;
            } else if (tab === "due") {
              data = due;
            } else if (tab === "draft") {
              data = draft;
            } else if (tab === "paid") {
              data = paid;
            } else if (tab === "outstanding") {
              data = pending;
            }

            const numInvoices = Object.keys(this.state.selectedInvoices).length;

            return (
              <Filter searchable>
                {(_, search) => (
                  <InvoicesTable
                    data={data}
                    selectable
                    searchParams
                    hideColumnLabels={numInvoices > 0}
                    onSelect={(invoice) => {
                      const {selectedInvoices: invoices} = this.state;
                      if (invoices[invoice.ID]) {
                        delete invoices[invoice.ID];
                      } else {
                        invoices[invoice.ID] = invoice;
                      }
                      this.setState({selectedInvoices: invoices});
                    }}
                    search={search}
                    searchFields={[
                      "TICKET.NAME",
                      "INVOICE_NUMBER",
                      "CONTACT.FIRST_NAME",
                      "CONTACT.LAST_NAME",
                      "CONTACT.COMPANY",
                    ]}
                    hideColumns={(invoices) => invoices.length > 0}
                    selectButtons={this.fetchBatchButtons(numInvoices)}
                  />
                )}
              </Filter>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(InvoicesPage);

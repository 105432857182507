import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {request} from "../../../utils/request";
import {showErrorAlert} from "../../../utils/alert-helper";
import {ExclamationCircleIcon} from "@heroicons/react/outline";

class CardLostOrStolenModal extends Component {
  state = {card: null};

  open(card) {
    this.setState({card}, () => this.slide.open());
  }

  handleSubmit = async ({report}) => {
    const {UNIQUE_ID} = this.state.card;

    if (report === "lost") {
      await request(`cash-account/cards/${UNIQUE_ID}/report-lost`, "POST");
    } else if (report === "stolen") {
      await request(`cash-account/cards/${UNIQUE_ID}/report-stolen`, "POST");
    }

    await this.props.syncState();
    this.slide.close();
  };

  render() {
    const {card} = this.state;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`Report card ending in **${card?.LAST_4}`}
        buttonLabel="Report"
        buttonOnClick={() => this.formikRef.submitForm()}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            report: null,
          }}
          validationSchema={Yup.object({
            report: Yup.string("Select a report").required("Select a report"),
          })}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            const {report} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label={"What happened to your card?"}
                  name="report"
                  options={formikOptions}
                  data={[
                    {label: "My card was lost", value: "lost"},
                    {label: "My card was stolen", value: "stolen"},
                  ]}
                />

                {report && (
                  <div className="mt-5 border-l-4 border-yellow-400 bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">This card will be permanently closed.</p>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default CardLostOrStolenModal;

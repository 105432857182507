import React, {Component} from "react";
import ComboBox from "../../../components/combobox";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";

class UnitDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    const {unit: prevUnit, ingredient: prevIngredient, supply: prevSupply} = prevProps;
    const {unit, ingredient} = this.props;
    const {units: reduxUnits} = this.props.supply;
    const {units: prevReduxUnits} = prevSupply;

    if (prevUnit !== unit || prevIngredient !== ingredient || reduxUnits !== prevReduxUnits) {
      this.syncState();
    }
  }

  syncState() {
    const {unit, units, ingredient = null} = this.props;

    if (!units) {
      let {units: data} = this.props.supply;

      if (unit) {
        const reduxUnit = data.find((_unit) => _unit.ID === unit);
        if (!reduxUnit) {
          this.setState({data: []});
          return;
        }
        const validUnits = parseIdDict(reduxUnit?.CONVERSIONS ?? [], "TO_UNIT");

        data = [reduxUnit, ...data.filter((_unit) => validUnits[_unit.ID])];
      }

      data = data.filter((_unit) => _unit?.INGREDIENT_ID === ingredient?.ID || !_unit?.INGREDIENT_ID);

      this.setState({data});
    }
  }

  render() {
    const {data} = this.state;
    const {units} = this.props;

    const comboData = units ?? data;

    return (
      <ComboBox
        data={comboData.map((unit) => ({
          unit,
          name: unit.NAME,
          id: unit.ID,
          label: unit.NAME,
          disabled: unit.DISABLED,
        }))}
        {...this.props}
      />
    );
  }
}

export default setupReduxConnection(["supply"])(UnitDropdown);

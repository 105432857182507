import React, {Component} from "react";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReactDOM from "react-dom";
import {Manager, Popper, Reference} from "react-popper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class DropdownSelect extends Component {
  state = {
    showDropdown: false,
  };

  constructor(props) {
    super(props);

    this.id = "cd_" + randomString(24);

    this.state = {
      showDropdown: false,
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", (e) => this.handleClick(e));
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", (e) => this.handleClick(e));
  }

  handleClick = (e) => {
    let {showDropdown} = this.state;

    if (!showDropdown) {
      return;
    }

    const dropdownNode = ReactDOM.findDOMNode(this.dropdownRef);
    const buttonNode = ReactDOM.findDOMNode(this.buttonRef);

    if (dropdownNode && !dropdownNode.contains(e.target) && !buttonNode.contains(e.target)) {
      this.setState({showDropdown: false});
    }
  };

  selectOption(option) {
    if (option) {
      option.func();
    } else {
      console.log("ERROR");
    }
  }

  render() {
    const {currentBox, showDropdown} = this.state;
    const {options, icon, label, iconSize} = this.props;

    return (
      <div id={this.id}>
        <button
          ref={(e) => (this.buttonRef = e)}
          type="button"
          className="px-4 text-sm font-medium text-gray-700 hover:cursor-pointer"
          onClick={() => this.setState({showDropdown: !showDropdown})}
        >
          <FontAwesomeIcon className="mr-2 h-4 w-4" icon={icon} />
          {label}
        </button>

        {showDropdown && (
          <ul
            ref={(e) => (this.dropdownRef = e)}
            style={{minWidth: 160}}
            className="mt-2 flex-col z-20 absolute bg-white rounded-md text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {options.map((option, index) => {
              const {label} = option;

              return (
                <Manager>
                  <Reference>
                    {({ref}) => (
                      <li
                        ref={ref}
                        key={index}
                        onClick={() => this.selectOption(option)}
                        className={classNames(
                          "cursor-pointer",
                          "ignoreClose px-3 dropdown relative flex flex-row justify-between py-2 pl-3 text-left bg-white text-gray-900 hover:bg-indigo-600 hover:text-white"
                        )}
                        onMouseEnter={(e) => {
                          const rect = e.target.getBoundingClientRect();
                          this.setState({
                            currentBox: label,
                            coords: {
                              left: rect.x,
                              top: rect.y,
                            },
                          });
                        }}
                      >
                        <div className={classNames("ignoreClose")}>{label}</div>
                      </li>
                    )}
                  </Reference>
                </Manager>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

DropdownSelect.propTypes = {
  options: PropTypes.array.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
};

export default DropdownSelect;

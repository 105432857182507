import React, {Component} from "react";
import {Loading, PageHeadings, Tab, Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import LocationModal from "../../../modals/admin/records/location-modal";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import CompanyModal from "../../../modals/admin/records/company-modal";

class CompaniesPage extends Component {
  state = {companies: null};

  componentDidMount() {
    request("admin/companies", "GET").then((companies) => {
      this.setState({companies});
    });
  }

  render() {
    const {companies} = this.state;

    if (companies === null) {
      return <Loading />;
    }

    return (
      <>
        <CompanyModal
          addState={(company) => this.props.router.navigate("/admin/company/" + company.UNIQUE_ID)}
          ref={(e) => (this.companyModal = e)}
        />

        <PageHeadings
          label="Companies"
          description="Create and manage companies"
          button={{
            label: "Create Company",
            onClick: () => this.companyModal.open(),
          }}
        />
        <Filter searchable>
          {(_, search) => {
            return (
              <Table
                pagination
                searchFields={["NAME"]}
                search={search}
                data={companies}
                className="mt-4"
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => {
                      this.props.router.navigate("/admin/company/" + row.ID);
                    },
                  },
                ]}
                columns={[
                  {
                    value: "NAME",
                    label: "Name",
                  },
                ]}
              />
            );
          }}
        </Filter>
      </>
    );
  }
}

export default withRouter(CompaniesPage);

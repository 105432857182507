import {Button, Card, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Col, Row} from "../../../operations/supply-chain/shared";
import {BrandedCardPreview} from "./branded-card-preview";
import React from "react";
import {Formik} from "formik";
import FormRow from "../../../../components/form-row";
import ComboBox from "../../../../components/combobox";
import ImageFormDropZone from "../../../../components/image-form-drop-zone";
import {BrandedAppRequests as BrandedAppCardRequests} from "../../../../utils/request-helpers/admin/branded-app-requests";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";

const EditField = ({options, label, name, setCard}) => {
  return (
    <FormInput
      className="w-full"
      label={label}
      name={name}
      options={options}
      onChangeSoft={(val) => setCard(name, val)}
    />
  );
};

const ColorPicker = ({options, label, name, setCard}) => {
  return (
    <div className="flex items-center space-x-2 w-full">
      <div
        style={{
          backgroundColor: options.values[name],
        }}
        className="w-10 h-10 rounded shadow-lg mt-3 border-grey-100 border-2"
      />
      <ComboBox
        label={label}
        options={options}
        name={name}
        onChangeSoft={({id}) => {
          setCard(name, id);
        }}
        data={[
          {
            id: "#000000",
            label: "Black",
          },
          {
            id: "#FFFFFF",
            label: "White",
          },
          {
            id: "#F0F0F6",
            label: "Grey",
          },
          {
            id: "#4E46E5",
            label: "Indigo",
          },
          {
            id: "#EE4E4E",
            label: "Red",
          },
          {
            id: "#5660ED",
            label: "Blue",
          },
        ]}
      />
    </div>
  );
};

const BrandedCardButtons = ({card, restoreCard, deleteCard, handleSubmit}) => {
  return (
    <>
      <Button label={card.ID ? "Save Branded Card" : "Create Branded Card"} onClick={handleSubmit} />
      {card.DATE_ARCHIVED ? (
        <Button className="bg-green-600" label={"Restore Branded Card"} onClick={restoreCard} />
      ) : (
        <Button className="bg-red-600" label={"Delete Branded Card"} onClick={deleteCard} />
      )}
    </>
  );
};

export const CardEditor = ({brandedAppUniqueId, card: inputCard, syncState}) => {
  const [card, setCard] = React.useState(inputCard);

  const updateCard = (field, value) => {
    setCard({...card, [field]: value});
  };

  const initialValues = {
    ID: card.ID || null,
    LOGO_URL: card.LOGO_URL || "",
    LINK: card.LINK || "",
    TEXT_LABEL: card.TEXT_LABEL || "",
    TEXT_BUTTON: card.TEXT_BUTTON || "",
    COLORS_BACKGROUND: card.COLORS_BACKGROUND || "",
    COLORS_TEXT: card.COLORS_TEXT || "",
  };

  const upsertCard = async (val) =>
    val.ID
      ? BrandedAppCardRequests.updateBrandedAppCard(brandedAppUniqueId, card.ID, val)
      : BrandedAppCardRequests.createBrandedAppCard(brandedAppUniqueId, val);

  const successAndUpdate = (message) => {
    showSuccessNotification(message);
    syncState();
  };

  const handelSubmit = (val) =>
    upsertCard(val)
      .then(() => successAndUpdate("Card Saved Successfully"))
      .catch((e) => showErrorNotification("Error saving card", e.error.error));

  const restoreCard = () =>
    BrandedAppCardRequests.updateBrandedAppCard(brandedAppUniqueId, card.ID, {...card, DATE_ARCHIVED: null})
      .then(() => successAndUpdate("Card Saved Archived"))
      .catch((e) => showErrorNotification("Error archiving card", e.error.error));

  const deleteCard = () =>
    BrandedAppCardRequests.deleteBrandedAppCard(brandedAppUniqueId, card.ID)
      .then(() => successAndUpdate("Card Saved Restored"))
      .catch((e) => showErrorNotification("Error restoring card", e.error.error));

  return (
    <Card>
      <Formik initialValues={initialValues} onSubmit={handelSubmit}>
        {(options) => {
          const {handleSubmit} = options;

          return (
            <Row className="m-4 justify-between">
              <Col className="justify-center space-y-3 w-2/4">
                <BrandedCardPreview card={card} />
                <BrandedCardButtons
                  card={card}
                  restoreCard={restoreCard}
                  deleteCard={deleteCard}
                  handleSubmit={handleSubmit}
                />
              </Col>
              <form onSubmit={options.handleSubmit} className="ml-5 w-full">
                <ImageFormDropZone
                  label="Logo"
                  name="LOGO_URL"
                  options={options}
                  type="image"
                  onChangeSoft={(val) => {
                    updateCard("LOGO_URL", val.fileDetails);
                  }}
                />
                <FormRow>
                  <EditField label="Link" name="LINK" options={options} setCard={updateCard} />
                  <EditField label="Label" name="TEXT_LABEL" options={options} setCard={updateCard} />
                </FormRow>
                <FormRow>
                  <EditField label="Button Text" name="TEXT_BUTTON" options={options} setCard={updateCard} />
                  <ColorPicker
                    label="Background Color"
                    name="COLORS_BACKGROUND"
                    options={options}
                    borderField="COLORS_TEXT"
                    setCard={updateCard}
                  />
                  <ColorPicker
                    label="Text Color"
                    name="COLORS_TEXT"
                    options={options}
                    borderField="COLORS_BACKGROUND"
                    setCard={updateCard}
                  />
                </FormRow>
              </form>
            </Row>
          );
        }}
      </Formik>
    </Card>
  );
};

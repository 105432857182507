import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class POSModal extends Component {
  state = {device: null};

  open(device) {
    this.setState({device}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    const {device} = this.state;
    const {NAME} = values;

    await request("device/" + device.ID, "PUT", values);

    this.props.updateInfo(device.ID, NAME);
    this.modal.close();
  };

  render() {
    const {device} = this.state;

    const schema = Yup.object({
      NAME: Yup.string().nullable().required("Name is required."),
    });

    return (
      <Modal
        label="Edit Device"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{NAME: device?.NAME}}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Device Name" name="NAME" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default POSModal;

import moment from "moment-timezone";
import React from "react";
import {TIME_OFF_REQUEST_STATUSES, TRADE_REQUEST_TYPES} from "./team-constants";
import WarningBadge from "../components/badges/warning-badge";
import DangerBadge from "../components/badges/danger-badge";
import SuccessBadge from "../components/badges/success-badge";

export function formatShiftString(startEpoch, endEpoch) {
  let dateStr = moment(startEpoch).format("M/DD");

  let shiftStartStr = moment(startEpoch).format("h:mm A");
  let shiftEndStr = moment(endEpoch).format("h:mm A");

  return `${dateStr}: ${shiftStartStr} - ${shiftEndStr}`;
}

export function tradeRequestStatusToBadge(status) {
  switch (status) {
    case TRADE_REQUEST_TYPES.employee_pending.value:
      return <WarningBadge>Employee Pending</WarningBadge>;
    case TRADE_REQUEST_TYPES.ready_for_review.value:
      return <WarningBadge>Ready for Review</WarningBadge>;
    case TRADE_REQUEST_TYPES.employee_rejected.value:
      return <DangerBadge>Employee Rejected</DangerBadge>;
    case TRADE_REQUEST_TYPES.reviewer_denied.value:
      return <DangerBadge>Rejected</DangerBadge>;
    case TRADE_REQUEST_TYPES.reviewer_approved.value:
      return <SuccessBadge>Approved</SuccessBadge>;
  }
}

export function timeOffStatusToBadge(status) {
  switch (status) {
    case "PENDING":
      return <WarningBadge>Pending Review</WarningBadge>;
    case "APPROVED":
      return <SuccessBadge>Approved</SuccessBadge>;
    case "DENIED":
      return <DangerBadge>Denied</DangerBadge>;
  }
}

export function openShiftStatusToBadge(status) {
  switch (status) {
    case "PND":
      return <WarningBadge>Pending Review</WarningBadge>;
    case "ACP":
      return <SuccessBadge>Approved</SuccessBadge>;
    case "DND":
      return <DangerBadge>Denied</DangerBadge>;
  }
}

export const RECURRENCE_TYPES = {
  NONE: 0,
  WEEKLY: 1,
  MONTHLY: 2,
};

export function fetchRecurrenceString(type, interval) {
  let toReturn = "";

  if (type === RECURRENCE_TYPES.NONE) {
    toReturn = "Once Only";
  } else if (type === RECURRENCE_TYPES.WEEKLY) {
    toReturn = `Every ${numToString(interval)}${interval > 1 ? " Weeks" : "Week"}`;
  } else if (type === RECURRENCE_TYPES.MONTHLY) {
    toReturn = `Every ${numToString(interval)}${interval > 1 ? " Months" : "Month"}`;
  }

  return toReturn;
}

function numToString(val) {
  const stringNums = ["", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"];

  return stringNums[val - 1];
}

export function getDateForISODay(isoDay) {
  const currentDate = moment();
  const currentISODay = currentDate.isoWeekday();

  const dayDiff = isoDay - currentISODay;

  const desiredDate = currentDate.add(dayDiff, "days");

  return desiredDate;
}

import React, {Component} from "react";
import Wizard from "../../../../components/wizard";
import * as Yup from "yup";
import {
  FormBoolean,
  FormElement,
  FormInput,
  FormTextArea,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {ROLE_COLORS} from "../../../../utils/team-constants";
import {darken} from "../../../../utils/util";
import {classNames, decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FormRow from "../../../../components/form-row";
import CategoryDropdown from "../../../../dropdowns/sales/category-dropdown";
import ProductPricing, {PRODUCT_PRICING_YUP} from "../../../../features/product/product-pricing";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import Gallery from "../../../../components/gallery";
import ImageFormDropZone from "../../../../components/image-form-drop-zone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const defaultProductImage = "https://dripos-assets.s3.amazonaws.com/default-product-logo.png";
export const defaultProductImages = [
  "https://dripos-assets.s3.amazonaws.com/default-product-logo.png",
  "https://dripos-assets.s3.amazonaws.com/default-product-bakery.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-beer.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-bowls.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-bread.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-brunch.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-cars.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-cocktails.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-dessert.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-espresso.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-fork.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-grabngo.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-otherbevs.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-greenpizza.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-purplepizza.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-redpizza.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-redpizzawhole.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-retail.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-salad.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-shots.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-smoothies.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-stars.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-takebake.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-toast.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-wholepizza.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-wine.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-winner.jpg",
  "https://dripos-assets.s3.amazonaws.com/default-product-workout.jpg",
];

class CreateProductPage extends Component {
  state = {category: null};

  variant = null;

  componentDidMount() {
    const url = new URL(window.location.href);

    if (url.searchParams.has("category")) {
      this.wizardRef.setCurrentFormFieldValue("category", parseInt(url.searchParams.get("category")));
    }
  }

  async submitProduct({
    name,
    logo,
    description,
    category,
    price,
    weightPrice,
    type,
    global,
    variant,
    mobile,
    kiosk,
    color,
    tax,
    third,
    autoComplete,
    taxOverride,
    advancedTime,
    alcoholic,
    oneClick,
    taxDisabled,
  }) {
    let logoLow = "";

    if (!logo) {
      logo = defaultProductImage;
      logoLow = defaultProductImage;
    } else if (defaultProductImages.includes(logo)) {
      logoLow = logo;
    } else {
      logoLow = "LOW_" + logo;
    }

    const productId = await request("products", "POST", {
      NAME: name,
      LOGO: logo,
      LOGO_LOW: logoLow,
      ENABLED: parseInt(mobile),
      KIOSK_ENABLED: kiosk,
      DESCRIPTION: description,
      CATEGORY_ID: parseInt(category),
      PRICE: decimalToDollars(price),
      ICON_COLOR: color,
      THIRD_PARTY_ENABLED: parseInt(third),
      TAX_DISABLED: parseInt(taxDisabled),
      AUTO_COMPLETE: autoComplete,
      TAX_OVERRIDE: taxOverride,
      ADVANCED_TIME: advancedTime,
      ALCOHOLIC: parseInt(alcoholic),
      ONE_CLICK: parseInt(oneClick),
      WEIGHT_UNIT_COST: parseInt(type) === 3 && weightPrice ? decimalToDollars(weightPrice) : null,
    });

    if (parseInt(type) === 2) {
      if (global === "premade") {
        await request("v2/preset/" + variant + "/product/" + productId, "POST", {});
      } else if (global === "custom") {
        await request("modifiers", "POST", {
          ...variant,
          PRODUCT_ID: productId,
          SEQ: 0,
        });
      }
    }

    if (tax) {
      await request("tax/product", "POST", {
        PRODUCT_ID: productId,
        TAX_ID: tax,
      });
    }

    const serverProduct = await request("product/" + productId, "GET", null);

    this.props.router.navigate("/product/" + serverProduct.UNIQUE_ID);
  }

  renderColorIcons(options) {
    return (
      <FormElement
        name={"color"}
        label={"Select an icon for display on the POS"}
        options={options}
        hint={"Optional"}
        className={"mt-6"}
        tooltip={
          "Optionally select an icon to display on the register screen of the POS. The icon will have the selected background color and a two letter abbreviation of the product."
        }
      >
        {(value) => {
          return (
            <div className="grid grid-cols-2 gap-x-2 gap-y-2 sm:grid-cols-3 sm:gap-x-2 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 xl:gap-x-2">
              <div
                className={classNames(
                  `px-5 py-5 hover:ring-1 border-gray-200 border hover:ring-indigo-500 rounded-lg cursor-pointer flex flex-row content-center justify-center text-5xl font-bold`,
                  value === null
                    ? "ring-2 ring-offset-2 ring-indigo-500"
                    : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                )}
                onClick={() => {
                  options.setFieldValue("color", null);
                }}
              >
                <div className={"text-center text-red-600 border-indigo-200"}>
                  <FontAwesomeIcon icon="fa-solid fa-xmark-large" />
                </div>
              </div>

              {ROLE_COLORS.map((color) => {
                const colorWithHash = `#${color}`;
                const isActive = value === color;

                return (
                  <div
                    style={{
                      backgroundColor: colorWithHash,
                      color: `#${darken(colorWithHash)}`,
                    }}
                    className={classNames(
                      `px-5 py-5 hover:ring-1 hover:ring-indigo-500 rounded-lg cursor-pointer flex flex-row content-center justify-center text-5xl font-bold`,
                      isActive
                        ? "ring-2 ring-offset-2 ring-indigo-500"
                        : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                    )}
                    onClick={() => {
                      options.setFieldValue("color", color);
                    }}
                  >
                    <div className={"text-center"}>AA</div>
                  </div>
                );
              })}
            </div>
          );
        }}
      </FormElement>
    );
  }

  render() {
    const {category} = this.state;

    return (
      <Wizard
        ref={(e) => (this.wizardRef = e)}
        onQuit={() => this.props.router.navigate("/products")}
        onSubmit={this.submitProduct.bind(this)}
        steps={[
          {
            id: 0,
            name: "Basic information",
            description: "Simple information about the product.",
            initialValues: {
              name: "",
              pos: "1",
              category,
              mobile: "1",
              kiosk: "1",
              third: "1",
              taxDisabled: "0",
              oneClick: "0",
              alcoholic: "0",
              advancedEnabled: "0",
              advancedTime: null,
              autoComplete: "0",
              taxOverride: "0",
            },
            yup: Yup.object({
              name: Yup.string().required("Please input a name for your product"),
              category: Yup.string().nullable().required("Please select a valid category"),
              description: Yup.string().nullable(),
            }),
            render: (formikOptions) => {
              const {values} = formikOptions;

              return (
                <div>
                  <FormRow>
                    <FormInput
                      label="Product Name"
                      placeholder="Iced Coffee"
                      options={formikOptions}
                      name="name"
                      flex
                    />

                    <CategoryDropdown
                      label="Product Category"
                      placeholder="Click to select a product category"
                      options={formikOptions}
                      onChangeSoft={({label, unique}) => {
                        formikOptions.setFieldValue("categoryName", label);
                      }}
                      name="category"
                      flex
                    />
                  </FormRow>

                  <FormRow>
                    <FormBoolean
                      label="Point of Sale Enabled"
                      options={formikOptions}
                      name="pos"
                      tooltip="Will this product display on the point of sale"
                      flex
                    />

                    <FormBoolean
                      label="Mobile Enabled"
                      options={formikOptions}
                      name="mobile"
                      tooltip="Can customers order this product on the mobile ordering app and website"
                      flex
                    />

                    <FormBoolean
                      label="Kiosk Enabled"
                      options={formikOptions}
                      tooltip="Can customers order this product on kiosks"
                      name="kiosk"
                      flex
                    />
                  </FormRow>

                  <FormRow>
                    <FormBoolean
                      label="Third Party Enabled"
                      options={formikOptions}
                      tooltip="Will this product show up on third party order websites"
                      name="third"
                      flex
                    />

                    <FormBoolean
                      label="Tax Disabled"
                      options={formikOptions}
                      name="taxDisabled"
                      tooltip="Is tax disabled for this product"
                      flex
                    />

                    <FormBoolean
                      label="Tax Override"
                      options={formikOptions}
                      tooltip="If this is set to yes, then taxes will be applied to this product even if it is ordered with a tax disabled order type. If taxes are explicitly toggled off with the tax toggle on the POS, then taxes will not be charged."
                      name="taxOverride"
                    />
                  </FormRow>

                  <FormRow>
                    <FormBoolean
                      label="One Click"
                      options={formikOptions}
                      tooltip="Will clicking on this item on the POS add it directly to the ticket without opening the modifiers screen"
                      name="oneClick"
                      flex
                    />

                    <FormBoolean
                      label="Is Alcohol"
                      options={formikOptions}
                      tooltip="Is this product, or does this product contain alcohol"
                      name="alcoholic"
                      flex
                    />
                  </FormRow>

                  <FormRow>
                    <FormBoolean
                      label="Auto Complete Product"
                      options={formikOptions}
                      tooltip="When enabled, this product will not be displayed on the Ticket Screen. This only applies to orders placed on the POS."
                      name="autoComplete"
                      flex
                    />

                    <FormBoolean
                      label="Require Advanced Notice"
                      options={formikOptions}
                      tooltip="Does this product need advanced notice when ordering"
                      name="advancedEnabled"
                      flex
                    />

                    {values.advancedEnabled === "1" && (
                      <FormInput
                        label="Days in Advanced Notice"
                        options={formikOptions}
                        tooltip="How many days in advance must notice be given to order this product"
                        name="advancedTime"
                        flex
                      />
                    )}
                  </FormRow>

                  <FormTextArea
                    label={`Product description`}
                    placeholder="A cold refreshing cup of coffee!"
                    hint="Optional"
                    name="description"
                    options={formikOptions}
                  />
                </div>
              );
            },
          },
          {
            id: 1,
            name: "Pricing",
            didUnmount: () => {
              if (this.productPricingRef) {
                this.productPricingRef.outsideVariant();
              }
            },
            description: "How will this product be priced.",
            initialValues: {
              type: "0",
              price: "",
              global: "premade",
              variant: this.variant,
            },
            yup: PRODUCT_PRICING_YUP,
            validation: () => {
              return this.productPricingRef.outsideValidate();
            },
            render: (formikOptions) => (
              <ProductPricing ref={(e) => (this.productPricingRef = e)} formikOptions={formikOptions} />
            ),
          },
          {
            id: 2,
            name: "Logo",
            description: "Choose a logo for your product.",
            initialValues: {logo: defaultProductImage, color: null},
            render: (formikOptions) => (
              <div>
                <ImageFormDropZone options={formikOptions} name="logo" />

                <Gallery
                  name="logo"
                  options={formikOptions}
                  label="or Select a product preset"
                  data={defaultProductImages.map((item) => {
                    return {
                      id: item,
                      source: item,
                    };
                  })}
                />

                {this.renderColorIcons(formikOptions)}
              </div>
            ),
          },
          {
            id: 3,
            name: "Preview",
            description: "Confirm product creation.",
            render: ({values}) => {
              const {
                name,
                category,
                categoryName,
                mobile,
                kiosk,
                pos,
                taxDisabled,
                third,
                autoComplete,
                advancedTime,
                alcoholic,
                oneClick,
                advancedEnabled,
              } = values;

              return (
                <TwoColumnList
                  label="Product Summary"
                  description="Lets take a quick look before we create this new product"
                  data={[
                    {label: "Name", value: name},
                    {label: "Category", value: categoryName},
                    {
                      label: "Point of sale Enabled",
                      value: pos,
                      type: "bool",
                    },
                    {
                      label: "Mobile Ordering Enabled",
                      value: mobile,
                      type: "bool",
                    },
                    {
                      label: "Kiosk Ordering Enabled",
                      value: kiosk,
                      type: "bool",
                    },
                    {
                      label: "Third Party Enabled",
                      value: third,
                      type: "bool",
                    },
                    {
                      label: "Tax Disabled",
                      value: taxDisabled,
                      type: "bool",
                    },
                    {
                      label: "One Click Add on POS",
                      value: oneClick,
                      type: "bool",
                    },
                    {
                      label: "Is Alcohol",
                      value: alcoholic,
                      type: "bool",
                    },
                    {
                      label: "Auto Complete Product",
                      value: autoComplete,
                      type: "bool",
                    },
                    {
                      label: "Require Advanced Notice",
                      value: advancedEnabled,
                      type: "bool",
                    },
                    {
                      label: "Days in Advanced Notice",
                      value: advancedTime ?? 0,
                      type: "number",
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />
    );
  }
}

export default withRouter(CreateProductPage);

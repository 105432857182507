import React, {Component} from "react";

class DaysOfWeekSelect extends Component {
  render() {
    const {value = [], onChange, options, label, name} = this.props;
    const days = [
      {value: "MON", label: "Mon"},
      {value: "TUE", label: "Tue"},
      {value: "WED", label: "Wed"},
      {value: "THU", label: "Thu"},
      {value: "FRI", label: "Fri"},
      {value: "SAT", label: "Sat"},
      {value: "SUN", label: "Sun"},
    ];

    const handleDayClick = (dayValue) => {
      const newValue = value.includes(dayValue) ? value.filter((v) => v !== dayValue) : [...value, dayValue];
      onChange(newValue);
    };

    return (
      <div className="mt-2">
        <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
        <div className="flex flex-wrap gap-2">
          {days.map((day) => (
            <button
              key={day.value}
              type="button"
              className={`px-3 py-2 text-sm font-medium rounded-md ${
                value.includes(day.value)
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => handleDayClick(day.value)}
            >
              {day.label}
            </button>
          ))}
        </div>
        {options.touched[name] && options.errors[name] && (
          <div className="text-red-500 text-sm mt-1">{options.errors[name]}</div>
        )}
      </div>
    );
  }
}

export default DaysOfWeekSelect;

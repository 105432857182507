import React, {Component} from "react";
import {Modal, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import PersonDropdown from "../../../dropdowns/team/person-dropdown";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import {showErrorAlert} from "../../../utils/alert-helper";

class AdminModal extends Component {
  state = {
    partner: null,
  };

  LOCATION_STR = "LOCATION";
  COMPANY_STR = "COMPANY";
  SUPER_STR = "SUPER";

  VALIDATION_SCHEMA = Yup.object().shape({
    level: Yup.string().nullable().required("Level is required"),
    company: Yup.string()
      .nullable()
      .when("level", {
        is: (e) => e === this.COMPANY_STR,
        then: Yup.string().nullable().required("Please select a company"),
      }),
    location: Yup.string()
      .nullable()
      .when("level", {
        is: (e) => e === this.LOCATION_STR,
        then: Yup.string().nullable().required("Please select a location"),
      }),
    partner: Yup.number().required(),
  });

  open(partner = null) {
    partner = partner ? {value: parseInt(partner.ID), label: partner.FULL_NAME} : null;

    this.setState(
      {
        partner,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.modal.open();
      }
    );
  }

  close() {
    this.modal.close();
  }

  handleSubmit(values) {
    const {partner, level, company, location} = values;
    const {syncState} = this.props;
    const {UMBRELLA_ID} = this.props.shop.location;

    if (level === "LOCATION") {
      request("umbrellas/partner/location/", "POST", {
        PARTNER: partner.value ? partner.value : partner,
        LOCATION_ID: location,
      })
        .then(() => {
          syncState();
          this.modal.close();
        })
        .catch(() => {
          showErrorAlert("You have insufficient permissions to create a location partner.");
        });
    } else if (level === "COMPANY") {
      request("umbrellas/partner/company/", "POST", {
        PARTNER: partner.value ? partner.value : partner,
        COMPANY_ID: company,
      })
        .then(() => {
          syncState();
          this.modal.close();
        })
        .catch(() => {
          showErrorAlert("You have insufficient permissions to create a company partner.");
        });
    } else if (level === "SUPER") {
      request("umbrellas/partner/", "POST", {
        PARTNER: partner.value ? partner.value : partner,
        UMBRELLA_ID,
      })
        .then(() => {
          syncState();
          this.modal.close();
        })
        .catch(() => {
          showErrorAlert("You have insufficient permissions to create a super partner.");
        });
    }
  }

  render() {
    const {people, locations, companies, level} = this.props;
    const {partner} = this.state;
    const disabled = !!partner;

    const peopleData = people
      .filter((person) => person.ID && person.FULL_NAME)
      .map((person) => {
        return {
          value: person.ID,
          label: person.FULL_NAME,
        };
      });

    const locationData =
      locations &&
      locations.map((location) => {
        return {
          value: location.ID,
          label: location.NAME,
        };
      });

    const companyData =
      companies &&
      companies.map((company) => {
        return {
          value: company.COMPANY_ID,
          label: company.COMPANY_NAME,
        };
      });

    return (
      <Modal
        label={"Add Administrators"}
        buttonLabel={"Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        large
      >
        <Formik
          onSubmit={(values) => this.handleSubmit(values)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            partner: partner?.value,
            level: level,
            company: null,
            location: null,
          }}
          validationSchema={this.VALIDATION_SCHEMA}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <PersonDropdown
                  partner={partner}
                  disabled={disabled}
                  placeholder={"Select a Partner"}
                  options={formikOptions}
                  data={peopleData}
                />

                {level === this.LOCATION_STR && (
                  <FormSelect
                    label={"Location"}
                    name={"location"}
                    tooltip={"At which location should this person be a partner?"}
                    data={locationData}
                    options={formikOptions}
                  />
                )}

                {level === this.COMPANY_STR && (
                  <FormSelect
                    label={"Company"}
                    name={"company"}
                    tooltip={"At which company should this person be a partner?"}
                    data={companyData}
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(AdminModal);

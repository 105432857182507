import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import VendorModal from "../../../../modals/operations/supply-chain/vendors/vendor-modal";
import VendorContactModal from "../../../../modals/operations/supply-chain/vendors/vendor-contact-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import VendorItemModal from "../../../../modals/operations/supply-chain/vendors/vendor-item-modal";
import {
  PurchaseOrderRequests,
  VendorRequests,
} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {setupReduxConnection} from "../../../../redux";
import Checkbox from "../../../../components/form-elements/checkbox";

class SupplyChainVendorPage extends Component {
  state = {
    vendor: null,
    isLoading: true,
  };

  componentDidMount() {
    this.syncState();
  }
  capitalizeFirstLetter(str) {
    if (!str) return str; // Handle empty or null strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  async syncState() {
    const {UNIQUE_ID: uniqueId} = this.props.router.params;
    const vendor = await VendorRequests.fetchVendor(uniqueId);
    const vendorContacts = await VendorRequests.fetchVendorContacts(vendor.ID);
    const vendorItems = await VendorRequests.fetchVendorItems(vendor.ID);
    const purchaseOrders = await VendorRequests.fetchPurchaseOrders(vendor.ID);
    this.setState({
      vendor: {
        ...vendor,
        CONTACTS: vendorContacts,
        ITEMS: vendorItems,
        PURCHASE_ORDERS: purchaseOrders,
      },
      isLoading: false,
    });
  }

  async archiveVendorContact(contact) {
    const {vendor} = this.state;
    showLoadingConfirmAlert(
      "Remove Contact",
      "Are you sure you would like to remove this contact from the vendor?"
    )
      .then(async (close) => {
        await VendorRequests.deleteVendorContact(vendor.ID, contact.ID);

        await this.syncState();

        return close();
      })
      .catch(() => {
        return;
      });
  }

  render() {
    const {vendor, isLoading} = this.state;

    if (!vendor || isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <VendorModal ref={(e) => (this.vendorModal = e)} syncState={() => this.syncState()} />

        <VendorContactModal
          ref={(e) => (this.vendorContactModal = e)}
          vendor={vendor}
          syncState={() => this.syncState()}
        />

        <VendorItemModal
          ref={(e) => (this.vendorItemModal = e)}
          vendor={vendor}
          syncState={() => this.syncState()}
        />

        <PageHeadings
          label={vendor.NAME}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Vendors", url: "/vendors"},
          ]}
        />

        <TwoColumnList
          label="Vendor Information"
          // tooltip={{data: "Store hours will be displayed online"}}
          data={[
            {label: "Vendor Name", value: vendor.NAME},
            {label: "Street", value: vendor.ADDRESS},
            {label: "City", value: vendor.CITY},
            {label: "State", value: vendor.STATE},
            {label: "Zip", value: vendor.ZIPCODE},
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.vendorModal.open(vendor),
            },
          ]}
        />

        <Tab
          data={[
            {id: "contacts", label: "Contacts"},
            {id: "ingredients", label: "Vendor Items"},
            {id: "po", label: "Purchase Orders"},
          ]}
        >
          {(id) => {
            if (id === "contacts") {
              return (
                <Card
                  label="Vendor Contacts"
                  description="View the contacts for this vendor"
                  button={{
                    label: "Add Contact",
                    onClick: () => {
                      this.vendorContactModal.open();
                    },
                  }}
                >
                  <Table
                    data={vendor.CONTACTS}
                    columns={[
                      {
                        value: "FIRST_NAME",
                        label: "Name",
                        format: (_, row) => {
                          return `${row.FIRST_NAME} ${row.LAST_NAME}`;
                        },
                      },
                      {
                        value: "IS_PRIMARY_CONTACT",
                        label: "Primary Contact",
                        format: (val, row) => {
                          return <Checkbox checked={val} onChange={console.log(row)} />;
                        },
                      },
                      {
                        value: "PHONE",
                        label: "Phone Number",
                        format: (val) => getFormattedPhone(val),
                      },
                      {value: "EMAIL", label: "Email"},
                    ]}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.vendorContactModal.open({vendorId: vendor.ID, ...row});
                        },
                      },
                      {
                        icon: "trash",
                        onClick: (row) => {
                          return this.archiveVendorContact(row);
                        },
                      },
                    ]}
                    expandable={(data) => {
                      return <div className="ml-10 p-2 text-gray-400">{data.NOTES ?? "No Notes"}</div>;
                    }}
                  />
                </Card>
              );
            } else if (id === "ingredients") {
              console.log(vendor.ITEMS);
              return (
                <Card
                  label="Vendor Items"
                  description="View all of the items you can purchase from this vendor"
                  button={{
                    label: "Create Vendor Item",
                    onClick: () => {
                      this.vendorItemModal.open();
                    },
                  }}
                >
                  <Table
                    data={vendor.ITEMS ?? []}
                    columns={[
                      {value: "NAME", label: "Vendor Item"},
                      {
                        value: "PRODUCT_SKU",
                        label: "SKU",
                        format: (sku) => {
                          return sku ? "#" + sku : "-";
                        },
                      },
                      {value: "INGREDIENT_NAME", label: "Attached Ingredient"},
                      {
                        value: "PRICE_PER_CASE",
                        label: "Vendor Item Price",
                        type: "dollars",
                      },
                      {
                        value: "UNIT",
                        label: "Vendor Item Unit",
                        format: (val) => {
                          return val?.NAME ?? "-";
                        },
                      },
                      {
                        value: "CASE_SIZE",
                        label: "Vendor Item Quantity",
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.vendorItemModal.open(row);
                        },
                      },
                    ]}
                  />
                </Card>
              );
            } else if (id === "po") {
              return (
                <Card
                  label="Purchase History"
                  description="See the purchase history from this vendor"
                  button={{
                    label: "Create Purchase Order",
                    onClick: () => {
                      this.props.router.navigate("/purchase-order-create?vendor=" + vendor.ID);
                    },
                  }}
                >
                  <Table
                    data={vendor.PURCHASE_ORDERS}
                    columns={[
                      {
                        value: "PO_NUMBER",
                        label: "Order Number",
                        format: (value) => {
                          const stringVal = "" + value;
                          return "#" + stringVal.padStart(7 - stringVal.length, "0");
                        },
                      },
                      {value: "STATUS", label: "Status", format: (val) => this.capitalizeFirstLetter(val)},
                      {value: "TOTAL", label: "Total", type: "dollars"},
                    ]}
                  />
                </Card>
              );
            }

            return <></>;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainVendorPage));

import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment/moment";

class RecurringInvoiceCard extends Component {
  render() {
    const {handleEdit, hideEdit, schedule} = this.props;

    const listFields = [
      {
        label: "Status",
        value: schedule.STATUS?.capitalize(),
        hide: true,
      },
      {
        label: "Next Invoice",
        value: schedule.INVOICE ? moment(schedule.INVOICE.DATE_CREATED).format("MM/DD/YY") : null,
        hide: true,
      },
      {label: "Interval", value: schedule.INTERVAL_TYPE.capitalize()},
      {label: "Frequency", value: schedule.FREQUENCY},
      {label: "Date Start", value: schedule.DATE_START, type: "date"},
      {
        label: "Date End",
        value: schedule.DATE_END || "None",
        type: schedule.DATE_END ? "date" : undefined,
      },
      {
        label: "Billing Anchor Date",
        value: schedule.BILLING_ANCHOR,
        type: "date",
      },
      {label: "Days until Due", value: schedule.DAYS_UNTIL_DUE},
    ];

    const listButtons = hideEdit
      ? []
      : [
          {
            label: "Edit Information",
            onClick: handleEdit,
          },
        ];

    return (
      <TwoColumnList
        label="Recurring Invoice Details"
        description="Information about the recurring invoice"
        data={listFields}
        buttons={listButtons}
      />
    );
  }
}

RecurringInvoiceCard.propTypes = {
  schedule: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default RecurringInvoiceCard;

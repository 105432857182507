import React, {Component} from "react";
import {PageHeadings, Card, FormSelect, Tab, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";
import {getHours, minutesToHourString} from "../../utils/hours-helper";
import {ISO_DAYS} from "../../utils/constants";
import HoursModal from "../../modals/shop/hours-modal";
import {HOURS_TYPES} from "../../utils/shop-constants";

class HoursPage extends Component {
  state = {
    isLoading: true,
    timeZone: this.props.shop.settings.TIMEZONE,
    hours: null,
    deliveryHours: null,
    thirdPartyHours: null,
  };

  async componentDidMount() {
    await this.syncState();
  }

  syncState = async () => {
    let {
      HOURS: hours,
      DELIVERY_HOURS: deliveryHours,
      THIRD_PARTY_HOURS: thirdPartyHours,
    } = await request("hours", "GET");

    return this.setState({
      isLoading: false,
      hours,
      deliveryHours,
      thirdPartyHours,
    });
  };

  renderStoreHours() {
    let {hours} = this.state;

    let data = [];

    for (let day of [0, 1, 2, 3, 4, 5, 6]) {
      let {open, storeOpenTime, storeCloseTime} = getHours(hours, day);

      data.push({
        label: `${ISO_DAYS[day]} Open`,
        value: open ? minutesToHourString(storeOpenTime) : "Closed",
      });
      data.push({
        label: `${ISO_DAYS[day]} Close`,
        value: open ? minutesToHourString(storeCloseTime) : "Closed",
      });
    }

    return (
      <TwoColumnList
        label="Store Hours"
        tooltip={{data: "Store hours will be displayed online"}}
        data={data}
        buttons={[
          {
            label: "Edit",
            onClick: () => this.hoursModal.open(HOURS_TYPES.STORE),
          },
        ]}
      />
    );
  }

  renderThirdPartyHours() {
    let {hours, thirdPartyHours} = this.state;

    let hoursToDisplay = thirdPartyHours;

    if (thirdPartyHours.USE_STORE_HOURS == "1") {
      hoursToDisplay = hours;
    }

    let data = [];

    for (let day of [0, 1, 2, 3, 4, 5, 6]) {
      let {open} = getHours(hours, day);
      let {
        open: deliveryEnabled,
        storeOpenTime: deliveryOpen,
        storeCloseTime: deliveryClose,
      } = getHours(hoursToDisplay, day);

      let startValue, endValue;
      if (!open) {
        startValue = "Closed";
        endValue = "Closed";
      } else if (!deliveryEnabled) {
        startValue = "Disabled";
        endValue = "Disabled";
      } else {
        startValue = minutesToHourString(deliveryOpen);
        endValue = minutesToHourString(deliveryClose);
      }

      data.push({
        label: `${ISO_DAYS[day]} Start`,
        value: startValue,
      });
      data.push({
        label: `${ISO_DAYS[day]} End`,
        value: endValue,
      });
    }

    return (
      <TwoColumnList
        label="Third Party Hours"
        tooltip={{
          data: "This determines what hours third party ordering will be available for your customers",
        }}
        data={data}
        buttons={[
          {
            label: "Edit",
            onClick: () => this.hoursModal.open(HOURS_TYPES.THIRD_PARTY),
          },
        ]}
      />
    );
  }

  renderDeliveryHours() {
    let {isLoading, hours, deliveryHours} = this.state;

    let data = [];

    for (let day of [0, 1, 2, 3, 4, 5, 6]) {
      let {open} = getHours(hours, day);
      let {
        open: deliveryEnabled,
        storeOpenTime: deliveryOpen,
        storeCloseTime: deliveryClose,
      } = getHours(deliveryHours, day);

      let startValue, endValue;
      if (!open) {
        startValue = "Closed";
        endValue = "Closed";
      } else if (!deliveryEnabled) {
        startValue = "Disabled";
        endValue = "Disabled";
      } else {
        startValue = minutesToHourString(deliveryOpen);
        endValue = minutesToHourString(deliveryClose);
      }

      data.push({
        label: `${ISO_DAYS[day]} Start`,
        value: startValue,
      });
      data.push({
        label: `${ISO_DAYS[day]} End`,
        value: endValue,
      });
    }

    return (
      <TwoColumnList
        label="Delivery Hours"
        // description="What are they going to get"
        tooltip={{
          data: "This determines what hours delivery will be available for your customers",
        }}
        data={data}
        buttons={[
          {
            label: "Edit",
            onClick: () => this.hoursModal.open(HOURS_TYPES.DELIVERY),
          },
        ]}
      />
    );
  }

  //
  // renderThirdPartyHours(){
  //   let {isLoading, hours, thirdPartyHours} = this.state;
  //
  //   console.log(thirdPartyHours)
  //
  //
  //   let data = [];
  //
  //   for (let day of [0, 1, 2, 3, 4, 5, 6]) {
  //     let {open} = getHours(hours, day);
  //     let {
  //       open: deliveryEnabled,
  //       storeOpenTime: deliveryOpen,
  //       storeCloseTime: deliveryClose,
  //     } = getHours(deliveryHours, day);
  //
  //     let startValue, endValue;
  //     if (!open) {
  //       startValue = "Closed";
  //       endValue = "Closed";
  //     } else if (!deliveryEnabled) {
  //       startValue = "Disabled";
  //       endValue = "Disabled";
  //     } else {
  //       startValue = minutesToHourString(deliveryOpen);
  //       endValue = minutesToHourString(deliveryClose);
  //     }
  //
  //     data.push({
  //       label: `${ISO_DAYS[day]} Start`,
  //       value: startValue,
  //     });
  //     data.push({
  //       label: `${ISO_DAYS[day]} End`,
  //       value: endValue,
  //     });
  //   }
  //
  //   return (
  //     <TwoColumnList
  //       label="Delivery Hours"
  //       // description="What are they going to get"
  //       tooltip={{
  //         data: "This determines what hours third party hours will be available for your customers",
  //       }}
  //       data={data}
  //       buttons={[
  //         {
  //           label: "Edit",
  //           onClick: () => this.hoursModal.open(false),
  //         },
  //       ]}
  //     />
  //   );
  // }

  render() {
    this.hourRefs = [];

    let {CHOWLY_ID, STREAM_ID} = this.props.shop.location;
    let {DELIVERY_ENABLED} = this.props.shop.settings;
    let {isLoading, hours} = this.state;

    if (isLoading) {
      return <div></div>;
    }

    let tabs = [{label: "Store", id: "store"}];

    if (CHOWLY_ID || STREAM_ID) {
      tabs.push({label: "Third Party", id: "thirdParty"});
    }

    if (DELIVERY_ENABLED === "1") {
      tabs.push({label: "Delivery", id: "delivery"});
    }

    return (
      <div>
        <HoursModal ref={(e) => (this.hoursModal = e)} handeUpdate={this.syncState} />

        <PageHeadings
          label="Hours"
          description={"Hours of the day that mobile ordering is available for your location"}
        />

        <Tab className="mt-2" data={tabs}>
          {(id) => {
            if (id === "store") {
              return this.renderStoreHours();
            } else if (id === "thirdParty") {
              return this.renderThirdPartyHours();
            } else {
              return this.renderDeliveryHours();
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(HoursPage);

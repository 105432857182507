import React, {Component} from "react";
import {FormBoolean, FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {ACCOUNT_SUB_TYPES, ACCOUNT_TYPES} from "../../utils/accounting-constants";
import {request} from "../../utils/request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setupReduxConnection} from "../../redux";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../utils/navigation";
import {showConfirmAlert} from "../../utils/alert-helper";

class AccountModal extends Component {
  state = {account: null};

  openNew() {
    this.setState({account: null}, () => this.modal.open());
  }

  open(account) {
    this.setState({account}, () => this.modal.open());
  }

  handleSubmit = async ({
    name,
    type,
    debitCredit,
    subType,
    parentLedgerId = null,
    startingBalance,
    reportCashFlow,
  }) => {
    const {account} = this.state;

    const payload = {
      NAME: name,
      TYPE: type,
      DEBIT_CREDIT: debitCredit,
      SUB_TYPE: subType,
      PARENT_ID: parentLedgerId,
      REPORT_CASH_FLOW: reportCashFlow,
    };

    if (!account) {
      payload.STARTING_BALANCE = decimalToDollars(startingBalance);

      await request("accounting/ledgers", "POST", payload);
    } else {
      await request(`accounting/ledgers/${account.UNIQUE_ID}`, "PATCH", payload);
    }

    await this.props.syncState();

    this.modal.close();
  };

  async deleteLedger() {
    const {account} = this.state;

    await showConfirmAlert(
      `Delete ${account.NAME}?`,
      `Are you sure you want to delete ${account.NAME}? This will delete all associated journal entries`
    );

    await request(`accounting/ledgers/${account.UNIQUE_ID}`, "DELETE");

    this.modal.close();

    this.props.router.navigate("/accounting/accounts");
  }

  render() {
    const {ledgers} = this.props.accounting;
    const {account} = this.state;

    return (
      <Modal
        label={`${account ? "Edit" : "New"} Account`}
        ref={(e) => (this.modal = e)}
        deleteLabel={"Delete"}
        deleteOnClick={() => this.deleteLedger()}
        buttonLabel={account ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            name: account?.NAME,
            type: account?.TYPE,
            subType: account?.SUB_TYPE,
            debitCredit: account?.DEBIT_CREDIT,
            isSubAccount: account?.PARENT_ID ? "1" : "0",
            parentLedgerId: account?.PARENT_ID,
            startingBalance: toDollars(0),
            reportCashFlow: account?.REPORT_CASH_FLOW ?? "0",
          }}
          enableReinitialize={true}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            type: Yup.string().required("Type is required"),
            subType: Yup.string().required("Sub Type is required"),
            debitCredit: Yup.string().required("Debit or Credit is required"),
            parentLedgerId: Yup.number().when("isSubAccount", {
              is: "1",
              then: Yup.number().required("Field 2 is required"),
              otherwise: Yup.number().nullable(),
            }),
          })}
          onSubmit={this.handleSubmit}
        >
          {(formikOptions) => {
            const {setFieldValue, handleSubmit, values} = formikOptions;
            const {type, subType, isSubAccount} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  className={"flex-1"}
                  name={"name"}
                  label={"Name"}
                  options={formikOptions}
                  disabled={account && !account?.IS_EDITABLE}
                />

                <FormSelect
                  name="type"
                  label={"Type"}
                  data={Object.keys(ACCOUNT_TYPES).map((_key) => ({
                    label: ACCOUNT_TYPES[_key].label,
                    value: _key,
                  }))}
                  options={formikOptions}
                  onChangeSoft={({value}) => {
                    if (subType && ACCOUNT_SUB_TYPES[subType].parent !== value) {
                      setFieldValue("subType", null);
                    }

                    const allowedSubTypes = Object.values(ACCOUNT_SUB_TYPES).filter(
                      ({parent}) => parent === value
                    );

                    if (allowedSubTypes.length === 1) {
                      setFieldValue("subType", allowedSubTypes[0].value);
                    }
                  }}
                  disabled={account && !account?.IS_EDITABLE}
                />

                <FormSelect
                  name="subType"
                  label={"Sub Type"}
                  data={Object.keys(ACCOUNT_SUB_TYPES)
                    .filter((_key) => ACCOUNT_SUB_TYPES[_key].parent === type)
                    .map((_key) => ({
                      label: ACCOUNT_SUB_TYPES[_key].label,
                      value: _key,
                    }))}
                  options={formikOptions}
                  disabled={account && !account?.IS_EDITABLE}
                />

                <FormSelect
                  name={"debitCredit"}
                  label={"Debit or Credit"}
                  data={[
                    {label: "Debit", value: "DEBIT"},
                    {label: "Credit", value: "CREDIT"},
                  ]}
                  options={formikOptions}
                  disabled={account && !account?.IS_EDITABLE}
                />

                <FormBoolean
                  name={"isSubAccount"}
                  label={"Is this a sub account?"}
                  options={formikOptions}
                  disabled={account && !account?.IS_EDITABLE}
                />

                {isSubAccount === "1" && (
                  <FormSelect
                    label={"Parent Account"}
                    name={"parentLedgerId"}
                    options={formikOptions}
                    data={ledgers
                      .filter(({SUB_TYPE}) => SUB_TYPE === subType)
                      .map((_l) => ({
                        label: _l.NAME,
                        value: _l.ID,
                      }))}
                    disabled={account && !account?.IS_EDITABLE}
                  />
                )}

                {!account && (
                  <FormInput label={"Starting Balance"} name={"startingBalance"} options={formikOptions} />
                )}

                <FormBoolean label={"Report Cash Flow"} name={"reportCashFlow"} options={formikOptions} />
              </form>
            );
          }}
        </Formik>

        {account && account?.IS_EDITABLE === 0 && (
          <div className={"text-center mt-3 text-sm text-gray-600"}>
            <FontAwesomeIcon icon={"lock"} className={"mr-1"} /> Account is used for automated transactions
            and cannot be archived or renamed.
          </div>
        )}
      </Modal>
    );
  }
}

export default setupReduxConnection(["accounting"], null, {forwardRef: true})(withRouter(AccountModal));

import React, {Component} from "react";
import {PageHeadings, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";

class CheckBenefitIntegrationPage extends Component {
  state = {isLoading: true, url: null};

  async componentDidMount() {
    await this.generateBenefitsLink();
  }

  async generateBenefitsLink() {
    let {integration} = this.props;
    let {url} = await request(CHECK_BENEFIT_INTEGRATIONS[integration].endpoint, "POST");

    this.setState({isLoading: false, url});
  }

  render() {
    let {isLoading, url} = this.state;
    let {integration} = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="flex flex-col h-screen">
        <PageHeadings label={CHECK_BENEFIT_INTEGRATIONS[integration].label} />

        <Card>
          <embed className="w-full h-screen" src={url} />
        </Card>
      </div>
    );
  }
}

export default CheckBenefitIntegrationPage;

export const CHECK_BENEFIT_INTEGRATIONS = {
  SIMPLY_INSURED: {
    id: "SIMPLY_INSURED",
    label: "Health Benefits",
    endpoint: "payroll/benefits/integrations/simply-insured",
  },
  NEXT: {
    id: "NEXT",
    label: "Workers Compensation",
    endpoint: "payroll/benefits/integrations/next",
  },
};

import React, {Component} from "react";
import {Card, Table, PageHeadings, Loading, AdvancedFilter} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import moment from "moment-timezone";
import {capitalize} from "lodash";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PayrollWhitelistWrapper from "../../../features/payroll/payroll-whitelist-wrapper";

class PayrollHistoryPage extends Component {
  state = {history: null, isLoading: true};

  render() {
    const {ALL_RUNS_PAYROLL} = this.props.shop.payrollGroup;
    const {location} = this.props.shop;

    return (
      <PayrollWhitelistWrapper router={this.props.router}>
        <PageHeadings label="Payroll History" />

        <Card>
          <AdvancedFilter
            defaultFilters={[
              {
                filter: "locations",
                value: location.UNIQUE_ID,
              },
            ]}
            data={[
              {
                id: "locations",
                type: "check",
                label: "Locations",
                data: ALL_RUNS_PAYROLL.map((_loc) => ({
                  label: _loc.NAME,
                  value: _loc.UNIQUE_ID,
                })),
              },
              {label: "Payday Start", type: "datetime", id: "start"},
              {label: "Payday End", type: "datetime", id: "end"},
            ]}
          >
            {(filters, search) => {
              return (
                <Table
                  pagination
                  filters={filters}
                  route={"payroll/history"}
                  actionButtons={[
                    {
                      label: "View",
                      onClick: (row) => this.props.router.navigate("/payrolls/" + row.CHECK_PAYROLL_ID),
                    },
                  ]}
                  columns={[
                    {
                      label: "Payday",
                      value: "PAYDAY",
                      format: (value) => moment(value).format("MMM D, YYYY"),
                    },
                    {
                      label: "Type",
                      value: "TYPE",
                      format: (value) => PAYROLL_TYPES[value],
                    },
                    {
                      label: "Period",
                      value: "DATE_START",
                      format: (value, row) => {
                        if (row.TYPE === "CONTRACTOR") {
                          return "";
                        }

                        return `${moment(row.PERIOD_START).format("MMM D, YYYY")}  - ${moment(
                          row.PERIOD_END
                        ).format("MMM D, YYYY")}`;
                      },
                    },
                    {
                      label: "Total Liability",
                      value: "TOTAL_LIABILITY",
                      format: (value) => toDollars(value, true),
                    },
                    {
                      label: "Status",
                      value: "STATUS",
                      format: (value, row) => {
                        if (row.PARTIALLY_PAID === 1) {
                          return "Partially Paid";
                        }

                        if (row.DATE_PAID) {
                          return "Paid";
                        }

                        if (row.DATE_PROCESSING) {
                          return "Processing";
                        }

                        return "Pending";
                      },
                    },
                  ]}
                />
              );
            }}
          </AdvancedFilter>
        </Card>
      </PayrollWhitelistWrapper>
    );
  }
}

const PAYROLL_TYPES = {
  REGULAR: "Regular",
  OFF_CYCLE: "Off-Cycle",
  CONTRACTOR: "Contractor",
};

export default setupReduxConnection(["shop"])(withRouter(PayrollHistoryPage));

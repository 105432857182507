import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {CELL_TYPES, INVOICING_COLUMNS} from "../../features/reporting/reports/reporting-constants";
import moment from "moment";
import ContactFilterDropdown from "../../components/contact-filter-dropdown";
import InvoiceStatusDropdown from "../../components/invoice-status-dropdown";

class InvoicingReportPage extends Component {
  state = {selectedContacts: [], selectedStatuses: []};

  convertDataToRows = (reportData) => {
    const {invoices, totals} = reportData;
    const toReturn = [];

    if (invoices) {
      for (const invoice of invoices) {
        toReturn.push({
          label: moment(invoice.DATE_CREATED).format("M/D/YYYY"),
          numbers: {
            ...invoice,
            PAID_DATE: invoice.PAID_DATE ? moment(invoice.PAID_DATE).format("M/D/YYYY") : "",
            DATE_CREATED: invoice.DATE_CREATED ? moment(invoice.DATE_CREATED).format("M/D/YYYY") : "",
          },
          rowType: CELL_TYPES.REGULAR,
        });
      }
    }

    toReturn.push({
      label: "Totals",
      numbers: totals,
      style: [CELL_TYPES.BOLD],
    });

    return toReturn;
  };

  render() {
    const {selectedContacts, selectedStatuses} = this.state;

    return (
      <div>
        <div className={"mb-5"}>
          <PageHeadings label={"Invoicing Report"} />
        </div>

        <FixedColumnReport
          firstColLabel={"Date Created"}
          firstColTooltip="This column will list the day that the invoice was created."
          endpoint={"report/invoicing"}
          filenamePrefix="invoices"
          constantColumns={INVOICING_COLUMNS}
          convertDataToRows={(reportData) => this.convertDataToRows(reportData)}
          locationPicker={true}
          customParams={{
            SELECTED_CONTACTS_ARRAY: [...selectedContacts],
            SELECTED_STATUSES_ARRAY: [...selectedStatuses],
          }}
          customFields={
            <div>
              <ContactFilterDropdown
                ignoreMargin
                onChange={(selectedContacts) => this.setState({selectedContacts})}
              />

              <InvoiceStatusDropdown
                noMl
                className={"ml-2"}
                onChange={(selectedStatuses) => this.setState({selectedStatuses})}
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default InvoicingReportPage;

import {request} from "../request";
import {box, randomBytes} from "tweetnacl";
import {decodeUTF8, encodeBase64} from "tweetnacl-util";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import CryptoJS from "crypto-js";

function convertDictToUint8Array(data) {
  return new Uint8Array(
    Object.keys(data).reduce((accum, item) => {
      accum.push(data[item]);
      return accum;
    }, [])
  );
}

export async function encryptData(msgParams) {
  const receiverPublicKey = await request("encryption/publickey", "GET");
  const ephemeralKeyPair = box.keyPair();
  const symmetricKey = randomString(32);

  const ciphertext = CryptoJS.AES.encrypt(CryptoJS.lib.WordArray.create(msgParams), symmetricKey).toString();

  const pubKeyUInt8Array = convertDictToUint8Array(receiverPublicKey);

  const msgParamsUInt8Array = decodeUTF8(symmetricKey);
  const nonce = randomBytes(box.nonceLength);

  const encryptedMessage = box(msgParamsUInt8Array, nonce, pubKeyUInt8Array, ephemeralKeyPair.secretKey);

  return {
    symKey: encodeBase64(encryptedMessage),
    ephemPubKey: encodeBase64(ephemeralKeyPair.publicKey),
    ciphertext,
    nonce: encodeBase64(nonce),
  };
}

import React, {Component} from "react";
import {Modal, FormInput, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";
import * as Yup from "yup";
import {request} from "../../../utils/request";

const CASH_EVENT_TYPES = {
  Opened: 0,
  Closed: 1,
  Event: 2,
  Verify: 3,
};

class CashEventModal extends Component {
  state = {event: null, type: 0};

  open(event = null) {
    this.setState(
      {
        event,
        type: CASH_EVENT_TYPES[event?.TYPE ?? "Event"],
      },
      () => this.modal.open()
    );
  }

  async submitEvent({amount, notes, employeePin}, {setFieldError}) {
    const {event} = this.state;
    const {syncState} = this.props;

    const payload = {
      AMOUNT: decimalToDollars(amount),
      NOTES: notes,
      EMPLOYEE_PIN: employeePin,
    };

    try {
      await request("cashbox/event/" + event.ID, "PATCH", payload);
    } catch (e) {
      if (e.message === "EMPLOYEE_INVALID" || e.message === "Something went wrong validating fields") {
        setFieldError("employeePin", "Invalid pin.");
        this.modal.fetchModalButton().stopLoading();
      } else {
        setFieldError("employeePin", "Unknown error occurred.");
        this.modal.fetchModalButton().stopLoading();
      }

      return;
    }

    syncState();
    this.modal.close();
  }

  async submitOpen({amount, employeePin}, {setFieldError}) {
    const {event} = this.state;
    const {syncState} = this.props;

    const payload = {
      OPEN_EMPLOYEE_PIN: employeePin,
      AMOUNT_OPEN: decimalToDollars(amount),
    };

    try {
      await request("cashbox/open/" + event.CASHBOX_ID, "PATCH", payload);
    } catch (e) {
      if (e.message === "EMPLOYEE_INVALID" || e.message === "Something went wrong validating fields") {
        setFieldError("employeePin", "Invalid pin.");
        this.modal.fetchModalButton().stopLoading();
      } else {
        setFieldError("employeePin", "Unknown error occurred.");
        this.modal.fetchModalButton().stopLoading();
      }
      return;
    }

    syncState();
    this.modal.close();
  }

  async submitClose({amount, employeePin, amountRemaining}, {setFieldError}) {
    const {event} = this.state;
    const {syncState} = this.props;

    const payload = {
      CLOSE_EMPLOYEE_PIN: employeePin,
      AMOUNT_CLOSE: decimalToDollars(amount),
      CASH_REMAINING: decimalToDollars(amountRemaining),
    };

    try {
      await request("cashbox/close/" + event.CASHBOX_ID, "PATCH", payload);
    } catch (e) {
      console.log("ERROR", e);

      if (e.message === "EMPLOYEE_INVALID" || e.message === "Something went wrong validating fields") {
        setFieldError("employeePin", "Invalid pin.");
        this.modal.fetchModalButton().stopLoading();
      } else {
        setFieldError("employeePin", "Unknown error occurred");
        this.modal.fetchModalButton().stopLoading();
      }

      return;
    }

    syncState();
    this.modal.close();
  }

  async submitVerify({amount, employeePin, notes = null}, {setFieldError}) {
    const {event} = this.state;
    const {syncState} = this.props;

    const payload = {
      VERIFY_EMPLOYEE_PIN: employeePin,
      AMOUNT_VERIFIED: decimalToDollars(amount),
      VERIFIED_NOTES: notes,
    };

    try {
      await request("cashbox/verify/" + event.CASHBOX_ID, "PATCH", payload);
    } catch (e) {
      console.log("ERROR", e);

      if (e.message === "EMPLOYEE_INVALID" || e.message === "Something went wrong validating fields") {
        setFieldError("employeePin", "Invalid pin.");
        this.modal.fetchModalButton().stopLoading();
      } else if (e.message === "EMPLOYEE_NOT_ALLOWED_TO_VERIFY") {
        setFieldError("employeePin", "Not allowed to verify.");
        this.modal.fetchModalButton().stopLoading();
      } else {
        setFieldError("employeePin", "Unknown error occurred");
        this.modal.fetchModalButton().stopLoading();
      }

      return;
    }

    syncState();
    this.modal.close();
  }

  render() {
    const {event, type} = this.state;
    const {drawer} = this.props;
    const {settings} = this.props.shop;
    const {REQUIRE_EMPLOYEE_PIN_CASH_DRAWER_EVENT} = settings;
    const isPinRequired = REQUIRE_EMPLOYEE_PIN_CASH_DRAWER_EVENT === "1";
    const employeePin = this.props.user?.employee?.PIN;

    return (
      <Modal
        label={type === 3 ? "Verify Cash Event" : "Edit Cash Event"}
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={(values, helpers) => {
            if (type === CASH_EVENT_TYPES.Closed) {
              return this.submitClose(values, helpers);
            } else if (type === CASH_EVENT_TYPES.Opened) {
              return this.submitOpen(values, helpers);
            } else if (type === CASH_EVENT_TYPES.Verify) {
              return this.submitVerify(values, helpers);
            }
            return this.submitEvent(values, helpers);
          }}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            amount: event?.AMOUNT ? toDollars(event?.AMOUNT) : null,
            amountRemaining: drawer?.CASH_REMAINING ? toDollars(drawer?.CASH_REMAINING) : null,
            notes: event?.NOTES,
            employeePin: employeePin,
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.string().nullable().required(),
            notes: Yup.string().nullable(),
            employeePin: Yup.string()
              .nullable()
              .test(
                "test-employee-pin",
                "Employee PIN is required",
                (val) => !isPinRequired || (val && val.length > 0)
              ),
            amountRemaining: Yup.string()
              .nullable()
              .test(
                "test-amount-remaining",
                "Employee PIN is required",
                (val) => type !== CASH_EVENT_TYPES.Closed || (val && val.length > 0)
              ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name={"amount"} label={"Amount"} options={formikOptions} />

                {type === CASH_EVENT_TYPES.Closed && (
                  <FormInput
                    name={"amountRemaining"}
                    label={"Amount Remaining in Drawer"}
                    options={formikOptions}
                  />
                )}

                {(type === CASH_EVENT_TYPES.Event || type === CASH_EVENT_TYPES.Verify) && (
                  <FormTextArea name={"notes"} label={"Notes"} options={formikOptions} />
                )}

                {isPinRequired && type !== CASH_EVENT_TYPES.Verify && (
                  <FormInput name={"employeePin"} label={"Employee PIN"} options={formikOptions} />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(CashEventModal);

import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {Card, Loading, PageHeadings, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import ReceiptModal from "../../../../modals/sales/receipts/receipt-modal";
import ReceiptEditor from "../../../../features/sales/flow/receipt-editor";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import DuplicateReceiptModal from "../../../../modals/sales/receipts/duplicate-receipt-modal";

class ReceiptPage extends Component {
  state = {isUndo: false, receipt: null};

  componentDidMount() {
    const {ID: id} = this.props.router.params;

    request("receipts/" + id, "GET", null).then((receipt) => {
      this.setState({receipt});
    });
  }

  async deleteReceipt() {
    const {receipt} = this.state;

    showLoadingConfirmAlert("Delete Receipt", "Are you sure you want to delete this receipt?").then(
      async (close) => {
        await request("receipts/" + receipt.ID, "DELETE", {});

        close();

        this.props.router.navigate("/receipts");
        this.modal.close();
      }
    );
  }

  async saveReceipt() {
    const {receipt} = this.state;
    const serverReceipt = {
      CONTENT: JSON.stringify(this.editorRef.fetchReceipt()),
    };

    let serverCategory = await request("receipts/" + receipt.ID, "PATCH", serverReceipt);

    this.editorRef.selectBlock(null);

    showSuccessNotification("Receipt Saved", "The receipt has been saved");
  }

  render() {
    const {receipt, isUndo} = this.state;

    if (receipt === null) {
      return <Loading />;
    }

    const receiptButtons = [
      {
        label: "Undo Action",
        onClick: () => this.editorRef.undoAction(),
      },
      // {
      //   label: "Get State",
      //   onClick: () => console.log(JSON.stringify(this.editorRef.fetchReceipt())),
      // },
      {
        label: "Save Receipt",
        onClick: () => this.saveReceipt(),
      },
    ];

    if (!isUndo) {
      receiptButtons.splice(0, 1);
    }

    return (
      <div>
        <ReceiptModal ref={(e) => (this.receiptModal = e)} updateState={(e) => this.setState(e)} />

        <DuplicateReceiptModal ref={(e) => (this.duplicateModal = e)} />

        <PageHeadings
          label={receipt?.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [{label: "Duplicate", onClick: () => this.duplicateModal.open(receipt)}],
                },
                {
                  items: [{label: "Archive", onClick: () => this.deleteReceipt()}],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Checkout Flow", url: "/receipts"},
            {label: "Receipts", url: "/receipts"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.receiptModal.open(receipt),
            },
          ]}
          label="Receipt Details"
          description="Information about this receipt"
          data={[
            {label: "Name", value: receipt.NAME},
            {label: "Type", value: receipt.TYPE},
            {label: "Item Specific", value: receipt.ITEM_SPECIFIC, type: "bool"},
          ]}
        />

        <div className="mt-2" />

        <Card label="Edit Receipt" buttons={receiptButtons}>
          <ReceiptEditor
            ref={(e) => (this.editorRef = e)}
            receipt={JSON.parse(receipt.CONTENT)}
            setUndo={(isUndo) => this.setState({isUndo})}
            serverReceipt={receipt}
          />
        </Card>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ReceiptPage));

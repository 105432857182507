import {withRouter} from "../../../utils/navigation";
import React, {Component} from "react";
import {Card, PageHeadings, Tab, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {request, sanitizeName} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showConfirmAlert, showSuccessAlert} from "../../../utils/alert-helper";
import {setupReduxConnection} from "../../../redux";
import EmailSendTestModal from "../../../modals/marketing/campaigns/email-send-test-modal";
import TextSendTestModal from "../../../modals/marketing/campaigns/text-send-test-modal";
import BillingAccountModal from "../../../modals/operations/billing-accounts/billing-account-modal";
import BillingAccountPayoffModal from "../../../modals/operations/billing-accounts/billing-account-payoff-modal";
import {showSuccessNotification} from "../../../utils/notification-helper";

class BillingAccountPage extends Component {
  state = {account: null};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let accounts = await request("billing/", "GET", {});

    let account = accounts.filter((acc) => acc.UNIQUE_ID === ID)[0];

    this.setState({account});
  }

  async syncState() {
    const {ID} = this.props.router.params;

    let accounts = await request("billing/", "GET", {});

    let account = accounts.filter((acc) => acc.UNIQUE_ID === ID)[0];

    this.setState({account});
  }

  async archiveBillingAccount() {
    const {account} = this.state;

    await showConfirmAlert("Archive Account", "Are you sure you would like to archive this billing account?");

    await request("billing/" + account.ID, "DELETE");

    this.props.router.navigate("/billing-accounts");
  }

  async sendEmail(email, cc, bcc) {
    const {account} = this.state;

    await request("billing/" + account.ID + "/receipt", "POST", {
      EMAIL: email,
      CC: cc,
      BCC: bcc,
    });

    showSuccessAlert("Email Invoice Sent!");
  }

  async sendText(phone) {
    const {account} = this.state;

    await request("billing/" + account.ID + "/receipt", "POST", {
      PHONE: phone,
    });

    showSuccessAlert("Text Invoice Sent!");
  }

  renderTickets() {
    let {account} = this.state;

    return (
      <div>
        <Table
          data={account.TICKETS.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED)}
          actionButtons={[
            {
              label: <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />,
              onClick: (ticket) => this.props.router.navigateNewTab("/ticket/" + ticket.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              label: "Date Created",
              value: "DATE_CREATED",
              format: (value, row) => moment(value).format("M/D/YYYY h:mm A"),
            },
            {
              label: "Total",
              value: "",
              format: (value, row) => toDollars(row.PAYMENT_INTENT?.TOTAL, true),
            },
          ]}
        />
      </div>
    );
  }

  renderPayments() {
    let {account} = this.state;

    return (
      <div>
        <Table
          data={account.PAYMENTS.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate("/billing-accounts/payment/" + row.UNIQUE_ID);
              },
            },
          ]}
          columns={[
            {
              label: "Date Paid",
              value: "DATE_CREATED",
              format: (value) => moment(value).format("M/D/YYYY h:mm A"),
            },
            {
              label: "Type",
              value: "PAYMENT_TYPE",
              format: (type, row) => {
                switch (type) {
                  case "CARD_NOT_PRESENT":
                    return "Credit/Debit Card";
                  case "CASH":
                    return "Cash";
                  case "CHECK":
                    return "Check" + (row.SOURCE_CONTENT ? ` (#${row.SOURCE_CONTENT})` : "");
                  default:
                    return type;
                }
              },
            },
            {
              label: "TIP",
              value: "AMOUNT_TIP",
              type: "dollars",
            },
            {
              label: "Subtotal",
              value: "TOTAL",
              type: "dollars",
            },
            {
              label: "Amount",
              value: "TOTAL",
              format: (value, row) => toDollars(value + row.AMOUNT_TIP, true),
            },
          ]}
          pagination
        />
      </div>
    );
  }

  renderInvoices() {
    let {account} = this.state;

    return (
      <div>
        <Table
          data={account.INVOICES.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED)}
          columns={[
            {
              label: "Date Sent",
              value: "DATE_CREATED",
              format: (value) => moment(value).format("M/D/YYYY h:mm A"),
            },
            {
              label: "Channel",
              value: "PHONE",
              format: (_, row) => (row.PHONE ? "Phone" : "Email"),
            },
            {
              label: "Destination",
              value: "PHONE",
              format: (_, row) => (row.PHONE ? getFormattedPhone(row.PHONE) : row.EMAIL),
            },
          ]}
        />
      </div>
    );
  }

  render() {
    let {account} = this.state;
    let {SLUG: locationSlug, NAME} = this.props.shop.location;

    if (!locationSlug) {
      locationSlug = sanitizeName(NAME);
    }

    if (!account) {
      return <LoadingSpinner />;
    }

    let buttons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [],
      },
    ];

    if (account?.TOTAL === 0) {
      buttons[0].sections.push({
        items: [
          {
            label: "Archive Account",
            onClick: () => this.archiveBillingAccount(),
          },
        ],
      });
    } else if (account?.TOTAL > 0) {
      buttons[0].sections.push(
        {
          items: [
            {
              label: "Mark as Paid",
              onClick: () => this.payoffModal.open(),
            },
          ],
        },
        {
          items: [
            {
              label: "Copy Payment Link",
              onClick: () => {
                navigator.clipboard
                  .writeText(`https://order.dripos.com/${locationSlug}/pay/${account.UNIQUE_ID}`)
                  .then(() => {
                    showSuccessNotification("Payment Link Copied!");
                  });
              },
            },
          ],
        },
        {
          items: [
            {
              label: "Send Invoice Text",
              onClick: () => this.textModal.open(),
            },
          ],
        },
        {
          items: [
            {
              label: "Send Invoice Email",
              onClick: () => this.emailModal.open(),
            },
          ],
        }
      );
    }

    return (
      <>
        <BillingAccountModal
          ref={(e) => (this.billingModal = e)}
          updateState={(serverAccount) => {
            this.setState({account: {...account, ...serverAccount}});
          }}
        />

        <EmailSendTestModal
          ref={(e) => (this.emailModal = e)}
          showCC
          label="Send Billing Invoice"
          sendEmail={(email, cc, bcc) => {
            return this.sendEmail(email, cc, bcc).then(() => this.syncState());
          }}
        />

        <TextSendTestModal
          ref={(e) => (this.textModal = e)}
          label="Send Billing Invoice"
          sendText={(phone) => {
            return this.sendText(phone).then(() => this.syncState());
          }}
        />

        <BillingAccountPayoffModal
          billingAccount={account}
          ref={(e) => {
            this.payoffModal = e;
          }}
          updateState={() => {
            this.syncState();
          }}
        />

        <PageHeadings
          label={"Billing Account"}
          description={"View and edit this billing account"}
          buttons={buttons}
        />

        <Card>
          <TwoColumnList
            label={"Billing Account Details"}
            button={{
              label: "Edit Information",
              onClick: () => {
                this.billingModal.open(account);
              },
            }}
            data={[
              {
                label: "Name",
                value: account.NAME,
              },
              {
                label: "Account PIN",
                value: account.PIN,
              },
              {
                label: "Phone",
                value: getFormattedPhone(account.PHONE),
              },
              {
                label: "Outstanding Balance",
                value: toDollars(account.TOTAL, true),
              },
              {
                label: "Pay Link",
                value: (
                  <a
                    href={`https://order.dripos.com/${locationSlug}/pay/${account.UNIQUE_ID}`}
                    className="text-indigo-600 font-semibold hover:text-indigo-800 hover:cursor-pointer"
                  >
                    Click Here
                  </a>
                ),
              },
            ]}
          />
        </Card>

        <Tab
          className="mt-2"
          data={[
            {label: "Unpaid Tickets", id: "tickets"},
            {label: "Payments", id: "payments"},
            {label: "Invoices", id: "invoices"},
          ]}
        >
          {(id) => {
            if (id === "tickets") {
              return this.renderTickets();
            } else if (id === "payments") {
              return this.renderPayments();
            } else if (id === "invoices") {
              return this.renderInvoices();
            }
          }}
        </Tab>
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(BillingAccountPage));

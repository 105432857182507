import React, {Component} from "react";
import {Modal, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {isDefaultGiftCardImage} from "../../../utils/marketing-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";

class GiftCardReorderImagesModal extends Component {
  state = {giftCardImages: []};

  open() {
    const {giftCardImages} = this.props.shop;

    const gcState = JSON.parse(JSON.stringify(giftCardImages));

    this.setState({giftCardImages: gcState});

    this.modal.open();
  }

  close() {
    this.modal.close();
  }

  async saveImages() {
    const {giftCardImages} = this.state;

    await request("cards/images", "PUT", {
      IMAGES: giftCardImages,
    });

    const serverImages = await request("cards/images", "GET", null);

    this.props.updateShop({giftCardImages: serverImages});

    this.modal.close();
  }
  render() {
    let {giftCardImages} = this.state;

    giftCardImages = giftCardImages
      .filter(({DATE_ARCHIVED}) => !DATE_ARCHIVED)
      .sort((a, b) => {
        return a.SEQ - b.SEQ;
      });

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        closeLabel="Close"
        label="Customize Gift Cards"
        description="Drag and drop to choose your desired order"
        large
        full
        className="px-5"
        buttonLabel="Save"
        buttonOnClick={() => this.saveImages()}
      >
        <Table
          columns={[
            {
              label: "Icon",
              value: "LOGO",
              format: (LOGO) => {
                return <img width="50" height="29" src={getObjectImage({LOGO}, "LOGO")} />;
              },
            },
            {
              label: "Type",
              value: "LOGO",
              format: (_, row) => {
                if (isDefaultGiftCardImage(row)) {
                  return "Default";
                } else {
                  return "Custom";
                }
              },
            },
            {
              label: "Enabled",
              value: "ENABLED",
              format: (enabled, row) => {
                return (
                  <input
                    type="checkbox"
                    className="hover: cursor-pointer"
                    checked={enabled}
                    onClick={() => {
                      giftCardImages.find((_row) => _row.LOGO === row.LOGO).ENABLED = !enabled;

                      this.setState({giftCardImages});
                    }}
                  />
                );
              },
            },
            {
              label: "",
              value: "LOGO",
              width: 4,
              format: (_, row) => {
                if (isDefaultGiftCardImage(row)) {
                  return <div></div>;
                }

                return (
                  <FontAwesomeIcon
                    icon="fa-regular fa-trash"
                    color="red"
                    onClick={() => {
                      showLoadingConfirmAlert(
                        "Remove Card Image?",
                        "Are you sure you would like to remove this gift card image?"
                      )
                        .then((close) => {
                          const image = giftCardImages.find((_row) => _row.LOGO === row.LOGO);

                          image.ENABLED = false;
                          image.DATE_ARCHIVED = Date.now();

                          this.setState({giftCardImages});

                          close();
                        })
                        .catch(() => {});
                    }}
                  />
                );
              },
            },
          ]}
          data={giftCardImages}
          draggable
          outRef={this.modal}
        />
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(GiftCardReorderImagesModal);

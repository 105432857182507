import React, {Component} from "react";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import Wizard from "../../../components/wizard";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import PolicyTypeForm from "../../../forms/team/policies/policy-type-form";
import PolicyDetailsForm from "../../../forms/team/policies/policy-details-form";
import PolicyLimitsForm from "../../../forms/team/policies/policy-limits-form";
import TimeOffCard from "../../../features/team/time-off-card";

class CreatePolicyPage extends Component {
  state = {isLoading: false, policy: null, unlimited: false};

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const {ID: id} = this.props.router.params;

    if (id) {
      const policy = await request("timeoff/policies/" + id, "GET");

      return this.setState({isLoading: false, unlimited: policy.UNLIMITED, policy}, () => {
        this.wizardRef.resetForm();
      });
    }

    this.setState({isLoading: false});
  }

  async createPolicy({details, method, limits}) {
    const serverPolicy = await request("timeoff/policies", "POST", {
      ...details,
      ...method,
      ...limits,
    });

    this.props.router.navigate("/policy/" + serverPolicy.UNIQUE_ID);
  }

  async savePolicy({details, method, limits}) {
    const {policy} = this.state;

    const serverPolicy = await request("timeoff/policies/" + policy.ID, "PATCH", {
      ...details,
      ...method,
      ...limits,
    });

    this.props.router.navigate("/policy/" + serverPolicy.UNIQUE_ID);
  }

  render() {
    const {isLoading, policy, unlimited} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const initialValues = {
      details: {},
      method: {METHOD: "FIXED"},
      limits: {},
    };

    if (policy) {
      initialValues.details = {
        NAME: policy.NAME,
        TYPE: policy.TYPE,
        CATEGORIES: policy.CATEGORIES,
        DESCRIPTION: policy.DESCRIPTION,
        PAID_DISMISSAL: policy.PAID_DISMISSAL,
        REVIEW_REQUESTS: policy.REVIEW_REQUESTS,
        UNLIMITED: policy.UNLIMITED,
        PAID: policy.PAID,
      };

      initialValues.method = {
        METHOD: policy.METHOD,
        AMOUNT: policy.AMOUNT,
        EARN_METHOD: policy.EARN_METHOD,
      };

      initialValues.limits = {
        MAX_HOURS: policy.MAX_HOURS,
        MAX_BALANCE: policy.MAX_BALANCE,
        MAX_CARRY_OVER: policy.MAX_CARRY_OVER,
        WAITING_PERIOD_ACCRUE: policy.WAITING_PERIOD_ACCRUE,
        WAITING_PERIOD_REQUEST: policy.WAITING_PERIOD_REQUEST,
      };
    }

    const steps = [
      {
        id: 0,
        name: "Policy details",
        description: "General information about the policy",
        render: ({values}, {handleSubmit, setupRef}) => {
          return (
            <PolicyDetailsForm
              ref={(e) => setupRef(e)}
              handleSubmit={handleSubmit}
              updateUnlimited={(unlimited) => this.setState({unlimited})}
              details={values?.details}
            />
          );
        },
      },
    ];

    if (!unlimited) {
      steps.push(
        {
          id: 1,
          name: "Accrual Details",
          visible: ({details}) => parseInt(details?.UNLIMITED) !== 1,
          description: "How are time off hours accumulated",
          render: ({values}, {handleSubmit, setupRef}) => {
            return (
              <PolicyTypeForm ref={(e) => setupRef(e)} handleSubmit={handleSubmit} method={values?.method} />
            );
          },
        },
        {
          id: 2,
          name: "Policy limits",
          visible: ({details}) => parseInt(details?.UNLIMITED) !== 1,
          description: "Set specific limitations on when the employee can request time off",
          render: ({values}, {handleSubmit, setupRef}) => {
            return (
              <PolicyLimitsForm
                ref={(e) => setupRef(e)}
                handleSubmit={handleSubmit}
                limits={values?.limits}
                method={values?.method}
              />
            );
          },
        }
      );
    }

    steps.push({
      id: steps.length === 1 ? 1 : 3,
      name: "Preview",
      description: "Confirm the details of the policy",
      render: ({values}) => {
        const {details, method, limits} = values;

        return (
          <>
            <TimeOffCard policy={{...details, ...method, ...limits}} />
          </>
        );
      },
    });

    return (
      <Wizard
        onQuit={() =>
          policy
            ? this.props.router.navigate("/policy/" + policy.UNIQUE_ID)
            : this.props.router.navigate("/policies")
        }
        initialValues={initialValues}
        submitLabel={policy ? "Save" : "Submit"}
        ref={(e) => (this.wizardRef = e)}
        steps={steps}
        onSubmit={policy ? this.savePolicy.bind(this) : this.createPolicy.bind(this)}
      />
    );
  }
}

export default withRouter(CreatePolicyPage);

import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import CompanyDocumentsDropdown from "../../../../dropdowns/team/onboarding/company-documents-dropdown";
import {request} from "../../../../utils/request";
import ComboBox from "../../../../components/combobox";

class OnboardingTaskModal extends Component {
  state = {task: null, seq: -1, options: []};

  open(task = null, seq = -1) {
    this.setState({seq, task, options: []}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  renderContent(formikOptions) {
    const {values} = formikOptions;

    if (values.type === "CUS_FORM") {
      return (
        <CompanyDocumentsDropdown
          updateState={(task) => this.setState({options: [task]})}
          options={formikOptions}
          name={"document"}
        />
      );
    } else if (values.type === "TEMPLATE") {
      return (
        <ComboBox
          label={"Template"}
          data={[
            {id: "I-9", label: "I-9"},
            {id: "W4", label: "W4"},
          ]}
          options={formikOptions}
          name={"template"}
          onChangeSoft={(e) => this.setState({options: [{NAME: e.id, SEQ: e.id}]})}
        />
      );
    } else if (values.type === "VIDEO") {
      return (
        <FormInput
          label={"Video URL"}
          name={"videoUrl"}
          options={formikOptions}
          onChangeSoft={(val) => {
            this.setState({
              options: [
                {
                  NAME: val.replace("/watch?v=", "/embed/"),
                  SEQ: 0,
                },
              ],
            });
          }}
        />
      );
    }
  }

  async deleteTask() {
    const {task} = this.state;
    const {checklist} = this.props;

    const serverLine = await request("checklists/" + checklist.ID + "/item/" + task.ID, "DELETE", {});

    this.props.updateState(serverLine.ID);
    this.modal.close();
  }

  async saveTask({name, type}) {
    const {task, options} = this.state;
    const {checklist} = this.props;

    type = type === "TEMPLATE" ? name : type;

    const taskPayload = {
      NAME: name,
      TYPE: type,
      OPTIONS: options,
      SEQ: task.SEQ,
    };

    let serverLine;

    if (type === "I-9") {
      serverLine = await request("checklists/" + checklist.ID + "/item/" + task.ID, "PATCH", {
        NAME: "I-9 Part 1",
        TYPE: "I-9_P1",
        OPTIONS: options,
        SEQ: task.SEQ,
      });

      this.props.updateState(serverLine.ID, serverLine);

      serverLine = await request("checklists/" + checklist.ID + "/item/" + task.ID, "PATCH", {
        NAME: "I-9 Part 2",
        TYPE: "I-9_P2",
        OPTIONS: options,
        SEQ: task.SEQ,
      });

      this.props.addState(serverLine);
    } else if (type === "W4") {
      serverLine = await request("checklists/" + checklist.ID + "/item/" + task.ID, "PATCH", {
        NAME: "W-4 Employee",
        TYPE: "W4_EMP",
        OPTIONS: options,
        SEQ: task.SEQ,
      });

      serverLine = await request("checklists/" + checklist.ID + "/item/" + task.ID, "PATCH", {
        NAME: "W-4 Company",
        TYPE: "W4_COMP",
        OPTIONS: options,
        SEQ: task.SEQ,
      });
    } else {
      serverLine = await request("checklists/" + checklist.ID + "/item/" + task.ID, "PATCH", taskPayload);
    }

    this.props.updateState(serverLine.ID, serverLine);
    this.modal.close();
  }

  async createTask({name, type}) {
    const {seq, options} = this.state;
    const {checklist} = this.props;
    type = type === "TEMPLATE" ? options[0].NAME : type;

    let serverLine;

    if (type === "I-9") {
      serverLine = await request("checklists/" + checklist.ID + "/item/", "POST", {
        NAME: "I-9 Part 1",
        TYPE: "I9_P1",
        OPTIONS: options,
        SEQ: seq,
      });

      this.props.addState(serverLine);

      serverLine = await request("checklists/" + checklist.ID + "/item/", "POST", {
        NAME: "I-9 Part 2",
        TYPE: "I9_P2",
        OPTIONS: options,
        SEQ: seq,
      });
    } else if (type === "W4") {
      serverLine = await request("checklists/" + checklist.ID + "/item/", "POST", {
        NAME: "W4 Employee",
        TYPE: "W4_EMP",
        OPTIONS: options,
        SEQ: seq,
      });

      this.props.addState(serverLine);

      serverLine = await request("checklists/" + checklist.ID + "/item/", "POST", {
        NAME: "W4 Company",
        TYPE: "W4_COMP",
        OPTIONS: options,
        SEQ: seq,
      });
    } else {
      const taskPayload = {
        NAME: name,
        TYPE: type,
        OPTIONS: options,
        SEQ: seq,
      };

      serverLine = await request("checklists/" + checklist.ID + "/item/", "POST", taskPayload);
    }

    this.props.addState(serverLine);
    this.modal.close();
  }

  render() {
    const {task} = this.state;

    return (
      <Modal
        deleteLabel={task && "Delete"}
        deleteOnClick={() => this.deleteTask()}
        buttonLabel={task ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        label={task ? "Edit Task" : "Create Task"}
      >
        <Formik
          onSubmit={task ? this.saveTask.bind(this) : this.createTask.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: task?.NAME ?? "",
            type: task?.TYPE ?? "I-9",
            options: task?.OPTIONS ?? [],
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            const data = [
              {value: "TEMPLATE", label: "Template"},
              {value: "CUS_FORM", label: "Company Form"},
              {value: "VIDEO", label: "Video"},
            ];

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" options={formikOptions} />

                <FormSelect name="type" label="Type" options={formikOptions} data={data} />

                {this.renderContent(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default OnboardingTaskModal;

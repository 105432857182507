import React, {Component} from "react";
import Wizard from "../../../components/wizard";
import {Card, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import RolePermissions from "../../../features/team/role-permissions";
import RoleForm from "../../../forms/team/roles/role-form";
import RolePermissionForm from "../../../forms/team/roles/role-permission-form";
import RoleRatesForm from "../../../forms/team/roles/role-rates-form";
import PayRatesTable from "../../../tables/team/pay-rates-table";
import {APPROVAL_NOTIFICATIONS} from "../../../modals/team/roles/role-modal";

class CreateRolePage extends Component {
  async submitRole({role, permissions, rates}) {
    let employeePayload = role;
    employeePayload.PERMISSIONS = permissions;

    let newRoleId = await request("employeeroles/roles", "POST", employeePayload);

    const {ROLE: serverRole} = await request("employeeroles/role/" + newRoleId, "GET");

    for (let rate of rates) {
      await request("pay/rates", "POST", {...rate, ROLE_ID: serverRole.ID});
    }

    this.props.router.navigate("/role/" + serverRole.UNIQUE_ID);
  }

  render() {
    return (
      <Wizard
        ref={(e) => (this.wizardRef = e)}
        onSubmit={this.submitRole.bind(this)}
        onQuit={() => this.props.router.navigate("/roles")}
        steps={[
          {
            id: 0,
            name: "Basic information",
            description: "Simple information about the role.",
            render: ({values}, {handleSubmit, setupRef}) => {
              return <RoleForm ref={(e) => setupRef(e)} handleSubmit={handleSubmit} role={values?.role} />;
            },
          },
          {
            id: 1,
            name: "Permissions",
            description: "Set the default permissions of employees with this role.",
            render: ({values}, {handleSubmit, setupRef}) => (
              <RolePermissionForm
                ref={(e) => setupRef(e)}
                handleSubmit={handleSubmit}
                permissions={values?.permissions}
              />
            ),
          },
          {
            id: 2,
            name: "Pay Rates",
            description: "Set the default pay rate for employees with this role.",
            render: ({values}, {handleSubmit, setupRef}) => (
              <RoleRatesForm ref={(e) => setupRef(e)} handleSubmit={handleSubmit} rates={values?.rates} />
            ),
          },
          {
            id: 3,
            name: "Preview",
            description: "Confirm role creation.",
            render: ({values}) => {
              const {permissions, role, rates} = values;

              return (
                <>
                  <TwoColumnList
                    label="Role Summary"
                    description="Lets take a quick look before we create this new role"
                    data={[
                      {label: "Name", value: role.NAME},
                      {label: "Color", value: role.COLOR, type: "color"},
                      {
                        label: "Tips Disabled",
                        value: role.TIPS_DISABLED,
                        type: "bool",
                      },
                      {
                        label: "Notify of Trade and Time Off Requests",
                        value: APPROVAL_NOTIFICATIONS.find((item) => item.id === role.APPROVAL_NOTIFICATIONS)
                          ?.label,
                      },
                      {
                        label: "Notify of Late Clock Ins",
                        value: APPROVAL_NOTIFICATIONS.find((item) => item.id === role.CLOCK_NOTIFICATIONS)
                          ?.label,
                      },
                    ]}
                  />

                  <Card
                    label="Permissions"
                    button={{
                      label: "Edit Information",
                      onClick: () => this.wizardRef.setStep(1),
                    }}
                  >
                    <RolePermissions permissions={permissions} read />
                  </Card>

                  <Card
                    label="Pay Rates"
                    button={{
                      label: "Edit Information",
                      onClick: () => this.wizardRef.setStep(2),
                    }}
                  >
                    <PayRatesTable rates={rates} />
                  </Card>
                </>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withRouter(CreateRolePage);

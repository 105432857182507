import React, {Component} from "react";
import {withRouter} from "../../../../utils/navigation";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import VendorModal from "../../../../modals/operations/supply-chain/vendors/vendor-modal";
import {VendorRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showAlert, showLoadingConfirmAlert, showSuccessAlert} from "../../../../utils/alert-helper";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import {updateVendors} from "../../../../redux/supply";
import {getStore, setupReduxConnection} from "../../../../redux";

class SupplyChainVendorsPage extends Component {
  state = {hasWaited: false};
  render() {
    const {hasWaited} = this.state;
    const {vendors} = this.props.supply;

    if (vendors.length === 0 && !hasWaited) {
      setTimeout(() => {
        this.setState({hasWaited: true});
      }, 5000);
      return <Loading />;
    }

    const nameColumn = {value: "NAME", label: "Name"};
    const addressColumn = {
      value: "ADDRESS",
      label: "Address",
      format: (STREET, {CITY, STATE, ZIPCODE}) => {
        return [STREET, CITY, STATE, ZIPCODE].filter((it) => it !== null).join(", ");
      },
    };

    const createVendorButton = {
      label: "Create Vendor",
      onClick: () => this.vendorModal.open(),
    };

    const trashVendor = {
      label: <FontAwesomeIcon icon="fa-regular fa-trash" color={"red"} />,
      onClick: async (vendor) => {
        try {
          const close = await showLoadingConfirmAlert(
            `Delete Vendor: ${vendor.NAME}`,
            "Are you sure you want to delete this vendor? This cannot be undone and may impact " +
              "old and active purchase orders, ingredient defaults, and other Vendor related items."
          );
          await VendorRequests.deleteVendor(vendor.ID);
          await getStore().dispatch(updateVendors());
          close();
          await showSuccessNotification(`Vendor ${vendor.NAME} successfully deleted.`);
        } catch (e) {
          await showSuccessNotification(`Vendor ${vendor.NAME} not deleted.`);
        }
      },
    };

    const viewVendor = {
      label: "View",
      onClick: (row) => {
        this.props.router.navigate("/vendor/" + row.UNIQUE_ID);
      },
    };

    return (
      <div>
        <VendorModal
          ref={(e) => (this.vendorModal = e)}
          syncState={() => {
            getStore().dispatch(updateVendors());
          }}
        />

        <PageHeadings
          label="Vendors"
          description="View all of your Supply Chain Vendors"
          button={createVendorButton}
        />

        <Table
          data={vendors}
          className="mt-4"
          columns={[nameColumn, addressColumn]}
          actionButtons={[trashVendor, viewVendor]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainVendorsPage));

import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import {IngredientCategoryRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";

class GroupIngredientModal extends Component {
  state = {group: null};

  open(group = null) {
    this.setState({group}, () => this.modal.open());
  }

  addGroupIngredient({ingredient}) {
    const {syncState} = this.props;
    const {group} = this.state;

    IngredientCategoryRequests.addCategoryIngredient(group?.ID, {
      INGREDIENT_ID: ingredient,
    })
      .then((ingredient) => {
        showSuccessNotification("Ingredient Added Successfully.");
      })
      .catch((err) => {
        showErrorNotification(
          "Something went wrong.",
          "Something went wrong adding the ingredient. Please try again."
        );
      })
      .finally(() => {
        syncState();
        this.modal && this.modal.close();
      });
  }

  fetchInitialValues = () => {
    return {
      ingredient: null,
    };
  };

  render() {
    return (
      <Modal
        buttonLabel={"Add"}
        label={"Add Ingredient"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={(values) => this.addGroupIngredient(values)}
          initialValues={this.fetchInitialValues()}
        >
          {(formikOptions) => {
            return (
              <form>
                <IngredientDropdown options={formikOptions} name="ingredient" label="Ingredient" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default GroupIngredientModal;

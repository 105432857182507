import {FormBoolean, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import FormDaySelect from "../../../components/form-day-select";
import {SHIFT_VIEWS_DATA} from "../../../features/scheduling/scheduler";

class SchedulerTabModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {view, confirmShifts, duration, startDay, openShiftApproval, email, oneTimeUnavailability} = values;
    const {SCHEDULER_FIRST_DAY: prevFirstDaySetting} = this.props.shop.settings;

    let payload = {
      SCHEDULER_DEFAULT_VIEW: view,
      SCHEDULER_SHIFT_CONFIRMATION: confirmShifts,
      SCHEDULER_DURATION_DISPLAY: duration,
      SCHEDULER_FIRST_DAY: startDay,
      EMAIL_SCHEDULE: email,
      SCHEDULER_OPEN_SHIFT_APPROVAL: openShiftApproval,
      ONE_TIME_UNAVAILABILITY: oneTimeUnavailability,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});

    if (prevFirstDaySetting !== startDay && this.props.fetchSchedule) {
      this.props.fetchSchedule();
    }

    this.modal.close();
  };

  render() {
    let {
      SCHEDULER_DEFAULT_VIEW,
      SCHEDULER_SHIFT_CONFIRMATION,
      SCHEDULER_DURATION_DISPLAY,
      SCHEDULER_FIRST_DAY,
      SCHEDULER_OPEN_SHIFT_APPROVAL,
      EMAIL_SCHEDULE,
      ONE_TIME_UNAVAILABILITY,
    } = this.props.shop.settings;

    return (
      <Modal
        label="Scheduler Settings"
        tooltip={{
          data: "Configure scheduler settings for the currently signed-in location.",
        }}
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.handleSubmit}
          initialValues={{
            view: SCHEDULER_DEFAULT_VIEW,
            confirmShifts: SCHEDULER_SHIFT_CONFIRMATION,
            duration: SCHEDULER_DURATION_DISPLAY,
            startDay: SCHEDULER_FIRST_DAY,
            openShiftApproval: SCHEDULER_OPEN_SHIFT_APPROVAL,
            email: EMAIL_SCHEDULE,
            oneTimeUnavailability: ONE_TIME_UNAVAILABILITY,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name="view"
                  label="Default to Role View on the Scheduler Page"
                  options={formikOptions}
                  data={SHIFT_VIEWS_DATA}
                  tooltip={
                    "This setting allows you to set the default view of the scheduler. You can still change between these views from the scheduler with the dropdown selection."
                  }
                />

                <FormBoolean
                  name="confirmShifts"
                  label="Enable Employee Shift Confirmation"
                  options={formikOptions}
                  tooltip={
                    "If this setting is enabled, employees will receive a confirmation prompt when new shifts are published and acknowledged shifts will appear on the scheduler with a checkmark."
                  }
                />

                <FormDaySelect
                  name="startDay"
                  label="First Day of the Week"
                  options={formikOptions}
                  tooltip={"The day that appears as the first day of the week on the scheduler."}
                />

                <FormBoolean
                  name="duration"
                  label="Duration Display on Shifts"
                  options={formikOptions}
                  tooltip={"When enabled, shifts on the scheduler will display duration in hours"}
                />

                <FormBoolean
                  name="openShiftApproval"
                  label="Enable Approval for Open Shifts"
                  options={formikOptions}
                  tooltip={"When enabled, picking up open shifts will require manager approval"}
                />

                <FormBoolean
                  name="email"
                  label="Weekly Schedule Emails"
                  options={formikOptions}
                  tooltip={
                    "When enabled, employees will be sent a recurring weekly schedule email with upcoming shifts and open shifts"
                  }
                />

                <FormBoolean
                  name="oneTimeUnavailability"
                  label="One Time Unavailability"
                  options={formikOptions}
                  tooltip={
                    "When enabled, employees will be able to create unavailability and shift preferences for a specific date"
                  }
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(SchedulerTabModal);

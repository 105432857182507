import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import ImageFormDropZone from "../../components/image-form-drop-zone";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";

class ShopImagesModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  handleSubmit = async (values) => {
    let {icon, logo} = values;

    let payload = {
      ICON: icon.trim().length > 0 ? icon : "appicon.png",
      LOGO: logo.trim().length > 0 ? logo : "appicon.png",
    };

    await request("location", "PATCH", payload);

    let {location} = this.props.shop;

    location = {...location, ...payload};
    this.props.updateLocation(location);

    this.modal.close();
  };

  render() {
    let {ICON, LOGO} = this.props.shop.location;

    return (
      <Modal
        buttonLabel="Save"
        label={"Shop Images"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{icon: ICON, logo: LOGO}}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <ImageFormDropZone label="Logo" options={formikOptions} name="icon" />

                <ImageFormDropZone label="Shop Image" options={formikOptions} name="logo" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(ShopImagesModal);

import React, {Component} from "react";
import {FormPhone, Modal, FormInput, FormEmail} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import {showConfirmAlert, showErrorAlert, showLoadingConfirmAlert} from "../../../../utils/alert-helper";

class CompanyModal extends Component {
  state = {isExisting: true, partner: null};

  open(partner = null) {
    this.setState({isExisting: true, partner}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createPartner({name, email, phone}) {
    const {isExisting} = this.state;
    const {addState, location} = this.props;

    phone = "1" + phone.replaceAll(" ", "");

    try {
      const accountPayload = {
        PHONE: phone,
      };

      let serverPartner = null;

      if (!isExisting) {
        serverPartner = await request("admin/dashboard/location/" + location.ID + "/partners", "POST", {
          PHONE: phone,
          FULL_NAME: name,
          EMAIL: email,
        });
      } else {
        serverPartner = await request(
          "admin/dashboard/location/" + location.ID + "/partners",
          "POST",
          accountPayload
        );
      }

      this.modal.close();

      addState && addState(serverPartner);
    } catch (e) {
      if (e.error === "ACCOUNT_INVALID") {
        this.setState({isExisting: false});
      }

      this.modal.stopLoading();
    }
  }

  deletePartner() {
    const {partner} = this.state;
    const {updateState, location} = this.props;

    showLoadingConfirmAlert(
      "Remove Partner",
      "Are you sure you want to remove " + partner.FULL_NAME + " as a partner?"
    ).then(async (close) => {
      await request(`admin/partner/${location.ID}/${partner.ACCOUNT_ID}`, "DELETE", null);

      close();

      updateState && updateState(partner.ID);

      this.modal.close();
    });
  }

  render() {
    const {isExisting, partner} = this.state;

    const isFullShowing = !isExisting || partner;

    return (
      <Modal
        buttonDisabled={!!partner}
        deleteLabel={partner && "Remove"}
        buttonLabel={partner ? "Save" : "Add"}
        label={partner ? "Edit Partner" : "Create Partner"}
        deleteOnClick={this.deletePartner.bind(this)}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.createPartner.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            phone: Yup.string().required("Phone is required"),
          })}
          initialValues={{
            name: partner?.FULL_NAME ?? "",
            phone: partner?.PHONE ?? "",
            email: partner?.EMAIL ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {isFullShowing && (
                  <>
                    <FormInput
                      disabled={isFullShowing && isExisting}
                      options={formikOptions}
                      label="Full Name"
                      name="name"
                    />

                    <FormInput
                      disabled={isFullShowing && isExisting}
                      options={formikOptions}
                      label="Email"
                      name="email"
                    />
                  </>
                )}

                <FormPhone disabled={isFullShowing} options={formikOptions} label="Phone" name="phone" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

CompanyModal.propTypes = {
  addState: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withRouter(CompanyModal);

import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {Filter, Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import ModifierModal from "../../../../modals/sales/modifiers/modifier-modal";
import {updateStateDelegator} from "../../../../utils/util";

const MODIFIER_TYPE = {
  0: "Single Select",
  1: "Single Select w/ Quantity",
  2: "Multi-Select",
  3: "Multi-Select w/ Quantity",
  4: "Multi-Select w/ Fixed Price",
  5: "Pricing Modifier",
};

class ModifiersPage extends Component {
  state = {isLoading: true, data: []};

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const serverPresets = await request("modifiers/presets", "GET");

    for (const preset of serverPresets) {
      if (preset.OPTIONS) {
        preset.OPTIONS.sort((a, b) => (a.SEQ || 1) - (b.SEQ || 1));
      }
    }

    this.setState({isLoading: false, data: serverPresets});
  }

  render() {
    const {isLoading, data} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <ModifierModal
          globalMod
          ref={(e) => (this.modifierModal = e)}
          addState={(modifier) => this.setState({data: [...data, modifier]})}
          updateState={(id, modifier) => {
            this.setState({data: updateStateDelegator(data, id, modifier)});
          }}
        />

        <PageHeadings
          label="Modifiers"
          description="Create modifiers that can be used across menu items"
          button={{
            theme: "primary",
            label: "Create Modifier",
            icon: "plus",
            type: "list",
            onChange: ({id}) => {
              this.modifierModal.open(
                {
                  ID: null,
                  NAME: "",
                  INTERNAL_NAME: "",
                  MAX_SELECTIONS: 0,
                  MIN_SELECTIONS: 0,
                  TYPE: id === "pricing" ? 5 : 0,
                  DEFAULT_OPTION: null,
                  REQUIRED: 1,
                  OPTIONS: [],
                  ENABLED: 1,
                  PRESET: 1,
                },
                null,
                true
              );
            },
            items: [
              {
                id: "general",
                label: "Add-On",
                description: "A modifier that adds onto the base price of the product.",
              },
              {
                id: "pricing",
                label: "Pricing",
                description: "A modifier that alters the default pricing of a product.",
              },
            ],
          }}
        />

        <Filter searchable searchParams className="mt-4" data={[]}>
          {(filters, search) => {
            return (
              <Table
                sortable
                pagination
                data={data}
                className="mt-4"
                search={search}
                defaultSort="NAME"
                searchFields={["NAME"]}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "Edit",
                    onClick: (row) => this.modifierModal.open(row),
                  },
                  {
                    label: "View",
                    onClick: (row) => this.props.router.navigate("/modifier/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "NAME",
                    label: "Name",
                    sortable: true,
                    sortFunction: (a, b) => {
                      if (!a?.FULL_NAME || b.FULL_NAME) {
                        return 0;
                      }
                      return a?.FULL_NAME > b?.FULL_NAME ? -1 : 1;
                    },
                    format: (value, row) => {
                      return (
                        <div className="flex items-center">
                          <div>
                            <div className="text-sm font-medium text-gray-900">{row.NAME}</div>

                            <div className="text-sm text-gray-500" />
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    value: "TYPE",
                    label: "Type",
                    format: (type) => MODIFIER_TYPE[parseInt(type)],
                  },
                  {
                    value: "OPTIONS",
                    label: "Options",
                    sortable: false,
                    format: (value) => {
                      if (value.length === 0) {
                        return "No options";
                      }

                      return value.map((item) => item.NAME).join(", ");
                    },
                  },
                ]}
              />
            );
          }}
        </Filter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ModifiersPage));

import React, {Component} from "react";
import {AdvancedFilter, Card, Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";
import {showErrorAlert} from "../../../utils/alert-helper";
import moment from "moment";
import Banner from "../../../components/banner";

class PayoutsPage extends Component {
  state = {loading: true, nextPayout: null, payouts: null};

  async componentDidMount() {
    const {NEXT_PAYOUT} = await request("payouts/totals", "GET");

    this.setState({loading: false, nextPayout: NEXT_PAYOUT});
  }

  getStatus(item) {
    if (item.DATE_FAILED) return "Failed";

    return moment(item.DATE_ARRIVED).valueOf() < moment().valueOf() ? "Paid" : "Pending";
  }

  renderSetupStripe() {
    return (
      <div className={"flex flex-col text-center py-40"}>
        <div className="text-lg font-bold">To receive payouts, please setup your Stripe account</div>
        <div
          className="font-bold text-indigo-700 cursor-pointer"
          onClick={() => this.props.router.navigate("settings")}
        >
          on the Payout Settings page
        </div>
      </div>
    );
  }

  render() {
    const {loading, nextPayout} = this.state;
    const {NAME, CONNECT_ID, PAYOUT_ENABLED} = this.props.shop.location;
    const {TAX_WITHHOLDING} = this.props.shop.settings;

    if (!CONNECT_ID) {
      return this.renderSetupStripe();
    }

    if (loading) {
      return <Loading />;
    }

    const settingDependentFilters = [];

    if (TAX_WITHHOLDING === "1") {
      settingDependentFilters.push({
        id: "type",
        type: "check",
        label: "Type",
        data: [
          {label: "Sales Payout", value: "DAILY"},
          {label: "Tax Withholding Payout", value: "TAX"},
        ],
      });
    }

    return (
      <div>
        {!PAYOUT_ENABLED && (
          <div className="pb-4">
            <Banner
              label={
                <>
                  Payouts Paused.{" "}
                  <a
                    href="https://dashboard.dripos.com/payouts/settings"
                    style={{textDecoration: "underline"}}
                  >
                    Please update your your bank information.
                  </a>
                </>
              }
            />
          </div>
        )}

        <PageHeadings label="Payouts" />

        <div>
          <Card>
            <div className="flex flex-row px-10 py-5">
              <div className="flex flex-col">
                <div className="font-bold text-xl">{toDollars(nextPayout, true)}</div>
                <div className="text-sm font-semibold text-gray-700">In Next Payout</div>
              </div>
            </div>
          </Card>

          <AdvancedFilter
            className="mt-10"
            data={[
              {
                type: "array",
                data: [
                  {label: "Start Date", type: "datetime", id: "start"},
                  {label: "End Date", type: "datetime", id: "end"},
                ],
              },
              ...settingDependentFilters,
            ]}
            csv={{
              file: `payouts-${NAME}`,
              route: "payouts/dumb",
              headers: [
                {
                  label: "Arrival Date",
                  key: "DATE_ARRIVED",
                },
                {
                  label: "Descriptor",
                  key: "DESCRIPTOR",
                },
                {
                  label: "Status",
                  key: "STATUS",
                },
                {
                  label: "Net Total",
                  key: "AMOUNT",
                  type: "dollars",
                },
                {
                  label: "Payout Fee",
                  key: "AMOUNT_PROCESSING",
                  type: "dollars",
                },
                {
                  label: "Net Payout",
                  key: "TOTAL",
                  type: "dollars",
                },
              ],
              formatDownloadData: (data) => {
                return data.map((item) => {
                  return {
                    ...item,
                    AMOUNT: `$${toDollars(item.AMOUNT)}`,
                    AMOUNT_PROCESSING: `$${toDollars(item.AMOUNT_PROCESSING)}`,
                    TOTAL: `$${toDollars(item.TOTAL)}`,
                    DATE_ARRIVED: moment(item.DATE_ARRIVED).format("MMM D, YYYY"),
                    STATUS: this.getStatus(item),
                  };
                });
              },
            }}
          >
            {(filters) => {
              return (
                <Table
                  route={"payouts/dumb"}
                  filters={filters}
                  pagination
                  getCSVInfo={filters}
                  actionButtons={[
                    {
                      label: "View",
                      onClick: (row) => {
                        if (row.UNIQUE_ID) {
                          this.props.router.navigate(`/payouts/${row.UNIQUE_ID}`);
                        } else {
                          showErrorAlert(
                            "No Breakdown",
                            "A breakdown for this payout is not available.",
                            "Ok"
                          );
                        }
                      },
                    },
                  ]}
                  columns={[
                    {
                      label: "Arrival Date",
                      value: "DATE_ARRIVED",
                      tooltip: "This is the estimated date that the payout will land in your bank account.",
                      format: (value) => moment(value).format("MMM D, YYYY, h:mm A"),
                    },
                    {
                      label: "Description",
                      tooltip:
                        "This will note the kind of payout. Payouts are either regular payouts from credit card sales or tax withholding payouts. Note: Regular payouts will be labeled as Dripos Payout and Tax withholding payouts will be labeled as Dripos Tax Payout.",
                      value: "DESCRIPTOR",
                    },
                    {
                      label: "Status",
                      value: "STATUS",
                      tooltip: {
                        data: [
                          {
                            label: "Paid",
                            data: "The payout has already successfully been paid out from Dripos.",
                          },
                          {label: "Pending", data: "The payout is in transit."},
                          {
                            label: "Failed",
                            data: "The payout has not been successfully paid.",
                          },
                        ],
                      },
                      format: (value, row) => this.getStatus(row),
                    },
                    {
                      label: "Net Payout",
                      value: "TOTAL",
                      tooltip:
                        "This is the amount that will land in your bank account after payout fees are removed.",
                      format: (value) => toDollars(value, true),
                    },
                  ]}
                />
              );
            }}
          </AdvancedFilter>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(PayoutsPage));

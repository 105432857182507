import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import ProductLocationsTableExpandableComponent, {
  FIELD_DISPLAYS,
} from "../../../../../features/product/product-locations-table-expandable-component";

class ProductLocationsTab extends Component {
  render() {
    const {product} = this.props;

    return (
      <div>
        <Table
          expandable={(product) => <ProductLocationsTableExpandableComponent product={product} />}
          data={product.LOCATION_PRODUCTS ?? []}
          columns={[
            {
              label: "Location",
              value: "LOCATION",
              format: (row) => row.NAME,
            },
            {
              label: "Sync Status",
              value: "OUT_OF_SYNC",
              format: (val) => (val ? "Out of Sync" : "In Sync"),
            },
            {
              label: "Discrepancies",
              value: "DISCREPANCIES",
              format: (discrepancies) => {
                discrepancies = discrepancies ?? [];

                return discrepancies
                  .filter((d) => d.FIELD !== "LOGO_LOW")
                  .map((discrepancy) => {
                    if (FIELD_DISPLAYS[discrepancy.FIELD]) {
                      return FIELD_DISPLAYS[discrepancy.FIELD];
                    }

                    return (
                      discrepancy.FIELD.substring(0, 1).toUpperCase() +
                      discrepancy.FIELD.substring(1).toLowerCase()
                    );
                  })
                  .join(", ");
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default ProductLocationsTab;

import React, {Component} from "react";
import {request} from "../../../../utils/request";
import SupplyComingSoonPage from "../supply-coming-soon-page";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {Filter, Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment/moment";
import CardAlert from "../../../../features/card-alert";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import RequestTransferModal from "../../../../modals/operations/supply-chain/transfers/request-transfer-modal";
import {deepClone, groupBy, isSupplyChainWhitelisted} from "../../../../utils/util";
import TransferTableExpandableComponent from "../../../../features/operations/supply-chain/transfers/transfer-table-expandable-component";
import GrayBadge from "../../../../components/badges/gray-badge";

class TransfersPage extends Component {
  state = {transfers: null, requests: null};

  componentDidMount() {
    this.syncState();
  }

  buildRequestsDict(requests) {
    const {companyLocations: locations} = this.props.shop;
    const {location} = this.props.shop;

    const incomingLocations = deepClone(
      locations.filter((_location) => {
        return _location.ID !== location.ID;
      })
    );

    const outgoingLocations = deepClone(
      locations.filter((_location) => {
        return _location.ID !== location.ID;
      })
    );

    const incomingRequests = groupBy(requests, "SUPPLIER_LOCATION_ID");
    const outgoingRequests = groupBy(requests, "RECEIVER_LOCATION_ID");

    for (const incomingLocation of incomingLocations) {
      incomingLocation.REQUESTS =
        outgoingRequests[location.ID]?.filter((_request) => {
          return _request.SUPPLIER_LOCATION_ID === incomingLocation.ID;
        }) ?? [];
    }

    for (const outgoingLocation of outgoingLocations) {
      outgoingLocation.REQUESTS =
        incomingRequests[location.ID]?.filter((_request) => {
          return _request.RECEIVER_LOCATION_ID === outgoingLocation.ID;
        }) ?? [];
    }

    return {requests, incomingRequests: incomingLocations, outgoingRequests: outgoingLocations};
  }

  syncState() {
    Promise.all([
      request("inventory-transfers", "GET"),
      request("inventory-transfers/items/requests", "GET"),
    ]).then(([transfers, requests]) => {
      // group requests by location

      const {incomingRequests, outgoingRequests} = this.buildRequestsDict(requests);

      this.setState({transfers, incomingRequests, outgoingRequests});
    });
  }

  renderHeader() {
    const {transfers} = this.state;
    const {location} = this.props.shop;

    const sentThisWeek = transfers
      .filter((transfer) => {
        return (
          transfer.STATUS === "SENT" &&
          transfer.SUPPLIER_LOCATION_ID === location.ID &&
          transfer.DATE_SENT &&
          moment(transfer.DATE_SENT).startOf("week").add(1, "week") < Date.now()
        );
      })
      .reduce((accum, item) => accum + item.TOTAL, 0);

    const receivedThisWeek = transfers
      .filter((transfer) => {
        return (
          transfer.STATUS === "RECEIVED" &&
          transfer.RECEIVER_LOCATION_ID === location.ID &&
          transfer.DATE_RECEIVED &&
          moment(transfer.DATE_RECEIVED).startOf("week").add(1, "week") < Date.now()
        );
      })
      .reduce((accum, item) => accum + item.TOTAL, 0);

    const toReceiveThisWeek = transfers.filter((transfer) => {
      return (
        transfer.STATUS === "SENT" &&
        transfer.RECEIVER_LOCATION_ID === location.ID &&
        transfer.DATE_SENT &&
        moment(transfer.DATE_SENT).startOf("week").add(1, "week") < Date.now()
      );
    });

    return (
      <>
        <RequestTransferModal ref={(e) => (this.requestModal = e)} />

        <PageHeadings
          label="Transfers Dashboard"
          description="Manage and transfer your inventory across locations"
          buttons={[
            {
              label: "Request Inventory",
              onClick: () => this.requestModal.open(),
            },
            {
              label: "Send Inventory",
              onClick: () => this.props.router.navigate("/transfer"),
            },
          ]}
        />
      </>
    );
  }

  renderRequestsTable(incoming = false) {
    const {incomingRequests, outgoingRequests} = this.state;
    const {location} = this.props.shop;
    const requests = [...incomingRequests, ...outgoingRequests];

    return (
      <Table
        data={requests}
        columns={[
          {
            value: "NAME",
            label: "Receiving Location",
            format: (_, row) => {
              return (
                <div className="my-1">
                  {row.NAME}

                  <GrayBadge className="ml-2">
                    {row.REQUESTS.filter((r) => r.STATUS === "REQUESTED").length}
                  </GrayBadge>
                </div>
              );
            },
          },
          {
            value: "RECEIVER_LOCATION_ID",
            label: "Type",
            format: (value) => {
              return value === location.ID ? "INCOMING" : "OUTGOING";
            },
          },
          {
            value: "",
            label: "",
            format: (_, request) => {
              if (incoming) {
                return <div />;
              }

              return (
                <button
                  onClick={() => {
                    this.props.router.navigate("/transfer/", {
                      state: {transfer: request},
                    });
                  }}
                  type="button"
                  className="text-sm text-indigo-500 hover:text-indigo-700 font-semibold"
                >
                  Fulfill
                </button>
              );
            },
          },
        ]}
        expandable={(row) => {
          return (
            <TransferTableExpandableComponent
              request={row}
              incoming={incoming}
              syncState={(newRequest) => {
                this.setState((prevState) => {
                  const {incomingRequests, outgoingRequests} = prevState;

                  if (incoming) {
                    return {
                      incomingRequests: incomingRequests.map((oldRequest) => {
                        return newRequest.ID === oldRequest.ID ? newRequest : oldRequest;
                      }),
                    };
                  }

                  return {
                    outgoingRequests: outgoingRequests.map((oldRequest) => {
                      return newRequest.ID === oldRequest.ID ? newRequest : oldRequest;
                    }),
                  };
                });
              }}
            />
          );
        }}
      />
    );
  }

  renderOutstandingRequestsTab() {
    const {outgoingRequests} = this.state;

    const numberOfRequests = outgoingRequests.reduce((accum, location) => {
      return accum + location.REQUESTS.filter((r) => r.STATUS === "REQUESTED").length;
    }, 0);

    return (
      <div>
        Pending Requests
        {numberOfRequests > 0 && (
          <GrayBadge className="bg-red-500 text-white ml-2">{numberOfRequests}</GrayBadge>
        )}
      </div>
    );
  }

  renderTable() {
    const {transfers} = this.state;
    const {location} = this.props.shop;

    return (
      <Tab
        data={[
          {id: "all", label: "Transfers"},
          {id: "outstanding", label: this.renderOutstandingRequestsTab()},
        ]}
      >
        {(tab) => {
          let tableData = transfers;

          if (tab === "requests") {
            return this.renderRequestsTable(true);
          } else if (tab === "outstanding") {
            return this.renderRequestsTable(false);
          } else if (tab === "outgoing") {
            tableData = tableData.filter((transfer) => {
              return transfer.SUPPLIER_LOCATION_ID === location.ID;
            });
          } else if (tab === "incoming") {
            tableData = tableData.filter((transfer) => {
              return transfer.RECEIVER_LOCATION_ID === location.ID;
            });
          }

          return (
            <Filter
              defaultFilters={[
                {filter: "status", id: "OPEN", label: "Open"},
                {filter: "status", id: "SENT", label: "Sent"},
                {filter: "status", id: "RECEIVED", label: "Received"},
              ]}
              data={[
                {
                  id: "status",
                  label: "Status",
                  onFilter: (options, data) => {
                    return data.filter((item) => {
                      return options.includes(item.STATUS);
                    });
                  },
                  options: [
                    {filter: "status", id: "OPEN", label: "Open"},
                    {filter: "status", id: "SENT", label: "Sent"},
                    {filter: "status", id: "RECEIVED", label: "Received"},
                  ],
                },
              ]}
            >
              {(filters) => {
                return (
                  <Table
                    data={tableData}
                    filters={filters}
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (transfer) => {
                          return this.props.router.navigate("/transfer/" + transfer.UNIQUE_ID);
                        },
                      },
                    ]}
                    columns={[
                      {
                        label: "Receiver Location",
                        value: "RECEIVER_LOCATION_ID",
                        format: (_, row) => row.RECEIVER_LOCATION.NAME,
                      },
                      {
                        label: "Supplier Location",
                        value: "SUPPLIER_LOCATION_ID",
                        format: (_, row) => row.SUPPLIER_LOCATION.NAME,
                      },
                      {
                        label: "Status",
                        value: "STATUS",
                      },
                      {
                        label: "Total Value",
                        value: "TOTAL",
                        format: (val) => toDollars(val, true),
                      },
                    ]}
                  />
                );
              }}
            </Filter>
          );
        }}
      </Tab>
    );
  }

  render() {
    const {transfers} = this.state;

    if (!isSupplyChainWhitelisted()) {
      return <SupplyComingSoonPage />;
    }

    if (!transfers) {
      return <Loading />;
    }

    return (
      <div>
        {this.renderHeader()}
        {this.renderTable()}
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(TransfersPage));

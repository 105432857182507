import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@frostbyte-technologies/frostbyte-tailwind";

class CardAlert extends Component {
  render() {
    const {label, value, link, subtext, icon, hideView, viewText = "View all", tooltip = null} = this.props;

    return (
      <div key={value} className="bg-white overflow-hidden shadow rounded-lg">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <FontAwesomeIcon icon={icon ?? "dagger"} className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <div className="flex flex-row justify-start">
                  <dt className="text-sm font-medium text-gray-500 truncate">{label}</dt>

                  {!!tooltip && <Tooltip className="ml-4" {...tooltip} />}
                </div>
                <dd>
                  <div className="text-lg font-medium text-gray-900">
                    {value}

                    {subtext && <span className="ml-2 text-sm font-medium text-gray-500">{subtext}</span>}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>

        {!hideView && (
          <div className="bg-gray-50 px-5 py-3">
            <div className="text-sm">
              <Link to={link} className="font-medium text-indigo-600 hover:text-indigo-500">
                {viewText}
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CardAlert.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  link: PropTypes.string,

  subtext: PropTypes.string,
};

export default CardAlert;

import React, {Component} from "react";
import {request} from "../../../../utils/request";

class VideoLandingPage extends Component {
  checkForRickRoll(url) {
    if (url === "https://www.youtube.com/embed/dQw4w9WgXcQ") {
      return url + "?autoplay=1";
    }

    return url;
  }

  async checkVideo() {
    const {instanceId, item, employee} = this.props;

    await request("checklists/instances/" + instanceId + "/tick/" + item.ID, "POST", {
      EMPLOYEE_ID: employee.PIN,
    });

    return request("checklists/instances/" + instanceId + "/item/" + item.ID + "/resolve", "POST");
  }

  render() {
    const {item} = this.props;
    const url = this.checkForRickRoll(item.OPTIONS[0].NAME);

    return (
      <div className={"flex flex-row justify-center my-10 "}>
        <iframe
          width="853"
          height="480"
          src={url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    );
  }
}

export default VideoLandingPage;

import React, {Component} from "react";
import {ExclamationIcon, XCircleIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class Banner extends Component {
  render() {
    const {className, label, type, description, detailsLabel, detailsOnClick} = this.props;

    let labelStyle = "text-yellow-800";
    let wrapperStyle = "bg-yellow-50";
    let descriptionStyle = "text-yellow-700";
    let icon = <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />;

    if (type === "error") {
      labelStyle = "text-red-800";
      wrapperStyle = "bg-red-50";
      descriptionStyle = "text-red-700";
      icon = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />;
    }

    return (
      <div className={classNames(className, wrapperStyle, "rounded-md p-4")}>
        <div className="flex">
          <div className="flex-shrink-0">{icon}</div>

          <div className="ml-3 flex-1 md:flex md:justify-between md:items-center">
            <div>
              <h3 className={classNames(labelStyle, "text-sm font-medium")}>{label}</h3>

              {!!description && (
                <div className={classNames(descriptionStyle, "mt-2 text-sm")}>
                  <p>{description}</p>
                </div>
              )}
            </div>

            {detailsLabel && (
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <div
                  onClick={() => detailsOnClick()}
                  className="whitespace-nowrap cursor-pointer font-medium text-indigo-500 hover:text-indigo-600"
                >
                  {detailsLabel} <span aria-hidden="true">&rarr;</span>
                </div>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Banner.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  detailsLabel: PropTypes.string,
  detailsOnClick: PropTypes.func,
};

export default Banner;

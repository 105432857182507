import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import {RecipeRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {updateIngredient} from "../../../../redux/supply";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";

class AttachIngredientModal extends Component {
  state = {recipe: null, sub_ingredient: null};

  open(recipe, sub_ingredient = null) {
    this.setState({recipe, sub_ingredient}, () => this.modal.open());
  }

  async attachIngredient(values, ingredient) {
    const {source} = this.props;

    let quantity = values.quantity;

    if (values.unit !== ingredient.UNIT.ID) {
      const conversion = ingredient.UNIT.CONVERSIONS.find((conversion) => conversion.TO_UNIT === values.unit);
      quantity /= conversion?.RATE;
    }

    const payload = {
      SOURCE_ID: source.ID,
      DESTINATION_ID: values.edge,
      QUANTITY: quantity,
    };

    try {
      await RecipeRequests.createRecipe(payload);
    } catch (e) {
      showErrorAlert("Cannot add ingredient.", "Adding this ingredient would create a cyclic dependency.");
    }

    await getStore().dispatch(updateIngredient(source.ID));
    this.modal.close();
  }

  deleteIngredient() {
    const {recipe} = this.state;
    const {source} = this.props;

    RecipeRequests.deleteRecipe(recipe.RECIPE_ID)
      .then(() => {
        showSuccessNotification("Sub-ingredient deleted successfully.");

        return getStore().dispatch(updateIngredient(source.ID));
      })
      .catch((err) => {
        showErrorNotification("Error deleting sub-ingredient,");
      })
      .finally(() => {
        this.modal && this.modal.close();
      });
  }

  async saveIngredient(values) {
    const {recipe} = this.state;
    const {source, unitDict} = this.props;

    let quantity = values.quantity;

    if (values.unit !== recipe.UNIT_ID) {
      const unit = unitDict[values.unit];
      const conversion = unit.CONVERSIONS.find((conversion) => conversion.TO_UNIT === recipe.UNIT_ID);
      quantity *= conversion?.RATE;
    }

    const payload = {
      QUANTITY: quantity,
    };

    try {
      await RecipeRequests.updateRecipe(recipe.RECIPE_ID, payload);
    } catch (e) {
      console.log(e);
    }

    await getStore().dispatch(updateIngredient(source.ID));
    this.modal.close();
  }

  render() {
    const {recipe, sub_ingredient} = this.state;
    const {source} = this.props;

    return (
      <Modal
        label="Attach Ingredient"
        formikOnClick={() => this.formikRef}
        buttonLabel={recipe ? "Save" : "Attach"}
        ref={(e) => (this.modal = e)}
        deleteLabel={recipe ? "Delete" : ""}
        deleteOnClick={() => this.deleteIngredient()}
      >
        <Formik
          onSubmit={(values) =>
            recipe ? this.saveIngredient(values) : this.attachIngredient(values, sub_ingredient)
          }
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            edge: recipe?.DESTINATION_ID,
            quantity: recipe?.QUANTITY || 1,
            unit: recipe?.UNIT_ID || null,
          }}
          validationSchema={Yup.object().shape({
            edge: Yup.number().integer().required(),
            quantity: Yup.number().required(),
            unit: Yup.string().nullable().required("Please select a unit"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <IngredientDropdown
                  name="edge"
                  label="Ingredient"
                  options={formikOptions}
                  disabled={recipe}
                  onChangeSoft={({ingredient}) => {
                    this.setState({sub_ingredient: ingredient});
                  }}
                />

                <div className="flex flex-row">
                  <FormInput
                    name="quantity"
                    label={`Usage Quantity`}
                    options={formikOptions}
                    className="flex-[3]"
                  />

                  <UnitDropdown
                    name="unit"
                    label="Unit"
                    options={formikOptions}
                    className="pl-2"
                    formLine={true}
                    tooltip="The unit you would like to use to measure the quantity of this ingredient."
                    ingredient={sub_ingredient}
                    unit={sub_ingredient?.UNIT_ID}
                    units={sub_ingredient?.UNITS}
                  />
                      
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default AttachIngredientModal;

import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import {getPeriodFromInvoiceDescription} from "../../utils/billing-helper";

class BillingHistoryTable extends Component {
  render() {
    const {invoices} = this.props;

    return (
      <Table
        columns={[
          {
            label: "Description",
            value: "charge",
            format: (value, _) => {
              return value?.statement_descriptor || value?.calculated_statement_descriptor || "-";
            },
          },
          {
            label: "Amount",
            value: "amount_due",
            format: (value) => {
              return toDollars(value, true);
            },
          },
          {
            label: "Date Paid",
            value: "status_transitions",
            format: (value, _) => {
              if (value?.paid_at) {
                return moment(value.paid_at * 1000).format("MM/DD/yyyy");
              }
              return "-";
            },
          },
          {
            label: "Billing Period",
            value: "charge",
            format: (_, row) => {
              const {period_start: invoicePeriodStart, period_end: invoicePeriodEnd} = row;

              if (invoicePeriodStart === invoicePeriodEnd) {
                return "One-off";
              }

              const {start = invoicePeriodStart, end = invoicePeriodEnd} =
                getPeriodFromInvoiceDescription(row);

              return moment(start * 1000).format("MM/DD") + " - " + moment(end * 1000).format("MM/DD");
            },
          },
          {
            label: "Status",
            value: "status",
            format: (value) => {
              if (value === "paid") {
                return "Paid";
              } else if (value === "void") {
                return "Voided";
              } else if (value === "open") {
                return "Open";
              }

              return "Unpaid";
            },
          },
        ]}
        placeholders={{noDataTitle: "No Payments Found!"}}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => {
              this.props.router.navigateNewTab(row.hosted_invoice_url);
            },
          },
          {
            label: <FontAwesomeIcon icon="download" />,
            onClick: (row) => this.props.router.navigateNewTab(row.invoice_pdf),
          },
        ]}
        data={invoices}
      />
    );
  }
}

BillingHistoryTable.propTypes = {
  invoices: PropTypes.array.isRequired,
};

export default withRouter(BillingHistoryTable);

import React, {Component} from "react";
import Wizard from "../../../components/wizard";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {Loading, Table, Card, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import TimeOffCard from "../../../features/team/time-off-card";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {toDecimalPlaces} from "../../../utils/decimal-helper";

class RunPolicyPage extends Component {
  state = {policies: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const policies = await request("timeoff/policies/run", "GET");

    this.setState({policies});
  }

  async submitPolicies() {
    await request("timeoff/policies/run", "POST", {});

    this.props.router.navigate("/policies");
  }

  render() {
    const {policies} = this.state;

    if (policies === null) {
      return <Loading />;
    }

    return (
      <>
        <Wizard
          ref={(e) => (this.wizardRef = e)}
          onQuit={() => this.props.router.navigate("/policies")}
          onSubmit={this.submitPolicies.bind(this)}
          steps={[
            {
              id: 0,
              name: "Employee policies",
              description: "General information about the policy",
              render: ({values}, {handleSubmit, setupRef}) => {
                return (
                  <Card
                    label="Time Off Policies"
                    description="Here is a list of policies that will be executed. Clicking the dropdown arrow next to a policy with show a by employee breakdown."
                  >
                    <Table
                      data={policies}
                      expandable={(row) => (
                        <Table
                          leftPad
                          hideBorder
                          data={row.execution}
                          columns={[
                            {
                              value: "employee",
                              label: "Employee Name",
                              format: (value, {employee: row}) => {
                                return (
                                  <div className="flex items-center">
                                    <div className="h-10 w-10 flex-shrink-0">
                                      <img
                                        className="h-10 w-10 rounded-full"
                                        src={getObjectImage(row, "LOGO", "appicon.png")}
                                        alt=""
                                      />
                                    </div>

                                    <div className="ml-4">
                                      <div className="text-sm font-medium text-gray-900">{row.FULL_NAME}</div>

                                      <div className="text-sm text-gray-500">
                                        {row.ROLES?.map((item) => item.ROLE_NAME).join(", ")}
                                      </div>
                                    </div>
                                  </div>
                                );
                              },
                            },
                            {
                              width: 200,
                              value: "BALANCE",
                              label: "Hours Worked",
                              format: (_, {log}) => log.HOURS,
                            },
                            {
                              width: 200,
                              value: "BALANCE",
                              label: "Hours Earned",
                              format: (_, {log}) => log.AMOUNT,
                            },
                            {
                              width: 200,
                              value: "BALANCE",
                              label: "Hours Balance",
                              format: (_, {log}) => log.BALANCE,
                            },
                          ]}
                        />
                      )}
                      columns={[
                        {
                          value: "employee",
                          label: "Policy Name",
                          format: (_, {policy}) => policy.NAME,
                        },
                        {
                          width: 200,
                          value: "BALANCE",
                          label: "Hours Worked",
                          format: (_, {execution}) =>
                            execution.reduce((accum, {log}) => {
                              return accum + log.HOURS;
                            }, 0),
                        },
                        {
                          width: 200,
                          value: "BALANCE",
                          label: "Hours Earned",
                          format: (_, {execution}) =>
                            execution.reduce((accum, {log}) => {
                              return accum + log.AMOUNT;
                            }, 0),
                        },
                        {
                          width: 200,
                          value: "BALANCE",
                          label: "Hours Balance",
                          format: (_, {execution}) =>
                            execution.reduce((accum, {log}) => {
                              return accum + log.BALANCE;
                            }, 0),
                        },
                      ]}
                    />
                  </Card>
                );
              },
            },
            {
              id: 1,
              name: "Preview",
              description: "Confirm the details of the time off policy",
              render: ({values}) => {
                const {policies} = this.state;

                const totalHours = policies.reduce(
                  (accum, {execution}) =>
                    accum +
                    execution.reduce((accum, item) => {
                      return accum + item.log.HOURS;
                    }, 0),
                  0
                );

                const totalEarned = policies.reduce(
                  (accum, {execution}) =>
                    accum +
                    execution.reduce((accum, item) => {
                      return accum + item.log.AMOUNT;
                    }, 0),
                  0
                );

                return (
                  <>
                    <TwoColumnList
                      label="Time Off Review"
                      description="Information"
                      data={[
                        {
                          label: "Hours Tracked",
                          value: toDecimalPlaces(totalHours, 2),
                        },
                        {
                          label: "Total Earned",
                          value: toDecimalPlaces(totalEarned, 2),
                        },
                      ]}
                    />
                  </>
                );
              },
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(RunPolicyPage);

import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";

class LabelerDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverCategories = await request("labels/categories", "GET");
    const data = serverCategories.map((item) => {
      return {
        id: item.ID,
        label: item.NAME,
      };
    });

    this.setState({data});
  }

  render() {
    const {data} = this.state;

    return <ComboBox label="Label Categories" {...this.props} data={data} />;
  }
}

export default LabelerDropdown;

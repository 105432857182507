import React, {Component} from "react";
import {Modal, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";

class SendTextModal extends Component {
  state = {phone: null};

  open(phone) {
    this.setState({phone}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  sendText = async ({content}) => {
    let {phone} = this.state;
    let serverText = await request("support/text", "POST", {
      CONTENT: content,
      PHONE: phone,
    });

    this.props.addText(serverText);
    this.modal.close();
  };

  render() {
    return (
      <Modal
        label="Text"
        buttonLabel="Send"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik initialValues={{}} onSubmit={this.sendText} innerRef={(e) => (this.formikRef = e)}>
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormTextArea name="content" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default SendTextModal;

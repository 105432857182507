import React, {Component} from "react";
import PropTypes from "prop-types";
import {XIcon} from "@heroicons/react/solid";

class Announcement extends Component {
  state = {hidden: false};

  render() {
    const {label, shortLabel, link} = this.props;
    const {hidden} = this.state;

    if (window.localStorage.getItem("refer") !== null) {
      return <div />;
    }

    if (hidden) {
      return <div />;
    }

    return (
      <div className="relative bg-indigo-600">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">{shortLabel}</span>
              <span className="hidden md:inline">{label}</span>

              <span className="block sm:ml-2 sm:inline-block">
                <a href={link} target="_blank" className="text-white font-bold underline">
                  {" "}
                  Learn more <span aria-hidden="true">&rarr;</span>
                </a>
              </span>
            </p>
          </div>

          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => {
                this.setState({hidden: true}, () => {
                  localStorage.setItem("refer", "true");
                });
              }}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Announcement.propTypes = {
  label: PropTypes.string.isRequired,
  shortLabel: PropTypes.string.isRequired,

  link: PropTypes.string.isRequired,
};

export default Announcement;

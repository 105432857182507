import React, {Component} from "react";
import {FormBoolean, FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import ColorDropdown from "../../../features/team/color-dropdown";
import ComboBox from "../../../components/combobox";
import {ROLE_COLORS} from "../../../utils/team-constants";

export const APPROVAL_NOTIFICATIONS = [
  {
    id: null,
    label: "No",
  },
  {
    id: "TEXT",
    label: "Notify by Text",
  },
  {
    id: "EMAIL",
    label: "Notify by Email",
  },
  {
    id: "BOTH",
    label: "Notify by Text and Email",
  },
];

class RoleModal extends Component {
  state = {role: null};

  open(role = null) {
    this.setState({role}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveRole({name, color, tips, notify, notifyClock, notifyGrabs, notifyStock}) {
    const {role} = this.state;

    const rolePayload = {
      TIPS_DISABLED: parseInt(tips),
      COLOR: color,
      NAME: name,
      APPROVAL_NOTIFICATIONS: notify,
      CLOCK_NOTIFICATIONS: notifyClock,
      GRABS_NOTIFICATIONS: notifyGrabs,
      STOCK_NOTIFICATIONS: notifyStock,
    };

    await request("employeeroles/roles/" + role.ID, "PATCH", rolePayload);

    this.props.onSave({
      TIPS_DISABLED: parseInt(tips),
      COLOR: color,
      NAME: name,
      APPROVAL_NOTIFICATIONS: notify,
      CLOCK_NOTIFICATIONS: notifyClock,
      GRABS_NOTIFICATIONS: notifyGrabs,
      STOCK_NOTIFICATIONS: notifyStock,
    });

    this.modal.close();
  }

  render() {
    const {role} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Role"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.saveRole.bind(this)}
          initialValues={{
            name: role?.NAME,
            color: role?.COLOR ?? ROLE_COLORS[0],
            tips: role?.TIPS_DISABLED,
            notify: role?.APPROVAL_NOTIFICATIONS,
            notifyClock: role?.CLOCK_NOTIFICATIONS,
            notifyGrabs: role?.GRABS_NOTIFICATIONS,
            notifyStock: role?.STOCK_NOTIFICATIONS,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Role name" name="name" options={formikOptions} />

                <ColorDropdown label="Scheduling Color" name="color" options={formikOptions} />

                <FormBoolean
                  label="Tips Disabled"
                  tooltip="Can employees with this role have tips added to their timesheets"
                  name="tips"
                  options={formikOptions}
                />

                <ComboBox
                  label={"Notify of Trade and Time Off Requests"}
                  tooltip="Send email notifications to employees with this role when trade and time off requests are submitted."
                  data={APPROVAL_NOTIFICATIONS}
                  name={"notify"}
                  options={formikOptions}
                />

                <ComboBox
                  label={"Notify of Late Clock Ins"}
                  tooltip="Send email or text notifications when employees clock in after the late clock in threshold."
                  data={APPROVAL_NOTIFICATIONS}
                  name={"notifyClock"}
                  options={formikOptions}
                />

                <ComboBox
                  label={"Notify of Up For Grabs Shifts"}
                  tooltip="Send email or text notifications when employees put a shift up for grabs."
                  data={APPROVAL_NOTIFICATIONS}
                  name={"notifyGrabs"}
                  options={formikOptions}
                />

                <ComboBox
                  label={"Notify of Stock Updates for Ingredients"}
                  tooltip="Send email or text notifications when ingredient stock is depleted or running below PAR."
                  data={APPROVAL_NOTIFICATIONS}
                  name={"notifyStock"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

RoleModal.propTypes = {
  onSave: PropTypes.func,
};

export default RoleModal;

import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import ReceiptModal from "../../../../modals/sales/receipts/receipt-modal";

class ReceiptsPage extends Component {
  state = {receipts: null, templates: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const receipts = await request("receipts", "GET");
    const templates = await request("receipts/templates", "GET");

    this.setState({receipts, templates});
  }

  render() {
    const {receipts, templates} = this.state;

    if (receipts === null) {
      return <Loading />;
    }

    return (
      <div>
        <ReceiptModal
          templates={templates}
          ref={(e) => (this.receiptModal = e)}
          addState={(state) => {
            this.props.router.navigate("/receipt/" + state.UNIQUE_ID);
          }}
        />

        <PageHeadings
          label="Receipts"
          description="View and modify printable receipts"
          button={{
            label: "Create Receipt",
            onClick: () => this.receiptModal.open(),
          }}
        />

        <Table
          pagination
          data={receipts}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/receipt/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "TYPE",
              label: "Type",
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ReceiptsPage));

import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import * as Yup from "yup";
import {request} from "../../utils/request";

class AutomatedEntryModal extends Component {
  state = {label: null, dict: {}};

  open(label, dict) {
    this.setState({label, dict}, () => this.modal.open());
  }

  handleSubmit = async (values) => {
    await request("accounting/mappings", "POST", values);

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    const {mappings} = this.props;
    const {label, dict} = this.state;
    const {ledgers} = this.props.accounting;

    return (
      <Modal
        label={`Edit ${label} Entry`}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        buttonLabel={"Save"}
        closeLabel={"Close"}
      >
        <Formik
          initialValues={mappings?.reduce((dict, mapping) => {
            dict[mapping.MAPPING] = mapping.LEDGER_ID;

            return dict;
          }, {})}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
          validationSchema={Yup.object(
            Object.keys(dict).reduce((validationDict, _key) => {
              validationDict[_key] = Yup.number().required("Account must be selected");

              return validationDict;
            }, {})
          )}
        >
          {(formikOptions) => {
            return (
              <div>
                {Object.keys(dict).map((_key) => (
                  <FormSelect
                    name={_key}
                    data={ledgers.map(({ID, NAME}) => ({
                      label: NAME,
                      value: ID,
                    }))}
                    label={dict[_key].label}
                    options={formikOptions}
                  />
                ))}
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["accounting"])(withRouter(AutomatedEntryModal));

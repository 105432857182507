import React, {Component} from "react";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import b64toBlob from "b64-to-blob";
import {Download} from "react-feather";
import moment from "moment/moment";

class AccountDocumentsPage extends Component {
  state = {documents: null, link: ""};

  async componentDidMount() {
    const documents = await request("hub/payroll/tax-documents");

    this.setState({documents});
  }

  openDocument = async (documentId) => {
    const pdf = await request(`hub/payroll/tax-documents/${documentId}`);

    let blob = b64toBlob(pdf, "application/pdf");
    let blobUrl = URL.createObjectURL(blob);

    await this.setState({link: blobUrl}, () => this.linkRef.click());
  };

  render() {
    let {link, documents} = this.state;

    documents = documents?.filter(
      (doc) =>
        doc.label !== "Federal W-2" ||
        (doc.label === "Federal W-2" && moment(doc.filed_on).valueOf() < moment("2024-01-01").valueOf()) ||
        (doc.label === "Federal W-2" && moment().valueOf() > moment("2024-01-19").valueOf())
    );

    const columns = [
      {
        label: "Name",
        value: "label",
      },
      {
        label: "Filed On",
        value: "filed_on",
      },
      {
        label: "Company",
        value: "groupName",
      },
      {
        name: "Open",
        value: "id",
        format: (id, row) => {
          return (
            <div style={{cursor: "pointer"}} onClick={() => this.openDocument(row.id)}>
              <Download className={"h-4 w-4 text-indigo-500"} />
            </div>
          );
        },
      },
    ];

    return (
      <div className="p-6">
        <a ref={(e) => (this.linkRef = e)} href={link} />

        <PageHeadings label="Tax Documents" />

        <Card label="Your Tax Documents">
          <Table columns={columns} data={documents} limit={10} />
        </Card>
      </div>
    );
  }
}

export default AccountDocumentsPage;

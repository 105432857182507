import React, {Component} from "react";

import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import {Card, Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {updateStateDelegator} from "../../../utils/util";
import OnboardingTaskModal from "../../../modals/team/employee/onboarding/onboarding-task-modal";

class CreateProcessPage extends Component {
  state = {process: null, tasks: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {UNIQUE_ID: id} = this.props.router.params;

    let process = await request("checklists/" + id, "GET", null);

    request("checklists/" + id + "/items", "GET").then((tasks) => {
      tasks.sort((a, b) => a.SEQ - b.SEQ);

      this.setState({tasks});
    });

    this.setState({process});
  }

  async archiveChecklist() {
    let {process} = this.state;

    showLoadingConfirmAlert("Delete Process", "Are you sure you want to delete this process?").then(
      async (close) => {
        await request("checklists/" + process.ID, "DELETE", {});

        this.props.router.navigate("/onboarding/processes");

        close();

        showSuccessNotification("Process Deleted", "The process has been deleted");
      }
    );
  }

  saveSequence() {
    const {tasks, process} = this.state;

    request("checklists/" + process.ID + "/item", "PATCH", {
      ITEMS: tasks.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    }).then(() => {
      tasks.sort((a, b) => a.SEQ - b.SEQ);
    });
  }

  render() {
    const {process, tasks} = this.state;

    if (process === null || tasks === null) {
      return <Loading />;
    }

    const seq = tasks ? Math.max(...tasks.map((item) => item.SEQ), 0) + 1 : 1;

    return (
      <div>
        <PageHeadings
          label={process.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Checklist",
                      onClick: () => this.archiveChecklist(),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Onboarding", url: "/onboarding"},
            {label: "Processes", url: "/onboarding/processes"},
          ]}
        />

        <OnboardingTaskModal
          checklist={process}
          onboarding={true}
          ref={(e) => (this.taskModal = e)}
          addState={(task) => this.setState({tasks: [...tasks, task]})}
          updateState={(id, line) => {
            this.setState({
              tasks: updateStateDelegator(tasks, id, line),
            });
          }}
        />

        <Card
          label="Process Tasks"
          description="Edit individual task items for this process"
          button={{
            label: "Add Task",
            onClick: () => this.taskModal.open(null, seq),
          }}
        >
          <Table
            draggable
            data={tasks}
            onDrag={async () => await this.saveSequence()}
            actionButtons={[
              {
                label: "Edit",
                onClick: (row) => this.taskModal.open(row, seq),
              },
            ]}
            columns={[
              {
                width: 1,
                value: "NAME",
                label: "Name",
              },
              {
                width: 1,
                value: "TYPE",
                label: "Type",
              },
              {
                value: "OPTIONS",
                label: "Options",
                format: (options, _) => {
                  if (!options) {
                    return "None";
                  }

                  return options.map((item) => item.NAME).join(", ");
                },
              },
              {
                value: "SEQ",
                label: "Sequence",
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(CreateProcessPage);

import React, {Component} from "react";
import {Formik} from "formik";
import PropTypes from "prop-types";
import {showErrorNotification} from "../../utils/notification-helper";
import {uploadImage} from "../../utils/request";
import EmployeeFormDropZone from "../employee-form-drop-zone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FILE_TYPES} from "../../utils/constants";
import {formatByteString} from "../../utils/util";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";

class ChatFileModal extends Component {
  state = {file: null};

  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  handleSubmit = async (values) => {
    const {file, fileName} = values;
    const {conversation} = this.props;

    if (!conversation.ID) {
      return;
    }

    try {
      await uploadImage("messaging/file/" + conversation.ID, file);
    } catch (err) {
      this.modal.stopLoading();

      return showErrorNotification(
        "Error Uploading File",
        "The file  failed to upload. Please make sure you are selecting an PDF, JPEG, JPG, PNG, or GIF file type."
      );
    }

    this.modal.close();
  };

  close() {
    this.modal.close();
  }

  render() {
    return (
      <Modal
        tooltip={{
          data: "Upload a file to the chat. We support PDF, PNG, JPEG, PNG, JPG, and GIF file types.",
        }}
        buttonLabel={"Send"}
        label={"Add File"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        closeLabel={"Cancel"}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{file: null, fileName: ""}}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {file, fileName} = values;

            return (
              <form onSubmit={handleSubmit}>
                {!file && (
                  <div className={"mt-2"}>
                    <EmployeeFormDropZone
                      name={"file"}
                      onChangeSoft={(file) => {
                        setFieldValue("fileName", file.name);
                        setFieldValue("file", file);
                      }}
                    />
                  </div>
                )}

                {file && (
                  <div className={"relative flex flex-row items-center h-20 bg-gray-100 rounded-md px-4"}>
                    <FontAwesomeIcon
                      icon={file.type === FILE_TYPES.PDF ? "fal fa-file-pdf" : "file-image"}
                      className={classNames(
                        file.type === FILE_TYPES.PDF ? "text-red-500" : "text-blue-500",
                        "h-6 w-6"
                      )}
                    />

                    <div className={"flex flex-col ml-4"}>
                      <div className={"text-sm"}>{fileName}</div>

                      <div className={"text-sm text-gray-700"}>
                        {"Size: " + formatByteString(file.size) + "Kb"}
                      </div>
                    </div>

                    <div className={"absolute top-2 right-2"}>
                      <FontAwesomeIcon
                        className={"flex w-4 h-4 text-gray-700 cursor-pointer"}
                        icon={"times"}
                        onClick={() => {
                          setFieldValue("file", null);
                          setFieldValue("fileName", "");
                        }}
                      />
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ChatFileModal.propTypes = {
  onSave: PropTypes.func,
};

export default ChatFileModal;

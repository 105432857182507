import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";

class RolesDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {exclude = [], includeOnly = null} = this.props;

    const serverRoles = await request("employeeroles/roles", "GET");
    let data = serverRoles;

    if (includeOnly) {
      data = data.filter((item) => includeOnly.includes(item.ID));
    }
    data = data
      .filter((item) => !exclude.includes(item.ID))
      .map((item) => {
        return {
          value: item.ID,
          id: item.ID,
          label: item.NAME,
        };
      });

    this.setState({data, raw: serverRoles});
  }

  fetchRole() {
    let {raw} = this.state;

    let {options, name, value} = this.props;
    if (options) {
      value = options.values[name];
    }

    return raw.find((item) => item.ID === parseInt(value));
  }

  render() {
    const {data} = this.state;
    const {multi} = this.props;

    if (this.props.disabled) {
      return (
        <>
          <div className="text-sm font-medium text-gray-700">Role</div>
          <div>{this.props.role.NAME} </div>
        </>
      );
    }

    if (multi) {
      return <FormSelect {...this.props} label="Roles" data={data} multi {...this.props} />;
    }

    return <ComboBox {...this.props} label="Role" data={data} {...this.props} />;
  }
}

RolesDropdown.propTypes = {
  ...ComboBox.propTypes,
};

export default RolesDropdown;

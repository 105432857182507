import React, {Component} from "react";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {GIFT_CARD_SALES_COLUMNS} from "../../features/reporting/reports/reporting-constants";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import {AUDIT_GIFT_CARD_SALES_COLUMNS} from "../../features/reporting/reports/audit-reporting-constants";

class GiftCardSalesReport extends Component {
  convertDataToRows = (reportData) => {
    let {REPORTING_MODE} = this.props.shop.settings;

    if (REPORTING_MODE === "AUDIT") {
      return [
        {
          label: "Gift Card Sales",
          numbers: reportData[0],
        },
      ];
    } else {
      let physicalGiftCardSales = reportData.TOTALS_BY_TYPE.find((x) => x.TYPE === "PHYSICAL_GIFT_CARD") ?? {
        ...EMPTY_RESULT,
      };

      let digitalGiftCardSales = reportData.TOTALS_BY_TYPE.find((x) => x.TYPE === "E_GIFT_CARD") ?? {
        ...EMPTY_RESULT,
      };

      let giftCardSales = reportData.TOTALS_BY_TYPE.find((x) => x.TYPE === "GIFT_CARD") ?? {...EMPTY_RESULT};

      let totalsFooter = {
        DISCOUNTS: physicalGiftCardSales.DISCOUNTS + digitalGiftCardSales.DISCOUNTS + giftCardSales.DISCOUNTS,
        FEES: physicalGiftCardSales.FEES + digitalGiftCardSales.FEES + giftCardSales.FEES,
        GROSS_SALES:
          physicalGiftCardSales.GROSS_SALES + digitalGiftCardSales.GROSS_SALES + giftCardSales.GROSS_SALES,
        NET_SALES: physicalGiftCardSales.NET_SALES + digitalGiftCardSales.NET_SALES + giftCardSales.NET_SALES,
        NET_TOTAL: physicalGiftCardSales.NET_TOTAL + digitalGiftCardSales.NET_TOTAL + giftCardSales.NET_TOTAL,
        ORDER_COUNT:
          physicalGiftCardSales.ORDER_COUNT + digitalGiftCardSales.ORDER_COUNT + giftCardSales.ORDER_COUNT,
        REFUNDS: physicalGiftCardSales.REFUNDS + digitalGiftCardSales.REFUNDS + giftCardSales.REFUNDS,
        TAXES: physicalGiftCardSales.TAXES + digitalGiftCardSales.TAXES + giftCardSales.TAXES,
        TOTAL_COLLECTED:
          physicalGiftCardSales.TOTAL_COLLECTED +
          digitalGiftCardSales.TOTAL_COLLECTED +
          giftCardSales.TOTAL_COLLECTED,
      };

      let rows = [
        {
          label: "Legacy Physical Gift Cards",
          numbers: physicalGiftCardSales,
        },
        {
          label: "Legacy Digital Gift Cards",
          numbers: digitalGiftCardSales,
        },
        {
          label: "Gift Cards",
          numbers: giftCardSales,
        },
        {
          label: "Totals",
          numbers: totalsFooter,
        },
      ];

      return rows;
    }
  };

  render() {
    let {REPORTING_MODE} = this.props.shop.settings;

    let endpoint = "report/productsales";
    let columns = GIFT_CARD_SALES_COLUMNS;

    if (REPORTING_MODE === "AUDIT") {
      endpoint = "report/giftcards";
      columns = AUDIT_GIFT_CARD_SALES_COLUMNS;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Gift Card Sales Report"} />
        </div>

        <FixedColumnReport
          hideCSVLink={true}
          filenamePrefix="gift-card-sales"
          endpoint={endpoint}
          constantColumns={columns}
          convertDataToRows={this.convertDataToRows}
          locationPicker={true}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(GiftCardSalesReport);

const EMPTY_RESULT = {
  DISCOUNTS: 0,
  FEES: 0,
  GROSS_SALES: 0,
  NET_SALES: 0,
  NET_TOTAL: 0,
  ORDER_COUNT: 0,
  REFUNDS: 0,
  TAXES: 0,
  TOTAL_COLLECTED: 0,
  GIFT_CARDS: 0,
};

import {FormInput, FormSelect, FormTextArea, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import FormStateSelect from "../../../components/form-state-select";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";

class AdminTollFreeVerificationModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    const {company} = this.props;

    values.optInImageUrls = [values.optInImageUrls];

    await request("admin/company/phone/verification/" + company.ID, "POST", {
      ...values,
    });

    this.props.onUpdate();

    this.modal.close();
  };

  render() {
    return (
      <Modal
        label="Toll-Free Verification"
        buttonLabel="Submit"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        large
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            businessName: "",
            businessStreetAddress: "",
            businessStreetAddress2: "",
            businessCity: "",
            businessStateProvinceRegion: null,
            businessPostalCode: "",
            businessCountry: "US",
            businessContactFirstName: "",
            businessContactLastName: "",
            businessContactEmail: "",
            businessContactPhone: "",
            businessWebsite: "",
            notificationEmail: "",
            useCaseCategories: ["MARKETING"], // TWO_FACTOR_AUTHENTICATION, ACCOUNT_NOTIFICATIONS, CUSTOMER_CARE, CHARITY_NONPROFIT, DELIVERY_NOTIFICATIONS, FRAUD_ALERT_MESSAGING, EVENTS, HIGHER_EDUCATION, K12, MARKETING,POLLING_AND_VOTING_NON_POLITICAL, POLITICAL_ELECTION_CAMPAIGNS, PUBLIC_SERVICE_ANNOUNCEMENT, SECURITY_ALERT
            useCaseSummary: "",
            productionMessageSample: "",
            optInImageUrls: "",
            messageVolume: "",
            additionalInformation: null,
            optInType: "WEB_FORM", //VERBAL, WEB_FORM, PAPER_FORM, VIA_TEXT, MOBILE_QR_CODE
          }}
          validationSchema={Yup.object().shape({
            businessName: Yup.string().nullable().required("Business Name is required.").max(500),
            businessStreetAddress: Yup.string().nullable().required("Street Address is required.").max(100),
            businessStreetAddress2: Yup.string().nullable().max(100),
            businessCity: Yup.string().nullable().required("City is required.").max(100),
            businessStateProvinceRegion: Yup.string().nullable("State is required.").required().max(2),
            businessPostalCode: Yup.number()
              .integer()
              .typeError("Zip code is must be a number.")
              .required("Zip code is required."),
            businessCountry: Yup.string().required("Country is required.").max(2),
            businessContactFirstName: Yup.string().nullable().required("Contact name is required.").max(500),
            businessContactLastName: Yup.string().nullable().required("Contact name is required.").max(500),
            businessContactEmail: Yup.string()
              .nullable()
              .required("Contact email is required.")
              .matches(/^\S+@\S+[.]\S+$/, "Email incorrect format."),
            businessContactPhone: Yup.string()
              .nullable()
              .required("Contact phone number is required.")
              .matches(/^\+[1-9]\d{1,14}$/, "Phone incorrect format."),
            businessWebsite: Yup.string()
              .nullable()
              .required("Contact website is required.")
              .max(500)
              .matches(/^https?:\/\/\S+$/, "Please make sure links include https:// to start"),
            notificationEmail: Yup.string()
              .nullable()
              .required("Notification email is required.")
              .max(500)
              .matches(/^\S+@\S+[.]\S+$/, "Email incorrect format."),
            useCaseCategories: Yup.array().required("Use case category is required."),
            useCaseSummary: Yup.string().nullable().required("Use case summary is required.").max(500),
            productionMessageSample: Yup.string()
              .nullable()
              .required("Sample message is required.")
              .max(1000),
            optInImageUrls: Yup.string()
              .nullable()
              .required("Image url is required")
              .max(998)
              .matches(/^https?:\/\/\S+$/, "Please make sure links include https:// to start"),
            optInType: Yup.string().required("Opt-in type is required."),
            messageVolume: Yup.string().nullable().required("Aprox. message volume is required."),
            additionalInformation: Yup.string().nullable(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="businessName" label="Business Legal Name" options={formikOptions} />

                <FormInput
                  name="businessStreetAddress"
                  label="Business Street Address"
                  options={formikOptions}
                />

                <FormInput
                  name="businessStreetAddress2"
                  label="Business Street Address Line 2"
                  hint="Optional"
                  options={formikOptions}
                />

                <FormRow>
                  <FormInput name="businessCity" label="Business City" options={formikOptions} flex />

                  <FormStateSelect
                    name="businessStateProvinceRegion"
                    label="BusinessState"
                    options={formikOptions}
                  />
                </FormRow>

                <FormRow>
                  <FormInput name="businessPostalCode" label="Zip Code" flex options={formikOptions} />

                  <FormInput
                    name="businessCountry"
                    label="Business Country"
                    disabled={true}
                    options={formikOptions}
                  />
                </FormRow>

                <FormRow>
                  <FormInput
                    name="businessContactFirstName"
                    label="Business Contact First Name"
                    options={formikOptions}
                    flex
                  />

                  <FormInput
                    name="businessContactLastName"
                    label="Business Contact Last Name"
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                <FormRow>
                  <FormInput
                    name="businessContactEmail"
                    label="Business Contact Email"
                    options={formikOptions}
                    flex
                  />

                  <FormInput
                    name="businessContactPhone"
                    label="Business Contact Phone"
                    tooltip={{
                      data: "Twilio has a specific format they use, it looks like this +18005551234 (make sure you include the plus sign)",
                    }}
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                <FormRow>
                  <FormInput name="businessWebsite" label="Business Website" options={formikOptions} flex />

                  <FormInput
                    name="useCaseCategories"
                    label="Use Case"
                    disabled={true}
                    options={formikOptions}
                  />
                </FormRow>

                <FormTextArea
                  name="useCaseSummary"
                  label="Use Case Summary"
                  tooltip={{
                    data: 'This is the summary of how the phone number will be used. For example: "This number is used to send out promotional offers and coupons to the customers of XYZ COMPANY"',
                  }}
                  options={formikOptions}
                />

                <FormTextArea
                  name="productionMessageSample"
                  label="Example Message"
                  options={formikOptions}
                  tooltip={{
                    data: 'This is an example message that the shop would send. Don\'t forget to append the Unsubscribe message to the end. For example: "Stop in at XYZ COMPANY tomorrow for a free Coffee! Reply UNSUBSCRIBE to opt out."',
                  }}
                />

                <FormInput
                  name="optInImageUrls"
                  label="Link to Opt-In Picutre"
                  tooltip={{
                    data: "Twilio needs a picture of the opt-in behavior. Text start to the phone number and take a screenshot. Upload it to google drive and make sure it is publicly visible. Copy the link to the image here.",
                  }}
                  options={formikOptions}
                />

                <FormRow>
                  <FormSelect
                    name="messageVolume"
                    label="Aprox. Monthly Message Volumne"
                    options={formikOptions}
                    flex
                    data={[
                      {label: "10", value: "10"},
                      {label: "100", value: "100"},
                      {label: "1,000", value: "1,000"},
                      {label: "10,000", value: "10,000"},
                      {label: "100,000", value: "100,000"},
                      {label: "250,000", value: "250,000"},
                      {label: "500,000", value: "500,000"},
                      {label: "750,000", value: "750,000"},
                      {label: "1,000,000", value: "1,000,000"},
                      {label: "5,000,000", value: "5,000,000"},
                      {label: "10,000,000+", value: "10,000,000+"},
                    ]}
                  />

                  <FormSelect
                    name="optInType"
                    label="Opt-In Type"
                    options={formikOptions}
                    data={[
                      {label: "Verbal", value: "VERBAL"},
                      {label: "Web Form", value: "WEB_FORM"},
                      {label: "Paper Form", value: "PAPER_FORM"},
                      {label: "Via Text", value: "VIA_TEXT"},
                      {label: "Mobile QR Code", value: "MOBILE_QR_CODE"},
                    ]}
                    flex
                  />
                </FormRow>

                <FormInput
                  name="notificationEmail"
                  label="Notification Email"
                  tooltip={{
                    data: "The email that twilio will send status updates about the submission to. If you don't want them, then enter ben@dripos.com",
                  }}
                  options={formikOptions}
                />

                <FormTextArea
                  name="additionalInformation"
                  label="Additional Information"
                  hint="Optional"
                  tooltip={{
                    data: "Additional info to help with the verification.",
                  }}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default AdminTollFreeVerificationModal;

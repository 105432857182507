import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Accordion extends Component {
  state = {expanded: false};

  componentDidMount() {
    const {defaultExpanded} = this.props;
    this.setState({expanded: defaultExpanded});
  }

  render() {
    const {expanded} = this.state;
    const {label, className} = this.props;

    // NOTE: keep accordion sections small, or they will be cut off. We need max-h-96 or the transitions break.

    return (
      <div className={classNames(className, "border border-gray-200 rounded-md")}>
        <div
          className={classNames(
            "focus:ring-4 focus:ring-gray-200 hover:bg-gray-100",
            expanded ? "border-b" : ""
          )}
        >
          <button
            type="button"
            className="flex items-center justify-between w-full px-4 py-2 font-medium rtl:text-right text-gray-500 gap-3 rounded-md"
            data-accordion-target="#accordion-collapse-body-2"
            aria-expanded="false"
            onClick={() => this.setState((prevState) => ({expanded: !prevState.expanded}))}
            aria-controls="accordion-collapse-body-2"
          >
            <span>{label}</span>

            <FontAwesomeIcon icon={expanded ? "chevron-down" : "chevron-right"} size={8} />
          </button>
        </div>

        <div
          className={classNames(
            "bg-gray-50 px-4 overflow-y-scroll transition-[max-height] delay-0 duration-500 ease-in-out",
            expanded ? "max-h-[30rem]" : "max-h-0"
          )}
        >
          <div className="py-2">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Accordion;

import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik, FieldArray} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class GiftCardPresetsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    const {presetOptions} = values;

    const payload = {
      GIFT_CARD_AMOUNT_PRESET: JSON.stringify(
        presetOptions.map((amount) => amount * 100) // Convert dollars to cents
      ),
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {GIFT_CARD_AMOUNT_PRESET} = this.props.shop.settings;

    // Parse and convert amounts from cents to dollars
    const presetOptions = JSON.parse(GIFT_CARD_AMOUNT_PRESET).map((giftCardAmount) => giftCardAmount / 100);

    const initialValues = {
      presetOptions,
    };

    const validationSchema = Yup.object().shape({
      presetOptions: Yup.array().of(
        Yup.number()
          .typeError("Gift card preset amount must be a number")
          .required("Gift card preset amount is required")
          .test("max-decimals", "Gift amount cannot have more than two decimal places", (value) => {
            if (value === undefined || value === null) return true;
            return /^\d+(\.\d{1,2})?$/.test(value.toString());
          })
      ),
    });

    return (
      <Modal
        label="Gift Card Preset Amount Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FieldArray
                  name="presetOptions"
                  render={(arrayHelpers) => (
                    <div>
                      {values.presetOptions.map((option, index) => (
                        <FormInput
                          key={index}
                          label={`Gift Card Preset Option ${index + 1}`}
                          name={`presetOptions.${index}`}
                          options={formikOptions}
                          error={
                            formikOptions.errors.presetOptions && formikOptions.errors.presetOptions[index]
                          }
                          touched={
                            formikOptions.touched.presetOptions && formikOptions.touched.presetOptions[index]
                          }
                        />
                      ))}
                    </div>
                  )}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(GiftCardPresetsModal);

import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment";
import {withRouter} from "../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class TicketsTable extends Component {
  render() {
    const {tickets} = this.props;

    return (
      <Table
        pagination
        data={tickets}
        ref={(e) => (this.tableRef = e)}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => this.props.router.navigate("/ticket/" + row.UNIQUE_ID),
          },
        ]}
        columns={[
          {
            label: "Amount",
            value: "PAYMENT_INTENT",
            format: (payment, row) => "$" + toDollars(row.TOTAL ?? payment?.TOTAL),
          },
          {
            label: "Location",
            value: "LOCATION_NAME",
          },
          {label: "Name", value: "NAME"},
          {
            label: "Ticket & Platform",
            value: "TICKET_TYPE_NAME",
            format: (name, row) => {
              return `${name} - ${row.PLATFORM ? row.PLATFORM.capitalize() : "POS"}`;
            },
          },
          {
            label: "Date Created",
            value: "DATE_CREATED",
            format: (val) => moment(val).format("hh:mma ddd MMM Do"),
          },
        ]}
      />
    );
  }
}

TicketsTable.propTypes = {
  tickets: PropTypes.array.isRequired,
};

export default withRouter(TicketsTable);

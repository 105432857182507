import {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";

class MessageDate extends Component {
  render() {
    const {date, messageDelta} = this.props;
    const dateMoment = moment(date);

    if (messageDelta < 1000 * 60 * 60) {
      return <div className="flex">{dateMoment.format("h:mma")}</div>;
    }

    if (messageDelta < 1000 * 60 * 60 * 6) {
      return <div className="flex">{dateMoment.format("dddd") + " at " + dateMoment.format("h:mma")}</div>;
    }

    return (
      <div className="flex">{dateMoment.format("ddd, MMM Do") + " at " + dateMoment.format("h:mma")}</div>
    );
  }
}

MessageDate.propTypes = {
  date: PropTypes.string.isRequired,
  delta: PropTypes.number.isRequired,
};

export default MessageDate;

export const Box = ({children, className = "", onClick}) => {
  return (
    <div className={`flex ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export const Row = ({children, className = "", onClick}) => {
  return (
    <div className={`flex flex-row ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export const Col = ({children, className = ""}) => {
  return <div className={`flex flex-col ${className}`}>{children}</div>;
};

const HeaderText = ({text}) => (
  <div className="flex-1 pl-4 text-left text-gray-500 py-2 text-sm font-medium">{text}</div>
);

/**
 *
 * @param columns - list of objects, requires the label key
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({columns}) => (
  <Row className="w-full justify-between border-b bg-gray-50 border-gray-200">
    {columns.map(({label}) => (
      <HeaderText text={label} />
    ))}
  </Row>
);

const LineText = ({text, isLast = false}) => (
  <div className={`flex-1 pl-4 text-left py-2 text-sm ${!isLast ? "border-r border-gray-300" : ""}`}>
    {text}
  </div>
);

/**
 *
 * @param columns - list of objects with fields key, format, and label
 * @param line - the line of data to use
 * @returns {JSX.Element}
 * @constructor
 */
const Line = ({columns, line, className = "", onClick}) => (
  <Row className={"w-full border-b " + className} onClick={onClick}>
    {columns.map(({key, format = (it) => it, formatRow}, idx) => (
      <LineText text={formatRow ? formatRow(line) : format(line[key])} isLast={idx === columns.length - 1} />
    ))}
  </Row>
);

export const ReadOnlyTable = ({columns, data, onRowClick, className = ""}) => {
  return (
    <Box className={"bg-white " + className}>
      <Col className="w-full border border-gray-200 rounded">
        <Header columns={columns} />
        {data.map((dataLine) => (
          <Line
            columns={columns}
            line={dataLine}
            onClick={() => (onRowClick ? onRowClick(dataLine) : null)}
            className={onRowClick && "cursor-pointer"}
          />
        ))}
      </Col>
    </Box>
  );
};

import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import {getDefaultVerbiageArray, getVerbiageArray} from "../../../utils/sales-helper";

class TicketVerbiageModal extends Component {
  state = {ticketType: {}, verbiages: []};

  open(ticketType, verbiages) {
    this.setState({ticketType, verbiages});
    this.modal.open();
  }

  getInitialVerbiageValues() {
    let {ticketType, verbiages} = this.state;

    let verbiageArr = getVerbiageArray(ticketType, verbiages);
    let statusInitialValues = {};

    for (let statusIndex in verbiageArr) {
      statusInitialValues[`verbiageLabel${statusIndex}`] = verbiageArr[statusIndex].LABEL;
      statusInitialValues[`verbiageDescription${statusIndex}`] = verbiageArr[statusIndex].DESCRIPTION;
    }

    return statusInitialValues;
  }

  handleSubmit = async (values) => {
    let {ticketType} = this.state;

    let defaultArray = getDefaultVerbiageArray(ticketType);

    let verbiageArray = [];
    for (let statusIndex in defaultArray) {
      let optionLabel = values[`verbiageLabel${statusIndex}`];
      let optionDescription = values[`verbiageDescription${statusIndex}`];

      let setLabel;
      let setDescription;

      if (optionLabel != defaultArray[statusIndex].LABEL) {
        setLabel = optionLabel;
      }

      if (optionDescription != defaultArray[statusIndex].DESCRIPTION) {
        setDescription = optionDescription;
      }

      verbiageArray.push({
        STAGE_INDEX: statusIndex,
        LABEL: setLabel,
        DESCRIPTION: setDescription,
      });
    }

    await request("types/order/verbiages/" + ticketType.ID, "POST", {
      VERBIAGE_ARRAY: verbiageArray,
    });

    await this.props.handleUpdate();
    this.modal.close();
  };

  render() {
    let {ticketType, verbiages} = this.state;

    let initial = this.getInitialVerbiageValues();
    let verbiageArray = getVerbiageArray(ticketType, verbiages);

    return (
      <Modal
        label={`Edit ${ticketType?.NAME} Status Text`}
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik innerRef={(e) => (this.formikRef = e)} initialValues={initial} onSubmit={this.handleSubmit}>
          {(formikOptions) => {
            return (
              <div>
                {verbiageArray.map((item, index) => (
                  <div className="mb-4">
                    <FormInput name={`verbiageLabel${index}`} label="Label" options={formikOptions} />

                    <div className="text-sm font-medium text-gray-700 mt-2">Description</div>

                    <div className="flex flex-row items-center mt-0">
                      <div className="text-sm font-medium text-gray-700 mr-3">Your order</div>

                      <FormInput
                        name={`verbiageDescription${index}`}
                        className="mt-0 flex-1"
                        options={formikOptions}
                      />
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TicketVerbiageModal;

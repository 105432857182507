import React, {Component} from "react";
import {FormInput, Modal, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import {IngredientCategoryRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {updateIngredientCategories, upsertIngredientCategory} from "../../../../redux/supply";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";

class IngredientCategoryModal extends Component {
  state = {group: null, categories: []};

  open(categories = [], group = null) {
    this.formikRef && this.formikRef.resetForm();
    this.setState({group, categories}, () => this.modal.open());
  }

  createIngredientGroup({name, wasteTarget, cogsTarget}) {
    IngredientCategoryRequests.createCategory({
      NAME: name,
      WASTE_TARGET: wasteTarget,
      COGS_TARGET: cogsTarget,
    })
      .then((category) => {
        showSuccessNotification("Group Created Successfully.", name + " was created successfully.");
        this.setState((prevState) => {
          const {categories: prevCategories} = prevState;
          return {categories: [category, ...prevCategories]};
        });
        return getStore().dispatch(upsertIngredientCategory(category));
      })
      .catch((err) => {
        showErrorNotification(
          "Something went wrong.",
          "Something went wrong creating the group. Please try again."
        );
      })
      .finally(() => {
        this.modal && this.modal.close();
      });
  }

  saveIngredientGroup(groupId, {name, wasteTarget, cogsTarget}) {
    const {syncState} = this.props;
    IngredientCategoryRequests.updateCategory(groupId, {
      NAME: name,
      WASTE_TARGET: wasteTarget,
      COGS_TARGET: cogsTarget,
    })
      .then((category) => {
        showSuccessNotification("Group Edited Successfully.", name + " was edited successfully.");

        return getStore().dispatch(upsertIngredientCategory(category));
      })
      .catch((err) => {
        showErrorNotification(
          "Something went wrong.",
          "Something went wrong editing the group. Please try again."
        );
      })
      .finally(() => {
        syncState();
        this.modal && this.modal.close();
      });
  }

  render() {
    const {group, categories} = this.state;
    const trashGroup = {
      label: <FontAwesomeIcon icon="fa-regular fa-trash" color={"red"} />,
      onClick: async (group) => {
        try {
          const close = await showLoadingConfirmAlert(
            `Delete Group: ${group.NAME}`,
            "Are you sure you want to delete this group? This cannot be undone."
          );
          await IngredientCategoryRequests.deleteCategory(group.ID);
          this.setState((prevState) => {
            const {categories: prevCategories} = prevState;
            return {categories: prevCategories.filter((category) => category !== group)};
          });
          await getStore().dispatch(updateIngredientCategories());
          close();
          await showSuccessNotification(`Group ${group.NAME} successfully deleted.`);
        } catch (e) {
          await showSuccessNotification(`Group ${group.NAME} not deleted.`);
        }
      },
    };

    return (
      <Modal
        buttonLabel={"Add New Group"}
        label={"Manage Ingredient Groups"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={(values) => this.createIngredientGroup(values)}
          initialValues={{
            name: group?.NAME,
            wasteTarget: group?.WASTE_TARGET,
            cogsTarget: group?.COGS_TARGET,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().nullable().required("Please enter a name for this group."),
            wasteTarget: Yup.number().nullable().positive("Please enter a positive number."),
            cogsTarget: Yup.number().nullable().positive("Please enter a positive number."),
          })}
        >
          {(formikOptions) => {
            return (
              <form>
                <FormInput
                  name="name"
                  label="New Group Name"
                  tooltip="Groups are used to categorize ingredients for filtering and search purposes."
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
        <Table
          data={categories}
          className="mt-4"
          columns={[{value: "NAME", label: "Groups"}]}
          actionButtons={[trashGroup]}
        />
      </Modal>
    );
  }
}

export default IngredientCategoryModal;

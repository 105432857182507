import React, {Component} from "react";
import {Card, Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";

import {withRouter} from "../../../../utils/navigation";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import SubscriptionListDetailsModal from "../../../../modals/marketing/subscription-lists/subscription-list-details-modal";
import {MarketingRequests} from "../../../../utils/request-helpers/marketing/marketing-requests";
import {getColumn} from "../../../../tables/columns/column-helper";

class SubscriptionListsPage extends Component {
  state = {isLoading: true, lists: []};

  componentDidMount() {
    this.setState({isLoading: true});

    this.syncState();
  }

  async syncState() {
    const lists = await MarketingRequests.getMarketingLists();

    this.setState({lists: lists ?? [], isLoading: false});
  }

  formatType(val, row) {
    if (val) {
      return <SuccessBadge>Default</SuccessBadge>;
    }

    if (row.SEGMENT) {
      return <DangerBadge blue>Segment</DangerBadge>;
    }

    return <DangerBadge yellow>Additional</DangerBadge>;
  }

  formatUseCase(val, row) {
    return val === 1 ? "Email" : "Text";
  }

  renderBody() {
    const {lists} = this.state;

    const columns = [
      getColumn("NAME", "Name"),
      getColumn("IS_DEFAULT", "Type", this.formatType),
      getColumn("PATRON_COUNT", "Number of Patrons"),
    ];

    const buttons = [
      {
        label: "Create List",
        onClick: () => this.subscriptionModal.open(),
      },
    ];

    const tooltip = {
      data: [
        {
          data: "Subscribed Patrons allow you to send messages to a subset of your subscribed patrons.",
          label: "Subscribed Patrons",
        },
        {
          data:
            "Your default list will contain all of your patrons who have manually subscribed to text" +
            " marketing.",
          label: "Default List",
        },
        {
          data:
            "Additional lists can contain some or all of the subscribed patrons from the default list. Patrons" +
            " can be on more than one additional list.",
          label: "Additional Lists",
        },
      ],
    };

    const actionButtons = [
      {
        label: "View",
        onClick: (row) => this.props.router.navigate("/subscriber-list/" + row.UNIQUE_ID),
      },
    ];

    const EmailSegmentsTab = () => (
      <Card
        label={"Email Segments"}
        description={"Lists of subscribers that you can send email campaigns to"}
        tooltip={tooltip}
        buttons={buttons}
      >
        <Table
          columns={columns}
          data={lists.filter((it) => it.EMAIL_ONLY === 1)}
          actionButtons={actionButtons}
          pagination
        />
      </Card>
    );

    const TextSegmentsTab = () => (
      <Card
        label={"Text Segments"}
        description={"Lists of subscribers that you can send text campaigns to"}
        tooltip={tooltip}
        buttons={buttons}
      >
        <Table
          columns={columns}
          data={lists.filter((it) => it.EMAIL_ONLY !== 1)}
          actionButtons={actionButtons}
          pagination
        />
      </Card>
    );

    const tabs = {
      email: EmailSegmentsTab,
      text: TextSegmentsTab,
    };

    return (
      <>
        <SubscriptionListDetailsModal
          ref={(e) => (this.subscriptionModal = e)}
          onSubmit={this.syncState.bind(this)}
        />

        <PageHeadings
          label={"Subscribed Patrons"}
          description={`Manage your subscriber lists for your marketing campaigns`}
        />

        <Tab
          data={[
            {id: "email", label: "Email Segments"},
            {id: "text", label: "Text Segments"},
          ]}
        >
          {(tab) => tabs[tab ?? "email"]()}
        </Tab>
      </>
    );
  }

  render() {
    const {isLoading} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return this.renderBody();
  }
}

export default withRouter(SubscriptionListsPage);

import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Checkbox from "../../../components/form-elements/checkbox";
import DangerBadge from "../../../components/badges/danger-badge";
import {request} from "../../../utils/request";
import {showConfirmAlert, showSuccessAlert} from "../../../utils/alert-helper";
import * as Yup from "yup";

class RefundModal extends Component {
  state = {ticket: null, itemsToRefund: [], openCashDrawers: []};

  open(ticket) {
    this.setState({ticket}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  componentDidMount() {
    request("cashboxManagement/dashboard/active/all", "GET").then((openCashDrawers) =>
      this.setState({openCashDrawers})
    );
  }

  verifyRefund = (serverTicket) => {
    const {itemsToRefund} = this.state;

    const refundedItems = serverTicket.ITEMS.filter((item) => itemsToRefund.includes(item.ID));

    return !refundedItems.some((item) => item.DATE_REFUNDED === null);
  };

  calculateCashRefunded(sources) {
    if (!sources) return 0;

    return sources.reduce((accum, item) => {
      if (item.TYPE === "CASH") {
        return accum + item.AMOUNT_REFUNDED;
      }

      return accum;
    }, 0);
  }

  calculateCashToRefund(sources) {
    if (!sources) return 0;

    return sources.reduce((accum, item) => {
      if (item.TYPE === "CASH") {
        return accum + item.AMOUNT;
      }

      return accum;
    }, 0);
  }

  handleSubmit = async (values) => {
    let {ticket} = this.state;
    let {type, amount, drawer, notes} = values;
    let cashRefunded;
    let {itemsToRefund} = this.state;

    if (type === REFUND_TYPES.DOLLAR) {
      const {serverTicket, sourcesRefunded} = await request(
        `tickets/${ticket.UNIQUE_ID}/refund/custom`,
        "POST",
        {
          AMOUNT: decimalToDollars(amount),
          DRAWER: drawer,
          NOTES: notes,
        }
      );

      cashRefunded = this.calculateCashRefunded(sourcesRefunded);
    }

    if (type === REFUND_TYPES.ITEM) {
      const {serverTicket, sourcesRefunded} = await request(`tickets/${ticket.ID}/refund`, "POST", {
        SEND_TEXT: true,
        ITEMS: Array.from(itemsToRefund),
        DRAWER: drawer,
        NOTES: notes,
      });

      if (!this.verifyRefund(serverTicket)) {
        await showConfirmAlert(
          "Refund Failed",
          "Could not refund gift card purchase. The gift card balance was less than the purchase price. All other requested items were refunded.",
          "Close"
        );
      }

      cashRefunded = this.calculateCashRefunded(sourcesRefunded);
    }

    await this.props.handleUpdate();
    this.modal.close();

    if (cashRefunded) {
      showSuccessAlert(
        toDollars(cashRefunded, true) + " cash refunded.",
        toDollars(cashRefunded, true) + " in cash should be refunded to the customer."
      );
    }
  };

  renderDollarType(formikOptions) {
    let {ticket} = this.state;

    let refundAmount = toDollars(
      ticket?.PAYMENT_INTENT?.TOTAL - ticket?.PAYMENT_INTENT?.AMOUNT_REFUNDED,
      true
    );

    return (
      <div>
        <div className="mt-3">
          <div className="block text-sm font-medium text-gray-700">Available for Refund</div>

          <div className="text-sm">{refundAmount}</div>
        </div>

        <FormInput name={"amount"} label="Refund Amount" options={formikOptions} />
      </div>
    );
  }

  renderItemType(formikOptions) {
    let {ticket} = this.state;

    return (
      <div className="mt-3">
        {ticket?.ITEMS.map((item) => {
          const isRefunded = item.DATE_REFUNDED !== null;

          return (
            <div className="py-1 flex flex-row justify-between text-sm items-center">
              <div className="flex flex-row items-center">
                {!isRefunded && (
                  <Checkbox
                    onChange={() => {
                      let {itemsToRefund} = this.state;

                      if (itemsToRefund.includes(item.ID)) {
                      } else {
                        itemsToRefund.push(item.ID);
                      }

                      this.setState({itemsToRefund});
                    }}
                  />
                )}

                {isRefunded && <div className="w-6" />}
                <div className="mr-4">{item.NAME}</div>

                {isRefunded && <DangerBadge>Refunded</DangerBadge>}
              </div>

              <div>{toDollars(item.TOTAL, true)}</div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let {ticket, openCashDrawers} = this.state;

    const cashToRefund = this.calculateCashToRefund(ticket?.PAYMENT_INTENT?.PAYMENTS);

    return (
      <Modal
        label="Refund Ticket"
        buttonLabel="Send Refund"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          initialValues={{type: "item", name: null, drawer: null, notes: ""}}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            type: Yup.string().required(),
            drawer: Yup.number().nullable(),
            notes: Yup.string().nullable(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            const {type} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name={"type"}
                  label={"Type"}
                  data={[
                    {label: "By Item", value: REFUND_TYPES.ITEM},
                    {label: "By Dollar", value: REFUND_TYPES.DOLLAR},
                  ]}
                  options={formikOptions}
                />

                <FormInput name="notes" label="Notes" hint="Optional" options={formikOptions} />

                {cashToRefund > 0 && openCashDrawers.length > 0 && (
                  <FormSelect
                    name={"drawer"}
                    label={"Drawer to use to Refund Cash Transaction"}
                    data={openCashDrawers.map((drawer) => {
                      return {label: drawer.DEVICE_NAME, value: drawer.ID};
                    })}
                    hint={"Optional"}
                    options={formikOptions}
                  />
                )}

                {type === "dollar" && this.renderDollarType(formikOptions)}

                {type === "item" && this.renderItemType(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default RefundModal;

const REFUND_TYPES = {
  ITEM: "item",
  DOLLAR: "dollar",
};

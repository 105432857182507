import React, {Component} from "react";
import {CheckIcon, SelectorIcon} from "@heroicons/react/solid";
import {Combobox} from "@headlessui/react";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import ReactResizeDetector from "react-resize-detector";
import PropTypes from "prop-types";
import {Float} from "@headlessui-float/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ComboBox extends Component {
  state = {
    query: "",
    offset: 0,
    force: false,
    cooldown: false,
    width: 0,
    focus: false,
  };
  lastForce = Date.now();

  constructor(props) {
    super(props);

    this.id = "cb_" + randomString(24);
  }

  componentDidMount() {
    this.buttonRef.disabled = true;
  }

  onChange(val) {
    const {options, name, onCreate, onChangeSoft, onChange} = this.props;
    const {query} = this.state;

    if (val === -1) {
      onCreate(query);

      return this.setState({force: false, cooldown: true}, () => {
        setTimeout(() => {
          this.setState({cooldown: false});
        }, 250);
      });
    }

    onChangeSoft && onChangeSoft(val);

    if (options) {
      options.setFieldValue(name, val.id);
    } else {
      onChange(val.id, val);
    }

    this.lastForce = Date.now();
    this.setState({value: val, force: false, query: "", cooldown: true}, () => {
      setTimeout(() => {
        this.setState({cooldown: false});
      }, 250);
    });
  }

  renderOption(person) {
    return (
      <div>
        <Combobox.Option
          key={person.id}
          value={person}
          disabled={person.disabled}
          className={({active}) =>
            classNames(
              "relative cursor-default select-none py-2 pl-3 pr-9",
              active ? "bg-indigo-600 text-white" : "text-gray-900",
              person.disabled ? "bg-gray-100" : "text-gray-900"
            )
          }
          style={{minHeight: "2.5rem"}}
        >
          {({active, selected}) => (
            <div>
              <div className="flex items-center">
                {person.logo && (
                  <img src={person.logo} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                )}

                <span className={classNames("ml-3 truncate font-medium", selected && "font-semibold")}>
                  {person.label}
                </span>

                {person.tag && <span className="ml-3">{person.tag}</span>}
              </div>

              {selected && (
                <span
                  className={classNames(
                    "absolute inset-y-0 right-0 flex items-center pr-4",
                    active ? "text-white" : "text-indigo-600"
                  )}
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </div>
          )}
        </Combobox.Option>
      </div>
    );
  }

  render() {
    const {query, force, cooldown, offset, focus} = this.state;
    const {
      sections = null,
      data: rawData = [],
      disabled,
      options,
      name,
      placeholder,
      createLabel,
      compact,
      strategy = "fixed",
      headerIcon,
      iconStyle = "",
      onQueryChange,
    } = this.props;

    let data = rawData;
    if (sections) {
      data = sections.reduce((accum, item) => {
        return [...accum, ...item.data];
      }, []);
    }

    return (
      <FormElement id={this.id} iconPadding {...this.props}>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          const filteredData =
            query === "" || onQueryChange
              ? data
              : data.filter((person) => {
                  return person.label.toLowerCase().includes(query.toLowerCase());
                });

          let filteredSections = sections;
          if (query !== "" && sections) {
            filteredSections = filteredSections
              .map((item) => {
                const itemFilter = item.data.filter((person) => {
                  return person.label.toLowerCase().includes(query.toLowerCase());
                });

                return {...item, data: itemFilter};
              })
              .filter((item) => {
                return item.data.length > 0;
              });
          }

          let actualData = data.find((item) => item.id === value);

          return (
            <ReactResizeDetector handleWidth handleHeight>
              {({width}) => (
                <Combobox
                  as="div"
                  ref={(e) => (this.comboRef = e)}
                  onBlur={() => {
                    this.lastTouch = Date.now();
                    // options && options.setFieldTouched(name);
                  }}
                  disabled={cooldown || disabled}
                  value={focus ? null : actualData}
                  onChange={(val) => {
                    this.onChange(val);
                  }}
                >
                  {({open}) => {
                    return (
                      <Float
                        strategy={strategy}
                        onShow={() => {
                          this.setState({focus: true});
                        }}
                        onHide={() => {
                          this.setState({focus: false}, () => {
                            options && options.setFieldTouched(name);
                          });
                        }}
                        offset={8}
                        shift={8}
                        placement="bottom-start"
                        portal={"#" + this.id}
                      >
                        <div className={classNames(compact ? "" : "mt-1", "relative")}>
                          <Combobox.Input
                            ref={(e) => (this.inputRef = e)}
                            autoComplete="off"
                            placeholder={placeholder}
                            className={classNames(
                              "w-full border bg-white py-2 pl-3 pr-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm",
                              compact ? "border-transparent" : "border-gray-300 rounded-md shadow-sm",
                              disabled ? "text-neutral-text" : undefined
                            )}
                            onChange={(event) => {
                              this.lastChange = Date.now();

                              this.setState(
                                {query: event.target.value},
                                () => this.props.onQueryChange && this.props.onQueryChange(event.target.value)
                              );
                            }}
                            displayValue={(person) => {
                              return person ? person.label : query;
                            }}
                            onFocus={(e) => {
                              if (!this.lastTouch || this.lastTouch + 100 < Date.now()) {
                                this.setState(
                                  {
                                    query: "",
                                    focus: true,
                                    offset: window.scrollY,
                                  },
                                  () => {
                                    this.buttonRef.disabled = false;
                                    this.buttonRef.click();
                                    this.buttonRef.disabled = true;
                                  }
                                );
                              }
                            }}
                          />

                          <div
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <Combobox.Button
                              ref={(e) => {
                                this.buttonRef = e;

                                if (e) {
                                  e.disabled = true;
                                }
                              }}
                              className="absolute pointer-events-none inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                            >
                              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                          </div>
                        </div>

                        {open || force ? (
                          <Combobox.Options>
                            <div
                              style={{
                                width,
                              }}
                              className={classNames(
                                "z-10 max-h-56 overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                                compact ? "rounded-none my-0" : "rounded-md"
                              )}
                            >
                              {createLabel && query.length > 0 ? (
                                <Combobox.Option
                                  key={-1}
                                  value={-1}
                                  className={({active}) =>
                                    classNames(
                                      "relative cursor-default select-none py-2 pl-3 pr-9",
                                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                                    )
                                  }
                                >
                                  {({active, selected}) => (
                                    <div>
                                      <div className="flex items-center">
                                        <span
                                          className={classNames(
                                            "ml-3 truncate font-medium",
                                            selected && "font-semibold"
                                          )}
                                        >
                                          {createLabel} "{query}"
                                        </span>
                                      </div>

                                      {selected && (
                                        <span
                                          className={classNames(
                                            "absolute inset-y-0 right-0 flex items-center pr-4",
                                            active ? "text-white" : "text-indigo-600"
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Combobox.Option>
                              ) : (
                                <div />
                              )}

                              {sections !== null ? (
                                <div>
                                  {filteredSections.map((item) => {
                                    return (
                                      <div>
                                        <div
                                          className={
                                            "ml-0 border-gray-300 text-indigo-500 text-sm font-bold py-2"
                                          }
                                        >
                                          {!!headerIcon && (
                                            <FontAwesomeIcon
                                              icon={headerIcon}
                                              className={classNames("px-1", iconStyle)}
                                            />
                                          )}
                                          {item.title}
                                        </div>

                                        {item.data.map((person) => {
                                          return this.renderOption(person);
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                filteredData.map((person) => {
                                  return this.renderOption(person);
                                })
                              )}
                            </div>
                          </Combobox.Options>
                        ) : (
                          <div />
                        )}
                      </Float>
                    );
                  }}
                </Combobox>
              )}
            </ReactResizeDetector>
          );
        }}
      </FormElement>
    );
  }
}

ComboBox.propTypes = {
  ...FormElement.propTypes,

  onChangeSoft: PropTypes.func,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,

      logo: PropTypes.string,
    })
  ),
};

export default ComboBox;

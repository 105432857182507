import React, {Component} from "react";
import {getURL, request} from "../../../../utils/request";
import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {saveAs} from "file-saver";
import {withRouter} from "../../../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../../redux";
import TransferPreview from "../../../../features/operations/supply-chain/transfers/transfer-preview";
import TransferLinesModal from "../../../../modals/operations/supply-chain/transfers/transfer-lines-modal";
import SendTransferModal from "../../../../modals/operations/supply-chain/transfers/send-transfer-modal";

const TRANSFER_STATUSES = {
  REQUESTED: "REQUESTED",
  OPEN: "OPEN",
  PACKED: "PACKED",
  SENT: "SENT",
  RECEIVED: "RECEIVED",
  PARTIAL_FULFILL: "PARTIAL_FULFILL",
  FULFILLED: "FULFILLED",
};

const TRANSFER_STATUS_LABELS = {
  REQUESTED: "Requested",
  OPEN: "Open",
  PACKED: "Packed",
  SENT: "Sent",
  RECEIVED: "Received",
  PARTIAL_FULFILL: "Partially Fulfilled",
  FULFILLED: "Fulfilled",
};

class PurchaseOrderPage extends Component {
  state = {
    transfer: null,
  };

  DOWNLOAD_BUTTON = {
    label: "Download PDF",
    onClick: () => {
      this.downloadTransfer();
    },
  };

  SEND_BUTTON = {
    label: "Send Transfer",
    onClick: () => this.sendTransferModal.open(this.state.transfer),
  };

  DELETE_BUTTON = {
    label: "Delete Transfer",
    onClick: () => {
      this.deleteTransfer();
    },
  };

  RECEIVE_BUTTON = {
    label: "Receive Transfer",
    onClick: () => {
      this.receiveTransfer();
    },
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {UNIQUE_ID: uniqueId} = this.props.router.params;
    const transfer = await request("inventory-transfers/" + uniqueId, "GET");
    this.setState({transfer});
  }

  async deleteTransfer() {
    const {transfer} = this.state;
    await request("inventory-transfers/" + transfer.UNIQUE_ID, "DELETE");

    this.props.router.navigate("/transfers");
  }

  downloadTransfer() {
    const {transfer} = this.state;
    saveAs(getURL() + "inventory-transfers/" + transfer.UNIQUE_ID + "/pdf", "pdf");
  }

  async receiveTransfer() {
    const {transfer} = this.state;
    this.props.router.navigate("/receive-transfer/" + transfer.UNIQUE_ID);
  }

  render() {
    const {transfer} = this.state;
    const {location} = this.props.shop;

    if (!transfer) {
      return <Loading />;
    }

    const buttons = [];

    if (transfer.STATUS === TRANSFER_STATUSES.OPEN) {
      buttons.push(this.SEND_BUTTON, this.DELETE_BUTTON);
    } else if (transfer.STATUS === TRANSFER_STATUSES.SENT && transfer.RECEIVER_LOCATION_ID === location.ID) {
      buttons.push(this.RECEIVE_BUTTON);
    }

    buttons.push(this.DOWNLOAD_BUTTON);
    const canEdit = transfer.STATUS === TRANSFER_STATUSES.OPEN;

    return (
      <div>
        <SendTransferModal ref={(e) => (this.sendTransferModal = e)} syncState={() => this.syncState()} />

        <TransferLinesModal ref={(e) => (this.transferLinesModal = e)} syncState={() => this.syncState()} />

        <PageHeadings
          label="Transfer"
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Transfers", url: "/transfers"},
          ]}
          buttons={[
            buttons[0],
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: buttons,
                },
              ],
            },
          ]}
        />

        <TwoColumnList
          label="Transfer Information"
          data={[
            {label: "Recipient Location", value: transfer.RECEIVER_LOCATION.NAME},
            {
              value: transfer.TRANSFER_NUMBER,
              label: "Number",
            },
            {
              value: TRANSFER_STATUS_LABELS[transfer.STATUS],
              label: "Status",
            },
            {
              label: "Total Price",
              value: toDollars(transfer.TOTAL, true),
            },
          ]}
          buttons={
            canEdit
              ? [
                  {
                    label: "Edit Information",
                    onClick: () => this.props.router.navigate("/transfer/" + transfer.UNIQUE_ID),
                  },
                ]
              : []
          }
        />

        <Tab
          data={[
            {id: "items", label: "Items"},
            {id: "preview", label: "Preview"},
          ]}
        >
          {(id) => {
            if (id === "items") {
              const columns = [
                {value: "NAME", label: "Name"},
                {value: "QUANTITY", label: "Quantity"},
                {
                  value: "",
                  label: "Current Stock",
                  format: (_, row) => row.INGREDIENT.CURRENT_STOCK,
                },
                {value: "TOTAL", label: "Total", type: "dollars"},
              ];

              if (transfer.STATUS === TRANSFER_STATUSES.RECEIVED) {
                columns.push(
                  {
                    value: "RECEIVED_QUANTITY",
                    label: "Received Quantity",
                    format: (val) => val ?? "Not Received",
                  },
                  {
                    value: "RECEIVED_TOTAL",
                    label: "Received Total",
                    format: (val) => (val ? toDollars(val, true) : "Not Received"),
                  }
                );
              }

              return (
                <Card
                  label="Transfer Items"
                  description="See the items on this purchase order"
                  button={{
                    label: "Edit Items",
                    onClick: () => this.transferLinesModal.open(transfer),
                  }}
                >
                  <Table data={transfer.ITEMS} columns={columns} />
                </Card>
              );
            } else if (id === "preview") {
              return <TransferPreview transfer={transfer} {...this.props} />;
            }
            return <></>;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(withRouter(PurchaseOrderPage));

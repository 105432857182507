import {PageHeadings, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {GiftCardsTab} from "./gift-cards-tab";
import {PatronsTab} from "./patrons-tab";

export const MarketingPage = () => {
  const TABS = [
    {
      id: "GIFT_CARDS",
      label: "Gift Cards",
    },
    {
      id: "PATRONS",
      label: "Patrons",
    },
  ];

  const selectTab = (id) => {
    switch (id) {
      case "GIFT_CARDS":
        return <GiftCardsTab />;
      case "PATRONS":
        return <PatronsTab />;
      default:
        return <GiftCardsTab />;
    }
  };
  return (
    <div className={"m-12"}>
      <PageHeadings label="Marketing Tools" />

      <Tab className={"mt-1"} data={TABS}>
        {(id) => selectTab(id)}
      </Tab>
    </div>
  );
};

import {withRouter} from "../../../utils/navigation";
import React, {Component} from "react";
import {Card, PageHeadings, Tab, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {request, sanitizeName} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showConfirmAlert, showSuccessAlert} from "../../../utils/alert-helper";
import {setupReduxConnection} from "../../../redux";
import EmailSendTestModal from "../../../modals/marketing/campaigns/email-send-test-modal";
import TextSendTestModal from "../../../modals/marketing/campaigns/text-send-test-modal";
import BillingAccountModal from "../../../modals/operations/billing-accounts/billing-account-modal";
import BillingAccountPayoffModal from "../../../modals/operations/billing-accounts/billing-account-payoff-modal";

class BillingAccountPaymentPage extends Component {
  state = {payment: null};

  async componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID} = this.props.router.params;

    let payment = await request("billing/payment/" + ID, "GET", {});

    this.setState({payment});
  }

  async sendEmail(email, cc, bcc) {
    const {payment} = this.state;

    await request("billing/payment/" + payment.ID + "/receipt", "POST", {
      EMAIL: email,
      CC: cc,
      BCC: bcc,
    });

    showSuccessAlert("Email Receipt Sent!");
  }

  async sendReceipt(phone) {
    const {payment} = this.state;

    await request("billing/payment/" + payment.ID + "/receipt", "POST", {
      PHONE: phone,
    });

    showSuccessAlert("Text Receipt Sent!");
  }

  renderTickets() {
    let {payment} = this.state;

    return (
      <div>
        <Table
          data={payment.TICKETS.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED)}
          actionButtons={[
            {
              label: <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />,
              onClick: (ticket) => this.props.router.navigateNewTab("/ticket/" + ticket.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              label: "Ticket Number",
              value: "TICKET_NUMBER",
            },
            {
              label: "Amount",
              value: "PAYMENT_INTENT",
              format: (payment) => "$" + toDollars(payment.TOTAL),
            },
            {label: "Name", value: "NAME"},
            {
              label: "Ticket & Platform",
              value: "TICKET_TYPE_NAME",
              format: (name, row) => {
                return `${name} - ${row.PLATFORM ? row.PLATFORM.capitalize() : "POS"}`;
              },
            },
            {
              label: "Date Created",
              value: "DATE_CREATED",
              format: (val, row) => moment(val).format("h:mma ddd MMM Do"),
            },
          ]}
        />
      </div>
    );
  }

  render() {
    let {payment} = this.state;

    if (!payment) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <EmailSendTestModal
          ref={(e) => (this.emailModal = e)}
          showCC
          label="Send Billing Payment Receipt"
          sendEmail={(email, cc, bcc) => {
            return this.sendEmail(email, cc, bcc).then(() => this.syncState());
          }}
        />

        <TextSendTestModal
          ref={(e) => (this.textModal = e)}
          label="Send Billing Payment Receipt"
          sendText={(phone) => {
            return this.sendReceipt(phone).then(() => this.syncState());
          }}
        />

        <PageHeadings
          label={"Billing Account Payment"}
          description={"View this payment of this billing account"}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Billing Accounts", url: "/billing-accounts"},
          ]}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Send Receipt Text",
                      onClick: () => this.textModal.open(),
                    },
                  ],
                },
                {
                  items: [
                    {
                      label: "Send Receipt Email",
                      onClick: () => this.emailModal.open(),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <Card>
          <TwoColumnList
            label={"Billing Account Payment Details"}
            data={[
              {
                label: "Payment Type",
                value: payment.PAYMENT.TYPE,
              },
              {
                label: "Ticket Total",
                value: payment.TOTAL,
                type: "dollars",
              },
              {
                label: "Payment Tip",
                value: payment.PAYMENT.AMOUNT_TIP,
                type: "dollars",
              },
              {
                label: "Payment Total",
                value: payment.PAYMENT.AMOUNT,
                type: "dollars",
              },
              {
                label: "Receipt Link",
                value: (
                  <a
                    href={`https://order.dripos.com/receipt/billing/${payment.UNIQUE_ID}`}
                    className="text-indigo-600 font-semibold hover:text-indigo-800 hover:cursor-pointer"
                  >
                    Click Here
                  </a>
                ),
              },
            ]}
          />
        </Card>

        <Card label={"Tickets Paid"}>{this.renderTickets()}</Card>

        {/*<Tab className="mt-2" data={[{label: "Tickets Paid", id: "tickets"}]}>*/}
        {/*  {(id) => {*/}
        {/*    if (id === "tickets") {*/}
        {/*      */}
        {/*    }*/}
        {/*  }}*/}
        {/*</Tab>*/}
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(BillingAccountPaymentPage));

import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import ModifierModal from "../../../modals/sales/modifiers/modifier-modal";
import {classNames, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Loading, PageHeadings, Tab, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import TicketReceiptModal from "../../../modals/sales/tickets/ticket-receipt-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import GrayBadge from "../../../components/badges/gray-badge";
import RefundModal from "../../../modals/sales/tickets/refund-modal";
import VoidModal from "../../../modals/sales/tickets/void-modal";
import EditTicketModal from "../../../modals/sales/tickets/edit-ticket-modal";
import {PDFDownloadLink} from "@react-pdf/renderer";
import TicketPDF from "../../../features/pdf/ticket-pdf";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {getLastFourPaymentString} from "../../../utils/payment-helper";
import {setupReduxConnection} from "../../../redux";
import {getFormattedDate} from "../../../utils/time-helper";

class TicketPage extends Component {
  state = {ticket: null};

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.router?.params?.ID !== this.props?.router?.params?.ID) {
      this.setState({ticket: null}, async () => {
        await this.syncState();
      });
    }
  }

  syncState = async () => {
    const {ID: id} = this.props.router.params;

    let ticket = await request("ticket/" + id, "GET", null);
    this.setState({ticket});
  };

  renderSelections(product) {
    const isRefunded = product.DATE_REFUNDED !== null;

    let customizations = product.SELECTIONS.reduce((dict, item) => {
      if (item.MODIFIER_ID in dict) {
        dict[item.MODIFIER_ID].push({...item});
      } else if (item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT) {
        return dict;
      } else {
        dict[item.MODIFIER_ID] = [{...item}];
      }

      return dict;
    }, {});

    let Notes = undefined;
    if (product.NOTES && product.NOTES.length > 0) {
      Notes = product.NOTES;
    }

    if (product.TYPE === "E_GIFT_CARD") {
      Notes = product.NOTES.split("-")[2];
    }

    let str = "";
    Object.values(customizations).forEach((item, index) => {
      str =
        str +
        item
          .map((option) => option.OPTION_NAME + (option.QUANTITY > 1 ? ` (${option.QUANTITY})` : ""))
          .join(", ");
      if (index !== Object.values(customizations).length - 1) {
        str = str + ", ";
      }
    });

    return (
      <div className={classNames(isRefunded && "line-through")}>
        <p className="mt-1 text-sm text-neutral-text">{str}</p>

        {Notes && <p className="mt-1 text-sm text-neutral-text">Notes: {Notes}</p>}
      </div>
    );
  }

  render() {
    const {ticket} = this.state;
    const {LOYALTY_POINTS_VERBIAGE} = this.props.shop.companySettings;
    const terminology = LOYALTY_POINTS_VERBIAGE
      ? LOYALTY_POINTS_VERBIAGE.charAt(0).toUpperCase() + LOYALTY_POINTS_VERBIAGE.slice(1)
      : "Points";

    if (ticket === null) {
      return <Loading />;
    }

    const ticketInformation = [
      {
        label: "Name",
        value: ticket.NAME,
      },
      {
        label: "Location",
        value: ticket.LOCATION_NAME,
      },
      {
        label: "Number",
        value: ticket.TICKET_NUMBER,
      },
      {
        label: "Platform",
        value: ticket.FROM_OFFLINE ? (
          <div className="flex flex-row">
            <div>{ticket.PLATFORM}</div>
            <DangerBadge className="ml-3">Offline Processed</DangerBadge>
          </div>
        ) : (
          ticket.PLATFORM
        ),
      },
      {
        label: "Unique Identifier",
        value: ticket.UNIQUE_ID,
      },
    ];

    if (ticket.TICKET_TYPE_NAME) {
      ticketInformation.push({
        label: "Ticket Order Type",
        value: ticket.TICKET_TYPE_NAME,
      });
    }

    if (ticket.EMPLOYEE_NAME) {
      ticketInformation.push({
        label: "Cashier Name",
        value: ticket.EMPLOYEE_NAME,
      });
    }

    if (ticket.EMAIL) {
      ticketInformation.push({
        label: "Email",
        value: ticket.EMAIL,
      });
    }

    if (ticket.PHONE) {
      ticketInformation.push({
        label: "Phone",
        value: getFormattedPhone(ticket.PHONE),
      });
    }

    if (ticket.NOTES) {
      ticketInformation.push({
        label: "Notes",
        value: ticket.NOTES,
        span: true,
      });
    }

    if (ticket?.LOYALTY?.REDEEMS?.length > 0 || ticket?.LOYALTY?.EARNS?.length > 0) {
      ticketInformation.push({
        label: `${terminology} Earned`,
        value: ticket.LOYALTY.POINTS_EARNED,
      });

      ticketInformation.push({
        label: `${terminology} Redeemed`,
        value: ticket.LOYALTY.POINTS_REDEEMED,
      });
    }

    const ticketTimeline = [
      {
        label: "Date Created",
        value: moment(ticket.DATE_CREATED).format("M/D/YY, h:mm A"),
      },
      {
        label: "Date Paid",
        value: getFormattedDate(
          ticket.PAYMENT_INTENT.PAYMENTS[ticket.PAYMENT_INTENT.PAYMENTS.length - 1]?.DATE_CREATED,
          "Unpaid"
        ),
      },
      {
        label: "Date Started",
        value: getFormattedDate(ticket.DATE_STARTED, "Not Started"),
      },
      {
        label: "Date Completed",
        value: getFormattedDate(ticket.DATE_COMPLETED, "Pending Completion"),
      },
    ];

    if (ticket.PAYMENT_INTENT.DATE_REFUNDED !== null) {
      ticketInformation.push({
        label: "Date Refunded",
        value: ticket.PAYMENT_INTENT.DATE_REFUNDED,
        type: "datetime",
      });
    }

    if (ticket.PAYMENT_INTENT.DATE_VOIDED !== null) {
      ticketInformation.push({
        label: "Date Voided",
        value: ticket.PAYMENT_INTENT.DATE_VOIDED,
        type: "datetime",
      });
    }

    if (ticket.THIRD_PARTY_ID) {
      ticketInformation.push({
        label: "Third Party ID",
        tooltip: {
          data: `Use this ID when contacting the third party delivery service (${ticket.TICKET_TYPE_NAME}) in order to get customer phone number`,
        },
        value: ticket.THIRD_PARTY_ID,
      });
    }

    let pdfName =
      ticket.TICKET_NUMBER +
      " " +
      moment(ticket.DATE_COMPLETED).format("MM/DD/YYYY") +
      " - " +
      ticket.LOCATION_NAME;

    let isVoided = ticket.STATUS === "VOIDED";
    let isRefunded = ticket.PAYMENT_INTENT.AMOUNT_REFUNDED;

    let fullyRefunded = ticket.PAYMENT_INTENT.AMOUNT_REFUNDED === ticket.PAYMENT_INTENT.AMOUNT_PAYED;

    const isUnpaid =
      ticket.PAYMENT_INTENT.PAYMENTS.reduce((total, payment) => total + payment.AMOUNT, 0) !==
      ticket.PAYMENT_INTENT.TOTAL;

    let ticketActionButtons = [];

    let ticketData = [
      {
        label: "Subtotal",
        value:
          "$" +
          toDollars(
            ticket.PAYMENT_INTENT.AMOUNT +
              ticket.PAYMENT_INTENT.AMOUNT_DISCOUNT -
              ticket.PAYMENT_INTENT.AMOUNT_TIP -
              ticket.PAYMENT_INTENT.AMOUNT_FEES
          ),
      },
      {
        label: "Discounts",
        value: (
          <span>
            -${toDollars(ticket.PAYMENT_INTENT.AMOUNT_DISCOUNT)}
            {(ticket.CUSTOM_DISCOUNT_EMPLOYEE_FULL_NAME && (
              <i> (by {ticket.CUSTOM_DISCOUNT_EMPLOYEE_FULL_NAME})</i>
            )) ||
              (ticket.EMPLOYEE_FULL_NAME && <i> (by {ticket.EMPLOYEE_FULL_NAME})</i>) ||
              (ticket.LINE_ITEM_EMPLOYEE_FULL_NAME && <i> (by {ticket.LINE_ITEM_EMPLOYEE_FULL_NAME})</i>)}
          </span>
        ),
      },
      {
        label: "Taxes",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_TAX),
      },
      {
        label: "Tips",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_TIP),
      },
      {
        label: "Fees",
        tooltip: {
          data: "These are fees that you have charged your customers. (Service Fees, Passed Processing Fees, Custom Fees, etc.)",
        },
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_FEES),
      },
      {
        label: "Total",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.TOTAL),
      },
    ];

    if (ticket.PAYMENT_INTENT.AMOUNT_REFUNDED > 0) {
      const tipRow = ticketData.find((row) => row.label === "Tips");

      if (tipRow) {
        tipRow.value =
          "$" +
          toDollars(
            ticket.ITEMS.reduce((accum, curr) => (curr.TYPE === "TIP" ? curr.AMOUNT + accum : accum), 0)
          );
      }

      const feeRow = ticketData.find((row) => row.label === "Fees");

      if (feeRow) {
        feeRow.value =
          "$" +
          toDollars(
            ticket.ITEMS.reduce(
              (accum, curr) =>
                curr.TYPE === "FEE" || curr.TYPE === "CUSTOM_FEE" ? curr.AMOUNT + accum : accum,
              0
            )
          );
      }

      ticketData.push({
        label: "Refunded",
        value: "$" + toDollars(ticket.PAYMENT_INTENT.AMOUNT_REFUNDED),
      });
    }

    if (!fullyRefunded) {
      ticketActionButtons.push({
        label: "Refund",
        onClick: () => this.refundModal.open(ticket),
      });
    }

    if (!isVoided) {
      ticketActionButtons.push({
        label: "Void",
        onClick: () => setTimeout(() => this.voidModal.open(ticket), 200),
      });
    }

    return (
      <div className="p-6 height-screen">
        <ModifierModal ref={(e) => (this.modifierModal = e)} />
        <TicketReceiptModal ref={(e) => (this.receiptModal = e)} />
        <RefundModal handleUpdate={this.syncState} ref={(e) => (this.refundModal = e)} />
        <VoidModal handleUpdate={this.syncState} ref={(e) => (this.voidModal = e)} />
        <EditTicketModal handleUpdate={this.syncState} ref={(e) => (this.editTicketModal = e)} />

        <PageHeadings
          label={"#" + ticket.TICKET_NUMBER + " " + ticket.NAME}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Tickets Page", url: "/tickets"},
          ]}
          buttons={[
            {
              label: "Actions",
              type: "dropdown",
              sections: [
                {
                  items: [
                    {
                      label: (
                        <PDFDownloadLink document={<TicketPDF ticket={ticket} />} fileName={pdfName}>
                          {({blob, url, loading, error}) => (
                            <div>
                              <FontAwesomeIcon icon="fa-file-pdf" className={"h-5 w-5 mr-3 text-gray-400"} />
                              {loading ? "Loading document..." : "Download as PDF"}
                            </div>
                          )}
                        </PDFDownloadLink>
                      ),
                    },
                    {
                      label: "Send Receipt",
                      icon: "receipt",
                      onClick: () => this.receiptModal.open(ticket),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.editTicketModal.open(ticket);
              },
            },
          ]}
          label={
            <div className={"flex flex-row"}>
              <div className="mr-4">Ticket Information</div>

              {isVoided && <DangerBadge>Voided</DangerBadge>}
            </div>
          }
          description="Information about this ticket"
          data={ticketInformation}
        />

        <TwoColumnList
          label="Ticket Timeline"
          description="The key events in the tickets lifespan"
          data={ticketTimeline}
        />

        <TwoColumnList
          buttons={ticketActionButtons}
          label={
            <div className={"flex flex-row"}>
              <div className="mr-4">Payment Overview</div>

              {!!isRefunded && (
                <GrayBadge darkGrey>{`${fullyRefunded ? "Fully" : "Partially"} Refunded`}</GrayBadge>
              )}
              {!!isUnpaid && <DangerBadge>Unpaid</DangerBadge>}
            </div>
          }
          description="Information about the payment"
          data={ticketData}
        />

        <Tab
          data={[
            {id: "items", label: "Line Items"},
            {id: "payments", label: "Payments"},
            {
              id: "refunds",
              label: "Refunds",
            },
          ]}
        >
          {(id) => {
            if (id === "items") {
              const items = [
                ...ticket.ITEMS.filter((item) => item.TYPE !== "FEE" && item.TYPE !== "TIP"),
                ...ticket.ITEMS.filter((item) => item.TYPE === "TIP"),
                ...ticket.ITEMS.filter((item) => item.TYPE === "FEE"),
              ];

              return (
                <Table
                  key={id}
                  className="mt-2"
                  data={items}
                  ref={(e) => (this.tableRef = e)}
                  columns={[
                    {
                      value: "NAME",
                      width: 1,
                      label: "Item",
                      format: (value, row) => (
                        <div className="flex items-center">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={getObjectImage(row, "LOGO", "appicon.png")}
                            alt=""
                          />

                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{row.NAME}</div>

                            <div className="text-sm text-gray-500">{this.renderSelections(row)}</div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      value: "QUANTITY",
                      label: "Quantity",
                    },
                    {
                      value: "AMOUNT",
                      label: "Item Price",
                      format: (amount, row) => toDollars(amount + (row?.AMOUNT_DISCOUNT ?? 0), true),
                    },
                    {
                      value: "AMOUNT_DISCOUNT",
                      label: "Discounts",
                      format: (amount) => "-" + toDollars(amount, true),
                    },
                    {
                      value: "AMOUNT_TAX",
                      label: "Tax",
                      format: (tax) => toDollars(tax, true),
                    },
                    {
                      value: "TOTAL",
                      label: "Total",
                      format: (total) => toDollars(total, true),
                    },
                  ]}
                />
              );
            }

            if (id === "payments") {
              return (
                <Table
                  key={id}
                  className="mt-2"
                  data={ticket.PAYMENT_INTENT.PAYMENTS}
                  ref={(e) => (this.tableRef = e)}
                  columns={[
                    {
                      value: "TYPE",
                      label: "Payment Method",
                      format: (val, row) => val + getLastFourPaymentString(row),
                    },
                    {
                      value: "AMOUNT",
                      label: "Amount Payed",
                      format: (val) => "$" + toDollars(val),
                    },
                    {
                      value: "AMOUNT_REFUNDED",
                      label: "Amount Refunded",
                      format: (val) => "$" + toDollars(val),
                    },
                    {
                      value: "NOTES",
                      label: "Notes",
                    },
                  ]}
                />
              );
            }

            if (id === "refunds") {
              return (
                <Table
                  key={id}
                  className="mt-2"
                  data={ticket.PAYMENT_INTENT.REFUNDS ?? []}
                  ref={(e) => (this.tableRef = e)}
                  columns={[
                    {
                      value: "DATE_CREATED",
                      label: "Date Refunded",
                      tooltip: "The date on which this refund occurred.",
                      format: (val) => moment(val).format("h:mm a MMM Do, YYYY"),
                    },
                    {
                      value: "AMOUNT",
                      label: "Amount Refunded",
                      format: (val) => "$" + toDollars(val),
                    },
                    {
                      value: "EMPLOYEE_NAME",
                      label: "Employee Name",
                      empty: "-",
                    },
                    {
                      value: "NOTES",
                      label: "Notes",
                    },
                  ]}
                />
              );
            }

            return <div>Hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "menu"])(withRouter(TicketPage));

import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {setupReduxConnection} from "../../../../redux";
import LocationAddressModal from "../../../../modals/payroll/onboard/location-address-modal";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import FormStateSelect from "../../../../components/form-state-select";
import LoadingSpinner from "../../../../components/loading-spinner";

class PayrollWorkplacesComponent extends Component {
  state = {locations: [], loading: true};

  async componentDidMount() {
    setTimeout(() => {
      this.setState({loading: false});
    }, 1000);

    await this.syncState();
  }

  syncState = async () => {
    let locations = await request("payroll/groups/locations");

    this.setState({locations});
  };

  async handleClick() {
    try {
      await request("payroll/groups/workplaces", "POST");

      return true;
    } catch ({error, data}) {
      this.syncState();

      showErrorAlert("Invalid Address", `The following addresses are invalid: ${data.message}`);

      return false;
    }
  }

  renderLocationCard(location) {
    return (
      <div className={"py-3"}>
        <div className={"flex flex-row justify-between"}>
          <div className={"text-black font-semibold text-md"}>{location.NAME}</div>

          <div
            className={"text-indigo-500 font-semibold cursor-pointer"}
            onClick={() => this.locationAddressModal.open(location)}
          >
            Edit
          </div>
        </div>

        <FormInput label="Address" value={location.ADDRESS} disabled={true} />

        <div className="flex flex-row justify-between">
          <FormInput label="City" value={location.CITY} disabled={true} />

          <FormStateSelect className={"mx-3"} label="State" value={location.STATE} disabled={true} />

          <FormInput className="w-24" label="Zipcode" value={location.ZIPCODE} disabled={true} />
        </div>
      </div>
    );
  }

  render() {
    let {locations, loading} = this.state;

    let toShowLocations = locations.filter(
      ({ID, CHECK_WORKPLACE_ID, TYPE}) => !CHECK_WORKPLACE_ID && TYPE === "STORE"
    );

    return (
      <div className={"p-6 w-96 flex justify-center w-full"} style={{height: 600}}>
        <LocationAddressModal ref={(e) => (this.locationAddressModal = e)} syncState={this.syncState} />

        <div className={"flex flex-col"} style={{minWidth: 400}}>
          <div className={"text-black text-lg font-semibold"}>Confirm Addresses</div>

          {loading && (
            <div className={"pt-6"}>
              <LoadingSpinner />
            </div>
          )}

          {!loading && <div>{toShowLocations.map((_loc) => this.renderLocationCard(_loc))}</div>}
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(PayrollWorkplacesComponent);

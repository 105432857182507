import React, {Component} from "react";
import {PageHeadings, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import CardTab from "../../../components/card-tab";
import OnboardingTable from "../../../tables/team/onboarding/onboarding-table";
import {request} from "../../../utils/request";
import CreateOnboardingModal from "../../../modals/team/employee/onboarding/create-onboarding-modal";
import Banner from "../../../components/banner";
import GrayBadge from "../../../components/badges/gray-badge";
import {showErrorAlert} from "../../../utils/alert-helper";
import {showErrorNotification} from "../../../utils/notification-helper";
import OnboardingConstructionBanner from "./onboarding-construction-banner";

class OnboardingPage extends Component {
  state = {data: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("checklists/onboarding/instances", "GET")
      .then((data) => {
        this.setState({data});
      })
      .catch((err) => {
        console.log(err);

        if (err.message === "EMPLOYEE_INVALID") {
          showErrorNotification(
            "Fetching documents failed.",
            "You are not listed as an employee. Please add yourself as an employee to access onboarding."
          );
          this.setState({
            data: {
              toDoOnboards: [],
              awaitingActionOnboards: [],
              completedOnboards: [],
            },
          });
        }
      });
  }

  render() {
    const {data} = this.state;

    if (!data) {
      return <Loading />;
    }

    const {toDoOnboards, awaitingActionOnboards, completedOnboards} = data;

    return (
      <div>
        <CreateOnboardingModal ref={(e) => (this.onboardingModal = e)} syncState={() => this.syncState()} />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>Onboarding Dashboard</div>

              <GrayBadge darkGrey className={"ml-3"}>
                Beta
              </GrayBadge>
            </div>
          }
          description={"Track employee hiring and onboarding"}
          button={{
            label: "Create Onboarding +",
            onClick: () => this.onboardingModal.open(),
          }}
        />

        <OnboardingConstructionBanner />

        <CardTab
          data={[
            {
              label: "To-Do",
              value: toDoOnboards.length,
              link: "/onboarding/overview?tab=in-progress",
              id: "to-do",
              icon: "fa-solid fa-square-list",
            },
            {
              label: "Awaiting Action",
              value: awaitingActionOnboards.length,
              link: "/onboarding/overview?tab=in-progress",
              id: "awaiting-action",
              icon: "fa-solid fa-clock",
            },
            {
              label: "Completed",
              value: completedOnboards.length,
              id: "completed",
              link: "/onboarding/overview?tab=completed",
              icon: "fa-solid fa-square-check",
            },
          ]}
        >
          {(tab) => {
            if (tab === "completed") {
              return (
                <OnboardingTable
                  tab={tab}
                  noButtons
                  data={completedOnboards}
                  syncState={() => this.syncState()}
                />
              );
            } else if (tab === "to-do") {
              return <OnboardingTable tab={tab} data={toDoOnboards} syncState={() => this.syncState()} />;
            } else if (tab === "awaiting-action") {
              return (
                <OnboardingTable
                  tab={tab}
                  noButtons
                  data={awaitingActionOnboards}
                  syncState={() => this.syncState()}
                />
              );
            }
          }}
        </CardTab>
      </div>
    );
  }
}

export default OnboardingPage;

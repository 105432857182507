import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";

class PayRatesTable extends Component {
  render() {
    const {rates, soft, addState, updateState} = this.props;

    const actionTexts = [];
    const actionButtons = [];

    if (updateState) {
      actionButtons.push({
        label: "Edit",
        onClick: (rate) => updateState(rate),
      });
    }

    if (addState) {
      actionTexts.push({
        label: "Add Pay Rate",
        onClick: () => addState(),
      });
    }

    const columns = [
      {
        width: 1,
        value: "NAME",
        label: "Name",
      },
      {
        value: "TYPE",
        label: "Type",
        format: (_, {TYPE, CONTENT}) => {
          if (TYPE === "TIME_RANGE") {
            const timeSplit = CONTENT.split("|");

            const startTime = moment().startOf("day").add(timeSplit[0], "minutes");
            const endTime = moment().startOf("day").add(timeSplit[1], "minutes");

            return "Hourly " + startTime.format("hh:mma") + " - " + endTime.format("hh:mma");
          }

          return "Hourly";
        },
      },
      {
        width: 1,
        value: "AMOUNT",
        label: "Amount",
        format: (value, row) => {
          return "$" + toDollars(row.AMOUNT);
        },
      },
    ];

    if (!soft) {
      columns.push(
        {
          width: 1,
          value: "STATUS",
          label: "Status",
          format: (value, row) => {
            return row.DATE_ARCHIVED ? "Inactive" : "Active";
          },
        },
        {
          width: 1,
          value: "DATE_UPDATED",
          label: "Updated",
          mobile: "lg:table-cell",
          format: (value) => {
            return moment(value).format("MM/DD/YY");
          },
        }
      );
    }

    return (
      <Table
        pagination
        data={rates}
        columns={columns}
        actionTexts={actionTexts}
        actionButtons={actionButtons}
      />
    );
  }
}

PayRatesTable.propTypes = {
  rates: PropTypes.array.isRequired,
};

export default withRouter(PayRatesTable);

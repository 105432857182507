import React, {Component} from "react";
import {Card, Loading, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {EARN_RULE_TYPES, REDEEM_RULE_TYPES} from "../../utils/loyalty-constants";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import EarningRuleModal from "../../modals/shop/shop-settings/loyalty/earning-rule-modal";
import RedeemingRuleModal from "../../modals/shop/shop-settings/loyalty/redeeming-rule-modal";
import AdvancedLoyaltyModal from "../../modals/marketing/advanced-loyalty-modal";
import {request} from "../../utils/request";
import Banner from "../../components/banner";

class LoyaltyTab extends Component {
  state = {redeemRules: null, earnRules: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const rules = await request("loyalty/rules", "GET", null);
    let {EARN_RULES: earnRules, REDEEM_RULES: redeemRules} = rules;

    redeemRules.sort((a, b) => a.POINT_AMOUNT - b.POINT_AMOUNT);
    earnRules.sort((a, b) => a.POINT_AMOUNT - b.POINT_AMOUNT);

    redeemRules = redeemRules.filter((rule) => !rule.DATE_ARCHIVED);
    earnRules = earnRules.filter((rule) => !rule.DATE_ARCHIVED);
    this.setState({earnRules, redeemRules});
  }

  renderSimpleLoyaltyDeprecationMessage() {
    return (
      <Banner
        className="my-4"
        label="Simple Loyalty is no longer be supported!"
        description="You currently have the simple loyalty setting enabled. We have stopped supporting this type of loyalty in favor of rule based loyalty. Don't worry! We will not disable this form of loyalty while you continue to use it. However, we do prefer that you switch to rule based loyalty. Please call support at (781) 583-3699 to help you migrate to the new loyalty approach!"
      />
    );
  }

  render() {
    let {
      ADVANCED_LOYALTY_ENABLED,
      ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
      IGNORE_LOYALTY_WITH_GIFT_CARDS,
      IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
      IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS,
    } = this.props.shop.settings;
    const {earnRules, redeemRules} = this.state;
    let {LOYALTY_POINTS_VERBIAGE} = this.props.shop.companySettings;

    const terminology = LOYALTY_POINTS_VERBIAGE ? LOYALTY_POINTS_VERBIAGE : "points";

    if (earnRules === null || redeemRules === null) {
      return <Loading />;
    }

    // only for legacy customers
    if (ADVANCED_LOYALTY_ENABLED !== "1") {
      return this.renderSimpleLoyaltyDeprecationMessage();
    }

    return (
      <>
        <EarningRuleModal ref={(e) => (this.earningModal = e)} onChange={this.syncState.bind(this)} />

        <RedeemingRuleModal ref={(e) => (this.redeemingModal = e)} onChange={this.syncState.bind(this)} />

        <AdvancedLoyaltyModal ref={(e) => (this.advLoyaltyModal = e)} onSubmit={() => this.syncState()} />

        <TwoColumnList
          label="Loyalty Rules Settings"
          description={`Configure your the way your patrons earn and redeem ${terminology}`}
          data={[
            {
              label: "Ignore Loyalty for Gift Cards as a Payment",
              value: IGNORE_LOYALTY_WITH_GIFT_CARDS,
              type: "bool",
              tooltip:
                "If enabled, transactions purchased with a gift card will not earn the patron any loyalty.",
            },
            {
              label: "Ignore Loyalty for Purchased Gift Cards",
              value: IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
              type: "bool",
              tooltip: "If enabled, purchases of gift cards will not grant loyalty points.",
            },
            {
              label: "Ignore Loyalty Earnings for Already Discounted Items",
              value: IGNORE_LOYALTY_EARN_ON_DISCOUNTED_ITEMS,
              type: "bool",
              tooltip: "If enabled, purchases of discounted items will not grant loyalty points.",
            },
            {
              label: "One Earn Rule Per Payment",
              value: ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
              type: "bool",
              tooltip: {
                label: "One Rule Per Payment",
                data: `If enabled, only one earn rule will be applied per payment. The rule that generates the maximum amount of ${terminology} will be applied. Otherwise, the patron will instead earn the sum of the ${terminology} for each satisfied earn rule.`,
              },
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.advLoyaltyModal.open(),
            },
          ]}
        />

        <Card
          label="Earning Rules"
          description={`Configure your loyalty rules for earning ${terminology}`}
          tooltip={{
            label: "Earning Rules",
            data: `When loyalty is turned on, these rules will determine how your customers will earn ${terminology}.`,
          }}
          button={{
            label: "Add Rule",
            onClick: () => this.earningModal.open(),
          }}
        >
          <Table
            data={earnRules}
            actionButtons={[
              {
                label: "Edit",
                onClick: (row) => this.earningModal.open(row),
              },
            ]}
            columns={[
              {label: "NAME", value: "NAME"},
              {
                label: "TYPE",
                value: "TYPE",
                format: (value, _) => {
                  return value === EARN_RULE_TYPES.TICKET
                    ? "Order Based"
                    : value === EARN_RULE_TYPES.PRODUCT
                    ? "Product Based"
                    : value === EARN_RULE_TYPES.CATEGORY
                    ? "Category"
                    : "-";
                },
              },
              {
                label: `${terminology.toUpperCase()}`,
                value: "POINT_AMOUNT",
                format: (points, row) => {
                  return `${points}${row.IS_MULTIPLIER ? "x" : ""}`;
                },
              },
              {
                label: "PRODUCTS/CATEGORIES",
                value: "TYPE",
                format: (value, row) => {
                  if (value === EARN_RULE_TYPES.TICKET) {
                    return "-";
                  }

                  // Product discount
                  if (value === EARN_RULE_TYPES.PRODUCT) {
                    return row.PRODUCTS.reduce((str, product, i) => {
                      if (i === 0) {
                        return product.NAME;
                      }
                      return str + ", " + product.NAME;
                    }, "");
                  }

                  // Category Discount
                  if (value === EARN_RULE_TYPES.CATEGORY) {
                    return row.CATEGORIES.reduce((str, cat, i) => {
                      if (i === 0) {
                        return cat.NAME;
                      }
                      return str + ", " + cat.NAME;
                    }, "");
                  }
                },
              },
            ]}
          />
        </Card>

        <Card
          label="Redeeming Rules"
          description={`Configure your loyalty rules for redeeming ${terminology}`}
          tooltip={{
            label: "Redeeming Rules",
            data: `When loyalty is turned on, these rules will determine how your customers can spend ${terminology}.`,
          }}
          button={{
            label: "Add Rule",
            onClick: () => this.redeemingModal.open(),
          }}
        >
          <Table
            data={redeemRules}
            actionButtons={[
              {
                label: "Edit",
                onClick: (row) => this.redeemingModal.open(row),
              },
            ]}
            columns={[
              {label: "NAME", value: "NAME"},
              {
                label: "TYPE",
                value: "TYPE",
                format: (value, _) => {
                  return value === REDEEM_RULE_TYPES.TICKET
                    ? "Ticket Discount"
                    : value === REDEEM_RULE_TYPES.PRODUCT
                    ? "Product Discount"
                    : value === REDEEM_RULE_TYPES.CATEGORY
                    ? "Category Discount"
                    : value === REDEEM_RULE_TYPES.ALL_PRODUCTS
                    ? "All Product Discount"
                    : "-";
                },
              },
              {
                label: "AMOUNT",
                value: "AMOUNT",
                format: (value, row) => {
                  return !!row.IS_PERCENT
                    ? row.MAX_VALUE > 0
                      ? `${value}% (max. ${toDollars(row.MAX_VALUE, true)})`
                      : `${value}%`
                    : `${toDollars(value, true)}`;
                },
              },
              {
                label: `${terminology.toUpperCase()}`,
                value: "POINT_AMOUNT",
              },
              {
                label: "PRODUCTS/CATEGORIES",
                value: "TYPE",
                format: (value, row) => {
                  if (value === REDEEM_RULE_TYPES.TICKET) {
                    return "-";
                  }

                  // Product discount
                  if (value === REDEEM_RULE_TYPES.PRODUCT) {
                    return row.PRODUCTS.reduce((str, product, i) => {
                      if (i === 0) {
                        return product.NAME;
                      }
                      return str + ", " + product.NAME;
                    }, "");
                  }

                  // Category Discount
                  if (value === REDEEM_RULE_TYPES.CATEGORY) {
                    return row.CATEGORIES.reduce((str, cat, i) => {
                      if (i === 0) {
                        return cat.NAME;
                      }
                      return str + ", " + cat.NAME;
                    }, "");
                  }

                  if (value === REDEEM_RULE_TYPES.ALL_PRODUCTS) {
                    return "All Products";
                  }
                },
              },
            ]}
          />
        </Card>
      </>
    );
  }
}

export default LoyaltyTab;

import PresetModal from "../../../../../modals/sales/modifiers/preset-modal";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import ModifiersTable from "../../../../../tables/sales/modifiers-table";
import React, {Component} from "react";
import {updateStateDelegator} from "../../../../../utils/util";
import ModifierModal from "../../../../../modals/sales/modifiers/modifier-modal";
import {request} from "../../../../../utils/request";

class ProductModifiersTab extends Component {
  async saveOrderingModifiers() {
    const {product} = this.props;
    const modifiers = product.CUSTOMIZATIONS;

    const serverModifiers = await request("modifiers/product/" + product.ID, "PUT", modifiers);

    this.setState({
      product: {...product, CUSTOMIZATIONS: serverModifiers},
      modifiers: serverModifiers,
    });
  }

  render() {
    const {product} = this.props;
    const modifiers = product.CUSTOMIZATIONS;

    return (
      <>
        <PresetModal ref={(e) => (this.presetDropdown = e)} />

        <PresetModal
          ref={(e) => (this.presetModal = e)}
          exclude={modifiers.map((item) => item.ID)}
          updateState={async ({type, modifier}, close) => {
            const serverModifier = await request(
              "v2/preset/" + modifier + "/product/" + product.ID,
              "POST",
              {}
            );

            serverModifier.DEPENDENCIES = [];

            modifiers.push(serverModifier);

            this.setState({modifiers}, () => {
              close();
            });
          }}
        />

        <ModifierModal
          product={product}
          updateState={(id, item) => {
            product.CUSTOMIZATIONS = updateStateDelegator(modifiers, id, item);

            this.props.updateProduct(product);
          }}
          addState={(item) => {
            const serverModifiers = JSON.parse(JSON.stringify(modifiers));
            serverModifiers.push(item);
            product.CUSTOMIZATIONS = serverModifiers;
            this.props.updateProduct(product);
          }}
          ref={(e) => (this.modifierModal = e)}
        />

        <Card
          label="Modifiers"
          buttons={[
            {
              theme: "primary",
              label: "Add Modifier",
              icon: "plus",
              type: "list",
              onChange: ({id}) => {
                if (id === "custom") {
                  this.modifierModal.open(
                    {
                      ID: null,
                      TYPE: 0,
                      REQUIRED: 1,
                      ENABLED: 1,
                      OPTIONS: [{NAME: "", PRICE: "", ENABLED: 1, INGREDIENTS: []}],
                    },
                    product
                  );
                }

                if (id === "global") {
                  this.presetModal.open();
                }
              },
              items: [
                {
                  id: "global",
                  label: "Global Modifier",
                  description: "Use a modifier that was created to be used globally.",
                },
                {
                  id: "custom",
                  label: "Custom",
                  description: "A modifier that will only be used for this product.",
                },
              ],
            },
          ]}
        >
          <ModifiersTable
            onClick={(item) => this.modifierModal.open(item, product)}
            data={modifiers.filter((item) => item.TYPE !== 5)}
            editOnClick={(item) => this.modifierModal.open(item, product)}
          />
        </Card>
      </>
    );
  }
}

export default ProductModifiersTab;

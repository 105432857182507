import React, {Component} from "react";
import {Card, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {classNames, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class StoreBanksExpansionComponent extends Component {
  renderCashDrawerEvents() {
    const {row} = this.props;
    const allEvents = [];

    !!row.DATE_OPEN &&
      allEvents.push({
        AMOUNT: row.AMOUNT_OPEN,
        FULL_NAME: row.OPEN_EMPLOYEE_NAME,
        NOTES: "Opening Event",
        DATE_SENT: row.DATE_OPEN,
      });

    allEvents.push(...row.PAYINS);

    !!row.DATE_CLOSE &&
      allEvents.push({
        AMOUNT: row.AMOUNT_CLOSE,
        FULL_NAME: row.CLOSE_EMPLOYEE_NAME,
        NOTES: "Closing Event",
        DATE_SENT: row.DATE_CLOSE,
      });

    return (
      <Table
        data={allEvents}
        columns={[
          {
            value: "AMOUNT",
            label: "Operation",
            format: (val, rest) => {
              if (rest.NOTES === "Opening Event") {
                return "Opening";
              } else if (rest.NOTES === "Closing Event") {
                return "Closing";
              }

              return val < 0 ? "Pay Out" : "Pay In";
            },
          },
          {
            value: "AMOUNT",
            label: "Amount",
            format: (val) => toDollars(Math.abs(val), true),
          },
          {
            value: "FULL_NAME",
            label: "Performed By",
          },
          {
            value: "NOTES",
            label: "Notes",
          },
          {
            value: "DATE_SENT",
            label: "Timestamp",
            format: (val) => moment(val).format("M/D/YYYY  hh:mm A"),
          },
        ]}
      />
    );
  }

  renderBankEvents() {
    const {row} = this.props;

    return (
      <Table
        data={row.PAYINS}
        columns={[
          {
            value: "AMOUNT",
            label: "Operation",
            format: (val) => (val < 0 ? "Pay Out" : "Pay In"),
          },
          {
            value: "AMOUNT",
            label: "Amount",
            format: (val) => toDollars(Math.abs(val), true),
          },
          {
            value: "FULL_NAME",
            label: "Performed By",
          },
          {
            value: "NOTES",
            label: "Notes",
          },
          {
            value: "DATE_SENT",
            label: "Timestamp",
            format: (val) => moment(val).format("M/D/YYYY  hh:mm A"),
          },
        ]}
      />
    );
  }

  renderTotalEvents() {
    const {row} = this.props;

    return (
      <Table
        data={row.PAYINS}
        columns={[
          {
            value: "AMOUNT",
            label: "Operation",
            format: (val) => (val < 0 ? "Pay Out" : "Pay In"),
          },
          {
            value: "AMOUNT",
            label: "Amount",
            format: (val) => toDollars(Math.abs(val), true),
          },
          {
            value: "FULL_NAME",
            label: "Performed By",
          },
          {
            value: "NOTES",
            label: "Notes",
          },
          {
            value: "DATE_SENT",
            label: "Timestamp",
            format: (val) => moment(val).format("M/D/YYYY  hh:mm A"),
          },
        ]}
      />
    );
  }

  renderEvents() {
    const {row} = this.props;

    if (row.DEVICE_NAME === "Bank") {
      return this.renderBankEvents();
    } else if (row.DEVICE_NAME === "Total") {
      return this.renderTotalEvents();
    } else {
      return this.renderCashDrawerEvents();
    }
  }

  renderSummaryRow({label, value, bold = false}) {
    return (
      <div
        className={classNames(
          "flex flex-row justify-between px-6 pr-30 py-4 text-sm text-gray-500 border border-gray-100 rounded-sm",
          bold ? "font-bold" : "font-md"
        )}
      >
        <div>{label}</div>
        <div>{value}</div>
      </div>
    );
  }

  renderCashDrawerSummary() {
    const {row} = this.props;

    const rows = [
      {
        label: "Starting Cash",
        value: toDollars(row.AMOUNT_OPEN, true),
        bold: true,
      },
      {label: "Pay In / Out", value: toDollars(row.PAYIN_TOTAL ?? 0, true)},
      {label: "Cash Sales", value: toDollars(row.CASH_SALES, true)},
      {
        label: "Expected in Drawer",
        value: toDollars(row.AMOUNT_EXPECTED, true),
        bold: true,
      },
      {
        label: "Cash Retained",
        value: toDollars(row.CASH_REMAINING, true),
      },
      {
        label: "Amount Close",
        value: toDollars(row.AMOUNT_CLOSE, true),
      },
      {
        label: "Cash on Hand",
        value: toDollars(row.OPEN_CLOSE_DIFFERENCE, true),
        bold: true,
      },
    ];

    return <div>{rows.map((row) => this.renderSummaryRow(row))}</div>;
  }

  renderBankSummary() {
    const {row} = this.props;

    const rows = [
      {
        label: "Expected in Cash Drawers",
        value: toDollars(row.AMOUNT_EXPECTED_BANK - row.BANK_PAYIN_TOTAL, true),
      },
      {
        label: "Pay In / Out",
        value: toDollars(row.BANK_PAYIN_TOTAL ?? 0, true),
      },
      {
        label: "Expected in Bank",
        value: toDollars(row.AMOUNT_EXPECTED_BANK, true),
      },
      {
        label: "Expected Deposit",
        value: toDollars(row.EXPECTED_DEPOSIT, true),
      },
      {
        label: "Deposit Amount",
        value: toDollars(row.AMOUNT_DEPOSIT, true),
        bold: true,
      },
    ];

    return <div>{rows.map((row) => this.renderSummaryRow(row))}</div>;
  }

  renderTotalSummary() {
    const {row} = this.props;

    const rows = [
      {
        label: "Starting Cash",
        value: toDollars(row.AMOUNT_OPEN ?? 0, true),
        bold: true,
      },
      {
        label: "Paid In / Out",
        value: toDollars(row.PAYIN_TOTAL, true),
      },
      {
        label: "Cash Sales",
        value: toDollars(row.CASH_SALES, true),
      },

      {
        label: "Expected in Drawer",
        value: toDollars(row.AMOUNT_EXPECTED, true),
        bold: true,
      },
      {
        label: "Closing Amount",
        value: toDollars(row.AMOUNT_CLOSE, true),
      },
      {
        label: "Cash Retained",
        value: toDollars(row.CASH_REMAINING, true),
      },
      {
        label: "Cash on Hand",
        value: toDollars(row.AMOUNT_CLOSE - row.CASH_REMAINING, true),
        bold: true,
      },
      {
        label: "Bank Pay In / Out",
        value: toDollars(row.BANK_PAYINS, true),
      },
      {
        label: "Expected Deposit Amount",
        value: toDollars(row.AMOUNT_EXPECTED_BANK - row.CASH_REMAINING, true),
      },
      {
        label: "Actual Deposit Submitted",
        value: toDollars(row.AMOUNT_DEPOSIT, true),
      },
      {
        label: "Deposit Differential",
        value: toDollars(row.AMOUNT_DEPOSIT - row.AMOUNT_EXPECTED_BANK + row.CASH_REMAINING, true),
      },
    ];

    return <div>{rows.map((row) => this.renderSummaryRow(row))}</div>;
  }

  renderSummary() {
    const {row} = this.props;

    if (row.DEVICE_NAME === "Bank") {
      return this.renderBankSummary();
    } else if (row.DEVICE_NAME === "Total") {
      return this.renderTotalSummary();
    } else {
      return this.renderCashDrawerSummary();
    }
  }

  render() {
    return (
      <Card className={"mx-14 mb-8 shadow-md w-1/2"}>
        <Tab
          data={[
            {id: "summary", label: "Summary"},
            {id: "events", label: "Events"},
          ]}
        >
          {(tab) => {
            if (tab === "summary") {
              return this.renderSummary();
            } else if (tab === "events") {
              return this.renderEvents();
            }
          }}
        </Tab>
      </Card>
    );
  }
}

export default StoreBanksExpansionComponent;

import React, {Component} from "react";
import PayoutsPage from "../payouts/payouts-page";

class CashAccountPayoutsPage extends Component {
  render() {
    return <PayoutsPage hideBanner={true} />;
  }
}

export default CashAccountPayoutsPage;

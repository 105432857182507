import React, {Component} from "react";
import {Card, Table, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {parseIdDict, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import {cloneDeep} from "lodash";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import {showErrorNotification, showSuccessNotification} from "../../../utils/notification-helper";
import TipBreakdownModalNew from "../../../modals/timesheets/tip-breakdown-modal-new";
import {groupBy} from "../../../utils/util";
import GrayBadge from "../../../components/badges/gray-badge";
import {showConfirmAlert} from "../../../utils/alert-helper";

class TipPayoutPage extends Component {
  state = {isLoading: true, timeCards: [], payouts: [], rolesDict: {}};

  async componentDidMount() {
    await this.syncState();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.locationId !== this.props.locationId) {
      await this.syncState();
    }
  }

  async syncState() {
    const {locationId} = this.props;

    this.setState({isLoading: true});

    let serverTimeCards = [];
    let serverEmployeeRolesDict = {};

    try {
      [serverTimeCards, serverEmployeeRolesDict] = await Promise.all([
        request(`team/timesheet/${locationId}`, "GET"),
        request(`employeeroles/roles/${locationId}`, "GET"),
      ]);

      this.setState({rolesDict: serverEmployeeRolesDict?.rolesDict});
    } catch (err) {
      return showErrorNotification("", "Error fetching time cards");
    }

    await this.getCurrentTipPayouts(serverTimeCards);

    this.setState({isLoading: false});
  }

  async getCurrentTipPayouts(timeCards) {
    const unblessedTimeCards = timeCards.filter(({DATE_BLESSED}) => !DATE_BLESSED);

    const unblessedGrouped = Object.values(groupBy(unblessedTimeCards, "EMPLOYEE_ID"));

    const result = await Promise.all(unblessedGrouped.map((_cards) => this.fetchTipPayouts(_cards)));

    return this.setState({payouts: result ?? [], isLoading: false});
  }

  async fetchTipPayouts(timeCards) {
    const {locationId} = this.props;

    if (!timeCards || timeCards.length === 0) {
      return;
    }

    return await request("team/timesheets/bless/fetch", "POST", {
      EMPLOYEE_ID: timeCards[0].EMPLOYEE_ID,
      TIMESHEET_IDS: timeCards.map(({ID}) => ID),
      LOCATION_ID: locationId,
    });
  }

  async resaveTimeCard(card) {
    const {locationId} = this.props;

    if (!card) {
      return showErrorNotification("", "Card not found, please refresh the page and try again.");
    }

    try {
      await request("team/timesheet/" + card.ID + "/resave", "PATCH", {
        LOCATION_ID: locationId,
      });
    } catch (err) {
      return showErrorNotification("", "Error resaving time card");
    }

    return showSuccessNotification("", "Successfully resaved time card");
  }

  async clearDeficit(card) {
    const {locationId} = this.props;

    try {
      await showConfirmAlert(
        "Clear Tip Payout",
        "Are you sure you want to clear this tip payout? This will remove the tips on this card from the employee's tip payout."
      );
    } catch (err) {
      return;
    }

    try {
      await request("team/timesheet/clear/" + card.ID, "POST", {
        LOCATION_ID: locationId,
      });
    } catch (err) {
      return showErrorNotification("", "Error clearing deficit");
    }

    this.removeCard(card);

    return showSuccessNotification("", "Successfully reset tips");
  }

  removeCard(card) {
    const {payouts} = this.state;

    const findPayoutIndex = payouts.findIndex(
      ({TIMESHEETS}) => TIMESHEETS[0].EMPLOYEE_ID === card.EMPLOYEE_ID
    );

    if (findPayoutIndex === -1) {
      return;
    }

    let findIndex = payouts[findPayoutIndex]?.TIMESHEETS?.findIndex(({ID}) => ID === card.ID);

    if (findIndex === -1) {
      findIndex = payouts[findPayoutIndex]?.DIFF_TIMESHEETS?.findIndex(({ID}) => ID === card.ID);

      payouts[findPayoutIndex]?.DIFF_TIMESHEETS?.splice(findIndex, 1);
    } else {
      if (payouts[findPayoutIndex]?.TIMESHEETS?.length > 1) {
        payouts[findPayoutIndex]?.TIMESHEETS?.splice(findIndex, 1);
      } else {
        payouts.splice(findPayoutIndex, 1);
      }
    }

    return this.setState({payouts});
  }

  render() {
    const {locationId} = this.props;
    const {isLoading, payouts = [], rolesDict} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <TipBreakdownModalNew locationId={locationId} ref={(e) => (this.tipBreakdownModal = e)} />

        <Card
          label={
            <div className={"flex flex-row"}>
              <div>Tip Payouts</div>

              <GrayBadge darkGrey className={"ml-2"}>
                Beta
              </GrayBadge>
            </div>
          }
          description={"Your shop's outstanding tip payouts"}
        >
          <Table
            pagination
            columns={[
              {
                value: "NAME",
                label: "NAME",
                format: (value, row) => {
                  const {TIMESHEETS: timesheets} = row;

                  return timesheets[0]?.FULL_NAME ?? "";
                },
              },
              {
                value: "TIP_CREDIT_DEFICIT",
                label: "Payout Amount ($)",
                format: (value, row) => {
                  const {TIMESHEETS = [], TIP_CREDIT_DEFICIT = 0} = row;

                  const tipPayout =
                    TIMESHEETS?.reduce(
                      (accum, item) => accum + item.AMOUNT_TIPS - item.AMOUNT_TIPS_PAYED,
                      0
                    ) + TIP_CREDIT_DEFICIT;

                  return "$" + toDollars(tipPayout);
                },
              },
            ]}
            expandable={(row) => {
              //Causes infinite loop if you don't copy
              const rowCopy = cloneDeep(row);

              return (
                <Table
                  className={"mx-2"}
                  data={[...rowCopy.DIFF_TIMESHEETS, ...rowCopy.TIMESHEETS]}
                  columns={[
                    {
                      value: "ROLE_NAME",
                      label: "Role",
                      format: (val, row) => {
                        const roleName = rolesDict[row.ROLE_ID]?.NAME ?? "Archived Role";
                        return <div>{val ?? roleName}</div>;
                      },
                    },
                    {
                      value: "DATE",
                      label: "Date",
                      format: (value, row) => {
                        return (
                          <div>
                            {moment(row.DATE_START).format("M/D h:mm A") +
                              " - " +
                              moment(row.DATE_END).format("M/D h:mm A")}
                          </div>
                        );
                      },
                    },
                    {
                      value: "DATE_BLESSED",
                      label: "Last Blessed",
                      format: (value, _) => (value ? moment(value).format("M/D h:mm A") : "N/A"),
                    },
                    {
                      value: "AMOUNT_TIPS",
                      label: "Tips Earned",
                      format: (value, row) => {
                        return "$" + toDollars(value);
                      },
                    },
                    {
                      value: "AMOUNT_TIPS_PAYED",
                      label: "Tips Paid",
                      format: (value, _) => {
                        return <div>{value || value === 0 ? "$" + toDollars(value) : "-"}</div>;
                      },
                    },
                  ]}
                  actionButtons={[
                    {
                      label: "View Breakdown",
                      onClick: (row) => this.tipBreakdownModal.open(row),
                    },
                    {
                      label: "Resave",
                      onClick: (row) => this.resaveTimeCard(row),
                    },
                    {
                      label: "Clear Tip Payout",
                      onClick: (row) => this.clearDeficit(row),
                      format: () => <div className={"text-red-600"}>Clear Tip Payout</div>,
                    },
                  ]}
                />
              );
            }}
            data={payouts}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TipPayoutPage);

import React, {Component} from "react";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import GrayBadge from "../../../components/badges/gray-badge";
import PayrollSuccessModal from "../../../modals/payroll/payroll-success-modal";
import PayrollRunErrorModal from "../../../modals/payroll/terminal/payroll-run-error-modal";
import WarningBadge from "../../../components/badges/warning-badge";

class RunLocations extends Component {
  state = {runLocations: null};

  async componentDidMount() {
    await this.syncState();
  }

  syncState = async () => {
    let runLocations = await request("admin/payroll/views/run", "GET");
    this.setState({runLocations});
  };

  render() {
    const {runLocations} = this.state;

    if (!runLocations) {
      return (
        <div className={"mt-5"}>
          <LoadingSpinner />
        </div>
      );
    }

    let actionButtons = [
      {
        label: "Edit",
        onClick: (row) => {
          console.log(row);
          this.payrollOnboardLocationModal.open(row.LOCATION_ID);
        },
      },
      {
        label: <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />,
        onClick: (row) => {
          window.open(`https://console.checkhq.com/companies/` + row.id, "_blank");
        },
      },
    ];

    const liveColumns = [
      {
        label: "Name",
        value: "NAME",
        format: (value, row) =>
          `${value} ${row.TIMESHEET_LOCATION_COUNT > 1 ? `(${row.TIMESHEET_LOCATION_COUNT})` : ""}`,
      },
      {
        label: "Approval Deadline",
        value: "",
        format: (value, row) => (row.PAYRUN ? moment(row.PAYRUN?.APPROVAL_DEADLINE).format("M/D") : ""),
        width: 1,
      },
      {
        label: "Next Payday",
        value: "",
        format: (value, row) => (row.PAYRUN ? moment(row.PAYRUN?.PAYDAY).format("M/D") : ""),
        width: 1,
      },
      {
        label: "Status",
        value: "",
        format: (value, row) => {
          switch (row.PAYRUN?.PAYROLL_STATUS) {
            case "approved":
              if (row.PAYRUN.TESTS_PASSED) {
                return <SuccessBadge>Approved</SuccessBadge>;
              } else {
                return (
                  <DangerBadge onClick={() => this.payrollRunErrorModal.open(row.PAYRUN.RUN_ERRORS)}>
                    Approved
                  </DangerBadge>
                );
              }
            case "paid":
              return <SuccessBadge>Paid</SuccessBadge>;
            case "processing":
              return <SuccessBadge>Processing</SuccessBadge>;
            case "late":
              return <DangerBadge>Late</DangerBadge>;
            case "period_open":
              return <GrayBadge>Period Open</GrayBadge>;
            case "needs_run":
              return <WarningBadge>Needs Run</WarningBadge>;
          }

          return <DangerBadge>No Pay Schedule</DangerBadge>;
        },
        width: 1,
      },
    ];

    return (
      <div>
        <PayrollSuccessModal refresh={this.syncState} ref={(e) => (this.payrollOnboardLocationModal = e)} />

        <PayrollRunErrorModal ref={(e) => (this.payrollRunErrorModal = e)} />

        <div>
          <div className={"text-md font-bold my-3"}>
            Onboarding{" "}
            <span className={"font-normal text-sm"}>
              ({runLocations.filter((_loc) => isOnboarding(_loc)).length})
            </span>
          </div>

          <Table
            columns={onboardingColumns}
            data={runLocations
              .filter((_loc) => isOnboarding(_loc))
              .sort((a, b) => {
                if (!a.PAYROLL_START_DATE && b.PAYROLL_START_DATE) {
                  return 1;
                } else if (a.PAYROLL_START_DATE && !b.PAYROLL_START_DATE) {
                  return -1;
                }

                return moment(a.PAYROLL_START_DATE).valueOf() - moment(b.PAYROLL_START_DATE).valueOf();
              })}
            actionButtons={actionButtons}
          />
        </div>

        <div>
          <div className={"text-md font-bold my-3"}>
            Live{" "}
            <span className={"font-normal text-sm"}>
              (
              {runLocations
                .filter((_loc) => isLive(_loc))
                .reduce((sum, _loc) => sum + _loc.TIMESHEET_LOCATION_COUNT, 0)}
              )
            </span>
          </div>

          <Table
            columns={liveColumns}
            data={runLocations
              .filter((_loc) => isLive(_loc))
              .sort(
                (a, b) =>
                  moment(a.PAYRUN?.APPROVAL_DEADLINE).valueOf() -
                  moment(b.PAYRUN?.APPROVAL_DEADLINE).valueOf()
              )
              .sort((a, b) => {
                const order = {
                  none: 0,
                  late: 1,
                  needs_run: 2,
                  period_open: 2,
                  approved: 3,
                  processing: 4,
                  paid: 5,
                };

                return order[a.PAYRUN?.PAYROLL_STATUS ?? "none"] - order[b.PAYRUN?.PAYROLL_STATUS ?? "none"];
              })}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    );
  }
}

const isOnboarding = (_loc) =>
  ["ONBOARDING", "SUBMITTED"].includes(_loc.PAYROLL_GROUP_STATUS) &&
  !moment().isAfter(moment(_loc.PAYROLL_START_DATE));

const isLive = (_loc) => _loc.PAYROLL_GROUP_STATUS === "LIVE";

const onboardingColumns = [
  {
    label: "Name",
    value: "NAME",
  },
  {
    label: "Start Date",
    value: "PAYROLL_START_DATE",
    format: (value) =>
      value
        ? `${moment(value).format("M/D")} (In ${moment(value)
            .startOf("day")
            .diff(moment().startOf("day"), "days")} Days)`
        : "None",
    width: 1,
  },
];

const TIP_CALCULATION_METHOD_TYPES = {
  TRANSACTION: "Trans",
  DAILY: "Day",
  WEEKLY: "Week",
  PAY_PERIOD: "Period",
};

export default RunLocations;

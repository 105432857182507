import {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Error extends Component {
  render() {
    const {label} = this.props;

    return (
      <div className="flex flex-col content-center justify-center text-center">
        <div className={"mx-auto"}>
          <FontAwesomeIcon className="flex h-8 w-8" icon={"exclamation-triangle"} color={"gray"} />
        </div>

        <div className={"text-gray-600 mt-3"}>{label}</div>
      </div>
    );
  }
}

Error.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Error;

import React, {useEffect, useState} from "react";
import {
  GoogleMap,
  HeatmapLayer,
  HeatmapLayerF,
  InfoWindow,
  LoadScript,
  Marker,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import {MapRequests} from "../../utils/request-helpers/map/map-requests";
import {FormCheck, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FormCheckbox from "../../components/form-elements/form-checkbox";
import LoadingSpinner from "../../components/loading-spinner";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 110px)",
};
const libraries = ["visualization"]; // Define the libraries array outside the component

const center = {
  lat: 39.5,
  lng: -98.35,
};

const createHeatMapData = (locations) => {
  return locations.map(({LATITUDE, LONGITUDE, ORDER_COUNT}) => {
    return {
      // eslint-disable-next-line no-undef
      location: new google.maps.LatLng(LATITUDE, LONGITUDE),
      weight: ORDER_COUNT * 10000,
    };
  });
};

function MapPage() {
  const [locations, setLocations] = useState([]);
  const [showMarkers, setShowMarkers] = useState(true);
  const [activeMarker, setActiveMarker] = useState(true);
  const [showHeatmap, setShowHeatmap] = useState(true);

  const googleMapsApiKey = "AIzaSyCIP0w6olBcNhOi2YpnLzI7zE0pt2uRxAE";

  const {isLoaded} = useLoadScript({
    googleMapsApiKey,
    libraries: ["visualization"],
  });

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    const fetchData = async () => {
      const locations = await MapRequests.fetchLocations();

      setLocations(locations);
    };
    fetchData();
  }, []);

  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded && locations.length > 0 ? (
    <div>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={5} onUnmount={onUnmount}>
        {showHeatmap && <HeatmapLayer data={createHeatMapData(locations)} />}

        {showMarkers &&
          locations.map((location) => (
            <Marker
              key={location.NAME}
              position={{lat: location.LATITUDE, lng: location.LONGITUDE}}
              onClick={() => handleActiveMarker(location.ADDRESS)}
            >
              {showMarkers && activeMarker === location.ADDRESS ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div>{location.NAME}</div>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
      </GoogleMap>

      <div className={"flex flex-row"}>
        <FormCheck
          value={showMarkers}
          className={"pl-5"}
          label={"Show Locations"}
          onChange={() => {
            setShowMarkers(!showMarkers);
          }}
        />

        <FormCheck
          value={showHeatmap}
          className={"pl-5"}
          label={"Show Heatmap"}
          onChange={() => {
            setShowHeatmap(!showHeatmap);
          }}
        />
      </div>
    </div>
  ) : (
    <>
      <LoadingSpinner />
    </>
  );
}

export default MapPage;

import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, FormToggle} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";

class PermissionModal extends Component {
  state = {
    permissions: [],
    rolePermissions: [],
    permissionSection: null,
    adminEnabled: false,
  };

  open(permissions, rolePermissions, permissionSection) {
    this.setState(
      {
        permissions,
        rolePermissions,
        permissionSection,
        adminEnabled: false,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.modal.open();
      }
    );
  }

  setAdminEnabled(enabled) {
    this.setState((prevState) => {
      if (prevState.adminEnabled !== enabled) {
        return {adminEnabled: enabled};
      }
    });
  }

  isDisabled(setting) {
    const {permissionSection, rolePermissions} = this.state;

    if (setting === "ADMIN_FULL") {
      return false;
    } else if (rolePermissions.includes("ADMIN_FULL")) {
      return true;
    } else if (setting === `${permissionSection}_FULL`) {
      return false;
    } else if (rolePermissions.includes(`${permissionSection}_FULL`)) {
      return true;
    }

    return false;
  }

  /**
   * Creates a function that toggles based on the master toggle with some conditions
   * When the master toggle is selected, all toggles are enabled
   * When the master toggle is deselected, all toggles are disabled
   * Master toggle is disabled if any other toggle is disabled
   * Master toggle is enabled if every other toggle is enabled
   * @property values - Object containing current value of toggles
   * @property setFieldValue - Provided function from Formik
   * @property id - Name of Toggle
   * @returns (val) => void
   */
  onChangeGenerator = (values, setFieldValue, id) => {
    const {permissionSection} = this.state;
    const master = `${permissionSection}_FULL`;

    return (val) => {
      //Makes all toggles follow 'master' toggle
      if (id === master) {
        for (const path of Object.keys(values)) {
          setFieldValue(path, val);
        }
        return;
      }

      // Turn off 'master' toggle if any other toggle is deselected
      if (val === "0") {
        setFieldValue(master, "0");
        return;
      }

      // Turn on 'master' toggle if all other toggles are selected
      if (
        Object.keys(values).filter((value) => value !== master && values[value] === "1").length ===
        Object.keys(values).length - 2
      ) {
        setFieldValue(master, "1");
        return;
      }
    };
  };

  render() {
    const {permissions, rolePermissions} = this.state;
    const {onSubmit} = this.props;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Permissions"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={(vals) => onSubmit(vals) || this.modal.close()}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={permissions.reduce((dict, item) => {
            dict[item.id] = rolePermissions.indexOf(item.id) === -1 ? "0" : "1";

            return dict;
          }, {})}
        >
          {(formikOptions) => {
            const {values, setFieldValue} = formikOptions;

            return permissions.map(({id, label, description}) => {
              return (
                <FormToggle
                  key={id}
                  name={id}
                  label={label}
                  description={description}
                  options={{
                    ...formikOptions,
                    handleChange: this.onChangeGenerator(values, setFieldValue, id),
                  }}
                  disabled={this.isDisabled(id)}
                  onOverride={this.isDisabled(id)}
                  flex
                />
              );
            });
          }}
        </Formik>
      </Modal>
    );
  }
}

PermissionModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PermissionModal;

import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import ProductRecipePage from "../../../../../pages/operations/supply-chain/recipes/product-recipe-page";
import {withRouter} from "../../../../../utils/navigation";

class SetupProductIngredientsModal extends Component {
  state = {
    product: null,
  };

  open(product) {
    this.setState({product}, () => this.modal.open());
  }

  handleClose() {
    const {pathname} = window.location;
    window.history.pushState({}, "", pathname);
    return;
  }

  render() {
    const {product} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        full
        hideClose={true}
        buttonLabel="Close"
        buttonOnClick={() => {
          this.handleClose();
          this.modal.close();
        }}
      >
        <ProductRecipePage productId={product} />
      </Modal>
    );
  }
}

export default withRouter(SetupProductIngredientsModal);

import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {WasteRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class WasteTypeModal extends Component {
  state = {wasteType: null};

  open(wasteType = null) {
    this.setState({wasteType}, () => this.modal.open());
  }

  saveWasteType(values) {
    const {name, description} = values;
    const {wasteType} = this.state;

    WasteRequests.updateWasteType(wasteType.ID, {
      NAME: name,
      DESCRIPTION: description,
    })
      .then(() => {
        return this.props.syncState && this.props.syncState();
      })
      .then(() => {
        this.modal.close();
      });
  }

  createWasteType(values) {
    const {name, description} = values;

    WasteRequests.createWasteType({
      NAME: name,
      DESCRIPTION: description,
    })
      .then(() => {
        return this.props.syncState && this.props.syncState();
      })
      .then(() => {
        this.modal.close();
      });
  }

  render() {
    const {wasteType} = this.state;

    return (
      <Modal
        buttonLabel={wasteType ? "Save" : "Add"}
        label={wasteType ? "Edit Waste Type" : "Create Waste Type"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={(values) => (wasteType ? this.saveWasteType(values) : this.createWasteType(values))}
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: wasteType?.NAME ?? "",
            description: wasteType?.DESCRIPTION ?? "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().nullable().required("Please enter a name for this unit."),
            description: Yup.string().nullable().required("Please enter a description for this unit."),
          })}
        >
          {(formikOptions) => {
            return (
              <form>
                <FormInput label="Name" name="name" options={formikOptions} />

                <FormInput label="Description" name="description" options={formikOptions} />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default WasteTypeModal;

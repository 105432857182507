import React, {Component} from "react";
import {Modal, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {withRouter} from "../../utils/navigation";
import LoadingSpinner from "../../components/loading-spinner";

class ReorderModal extends Component {
  state = {employees: [], roles: [], isLoading: false};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  open() {
    this.modal.open();

    this.syncData();
  }

  close() {
    this.modal.close();
  }

  async saveBoth() {
    await Promise.all([this.saveEmployeeSequence(), this.saveRoleSequence()]);

    // Removed awaits since there are loading spinners anyway.
    this.syncData();
    this.props.refresh();
    this.modal.close();
  }

  async saveEmployeeSequence() {
    const {employees} = this.state;
    const {locationId} = this.props;

    return request("employees/seq", "POST", {
      EMPLOYEES: employees.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
      LOCATION_ID: locationId,
    });
  }

  async saveRoleSequence() {
    const {roles} = this.state;

    return request("employeeroles/seq", "POST", {
      ROLES: roles.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    });
  }

  async syncData() {
    const {locationId} = this.props;

    this.setState({isLoading: true});

    const serverRoles = await request("employeeroles/roles", "GET");

    const serverEmployees = await request("employees/v2/" + locationId, "GET", {
      ROLES: true,
      ACTIVE: 0,
    });

    serverEmployees.sort((a, b) => a.SEQ - b.SEQ);
    serverRoles.sort((a, b) => a.SEQ - b.SEQ);

    this.setState({
      employees: serverEmployees.filter((item) => parseInt(item.LOCATION_ID) === locationId),
      roles: serverRoles,
      isLoading: false,
    });
  }

  render() {
    const {employees, isLoading} = this.state;
    const {roles} = this.state;
    const activeEmployees = employees?.filter((item) => item.ACTIVE === 1);
    const activeRoles = roles?.filter((item) => !item.DATE_ARCHIVED);

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        closeLabel={"Close"}
        label={`Custom Order`}
        description={"Drag and drop to choose your desired order"}
        large
        full
        className="px-5"
        buttonLabel={"Save"}
        buttonOnClick={() => this.saveBoth()}
      >
        <Tab
          data={[
            {label: "Roles", id: "roles"},
            {label: "Employees", id: "employees"},
          ]}
        >
          {(id) => {
            if (id === "employees") {
              return (
                <Table
                  columns={[
                    {
                      label: "Name",
                      value: "FULL_NAME",
                    },
                  ]}
                  data={activeEmployees}
                  draggable
                  // onDrag={this.saveEmployeeSequence.bind(this)}
                  outRef={this.modal}
                />
              );
            }

            if (!!isLoading) {
              return (
                <div className="mt-4">
                  <LoadingSpinner />
                </div>
              );
            }

            return (
              <Table
                columns={[
                  {
                    label: "Name",
                    value: "NAME",
                  },
                ]}
                data={activeRoles}
                draggable
                // onDrag={this.saveRoleSequence.bind(this)}
                outRef={this.modal}
              />
            );
          }}
        </Tab>
      </Modal>
    );
  }
}

export default withRouter(ReorderModal);

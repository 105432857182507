import React, {Component} from "react";
import {request} from "../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import moment from "moment-timezone";
import FormDateTimeSelect from "../../components/form-date-time-select";
import {
  FormSelect,
  FormTextArea,
  Modal,
  Loading,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import MultiDaySelect from "../../components/multi-day-select";
import {checkDSTConversion} from "../../utils/time-helper";
import {showErrorNotification, showSuccessNotification} from "../../utils/notification-helper";
import FormFromToTime from "../../components/form-from-to-time";

class OpenShiftModal extends Component {
  state = {
    loading: true,
    open: false,
    initialStart: null,
    initialEnd: null,
    initialNotes: "",
    shiftRecord: null, // implies that we are updating an existing record
    assignableRoles: [],
    assignableEmployees: [],
    currentRoleId: null,
    initialShiftTagId: null,
    shiftTags: [],
  };

  findEmployeeRoleId = (employeeId, roleId) => {
    if (employeeId === null) {
      return null;
    }

    const {assignableEmployees} = this.state;
    const employee = assignableEmployees.find((item) => item.ID === employeeId);

    if (!employee) {
      return null;
    }

    const {EMPLOYEE_ROLES: roles} = employee;
    const role = roles.find((item) => item.ROLE_ID === roleId);

    if (!role) {
      return null;
    }

    return role.ID;
  };

  getUniqueRoles = (employees) => {
    let uniq = [];
    let ids = [];

    for (const emp of employees) {
      const {EMPLOYEE_ROLES: roles} = emp;
      //Check if roles exist otherwise will crash scheduler
      if (roles) {
        for (const role of roles) {
          const {ROLE_ID, ROLE_NAME} = role;
          if (ids.indexOf(ROLE_ID) === -1) {
            ids.push(ROLE_ID);
            uniq.push({ROLE_ID, ROLE_NAME});
          }
        }
      }
    }

    return uniq;
  };

  componentDidMount = async () => {
    this.setState({loading: true});

    let [assignableEmployees, shiftTags] = await Promise.all([
      request("employees", "GET"),
      request("team/shift-tags", "GET"),
    ]);

    shiftTags = [
      {
        NAME: "None",
        ID: null,
      },
      ...shiftTags,
    ];

    let assignableRoles = this.getUniqueRoles(assignableEmployees);

    this.setState({
      assignableEmployees,
      assignableRoles,
      shiftTags,
      loading: false,
    });
  };

  closeModal = () => {
    this.slide.close();
  };

  openForCreation = (startOfDayEpoch, start, end) => {
    this.setState(
      {
        open: true,
        initialDate: startOfDayEpoch,
        initialStart: null,
        initialEnd: null,
        initialRoleId: null,
        shiftRecord: null,
        initialNotes: "",
        currentRoleId: null,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.slide.open();
      }
    );
  };

  openForEdit = (shiftRecord) => {
    this.setState(
      {
        open: true,
        initialDate: moment(shiftRecord.DATE_START).valueOf(),
        initialStart: moment(shiftRecord.DATE_START).valueOf(),
        initialEnd: moment(shiftRecord.DATE_END).valueOf(),
        initialRoleId: shiftRecord.ROLE_ID,
        initialNotes: shiftRecord.NOTES,
        currentRoleId: shiftRecord.ROLE_ID,
        shiftRecord,
        initialShiftTagId: shiftRecord.SHIFT_TAG_ID,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.slide.open();
      }
    );
  };

  handleFormSubmission = async (values) => {
    const {shiftRecord} = this.state;
    let {to, from, date, roleId, notes, days, shiftTagId, location} = values;
    const {startDay: startIsoDay = 1, isToggledLocation} = this.props;

    to = parseInt(to.split(":")[0] * 60) + parseInt(to.split(":")[1]);
    from = parseInt(from.split(":")[0] * 60) + parseInt(from.split(":")[1]);

    if (to && to < from) {
      to += 1440;
    }

    const {dateStart, dateEnd} = checkDSTConversion(date, from, to);

    const mainPayload = {
      EMPLOYEE_ID: null,
      ROLE_ID: roleId,
      DATE_START: dateStart.valueOf(),
      DATE_END: dateEnd.valueOf(),
      NOTES: notes,
      SHIFT_TAG_ID: shiftTagId,
    };

    let shiftList = [];
    const weekday = moment(date).isoWeekday();
    let shifts;

    if (!shiftRecord) {
      if (days.length > 0) {
        shiftList = days.map((day) => {
          // these two variables allow us to recenter the day around the start day for the purposes of calculating the day
          // offset
          const calcWeekday = weekday < startIsoDay ? weekday + 7 : weekday;
          const calcDay = day < startIsoDay ? day + 7 : day;

          const dayOffset = calcDay - calcWeekday;

          const newDate = moment(date).add(dayOffset, "days");
          let {dateStart, dateEnd} = checkDSTConversion(newDate, from, to);

          return {
            EMPLOYEE_ID: null,
            EMPLOYEE_ROLE_ID: null,
            ROLE_ID: roleId,
            DATE_START: dateStart.valueOf(),
            DATE_END: dateEnd.valueOf(),
            NOTES: notes,
            SHIFT_TAG_ID: shiftTagId,
          };
        });
      }

      shiftList.push(mainPayload);

      try {
        shifts = await request("scheduling/v2/shifts", "POST", {
          shifts: shiftList,
          LOCATION_ID: location,
        });
      } catch (err) {
        showErrorNotification(
          "Error Creating Open Shift",
          "Encountered an error trying to create shift, please refresh the scheduler and try again."
        );

        return this.closeModal();
      }
    } else {
      const {employeeId} = values;
      const employeeRoleId = this.findEmployeeRoleId(employeeId, roleId);

      if (days.length > 0) {
        shiftList = days.map((day) => {
          // these two variables allow us to recenter the day around the start day for the purposes of calculating the day
          // offset
          const calcWeekday = weekday < startIsoDay ? weekday + 7 : weekday;
          const calcDay = day < startIsoDay ? day + 7 : day;

          const dayOffset = calcDay - calcWeekday;

          const newDate = moment(date).add(dayOffset, "days");
          let {dateStart, dateEnd} = checkDSTConversion(newDate, from, to);

          return {
            EMPLOYEE_ID: employeeId,
            EMPLOYEE_ROLE_ID: employeeRoleId,
            ROLE_ID: roleId,
            DATE_START: dateStart.valueOf(),
            DATE_END: dateEnd.valueOf(),
            NOTES: notes,
            SHIFT_TAG_ID: shiftTagId,
          };
        });
      }

      const {dateStart, dateEnd} = checkDSTConversion(date, from, to);

      const mainPayload = {
        ID: shiftRecord.ID,
        EMPLOYEE_ID: employeeId,
        EMPLOYEE_ROLE_ID: employeeRoleId,
        ROLE_ID: roleId,
        DATE_START: dateStart.valueOf(),
        DATE_END: dateEnd.valueOf(),
        NOTES: notes,
        SHIFT_TAG_ID: shiftTagId,
      };

      shiftList.push(mainPayload);

      try {
        shifts = await request(`scheduling/v2/shifts`, "POST", {
          shifts: shiftList,
          LOCATION_ID: location,
        });
      } catch (err) {
        showErrorNotification(
          "Error Creating Open Shift",
          "Encountered an error trying to create shift, please refresh the scheduler and try again."
        );

        return this.closeModal();
      }
    }

    if (isToggledLocation && shifts && this.props.upsertShifts) {
      this.props.upsertShifts(shifts);
    }

    showSuccessNotification(
      "Open Shift Saved",
      "Your changes have been recorded. If you do not immediately see your shift on the schedule, please make sure you are toggled on the correct location."
    );

    this.closeModal();
  };

  handleEventDelete = async () => {
    try {
      const {shiftRecord} = this.state;
      const {ID: id} = shiftRecord;

      const toDeleteShift = await request(
        "scheduling/locations/shift/" + this.state.shiftRecord.ID,
        "DELETE",
        {}
      );

      if (toDeleteShift) {
        this.props.deleteShift(toDeleteShift.ID);
      }

      showSuccessNotification("Delete Shift Success");

      return this.closeModal();
    } catch (e) {
      return showErrorNotification(
        "Delete Shift Error",
        "Encountered an error while trying to delete shift. Please refresh the page and try again."
      );
    }
  };

  render() {
    const {startDay: startIsoDay = 1, locations, locationIdArr = []} = this.props;

    let {
      initialDate,
      initialStart,
      initialEnd,
      initialRoleId,
      initialNotes,
      initialShiftTagId,
      shiftRecord,
      assignableRoles,
      assignableEmployees,
      currentRoleId,
      shiftTags,
    } = this.state;

    let initialValue = {
      date: initialDate ? moment(initialDate).startOf("day").valueOf() : moment().startOf("day").valueOf(),
      from: initialStart ? moment(initialStart).format("HH:mm") : undefined,
      to: initialEnd ? moment(initialEnd).format("HH:mm") : undefined,
      roleId: initialRoleId,
      notes: initialNotes,
      days: [],
      shiftTagId: initialShiftTagId,
      location: locationIdArr.length > 0 ? locationIdArr[0] : null,
    };

    const displayRoles = assignableRoles.map((item) => {
      return {value: item.ROLE_ID, label: item.ROLE_NAME};
    });

    let displayEmployees = [];

    if (shiftRecord) {
      //displayEmployees = this.findEmployeesWithRole(currentRoleId);
      displayEmployees = assignableEmployees
        .filter((item) => {
          // this should never happen
          if (!item.EMPLOYEE_ROLES) {
            return false;
          }

          for (const role of item.EMPLOYEE_ROLES) {
            if (role.ROLE_ID === currentRoleId) {
              return true;
            }
          }
          return false;
        })
        .map((item) => {
          return {value: item.ID, label: item.FULL_NAME};
        });
    }

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <Modal
        large
        formikOnClick={() => this.formikRef}
        buttonLabel={shiftRecord ? "Assign" : "Save"}
        label={shiftRecord ? "Edit Open Shift" : "Create Open Shift"}
        description=""
        deleteLabel={shiftRecord ? "Delete Open Shift" : undefined}
        deleteOnClick={shiftRecord ? () => this.handleEventDelete() : undefined}
        ref={(e) => (this.slide = e)}
      >
        <Formik
          onSubmit={this.handleFormSubmission}
          initialValues={initialValue}
          enableReinitialize={true}
          validationSchema={validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            let {handleSubmit, setFieldValue, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {locations && locations.length > 1 && (
                  <FormSelect
                    className="flex-1 mr-2"
                    name="location"
                    label="Location"
                    options={formikOptions}
                    data={locations}
                  />
                )}

                <FormSelect
                  name="roleId"
                  className="mt-2"
                  label="Role"
                  options={formikOptions}
                  data={displayRoles}
                  onChangeSoft={({value}) => {
                    // TODO clear the employee select
                    this.setState({currentRoleId: value});
                  }}
                />

                {shiftTags.length > 1 && (
                  <FormSelect
                    label={"Shift Tag"}
                    name={"shiftTagId"}
                    data={shiftTags.map((st) => ({
                      label: st.NAME,
                      value: st.ID,
                    }))}
                    options={formikOptions}
                  />
                )}

                {shiftRecord && (
                  <FormSelect
                    name="employeeId"
                    className="mt-2"
                    label="Assign To"
                    options={formikOptions}
                    data={displayEmployees}
                    ref={(e) => (this.employeeSelect = e)}
                  />
                )}

                <FormDateTimeSelect
                  name="date"
                  label={"Date"}
                  options={formikOptions}
                  buttonText={(epoch) => moment(epoch).format("dddd, M/D")}
                  hideTime={true}
                  onChangeSoft={(epoch) => {
                    const isoDay = moment(epoch).isoWeekday() - 1;
                    const {days} = values;
                    const idx = days.indexOf(isoDay);

                    if (idx !== -1) {
                      days.splice(idx, 1);
                      setFieldValue("days", [...days]);
                    }
                  }}
                />

                <FormFromToTime
                  name="time"
                  label="Time"
                  className={"mt-2"}
                  tooltip={{
                    label: "Time Entry",
                    data: "Separate times with a -",
                  }}
                  options={formikOptions}
                />

                <FormTextArea className="mt-2" label="Notes" name="name" options={formikOptions} />

                <MultiDaySelect
                  disableDays={[moment(values.date).isoWeekday()]}
                  options={formikOptions}
                  onChange={(days) => setFieldValue("days", [...days])}
                  iconPadding
                  name="days"
                  label={"Duplicate Days"}
                  tooltip={{
                    data: "Select days that this shift should be duplicated to.",
                  }}
                  hint={"Optional"}
                  ignoreShadow
                  startDay={parseInt(startIsoDay)}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default OpenShiftModal;

const validationSchema = Yup.object().shape({
  roleId: Yup.number().typeError("Please select a role").required("Please select a role"),
  from: Yup.string().typeError("Please set a valid start time").required("Please set a start time"),
  to: Yup.string().typeError("Please set a valid end time").required("Please set an end time"),
});

import {Formik} from "formik";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {useSearchParams} from "react-router-dom";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import {Box, Row, ReadOnlyTable} from "../shared";
import {Button, FormInput, Modal, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {useState} from "react";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {RecipeRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {setProduct, setProducts, updateProducts} from "../../../../redux/menu";
import LoadingSpinner from "../../../../components/loading-spinner";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";

const OptionButton = ({option, setValue, enabled}) => {
  const {ID, NAME} = option;
  return (
    <Button
      type={enabled ? "button" : "gray"}
      className="mr-4 mt-2 w-32 h-16 items-center"
      label={NAME}
      onClick={() => setValue(ID)}
    />
  );
};

const recipeColumns = (unitDict) => [
  {key: "VARIANT_OPTION", label: "Variant Name", format: (it) => it?.NAME ?? "All Variants"},
  {key: "OPTION", label: "Modifier Option", format: (it) => it?.NAME ?? "All Options"},
  {key: "NAME", label: "Ingredient"},
  {
    key: "RECIPE_COST",
    label: "Quantity",
    formatRow: (row) => `${row.RECIPE_COST} ${unitDict[row.UNIT_ID]?.NAME}`,
  },
  {
    key: "CURRENT_STOCK",
    label: "Current Stock",
    formatRow: (row) => `${row.CURRENT_STOCK} ${unitDict[row.UNIT_ID]?.NAME}`,
  },
];

export const RemoveRecipeView = ({unitDict, product, className = ""}) => {
  const dispatch = useDispatch();
  const columns = [
    ...recipeColumns(unitDict),
    {
      key: "Actions",
      formatRow: (row) => {
        return (
          <div
            className="text-blue-600 cursor-pointer"
            onClick={() => {
              product.RECIPES = product.RECIPES.filter((it) => it.RECIPE_ID !== row.RECIPE_ID);
              const newRecipes = product.RECIPES.map((recipe) => ({
                OPTION_ID: recipe.OPTION_ID ?? null,
                VARIANT_OPTION_ID: recipe.VARIANT_OPTION_ID ?? null,
                INGREDIENT_ID: recipe.ID,
                PRODUCT_ID: product.ID,
                QUANTITY: recipe.RECIPE_COST,
              }));
              dispatch(setProduct(product));
              RecipeRequests.attachRecipe(product.ID, {RECIPES: newRecipes}).then(() =>
                dispatch(updateProducts())
              );
            }}
          >
            Remove {row.NAME}
          </div>
        );
      },
    },
  ];
  return <ReadOnlyTable className={className} columns={columns} data={product.RECIPES} />;
};

const AddIngredientForm = ({selectedOptionId, activeOption, selectedVariantId, activeVariant, product, unitDict}) => {
  const [ingredient, setIngredient] = useState({});
  const menu = useSelector((state) => state.menu); // Replace 'user' with the correct slice
  const dispatch = useDispatch();

  return (
    <Formik
      validationSchema={Yup.object().shape({
        ingredient: Yup.string().nullable().required("Please select an ingredient"),
        quantity: Yup.number().positive().nullable().required("Please enter a number greater than 0"),
        unit: Yup.string().nullable().required("Please select a unit"),
      })}
      initialValues={{
        ingredient: null,
        quantity: null,
        unit: null,
      }}
    >
      {(formikOptions) => {
        const {values, setFieldValue} = formikOptions;

        return (
          <Row>
            <IngredientDropdown
              name="ingredient"
              placeholder="Select an ingredient"
              options={formikOptions}
              onChangeSoft={({ingredient}) => {
                setIngredient(ingredient);
                setFieldValue("unit", ingredient.UNIT_ID);
              }}
              className="pr-1.5"
            />

            <FormInput placeholder="Quantity" name="quantity" options={formikOptions} className="px-1.5" />

              <UnitDropdown
                  name="unit"
                  placeholder="Unit of measurement"
                  ingredient={ingredient}
                  options={formikOptions}
                  unit={ingredient?.UNIT_ID}
                  className="pl-1.5 pr-3"
              />

              {ingredient && values.unit && <Button
              label="Add"
              className="w-32 h-9.5 my-3"
              onClick={() => {
                const baseRecipes = product.RECIPES.map((recipe) => ({
                  OPTION_ID: recipe.OPTION_ID ?? null,
                  VARIANT_OPTION_ID: recipe.VARIANT_OPTION_ID ?? null,
                  INGREDIENT_ID: recipe.ID,
                  PRODUCT_ID: product.ID,
                  QUANTITY: recipe.RECIPE_COST,
                }));

                const RECIPES = [
                  ...baseRecipes,
                  {
                    OPTION_ID: selectedOptionId,
                    VARIANT_OPTION_ID: selectedVariantId,
                    INGREDIENT_ID: ingredient?.ID,
                    PRODUCT_ID: product.ID,
                    QUANTITY: parseFloat(values.quantity),
                  },
                ];

                let quantity = parseFloat(values.quantity);
                  if (values.unit !== ingredient.UNIT_ID) {
                      const unit = unitDict[values.unit];
                      const conversion = unit.CONVERSIONS.find(
                          (conversion) => conversion.TO_UNIT === ingredient.UNIT_ID
                      );
                      quantity *= conversion?.RATE;
                  }

                const newRenderableRecipe = {
                  ...ingredient,
                  RECIPE_COST: quantity,
                  OPTION_ID: selectedOptionId,
                  VARIANT_OPTION_ID: selectedVariantId,
                  PRODUCT_ID: product.ID,
                  OPTION: activeOption,
                  VARIANT_OPTION: activeVariant,
                };
                product.RECIPES.push(newRenderableRecipe);
                dispatch(setProduct(product));
                RecipeRequests.attachRecipe(product.ID, {RECIPES}).then(() => dispatch(updateProducts()));
              }}
            />}
          </Row>
        );
      }}
    </Formik>
  );
};
function safeParseInt(value, radix = 10) {
  const parsed = parseInt(value, radix);
  return isNaN(parsed) ? null : parsed;
}

const ProductRecipePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const productId = parseInt(searchParams.get("productId")),
    selectedOptionId = safeParseInt(searchParams.get("selectedOptionId")),
    selectedCustomizationId = safeParseInt(searchParams.get("selectedCustomizationId")),
    selectedVariantId = safeParseInt(searchParams.get("selectedVariantId"));

  const supply = useSelector((state) => state.supply); // Replace 'user' with the correct slice
  const menu = useSelector((state) => state.menu); // Replace 'user' with the correct slice
  const currentActiveId = menu.activeProduct?.ID;
  if (currentActiveId !== productId) {
    dispatch(setProduct(menu.products.find((it) => it.ID === productId)));
  }
  const product = menu.activeProduct;
  if (!product) {
    return <LoadingSpinner />;
  }

  const setVariant = (variantId) => {
    if (variantId === selectedVariantId) {
      searchParams.delete("selectedVaraintId");
    } else {
      searchParams.set("selectedVariantId", variantId);
    }
    setSearchParams(searchParams);
  };
  const setCustomization = (customizationId) => {
    if (customizationId === selectedCustomizationId) {
      searchParams.delete("selectedCustomizationId");
    } else {
      searchParams.set("selectedCustomizationId", customizationId);
    }
    setSearchParams(searchParams);
  };
  const setOption = (optionId) => {
    if (optionId === selectedOptionId) {
      searchParams.delete("selectedOptionId");
    } else {
      searchParams.set("selectedOptionId", optionId);
    }
    setSearchParams(searchParams);
  };

  const variant = product.CUSTOMIZATIONS?.find((it) => it.TYPE === CUSTOMIZATION_TYPES.VARIANT);
  const modifiers = product.CUSTOMIZATIONS?.filter((it) => it.TYPE !== CUSTOMIZATION_TYPES.VARIANT);
  const currentModifierGroup = modifiers?.find((it) => it.ID === selectedCustomizationId);

  const activeVariant = variant?.OPTIONS?.find((it) => it.ID === selectedVariantId);
  const activeOption = currentModifierGroup?.OPTIONS?.find((it) => it.ID === selectedOptionId);

  const unitDict = parseIdDict(supply.units ?? []);

  const changeProduct = {
    label: "All Recipes",
    onClick: async () => {
      console.log("Hello");
    },
  };

  return (
    <>
      <PageHeadings label={product.NAME} description="Edit your product's recipe" />

      {variant && <div className="text-lg font-semibold mt-2 pt-2">Variant</div>}
      {variant && (
        <Row className="mt-2">
          {variant &&
            variant.OPTIONS.map((option) => {
              console.log(selectedVariantId, option);
              return (
                <OptionButton
                  option={option}
                  setValue={setVariant}
                  enabled={selectedVariantId === option.ID}
                />
              );
            })}
        </Row>
      )}

      <div className="text-lg font-semibold mt-2 pt-2">Modifier</div>
      <Row className="mt-2">
        {modifiers.map((customization) => {
          return (
            <OptionButton
              option={customization}
              setValue={setCustomization}
              enabled={selectedCustomizationId === customization.ID}
            />
          );
        })}
      </Row>

      <div className="text-lg font-semibold mt-2 pt-2">Options</div>
      <Box className="mt-2 flex-wrap">
        {currentModifierGroup &&
          currentModifierGroup.OPTIONS.map((option) => {
            return (
              <OptionButton option={option} setValue={setOption} enabled={selectedOptionId === option.ID} />
            );
          })}
      </Box>

      <div class="border-t border-gray-300 my-4"></div>

      <div className="text-lg font-semibold mt-2">Add Recipe Ingredient</div>
      <AddIngredientForm
        selectedOptionId={selectedOptionId}
        activeOption={activeOption}
        selectedVariantId={selectedVariantId}
        activeVariant={activeVariant}
        product={product}
        unitDict={unitDict}
      />

      <div class="border-t border-gray-300 my-4"></div>

      <div className="text-lg font-semibold mt-2">All Product Recipes</div>
      <RemoveRecipeView className="mt-4" product={product} unitDict={unitDict} />
    </>
  );
};

export default ProductRecipePage;

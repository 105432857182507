import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class GiftCardUploadImagesModal extends Component {
  state = {uploadedFiles: []};

  open() {
    this.setState({uploadedFiles: []});
    this.modal.open();
  }

  handleSubmit = async () => {
    const {uploadedFiles} = this.state;

    const payload = uploadedFiles.map((logo) => {
      return {LOGO: logo, ENABLED: true};
    });

    await request("cards/images", "PUT", {IMAGES: payload});

    const giftCardImages = await request("cards/images", "GET", null);

    this.props.updateShop({giftCardImages});
    this.modal.close();
  };

  render() {
    const {uploadedFiles} = this.state;

    return (
      <Modal
        buttonLabel="Upload"
        label="Upload Gift Card Image"
        buttonOnClick={this.handleSubmit}
        ref={(e) => (this.modal = e)}
      >
        <form>
          <ImageFormDropZone
            label="Logo"
            name="icon"
            endpoint={"shop/card/image"}
            multiple={true}
            onChangeSoft={(file) => {
              this.setState({
                uploadedFiles: [file.fileDetails, ...this.state.uploadedFiles],
              });
            }}
            value={uploadedFiles}
          />
        </form>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(GiftCardUploadImagesModal);

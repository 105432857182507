import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import Papa from "papaparse";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import moment from "moment/moment";

class PayrollImportFirstTab extends Component {
  state = {
    locationId: null,
    checkTemplateData: null,
    exportData: null,
    onboardingCompanies: [],
    groupUniqueId: null,
    companyName: null,
    pastProvider: null,
  };

  async componentDidMount() {
    let companies = await request("admin/payroll/groups", "GET");

    let onboardingCompanies = companies.filter((company) => {
      let daysAway = moment(company.START_DATE).diff(moment(), "days");

      return -1 < daysAway;
    });

    onboardingCompanies = onboardingCompanies.map((company) => ({
      label: company.NAME,
      value: company.UNIQUE_ID,
    }));

    this.setState({onboardingCompanies});
  }

  handleClick = async () => {
    await this.preProcess();

    let {checkTemplateData, exportData, groupUniqueId, companyName} = this.state;

    this.props.updateImport({
      checkTemplateData,
      exportData,
      groupUniqueId,
      companyName,
    });

    return true;
  };

  preProcessToast = () => {
    let {exportData} = this.state;

    const quarters = 3;

    const employeeDict = exportData.reduce((dict, _row) => {
      const name = _row[0];
      const ssn = _row[5];
      const rowTitle = _row[13];
      const rowType = _row[12];
      const base = 15;

      if (name === "name") {
        return dict;
      }

      if (!dict[ssn]) {
        dict[ssn] = {
          "Employee Name": `${_row[3]} ${_row[2]}`,
          "Workplace Address": `${_row[8]}`,
          quarters: [...Array.from({length: quarters}, () => ({}))],
        };
      }

      for (let i = 0; i < quarters; i++) {
        dict[ssn].quarters[i][rowTitle] = _row[base + i];

        if (["REGULAR", "OVERTIME"].includes(rowTitle)) {
          dict[ssn].quarters[i][`${rowTitle} hours`] = _row[19 + i];
        }
      }

      return dict;
    }, {});

    const paychecks = Object.values(employeeDict).reduce((arr, _emp) => {
      const copy = {..._emp};

      delete copy["quarters"];

      return [
        ...arr,
        ..._emp.quarters.map((_quarter, index) => ({
          ...copy,
          "Period Start": moment()
            .startOf("year")
            .quarter(index + 1)
            .startOf("quarter")
            .format("YYYY-MM-DD"),
          "Period End": moment()
            .startOf("year")
            .quarter(index + 1)
            .endOf("quarter")
            .format("YYYY-MM-DD"),
          Payday: moment()
            .startOf("year")
            .quarter(index + 1)
            .endOf("quarter")
            .format("YYYY-MM-DD"),
          ..._quarter,
        })),
      ];
    }, []);

    const columns = Array.from(
      new Set(
        paychecks.reduce((arr, _row) => {
          return [...arr, ...Object.keys(_row)];
        }, [])
      )
    );

    const columnDict = columns.reduce((dict, _col, currentIndex) => {
      dict[_col] = currentIndex;

      return dict;
    }, {});

    let s = [
      columns.reduce((dict, _col, index) => {
        dict[index] = _col;
        return dict;
      }, {}),
      ...paychecks.reduce((arr, _paycheck) => {
        const entry = Object.keys(_paycheck).reduce((dict, field) => {
          dict[columnDict[field]] = _paycheck[field];

          return dict;
        }, {});

        return [...arr, entry];
      }, []),
    ];
    console.log(s);
    return s;
  };

  async preProcess() {
    let {pastProvider} = this.props.payroll.importData;

    if (pastProvider === "gusto-summary") {
      this.props.updateImport({
        firstPaycheckStartRow: 0,
        paycheckIncrementer: 1,
      });
    } else if (pastProvider === "gusto-paychecks") {
      this.props.updateImport({
        firstPaycheckStartRow: 2,
        paycheckIncrementer: 1,
      });
    } else if (pastProvider === "toast") {
      this.props.updateImport({
        firstPaycheckStartRow: 0,
        paycheckIncrementer: 1,
      });
      await this.setState({exportData: this.preProcessToast()});
    }
  }

  handleCheckTemplate = (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    Papa.parse(file, {
      header: true,
      complete: (checkTemplateData) => {
        this.setState({checkTemplateData});
      },
    });
  };

  handleExport = (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    Papa.parse(file, {
      header: true,
      complete: ({data}) => {
        this.setState({exportData: data});
      },
    });
  };

  render() {
    let {onboardingCompanies, groupUniqueId} = this.state;
    let pastProvider = this.props.payroll.importData?.pastProvider;

    return (
      <div style={{height: 600}} className="py-20 flex flex-col justify-center items-center space-y-5">
        <div className="flex flex-row space-x-2">
          <FormSelect
            value={groupUniqueId}
            className={"w-48"}
            label={"Company"}
            name={"groupUniqueId"}
            data={onboardingCompanies}
            onChange={(groupUniqueId) => {
              let companyName = onboardingCompanies.find((comp) => comp.value === groupUniqueId).label;

              this.setState({groupUniqueId, companyName});
            }}
          />

          <FormSelect
            value={pastProvider}
            label={"Past Provider"}
            className={"w-48"}
            data={[
              {label: "Square", value: "square"},
              {label: "Gusto - Summary", value: "gusto-summary"},
              {label: "Gusto - Paychecks", value: "gusto-paychecks"},
              {label: "ADP", value: "adp"},
              {label: "Toast", value: "toast"},
            ]}
            onChange={(pastProvider) => this.props.updateImport({pastProvider})}
          />
        </div>

        <div className={"space-y-2"}>
          <div className={"flex flex-row justify-between"}>
            <div className={"mr-1"}>Check Template</div>

            <input label="Check Template" type="file" accept=".csv" onChange={this.handleCheckTemplate} />
          </div>

          <div className={"flex flex-row justify-between"}>
            <div>Raw Export</div>
            <input type="file" accept=".csv" onChange={this.handleExport} />
          </div>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(PayrollImportFirstTab));

import React, {Component} from "react";
import moment from "moment";
import {getShortURL, request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import io from "socket.io-client";
import {Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {numberWithCommas} from "../../../utils/util";

class SalesTrackerPage extends Component {
  state = {
    orders: [],
    totalItems: 0,
    totalTickets: 0,
    totalProcessed: 0,
    items: 0,
    tickets: 0,
    processed: 0,
    top: null,
    locations: {},
    average: [],
  };

  componentDidMount() {
    this.loadData();
    this.setupSocket();
  }

  setupSocket() {
    let socket = io.connect(getShortURL(), {
      query: "b64=1",
    });

    socket.on("connect", () => {
      socket.emit("admin", {
        TOKEN: window.localStorage["TOKEN"],
      });
    });

    socket.on("TICKET_PROCESSED", (data) => {
      let {
        orders,
        items,
        processed,
        tickets,
        totalTickets,
        totalItems,
        totalProcessed,
        top,
        locations,
        average,
      } = this.state;

      if (!top || top.PAYMENT_INTENT.TOTAL < data.PAYMENT_INTENT.TOTAL) {
        top = data;
      }

      locations[data.LOCATION_NAME] = parseInt(data.TICKET_NUMBER);

      average.push(data.PAYMENT_INTENT.TOTAL);

      this.setState({
        top,
        average,
        locations,
        orders: [data, ...orders],
        tickets: tickets + 1,
        items: items + data.ITEMS.length,
        processed: processed + data.PAYMENT_INTENT.TOTAL,
        totalTickets: totalTickets + 1,
        totalItems: totalItems + data.ITEMS.length,
        totalProcessed: totalProcessed + data.PAYMENT_INTENT.TOTAL,
      });

      console.log("Ticket gained", data);
    });
  }

  async loadData() {
    request("admin/dashboard/homepage", "GET", {
      DATE_START: moment().startOf("day").valueOf(),
      DATE_END: null,
      TIMEZONE: moment.tz.guess(),
    }).then(({ORDER_COUNT, ITEM_COUNT, PROCESSED}) => {
      const {items, tickets, processed} = this.state;

      this.setState({
        items: items + ITEM_COUNT,
        tickets: tickets + ORDER_COUNT,
        processed: processed + PROCESSED.TOTAL,
      });
    });

    request("admin/dashboard/homepage", "GET", {
      DATE_START: moment().startOf("year").subtract(5, "years").valueOf(),
      DATE_END: null,
      TIMEZONE: moment.tz.guess(),
    }).then(({ORDER_COUNT, ITEM_COUNT, PROCESSED}) => {
      const {totalItems, totalTickets, totalProcessed} = this.state;

      this.setState({
        totalItems: totalItems + ITEM_COUNT,
        totalTickets: totalTickets + ORDER_COUNT,
        totalProcessed: totalProcessed + PROCESSED.TOTAL,
      });
    });
  }

  render() {
    const {
      totalProcessed,
      totalTickets,
      totalItems,
      tickets,
      processed,
      orders,
      items,
      top,
      average,
      locations,
    } = this.state;

    let topAmount = 0;
    let topLocation = "None yet";
    for (let key of Object.keys(locations)) {
      if (locations[key] > topAmount) {
        topAmount = locations[key];
        topLocation = key;
      }
    }

    return (
      <div className="p-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Processed all time</h3>

          <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Dollars Processed</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">${toDollars(totalProcessed)}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Tickets Processed</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{numberWithCommas(totalTickets)}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Items Sold</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{numberWithCommas(totalItems)}</dd>
            </div>
          </dl>
        </div>

        <div className="mt-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Processed today</h3>

          <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Dollars Processed</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${toDollars(processed)}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Tickets Processed</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{numberWithCommas(tickets)}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Items Sold</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{numberWithCommas(items)}</dd>
            </div>
          </dl>

          <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Top Ticket</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {top
                  ? top.NAME + " (" + top.LOCATION_NAME + ") - $" + toDollars(top?.PAYMENT_INTENT?.TOTAL)
                  : "$0.00"}
              </dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Average Ticket</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {average.length > 0
                  ? "$" +
                    toDollars(Math.round(average.reduce((accum, item) => accum + item, 0) / average.length))
                  : "0.00"}
              </dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Top Location</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {topLocation} - {topAmount} tickets
              </dd>
            </div>
          </dl>
        </div>

        <Card>
          <Table
            data={orders.slice(0, 10)}
            columns={[
              {
                width: 1,
                value: "NAME",
                label: "Name",
                format: (value, row) => (
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={getObjectImage(row, "LOCATION_LOGO", "appicon.png")}
                        alt=""
                      />
                    </div>

                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {row.NAME} ({row.TICKET_NUMBER})
                      </div>

                      <div className="text-sm text-gray-500">{row.LOCATION_NAME}</div>
                    </div>
                  </div>
                ),
              },
              {
                value: "ITEMS",
                label: "Items",
                format: (value, row) => {
                  return value
                    .map(
                      (item) =>
                        (item.QUANTITY > 1 ? item.QUANTITY + "x " : "") +
                        item.NAME +
                        " ($" +
                        toDollars(item.TOTAL) +
                        ")"
                    )
                    .join(", ");
                },
              },
              {
                width: 1,
                value: "PLATFORM",
                label: "Platform",
              },
              {
                width: 1,
                value: "PAYMENT",
                label: "Payment Type",
                format: (value, row) => {
                  return row.PAYMENT_INTENT.PAYMENTS.map((item) => item.TYPE).join(", ");
                },
              },
              {
                width: 1,
                value: "TOTAL",
                label: "Total",
                format: (value, row) => {
                  return "$" + toDollars(row.PAYMENT_INTENT.TOTAL);
                },
              },
              {
                width: 1,
                value: "DATE_CREATED",
                label: "Created",
                format: (value, row) => {
                  return moment(value).format("hh:mmA");
                },
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default SalesTrackerPage;

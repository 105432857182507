import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Tab, Loading, PageHeadings, Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import Banner from "../../../components/banner";
import DiscountDetailsModal from "../../../modals/sales/discounts/discount-details-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class DiscountsPage extends Component {
  state = {
    isLoading: true,
    discounts: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let discounts = await request("partner/discounts", "GET");

    discounts = discounts.filter((item) => item.ARCHIVED !== 1);

    this.setState({isLoading: false, discounts});
  }

  render() {
    const {isLoading, discounts} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const ticketDiscounts = discounts.filter((item) => {
      return item.PRODUCT === 0;
    });

    const productDiscounts = discounts.filter((item) => {
      return item.PRODUCT === 1;
    });

    return (
      <div>
        <DiscountDetailsModal
          ref={(e) => (this.discountModal = e)}
          addState={(serverDiscount) => this.props.router.navigate("/discount/" + serverDiscount.UNIQUE_ID)}
          updateState={() => {
            this.syncState();
          }}
          onDelete={() => {
            this.syncState();
          }}
        />

        <PageHeadings
          label="Discounts"
          description="Create custom discounts available on the register"
          button={{
            onChange: ({id}) => this.discountModal.open(null, parseInt(id)),
            theme: "primary",
            label: "Create",
            icon: "plus",
            type: "list",
            items: [
              {
                id: "0",
                label: "Ticket Discount",
                description: "Create a custom discount to be applied to full tickets.",
              },
              {
                id: "1",
                label: "Product Discount",
                description: "Create a custom discount to be applied to individual products.",
              },
            ],
          }}
        />

        <Banner
          className="mt-2"
          label="Coupon codes & Promotions"
          description="If you are looking for coupons and discounts, click the button to the right!"
          detailsLabel="Go to deals"
          detailsOnClick={() => this.props.router.navigate("/deals")}
        />

        <Tab
          data={[
            {
              id: "ticket",
              label: "Ticket Discounts",
              badge: "" + ticketDiscounts.length,
            },
            {
              id: "product",
              label: "Product Discounts",
              badge: "" + productDiscounts.length,
            },
          ]}
        >
          {(id) => {
            let description = "";
            let label = "";
            let tooltip = {};

            if (id === "ticket") {
              label = "Ticket Discounts";
              description = "Discounts that can be applied at the ticket level";
              tooltip.label = "Ticket Discounts";
              tooltip.data =
                "These will be available on the Register Screen on the Fees and Discounts page on the POS. They will " +
                "be applied as a discount on the entire ticket.";
            } else if (id === "product") {
              label = "Product Discounts";
              description = "Discounts that can be applied at the product level";
              tooltip.label = "Product Discounts";
              tooltip.data =
                "To make these available for use on a product, navigate to the product's page on the Partner" +
                " Dashboard and add the discount to the product via the Discounts tab. Then the discount will be" +
                " available on the product's page on the POS. When selected on the POS, the discount will be " +
                "applied as a discount for that item, not the entire ticket.";
            }

            return (
              <Card label={label} description={description} tooltip={tooltip}>
                <Table
                  pagination
                  ref={(e) => (this.tableRef = e)}
                  data={discounts.filter((item) => {
                    if (id === "ticket") {
                      return item.PRODUCT === 0;
                    } else if (id === "product") {
                      return item.PRODUCT === 1;
                    }
                  })}
                  actionButtons={[
                    {
                      label: "View",
                      onClick: (row) => this.props.router.navigate("/discount/" + row.UNIQUE_ID),
                    },
                  ]}
                  columns={[
                    {
                      value: "NAME",
                      label: "Name",
                    },
                    {
                      value: "CONTENT",
                      label: "Discount Amount",
                      format: (_, row) => {
                        return row.TYPE === 0 ? `$${toDollars(row.CONTENT)}` : `${row.CONTENT}%`;
                      },
                    },
                  ]}
                />
              </Card>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(DiscountsPage));

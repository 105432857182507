const defaultState = {
  header: true,

  isFromAdmin: false,
};

const UPDATE_LAYOUT_HEADER = "UPDATE_LAYOUT_HEADEr";
const UPDATE_LAYOUT_FROM_ADMIN = "UPDATE_LAYOUT_FROM_ADMIN";

export function updateLayoutHeader(payload) {
  return {type: UPDATE_LAYOUT_HEADER, payload};
}

export function updateLayoutFromAdmin(payload) {
  return {type: UPDATE_LAYOUT_FROM_ADMIN, payload};
}

export const layoutReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_LAYOUT_HEADER:
      return {...state, header: payload};
    case UPDATE_LAYOUT_FROM_ADMIN:
      return {...state, isFromAdmin: payload};
  }
};

import React, {Component} from "react";
import {Modal, FormInput, Card, FormSelect, Table, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {Formik} from "formik";
import LoadingSpinner from "../../components/loading-spinner";
import * as Yup from "yup";
import {showErrorNotification, showSuccessNotification} from "../../utils/notification-helper";
import {toDecimalPlaces} from "../../utils/decimal-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class MultiTemplatesModal extends Component {
  state = {
    templates: [],
    selectedLocation: null,
    startWeek: null,
    isSaving: false,
    isLoading: false,
  };

  async open(startWeek) {
    this.syncState();

    this.setState({startWeek}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  close() {
    this.modal.close();
  }

  createTemplate = async ({location, name}) => {
    this.setState({isSaving: true});
    let {startWeek} = this.state;
    let allTemplates = [];

    try {
      allTemplates = await request(`scheduling/templates/${startWeek}`, "POST", {
        NAME: name,
        LOCATION_ID: location,
      });
    } catch (err) {
      return showErrorNotification(
        "Error Creating Template",
        "Encountered an error trying to create this template."
      );
    }

    this.setState({templates: allTemplates, isSaving: false});
  };

  async syncState(loading = true) {
    const {locationIdArr = []} = this.props;

    if (locationIdArr.length > 0) {
      this.setState({selectedLocation: locationIdArr[0]});
    }

    if (!!loading) {
      this.setState({isLoading: true});
    }

    let templates = [];

    try {
      templates = await request("scheduling/locations/templates", "POST", {
        LOCATION_ID_ARR: locationIdArr,
      });
    } catch (err) {
      showErrorNotification("Error Fetching Templates", "Encountered an issue fetching schedule templates.");

      return this.modal.close();
    }

    this.setState({isLoading: false, templates});
  }

  renderLoading() {
    return <LoadingSpinner />;
  }

  async applyTemplate(template) {
    let {startWeek} = this.state;
    const {ID} = template;

    await request(`scheduling/locations/templates/${ID}/${startWeek}`, "POST", {});

    if (this.props.isToggledLocation && this.props.refresh) {
      this.props.refresh(false);
    }

    showSuccessNotification("Template Applied", `${template.NAME} successfully applied to schedule.`);
  }

  async deleteTemplate(template) {
    let {templates} = this.state;
    const {ID} = template;

    await request(`scheduling/templates/${ID}`, "DELETE", {});

    const toDeleteIndex = templates.findIndex((_template) => _template.ID === ID);

    if (toDeleteIndex !== -1) {
      templates.splice(toDeleteIndex, 1);
    }

    this.setState({templates});

    showSuccessNotification(
      "Template Deleted",
      `${template.NAME} successfully deleted from saved shift templates.`
    );
  }

  renderTemplateTable() {
    let {templates, isLoading, selectedLocation} = this.state;

    templates = templates.filter((_template) => _template.LOCATION_ID === selectedLocation);

    if (!!isLoading) {
      return (
        <div className={"my-4"}>
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <Table
        white
        verticalLines
        className="mt-4"
        data={templates || null}
        columns={TEMPLATE_TABLE_COLUMNS}
        ref={(e) => (this.templateTable = e)}
        //TODO: make action items more responsive when apply and delete
        actionButtons={[
          {
            icon: "calendar-check",
            onClick: (row) => this.applyTemplate(row),
          },
          {
            icon: "trash",
            onClick: (row) => this.deleteTemplate(row),
          },
        ]}
      />
    );
  }

  render() {
    let {templates, startWeek, isSaving, isLoading} = this.state;
    const {locationIdArr = [], locations = []} = this.props;

    let locationData = [];

    if (locationIdArr.length > 0) {
      const locationIdSet = new Set(locationIdArr);

      locationData = locations
        .filter((_location) => locationIdSet.has(_location.ID))
        .map(({ID, NAME}) => ({value: ID, label: NAME}));
    } else {
      locationData = locations.map((_location) => ({
        value: _location.ID,
        label: _location.NAME,
      }));
    }

    return (
      <Modal
        xlarge
        label="Scheduling Templates"
        closeLabel="Close"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          validationSchema={Yup.object().shape({
            location: Yup.number()
              .typeError("Location is a required field")
              .required("Location is a required field"),
            name: Yup.string().required("Name is a required field"),
          })}
          onSubmit={this.createTemplate}
          initialValues={{
            location: locationIdArr && locationIdArr.length > 0 ? locationIdArr[0] : null,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            let {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form className="" onSubmit={handleSubmit}>
                <FormSelect
                  name={"location"}
                  data={locationData}
                  label="Location"
                  onChangeSoft={(location) => {
                    const {value: locationId} = location;

                    setFieldValue("location", locationId);

                    this.setState({selectedLocation: locationId});
                  }}
                  options={formikOptions}
                />

                <FormInput
                  className="mt-1"
                  name={"name"}
                  label="Template Name"
                  placeholder="Name"
                  options={formikOptions}
                />

                <div className={"flex justify-end mt-4"}>
                  <button
                    type="submit"
                    // onClick={() => this.formikRef.submitForm()}
                    className="justify-center text-center inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                  >
                    {!isSaving && <div>Create</div>}

                    {isSaving && <LoadingSpinner color="white" size={20} />}
                  </button>
                </div>

                {this.renderTemplateTable()}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export const TEMPLATE_TABLE_COLUMNS = [
  {value: "LOCATION_NAME", label: "Location"},
  {
    value: "NAME",
    label: "Name",
  },
  {
    value: "TOTAL_HOURS",
    label: "Total Hours",
    format: (val) => toDecimalPlaces(val, 0),
  },
  {
    value: "TOTAL_COST",
    label: "Total Cost",
    format: (val) => "$" + toDollars(val),
  },
];

export default MultiTemplatesModal;

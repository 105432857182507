import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class AutoGratuitySettingsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {allowTipOnAutoGratuity} = values;

    let payload = {
      ALLOW_TIP_ON_AUTO_GRATUITY: allowTipOnAutoGratuity,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {ALLOW_TIP_ON_AUTO_GRATUITY} = this.props.shop.settings;

    return (
      <Modal
        label="Auto Gratuity Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            allowTipOnAutoGratuity: ALLOW_TIP_ON_AUTO_GRATUITY,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="allowTipOnAutoGratuity"
                  label="Allow Tipping On Auto-Gratuity"
                  tooltip={
                    "If enabled, you will be able to select a tip from the gratuity step regardless of whether auto-gratuity has been applied."
                  }
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(AutoGratuitySettingsModal);

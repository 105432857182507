import React, {Component} from "react";
import {FormBoolean, FormInput, FormTextArea, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import FormRow from "../../../components/form-row";
import CustomerModal from "../../../modals/operations/invoices/contact-modal";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import ContactDropdown from "../../../dropdowns/operations/contact-dropdown";
import * as Yup from "yup";
import ContactPaymentModal from "../../../modals/operations/invoices/contact-payment-modal";
import {fetchPaymentMethodLabel} from "../../../utils/sales-helper";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import {COLLECTION_METHODS} from "../../../utils/constants";
import {Col} from "../../../pages/operations/supply-chain/shared";

class InvoiceForm extends Component {
  state = {orderTypes: []};

  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData() {
    const {
      contact,
      contactObj,
      status,
      contactName,
      number,
      due,
      service,
      notes,
      tipEnabled,
      source,
      method,
      sendToKds,
    } = this.formikRef.values;

    return {
      invoice: {
        STATUS: status,
        INVOICE_NUMBER: number,

        CONTACT: contactObj,
        CONTACT_NAME: contactName,
        CONTACT_ID: contact,

        CUSTOMER_ID: contactObj.CUSTOMER_ID,
        COLLECTION_METHOD: method || "SEND_INVOICE",
        SOURCE_ID: source,

        DATE_SERVICE: service ? parseInt(service) : null,
        DATE_DUE: due ? parseInt(due) : null,
        REQUEST_TIP: tipEnabled,
        NOTES: notes,
        SEND_TO_KDS: parseInt(sendToKds),
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  fetchButtonHints(values) {
    const {contactObj} = values;

    if (contactObj) {
      return {
        label: "Add Payment Method",
        onClick: () => this.paymentModal.open(values.contactObj),
      };
    }
  }

  renderDueField(formikOptions) {
    const {isRecurring} = this.props;

    if (!isRecurring) {
      return (
        <FormRow>
          <FormDateTimeSelect
            name="due"
            label="Due Date"
            options={formikOptions}
            onChangeSoft={(val) => {
              this.props.onDueChange && this.props.onDueChange(val);
            }}
            hideTime
            flex
          />
        </FormRow>
      );
    }
  }

  renderSource(options) {
    const {values} = options;

    if (values.method === COLLECTION_METHODS.AUTO_CHARGE) {
      return (
        <FormSelect
          flex
          buttonHint={this.fetchButtonHints(values)}
          name="source"
          label="Payment Method"
          options={options}
          data={
            values.contactObj?.PAYMENT_METHODS?.map((item) => {
              return {label: fetchPaymentMethodLabel(item), value: item.id};
            }) ?? []
          }
        />
      );
    } else {
      if (values.discount === "flat") {
        return <FormInput label="Flat Amount" name="flat" options={options} />;
      }
      return <FormInput label="Discount Percentage" name="percentage" options={options} />;
    }
  }

  fetchInitialValues(invoice) {
    let {INVOICE_DEFAULT_NOTES} = this.props.shop.settings;

    const initialNotes = invoice?.TICKET?.NOTES ?? invoice.NOTES ?? INVOICE_DEFAULT_NOTES;

    return {
      number: invoice?.INVOICE_NUMBER ?? null,
      contact: invoice?.CONTACT_ID ?? null,
      contactName: invoice?.CONTACT_NAME ?? null,
      service: invoice?.DATE_SERVICE ?? Date.now(),
      status: invoice?.STATUS ?? "DRAFT",
      due: invoice?.DATE_DUE ?? null,
      notes: initialNotes,
      contactObj: invoice?.CONTACT ?? null,
      tipEnabled: invoice?.REQUEST_TIP ?? false,
      sendToKds: invoice?.SEND_TO_KDS ?? "0",
      method: invoice?.COLLECTION_METHOD ?? "SEND_INVOICE",
      source: invoice?.SOURCE_ID ?? null,
    };
  }

  renderContactDropdown(formikOptions, errors, setFieldValue) {
    const {isRecurring} = this.props;
    return (
      <FormRow>
        <Col className="flex-1">
          <ContactDropdown
            flex
            onChangeSoft={async ({name, contact}) => {
              contact.PAYMENT_METHODS = [];

              setFieldValue("contactName", name);
              setFieldValue("contactObj", contact);

              const serverContact = await request("contacts/" + contact.UNIQUE_ID, "GET", null);

              setFieldValue("contactObj", serverContact);
            }}
            nameField="contactName"
            options={formikOptions}
            label="Contact"
            name="contact"
            createLabel="Create Contact"
            ref={(e) => (this.patronDropdown = e)}
            onCreate={(query) => this.customerModal.open(null, query)}
          />

          {errors.contactName && <div className={"text-sm text-red-700"}>Please select a contact.</div>}
        </Col>

        {!isRecurring && <FormInput options={formikOptions} label="Invoice Number" name="number" flex />}
      </FormRow>
    );
  }

  render() {
    const {handleSubmit, isRecurring, invoice} = this.props;

    const validationSchema = Yup.lazy((values) => {
      const formSchema = {
        contactName: Yup.string().nullable().required("Please select a contact"),
      };

      if (values.method === COLLECTION_METHODS.AUTO_CHARGE) {
        formSchema.source = Yup.string()
          .typeError("Payment is required with automatic collection")
          .required("Payment is required with automatic collection");
      }

      return Yup.object(formSchema);
    });

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={this.fetchInitialValues(invoice)}
        validationSchema={validationSchema}
      >
        {(formikOptions) => {
          const {values, handleSubmit, setFieldValue, errors} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <ContactPaymentModal
                ref={(e) => (this.paymentModal = e)}
                addState={(card) => {
                  const {contact} = values;
                  contact.PAYMENT_METHODS.push(card);
                  setFieldValue("contactObj", contact);
                }}
              />

              <CustomerModal
                addState={async (contact) => {
                  contact.PAYMENT_METHODS = [];

                  setFieldValue("contactObj", contact);
                  setFieldValue("contact", contact.ID);

                  const serverContact = await request("contacts/" + contact.UNIQUE_ID, "GET", null);

                  setFieldValue("contactObj", serverContact);

                  this.patronDropdown.syncData(contact.FIRST_NAME + " " + contact.LAST_NAME);
                }}
                ref={(e) => (this.customerModal = e)}
              />

              {this.renderContactDropdown(formikOptions, errors, setFieldValue)}

              <FormRow>
                <FormSelect
                  flex
                  name="method"
                  options={formikOptions}
                  label="Collection Method"
                  tooltip="Select how you would like to collect payment for this invoice. In the future, automated billing will be available for customers with a saved payment method."
                  data={[{label: "Send Invoice", value: "SEND_INVOICE"}]}
                />

                {this.renderSource(formikOptions)}
              </FormRow>

              {this.renderDueField(formikOptions)}

              <FormTextArea
                options={formikOptions}
                tooltip="These notes will display on invoice emails, pdfs, and receipts."
                label="Invoice Notes"
                hint="Optional"
                name="notes"
              />

              <FormBoolean options={formikOptions} label={"Enable Tips"} name={"tipEnabled"} />

              <div className={"flex flex-row justify-start space-x-4 items-end"}>
                <FormDateTimeSelect
                  options={formikOptions}
                  label="Service Date"
                  name="service"
                  tooltip={{
                    label: "Service Date",
                    data: "Select when items in this invoice will show up on reports as Sales.",
                  }}
                />

                <FormBoolean
                  options={formikOptions}
                  name="sendToKds"
                  tooltip="If enabled, the invoice line items will be sent to the KDS at this location at the time of service."
                  label={"Send to Ticket Screen at Service"}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

InvoiceForm.propTypes = {
  isRecurring: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

export default setupReduxConnection(["shop"])(InvoiceForm);

import {request} from "../../request";

export const BrandedAppRequests = {
  fetch: (uniqueId) => {
    return request(`branded/app/${uniqueId}`);
  },
  create: (companyId, body) => {
    return request(`branded/app/${companyId}`, "POST", body);
  },
  upsertBrandedApp: (uniqueId, body) => {
    return request(`branded/app/${uniqueId}`, "PATCH", body);
  },
  build: (uniqueId) => {
    return request(`branded/app/${uniqueId}/build`, "POST");
  },
  fetchAndroidDownloadList: (uniqueId) => {
    return request(`branded/app/${uniqueId}/android`);
  },
  fetchBrandedAppCards: (uniqueId) => {
    return request(`branded/app/${uniqueId}/cards/all`);
  },
  createBrandedAppCard: (uniqueId, body) => {
    return request(`branded/app/${uniqueId}/cards`, "POST", body);
  },
  updateBrandedAppCard: (uniqueId, cardId, body) => {
    return request(`branded/app/${uniqueId}/cards/${cardId}`, "PATCH", body);
  },
  deleteBrandedAppCard: (uniqueId, cardId) => {
    return request(`branded/app/${uniqueId}/cards/${cardId}`, "DELETE");
  },
};

import React from "react";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const converCardToBrandedCard = (card) => {
  return {
    logo: card.LOGO_URL,
    link: card.LINK,
    text: {
      label: card.TEXT_LABEL,
      button: card.TEXT_BUTTON,
    },
    colors: {
      background: card.COLORS_BACKGROUND,
      text: card.COLORS_TEXT,
    },
  };
};

export function BrandedCardPreview({card}) {
  const item = converCardToBrandedCard(card);

  return (
    <div
      style={{
        backgroundColor: item.colors.background,
      }}
      className="flex items-center justify-between rounded-lg p-4 overflow-hidden h-40"
    >
      <div className="flex-1 pr-4">
        {/* Main label */}
        <p
          style={{
            color: item.colors.text,
          }}
          className="text-lg font-semibold leading-6 line-clamp-4 "
        >
          {item.text.label}
        </p>

        {/* Button row */}
        <div
          style={{
            backgroundColor: item.colors.text,
          }}
          className="flex items-center rounded-full mt-3 py-1 px-3 cursor-pointer"
          onClick={() => window.open(item.link, "_blank")}
        >
          <p
            style={{
              color: item.colors.background,
            }}
            className="text-sm font-semibold mr-2"
          >
            {item.text.button}
          </p>
          <FontAwesomeIcon
            icon={["fas", "arrow-right"]}
            style={{
              color: item.colors.background,
            }}
            className="h-4 w-4"
          />
        </div>
      </div>

      {/* Image */}
      <img src={getObjectImage(item, "logo", "appicon.png")} alt="logo" className="w-20 rounded-lg" />
    </div>
  );
}

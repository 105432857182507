import React, {Component} from "react";
import {FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";

class PolicyTypeForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({method, amount, earned, worked, earnMethod}) {
    return {
      method: {
        METHOD: method,
        AMOUNT: method === "FIXED" ? amount : earned / worked,
        EARN_METHOD: method === "FIXED" ? earnMethod : null,
      },
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  renderMethodContent(formikOptions) {
    const {values} = formikOptions;

    if (values.method === "FIXED") {
      return (
        <>
          <FormInput
            options={formikOptions}
            tooltip="The number of hours allotted per earning cycle."
            label="Yearly Allotted Hours"
            name="amount"
          />

          <FormSelect
            options={formikOptions}
            label="Earning Cycle"
            name="earnMethod"
            tooltip={{
              data: [
                {
                  label: "Starting Today",
                  data: "The earning cycle will be annual starting today.",
                },
                {
                  label: "Percent",
                  data: "Percent earning method means 25% is paid every quarter.",
                },
                {
                  label: "Every Calendar Year",
                  data: "The earning cycle will be annual starting Jan 1 each year.",
                },
              ],
            }}
            data={[
              {label: "Starting Today", value: "YEARLY"},
              {label: "Percent Every Quarter", value: "PERCENT"},
              {label: "Every Calendar Year", value: "NEW_YEAR"},
            ]}
          />
        </>
      );
    }

    if (values.method === "HOURLY") {
      return (
        <>
          <FormRow>
            <FormInput options={formikOptions} label="Hours Worked" name="worked" flex />

            <FormInput options={formikOptions} label="Hours Earned" name="earned" flex />
          </FormRow>
        </>
      );
    }
  }

  render() {
    const {handleSubmit, method} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Please enter a number value")
            .nullable()
            .test("Amount Not Valid", "Please enter an amount divisible by four", (value, ctx) => {
              if (ctx.parent.method === "FIXED" && ctx.parent.earnMethod === "PERCENT") {
                return !!parseInt(value) && parseInt(value) % 4 === 0;
              }

              return true;
            }),
        })}
        initialValues={{
          method: method?.METHOD ?? "HOURLY",
          amount: method?.AMOUNT ?? "1",
          worked: method?.METHOD === "HOURLY" ? 1 / method?.AMOUNT : "0",
          earned: method?.METHOD === "HOURLY" ? 1 : "0",
          earnMethod: method?.EARN_METHOD ?? "YEARLY",
        }}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <FormSelect
                options={formikOptions}
                label="Accrual Method"
                name="method"
                data={[
                  {label: "Fixed Rate", value: "FIXED"},
                  {label: "Hourly", value: "HOURLY"},
                ]}
              />

              {this.renderMethodContent(formikOptions)}
            </form>
          );
        }}
      </Formik>
    );
  }
}

PolicyTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  method: PropTypes.object,
};

export default PolicyTypeForm;

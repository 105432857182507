import {FormBoolean, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {PlaidLink} from "react-plaid-link";

class PlaidUpdateModal extends Component {
  state = {token: null};
  open = (bank) => {
    request(`accounting/link/${parseInt(bank.PROVIDER_OBJECT_ID)}`, "POST", {
      REDIRECT: "https://dripos.ngrok.io/accounting/plaid",
    })
      .then(({link_token}) => {
        console.log(link_token);
        this.setState({token: link_token});
      })
      .catch((e) => console.log(e));

    this.modal.open();
  };

  render() {
    const {token} = this.state;

    return (
      <Modal
        label={"Select Update Account"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <PlaidLink
          token={token}
          style={{backgroundColor: "#6365f1", border: "none", color: "white"}}
          onSuccess={() => this.modal.close()}
          onExit={this.onExit}
        >
          Update
        </PlaidLink>
      </Modal>
    );
  }
}

export default setupReduxConnection(["accounting"], null, {forwardRef: true})(withRouter(PlaidUpdateModal));

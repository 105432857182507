import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";

class LabelCategoryModal extends Component {
  state = {unit: null};

  open(unit = null) {
    this.setState({unit}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createLabelCategory({name}) {
    let serverCategory = await request("labels/categories", "POST", {
      NAME: name,
    });

    this.props.addState(serverCategory);
    this.modal.close();
  }

  async saveLabelCategory({name}) {
    const {unit} = this.state;

    let serverCategory = await request("labels/categories/" + unit.ID, "PATCH", {
      NAME: name,
    });

    this.props.updateState(serverCategory);
    this.modal.close();
  }

  render() {
    const {unit} = this.state;

    return (
      <Modal
        buttonLabel={unit ? "Save" : "Add"}
        label={unit ? "Edit Label Category" : "Save Label Category"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={unit ? this.saveLabelCategory.bind(this) : this.createLabelCategory.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Unit name is required"),
          })}
          initialValues={{
            name: unit?.NAME,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput options={formikOptions} placeholder="Ounces" label="Category Name" name="name" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default LabelCategoryModal;

import React, {Component} from "react";
import {request} from "../utils/request";
import FilterDropdown from "./filter-dropdown";
import {setupReduxConnection} from "../redux";

class ContactFilterDropdown extends Component {
  state = {contacts: []};

  async componentDidMount() {
    const contacts = await request("invoices/contacts", "GET");

    const initialValues = contacts.map((contact) => contact.ID);

    this.setState({contacts, initialValues});
  }

  render() {
    const {COMPANY_ID} = this.props.shop.location;
    const {contacts, initialValues} = this.state;

    return (
      <FilterDropdown
        noMl={true}
        cannotBeEmpty={true}
        initialValues={initialValues}
        enableReinitialize
        initialText={"Select Contact"}
        plural={"Contacts"}
        data={contacts
          .filter((contact) => contact.COMPANY_ID === COMPANY_ID)
          .map((contact) => {
            return {
              name: contact.FIRST_NAME + " " + contact.LAST_NAME,
              value: contact.ID,
            };
          })}
        onChange={this.props.onChange}
      />
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(ContactFilterDropdown);

import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import moment from "moment-timezone";
import FormDateTimeSelect from "../../components/form-date-time-select";
import Modal from "@frostbyte-technologies/frostbyte-tailwind/dist/components/modals/modal";
import {FormSelect, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import ComboBox from "../../components/combobox";
import MultiDaySelect from "../../components/multi-day-select";
import {checkDSTConversion} from "../../utils/time-helper";
import FormFromToTime from "../../components/form-from-to-time";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {showErrorNotification, showSuccessNotification} from "../../utils/notification-helper";
import NotificationForm from "../../forms/scheduling/notification-form";
import {Shifts, ShiftTags} from "../../utils/request-helpers/scheduling/scheduling-requests";
import {EmployeeRequests} from "../../utils/request-helpers/employees/employee-requests";

class ShiftModal extends Component {
  state = {
    open: false,
    initialDate: null,
    initialStart: null,
    initialEnd: null,
    initialEmployeeId: null,
    initialShiftTagId: null,
    initialNotes: "",
    shiftRecord: null, // implies that we are updating an existing record
    assignableEmployees: [],
    shiftTags: [],
    view: null,
  };

  componentDidMount = async () => {
    try {
      const fetchedEmployees = await EmployeeRequests.fetchAllEmployees();

      this.setState({assignableEmployees: fetchedEmployees});
    } catch (error) {
      showErrorNotification("Failed to load employees.", "Please refresh the page.");
    }
  };

  fetchShiftTags = async () => {
    try {
      const fetchedShiftTags = await ShiftTags.fetch();

      const shiftTags = [{NAME: "None", ID: null}, ...fetchedShiftTags];

      this.setState({shiftTags});
    } catch (error) {
      showErrorNotification("Failed to load shift tags.", "Please refresh the page.");
    }
  };

  closeModal = () => {
    this.slide.close();
  };

  openForCreation = async (startOfDayEpoch, employeeId, employeeRoleId, start, end, view = null) => {
    await this.fetchShiftTags();

    this.setState(
      {
        open: true,
        initialDate: startOfDayEpoch,
        initialStart: null,
        initialEnd: null,
        initialEmployeeId: employeeId,
        initialEmployeeRoleId: employeeRoleId,
        initialShiftTagId: null,
        shiftRecord: null,
        initialNotes: "",
        view,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.slide.open();
      }
    );
  };

  openForEdit = async (shiftRecord, view = null) => {
    await this.fetchShiftTags();

    this.setState(
      {
        open: true,
        initialDate: moment(shiftRecord.DATE_START).valueOf(),
        initialStart: moment(shiftRecord.DATE_START).valueOf(),
        initialEnd: moment(shiftRecord.DATE_END).valueOf(),
        initialEmployeeId: shiftRecord.EMPLOYEE_ID,
        initialEmployeeRoleId: shiftRecord.EMPLOYEE_ROLE_ID,
        initialShiftTagId: shiftRecord.SHIFT_TAG_ID,
        initialNotes: shiftRecord.NOTES,
        shiftRecord,
        view,
      },
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.slide.open();
      }
    );
  };

  renderUnavailabilities(employeeId, date) {
    let {unavailability, timeOff} = this.props.scheduling.schedule;

    unavailability = unavailability.reduce((dict, item) => {
      if (!dict[item.ID]) {
        dict[item.ID] = item;
      }
      return dict;
    }, {});

    timeOff = timeOff.reduce((dict, item) => {
      if (!dict[item.ID]) {
        dict[item.ID] = item;
      }
      return dict;
    }, {});

    const employeeUnavailability = Object.values(unavailability).filter(
      (item) =>
        item.TYPE === 1 &&
        item.EMPLOYEE_ID === employeeId &&
        moment(item.DATE_START).startOf("day").valueOf() <= date &&
        moment(item.DATE_END).endOf("day").valueOf() >= date
    );
    const employeeTimeOff = Object.values(timeOff).filter(
      (item) =>
        item.EMPLOYEE_ID === employeeId &&
        moment(item.DATE_START).startOf("day").valueOf() <= date &&
        moment(item.DATE_END).endOf("day").valueOf() >= date
    );

    return (
      <div>
        {employeeUnavailability.map((unavail) => {
          const timeText = unavail.ALL_DAY
            ? "All Day"
            : `${moment(unavail.DATE_START).format("LLL")} to ${moment(unavail.DATE_END).format("LLL")}`;
          return <div className={"mt-2 text-red-400 text-sm italic"}>{`Unavailability: ${timeText}`}</div>;
        })}

        {employeeTimeOff.length > 0 && (
          <div className="mt-2 text-red-400 text-sm italic">Employee has time off on this day</div>
        )}
      </div>
    );
  }

  editPublishedShift = async (payload, notify, email, text) => {
    const notificationSettings = {
      SEND_ALL: notify,
      SEND_EMAIL: email,
      SEND_SMS: text,
    };

    return Shifts.update(payload.ID, {
      notificationSettings: notificationSettings,
      ...payload,
    });
  };

  handleFormSubmission = async (values, initialValues, isPublished) => {
    let {
      date,
      to,
      from,
      time,
      employeeId,
      employeeRoleId,
      shiftTagId,
      notes,
      days,
      location,
      notify,
      text,
      email,
    } = values;

    const {shiftRecord, view} = this.state;
    const {startDay: startIsoDay = 1, isToggledLocation} = this.props;

    to = parseInt(to.split(":")[0] * 60) + parseInt(to.split(":")[1]);
    from = parseInt(from.split(":")[0] * 60) + parseInt(from.split(":")[1]);

    if (to && to < from) {
      to += 1440;
    }

    const {dateStart, dateEnd} = checkDSTConversion(date, from, to);

    const mainPayload = {
      EMPLOYEE_ID: employeeId,
      EMPLOYEE_ROLE_ID: employeeRoleId,
      SHIFT_TAG_ID: shiftTagId,
      DATE_START: dateStart.valueOf(),
      DATE_END: dateEnd.valueOf(),
      NOTES: notes,
      DRAFT: +!isPublished,
    };

    if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
      mainPayload.ACKNOWLEDGED = 0;
    } else {
      showSuccessNotification(
        "Shift Saved",
        "Your changes have been recorded. If you do not immediately see your shift on the schedule, please make sure you are toggled on the correct location."
      );

      return this.closeModal();
    }

    let shiftList = [];
    const weekday = moment(date).isoWeekday();

    if (shiftRecord) {
      mainPayload.ID = shiftRecord.ID;
    }
    let newDate;

    if (days.length > 0) {
      shiftList = days.map((day) => {
        // these two variables allow us to recenter the day around the start day for the purposes of calculating the day
        // offset
        const calcWeekday = weekday < startIsoDay ? weekday + 7 : weekday;
        const calcDay = day < startIsoDay ? day + 7 : day;

        const dayOffset = calcDay - calcWeekday;

        newDate = moment(date).add(dayOffset, "days");
        let {dateStart, dateEnd} = checkDSTConversion(newDate, from, to);

        return {
          EMPLOYEE_ID: employeeId,
          EMPLOYEE_ROLE_ID: employeeRoleId,
          DATE_START: dateStart.valueOf(),
          DATE_END: dateEnd.valueOf(),
          NOTES: notes,
          DRAFT: 1,
          ACKNOWLEDGED: 0,
          SHIFT_TAG_ID: shiftTagId,
        };
      });
    }

    let updatedShifts = [];

    if (isPublished) {
      try {
        const editedShift = await this.editPublishedShift(mainPayload, notify, email, text);

        updatedShifts = [editedShift];
      } catch (error) {
        showErrorNotification(
          "Error Saving Edited Shift",
          "Encountered an error trying to save edited shift, please refresh the scheduler and try again."
        );

        return this.closeModal();
      }
    } else {
      shiftList.push(mainPayload);
    }

    if (shiftList.length > 0) {
      try {
        const draftShifts = await Shifts.create({
          shifts: shiftList,
          LOCATION_ID: location,
        });

        updatedShifts = [...updatedShifts, ...draftShifts];
      } catch (err) {
        showErrorNotification(
          "Error Creating Shift",
          "Encountered an error trying to create shift, please refresh the scheduler and try again."
        );

        return this.closeModal();
      }
    }

    if (isToggledLocation && updatedShifts && this.props.upsertShifts) {
      this.props.upsertShifts(updatedShifts);
    }

    showSuccessNotification(
      "Shift Saved",
      "Your changes have been recorded. If you do not immediately see your shift on the schedule, please make sure you are toggled on the correct location or refresh the schedule."
    );

    this.closeModal();
  };

  handleEventDelete = async () => {
    let toDeleteShift;

    try {
      toDeleteShift = await Shifts.delete(this.state.shiftRecord.ID);
    } catch (err) {
      return showErrorNotification(
        "Delete Shift Error",
        "Encountered an error while trying to delete shift. Please refresh the page and try again."
      );
    }

    if (toDeleteShift) {
      this.props.deleteShift(toDeleteShift.ID);
    }

    showSuccessNotification("Delete Shift Success");

    this.closeModal();
  };

  render() {
    let {startDay: startIsoDay = 1, locations, locationIdArr = []} = this.props;

    let {
      initialDate,
      initialStart,
      initialEnd,
      initialEmployeeId,
      initialEmployeeRoleId,
      initialShiftTagId,
      initialNotes,
      shiftRecord,
      assignableEmployees,
      shiftTags,
    } = this.state;

    const isPublished = shiftRecord?.DRAFT === 0;

    let validationSchema = Yup.object().shape({
      employeeId: Yup.number().typeError("Please select an employee").required("Please select an employee"),
      employeeRoleId: Yup.number().typeError("Please select a role").required("Please select a role"),
      from: Yup.string().typeError("Please set a valid start time").required("Please set a start time"),
      to: Yup.string().typeError("Please set a valid end time").required("Please set an end time"),
    });

    let initialValue = {
      location: shiftRecord ? shiftRecord.LOCATION_ID : locationIdArr.length > 0 ? locationIdArr[0] : null,
      date: initialDate ? moment(initialDate).startOf("day").valueOf() : moment().startOf("day").valueOf(),
      from: initialStart ? moment(initialStart).format("HH:mm") : undefined,
      to: initialEnd ? moment(initialEnd).format("HH:mm") : undefined,
      employeeId: initialEmployeeId,
      employeeRoleId: initialEmployeeRoleId,
      shiftTagId: initialShiftTagId,
      notes: initialNotes,
      days: [],
      notify: 4,
      text: true,
      email: true,
    };

    return (
      <Modal
        large
        buttonLabel={isPublished ? "Save & Republish" : "Save"}
        formikOnClick={() => this.formikRef}
        label={shiftRecord ? "Edit Shift" : "Create Shift"}
        description=""
        deleteLabel={shiftRecord ? "Delete Shift" : undefined}
        deleteOnClick={shiftRecord ? () => this.handleEventDelete() : undefined}
        ref={(e) => (this.slide = e)}
      >
        <Formik
          onSubmit={(values) => this.handleFormSubmission(values, initialValue, isPublished)}
          initialValues={initialValue}
          validationSchema={validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            let {handleSubmit, values, setFieldValue} = formikOptions;
            let {employeeId, employeeRoleId, shiftTagId} = values;

            let employeeRoles = [];
            let color;

            let reduxEmployee = assignableEmployees.find((x) => {
              return x.ID === employeeId;
            });

            if (reduxEmployee) {
              employeeRoles = reduxEmployee.EMPLOYEE_ROLES.map((x) => ({
                label: x.ROLE_NAME,
                value: x.ID,
              }));

              color = reduxEmployee.EMPLOYEE_ROLES.find((x) => x.ID === employeeRoleId)?.COLOR;
            }

            return (
              <form onSubmit={handleSubmit}>
                <ComboBox
                  name="employeeId"
                  className="mt-2"
                  secondaryBlock
                  label="Employee Name"
                  options={formikOptions}
                  onChangeSoft={(e) => {
                    if (employeeId) {
                      let reduxEmployee = assignableEmployees.find((x) => {
                        return x.ID === employeeId;
                      });

                      let role = reduxEmployee?.EMPLOYEE_ROLES.find((x) => x.ID === employeeRoleId);

                      if (role?.ROLE_ID) {
                        let newEmployee = assignableEmployees.find((x) => {
                          return x.ID === e.id;
                        });

                        let newEmployeeRole = newEmployee?.EMPLOYEE_ROLES.find(
                          (x) => x.ROLE_ID === role?.ROLE_ID
                        );

                        if (newEmployeeRole) {
                          setFieldValue("employeeRoleId", newEmployeeRole.ID);
                        } else {
                          setFieldValue("employeeRoleId", null);
                        }
                      }
                    }
                  }}
                  data={assignableEmployees.map((emp) => {
                    return {
                      label: emp.FULL_NAME,
                      id: emp.ID,
                    };
                  })}
                />

                {locations && locations.length > 1 && (
                  <FormSelect
                    className="flex-1 mr-2"
                    name="location"
                    label="Location"
                    options={formikOptions}
                    data={locations}
                  />
                )}

                {employeeId && (
                  <div className="flex flex-row">
                    <FormSelect
                      className="flex-1 mr-2"
                      name="employeeRoleId"
                      label="Role"
                      options={formikOptions}
                      data={employeeRoles}
                    />

                    {employeeRoleId && (
                      <div className="mt-5">
                        <div className="mt-0.5">
                          <div
                            style={{
                              backgroundColor: `#${color}`,
                            }}
                            className="rounded-sm mt-4 h-7 w-7"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {employeeId && shiftTags.length > 1 && (
                  <div className={"flex flex-row"}>
                    <FormSelect
                      className={"flex-1"}
                      label={"Shift Tag"}
                      name={"shiftTagId"}
                      data={shiftTags.map((st) => ({
                        label: st.NAME,
                        value: st.ID,
                      }))}
                      options={formikOptions}
                    />

                    {shiftTagId && (
                      <div className="mt-5 ml-2">
                        <div className="mt-0.5">
                          <div
                            style={{
                              backgroundColor: `#${shiftTags.find((st) => st.ID === shiftTagId)?.COLOR}`,
                            }}
                            className="rounded-sm mt-4 h-7 w-7"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <FormDateTimeSelect
                  name="date"
                  label={"Date"}
                  options={formikOptions}
                  buttonText={(epoch) => moment(epoch).format("dddd, M/D")}
                  hideTime={true}
                  onChangeSoft={(epoch) => {
                    const isoDay = moment(epoch).isoWeekday() - 1;
                    const {days} = values;
                    const idx = days.indexOf(isoDay);

                    if (idx !== -1) {
                      days.splice(idx, 1);
                      setFieldValue("days", [...days]);
                    }
                  }}
                />

                <FormFromToTime
                  name="time"
                  label="Time"
                  className={"mt-2"}
                  tooltip={{
                    label: "Time Entry",
                    data: "Separate times with a -",
                  }}
                  options={formikOptions}
                />

                {values.employeeId &&
                  values.date &&
                  this.renderUnavailabilities(values.employeeId, values.date)}

                <FormTextArea options={formikOptions} className="mt-2" label="Notes" name="notes" />

                <MultiDaySelect
                  disableDays={[moment(values.date).isoWeekday()]}
                  options={formikOptions}
                  onChange={(days) => {
                    setFieldValue("days", [...days]);
                  }}
                  iconPadding
                  name="days"
                  label={"Duplicate Days"}
                  tooltip={{
                    data: "Select days that this shift should be duplicated to.",
                  }}
                  hint={"Optional"}
                  ignoreShadow
                  startDay={parseInt(startIsoDay)}
                />

                {isPublished && <NotificationForm isSingleShift formikOptions={formikOptions} />}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop", "scheduling"])(withRouter(ShiftModal));

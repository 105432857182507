import React, {Component} from "react";
import {Loading, PageHeadings, Table, Card, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";
import Banner from "../../../components/banner";
import LoyaltyTab from "../../../features/marketing/loyalty-tab";

class LoyaltyPage extends Component {
  state = {redeems: null, tasks: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let redeems = await request("partner/redeems", "GET");
    let tasks = await request("rewards/tasks", "GET");

    this.setState({redeems, tasks});
  }

  render() {
    const {redeems, tasks} = this.state;

    if (redeems === null || tasks === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings label="Loyalty" description="View every item that was redeemed with loyalty" />

        <Tab
          data={[
            {label: "Redemptions", id: "redemptions"},
            {label: "Rules", id: "rules"},
            {label: "Tasks", id: "tasks"},
          ]}
        >
          {(id) => {
            if (id === "redemptions") {
              return (
                <Card
                  label="Item Redemptions"
                  button={{
                    label: "View all",
                    onClick: () => this.props.router.navigate("/redemptions"),
                  }}
                >
                  <Table
                    pagination
                    data={redeems}
                    actionButtons={[
                      {
                        label: "View Patron",
                        onClick: (row) => this.props.router.navigate("/patron/" + row.PATRON_ID),
                      },
                    ]}
                    columns={[
                      {
                        value: "FULL_NAME",
                        label: "Customer Name",
                      },
                      {
                        value: "PRODUCT_NAME",
                        label: "Product",
                      },
                      {
                        width: 1,
                        value: "PRICE",
                        label: "Cost",
                        format: (cost) => "$" + toDollars(cost),
                      },
                      {
                        width: 1,
                        value: "DATE_REDEEMED",
                        label: "Date Redeemed",
                        format: (value, _) => {
                          return moment(value).format("MM/DD/YY");
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "tasks") {
              return (
                <>
                  <Banner
                    className="my-4"
                    label="Loyalty Tasks are under construction!"
                    description="Loyalty Tasks are currently under construction and will be available to use soon!"
                  />

                  <Card
                    label="Tasks"
                    button={{
                      label: "View all",
                      onClick: () => this.props.router.navigate("/tasks"),
                    }}
                  >
                    <Table
                      pagination
                      data={tasks}
                      actionButtons={[
                        {
                          label: "View",
                          onClick: (ticket) => this.props.router.navigate("/task/" + ticket.UNIQUE_ID),
                        },
                      ]}
                      columns={[
                        {
                          value: "NAME",
                          label: "Name",
                        },
                        {
                          value: "DESCRIPTION",
                          label: "Description",
                        },
                        {
                          value: "LOCATION_NAME",
                          label: "Location",
                          format: (name) => (name ? name : "All Locations"),
                        },
                        {
                          value: "DATE_CREATED",
                          label: "Created",
                          mobile: "lg:table-cell",
                          format: (value) => {
                            return moment(value).format("MM/DD/YY");
                          },
                        },
                        {
                          value: "DATE_UPDATED",
                          label: "Updated",
                          mobile: "lg:table-cell",
                          format: (value) => {
                            return (
                              <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>
                            );
                          },
                        },
                      ]}
                    />
                  </Card>
                </>
              );
            }

            if (id === "rules") {
              return <LoyaltyTab {...this.props} />;
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(LoyaltyPage));

import React, {Component} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {Popover} from "@headlessui/react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {ChevronDown} from "react-feather";
import {minutesToHourString} from "../utils/hours-helper";

class FormTimeSelect extends Component {
  render() {
    let {options, name} = this.props;
    return (
      <FormElement {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          let setMinutes = [0, 15, 30, 45];
          let times = [];
          for (let hour = 0; hour < 24; hour++) {
            for (let minute of setMinutes) {
              times.push({hour, minute});
            }
          }

          times.push({hour: 23, minute: 59});

          return (
            <select
              className="inline-flex w-28 flex flex-row items-center pl-4 pr-2 py-2 border border-gray-300 shadow-sm text-sm rounded-md font-medium text-gray-700 bg-white hover:bg-gray-50  "
              // className="shadow-sm text-center w-full sm:text-sm border-gray-300 rounded-md"
              onChange={(e) => {
                let minutes = parseInt(e.target.value);

                if (options) {
                  options.setFieldValue(name, minutes);
                } else {
                  onChange(minutes);
                }
              }}
            >
              {times.map((time) => {
                let {hour, minute} = time;
                let minutes = hour * 60 + minute;

                return (
                  <option value={minutes} selected={minutes === value}>
                    {minutesToHourString(minutes)}
                  </option>
                );
              })}
            </select>
          );
        }}
      </FormElement>
    );
  }
}

export default FormTimeSelect;

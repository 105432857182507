import React, {Component} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Fuse from "fuse.js";
import ReactDOM from "react-dom";
import ReactResizeDetector from "react-resize-detector";

class FormFromTimeToTime extends Component {
  constructor(props) {
    super(props);

    this.id = "fttt_" + randomString(24);

    this.state = {
      display: null,
      selectionStart: 0,
      filteredOptions: [],
      showDropdown: false,
    };
  }

  componentDidMount() {
    this.mounted = true;

    this.setState({filteredOptions: this.getOptions()});
  }

  onChange(display) {
    const {options} = this.props;
    const {from, to} = this.convertInputToValues(display);

    if (options) {
      options.setFieldValue("from", from);
      options.setFieldValue("to", to);
    }
  }

  onInputChange(value) {
    this.setState({display: value, filteredOptions: this.filterOptions(value)});
  }

  onBlur = (e, display, override) => {
    if (!this.blockBlur || override) {
      let {from, to} = this.convertInputToValues(display);

      display = this.convertValuesToReadable(from, to);

      this.setState({display: display});

      this.onChange(display);
    } else {
      e.stopPropagation();
    }
  };

  convertInputToValues(displayStr) {
    const filteredString = displayStr.replace(/[^0-9apAP:-]/gi, "").toLowerCase();

    const splitArr = filteredString.split("-");

    let from = this.convertTimeEntryToFormatted(splitArr[0]);
    let to = undefined;

    if (splitArr.length > 1) {
      to = this.convertTimeEntryToFormatted(splitArr[1]);
    }

    return {
      from,
      to,
    };
  }

  convertValuesToReadable(from, to) {
    if (from || to) {
      return `${this.convertValueToReadable(from)} - ${this.convertValueToReadable(to)}`;
    }

    return "";
  }

  convertValueToReadable(value) {
    if (!value) {
      return "";
    }

    const arr = value.split(":");

    let hour = arr[0];

    let minutes = arr[1];
    let meridian = "AM";

    if (parseInt(hour) > 12) {
      hour -= 12;
      meridian = "PM";
    }

    if (hour === "12") {
      meridian = "PM";
    }

    if (hour === "00") {
      hour = "12";
      meridian = "AM";
    }

    return `${hour}:${minutes} ${meridian}`;
  }

  convertTimeEntryToFormatted = (formattedHalf) => {
    const digits = (formattedHalf.match(/\d/g) ?? []).map((_d) => parseInt(_d));

    if (digits.length > 4 || digits.length === 0) {
      return undefined;
    }

    let hour;
    let minutes = "00";

    if (digits.length === 4) {
      hour = digits[0] * 10 + digits[1];

      minutes = `${digits[2]}${digits[3]}`;
    } else if (digits.length === 3) {
      hour = digits[0];
      minutes = `${digits[1]}${digits[2]}`;
    } else if (digits.length === 2) {
      hour = digits[0] * 10 + digits[1];
    } else if (digits.length === 1) {
      hour = digits[0];
    }

    if (formattedHalf.includes("p") && hour !== 12) {
      hour += 12;
    } else if (formattedHalf.includes("a") && hour === 12) {
      hour = `00`;
    }

    if (hour > 24 || minutes > 60) {
      return undefined;
    }

    return `${hour}:${minutes}`;
  };

  onSelect = (e) => {
    this.setState({
      selectionStart: e.target.selectionStart,
      filteredOptions: this.filterOptions(e.target.value, e.target.selectionStart),
    });
  };

  getOptions() {
    let toReturn = [];

    let hours = [...Array(24).keys()];
    let quarters = [...Array(4).keys()];

    for (let hour of hours) {
      for (let quarter of quarters) {
        let merid = "AM";
        let displayHour = hour;
        let displayMinute = quarter * 15;

        if (hour > 12) {
          displayHour -= 12;
          merid = "PM";
        }

        if (hour === 12) {
          merid = "PM";
        }

        if (hour === 0) {
          displayHour = 12;
        }

        if (displayMinute < 10) {
          displayMinute = "0" + displayMinute;
        }

        toReturn.push({
          label: `${displayHour}:${displayMinute} ${merid}`,
          shortForm: `${displayHour}${displayMinute}`,
          value: `${hour}:${displayMinute}`,
        });
      }
    }

    return toReturn;
  }

  filterOptions(display, selectionStart = null) {
    if (!selectionStart) {
      selectionStart = this.state.selectionStart;
    }

    const options = this.getOptions();

    const splitIndex = display.indexOf("-");

    let searchStr = display.substring(splitIndex, display.length).replaceAll("-", "");
    if (selectionStart < splitIndex) {
      searchStr = display.substring(0, splitIndex);
    }

    if (searchStr.replaceAll(" ", "").length === 0) {
      return options;
    }

    return new Fuse(options, {
      keys: ["label", "shortForm"],
      useExtendedSearch: true,
      threshold: 0,
    })
      .search(searchStr)
      .map(({item}) => item);
  }

  selectTime = (e, newTimeStr, display) => {
    this.blockBlur = true;

    const {selectionStart} = this.state;
    const splitIndex = display.indexOf("-");

    if (splitIndex === -1) {
      display = newTimeStr;
    } else if (selectionStart < splitIndex) {
      display = newTimeStr + display.substring(splitIndex, display.length);
    } else {
      display = display.substring(0, splitIndex + 1) + newTimeStr;
    }

    this.setState({display: display});

    this.onBlur(e, display, true);

    this.blockBlur = false;
  };

  getDisplay(from, to) {
    const {display} = this.state;

    if (display !== null) {
      return display;
    }

    if (!from && !to) {
      return "";
    }

    return this.convertValuesToReadable(from, to);
  }

  render() {
    const {name, placeholder, disabled, options} = this.props;
    const {filteredOptions} = this.state;

    return (
      <FormElement id={this.id} {...this.props}>
        {(rawValue, error, onChange, onBlur, extraStyle) => {
          const open = document.activeElement === document.getElementById(name);

          const from = options.values.from;
          const to = options.values.to;

          const value = this.getDisplay(from, to);

          return (
            <div>
              <input
                key={name}
                id={name}
                name={name}
                value={value}
                onBlur={(e) => this.onBlur(e, value)}
                type={"text"}
                onSelect={this.onSelect}
                disabled={disabled}
                onChange={(e) => {
                  this.onInputChange(e.target.value);
                }}
                placeholder={placeholder}
                className={classNames(
                  disabled ? "text-neutral-text" : undefined,
                  error
                    ? "text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500"
                    : "focus:ring-primary-border focus:border-primary-border border-neutral-border",
                  "block w-full focus:outline-none sm:text-sm rounded-md",
                  extraStyle
                )}
                aria-describedby="email-error"
                aria-invalid="true"
              />

              <ReactResizeDetector handleWidth handleHeight>
                {({width}) => {
                  return (
                    <div>
                      {this.mounted &&
                        open &&
                        filteredOptions?.length > 0 &&
                        ReactDOM.createPortal(
                          <ul
                            ref={(e) => (this.dropdownRef = e)}
                            style={{width, maxHeight: 200}}
                            className="mt-2 flex-col z-20 absolute bg-white rounded-md text-base shadow-lg ring-1 ring-red-600 ring-opacity-5 focus:outline-none sm:text-sm overflow-auto"
                          >
                            {filteredOptions.map((_option) => {
                              return (
                                <li>
                                  <div
                                    key={_option.value}
                                    className={
                                      "cursor-pointer select-none py-2 pl-3 pr-9  text-gray-900 sm:text-sm hover:bg-indigo-600 hover:text-white font-semibold"
                                    }
                                    onMouseDown={(e) => {
                                      e.stopPropagation();

                                      this.selectTime(e, _option.label, this.getDisplay(from, to));
                                    }}
                                  >
                                    {_option.label}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>,
                          document.querySelector("#" + this.id)
                        )}
                    </div>
                  );
                }}
              </ReactResizeDetector>
            </div>
          );
        }}
      </FormElement>
    );
  }
}

export default FormFromTimeToTime;

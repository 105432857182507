import React, {Component} from "react";
import {request} from "../../../utils/request";
import {Card, PageHeadings, Tab, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import BillModal, {BILL_MODES} from "../../../modals/accounting/bills/bill-modal";
import moment from "moment";
import {setupReduxConnection} from "../../../redux";
import LoadingSpinner from "../../../components/loading-spinner";
import {AccountingRequests} from "../../../utils/request-helpers/accounting/accounting-requests";

class BillPage extends Component {
  state = {bill: null};

  async componentDidMount() {
    this.props.loadLedgers();

    this.syncState();
  }

  syncState = async () => {
    const {UNIQUE_ID} = this.props.router.params;

    const bill = await AccountingRequests.Bills.fetchBill(UNIQUE_ID);

    this.setState({bill});
  };

  renderLines() {
    const {bill} = this.state;

    return (
      <Card
        label={"Bill Lines"}
        button={{
          label: "Edit",
          onClick: () => this.billModal.open(bill, BILL_MODES.LINES),
        }}
      >
        <Table
          columns={[
            {label: "Category", value: "CATEGORY_NAME"},
            {label: "Description", value: "DESCRIPTION"},
            {
              label: "Amount",
              value: "AMOUNT",
              format: (val) => toDollars(val, true),
            },
          ]}
          data={bill.LINES}
        />
      </Card>
    );
  }

  render() {
    const {bill} = this.state;

    const extraDetails = [];

    if (bill?.BILL_NUMBER) {
      extraDetails.push({
        label: "Number",
        value: bill?.BILL_NUMBER,
      });
    }

    return (
      <div>
        <BillModal ref={(e) => (this.billModal = e)} syncState={this.syncState} />

        <PageHeadings
          label={`Bill ${bill?.VENDOR_NAME ? `from ${bill?.VENDOR_NAME}` : ""}`}
          breadcrumbs={[
            {label: "Accounting", url: "/accounting"},
            {label: "Bills", url: "/accounting/bills"},
          ]}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [{label: "Add a Payment", onClick: () => this.deleteProduct()}],
                },
                {
                  items: [
                    {
                      label: "Duplicate",
                      onClick: () => {
                        this.duplicateProduct();
                      },
                    },
                  ],
                },
                {
                  items: [{label: "Delete", onClick: () => this.deleteProduct()}],
                },
              ],
            },
          ]}
        />

        {!bill && <LoadingSpinner />}

        {bill && (
          <div>
            <TwoColumnList
              label={"Bill Details"}
              button={{
                label: "Edit",
                onClick: () => this.billModal.open(bill),
              }}
              data={[
                {
                  label: "Total Amount",
                  value: toDollars(bill?.AMOUNT, true),
                },
                {
                  label: "Issue Date",
                  value: moment(bill?.DATE_ISSUED).format("M/D/YYYY"),
                },
                {
                  label: "Due Date",
                  value: moment(bill?.DATE_DUE).format("M/D/YYYY"),
                },
                ...extraDetails,
              ]}
            />

            <Tab
              data={[
                {
                  label: "Lines",
                  id: "lines",
                },
                {
                  label: "Payments",
                  id: "payments",
                },
              ]}
            >
              {(id) => {
                if (id === "lines") {
                  return this.renderLines();
                }

                if (id === "payments") {
                }
              }}
            </Tab>
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["accounting"])(withRouter(BillPage));

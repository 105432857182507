import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import InvoiceForm from "../../../forms/operations/invoices/invoice-form";
import PropTypes from "prop-types";

class InvoiceModal extends Component {
  state = {invoice: null};

  open(invoice = null) {
    this.setState({invoice}, () => {
      this.modal.open();
    });
  }

  async saveInvoice(values) {
    const {contact, number, notes, due, service, tipEnabled, sendToKds} = values;

    const {invoice} = this.state;

    let serverInvoice = await request("invoices/" + invoice.ID, "PATCH", {
      INVOICE_NUMBER: number,
      CONTACT_ID: contact,

      DATE_SERVICE: service,
      DATE_DUE: due,

      NOTES: notes,
      REQUEST_TIP: tipEnabled,
      SEND_TO_KDS: parseInt(sendToKds),
    });

    this.props.updateState(serverInvoice);
    this.modal.close();
  }

  render() {
    const {large, isRecurring} = this.props;
    const {invoice} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Invoice"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        large={large}
      >
        <InvoiceForm
          isRecurring={isRecurring}
          handleSubmit={this.saveInvoice.bind(this)}
          ref={(e) => (this.formikRef = e)}
          invoice={invoice}
        />
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {
  updateState: PropTypes.func.isRequired,
  isRecurring: PropTypes.bool,
  large: PropTypes.bool,
};

export default InvoiceModal;
